import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'preact/hooks';
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';

import * as accountUserAliasApi from '@api/restricted/account-user-alias-api';
import loadAccountProfile from '@actions/loadAccountProfile';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import AlertMessage from '@ui-kit/alert/Alert';
import Header from '@ui-kit/typography/header';
import Box from '@ui-kit/box';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
// import Text from '@ui-kit/typography/text';
// import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';
// import PanelDataCard from '@distinct-components/cards/panelDataCard/PanelDataCard';

const maskGenerator = createDefaultMaskGenerator('9999 9999 9999 9999');

function CreateAlias({
  closeFunction,
}) {
  const {
    register, setValue, trigger, handleSubmit, formState: { isValid },
  } = useForm({ mode: 'onChange' });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isni, setIsni] = useState('');

  useEffect(() => {
    register('isni', { minLength: 19, maxLength: 19 });
    setValue('isni', '');
  }, []);

  const handleInputChange = (data) => {
    setValue('isni', data);
    trigger('isni');
  };

  const handleCreateAlias = (data) => {
    const cleanedData = {
      aliasName: data.aliasName?.trim(),
      ipi: data.ipi,
      isni: data.isni,
    };
    if (!cleanedData.aliasName) {
      setError('Invalid persona name');
    } else if (!cleanedData.ipi && !cleanedData.isni) {
      setError('A valid Persona has at least one unique identifier');
    } else {
      setIsLoading(true);
      let formattedIsni = cleanedData.isni;
      if (cleanedData.isni) {
        const trimmed = cleanedData.isni.replace(/\s/g, '');
        const sections = trimmed.match(/.{1,4}/g);
        formattedIsni = sections.join(' ');
      }
      accountUserAliasApi.postAccountUserAlias(
        cleanedData.aliasName,
        cleanedData.ipi,
        '',
        formattedIsni,
        false,
      ).then((response) => {
        setIsLoading(false);
        if (response.status !== 200) {
          response.json()
            .then((json) => setError(json.error ? json.error : response.statusText))
            .catch(() => setError(response.statusText));
        } else {
          closeFunction();
          loadAccountProfile();
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
    }
  };

  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>
      <Box>
        <Header mb="1em">Create Persona</Header>
        <form onSubmit={handleSubmit(handleCreateAlias)} autoComplete="off">
          <InputLabel label="Persona Name" />
          <BaseInput
            {...register('aliasName', { required: true })}
            id="aliasName"
            name="aliasName"
            placeholder="Persona or Stage Name"
            type="text"
            fluid
            mb="1.5rem"
          />
          <InputLabel label="Unique IPI" />
          <BaseInput
            {...register('ipi', { required: false })}
            id="ipi"
            name="ipi"
            placeholder="9-11 Digit IPI"
            type="text"
            inputMode="decimal"
            pattern="[0-9]+"
            minLength="9"
            maxLength="11"
            fluid
            mb="1.5rem"
          />
          <InputLabel label="Unique ISNI" />
          <MaskedInput
            maskGenerator={maskGenerator}
            className="masked-input"
            value={isni}
            onChange={setIsni}
            onKeyUp={(e) => handleInputChange(e.target.value)}
            id="isni"
            name="isni"
            placeholder="16 Digit ISNI"
            maxLength="19"
            minLength="19"
            type="text"
            inputMode="decimal"
          />
          {/* <PanelDataCard mb="2.5em" p="0.625em 1em">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Text mr="1.25em" fontSize="0.75rem" color="var(--text-primary)">Default for Compositions</Text>
              <ToggleSwitch id="compositionDefault">
                <input
                  {...register('compositionDefault', { required: false })}
                  type="checkbox"
                  name="compositionDefault"
                  id="compositionDefault"
                  checked={getValues().compositionDefault}
                />
              </ToggleSwitch>
            </Box>
          </PanelDataCard> */}
          {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}
          <BaseButton mb="1.5em" type="submit" btnText="Save" disabled={!isValid} fluid isLoading={isLoading} />
        </form>
      </Box>
    </BaseEditOverlay>
  );
}

CreateAlias.propTypes = {
  closeFunction: PropTypes.func.isRequired,
};

export default CreateAlias;
