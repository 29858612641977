import { accountIdentityVerificationUrl } from '../urls';

export async function postAccountIdentityVerification(returnUrl) {
  const data = {
    redirect: returnUrl,
  };
  const response = await fetch(accountIdentityVerificationUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

export async function patchAccountIdentityVerificationReport() {
  const response = await fetch(accountIdentityVerificationUrl, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return response;
}
