/* eslint-disable react/forbid-prop-types */
import { useEffect, useRef, useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import AppState from '@state';
import { Fragment } from 'preact';
import { route } from 'preact-router';

import getWorkspaceContractVersions from '@api/restricted/workspace-contract-versions-api';
import loadComposition from '@actions/loadComposition';
import { patchWorkspaceLock } from '@api/restricted/workspace-api';
import postWorkspaceSignatureRequest from '@api/restricted/workspace-signature-request-api';
import postWorkspaceContractDownload from '@api/restricted/workspace-contract-download-api';
import { postWorkspaceContractAmendment, deleteWorkspaceContractAmendment } from '@api/restricted/workspace-contract-amendment-api';
import getWorkspaceContract from '@api/restricted/workspace-contract-api';

import useErrorOverlay from '@hooks/useErrorOverlay';
import useRoundNumber from '@hooks/useRoundNumber';
import useIsoDateFormat from '@hooks/useIsoDateFormat';

import Box from '@ui-kit/box';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import Icon from '@ui-kit/icon';
import ContractIcon from '@assets/illustrations/contract-quill.svg';
import Text from '@ui-kit/typography/text';
import BreakLine from '@ui-kit/dividers/breakLine';

import { CONTRACT_ACTION_EXPLANATIONS, CONTRACT_EXPLANATIONS } from '@constants/supportingCopy';

import DeleteConfirmationOverlay from '@shared-overlays/deleteConfirmationOverlay';
import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import LockSingleWriter from './lockSingleWriter';
import UnlockSingleWriter from './unlockSingleWriter';
import CreateAmendment from './createAmendment/CreateAmendment';
import CreateSplitSheet from './createSplitSheet/CreateSplitSheet';

import {
  ActionMsg,
  ActionTitle,
  ActionWrapper,
  ActionBtnRow,
  StyledActionButton,
  StyledSecondaryButton,
  SecondaryActionRow,
  RejectionWrapper,
} from './OwnershipActionsStyles';

function CompositionOwnershipActions({ activeUser, workspaceId }) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [latestContract, setLatestContract] = useState([]);
  const [rejectionDetails, setRejectionDetails] = useState(null);
  const [secondaryAction, setSecondaryAction] = useState(null);
  const [primaryAction, setPrimaryAction] = useState(null);
  const [actionTitle, setActionTitle] = useState('');
  const [actionColor, setActionColor] = useState('');
  const [actionMsg, setActionMsg] = useState('');
  const [showContractHistory, setShowContractHistory] = useState(false);
  const writersRef = useRef(null);
  const invitesRef = useRef(null);
  const shareRef = useRef(null);

  // SINGLE WRITER
  const [showLockSingleWriter, setShowLockSingleWriter] = useState(false);
  const [showUnlockSingleWriter, setShowUnlockSingleWriter] = useState(false);
  const [showCancelAmendment, setShowCancelAmendment] = useState(false);

  // MULTI WRITER
  const [showCreateContract, setShowCreateContract] = useState(false);
  const [showCreateAmendment, setShowCreateAmendment] = useState(false);

  const [errorCancelAmendment, setErrorCancelAmendment] = useState(null);
  const [isCancellingAmendment, setIsCancellingAmendment] = useState(false);

  const TOTAL_SHARE = useRoundNumber(AppState.workspaceAccounts.value.filter((account) => account.isWriter).reduce((prev, current) => prev + +(current.share), 0), 2);
  const TOTAL_WRITERS = AppState.workspaceAccounts.value.filter((account) => account.isWriter).length;
  const TOTAL_WRITER_INVITES = AppState.workspaceInvites.value.filter((account) => account.isWriter).length;

  const ACTION_STRING_DOWNLOAD_CONTRACT = 'Download PDF';
  const ACTION_STRING_AMEND = 'Make Changes';
  const ACTION_STRING_SIGN_SINGLE = 'Sign Attestation';
  const ACTION_STRING_SIGN_MULTI = 'Review and Sign';
  const ACTION_STRING_CREATE_CONTRACT = 'Create Split Sheet';

  const loadContractVersions = async () => {
    try {
      const response = await getWorkspaceContractVersions(workspaceId);
      if (response.status === 200) {
        const json = await response.json();
        const executedVersions = json.versions.filter((v) => v.status === 'executed').map((v) => v.version);
        AppState.composition.shareableVersion.value = (executedVersions.length > 0) ? Math.max(...executedVersions) : 0;
        return json.versions;
      }
      const json = await response.json();
      useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading contract versions` });
      return null;
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading contract versions` });
      return null;
    }
  };

  const handleSingleWriterContent = () => {
    setPrimaryAction(null);
    setSecondaryAction(null);
    loadContractVersions().then((versions) => {
      if (versions !== null) {
        const lastContractVersion = versions.reduce((maxVersion, contract) => (contract.version ? Math.max(maxVersion, contract.version) : maxVersion), -Infinity);
        const lastContract = versions.find((c) => c.version === lastContractVersion);
        setContracts(versions);
        if (TOTAL_SHARE < 100) {
          setActionColor('purple');
          setActionTitle('Next Step: Add Writers or Claim 100%');
          setActionMsg(CONTRACT_ACTION_EXPLANATIONS.singleWriter.pending);
        } else if (lastContract?.status !== 'executed' && TOTAL_SHARE === 100) {
          setActionColor('green');
          setActionTitle('Next Step: Finalize Your Ownership');
          setActionMsg(CONTRACT_ACTION_EXPLANATIONS.singleWriter.ready);
          setPrimaryAction(ACTION_STRING_SIGN_SINGLE);
        } else {
          setActionTitle('Single Writer Attested');
          setActionColor('purple');
          setActionMsg(CONTRACT_ACTION_EXPLANATIONS.singleWriter.signed);
          setPrimaryAction(ACTION_STRING_AMEND);
        }
      }
      setShowLockSingleWriter(false);
      setShowUnlockSingleWriter(false);
      setShowCancelAmendment(false);
    });
  };

  const getContractDetails = async (contractId, contractVersion) => {
    try {
      const response = await getWorkspaceContract(contractId, contractVersion);
      if (response.status !== 200) {
        const json = await response.json();
        AppState.exceptions.errorPageError.value = `${json.error || response.statusText} when loading contract`;
        route('/error/exception');
      } else {
        const json = await response.json();
        AppState.workspace.writerSignatureData.value = json.writers;
        if (json?.rejection) {
          setRejectionDetails(json.rejection);
        }
      }
    } catch (err) {
      AppState.exceptions.errorPageError.value = `${err.message} when loading contract`;
      route('/error/exception');
    }
  };

  const handleMultiWriterContent = () => {
    setPrimaryAction(null);
    setSecondaryAction(null);
    setRejectionDetails(null);
    loadContractVersions().then((versions) => {
      if (versions !== null) {
        const lastContractVersion = versions.reduce((maxVersion, contract) => (contract.version ? Math.max(maxVersion, contract.version) : maxVersion), -Infinity);
        const lastContract = versions.find((c) => c.version === lastContractVersion);

        if (lastContract.status === 'rejected') {
          getContractDetails(AppState.composition.id.value, lastContract.version);
        }
        setLatestContract(lastContract);
        setContracts(versions);

        if (lastContract.status === 'signature' && lastContract.isAmendment && !lastContract.signedByUser && !AppState.composition.isLocked.value && TOTAL_SHARE === 100) {
          setActionTitle('Next Step: Create Split Sheet');
          setActionColor('green');
          setActionMsg(CONTRACT_ACTION_EXPLANATIONS.multiWriter.amending);
          setPrimaryAction(ACTION_STRING_CREATE_CONTRACT);
          return;
        }

        if (lastContract.status === 'signature' && !lastContract.signedByUser && AppState.composition.isLocked.value) {
          getContractDetails(AppState.composition.id.value, lastContract.version);
          setActionTitle('Next Step: Sign Your Splits');
          setActionColor('green');
          setActionMsg(CONTRACT_ACTION_EXPLANATIONS.multiWriter.readyToSign);
          setPrimaryAction(ACTION_STRING_SIGN_MULTI);
          return;
        }

        if (lastContract.status === 'signature' && lastContract.signedByUser) {
          getContractDetails(AppState.composition.id.value, lastContract.version);
          setActionTitle('Next Step: Awaiting Signatures');
          setActionColor('purple');
          setActionMsg(CONTRACT_ACTION_EXPLANATIONS.multiWriter.awaitingSignatures);
          setSecondaryAction('View Contract');
          return;
        }

        if (TOTAL_SHARE < 100) {
          setActionTitle('Next Step: Define 100% Ownership');
          setActionColor('purple');
          setActionMsg(CONTRACT_ACTION_EXPLANATIONS.multiWriter.pending);
        } else if (TOTAL_SHARE > 100) {
          setActionTitle('Next Step: Ownership is over 100%');
          setActionColor('purple');
          setActionMsg(CONTRACT_ACTION_EXPLANATIONS.multiWriter.splitRangeExceeded);
        } else if (lastContract?.status !== 'executed' && TOTAL_SHARE === 100) {
          if (activeUser.isAdmin) {
            setActionTitle('Next Step: Create Split Sheet');
            setActionColor('green');
            setActionMsg(CONTRACT_ACTION_EXPLANATIONS.multiWriter.ready);
          } else {
            setActionTitle('Next Step: Await Contract Creation');
            setActionColor('purple');
            setActionMsg(CONTRACT_ACTION_EXPLANATIONS.multiWriter.readyNonAdmin);
          }
          setPrimaryAction(ACTION_STRING_CREATE_CONTRACT);
        } else {
          getContractDetails(AppState.composition.id.value, lastContract.version);
          setActionTitle('Split Sheet Fully Executed');
          setActionColor('purple');
          setActionMsg(CONTRACT_ACTION_EXPLANATIONS.multiWriter.signed);
          setPrimaryAction(ACTION_STRING_DOWNLOAD_CONTRACT);
          setSecondaryAction('View Contract');
        }
      }
    });
  };

  useEffect(() => {
    AppState.workspace.writerSignatureData.value = null;
    if (
      writersRef.current !== TOTAL_WRITERS
      || invitesRef.current !== TOTAL_WRITER_INVITES
      || shareRef.current !== TOTAL_SHARE
    ) {
      writersRef.current = TOTAL_WRITERS;
      invitesRef.current = TOTAL_WRITER_INVITES;
      shareRef.current = TOTAL_SHARE;
      setTimeout(() => {
        if (TOTAL_WRITERS + TOTAL_WRITER_INVITES < 2) {
          handleSingleWriterContent();
        } else {
          handleMultiWriterContent();
        }
      }, 300);
    }
  }, [TOTAL_WRITER_INVITES, TOTAL_WRITERS, TOTAL_SHARE]);

  const useToastAlertError = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'error' };
  };

  const handleFileDownload = async (version, compName) => {
    setIsLoading(true);

    postWorkspaceContractDownload(workspaceId, version)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          response.blob()
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = compName;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove();
            })
            .catch((err) => {
              useToastAlertError(err.message);
            });
        } else {
          response.json()
            .then((json) => {
              useToastAlertError(json.error ? json.error : response.statusText);
            })
            .catch(() => {
              useToastAlertError(response.statusText);
            });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        useToastAlertError(err.message);
      });
  };

  const downloadLatestContract = () => {
    const lastContractVersion = contracts.reduce((maxVersion, contract) => (contract.version ? Math.max(maxVersion, contract.version) : maxVersion), -Infinity);
    const lastContract = contracts.find((c) => c.version === lastContractVersion);
    handleFileDownload(lastContract.version, lastContract.compositionName);
  };

  const handlePrimaryAction = () => {
    switch (primaryAction) {
      case ACTION_STRING_SIGN_SINGLE:
        setShowLockSingleWriter(true);
        break;
      case ACTION_STRING_CREATE_CONTRACT:
        setShowCreateContract(true);
        break;
      case ACTION_STRING_SIGN_MULTI:
        route(`/contracts/contract/${AppState.composition.id.value}/${latestContract.version}`);
        break;
      case ACTION_STRING_AMEND:
        setShowUnlockSingleWriter(true);
        break;
      case ACTION_STRING_DOWNLOAD_CONTRACT:
        downloadLatestContract();
        break;
      default:
        useToastAlertError('Error Handling Action');
    }
  };

  const handleSecondaryAction = () => {
    switch (secondaryAction) {
      case 'View Contract':
        route(`/contracts/contract/${AppState.composition.id.value}/${latestContract.version}`);
        break;
      default:
        useToastAlertError('Error Handling Action');
    }
  };

  const closeSingleWriter = () => {
    handleSingleWriterContent();
  };

  const closeFunction = () => {
    setError('');
    setErrorCancelAmendment(null);
    setShowCreateContract(false);
    setShowCreateAmendment(false);
    setShowCancelAmendment(false);
  };

  const handleCancelAmendment = () => {
    setErrorCancelAmendment(null);
    setIsCancellingAmendment(true);

    deleteWorkspaceContractAmendment(workspaceId)
      .then((response) => {
        if (response.status === 200) {
          loadComposition(AppState.composition.id.value).then(() => {
            setErrorCancelAmendment(null);
            setIsCancellingAmendment(false);
            closeSingleWriter();
            closeFunction();
            setTimeout(() => {
              if (TOTAL_WRITERS + TOTAL_WRITER_INVITES < 2) {
                handleSingleWriterContent();
              } else {
                handleMultiWriterContent();
              }
            }, 300);
          });
        } else {
          response.json()
            .then((json) => setErrorCancelAmendment(json.error || response.statusText))
            .catch(() => setErrorCancelAmendment(response.statusText));
          setIsCancellingAmendment(false);
        }
      })
      .catch((err) => {
        setIsCancellingAmendment(false);
        setErrorCancelAmendment(err.message);
      });
  };

  const handleCreateContract = async (message) => {
    setIsLoading(true);

    setTimeout(async () => {
      patchWorkspaceLock(AppState.composition.id.value)
        .then((response) => {
          if (response.status === 200) {
            postWorkspaceSignatureRequest(AppState.composition.id.value, message);
            loadContractVersions();
            loadComposition(workspaceId);

            setIsLoading(false);
            setError(null);
            setShowCreateContract(false);
            setTimeout(() => {
              if (TOTAL_WRITERS + TOTAL_WRITER_INVITES < 2) {
                handleSingleWriterContent();
              } else {
                handleMultiWriterContent();
              }
            }, 300);
          } else {
            setIsLoading(false);
            response.json()
              .then((json) => setError(json.error || response.statusText))
              .catch(() => setError(response.statusText));
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err.message);
        });
    }, 700);
  };

  const handleCreateAmendment = (message) => {
    setIsLoading(true);
    setError('');
    postWorkspaceContractAmendment(workspaceId, message)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          loadContractVersions();
          loadComposition(workspaceId);
          setShowCreateAmendment(false);
          setTimeout(() => {
            if (TOTAL_WRITERS + TOTAL_WRITER_INVITES < 2) {
              handleSingleWriterContent();
            } else {
              handleMultiWriterContent();
            }
          }, 300);
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return (
    <Fragment>

      <Box width="100%" mb="1.5em">
        <Box>
          <ActionWrapper actionColor={actionColor}>
            <ActionTitle>{actionTitle}</ActionTitle>
            <ActionMsg>{actionMsg}</ActionMsg>
            <ActionBtnRow>
              {secondaryAction && (
              <StyledSecondaryButton actionColor={actionColor} btnText={secondaryAction} onClick={handleSecondaryAction} fluid />
              )}
              {primaryAction && (!activeUser.isAdmin && primaryAction !== ACTION_STRING_CREATE_CONTRACT) && (
              <StyledActionButton actionColor={actionColor} onClick={handlePrimaryAction} btnText={primaryAction} fluid decoration={primaryAction === ACTION_STRING_DOWNLOAD_CONTRACT ? 'pdf' : null} />
              )}
              {primaryAction && activeUser.isAdmin && (
              <StyledActionButton actionColor={actionColor} onClick={handlePrimaryAction} btnText={primaryAction} fluid decoration={primaryAction === ACTION_STRING_DOWNLOAD_CONTRACT ? 'pdf' : null} />
              )}
            </ActionBtnRow>

            {(contracts.length > 1 || (activeUser.isAdmin && primaryAction === ACTION_STRING_DOWNLOAD_CONTRACT)) && (
              <SecondaryActionRow actionColor={actionColor}>
                {latestContract.isAmendment && activeUser.isAdmin && !AppState.composition.isLocked.value
                  && <LinkButton variant="small" btnText="Cancel Amendment" onClick={() => setShowCancelAmendment(true)} />}
                {activeUser.isAdmin && primaryAction === ACTION_STRING_DOWNLOAD_CONTRACT && <LinkButton color="var(--text-primary)" btnText="Make Changes" fontSize="0.813rem" onClick={() => setShowCreateAmendment(true)} />}
                {contracts.length > 1 && <LinkButton color="var(--text-primary)" btnText="View Contract History" fontSize="0.813rem" onClick={() => setShowContractHistory(true)} />}
              </SecondaryActionRow>
            )}
          </ActionWrapper>
        </Box>
      </Box>

      {rejectionDetails && (
        <RejectionWrapper>
          <Text fontWeight="600">Latest Contract Rejected</Text>
          {rejectionDetails.legalName && (
            <Text opacity="0.8">
              By:
              {' '}
              {rejectionDetails.legalName}
            </Text>
          )}
          <Text opacity="0.8">
            Reason:
            {' '}
            {rejectionDetails.reason}
          </Text>
        </RejectionWrapper>
      )}

      {showLockSingleWriter && (
      <LockSingleWriter closeFunction={closeSingleWriter} />
      )}

      {showUnlockSingleWriter && (
        <UnlockSingleWriter closeFunction={closeSingleWriter} />
      )}

      {showCancelAmendment && (
        <DeleteConfirmationOverlay
          headerLabel="Cancel Amendment"
          header="Any changes you have made will be reverted"
          explanation={CONTRACT_EXPLANATIONS.lifecycle.deleteAmendmentMessage}
          closeFunction={closeSingleWriter}
          handleDelete={handleCancelAmendment}
          error={errorCancelAmendment}
          isLoading={isCancellingAmendment}
          confirmationPhrase="Cancel Amendment"
          buttonText="Cancel Amendment"
        />
      )}

      {showCreateContract && (
      <CreateSplitSheet
        isLoading={isLoading}
        error={error}
        handleCreateContract={handleCreateContract}
        closeFunction={closeFunction}
      />
      )}

      {showCreateAmendment && (
        <CreateAmendment
          isLoading={isLoading}
          error={error}
          handleCreateAmendment={handleCreateAmendment}
          closeFunction={closeFunction}
        />
      )}

      {showContractHistory && (
        <BaseEditOverlay formWidth="26em" closeFunction={() => setShowContractHistory(false)}>
          <ConversationPrompt
            primaryMessage="Contract History"
            mb="1em"
          />
          <ConversationExplanation mb="2em" explanation="The original split sheet was amended. View and download previous versions of the split sheet" truncateAtCharacter={500} />

          {contracts.filter((c) => c.status === 'executed').map((contract) => (
            <Fragment>
              <Box display="flex" justifyContent="space-between" alignItems="baseline">
                <Box display="flex" alignItems="center">
                  <Icon
                    mr="0.75em"
                    color="var(--white)"
                  >
                    <ContractIcon />
                  </Icon>
                  <Box>
                    <Box>
                      <Text fontWeight="600" color="var(--text-primary)" lineHeight="1" mb="0.25rem">
                        {contract.isAmendment && contract.numWriters > 1 ? 'Amended Split Sheet' : 'Split Sheet'}
                      </Text>
                      <Text fontWeight="400" color="var(--text-primary)" fontSize="0.813rem" lineHeight="1">
                        Finalized on
                        {' '}
                        {useIsoDateFormat(contract.finalizedDate)}
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <Box pb="0.25rem">
                  <LinkButton variant="small" btnText="Download" onClick={() => handleFileDownload(contract.version, contract.compositionName)} />
                </Box>
              </Box>
              <BreakLine mb="1.5em" mt="0.5em" color="var(--panel-info-border)" />
            </Fragment>
          ))}
          ;
        </BaseEditOverlay>
      )}

    </Fragment>
  );
}

CompositionOwnershipActions.propTypes = {
  activeUser: PropTypes.object.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

export default CompositionOwnershipActions;
