/* eslint-disable react/jsx-boolean-value */
import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import { route } from 'preact-router';

import AppState from '@state';

import resetGlobalState from '@state/reset';

import useErrorOverlay from '@hooks/useErrorOverlay';
import useAuthTokensValidCheck from '@hooks/useAuthTokensValidCheck';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import BaseButton from '@ui-kit/buttons/baseButton';
import Header from '@ui-kit/typography/header';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import Spinner from '@ui-kit/loaders/Spinner';

import LoginForm from '@shared-forms/loginForm/LoginForm';
import SignUpForm from '@shared-forms/signUpForm/SignUpForm';

import ConversationLayout from '@layouts/conversations';

import { postLoginWithEmail } from '@api/public/login-api';
import { postUserRegister } from '@api/public/user-register-api';
import { getWorkspaceAccountInviteToken, postWorkspaceAccountInviteToken } from '@api/public/workspace-account-invite-token-api';
import postWorkspaceInviteAccept from '@api/restricted/workspace-invite-accept-api';
import loadAccountProfile from '@actions/loadAccountProfile';

import AccountDetail from '../../auth/register/sections/account-detail';
import InvitationAccept from './sections/invitationAccept';
import InvitationSuccess from './sections/invitationSuccess';

function WriterInvitation() {
  const [workspaceId, setWorkspaceId] = useState(0);
  const [compositionTitle, setCompositionTitle] = useState('');
  const [inviterName, setInviterName] = useState('');
  const [hasEmail, setHasEmail] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isWriter, setIsWriter] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [isNew, setIsNew] = useState(true);
  const [guestFirstName, setGuestFirstName] = useState('');
  const [guestLastName, setGuestLastName] = useState('');

  const [invitationMode, setInvitationMode] = useState('Loading');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    document.title = 'Switchchord - Invitation';
    setIsLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    getWorkspaceAccountInviteToken(
      queryParams.get('token'),
    ).then((response) => {
      setIsLoading(false);
      if (response.status === 200) {
        response.json().then((json) => {
          setWorkspaceId(json.workspaceId);
          setCompositionTitle(json.workspaceTitle);
          setInviterName(json.inviterName);
          setHasEmail(json.hasEmail);
          setIsAdmin(json.admin);
          setIsWriter(json.writer);
          setCanEdit(json.edit);
          setIsNew(json.new);
          setGuestFirstName(json.suggestedName?.firstName || '');
          setGuestLastName(json.suggestedName?.lastName || '');

          if (useAuthTokensValidCheck()) {
            loadAccountProfile().then(() => {
              setError('');
              setInvitationMode('Accept');
            });
          } else if (queryParams.get('register')) {
            setError('');
            setInvitationMode('Register');
          } else {
            setError('');
            setInvitationMode('Landing');
          }
        });
      } else {
        // const errorData = {
        //   title: 'Invalid Link',
        //   userMessage: 'The link you are attempting to use is invalid, expired, or already accepted',
        // };
        // useErrorOverlay(errorData);
        AppState.exceptions.errorPageError.value = 'The link you are attempting to use is invalid, expired, or already accepted';
        route('/error/invalidLink', true);
      }
    }).catch(() => {
      // const errorData = {
      //   title: 'Invalid Link',
      //   userMessage: 'The link you are attempting to use is invalid, expired, or already accepted',
      //   errorMessage: err.message,
      // };
      // useErrorOverlay(errorData);
      AppState.exceptions.errorPageError.value = 'The link you are attempting to use is invalid, expired, or already accepted';
      route('/error/invalidLink', true);
    });
  }, []);

  const onBackToLanding = () => {
    setError('');
    setInvitationMode('Landing');
  };

  const onClickLogin = () => {
    setError('');
    setInvitationMode('Auth');
  };

  const onClickRegister = () => {
    setError('');
    if (hasEmail) {
      setInvitationMode('Register');
    } else {
      setInvitationMode('Signup');
    }
  };

  const handleAuthentication = (data) => {
    setIsLoading(true);
    postLoginWithEmail(data.email, data.password)
      .then((response) => {
        if (response.status !== 200) {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        } else {
          response.json().then((json) => {
            resetGlobalState();
            localStorage.setItem('accessToken', json.accessToken);
            localStorage.setItem('refreshToken', json.refreshToken);
            loadAccountProfile().then(() => {
              setError('');
              setTimeout(() => {
                setIsLoading(false);
                setInvitationMode('Accept');
              }, 200);
            });
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const cleanName = (name) => {
    const words = name.trim().split(/\s+/);
    return words.join(' ').replaceAll('@', '');
  };

  const handleAccountDetail = (data) => {
    const cleanedData = {
      firstName: data.firstName ? cleanName(data.firstName) : data.firstName,
      lastName: data.lastName ? cleanName(data.lastName) : data.lastName,
      password: data.password,
    };
    if (!cleanedData.firstName || !cleanedData.lastName) {
      setError('Invalid input');
    } else {
      setIsLoading(true);
      const queryParams = new URLSearchParams(window.location.search);
      postUserRegister(
        queryParams.get('token'),
        `${cleanedData.firstName} ${cleanedData.lastName}`,
        queryParams.get('email'),
        cleanedData.password,
        cleanedData.firstName,
        cleanedData.lastName,
      ).then((response) => {
        if (response.status === 200) {
          setError('');
          response.json().then((json) => {
            resetGlobalState();
            localStorage.setItem('accessToken', json.accessToken);
            localStorage.setItem('refreshToken', json.refreshToken);
            loadAccountProfile().then(() => {
              setError('');
              setTimeout(() => {
                setIsLoading(false);
                setInvitationMode('Accept');
              }, 200);
            });
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
    }
  };

  const handleSignup = async (email) => {
    const queryParams = new URLSearchParams(window.location.search);
    setIsLoading(true);
    setError('');
    try {
      const response = await postWorkspaceAccountInviteToken(email, queryParams.get('token'));
      if (response.status !== 200) {
        try {
          const json = response.json();
          setError(json.error || response.statusText);
        } catch {
          setError(response.statusText);
        }
        setIsLoading(false);
        return false;
      }
      setIsLoading(false);
      return true;
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
      return false;
    }
  };

  const handleAcceptInvite = (writerData) => {
    const { persona, ...metadata } = writerData;
    if (!metadata.contribution) {
      metadata.contribution = '';
    }
    if (!metadata.share) {
      metadata.share = 0;
    }
    const queryParams = new URLSearchParams(window.location.search);
    setIsLoading(true);
    postWorkspaceInviteAccept(
      queryParams.get('token'),
      metadata,
      persona,
    ).then((response) => {
      setIsLoading(false);
      if (response.status === 200 || response.status === 202) {
        response.json().then((json) => {
          if (json.existingUser) {
            setIsNew(false);
          }
          setError('');
          setInvitationMode('Success');
        });
      } else if (response.status === 400) {
        const errorData = {
          title: 'Invalid Link',
          userMessage: 'This invitation is no longer valid.',
        };
        useErrorOverlay(errorData);
      } else {
        response.json().then((json) => {
          setError(json.error || response.statusText);
        });
      }
    }).catch((err) => {
      setIsLoading(false);
      setError(err.message);
    });
  };

  const handleSignOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    resetGlobalState();
    setError('');
    setInvitationMode('Landing');
  };

  return (
    <ConversationLayout>
      {(invitationMode === 'Landing' || invitationMode === 'Accept')
        && (
        <ConversationPrompt
          primaryMessage={inviterName ? `${inviterName} has invited you to join the composition:` : 'Join the Composition:'}
          promptLabel="New Invitation"
          secondaryMessage={compositionTitle}
          mb="2em"
          pt="1em"
        />
        )}
      {{
        Loading:
        (
          <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
            <Spinner size="2em" variant="page" />
          </Box>
        ),

        Landing:
        (
          <Fragment>
            <Text mb="3em">
              Login to view invitation.  If you are new here, we can create your free account in one quick step.
            </Text>
            <BaseButton
              mb="1.5em"
              btnText="Login"
              fluid
              onClick={onClickLogin}
            />
            <BaseButton
              variant="secondary"
              mb="1.5em"
              btnText="Create My Account"
              fluid
              onClick={onClickRegister}
            />
          </Fragment>
        ),

        Auth:
        (
          <Fragment>
            <Header mb="1em">Welcome Back</Header>
            <LoginForm
              isLoading={isLoading}
              error={error}
              handleAuthentication={handleAuthentication}
            />
            <Box mt="1.5em" display="flex" justifyContent="center">
              <LinkButton
                btnText="Go Back"
                onClick={onBackToLanding}
              />
            </Box>
          </Fragment>
        ),

        Register:
        (
          <Fragment>
            <AccountDetail
              isLoading={isLoading}
              error={error}
              handleAccountDetail={handleAccountDetail}
              defaultFirstName={guestFirstName}
              defaultLastName={guestLastName}
            />
            <Box mt="2em" display="flex" justifyContent="center">
              <LinkButton
                btnText="Go Back"
                onClick={onBackToLanding}
              />
            </Box>
          </Fragment>
        ),

        Signup: <SignUpForm
          isLoading={isLoading}
          error={error}
          handleSignup={handleSignup}
          onClickBackButton={onBackToLanding}
        />,

        Accept: <InvitationAccept
          isWriter={isWriter}
          isAdmin={isAdmin}
          isCanEdit={canEdit}
          handleAcceptInvite={handleAcceptInvite}
          isLoading={isLoading}
          error={error}
          handleSignOut={handleSignOut}
        />,

        Success: <InvitationSuccess
          isWriter={isWriter}
          isNewUser={isNew}
          workspaceTitle={compositionTitle}
          workspaceId={workspaceId}
        />,
      }[invitationMode]}
    </ConversationLayout>
  );
}

export default WriterInvitation;
