/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';

export const PresetHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 0em 0.5em 1em;
    border-bottom: 1px solid var(--panel-info-border);
    position: relative;
    ${space};
`;

export const PresetBody = styled.div`
    padding: 0.5em 1em;
    ${space};
`;

export const ActionMenuItem = styled.div`
    border-bottom: 1px solid var(--border);
    color: var(--text-soft-mute);
    font-size: 0.75rem;
    font-weight: 500;
    width: 100%;
    padding-bottom: 1em;
    margin-bottom: 1.125em;
    cursor: pointer;
`;

export const MenuDot = styled.div`
    height: 4px;
    width: 4px;
    border-radius: 50px;
    background: var(--icon);
    cursor: pointer;
    ${space};
`;

export const DefaultChip = styled.div`
    color: var(--white);
    background: var(--blue-200);
    font-size: 0.75rem;
    border-radius: 0.5em;
    width: max-content;
    padding: 0.25em 0.5em;
    margin-bottom: 0.75em;
    margin-top: 0.75em;
`;

export const InvalidChip = styled.div`
    color: var(--white);
    background: var(--orange-200);
    font-size: 0.75rem;
    border-radius: 0.5em;
    width: max-content;
    padding: 0.25em 0.5em;
    margin-bottom: 0.75em;
    margin-top: 0.75em;
`;
