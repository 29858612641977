/* eslint-disable import/prefer-default-export */
import { orgArtistInviteTokenUrl } from '../urls';

export async function getOrgArtistInviteToken(token) {
  const url = `${orgArtistInviteTokenUrl}?token=${encodeURIComponent(token)}`;
  const response = await fetch(url, {
    method: 'GET',
  });
  return response;
}
