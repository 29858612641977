/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useEffect } from 'preact/hooks';

import AuthLayout from '@layouts/auth/AuthLayout';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseButton from '@ui-kit/buttons/baseButton';
import Header from '@ui-kit/typography/header';
import Text from '@ui-kit/typography/text';
import AlertMessage from '@ui-kit/alert/Alert';
import Box from '@ui-kit/box/Box';
import { Fragment } from 'preact';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import MailWedge from '@animations/wedges/MailWedge';

import { postForgotPassword } from '@api/public/forgot-password-api';

function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [currentStep, setCurrentStep] = useState('enterEmail');
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = 'Switchchord - Forgot Password';
  }, []);

  function callPostForgotPassword(nextStep) {
    const cleanedEmail = userEmail.toLowerCase();
    postForgotPassword(cleanedEmail)
      .then((response) => {
        if (response.status !== 200) {
          response.json()
            .then((json) => setError(json.error ? json.error : response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setError('');
          setCurrentStep(nextStep);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  }

  function handleEmailSubmit() {
    setIsLoading(true);
    callPostForgotPassword('emailSent');
  }

  const handleEmailValue = (event) => {
    event.persist();
    setUserEmail(event.target.value);
    if (event.target.validity.valid) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  function handleEmailResend() {
    setIsLoading(true);
    callPostForgotPassword('emailResent');
  }

  function handleStartOver() {
    setCurrentStep('enterEmail');
    setUserEmail('');
    setEmailValid(false);
    setError(null);
  }

  function handleKeyPress(e) {
    if (e.keyCode === 13 && emailValid === true) {
      e.preventDefault();
      handleEmailSubmit();
    }
  }

  return (
    <AuthLayout>
      {
        {
          enterEmail:
          (
            <Fragment>
              <Header mb="0.5em">You Forgot?</Header>
              <Text variant="thinPrompt" mb="1.5em">
                No problem. Enter your email and we will help you reset your password.
              </Text>
              <form autoComplete="on">
                <BaseInput
                  placeholder="you@yourdomain.com"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="on"
                  fluid
                  mb="1.5rem"
                  onChange={handleEmailValue}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
              </form>
              {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}
              <BaseButton mb="1.5em" btnText="Submit" onClick={() => handleEmailSubmit()} fluid isLoading={isLoading} disabled={!emailValid} />
              <Box display="flex" justifyContent="center">
                <Text><a href="/login">Back to Login</a></Text>
              </Box>
            </Fragment>
          ),
          emailSent:
          (
            <Fragment>
              <Box mb="1em" display="flex" alignItems="center">
                <MailWedge />
                <Header ml="1rem">Help is on the way...</Header>
              </Box>
              <Text variant="thinPrompt" mb="1.5em">
                If an account exists for the email you provided,
                you will receive a link and instructions.  We sent this to:
                <Text mt="0.5em" fontSize="1rem">{userEmail}</Text>
              </Text>
              <Box mb="0.5rem" display="flex" alignItems="center">
                <LinkButton mr="1em" btnText="Resend Email" onClick={() => handleEmailResend()} isLoading={isLoading} />
                <LinkButton btnText="Start Over" onClick={() => handleStartOver()} />
              </Box>
            </Fragment>
          ),
          emailResent:
          (
            <Fragment>
              <Box mb="1em" display="flex" alignItems="center">
                <MailWedge />
                <Header ml="1rem">On the way again...</Header>
              </Box>
              <Text variant="thinPrompt" mb="1.5em">
                We resent the email. This is usually pretty fast but give
                it a moment and check other mailboxes for:
                <Text mt="0.5em" fontSize="1rem">{userEmail}</Text>
              </Text>
              <Box mb="0.5rem" display="flex" alignItems="center">
                <LinkButton btnText="Start Over" onClick={() => handleStartOver()} />
              </Box>
            </Fragment>
          ),
        }[currentStep]
      }
    </AuthLayout>
  );
}

export default ForgotPassword;
