import { userRegisterUrl } from '../urls';

export async function getUserRegisterInfo(
  token,
  email,
) {
  const response = await fetch(`${userRegisterUrl}?${new URLSearchParams({
    token,
    email,
  })}`, {
    method: 'GET',
  });
  return response;
}

export async function postUserRegister(
  token,
  legalName,
  email,
  password,
  firstName,
  lastName,
) {
  const data = {
    token,
    legalName,
    email,
    password,
    firstName,
    lastName,
  };
  const response = await fetch(userRegisterUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response;
}
