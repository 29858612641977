import styled from 'styled-components';
import {
  space,
} from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const ProgressWrapper = styled.div(
  ({ showBackButton }) => `
  background: var(--panel-card);
  position: relative;
  width: 100%;
  border-radius: 0.5em;
  padding: 1em;
  border: 1px solid var(--border);
  ${space};


  @media screen and (max-width: ${pointBreaks.sm}) {
    ${showBackButton && 'padding-left: 1.5em;'}
}
`,
  space,
);

export const ProgressBar = styled.div`
  width: 100%;
  position: relative;
  height: 0.5em;
  border-radius: 0.5em;
  background: var(--panel-stat);
  overflow: hidden;
`;

export const ProgressFill = styled.div(
  ({
    width,
  }) => `
  width: ${width || '0'}%;
  position: absolute;
  transition: width 600ms ease-in-out;
  height: 0.5em;
  background: var(--blue-400);
  ${space};
`,
  space,
);

export const BackButton = styled.div`
  height: 2.5em;
  width: 2.5em;
  position: absolute;
  left: -3.25em;
  top: 0.75em;
  border: 1px solid var(--border);
  background: var(--panel-card);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  @media screen and (max-width: ${pointBreaks.sm}) {
    left: -1em;
    height: 2em;
    width: 2em;
    top: 1em;
    z-index: 400;
}
`;
