/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const CardHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--card-divider);
  margin-left: 1em;
  padding: 0.5em 1em 0.5em 0em;
  min-height: 2.625em;
`;

export const CardHeader = styled.div`
  font-size: 0.875em;
  font-weight: 500;
  color: var(--text-soft-mute);
`;

export const CardBodyWrapper = styled.div`
  padding: 1em;
`;
