import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'preact/hooks';
import AppState from '@state';

import AlertMessage from '@ui-kit/alert/Alert';
import Header from '@ui-kit/typography/header';
import Box from '@ui-kit/box';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import AddressTypeAhead from '@distinct-components/typeAheadInputs/addressTypeAhead';
import BaseTypeAhead from '@distinct-components/typeAheadInputs/baseTypeAhead';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import Text from '@ui-kit/typography/text';
import ArrowLeftIcon from '@assets/icons/arrow-left.svg';
import Icon from '@ui-kit/icon';

import { SOCIETIES } from '@constants/collectionSocieties';

import { postAccountPublisher } from '@api/restricted/account-publisher-api';
import loadAccountPublishers from '@actions/loadAccountPublishers';

import { ACCOUNT_EXPLANATIONS } from '@constants/supportingCopy';

const WriterSocieties = SOCIETIES.filter((s) => s.societyType === 'pro');

function ManagePublisherOverlayCreate({
  handleAddPublisher,
  setMode,
  usePlacesState,
}) {
  const {
    register, handleSubmit, setValue, trigger, formState: { isValid },
  } = useForm({ mode: 'onChange' });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [typeAheadValue, setTypeAheadValue] = useState('');

  useEffect(() => {
    register('address', { required: true });
    register('society', { required: true });
  }, []);

  const handleAddressSelected = (data) => {
    setValue('address', data);
    trigger('address');
    setTypeAheadValue(data);
  };

  const handleSocietySelected = (data) => {
    setValue('society', data);
    trigger('society');
  };

  const handleCreatePublisher = (data) => {
    const cleanedData = {
      name: data.name?.trim().split(/\s+/).join(' '),
      society: data.society,
      ipi: data.ipi,
      address: data.address?.trim().split(/\s+/).join(' '),
    };
    if (!cleanedData.name || !cleanedData.address) {
      setError('Invalid input');
    } else {
      setIsLoading(true);
      postAccountPublisher(cleanedData.name, cleanedData.society, cleanedData.ipi, cleanedData.address)
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            response.json().then(({ id }) => {
              setError(false);
              loadAccountPublishers().then(() => {
                if (Object.keys(AppState.publishers.value).length > 1) {
                  setMode('list');
                } else {
                  handleAddPublisher({ id });
                }
              });
            });
          } else {
            response.json()
              .then((json) => setError(json.error ? json.error : response.statusText))
              .catch(() => setError(response.statusText));
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err.message);
        });
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" className="cursor-p" onClick={() => setMode('list')} mb="1em">
        <Icon size="1.25em" cursor><ArrowLeftIcon /></Icon>
        <Text ml="0.5em">Go Back</Text>
      </Box>
      <Header mb="0.75em">Create Publisher</Header>
      <ConversationExplanation mb="2em" explanation={ACCOUNT_EXPLANATIONS.publishers.createExplanation} />
      <form onSubmit={handleSubmit(handleCreatePublisher)} autoComplete="off">
        <InputLabel label="Publisher Name" />
        <BaseInput
          {...register('name', { required: true })}
          id="name"
          name="name"
          placeholder="Name of Publisher"
          type="text"
          fluid
          mb="1.5rem"
        />
        <BaseTypeAhead
          label="Publishers Collection Society"
          id="societies"
          data={WriterSocieties}
          nameValue="name"
          handleChange={handleSocietySelected}
          initialValue=""
        />
        <InputLabel label="Publisher IPI" />
        <BaseInput
          {...register('ipi', { required: true })}
          id="ipi"
          name="ipi"
          placeholder="9-11 Digit IPI"
          type="text"
          inputMode="decimal"
          pattern="[0-9]+"
          maxLength="11"
          minLength="9"
          fluid
          mb="1.5rem"
        />
        <AddressTypeAhead
          placesState={usePlacesState}
          handleChange={handleAddressSelected}
          address={typeAheadValue}
          label="Publisher Address"
          id="pubAddress"
        />
        {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}
        <BaseButton mb="1.5em" type="submit" btnText="Save" disabled={!isValid} fluid isLoading={isLoading} />
      </form>
    </Box>
  );
}

ManagePublisherOverlayCreate.propTypes = {
  handleAddPublisher: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  usePlacesState: PropTypes.string.isRequired,
};

export default ManagePublisherOverlayCreate;
