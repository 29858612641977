import PropTypes from 'prop-types';

import { StyledLabel } from './InputLabelStyles';

function InputLabel({
  label,
  ...restProps
}) {
  return (
    <StyledLabel {...restProps}>{label}</StyledLabel>
  );
}

InputLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

export default InputLabel;
