import PropTypes from 'prop-types';
import { Fragment } from 'preact';

import Icon from '@ui-kit/icon';
import Text from '@ui-kit/typography/text';
import SwitchLogoBrand from '@assets/logos/switchchord-logo-auth.svg';
import SwitchLogo from '@assets/logos/switchchord-logo-main.svg';
import {
  AuthBrandPanel,
  AuthContainer,
  AuthContent,
  AuthContentWrapper,
  AuthFormWrapper,
  PromptWrapper,
  StyledPrompt,
  AuthMobileBrand,
  AuthBrandTitle,
  AuthBrandMessage,
  AuthSteamrollerMark,
  // AuthInfoBar,
} from './AuthLayoutStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function AuthLayout({
  children,
  prompt,
  action,
  actionRoute,
}) {
  return (
    <AuthContainer>
      <AuthBrandPanel>
        <Icon size="196px"><SwitchLogoBrand /></Icon>
        <AuthBrandTitle>Give your works identity</AuthBrandTitle>
        <AuthBrandMessage>
          Modern digital split sheets that form verifiable identities for the songs you write. Share these identities with 3rd Parties and Publishers.
        </AuthBrandMessage>
        <AuthSteamrollerMark>Powered by STEAMROLLER</AuthSteamrollerMark>
      </AuthBrandPanel>
      <AuthContentWrapper>
        {/* TEMPORARY BANNER */}
        {/* <AuthInfoBar>
          <span style={{ fontWeight: 600, paddingRight: ' 0.5em' }}>Public Beta:</span>
          Please reach out if you encounter issues as we solidify our official release.
        </AuthInfoBar> */}
        <PromptWrapper>
          <AuthMobileBrand><Icon size="172px"><SwitchLogo /></Icon></AuthMobileBrand>
          {prompt
          && (
            <Fragment>
              <StyledPrompt><Text>{prompt}</Text></StyledPrompt>
              <Text ml="0.938em"><a href={actionRoute}>{action}</a></Text>
            </Fragment>
          )}
        </PromptWrapper>
        <AuthContent>
          <AuthFormWrapper>
            {children}
          </AuthFormWrapper>
        </AuthContent>
      </AuthContentWrapper>
    </AuthContainer>
  );
}

AuthLayout.propTypes = {
  children: childrenPropTypes.isRequired,
  prompt: PropTypes.string,
  action: PropTypes.string,
  actionRoute: PropTypes.string,
};

AuthLayout.defaultProps = {
  prompt: null,
  action: null,
  actionRoute: null,
};

export default AuthLayout;
