import Box from '@ui-kit/box';
import ProfileWriterSection from './sections/profileWriterSection';
// import ProfilePerformerSection from './sections/profilePerformerSection';
import ProfileISNISection from './sections/profileISNISection';
import ProfilePublisherSection from './sections/profilePublisherSection';
import { SectionsGrid } from './ProfilePrimaryModeStyles';

function ProfilePrimaryMode() {
  return (
    <SectionsGrid>
      <ProfilePublisherSection />
      <Box>
        <ProfileWriterSection />
        {/* <ProfilePerformerSection /> */}
        <ProfileISNISection />
      </Box>
    </SectionsGrid>
  );
}

export default ProfilePrimaryMode;
