import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const EventWrapper = styled.div(({
  clickable,
}) => `
    padding: 0.625em 0em;
    border-bottom: 1px solid var(--panel-divider);
    cursor: ${clickable ? 'pointer' : 'default'};

    &:last-child {
        border-bottom: none;
    }

    & time {
        font-size: 0.75rem;
        color: var(--text-medium-mute);
    }
`);

export const StyledEventListWrapper = styled.div`
    height: 100%;
    overflow-y: scroll;

    @media screen and (max-width: ${pointBreaks.sm}) {
        max-height: auto;
    }
`;

export const EventIconWrapper = styled.div`
    margin-right: 0.75em;
    margin-top: 0.125em;
    display: flex;
    justify-content: center;
`;
