import PropTypes from 'prop-types';
import { useEffect, useRef } from 'preact/hooks';

import CorePublisherHeader from '@distinct-components/navigation/corePublisherHeader';
import CoreSideNav from '@distinct-components/navigation/coreSideNav/CoreSideNav';

import {
  CoreWrapper,
  CorePageWrapper,
  CoreAlertWrapper,
  MockAlert,
  CoreHeaderWrapper,
  CoreWorkSpaceWrapper,
  CoreContentWrapper,
} from './CorePublisherLayoutStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function CorePublisherLayout({
  children,
  globalAlert,
}) {
  const topRef = useRef();
  useEffect(() => {
    topRef.current.scrollIntoView();
    document.body.style.backgroundColor = 'var(--background)';
  }, []);

  return (
    <CoreWrapper>
      <CorePageWrapper ref={topRef}>
        {globalAlert
        && (
        <CoreAlertWrapper>
          <MockAlert>{globalAlert}</MockAlert>
        </CoreAlertWrapper>
        )}
        <CoreHeaderWrapper globalAlert={globalAlert}>
          <CorePublisherHeader />
        </CoreHeaderWrapper>
        <CoreWorkSpaceWrapper>
          <CoreSideNav variant="traditional" mode="publisher" globalAlert={globalAlert} />
          <CoreContentWrapper>
            {children}
          </CoreContentWrapper>
        </CoreWorkSpaceWrapper>
      </CorePageWrapper>
    </CoreWrapper>
  );
}

CorePublisherLayout.propTypes = {
  children: childrenPropTypes.isRequired,
  globalAlert: PropTypes.string,
};

CorePublisherLayout.defaultProps = {
  globalAlert: null,
};

export default CorePublisherLayout;
