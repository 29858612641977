/* eslint-disable object-shorthand */
/* eslint-disable react/style-prop-object */
import anime from 'animejs/lib/anime.es';
import Box from '@ui-kit/box';
import { useEffect } from 'preact/hooks';

function SwitchMachine() {
  const machineAnimation = anime.timeline({
    autoplay: true,
    loop: true,
    direction: 'alternate',
  });

  const wave1 = 'm214 104.575 5.396-9.081c1.742 10.615 2.613 15.526 2.613 14.732 0-.793 1.263-6.793 3.79-18.001l2.94 18.072 2.94-6.579 3.73-7.493 4.066 7.35 4.098-7.536L249 104.11';
  const wave2 = 'm214 104.575 5.396-9.081c1.742 10.615 2.613 15.526 2.613 14.732 0-.793 1.263-6.793 3.79-18.001l2.94 14.072 2.94-6.579 3.43-7.493 4.066 12.35 4.098-7.536L249 104.11';

  useEffect(() => {
    anime({
      targets: '#crazy-line',
      d: [
        { value: [wave1, wave2] },
      ],
      loop: true,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      duration: 1500,
      delay: 1500,
      direction: 'alternate',
    });

    anime({
      targets: '#blinker',
      opacity: [1, 0],
      loop: true,
      delay: 2000,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      duration: 500,
      direction: 'alternate',
    });

    machineAnimation
      .add({
        targets: '#t-knob',
        elasticity: 400,
        duration: 1800,
        direction: 'normal',
        rotate: [-20, 120],
      }, '+=1000').add({
        targets: '#b-knob',
        elasticity: 400,
        duration: 1300,
        rotate: [40, -88],
      })
      .add({
        targets: '#blinker',
        duration: 500,
      })
      .add({
        targets: '#machine #fader #knob',
        translateY: anime.stagger([-10, 16, -12]),
        duration: 800,
      }, '-=3800');
  }, []);

  return (
    <Box>
      <svg xmlns="http://www.w3.org/2000/svg" width="190" height="100%" viewBox="0 0 270 144">
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-88 -124)">
            <g id="machine" transform="translate(88 124)">
              <rect id="core-square" width="270" height="144" x="0" y="0" fill="#5620CA" rx="5" />
              <g id="TOP_KNOB" transform="translate(160 24)">
                <g id="t-knob" style="transform-box: fill-box; transform-origin: 50% 50%">
                  <ellipse id="Oval" cx="18.5" cy="18.704" fill="#9069FF" rx="18.5" ry="18.704" />
                  <ellipse id="Oval" cx="18.5" cy="18.704" fill="#D1C1FF" rx="9.584" ry="9.69" />
                  <rect id="Rectangle" width="1.783" height="5.859" x="17.831" y="11.268" fill="#381A78" rx=".892" />
                </g>
              </g>
              <g id="Bottom-Knob" transform="translate(160 82.592)">
                <g id="b-knob" style="transform-box: fill-box; transform-origin: 50% 50%">
                  <ellipse id="Oval-Copy-3" cx="18.5" cy="18.704" fill="#9069FF" rx="18.5" ry="18.704" />
                  <ellipse id="Oval-Copy-4" cx="18.5" cy="18.704" fill="#D1C1FF" rx="9.584" ry="9.69" />
                  <rect id="Rectangle-Copy" width="1.783" height="5.859" x="17.831" y="11.268" fill="#381A78" rx=".892" />
                </g>
              </g>
              <ellipse id="Oval-Copy-5" cx="194.325" cy="59.606" fill="#9069FF" rx="1.337" ry="1.352" />
              <ellipse id="Oval-Copy-8" cx="194.325" cy="118.197" fill="#9069FF" rx="1.337" ry="1.352" />
              <ellipse id="Oval-Copy-6" cx="163.566" cy="59.606" fill="#9069FF" rx="1.337" ry="1.352" />
              <ellipse id="Oval-Copy-7" cx="163.566" cy="118.197" fill="#9069FF" rx="1.337" ry="1.352" />
              <rect id="Rectangle" width="4" height="70.329" x="26" y="37" fill="#381A78" rx="2" />
              <rect id="Rectangle-Copy-5" width="4" height="70.329" x="107" y="37" fill="#381A78" rx="2" />
              <rect id="Rectangle-Copy-2" width="4" height="70.329" x="45" y="37" fill="#381A78" rx="2" />
              <g id="fader" style="transform-box: fill-box; transform-origin: 50% 50%" transform="translate(21 58.6)">
                <g id="knob" style="transform-box: fill-box; transform-origin: 50% 50%; transform: translateY(0px);">
                  <ellipse id="Oval" cx="7" cy="7.033" fill="#9069FF" rx="7" ry="7.033" />
                  <ellipse id="Oval-Copy" cx="7" cy="7.033" fill="#D1C1FF" rx="2.5" ry="2.512" />
                </g>
              </g>
              <g id="fader" style="transform-box: fill-box; transform-origin: 50% 50%" transform="translate(102 58.6)">
                <g id="knob" style="transform-box: fill-box; transform-origin: 50% 50%; transform: translateY(25px);">
                  <ellipse id="Oval-Copy-22" cx="7" cy="7.033" fill="#9069FF" rx="7" ry="7.033" />
                  <ellipse id="Oval-Copy-24" cx="7" cy="7.033" fill="#D1C1FF" rx="2.5" ry="2.512" />
                </g>
              </g>
              <g id="fader" transform="translate(40 58.6)">
                <g id="knob" style="transform-box: fill-box; transform-origin: 50% 50%; transform: translateY(18px);">
                  <ellipse id="Oval-Copy-10" cx="7" cy="7.033" fill="#9069FF" rx="7" ry="7.033" />
                  <ellipse id="Oval-Copy-11" cx="7" cy="7.033" fill="#D1C1FF" rx="2.5" ry="2.512" />
                </g>
              </g>
              <ellipse id="Oval-Copy-2" cx="34" cy="39.009" fill="#9069FF" rx="1.5" ry="1.507" />
              <ellipse id="Oval-Copy-26" cx="115" cy="39.009" fill="#9069FF" rx="1.5" ry="1.507" />
              <ellipse id="blinker" cx="53" cy="39.009" fill="#9069FF" rx="1.5" ry="1.507" />
              <path id="Oval-Copy-9" fill="#9069FF" fillRule="nonzero" d="M34 104.315c.828 0 1.5.674 1.5 1.507 0 .832-.672 1.507-1.5 1.507s-1.5-.675-1.5-1.507c0-.833.672-1.507 1.5-1.507Zm0 .5c-.552 0-1 .45-1 1.007 0 .556.448 1.007 1 1.007s1-.45 1-1.007c0-.557-.448-1.007-1-1.007Z" />
              <path id="Oval-Copy-28" fill="#9069FF" fillRule="nonzero" d="M115 104.315c.828 0 1.5.674 1.5 1.507 0 .832-.672 1.507-1.5 1.507s-1.5-.675-1.5-1.507c0-.833.672-1.507 1.5-1.507Zm0 .5c-.552 0-1 .45-1 1.007 0 .556.448 1.007 1 1.007s1-.45 1-1.007c0-.557-.448-1.007-1-1.007Z" />
              <path id="Oval-Copy-13" fill="#9069FF" fillRule="nonzero" d="M53 104.315c.828 0 1.5.674 1.5 1.507 0 .832-.672 1.507-1.5 1.507s-1.5-.675-1.5-1.507c0-.833.672-1.507 1.5-1.507Zm0 .5c-.552 0-1 .45-1 1.007 0 .556.448 1.007 1 1.007s1-.45 1-1.007c0-.557-.448-1.007-1-1.007Z" />
              <rect id="Rectangle-Copy-3" width="4" height="70.329" x="64" y="37" fill="#381A78" rx="2" />
              <g id="fader" transform="translate(59 58.6)">
                <g id="knob" style="transform-box: fill-box; transform-origin: 50% 50%; transform: translateY(-8px);">
                  <ellipse id="Oval-Copy-17" cx="7" cy="7.033" fill="#9069FF" rx="7" ry="7.033" />
                  <ellipse id="Oval-Copy-16" cx="7" cy="7.033" fill="#D1C1FF" rx="2.5" ry="2.512" />
                </g>
              </g>
              <ellipse id="Oval-Copy-15" cx="72" cy="39.009" fill="#9069FF" rx="1.5" ry="1.507" />
              <path id="Oval-Copy-14" fill="#9069FF" fillRule="nonzero" d="M72 104.315c.828 0 1.5.674 1.5 1.507 0 .832-.672 1.507-1.5 1.507s-1.5-.675-1.5-1.507c0-.833.672-1.507 1.5-1.507Zm0 .5c-.552 0-1 .45-1 1.007 0 .556.448 1.007 1 1.007s1-.45 1-1.007c0-.557-.448-1.007-1-1.007Z" />
              <rect id="Rectangle-Copy-4" width="4" height="70.329" x="84" y="37" fill="#381A78" rx="2" />
              <g id="fader" transform="translate(79 58.6)">
                <g id="knob" style="transform-box: fill-box; transform-origin: 50% 50%; transform: translateY(-8px);">
                  <ellipse id="Oval-Copy-21" cx="7" cy="7.033" fill="#9069FF" rx="7" ry="7.033" />
                  <ellipse id="Oval-Copy-20" cx="7" cy="7.033" fill="#D1C1FF" rx="2.5" ry="2.512" />
                </g>
              </g>
              <ellipse id="blinker" cx="92" cy="39.009" fill="#9069FF" rx="1.5" ry="1.507" />
              <path id="Oval-Copy-18" fill="#9069FF" fillRule="nonzero" d="M92 104.315c.828 0 1.5.674 1.5 1.507 0 .832-.672 1.507-1.5 1.507s-1.5-.675-1.5-1.507c0-.833.672-1.507 1.5-1.507Zm0 .5c-.552 0-1 .45-1 1.007 0 .556.448 1.007 1 1.007s1-.45 1-1.007c0-.557-.448-1.007-1-1.007Z" />
              <circle id="Oval-Copy-23" cx="261" cy="9" r="3" fill="#3C158F" />
              <circle id="Oval-Copy-27" cx="261" cy="136" r="3" fill="#3C158F" />
              <circle id="Oval-Copy-25" cx="8" cy="9" r="3" fill="#3C158F" />
              <circle id="Oval-Copy-29" cx="8" cy="136" r="3" fill="#3C158F" />
              <path id="Rectangle-Copy-6" fill="#381A78" d="M138 13h2v118h-2z" />
              <path id="Rectangle-Copy-60" fill="#9069FF" d="M219.938 26h26.422a3.938 3.938 0 0 1 3.938 3.938V54.062A3.938 3.938 0 0 1 246.36 58h-26.422A3.938 3.938 0 0 1 216 54.062V29.938A3.938 3.938 0 0 1 219.938 26Z" transform="matrix(1 0 0 -1 0 84)" />
              <path id="Rectangle-Copy-61" fill="#5620CA" d="M230.76 28h13.64a3.938 3.938 0 0 1 3.938 3.938V52.062A3.938 3.938 0 0 1 244.4 56h-22.502a3.938 3.938 0 0 1-3.938-3.938V40.8c0-7.07 5.73-12.8 12.8-12.8Z" transform="matrix(1 0 0 -1 0 84)" />
              <path id="Combined-Shape" fill="#AC95EE" fillRule="nonzero" d="M228.74 40.222c1.623 0 2.94-1.343 2.94-3 0-1.656-1.317-3-2.94-3-1.624 0-2.94 1.344-2.94 3 0 1.657 1.316 3 2.94 3Zm0-1.392c-.774 0-1.454-.695-1.454-1.608 0-.912.68-1.607 1.453-1.607s1.454.695 1.454 1.607c0 .913-.68 1.608-1.454 1.608Zm7.839-4.608c1.374 0 2.527.962 2.85 2.261l8.88.001v1.477h-8.88c-.323 1.3-1.476 2.261-2.85 2.261-1.624 0-2.94-1.343-2.94-3 0-1.656 1.316-3 2.94-3Zm0 1.393c-.786 0-1.47.698-1.47 1.607 0 .91.684 1.608 1.47 1.608.786 0 1.47-.698 1.47-1.608 0-.91-.684-1.607-1.47-1.607Zm-7.84 12.607c1.624 0 2.94-1.343 2.94-3 0-1.656-1.316-3-2.94-3-1.623 0-2.94 1.344-2.94 3 0 1.657 1.317 3 2.94 3Zm0-1.392c-.773 0-1.453-.695-1.453-1.608 0-.912.68-1.607 1.453-1.607s1.454.695 1.454 1.607c0 .913-.68 1.608-1.454 1.608Zm10.69-.868c-.323 1.3-1.476 2.26-2.85 2.26-1.624 0-2.94-1.343-2.94-3 0-1.656 1.316-3 2.94-3 1.374 0 2.527.962 2.85 2.261l1.663.001a2.708 2.708 0 0 1 2.703 2.543l.005.165v8.801h-1.477v-8.801a1.23 1.23 0 0 0-1.105-1.225l-.126-.006Zm-2.85-2.347c-.786 0-1.47.698-1.47 1.607 0 .91.684 1.608 1.47 1.608.786 0 1.47-.698 1.47-1.608 0-.91-.684-1.607-1.47-1.607Z" />
              <rect id="Rectangle" width="34" height="41" x="214.5" y="81" fill="#381B77" opacity=".661" rx="6" transform="rotate(-90 231.5 101.5)" />
              <rect id="Rectangle-Copy-7" width="30" height="36.568" x="216.5" y="83.216" fill="#381B77" opacity=".661" rx="4" transform="rotate(-90 231.5 101.5)" />
              <path id="crazy-line" stroke="#979797" strokeLinecap="round" strokeLinejoin="round" d="m214 104.575 5.396-9.081c1.742 10.615 2.613 15.526 2.613 14.732 0-.793 1.263-6.793 3.79-18.001l2.94 14.072 2.94-6.579 3.43-7.493 4.066 12.35 4.098-7.536L249 104.11" />
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );
}

export default SwitchMachine;
