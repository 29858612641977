import { useEffect, useState, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import { route } from 'preact-router';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import AppState from '@state';
import { Fragment } from 'preact';

import loadAccountProfile from '@actions/loadAccountProfile';
import loadComposition from '@actions/loadComposition';
import loadWorkspaceAccounts from '@actions/loadWorkspaceAccounts';

import { postWorkspaceConfirm } from '@api/restricted/workspace-confirm-api';

import useClickOutside from '@hooks/useClickOutside';
import usePollForData from '@hooks/usePollForData';

import ChevronIcon from '@assets/icons/chevron-down.svg';
import CloseIcon from '@assets/icons/close-solo.svg';
import ShareIcon from '@assets/icons/share-icon.svg';
import SoloLogo from '@assets/logos/logo-symbol.svg';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import Box from '@ui-kit/box';
import BreakLine from '@ui-kit/dividers/breakLine';
import Header from '@ui-kit/typography/header';
import Icon from '@ui-kit/icon';
import InputLabel from '@ui-kit/inputs/inputLabel';
import PopoverMenu from '@ui-kit/menu/popoverMenu';
import Spinner from '@ui-kit/loaders/Spinner';
import Tabs from '@ui-kit/tabs/Tabs';
import Text from '@ui-kit/typography/text';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';

import WorkspaceEditOverlay from '@layouts/full-screen/workspaceEditOverlay';

import CompositionMode from './modes/compositionMode/CompositionMode';
import LeaveWorkspace from './modes/sharedSections/leaveWorkspace';
import DeleteWorkspace from './modes/sharedSections/deleteWorkspace';
import ShareWorkspace from './modes/sharedSections/shareWorkspace';

import {
  WorkspaceWrapper,
  HeaderWrapper,
  HeaderDetails,
  HeaderActions,
  NavigationWrapper,
  NavigationTabs,
  PageWrapper,
  PageContent,
  WorkItemsWrapper,
  StyledHeaderButton,
  ActionMenuItem,
  MobileHeaderContent,
  HeaderContent,
  MobileTitleWrapper,
} from './WorkspaceStyles';

function Workspace({ id }) {
  const [workspaceMode, setWorkspaceMode] = useState(null);
  const [showJoinWorkspace, setShowJoinWorkspace] = useState(false);
  const [workspacePersona, setWorkspacePersona] = useState(null);
  const [acceptSuggestedIPI, setAcceptSuggestedIPI] = useState(true);
  const [acceptSuggestedSociety, setAcceptSuggestedSociety] = useState(true);
  const [isJoining, setIsJoining] = useState(false);
  const [joinWorkspaceError, setJoinWorkspaceError] = useState(null);
  const [isLoadingWorkspace, setIsLoadingWorkspace] = useState(true);
  const [workspaceTab, setWorkspaceTab] = useState('Main');
  const isChildEditing = AppState.workspace.isEditingChild.value;
  const workspaceId = parseInt(id, 10);
  const [showActions, setShowActions] = useState(false);
  const [showMobileActions, setShowMobileActions] = useState(false);
  const [showLeaveWorkspace, setShowLeaveWorkspace] = useState(false);
  const [showDeleteWorkspace, setShowDeleteWorkspace] = useState(false);
  const [activeUser, setActiveUser] = useState({});
  const [showShareWorkspace, setShowShareWorkspace] = useState(false);

  const ref = useRef();
  const mobileRef = useRef();
  useClickOutside(ref, () => setShowActions(false));
  useClickOutside(mobileRef, () => setShowMobileActions(false));

  const loadWorkspace = async () => {
    await loadAccountProfile();
    await loadComposition(workspaceId);
    await loadWorkspaceAccounts(workspaceId);
    const currentUserId = AppState.accountId.value;
    const workspaceAccounts = AppState.workspaceAccounts.value;
    const currentUser = workspaceAccounts.find((user) => user.id === currentUserId);
    setActiveUser(currentUser);
    setShowJoinWorkspace(!currentUser.hasJoined);
  };

  const handlePollForData = () => {
    loadWorkspaceAccounts(workspaceId);
  };

  usePollForData(true, 5000, handlePollForData, false);

  useEffect(() => {
    document.title = 'Composition';
    document.body.style.backgroundColor = 'var(--background)';
    AppState.media.currentlyPlaying.value = null;
    AppState.workspaceInvitesToSend.value = [];
    setWorkspaceMode('composition');

    scrollIntoView(document.querySelector('#scrollPoint'), { behavior: 'instant', block: 'center', inline: 'center' });

    loadWorkspace().then(() => {
      setIsLoadingWorkspace(false);
    });
  }, []);

  const handleTabChange = (tab) => {
    setWorkspaceTab(tab.value);
  };

  const onToggleLeaveWorkspace = () => {
    setShowLeaveWorkspace(!showLeaveWorkspace);
  };

  const onToggleDeleteWorkspace = () => {
    setShowDeleteWorkspace(!showDeleteWorkspace);
  };

  const onCloseWorkspace = () => {
    if (AppState.navigation.backActionPath.value != null) {
      route(AppState.navigation.backActionPath.value);
    } else {
      route('/compositions');
    }
  };

  const onToggleShareWorkspace = () => {
    setShowShareWorkspace(!showShareWorkspace);
  };

  const onClickJoinWorkspace = () => {
    setIsJoining(true);
    setJoinWorkspaceError(null);

    postWorkspaceConfirm(
      workspaceId,
      null,
      workspacePersona ? parseInt(workspacePersona, 10) : null,
      (activeUser.suggestions?.ipi && activeUser.suggestions.ipi !== AppState.artistProfile.ipi.value && acceptSuggestedIPI)
        ? activeUser.suggestions.ipi
        : null,
      (activeUser.suggestions?.society && activeUser.suggestions.society.name !== AppState.artistProfile.swCollSociety.value[0]?.name && acceptSuggestedSociety)
        ? activeUser.suggestions.society
        : null,
    )
      .then((response) => {
        if (response.status === 200 || response.status === 202) {
          setIsLoadingWorkspace(true);
          setTimeout(() => {
            loadWorkspace().then(() => {
              setIsJoining(false);
              setJoinWorkspaceError(null);
              setIsLoadingWorkspace(false);
            });
          }, 1200);
        } else {
          response.json().then((json) => {
            setJoinWorkspaceError(json.error || response.statusText);
          });
        }
      })
      .catch((err) => {
        setIsJoining(true);
        setJoinWorkspaceError(err.message);
      });
  };

  const onClickCancelJoinWorkspace = () => {
    route('/home');
  };

  return (
    showJoinWorkspace ? (
      <WorkspaceEditOverlay
        formWidth="23em"
        formCentered
      >
        <Header mb="0.25em">
          Join:
          {' '}
          {AppState.composition.name.value}
        </Header>
        <Text variant="thinPrompt" mb="1.5em">By clicking Continue, you confirm that you are a participant.</Text>

        {AppState.aliases.value.length > 0 && (
        <Fragment>
          <InputLabel label="Use a Persona (Optional)" />
          <BaseSelect
            onChange={(e) => setWorkspacePersona(e.target.value)}
            id="persona"
            name="persona"
            fluid
            mb="1.5rem"
          >
            <option value="" default selected>
              None (
              {AppState.userProfile.firstName.value}
              {' '}
              {AppState.userProfile.lastName.value}
              )
            </option>
            {AppState.aliases.value.map((a) => (
              <option value={a.id}>{a.name}</option>
            ))}
          </BaseSelect>
        </Fragment>
        )}

        {(activeUser.suggestions?.society?.name && activeUser.suggestions.society.name !== AppState.artistProfile.swCollSociety.value[0]?.name) && (
        <Box>
          <BreakLine mb="1.5em" color="var(--panel-info-border)" />
          <Text fontWeight="800">Suggested Collection Society</Text>
          <Text fontSize="1.67em" mb="0.125em" fontWeight="200" color={acceptSuggestedSociety ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}>
            {activeUser.suggestions.society.name}
          </Text>
          <Box display="flex" justifyContent="flex-end" mb="1.5em">
            <Text mr="1.25em" fontSize="0.75rem" color="var(--text-primary)">Add to Profile</Text>
            <ToggleSwitch id="society">
              <input
                type="checkbox"
                name="society"
                id="society"
                onChange={() => setAcceptSuggestedSociety(!acceptSuggestedSociety)}
                checked={acceptSuggestedSociety}
              />
            </ToggleSwitch>
          </Box>
        </Box>
        )}

        {(activeUser.suggestions?.ipi && activeUser.suggestions.ipi !== AppState.artistProfile.ipi.value) && (
        <Box>
          <BreakLine mb="1.5em" color="var(--panel-info-border)" />
          <Text fontWeight="800">Suggested IPI Number</Text>
          <Text fontSize="1.67em" mb="0.125em" fontWeight="200" color={acceptSuggestedIPI ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}>
            {activeUser.suggestions.ipi}
          </Text>
          <Box display="flex" justifyContent="flex-end" mb="1.5em">
            <Text mr="1.25em" fontSize="0.75rem" color="var(--text-primary)">Add to Profile</Text>
            <ToggleSwitch id="ipi">
              <input
                type="checkbox"
                name="ipi"
                id="ipi"
                onChange={() => setAcceptSuggestedIPI(!acceptSuggestedIPI)}
                checked={acceptSuggestedIPI}
              />
            </ToggleSwitch>
          </Box>
        </Box>
        )}

        <Box mt="2px">
          {joinWorkspaceError && (
          <AlertMessage
            variant="negative"
            message={joinWorkspaceError}
            mb="1.5em"
          />
          )}

          <Box>
            <BaseButton
              mb="1.5em"
              btnText="Join Composition"
              onClick={onClickJoinWorkspace}
              disabled={isJoining}
              isLoading={isJoining}
              fluid
            />
            <BaseButton
              mb="1.5em"
              btnText="Cancel"
              variant="secondary"
              onClick={onClickCancelJoinWorkspace}
              disabled={isJoining}
              fluid
            />
          </Box>
        </Box>
      </WorkspaceEditOverlay>
    ) : (
      <WorkspaceWrapper isEditing={isChildEditing}>
        <HeaderWrapper id="scrollPoint">
          <MobileHeaderContent>
            <Box justifySelf="flexStart" flex="0">
              <StyledHeaderButton
                onClick={onCloseWorkspace}
              >
                <Icon cursor size="0.75em" style={{ transform: 'rotate(90deg)' }}><ChevronIcon /></Icon>
              </StyledHeaderButton>
            </Box>
            <MobileTitleWrapper>
              <Text fontWeight="700" fontSize="0.938rem" color="var(--text-primary)">Composition</Text>
            </MobileTitleWrapper>
            {!isLoadingWorkspace && (
            <Box display="flex" justifySelf="flex-end" justifyContent="flex-end" position="relative">
              <StyledHeaderButton mr="1em" onClick={onToggleShareWorkspace}>
                <Box display="flex">
                  <Icon cursor size="0.938em" pt="4px"><ShareIcon /></Icon>
                </Box>
              </StyledHeaderButton>
              <StyledHeaderButton onClick={() => setShowMobileActions(true)}>
                <Box display="flex">
                  <svg width="14" height="4"><path fill="var(--icon)" fillRule="evenodd" d="M4 2a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm5 0a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm5 0a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" /></svg>
                </Box>
              </StyledHeaderButton>
              {showMobileActions
              && (
              <PopoverMenu ref={mobileRef} transform="translate(0px,50px)">
                <ActionMenuItem onClick={onToggleLeaveWorkspace}>Leave Workspace</ActionMenuItem>
                {activeUser.isAdmin && <ActionMenuItem color="var(--red-300)" onClick={onToggleDeleteWorkspace}>Delete Workspace</ActionMenuItem>}
              </PopoverMenu>
              )}
            </Box>
            )}
          </MobileHeaderContent>
          <HeaderContent>
            <HeaderDetails className="cursor-p" onClick={onCloseWorkspace}>
              <Icon pt="0.188em" cursor><SoloLogo /></Icon>
              <Text fontWeight="700" ml="0.75em" fontSize="1em" color="var(--text-primary)">Composition</Text>
            </HeaderDetails>
            <HeaderActions>
              {true && (
              <Fragment>
                <StyledHeaderButton mr="1em" onClick={onToggleShareWorkspace}>
                  <Box display="flex">
                    <Icon cursor size="0.875em" pt="4px" mr="0.5em"><ShareIcon /></Icon>
                    <Text fontWeight="600">Share</Text>
                  </Box>
                </StyledHeaderButton>
                <StyledHeaderButton onClick={() => setShowActions(true)}>
                  <Box display="flex">
                    <Text mr="0.25em" fontWeight="600">Actions</Text>
                    <Icon cursor size="0.75em"><ChevronIcon /></Icon>
                  </Box>
                </StyledHeaderButton>
                {showActions && (
                <PopoverMenu ref={ref} transform="translate(-58px,76px)">
                  <ActionMenuItem onClick={onToggleLeaveWorkspace}>Leave Workspace</ActionMenuItem>
                  {activeUser.isAdmin && <ActionMenuItem color="var(--red-300)" onClick={onToggleDeleteWorkspace}>Delete Workspace</ActionMenuItem>}
                </PopoverMenu>
                )}
              </Fragment>
              )}
              <StyledHeaderButton
                ml="0.875em"
                onClick={onCloseWorkspace}
              >
                <Icon cursor size="0.75em"><CloseIcon /></Icon>
              </StyledHeaderButton>

            </HeaderActions>
          </HeaderContent>
        </HeaderWrapper>
        {isLoadingWorkspace ? (
          <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
            <Spinner size="2em" variant="page" />
          </Box>
        ) : (
          <PageWrapper>
            <PageContent>
              <NavigationWrapper>
                <NavigationTabs>
                  <Tabs
                    tabs={[{ name: 'Ownership', value: 'Main' }, { name: 'Lyrics & Files', value: 'Proof' }, { name: 'Samples', value: 'OtherData' }, { name: 'Activity', value: 'Activity' }]}
                    handleTabChange={handleTabChange}
                    selectedTab={workspaceTab}
                  />
                </NavigationTabs>
              </NavigationWrapper>
              <WorkItemsWrapper style={AppState.media.currentlyPlaying.value && { marginBottom: '6.5em' }}>
                {{
                  composition:
                  (
                    <CompositionMode
                      workspaceTab={workspaceTab}
                      workspaceId={workspaceId}
                      activeUser={activeUser}
                    />
                  ),
                  recording: <Text>RECORDING SPECIFIC ELEMENTS</Text>,
                }[workspaceMode]}
              </WorkItemsWrapper>
            </PageContent>
          </PageWrapper>
        )}

        {showLeaveWorkspace
        && <LeaveWorkspace closeFunction={onToggleLeaveWorkspace} activeUser={activeUser} />}
        {showDeleteWorkspace
        && <DeleteWorkspace closeFunction={onToggleDeleteWorkspace} />}
        {showShareWorkspace
        && <ShareWorkspace closeFunction={onToggleShareWorkspace} />}
      </WorkspaceWrapper>
    )

  );
}

Workspace.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Workspace;
