/* eslint-disable import/prefer-default-export */
import { artistCollaboratorsUrl } from '../urls';
import refresh from '../refresh';

export async function getArtistRecentCollaborators(workspaceId, limit) {
  const url = `${artistCollaboratorsUrl}?${new URLSearchParams({
    workspace: workspaceId,
    limit,
  })}`;
  return refresh(async () => {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}
