/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import Box from '@ui-kit/box';

import {
  StyledLabel,
  StyledData,
} from './IdentifierDataRowStyles';

function EventList({
  writer,
  ...restProps
}) {
  return (
    <Box display="flex" flexWrap="wrap" {...restProps}>
      <Box mb="0.25em" mr="0.75em">
        <StyledLabel>Society:</StyledLabel>
      &nbsp;
        <StyledData>{writer.societies || 'Not Defined'}</StyledData>
      </Box>
      <Box mb="0.25em" mr="0.75em">
        <StyledLabel>IPI:</StyledLabel>
      &nbsp;
        <StyledData>{writer.ipi || 'Not Defined'}</StyledData>
      </Box>
      <Box mb="0.25em" mr="0.75em">
        <StyledLabel>ISNI:</StyledLabel>
      &nbsp;
        <StyledData>{writer.isni || 'Not Defined'}</StyledData>
      </Box>
    </Box>
  );
}

EventList.propTypes = {
  writer: PropTypes.object.isRequired,
};

export default EventList;
