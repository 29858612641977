import { route } from 'preact-router';
import { useState, useEffect, useRef } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import useIsoDateFormat from '@hooks/useIsoDateFormat';
import useWindowDimensions from '@hooks/useWindowDimensions';

import CoreLayout from '@layouts/core/';
import PageCard from '@layouts/pageCard';

import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import ContractIcon from '@assets/illustrations/contract-chip.svg';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TableControls from '@ui-kit/table/tableControls';
import TablePagination from '@ui-kit/table/tablePagination';

import { CONTRACT_EXPLANATIONS } from '@constants/supportingCopy';

import loadAccountContracts from '@actions/loadAccountContracts';

import { TableIllustrationWrapper, TableSearchEmptyPrompt } from './ContractsStyles';

function Contracts() {
  const [isLoadingContracts, setIsLoadingContracts] = useState(true);
  const [allContracts, setAllContracts] = useState([]);
  const [searchedContracts, setSearchedContracts] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(AppState.pagination.contractPage.value);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  const formatDate = useIsoDateFormat;

  useEffect(() => {
    document.title = 'Split Sheets';
    if (height < 1080) {
      setItemsPerPage(7);
    }

    loadAccountContracts().then(() => {
      const contractsExecuted = AppState.contracts.value.filter((c) => c.totalParties === c.totalPartiesSigned);
      setAllContracts(contractsExecuted);
      setSearchedContracts(contractsExecuted);
      setSearchActive(false);
      setIsLoadingContracts(false);
    });
  }, []);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = searchedContracts.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    setCurrentPage(1);
    if (query) {
      setSearchedContracts(allContracts.filter((c) => c.compositionName.toLocaleLowerCase().includes(query.toLocaleLowerCase())));
      setSearchActive(true);
    } else {
      setSearchedContracts(allContracts);
      setSearchActive(false);
    }
  };

  const goToContract = (id, version) => {
    route(`/contracts/contract/${id}/${version}`);
    AppState.navigation.backActionPath.value = '/contracts';
    AppState.pagination.contractPage.value = currentPage;
  };

  return (
    <CoreLayout>
      <PageCard
        title="Completed Split Sheets"
        isLoadingPage={isLoadingContracts}
      >
        <div ref={pageRef} />
        {allContracts.length === 0 ? (
          <Card variant="panel" mt="1.5em">
            <BaseEmptyState
              title="No Completed Split Sheets"
              message={CONTRACT_EXPLANATIONS.list.emptyState}
              illustration="contracts"
            />
          </Card>
        ) : (
          <Fragment>
            <TableControls
              itemName="Split Sheet"
              tableData={allContracts}
              currentResults={currentResults}
              indexOfFirst={indexOfFirst}
              indexOfLast={indexOfLast}
              showSearch
              searchActive={searchActive}
              searchResults={searchedContracts}
              handleSearch={onSearchResults}
            />
            <Table mb="1em">
              <TableHeader>
                <TableCell widthPercent="48%" pr="0.75em">Contract</TableCell>
                <TableCell>Executed on</TableCell>
                <TableCell>Songwriters</TableCell>
                <TableCell>Status</TableCell>
              </TableHeader>
              {currentResults && currentResults
                .map((contract) => (
                  <TableRow onClick={() => { goToContract(contract.id, contract.version); }} cursor>
                    <TableCell widthPercent="48%" pr="0.75em">
                      <TableIllustrationWrapper>
                        <Icon className="icon" size="3.25em" pt="2px" mr="1em"><ContractIcon /></Icon>
                        <Box pt="0.25rem">
                          <Text fontSize="0.938rem" color="var(--text-primary)" fontWeight="600" maxWidth="16em" lineHeight="1.2" truncate>{contract.compositionName}</Text>
                          <Text mt="0.125em" fontSize="0.688rem;" color="var(--text-secondary)">
                            {contract.isAmendment ? 'Split Sheet Amendment' : 'Split Sheet'}
                          </Text>
                        </Box>
                      </TableIllustrationWrapper>
                    </TableCell>
                    <TableCell mobileLabel="Updated:">{formatDate(contract.lastSigned)}</TableCell>
                    <TableCell mobileLabel="Signers:">
                      {contract.totalParties}
                    </TableCell>
                    <TableCell
                      mobileLabel="Contract Status:"
                      color="var(--green-400)"
                    >
                      Fully Executed
                    </TableCell>
                  </TableRow>
                ))}
              {currentResults.length === 0
              && (
                <TableSearchEmptyPrompt>
                  <Text color="var(--text-muted-panel)">No Contracts Match Your Filter</Text>
                </TableSearchEmptyPrompt>
              )}
            </Table>
            <TablePagination
              itemsPerPage={itemsPerPage}
              totalItems={searchedContracts.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </Fragment>
        )}

      </PageCard>
    </CoreLayout>
  );
}

export default Contracts;
