/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';
import { route } from 'preact-router';

import loadWorkspaceAccounts from '@actions/loadWorkspaceAccounts';
import { putWorkspaceAccountMetadata, putWorkspaceAccountPermissionsAndSuggestions, deleteWorkspaceAccount } from '@api/restricted/workspace-account-api';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Span from '@ui-kit/typography/span';
import BreakLine from '@ui-kit/dividers/breakLine';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import WorkspaceCard from '@distinct-components/cards/workspaceCard';
import Icon from '@ui-kit/icon';
import CloseSquare from '@assets/icons/close-square.svg';
import Avatar from '@ui-kit/avatar';
import RadioList from '@ui-kit/inputs/radioList';
import BaseButton from '@ui-kit/buttons/baseButton';
import AlertMessage from '@ui-kit/alert/Alert';
import CountDown from '@distinct-components/feedback/countDown';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseTypeAhead from '@distinct-components/typeAheadInputs/baseTypeAhead';

import WorkspaceEditOverlay from '@layouts/full-screen/workspaceEditOverlay';

import { COMPOSITION_PERMISSIONS } from '@constants/sharedOptions';
import { SOCIETIES } from '@constants/collectionSocieties';

import useRoundNumber from '@hooks/useRoundNumber';

import WriterListItem from './writerListItem/WriterListItem';
import AddWriterForm from './addWriterForm/AddWriterForm';

import {
  StyledOwnerShipWrapper,
  StylePercentageText,
  EditUserWrapper,
  UserContainer,
} from './CompositionWriterShareSectionStyles';
import WriterCard from './writerCard';

function CompositionWriterShareSection({ workspaceId, activeUser }) {
  const [isAddingWriter, setIsAddingWriter] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedWriter, setSelectedWriter] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeletingCollaborator, setIsDeletingCollaborator] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCanEdit, setIsCanEdit] = useState(false);
  const [guestFirstName, setGuestFirstName] = useState('');
  const [guestLastName, setGuestLastName] = useState('');
  const [guestSociety, setGuestSociety] = useState(null);
  const [guestIPI, setGuestIPI] = useState('');

  const [writerShareTotalDefined, setWriterShareTotalDefined] = useState(null);

  const [writers, setWriters] = useState(AppState.workspaceAccounts.value.filter((account) => account.isWriter));

  const selectedUser = AppState.workspace.writerToManage.value;
  const selectedGuest = AppState.workspace.guestToManage.value;

  const WriterSocieties = SOCIETIES.filter((s) => s.societyType === 'pro');
  const getSocietyByName = (societyName) => {
    if (!societyName) {
      return null;
    }
    const found = WriterSocieties.find((s) => s.name === societyName);
    return found ? ({
      name: found.name,
      country: found.country,
      type: found.societyType,
    }) : null;
  };

  const refreshSplits = () => {
    const latest = AppState.workspaceAccounts.value.filter((account) => account.isWriter);
    if (selectedWriter) {
      const updatedWriter = latest.filter((writer) => writer.email === selectedWriter.email);
      setSelectedWriter(updatedWriter[0]);
    }
    setWriters(latest);
    const writerShareTotal = latest.reduce((prev, current) => prev + +(current.share), 0);
    setWriterShareTotalDefined(useRoundNumber(writerShareTotal, 2));
  };

  const handleBrowserBack = () => {
    route('/compositions');
  };

  useEffect(() => {
    refreshSplits();
    window.addEventListener('popstate', handleBrowserBack);

    return () => {
      window.removeEventListener('popstate', handleBrowserBack);
    };
  }, [AppState.workspaceAccounts.value]);

  const refreshWriters = () => {
    loadWorkspaceAccounts(workspaceId).then(() => {
      refreshSplits();
    });
  };

  const onClickAddWriter = () => {
    setIsAddingWriter(true);
    AppState.workspace.isEditingChild.value = true;
  };

  const onClickWriterCard = (writer) => {
    setSelectedWriter(writer);
    setIsEditing(!isEditing);
    AppState.workspace.isEditingChild.value = true;
  };

  const handleCancelAddOrEdit = () => {
    setIsEditing(false);
    setIsAddingWriter(false);
    AppState.workspace.isEditingChild.value = false;
  };

  const onClickViewAllWriters = () => {
    setSelectedWriter(null);
  };

  const handleUpdateWriter = (writer, metadata, hash) => {
    setIsLoading(true);
    putWorkspaceAccountMetadata(workspaceId, writer, metadata, hash)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          AppState.messages.toastMessage.value = { text: 'Writer Updated', variant: 'success' };
          refreshWriters();
          setError('');
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const setExistingPermissions = (user) => {
    if (user != null) {
      if (user.isAdmin && user.isCanEdit) {
        return 'Admin';
      } if (!user.isCanEdit) {
        return 'Restricted';
      }
      return 'Edit';
    }
    return null;
  };

  const handlePermissionToggle = (selection) => {
    if (selection === 'Restricted') {
      setIsAdmin(false);
      setIsCanEdit(false);
    } if (selection === 'Edit') {
      setIsAdmin(false);
      setIsCanEdit(true);
    } if (selection === 'Admin') {
      setIsAdmin(true);
      setIsCanEdit(true);
    } if (selection === 'Viewer') {
      setIsAdmin(false);
      setIsCanEdit(false);
    } if (selection === 'ManagerRepEdit') {
      setIsAdmin(false);
      setIsCanEdit(true);
    }
  };

  const handleUpdateCollaborator = () => {
    const accountToUpdate = {
      id: selectedUser.id,
      email: selectedUser.email,
      hasJoined: selectedUser.hasJoined,
    };
    setIsLoading(true);
    putWorkspaceAccountPermissionsAndSuggestions(workspaceId, accountToUpdate, isAdmin, true, isCanEdit)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setError('');
          loadWorkspaceAccounts(workspaceId);
          AppState.workspace.writerToManage.value = null;
        } else {
          response.json().then((json) => {
            if (json.error) {
              setError(json.error);
            } else {
              setError(response.statusText);
            }
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const cleanName = (name) => {
    const words = name.trim().split(/\s+/);
    return words.join(' ');
  };

  const handleUpdateGuestCollaborator = () => {
    setError('');
    if (guestIPI && (!/^[0-9]{9,11}$/.test(guestIPI))) {
      setError('IPI number must be 9-11 digits');
      return;
    }
    const societyData = getSocietyByName(guestSociety);
    if (guestSociety && !societyData) {
      setError('Society not found');
      return;
    }

    const firstNameToUse = cleanName(guestFirstName) || selectedGuest.firstName;
    const lastNameToUse = cleanName(guestLastName) || selectedGuest.lastName;

    const accountToUpdate = {
      id: selectedGuest.id,
      email: selectedGuest.email,
      hasJoined: selectedGuest.hasJoined,
    };
    const suggestions = {
      ...(guestIPI && { ipi: guestIPI }),
      ...(societyData && { society: societyData }),
    };
    setIsLoading(true);
    putWorkspaceAccountPermissionsAndSuggestions(workspaceId, accountToUpdate, isAdmin, true, isCanEdit, firstNameToUse, lastNameToUse, suggestions)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setError('');
          loadWorkspaceAccounts(workspaceId);
          AppState.workspace.guestToManage.value = null;
        } else {
          response.json().then((json) => {
            if (json.error) {
              setError(json.error);
            } else {
              setError(response.statusText);
            }
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const onClickRemoveCollaborator = () => {
    setShowDeleteConfirmation(true);
    setTimeout(() => {
      setShowDeleteConfirmation(false);
    }, 5000);
  };

  const handleRemoveCollaborator = () => {
    setShowDeleteConfirmation(true);
    setIsDeletingCollaborator(true);
    deleteWorkspaceAccount(workspaceId, {
      id: selectedUser.id,
      email: selectedUser.email,
      hasJoined: selectedUser.hasJoined,
    })
      .then((response) => {
        setIsDeletingCollaborator(false);
        if (response.status === 200) {
          loadWorkspaceAccounts(workspaceId);
          setError('');
          AppState.messages.toastMessage.value = { text: 'User Removed', variant: 'success' };
          AppState.workspace.writerToManage.value = null;
        } else {
          response.json().then((json) => {
            if (json.error) {
              setError(json.error);
            } else {
              setError(response.statusText);
            }
          });
        }
      })
      .catch((err) => {
        setIsDeletingCollaborator(false);
        setError(err.message);
      });
  };

  const handleRemoveGuestCollaborator = () => {
    setShowDeleteConfirmation(true);
    setIsDeletingCollaborator(true);
    deleteWorkspaceAccount(workspaceId, {
      id: selectedGuest.id,
      email: selectedGuest.email,
      hasJoined: selectedGuest.hasJoined,
    })
      .then((response) => {
        setIsDeletingCollaborator(false);
        if (response.status === 200) {
          loadWorkspaceAccounts(workspaceId);
          setError('');
          AppState.messages.toastMessage.value = { text: 'User Removed', variant: 'success' };
          AppState.workspace.guestToManage.value = null;
        } else {
          response.json().then((json) => {
            if (json.error) {
              setError(json.error);
            } else {
              setError(response.statusText);
            }
          });
        }
      })
      .catch((err) => {
        setIsDeletingCollaborator(false);
        setError(err.message);
      });
  };

  const closeFunction = () => {
    AppState.workspace.writerToManage.value = null;
    AppState.workspace.guestToManage.value = null;
    setError('');
  };

  const handleWriterAdded = () => {
    AppState.workspace.isEditingChild.value = false;
    loadWorkspaceAccounts(workspaceId);
    AppState.messages.toastMessage.value = { text: 'Writer Added', variant: 'success' };
    setIsAddingWriter(false);
  };

  return (
    <Fragment>
      <WorkspaceCard
        pb="5em"
        title={`Songwriters: ${writers.length}`}
        handleAddToSection={onClickAddWriter}
        isCardLocked={!activeUser.isAdmin || AppState.composition.isLocked.value}
        lockedText="You do not have permission to edit this section or the composition is locked."
        mb="10em"
      >
        <StyledOwnerShipWrapper writerCount={writers.length}>
          {/* {writers.sort((a, b) => a.lastName.localeCompare(b.lastName)).map((writer) => ( */}
          {writers.map((writer) => (
            <WriterCard writer={writer} onClickWriterCard={onClickWriterCard} />
          ))}
        </StyledOwnerShipWrapper>
      </WorkspaceCard>

      {isEditing
      && (
      <WorkspaceEditOverlay
        closeFunction={handleCancelAddOrEdit}
        formWidth="46em"
        isClickOutsideDisabled={!!selectedUser || !!selectedGuest}
      >
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box width="100%">
              <Text fontSize="1.5em" fontFamily="var(--font-secondary)" fontWeight="800">
                {selectedWriter ? 'Edit Writer' : 'Writers'}
              </Text>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  {!selectedWriter
                  && (
                    <Span fontWeight="600" mr="1em">
                      {writers.length}
                      {' '}
                      <Span>
                        Writers
                      </Span>
                    </Span>
                  )}
                  <StylePercentageText total={writerShareTotalDefined}>
                    <Span>Total Split:</Span>
                    {' '}
                    {writerShareTotalDefined}
                    %
                  </StylePercentageText>
                </Box>
                <Box>
                  {selectedWriter
                    && <LinkButton type="button" fontSize="0.813rem" btnText="View All Writers" onClick={onClickViewAllWriters} />}
                </Box>
              </Box>
            </Box>
          </Box>
          <BreakLine mt="0.75em" mb="1em" color="var(--card-divider)" />
          {selectedWriter
            ? (
              <WriterListItem
                isLoading={isLoading}
                error={error}
                handleUpdateWriter={handleUpdateWriter}
                refreshWriters={refreshWriters}
                writer={selectedWriter}
                activeUser={activeUser}
              />
            )
            : (
              <Fragment>
                {writers.map((writer) => (
                  <WriterListItem
                    isLoading={isLoading}
                    error={error}
                    handleUpdateWriter={handleUpdateWriter}
                    refreshWriters={refreshWriters}
                    writer={writer}
                    activeUser={activeUser}
                  />
                ))}
              </Fragment>
            )}
        </Box>
      </WorkspaceEditOverlay>
      )}

      {selectedUser && (
        <EditUserWrapper>
          <UserContainer>
            <Box
              display="flex"
              justifyContent="flex-end"
              style={{
                position: 'absolute',
                right: '22px',
              }}
            >
              <Icon size="1.5em" cursor onClick={closeFunction}><CloseSquare /></Icon>
            </Box>
            <Box display="flex" alignItems="center" mb="1em">
              <Avatar size="3.5em" mb="1em" imgURL={selectedUser.imgURL} isVerified={selectedUser?.isVerified} checkSize="1em" checkY="4px" />
              <Box ml="1em">
                {!selectedUser.isRegistered ? (
                  <Text variant="thinPrompt" mb="1.5em">
                    {selectedUser.email}
                  </Text>
                ) : (
                  <Text fontWeight="500" fontFamily="var(--font-lexend)" color="var(--text-primary)" lineHeight="1.7" fontSize="0.875rem" pr="20px">
                    {selectedUser.firstName}
                    {' '}
                    {selectedUser.lastName}
                  </Text>
                )}
                {selectedUser.isRegistered && (
                <Text color="var(--text-secondary)" fontSize="0.813rem">
                  @
                  {selectedUser.username}
                </Text>
                )}
              </Box>
            </Box>
            <BreakLine mb="1.5em" mt="0.5em" color="var(--border)" />
            <Box>
              <Text mb="1em">What can this Songwriter do?</Text>
              <RadioList
                options={COMPOSITION_PERMISSIONS}
                defaultValue={() => setExistingPermissions(selectedUser)}
                handleChange={handlePermissionToggle}
                inputPadding="0.4em"
                mb="2.5em"
              />
            </Box>
            {error && (
            <AlertMessage
              variant="negative"
              message={error}
              mb="1.5em"
            />
            )}
            <BaseButton
              type="submit"
              btnText="Save Changes"
              onClick={handleUpdateCollaborator}
              fluid
              isLoading={isLoading}
              mb="1.5em"
            />
            <Box mb="0.5rem" display="flex" alignItems="center" justifyContent="center">
              {showDeleteConfirmation ? (
                <Fragment>
                  <CountDown mr="0.25em" timeInSeconds={5} size={12} color="var(--red-300)" />
                  <LinkButton btnText="Yes, Remove Writer" color="var(--red-300)" mr="1em" onClick={handleRemoveCollaborator} />
                </Fragment>
              ) : (
                <LinkButton
                  type="button"
                  color="var(--red-300)"
                  spinnerVariant="danger"
                  isLoading={isDeletingCollaborator}
                  btnText="Remove Writer"
                  onClick={onClickRemoveCollaborator}
                />
              )}
            </Box>
          </UserContainer>
        </EditUserWrapper>
      )}

      {selectedGuest && (
        <EditUserWrapper>
          <UserContainer>
            <Box
              display="flex"
              justifyContent="flex-end"
              style={{
                position: 'absolute',
                right: '22px',
              }}
            >
              <Icon size="1.5em" cursor onClick={closeFunction}><CloseSquare /></Icon>
            </Box>
            <Box display="flex" alignItems="center" mb="1em">
              <Avatar size="3.5em" mb="1em" imgURL={null} />
              <Box ml="1em">
                <Text fontWeight="500" fontFamily="var(--font-lexend)" color="var(--text-primary)" lineHeight="1.7" fontSize="0.875rem" pr="20px">
                  Guest Writer
                </Text>
                <Text color="var(--text-secondary)" fontSize="0.813rem">
                  {selectedGuest.email}
                </Text>
              </Box>
            </Box>
            <BreakLine mb="1.5em" mt="0.5em" color="var(--border)" />
            <Box mb="1em">
              <InputLabel label="First Name" />
              <BaseInput
                id="firstName"
                name="firstName"
                onChange={(e) => setGuestFirstName(e.target.value)}
                defaultValue={selectedGuest.firstName}
                fluid
                type="text"
                autoComplete="off"
                mb="1rem"
              />
              <InputLabel label="Last Name" />
              <BaseInput
                id="lastName"
                name="lastName"
                onChange={(e) => setGuestLastName(e.target.value)}
                defaultValue={selectedGuest.lastName}
                fluid
                type="text"
                autoComplete="off"
                mb="1rem"
              />
              <BaseTypeAhead
                label="Guest Collection Society (Optional)"
                id="societies"
                data={SOCIETIES}
                nameValue="name"
                handleChange={(data) => setGuestSociety(data)}
                initialValue={selectedGuest.suggestions?.society?.name || ''}
              />

              <Fragment>
                <InputLabel label="Guest IPI (Optional)" />
                <BaseInput
                  id="ipi"
                  name="ipi"
                  placeholder="Enter 9-11 Digit IPI"
                  defaultValue={selectedGuest.suggestions?.ipi || ''}
                  onChange={(e) => setGuestIPI(e.target.value)}
                  pattern="[0-9]+"
                  maxLength="11"
                  minLength="9"
                  fluid
                  type="text"
                  inputMode="decimal"
                  mb="1.5em"
                  autoComplete="off"
                />
              </Fragment>
              <BreakLine mb="1.5em" mt="0.5em" color="var(--border)" />
            </Box>
            <Box>
              <Text mb="1em">What can this Songwriter do?</Text>
              <RadioList
                options={COMPOSITION_PERMISSIONS}
                defaultValue={() => setExistingPermissions(selectedGuest)}
                handleChange={handlePermissionToggle}
                inputPadding="0.4em"
                mb="2.5em"
              />
            </Box>
            {error && (
            <AlertMessage
              variant="negative"
              message={error}
              mb="1.5em"
            />
            )}
            <BaseButton
              type="submit"
              btnText="Save Changes"
              onClick={handleUpdateGuestCollaborator}
              fluid
              isLoading={isLoading}
              mb="1.5em"
            />
            <Box mb="0.5rem" display="flex" alignItems="center" justifyContent="center">
              {showDeleteConfirmation ? (
                <Fragment>
                  <CountDown mr="0.25em" timeInSeconds={5} size={12} color="var(--red-300)" />
                  <LinkButton btnText="Yes, Remove Guest" color="var(--red-300)" mr="1em" onClick={handleRemoveGuestCollaborator} />
                </Fragment>
              ) : (
                <LinkButton
                  type="button"
                  color="var(--red-300)"
                  spinnerVariant="danger"
                  isLoading={isDeletingCollaborator}
                  btnText="Remove Guest Writer"
                  onClick={onClickRemoveCollaborator}
                />
              )}
            </Box>
          </UserContainer>
        </EditUserWrapper>
      )}

      {isAddingWriter
      && (
      <WorkspaceEditOverlay
        closeFunction={handleCancelAddOrEdit}
        formWidth="26em"
      >
        <AddWriterForm workspaceId={workspaceId} activeUser={activeUser} handleCloseFunction={handleWriterAdded} />
      </WorkspaceEditOverlay>
      )}

    </Fragment>
  );
}

CompositionWriterShareSection.propTypes = {
  workspaceId: PropTypes.number.isRequired,
  activeUser: PropTypes.object.isRequired,
};

export default CompositionWriterShareSection;
