/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'preact/hooks';
import { useForm } from 'react-hook-form';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseButton from '@ui-kit/buttons/baseButton';
import BreakLine from '@ui-kit/dividers/breakLine';
import AlertMessage from '@ui-kit/alert/Alert';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';

import { Fragment } from 'preact';
import {
  StyledPercentBox,
  StyledPercentageInput,
} from './AddWriterRequirementsStyles';

function AddWriterRequirements({
  requirements,
  setRequirements,
  setStep,
  ...restProps
}) {
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [provideDetails, setProvideDetails] = useState(true);
  const [allowCombineWorks, setAllowCombineWorks] = useState(true);
  const [hasMinimum, setHasMinimum] = useState(false);

  const {
    register, handleSubmit, getValues, formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      totalWorks: requirements?.totalWorks,
      deliveredToDate: requirements?.deliveredToDate || 0,
      qualifyingPercentage: requirements?.qualifyingPercentage || 100,
      minimumPercentage: requirements?.minimumPercentage || null,
    },
  });

  useEffect(() => {
    if (requirements) {
      setProvideDetails(requirements.detailProvided);
      setAllowCombineWorks(requirements.allowCombineWorks);
      setHasMinimum(requirements.hasMinimum);
    }
  }, []);

  const onClickContinue = (data) => {
    if (allowCombineWorks && hasMinimum && data.minimumPercentage >= data.qualifyingPercentage) {
      setError('Minimum Percentage has to be less than Qualifying Percentage');
      return;
    }

    setError('');
    setIsSaving(true);
    const commitmentData = {
      detailProvided: true,
      totalWorks: data.totalWorks,
      deliveredToDate: data.deliveredToDate,
      qualifyingPercentage: data.qualifyingPercentage,
      allowCombineWorks,
      hasMinimum,
      minimumPercentage: data.minimumPercentage || 0,
    };
    setRequirements(commitmentData);
    setTimeout(() => {
      setIsSaving(false);
      setStep('review');
    }, 800);
  };

  const onClickSkip = () => {
    const commitmentData = {
      detailProvided: false,
      totalWorks: getValues('totalWorks'),
      deliveredToDate: getValues('deliveredToDate'),
      qualifyingPercentage: getValues('qualifyingPercentage'),
      allowCombineWorks,
      hasMinimum,
      minimumPercentage: getValues('minimumPercentage'),
    };
    setRequirements(commitmentData);
    setStep('review');
  };

  return (
    <Box {...restProps}>
      <BreakLine mb="1.5em" mt="0.5em" color="var(--panel-info-border)" />

      <Box display="flex" justifyContent="space-between" mb="1.25em">
        <Box>
          <Text fontWeight="600" mb="0.125em">Track Delivery of Works</Text>
          <Text fontSize="0.813rem" pr="0.5em" color={provideDetails ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}>
            Add requirements to monitor each writer&apos;s delivery progress from their profile.
          </Text>
        </Box>
        <ToggleSwitch id="commitment">
          <input
            id="commitment"
            name="commitment"
            type="checkbox"
            checked={provideDetails}
            onChange={() => setProvideDetails(!provideDetails)}
          />
        </ToggleSwitch>
      </Box>

      <BreakLine mb="1.5em" color="var(--panel-info-border)" />

      {provideDetails ? (
        <form id="commitmentForm" onSubmit={handleSubmit(onClickContinue)} autoComplete="off">

          <InputLabel label="Number of Works to Deliver" />
          <BaseInput
            {...register('totalWorks', { required: true, valueAsNumber: true })}
            placeholder="Enter Number"
            type="number"
            inputMode="numeric"
            max={1000000}
            min={1}
            fluid
            mb="1.5rem"
          />
          <InputLabel label="Already Delivered to Date" />
          <BaseInput
            {...register('deliveredToDate', { required: true, valueAsNumber: true })}
            placeholder="Enter Number (Optional)"
            type="number"
            inputMode="numeric"
            max={1000000}
            min={0}
            fluid
            mb="1.5rem"
          />
          <Box mb="1.5em">
            <InputLabel label="Percentage Required for Each Delivery" />
            <Box display="flex" mb="0.25em">
              <StyledPercentageInput
                {...register('qualifyingPercentage', { required: true, valueAsNumber: true })}
                placeholder="Enter Percentage"
                type="number"
                inputMode="decimal"
                step="0.01"
                max={100}
                min={0.01}
              />
              <StyledPercentBox>%</StyledPercentBox>
            </Box>
          </Box>

          <BreakLine mb="1.5em" mt="0.5em" color="var(--panel-info-border)" />

          <Box display="flex" justifyContent="space-between" mb="1.25em">
            <Box>
              <Text fontWeight="600" mb="0.125em">Combine Ownership Percentages</Text>
              <Text fontSize="0.813rem" pr="0.5em" color={allowCombineWorks ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}>
                If enabled, multiple works of a lesser percentage can combine to satisfy a single delivery.
              </Text>
            </Box>
            <ToggleSwitch id="combineWorks">
              <input
                id="combineWorks"
                name="combineWorks"
                type="checkbox"
                checked={allowCombineWorks}
                onChange={() => setAllowCombineWorks(!allowCombineWorks)}
              />
            </ToggleSwitch>
          </Box>

          <BreakLine mb="1.5em" color="var(--border)" />

          {allowCombineWorks && (
            <Fragment>
              <Box display="flex" justifyContent="space-between" mb="1.25em">
                <Box>
                  <Text fontWeight="600" mb="0.125em">Set Minimum Percentage</Text>
                  <Text fontSize="0.813rem" pr="0.5em" color={hasMinimum ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}>
                    If enabled, only works of at least the minimum percentage will be considered for aggregate.
                  </Text>
                </Box>
                <ToggleSwitch id="minimum">
                  <input
                    id="minimum"
                    name="minimum"
                    type="checkbox"
                    checked={hasMinimum}
                    onChange={() => setHasMinimum(!hasMinimum)}
                  />
                </ToggleSwitch>
              </Box>

              {hasMinimum && (
                <Box mb="1.5em">
                  <InputLabel label="Minimum Percentage for Each Work" />
                  <Box display="flex" mb="0.25em">
                    <StyledPercentageInput
                      {...register('minimumPercentage', { required: false, valueAsNumber: true })}
                      placeholder="Enter Percentage"
                      type="number"
                      inputMode="decimal"
                      step="0.01"
                      max={99.99}
                      min={0.01}
                      required // this means not required if toggle is off, otherwise required
                    />
                    <StyledPercentBox>%</StyledPercentBox>
                  </Box>
                </Box>
              )}

              <BreakLine mb="1.5em" color="var(--border)" />
            </Fragment>
          )}

          {error && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}

          <Box display="flex" justifyContent="flex-end">
            <BaseButton
              mb="1.5em"
              btnText="Continue"
              disabled={isSaving || !isValid}
              isLoading={isSaving}
              fluid
              decoration="arrow"
            />
          </Box>
        </form>
      ) : (
        <Box display="flex" justifyContent="flex-end">
          <BaseButton
            mb="1.5em"
            btnText="Skip"
            onClick={onClickSkip}
            fluid
            decoration="arrow"
          />
        </Box>
      )}

    </Box>
  );
}

AddWriterRequirements.propTypes = {
  setStep: PropTypes.func.isRequired,
  requirements: PropTypes.object,
  setRequirements: PropTypes.func.isRequired,
};

AddWriterRequirements.defaultProps = {
  requirements: null,
};

export default AddWriterRequirements;
