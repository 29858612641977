import PropTypes from 'prop-types';

import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import SendIcon from '@assets/icons/send-message.svg';
import ArrowIcon from '@assets/icons/arrow-left.svg';

import {
  ProgressWrapper, ProgressBar, ProgressFill, BackButton,
} from './WizardProgressStyles';

function WizardProgress({
  handleStepBack,
  progressWidth,
  progressIcon,
  headerText,
  subHeaderText,
  showBackButton,
  ...restProps
}) {
  return (
    <Box style={{ position: 'relative' }}>
      {showBackButton
      && (
        <BackButton onClick={handleStepBack}>
          <Icon size="1.125em" color="var(--blue-500)" cursor>
            <ArrowIcon />
          </Icon>
        </BackButton>
      )}
      <ProgressWrapper {...restProps} showBackButton={showBackButton}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Text lineHeight="1" fontWeight="600" fontSize="0.75rem" mb="0.125em">{headerText}</Text>
            <Text fontSize="0.813rem" mb="0.5em">{subHeaderText}</Text>
          </Box>
          <Icon size="1.125em" mb="4px" color="var(--blue-500)">
            {progressIcon === 'send' && <SendIcon />}
          </Icon>
        </Box>
        <ProgressBar>
          <ProgressFill width={progressWidth} />
        </ProgressBar>
      </ProgressWrapper>
    </Box>
  );
}

WizardProgress.propTypes = {
  handleStepBack: PropTypes.func.isRequired,
  progressWidth: PropTypes.number.isRequired,
  progressIcon: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  subHeaderText: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool.isRequired,
};

export default WizardProgress;
