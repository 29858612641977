/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';
import Span from '@ui-kit/typography/span';
import pointBreaks from '@styles/styleConstants';
import Header from '@ui-kit/typography/header';

export const DocumentWrapper = styled.div`
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    background: var(--card);
    border-radius: 1em;
    padding: 4em 3em 8em 3em;
    margin-bottom: 5em;
    margin-top: 1.5em;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 3em 1.5em 4em 1.5em;
    }
`;

export const StyledAmendmentHeader = styled(Header)`
    font-size: 1.25rem;
    margin-bottom: 0.5em;
    font-weight: 400;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1rem;
    }
`;

export const DocumentHeader = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: 3em;
    ${space};
`;

export const DocumentBody = styled.div`
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    ${space};
`;

export const StyledHeader = styled(Header)`
    font-size: 1.75rem;
    margin-bottom: 0.5em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1.25rem;
    }
`;

export const StyledProse = styled(Span)`
    font-size: 1.125rem;
    line-height: 2;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 0.938rem;
    }
`;

export const WriterTable = styled.div`
    border-radius: 0.5em;
    border: 1px solid var(--text-secondary);
`;

export const WriterTableItem = styled.div`
    border-bottom: 1px solid var(--text-secondary);
    display: flex;
    justify-content: space-between;
    padding: 1em;

    &:last-child {
        border-bottom: none;
    }
    
    p {
        flex-basis: 33%;
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
       flex-direction: column;

       p {
        flex-basis: 100%;
        margin-right: 1em;
       }
    }
`;

export const WriterReferenceItem = styled.div`
    border-bottom: 1px solid var(--text-secondary);
    justify-content: space-between;
    padding: 1em;

    &:last-child {
        border-bottom: none;
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
       flex-direction: column;
    }
`;
