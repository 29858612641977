/* eslint-disable object-shorthand */
/* eslint-disable react/style-prop-object */
import anime from 'animejs/lib/anime.es';
import Box from '@ui-kit/box';
import { useEffect, useState } from 'preact/hooks';

function DataContainer() {
  const [play, setPlay] = useState(true);
  const [complete, setComplete] = useState(false);
  const [count, setCount] = useState(0);
  const [pillText, setPillText] = useState('');
  const dataAnimation = anime.timeline({
    autoplay: true,
    loop: false,
  });

  const wordAnimation = anime.timeline({
    autoplay: false,
    loop: false,
  });

  const dotAnimation = anime.timeline({
    autoplay: false,
    loop: false,
  });

  const pillStyle = {
    color: '#FFF',
    position: 'absolute',
    top: '14px',
    right: '30px',
    width: '133px',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1rem',
  };

  const wordAnimations = () => {
    wordAnimation.play();
  };

  const replay = () => {
    if (complete) {
      setComplete(false);
      setPlay(false);

      setTimeout(() => {
        setPlay(true);
        setCount(count + 1);
      }, 300);
    }
  };

  useEffect(() => {
    wordAnimation.add({
      targets: '#word-wrap',
      translateY: [0, -6],
      duration: 400,
      complete: () => dotAnimation.play(),
    })
      .add({
        targets: '#word-wrap',
        translateY: [-6, 0],
        duration: 400,
      });

    dotAnimation.add({
      targets: '#data-point',
      opacity: [0, 1],
      scale: 1.2,
      duration: 200,
    }).add({
      targets: '#data-point',
      translateY: [-16, 32],
      opacity: [1, 0],
      scale: [1.2, 1],
      duration: 700,
    });

    dataAnimation
      .add({
        targets: '#data-animation',
        opacity: [0, 1],
        duration: 100,
      })
      .add({
        targets: '#square',
        scale: [1.5, 1.8],
        duration: 900,
        easing: 'spring(1, 80, 10, 0)',
      })
      .add({
        targets: '#music-note',
        opacity: [1, 0],
        duration: 600,
      }, '-=500')
      .add({
        targets: '#the-box',
        opacity: [0, 1],
        scale: 1.8,
        duration: 600,
      }, '-=600')
      .add({
        duration: 1400,
        complete: () => setPillText('Identities'),
      }, '-=1400')
      .add({
        begin: () => wordAnimations(),
        duration: 1400,
        complete: () => setPillText('Lyrics'),
      })
      .add({
        begin: () => wordAnimations(),
        duration: 1400,
        complete: () => setPillText('Splits'),
      })
      .add({
        begin: () => wordAnimations(),
        duration: 1400,
        complete: () => setPillText('Data'),
      })
      .add({
        begin: () => wordAnimations(),
        duration: 1400,
        complete: () => setPillText(''),
      })
      .add({
        targets: '#the-box',
        opacity: [1, 0],
        duration: 600,
      }, '-=600')
      .add({
        targets: '#data-container-icon',
        opacity: [0, 1],
        scale: 1.8,
        duration: 600,
      }, '-=600')
      .add({
        targets: '#square, #data-container-icon',
        scale: [1.8, 1],
        duration: 900,
        elasticity: 400,
      })
      .add({
        targets: '#the-lines',
        opacity: [0, 1],
        duration: 900,
        scale: [0, 1],
        elasticity: 400,
      }, '-=800')
      .add({
        targets: '#bottom-left, #top-left, #bottom-right, #top-right',
        opacity: [0, 1],
        scale: [0, 1.15],
        duration: 800,
        elasticity: 400,
        delay: anime.stagger(200),
      }, '-=900')
      .add({
        duration: 3000,
      })
      .add({
        targets: '#bottom-left, #top-left, #bottom-right, #top-right, #the-lines',
        opacity: [1, 0],
        duration: 900,
      }, '-=900')
      .add({
        targets: '#square, #data-container-icon',
        scale: [1, 1.5],
        duration: 900,
        elasticity: 400,
      }, '-=900')
      .add({
        targets: '#inner-wave2, #outer-wave2',
        opacity: [0, 1],
        scale: [0, 0.5],
        duration: 100,
      });

    dataAnimation.finished.then(() => {
      anime({
        targets: '#badge',
        scale: [1, 1.25, 1],
        easing: 'spring(1, 80, 10, 0)',
        delay: 4000,
        loop: true,
        duration: 1600,
      });
      anime({
        targets: '#inner-wave2',
        scale: [0.5, 1.1],
        loop: true,
        delay: 100,
        duration: 4000,
        opacity: [1, 0.5, 0],
        easing: 'easeOutSine',
      });
      anime({
        targets: '#outer-wave2',
        scale: [0.5, 1.8],
        loop: true,
        delay: 100,
        duration: 6000,
        opacity: [1, 0.5, 0],
        easing: 'easeOutSine',
      });
      setComplete(true);
    });
  }, [count]);

  return (
    <Box position="relative" onClick={replay}>
      <div id="word-wrap" style={pillStyle}>
        {pillText}
      </div>
      {play
      && (
      <svg xmlns="http://www.w3.org/2000/svg" width="196" height="100%" viewBox="0 0 370 386">
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-38 -3)">
            <g id="data-animation" transform="translate(38 3)" style={{ opacity: 0 }}>
              <g transform="translate(46 14)">
                <circle id="outer-wave2" stroke="#5620CA" cx="139.090515" cy="186.344788" r="137" style="transform-box: fill-box; transform-origin: 50% 50%; opacity: 0;" />
                <circle id="inner-wave2" stroke="#5620CA" strokeWidth="0.89" cx="139.090515" cy="186.344788" r="122.055" style="transform-box: fill-box; transform-origin: 50% 50%; opacity: 0;" />
              </g>
              <g id="verified-square2" transform="translate(128 138)">
                <g id="square" style="transform-box: fill-box; transform-origin: 50% 50%">
                  <rect id="core-square" width="92" height="92" x="11" y="9" fill="#5620CA" rx="5" />
                  <path id="music-note" fill="#EDE5FF" d="m61.284 38.305 6.463 2.192c2.43.815 4.253 3.4 4.253 6.014v1.742c0 1.77-.718 3.316-1.988 4.243-.801.59-1.768.9-2.79.9a5.88 5.88 0 0 1-1.795-.31l-6.463-2.191-.111-.041v13.08l-.001.028-.003.223C58.72 68.51 55.208 72 50.926 72 46.563 72 43 68.375 43 63.935c0-4.44 3.563-8.065 7.926-8.065 1.37 0 2.659.356 3.785.983V43.076l.005-.201c.068-1.66.777-3.099 1.983-3.98 1.27-.927 2.928-1.152 4.585-.59ZM50.926 60.113c-2.07 0-3.783 1.714-3.783 3.85 0 2.108 1.684 3.85 3.783 3.85 2.072 0 3.784-1.714 3.784-3.85s-1.712-3.85-3.784-3.85Zm8.535-17.902a.681.681 0 0 0-.359.084c-.132.09-.23.305-.263.588.01.076.015.152.015.228v2.02c.137.725.773 1.521 1.408 1.745l6.463 2.192c.359.112.69.112.856 0 .166-.112.276-.422.276-.815v-1.742c0-.787-.718-1.77-1.436-2.024l-6.463-2.192a1.673 1.673 0 0 0-.497-.084Z" />
                  <g id="Group" transform="translate(86)">
                    <g id="badge" style="transform-box: fill-box; transform-origin: 50% 50%">
                      <path id="Path-7" fill="#355DFF" d="m8.859.654 4.16 1.624h.227L17.417 0l2.814 4.062 4.403.411v5.064L28 12.764l-2.77 3.449.625 4.968-4.207 1.092-2.412 4.466-4.366-1.483-4.18 2.109-2.66-3.899-4.63-.583v-4.908L0 14.589l2.855-3.448-.809-4.685 4.616-1.554z" />
                      <ellipse id="Oval" cx="14.022" cy="13.768" stroke="#FAF8FF" strokeWidth="1.4" rx="7.606" ry="7.691" />
                      <path id="Path-8" fill="#FAF8FF" d="M9.998 14.597c1.655 2.29 2.65 3.372 2.986 3.244.336-.129 2.168-2.29 5.494-6.487-.04-.632-.159-1.194-1.071-1.051l-4.423 4.457h-.197l-1.731-1.214c-.874-.195-1.274.106-1.058 1.05Z" />
                      <path id="Path-9" fill="#355DFF" d="m6.065 26.912 1.796-1.099 2.706 3.963 4.313-2.411 4.574 1.589 1.803-2.929.601.302L22.065 43l-8.043-6.805L6.065 43z" />
                    </g>
                  </g>
                  <path id="Rectangle" d="M0 8h11v93H0z" />
                </g>
              </g>
              <path id="Rectangle" d="M0 0h370v386H0z" />
              <g fillRule="nonzero" transform="translate(166 173)">
                <g id="the-box" style="transform-box: fill-box; transform-origin: 50% 50%">
                  <path id="Path" fill="#D1BDFF" d="M32.5 12h-26c-.715 0-1.3.585-1.3 1.3v20.8c0 .624.442 1.157 1.04 1.274l12.987 2.6h.013c.091.013.169.026.26.026s.169-.013.26-.026h.013l12.987-2.6A1.297 1.297 0 0 0 33.8 34.1V13.3c0-.715-.585-1.3-1.3-1.3Z" />
                  <path id="Path" fill="#9A7ADF" d="M19.5 12v26c-.091 0-.169-.013-.26-.026h-.013l-12.987-2.6A1.297 1.297 0 0 1 5.2 34.1V13.3c0-.715.585-1.3 1.3-1.3h13Z" />
                  <path id="Path" fill="#DED4F6" d="M18.421 14.015 19.5 15.64l-4.12 6.175c-.234.364-.65.585-1.08.585H1.304c-.481 0-.923-.26-1.144-.689a1.253 1.253 0 0 1 .065-1.326l5.2-7.8c.233-.364.65-.585 1.078-.585H19.5c-.48 0-.923.26-1.144.689-.234.416-.208.936.065 1.326ZM39 21.1c0 .715-.584 1.3-1.298 1.3H24.693c-.428 0-.844-.221-1.077-.585L19.5 15.64l1.078-1.625c.272-.39.298-.91.064-1.326A1.277 1.277 0 0 0 19.5 12h12.983c.428 0 .844.221 1.077.585l5.076 7.605c.221.234.364.559.364.91Z" />
                  <path id="Path" fill="#D1BDFF" d="m13.375 4.78 5 4c.045.037.098.055.148.082.04.022.075.049.119.066.113.043.23.07.35.07L19 9l.007-.001c.12-.001.238-.028.352-.071.043-.017.078-.043.118-.066.05-.027.103-.045.148-.081l5-4a1 1 0 0 0-1.25-1.562L20 5.92V1a1 1 0 1 0-2 0v4.92l-3.375-2.7a.997.997 0 0 0-1.406.155 1 1 0 0 0 .156 1.406Z" />
                </g>
              </g>
              <g id="the-group" style="transform-box: fill-box; transform-origin: 50% 50%">
                <g stroke="#9069FF" strokeDasharray="4" transform="translate(106.425 116.111)">
                  <g id="the-lines" style="transform-box: fill-box; transform-origin: 50% 50%">
                    <path id="Path-3" d="m0 151.777 29.075-26.388" />
                    <path id="Path-3-Copy" d="M131 26.777 160.075.389" />
                    <path id="Path-3-Copy" d="m132 151.777 29.075-26.388" transform="matrix(1 0 0 -1 0 277)" />
                    <path id="Path-3-Copy-2" d="M0 26.777 29.075.389" transform="matrix(1 0 0 -1 0 27)" />
                  </g>
                </g>
                <g transform="translate(21 260)">
                  <g id="bottom-left" style="transform-box: fill-box; transform-origin: 50% 50%">
                    <rect id="core-square-copy-4" width="92" height="92" x="0" y="0" fill="#5620CA" rx="46" />
                    <path id="Combined-Shape" fill="#D1BDFF" d="M50.465 30C58.547 30 62 33.453 62 41.535v8.93C62 58.547 58.547 62 50.465 62h-8.93C33.453 62 30 58.547 30 50.465v-8.93C30 33.453 33.453 30 41.535 30h8.93Zm9.245 9.837H32.29a23.59 23.59 0 0 0-.057 1.698v8.93c0 6.862 2.44 9.302 9.302 9.302h8.93c6.862 0 9.302-2.44 9.302-9.302v-8.93c0-.6-.018-1.165-.057-1.698ZM46.82 44.809l3.095 1.786c1.295.744 2.054 1.86 2.054 3.051 0 1.19-.759 2.307-2.068 3.051l-3.096 1.786c-.715.417-1.459.625-2.158.625a3.096 3.096 0 0 1-1.504-.386c-1.012-.596-1.607-1.787-1.607-3.29V47.86c0-1.503.595-2.709 1.622-3.304 1.027-.595 2.367-.506 3.662.253Zm-2.159 1.593a.783.783 0 0 0-.387.089c-.312.178-.506.684-.506 1.37v3.571c0 .67.194 1.176.506 1.37.313.178.849.089 1.43-.254l3.095-1.786c.58-.342.938-.759.938-1.116 0-.357-.343-.774-.938-1.116l-3.096-1.786c-.387-.223-.759-.342-1.042-.342Zm-12.047-8.797h7.09V32.3c-4.132.328-6.284 1.886-7.09 5.305Zm17.45-5.373h-8.127v5.373h8.127v-5.373Zm2.234.068-.001 5.305h7.09c-.805-3.42-2.957-4.977-7.089-5.305Z" />
                  </g>
                </g>
                <g transform="translate(21 34)">
                  <g id="top-left" style="transform-box: fill-box; transform-origin: 50% 50%">
                    <rect id="core-square-copy-7" width="92" height="92" x="0" y="0" fill="#5620CA" rx="46" />
                    <path id="Combined-Shape" fill="#D1BDFF" fillRule="nonzero" d="M51.023 32C60.115 32 64 35.845 64 44.843v3.314C64 57.155 60.115 61 51.023 61H40.977C31.885 61 28 57.155 28 48.157v-3.314C28 35.845 31.885 32 40.977 32h10.046Zm0 2.486H40.977c-7.72 0-10.465 2.717-10.465 10.357v3.314c0 7.64 2.746 10.357 10.465 10.357h10.046c7.72 0 10.465-2.717 10.465-10.357v-3.314c0-7.64-2.746-10.357-10.465-10.357Zm.837 13.605c.921 0 1.675.746 1.675 1.657s-.737 1.674-1.675 1.674c-.92 0-1.674-.73-1.674-1.64v-.034a1.67 1.67 0 0 1 1.674-1.657ZM42.82 43.07c.485.48.485 1.276 0 1.756l-1.667 1.648 1.717 1.7a1.241 1.241 0 0 1-.887 2.12c-.318 0-.636-.115-.888-.364l-1.717-1.699-1.665 1.65c-.252.231-.57.364-.888.364a1.241 1.241 0 0 1-.887-2.121l1.665-1.65-1.615-1.598a1.241 1.241 0 0 1 0-1.757c.486-.48 1.29-.48 1.775 0l1.615 1.598 1.667-1.647c.485-.481 1.289-.481 1.775 0Zm5.76 1.773c.921 0 1.674.746 1.674 1.657a1.66 1.66 0 0 1-1.674 1.657c-.92 0-1.691-.746-1.691-1.657s.737-1.657 1.658-1.657h.033Zm6.596 0c.921 0 1.675.746 1.675 1.657a1.67 1.67 0 0 1-1.675 1.657c-.92 0-1.69-.746-1.69-1.657s.736-1.657 1.657-1.657h.033Zm-3.315-3.281c.921 0 1.675.745 1.675 1.657 0 .911-.737 1.674-1.675 1.674-.92 0-1.674-.73-1.674-1.641v-.033a1.67 1.67 0 0 1 1.674-1.657Z" />
                  </g>
                </g>
                <g transform="translate(258 260)">
                  <g id="bottom-right" style="transform-box: fill-box; transform-origin: 50% 50%">
                    <rect id="core-square-copy-5" width="92" height="92" x="0" y="0" fill="#5620CA" rx="46" />
                    <g id="Group-2" fill="#D1BDFF" fillRule="nonzero" transform="translate(30 32)">
                      <path id="Vector" d="M0 19.21V8.766C0 7.767.806 6.94 1.778 6.94s1.778.828 1.778 1.826v10.446c0 1.022-.806 1.826-1.778 1.826S0 20.209 0 19.21ZM7.111 22.691V5.307c0-.998.806-1.826 1.778-1.826s1.778.828 1.778 1.826v17.384c0 1.023-.806 1.827-1.778 1.827S7.11 23.69 7.11 22.69ZM14.222 26.174V1.826C14.222.828 15.028 0 16 0s1.778.828 1.778 1.826v24.348c0 .998-.806 1.826-1.778 1.826s-1.778-.828-1.778-1.826ZM21.333 22.691V5.307c0-.998.806-1.826 1.778-1.826s1.778.828 1.778 1.826v17.384c0 1.023-.806 1.827-1.778 1.827s-1.778-.828-1.778-1.827ZM28.444 19.21V8.766c0-.998.806-1.826 1.778-1.826S32 7.767 32 8.765v10.446c0 1.022-.806 1.826-1.778 1.826s-1.778-.828-1.778-1.826Z" />
                    </g>
                  </g>
                </g>
                <g transform="translate(258 34)">
                  <g id="top-right" style="transform-box: fill-box; transform-origin: 50% 50%">
                    <rect id="core-square-copy-8" width="92" height="92" x="0" y="0" fill="#5620CA" rx="46" />
                    <path id="Combined-Shape" fill="#D1BDFF" d="M55.767 28C59.144 28 61 29.856 61 33.233v19.534C61 56.144 59.144 58 55.767 58h-8.372l-.063-.001-11.1.001C32.857 58 31 56.144 31 52.767V41.605c0-3.01 1.474-4.811 4.186-5.168v-1.81a3.835 3.835 0 0 1 3.837-3.836h3.557C43.303 28.973 44.95 28 47.395 28h8.372ZM42.163 38.465h-5.93c-2.205 0-3.14.935-3.14 3.14v11.162c0 2.205.935 3.14 3.14 3.14h6.695c-.419-.682-.67-1.524-.743-2.515 0-.014-.002-.027-.003-.04a7.44 7.44 0 0 1-.02-.585V38.465Zm13.604-8.372h-8.372c-1.723 0-2.67.571-3.002 1.873a.821.821 0 0 1-.026.136l-.034.144a5.704 5.704 0 0 0-.077.987v19.534c0 2.205.935 3.14 3.14 3.14l.348-.001v-4.534a2.454 2.454 0 0 1 2.442-2.442h2.79a2.454 2.454 0 0 1 2.443 2.442l-.001 4.534.35.001c2.204 0 3.139-.935 3.139-3.14V33.233c0-2.205-.935-3.14-3.14-3.14Zm-2.79 20.93h-2.791a.345.345 0 0 0-.349.35v4.534h3.489v-4.535a.345.345 0 0 0-.35-.349Zm-15.35-7.674c.573 0 1.047.474 1.047 1.046v5.582c0 .572-.474 1.046-1.046 1.046a1.054 1.054 0 0 1-1.047-1.046v-5.582c0-.572.475-1.046 1.047-1.046Zm11.164-6.977c.572 0 1.046.475 1.046 1.047v6.976c0 .572-.474 1.047-1.046 1.047a1.054 1.054 0 0 1-1.047-1.047V37.42c0-.572.475-1.047 1.047-1.047Zm5.581 0c.572 0 1.047.475 1.047 1.047v6.976c0 .572-.475 1.047-1.047 1.047a1.054 1.054 0 0 1-1.046-1.047V37.42c0-.572.474-1.047 1.046-1.047Zm-12.21-3.488h-3.139c-.963 0-1.744.781-1.744 1.744v1.744h4.884v-3.488Z" />
                  </g>
                </g>
              </g>
              <circle id="data-point" cx="185" cy="85" r="8" fill="#5008EC" style="transform-box: fill-box; transform-origin: 50% 50%" />
              <g fill="#EDE5FF" transform="translate(166 173)">
                <g id="data-container-icon" style="transform-box: fill-box; transform-origin: 50% 50%">
                  <path id="music-note-copy" d="m25.827 7.153 3.12 1.096C30.12 8.656 31 9.949 31 11.255v.871c0 .886-.347 1.659-.96 2.122a2.2 2.2 0 0 1-1.347.45 2.75 2.75 0 0 1-.866-.155l-3.12-1.096-.054-.021v6.541c0 2.22-1.72 4.033-3.826 4.033C18.72 24 17 22.187 17 19.967c0-2.22 1.72-4.032 3.827-4.032.66 0 1.283.178 1.827.491V9.537l.006-.17c.048-.8.387-1.491.954-1.92.613-.463 1.413-.576 2.213-.294Zm-5 10.904c-1 0-1.827.857-1.827 1.924 0 1.054.813 1.925 1.827 1.925 1 0 1.826-.857 1.826-1.925 0-1.067-.826-1.924-1.826-1.924Zm4.12-8.951a.32.32 0 0 0-.173.042c-.064.044-.11.151-.127.292a.943.943 0 0 1 .006.116v1.003c.063.364.372.766.68.879l3.12 1.096c.174.056.334.056.414 0 .08-.056.133-.21.133-.408v-.87c0-.394-.347-.886-.693-1.012l-3.12-1.096a.782.782 0 0 0-.24-.042Z" />
                  <path id="Combined-Shape" fillRule="nonzero" d="M27.34 0C34.107 0 38 3.907 38 10.698v18.604C38 36.093 34.107 40 27.34 40h-9.269c-.76 0-1.39-.633-1.39-1.395 0-.763.63-1.396 1.39-1.396h9.27c5.301 0 7.878-2.586 7.878-7.907V10.698c0-5.321-2.577-7.907-7.879-7.907H12.51c-5.302 0-7.879 2.586-7.879 7.907V23.72c0 .763-.63 1.395-1.39 1.395-.76 0-1.39-.632-1.39-1.395V10.698C1.85 3.907 5.742 0 12.51 0h14.83ZM6.076 28.316c.538.54.538 1.433 0 1.972L3.35 33.023l2.725 2.735c.538.54.538 1.433 0 1.972-.278.28-.63.41-.983.41-.352 0-.704-.13-.982-.41L.403 34.01a1.407 1.407 0 0 1 0-1.973l3.708-3.72a1.395 1.395 0 0 1 1.965 0Zm5.561 0 3.708 3.721c.538.54.538 1.433 0 1.972l-3.708 3.721c-.278.28-.63.41-.982.41s-.705-.13-.983-.41a1.407 1.407 0 0 1 0-1.972l2.726-2.735-2.726-2.735a1.407 1.407 0 0 1 0-1.972 1.395 1.395 0 0 1 1.965 0Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      )}
    </Box>
  );
}

export default DataContainer;
