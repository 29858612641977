import { createWalletPassUrl } from '../urls';

export default async function postCreateWalletPass(data) {
  const body = data;

  const response = await fetch(`${createWalletPassUrl}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return response;
}
