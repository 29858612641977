import styled from 'styled-components';
import {
  space,
  color,
} from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const Cell = styled.div(
  ({
    widthPercent,
    cursor,
    align,
  }) => `
    flex-basis: ${widthPercent || '25%'};
    flex-grow: 1;
    color: var(--text-soft-mute);
    text-align: ${align || 'left'};
    ${cursor && 'cursor: pointer;'}
    ${space};
    ${color};

    @media screen and (max-width: ${pointBreaks.sm}) {
        margin-bottom: 0.5em;
        text-align: left;
    }
`,
  space,
  color,
);

export const StyledMobileLabel = styled.span`
   color: var(--text-primary);
   margin-right: 0.5em;
   font-weight: 600;
   display: none;
  
      @media screen and (max-width: ${pointBreaks.sm}) {
          display: inline;
      }
  `;
