import { workspaceAccountInviteUrl } from '../urls';
import refresh from '../refresh';

export async function getWorkspaceInvites(workspaceId) {
  return refresh(async () => {
    const response = await fetch(`${workspaceAccountInviteUrl}?workspace=${workspaceId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function postWorkspaceAccountInvite(workspaceId, accounts) {
  const data = {
    workspace: workspaceId,
    accounts,
  };
  return refresh(async () => {
    const response = await fetch(workspaceAccountInviteUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function postWorkspaceAccountInviteJoinToken(workspaceId, isAdmin, isWriter, canEdit) {
  const data = {
    workspace: workspaceId,
    admin: isAdmin,
    participant: isWriter,
    edit: canEdit,
  };
  return refresh(async () => {
    const response = await fetch(workspaceAccountInviteUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteWorkspaceAccountInvite(workspaceId, email) {
  const data = {
    workspace: workspaceId,
    email,
  };
  return refresh(async () => {
    const response = await fetch(workspaceAccountInviteUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
