/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'preact/hooks';

import AppState from '@state';

import loadOrgRegistrations from '@actions/loadOrgRegistrations';
import { postOrgRegistration, patchOrgRegistration } from '@api/restricted/org-registration-api';

import AlertMessage from '@ui-kit/alert/Alert';
import Header from '@ui-kit/typography/header';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import AddressTypeAhead from '@distinct-components/typeAheadInputs/addressTypeAhead';
import BaseTypeAhead from '@distinct-components/typeAheadInputs/baseTypeAhead';

import FullScreenModal from '@layouts/full-screen/full-screen-modal';

import { SOCIETIES } from '@constants/collectionSocieties';

const WriterSocieties = SOCIETIES.filter((s) => s.societyType === 'pro');

function CreateEditRegistration({
  registration,
  usePlacesState,
  handleCloseRegistration,
}) {
  const {
    register, handleSubmit, setValue, trigger, formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [typeAheadValue, setTypeAheadValue] = useState(registration?.address || '');

  useEffect(() => {
    register('address', { required: true });
    register('society', { required: true });
    if (registration?.id) {
      setValue('address', registration.address);
      setValue('society', registration.society);
    }
  }, []);

  const handleAddressSelected = (data) => {
    setValue('address', data);
    trigger('address');
    setTypeAheadValue(data);
  };

  const handleSocietySelected = (data) => {
    setValue('society', data);
    trigger('society');
  };

  const handleSaveRegistration = (data) => {
    const cleanedData = {
      ...data,
      name: data.name.trim().split(/\s+/).join(' '),
      address: data.address.trim().split(/\s+/).join(' '),
      contactEmail: data.email.trim().split(/\s+/).join(' '),
    };
    if (!cleanedData.name) {
      setError('Invalid registration name');
      return;
    }

    const handleCreateEditRegistration = async () => (
      registration?.id
        ? patchOrgRegistration(registration.id, cleanedData.name, cleanedData.society, cleanedData.ipi, cleanedData.address, cleanedData.contactEmail)
        : postOrgRegistration(AppState.pubOrganization.id.value, cleanedData.name, cleanedData.society, cleanedData.ipi, cleanedData.address, cleanedData.contactEmail)
    );

    setIsLoading(true);
    handleCreateEditRegistration()
      .then((response) => {
        if (response.status === 200) {
          loadOrgRegistrations(AppState.pubOrganization.id.value, { forcedLoad: true }).then(() => {
            setIsLoading(false);
            setError('');
            handleCloseRegistration(true);
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return (
    <FullScreenModal formWidth="23em" closeFunction={() => handleCloseRegistration(false)}>
      <Header mb="0.75em">{registration?.id ? 'Edit PRO Affiliation' : 'Add PRO Affiliation'}</Header>
      <form onSubmit={handleSubmit(handleSaveRegistration)} autoComplete="off">
        <InputLabel label="Name" />
        <BaseInput
          {...register('name', { required: true })}
          id="name"
          name="name"
          defaultValue={registration?.name}
          placeholder="Name of Registration"
          type="text"
          pattern=".*[^\s].*"
          fluid
          mb="1.5rem"
        />
        <BaseTypeAhead
          label="Collection Society"
          id="societies"
          data={WriterSocieties}
          nameValue="name"
          handleChange={handleSocietySelected}
          initialValue={registration?.society}
        />
        <InputLabel label="IPI Number" />
        <BaseInput
          {...register('ipi', { required: true })}
          id="ipi"
          name="ipi"
          placeholder="9-11 Digit IPI"
          defaultValue={registration?.ipi}
          type="text"
          inputMode="decimal"
          pattern="[0-9]+"
          maxLength="11"
          minLength="9"
          fluid
          mb="1.5rem"
        />
        <InputLabel label="Contact Email" />
        <BaseInput
          {...register('email', { required: true })}
          id="email"
          name="email"
          defaultValue={registration?.contact_email}
          placeholder="info@publisher.com"
          fluid
          type="email"
          mb="1.5em"
        />
        {/* NOTE: Consideration for TAX ID EIN vs Sole Proprietor Likely will go here */}
        <AddressTypeAhead
          placesState={usePlacesState}
          handleChange={handleAddressSelected}
          address={typeAheadValue}
          label="Address"
          id="regAddress"
        />

        {error && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
        />
        )}

        <BaseButton mb="1.5em" type="submit" btnText="Save" disabled={!isValid} fluid isLoading={isLoading} />
      </form>
    </FullScreenModal>
  );
}

CreateEditRegistration.propTypes = {
  handleCloseRegistration: PropTypes.func.isRequired,
  usePlacesState: PropTypes.string.isRequired,
  registration: PropTypes.object,
};

CreateEditRegistration.defaultProps = {
  registration: null,
};

export default CreateEditRegistration;
