import { useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';

function usePollForData(
  initialLoadComplete,
  pollInterval,
  onPoll,
  isError,
) {
  let pollingInterval;
  let timeoutId;
  let currentlyPolling;

  const startPolling = () => {
    if (window.location.href.includes('localhost')) { return; }
    if (!currentlyPolling) {
      currentlyPolling = true;
      onPoll();
      pollingInterval = setInterval(() => {
        if (initialLoadComplete && !isError) {
          onPoll();
        }
      }, pollInterval);

      timeoutId = setTimeout(() => {
        clearInterval(pollingInterval);
        currentlyPolling = false;
      }, 30000);
    }
  };

  const handleVisibilityChange = () => {
    if (!document.hidden) {
      if (initialLoadComplete && !isError) {
        startPolling();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', startPolling);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    if (initialLoadComplete && !isError) {
      startPolling();
    }

    return () => {
      document.removeEventListener('mousemove', startPolling);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(pollingInterval);
      clearTimeout(timeoutId);
    };
  }, [initialLoadComplete, isError]);
}

usePollForData.propTypes = {
  initialLoadComplete: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  pollInterval: PropTypes.number.isRequired,
  onPoll: PropTypes.func.isRequired,
};

export default usePollForData;
