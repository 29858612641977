import { accountUserAliasUrl } from '../urls';
import refresh from '../refresh';

export async function postAccountUserAlias(name, ipi, ipn, isni, defaultComposition) {
  const data = {
    name,
    ipi,
    ipn,
    isni,
    defaultComposition,
  };
  return refresh(async () => {
    const response = await fetch(accountUserAliasUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function putAccountUserAlias(aliasId, name, ipi, ipn, isni, defaultComposition) {
  const data = {
    alias: aliasId,
    name,
    ipi,
    ipn,
    isni,
    defaultComposition,
  };
  return refresh(async () => {
    const response = await fetch(accountUserAliasUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteAccountUserAlias(aliasId) {
  const data = {
    alias: aliasId,
  };
  return refresh(async () => {
    const response = await fetch(accountUserAliasUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
