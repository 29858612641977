/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  space,
  layout,
  border,
  variant,
} from 'styled-system';

export const StyledBaseCard = styled.div(
  ({
    width,
    minHeight,
    isSelected,
    padding,
    cursor,
  }) => `
    border: 0.8px solid ${isSelected ? 'var(--brand-primary)' : 'var(--card-border)'};
    ${isSelected
    ? 'box-shadow: 2px 4px 11px var(--card-selected)'
    : 'box-shadow: 2px 4px 11px var(--card-shadow)'};
    width: ${width || '100%'};
    background: var(--card);
    border-radius: 0.5em;
    cursor: ${cursor || 'default'};
    min-height: ${minHeight || '1.5em'};
    overflow: hidden;
    padding: ${padding || '1.5em'};
    ${space};
    ${layout};
    ${border};
`,
  variant({
    variants: {
      panel: {
        background: 'var(--panel-card)',
      },
      overflow: {
        overflow: 'visible',
      },
    },
  }),
  space,
  layout,
  border,
);
