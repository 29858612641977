/* eslint-disable no-multi-assign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable default-case */
export default function useLightenColors(hex, percent) {
  // Parse the hex color to RGB
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  function rgbToHsl(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h; let s; const
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return [h * 360, s * 100, l * 100];
  }

  // Convert RGB to HSL
  const hsl = rgbToHsl(r, g, b);

  // Increase the lightness by the specified percentage
  hsl[2] += (percent / 100);

  // Clamp lightness value to [0, 100]
  hsl[2] = Math.min(100, Math.max(0, hsl[2]));

  // Convert HSL back to RGB
  const rgb = hslToRgb(hsl[0], hsl[1], hsl[2]);

  // Convert RGB to hex
  const rHex = Math.round(rgb[0]).toString(16).padStart(2, '0');
  const gHex = Math.round(rgb[1]).toString(16).padStart(2, '0');
  const bHex = Math.round(rgb[2]).toString(16).padStart(2, '0');

  return `#${rHex}${gHex}${bHex}`;
}

function hslToRgb(h, s, l) {
  h /= 360;
  s /= 100;
  l /= 100;

  let r; let g; let
    b;

  if (s === 0) {
    // eslint-disable-next-line no-multi-assign
    r = g = b = l;
  } else {
    const hueToRgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hueToRgb(p, q, h + 1 / 3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1 / 3);
  }

  return [r * 255, g * 255, b * 255];
}
