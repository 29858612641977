import AppState from './AppState';

export default function resetGlobalState() {
  AppState.timestamp.value = 0;
  AppState.accountId.value = 0;

  AppState.repSession.isActive.value = false;
  AppState.repSession.repName.value = '';
  AppState.repSession.repTasks.value = [];
  AppState.repSession.powerOfAttorney.value = false;

  AppState.workspace.isEditingChild.value = false;
  AppState.workspace.writerToManage.value = null;
  AppState.workspace.guestToManage.value = null;
  AppState.workspace.writerSignatureData.value = null;

  AppState.writer.value = false;
  AppState.subscribed.value = false;
  AppState.verified.value = false;
  AppState.paid.value = false;
  AppState.verifyError.value = null;
  AppState.workspaceAccounts.value = [];
  AppState.workspaceInvites.value = [];
  AppState.workspaceInvitesToSend.value = [];
  AppState.aliases.value = [];

  AppState.userProfile.email.value = '';
  AppState.userProfile.phone.value = '';
  AppState.userProfile.legalName.value = '';
  AppState.userProfile.firstName.value = '';
  AppState.userProfile.lastName.value = '';
  AppState.userProfile.country.value = '';
  AppState.userProfile.username.value = '';
  AppState.userProfile.imgURL.value = null;
  AppState.userProfile.verifiedData.value = null;

  AppState.artistProfile.uuid.value = '';
  AppState.artistProfile.did.value = '';
  AppState.artistProfile.swCollSociety.value = [];
  AppState.artistProfile.perfCollSociety.value = null;
  AppState.artistProfile.ipi.value = null;
  AppState.artistProfile.ipn.value = '';
  AppState.artistProfile.isni.value = '';
  AppState.artistProfile.ipiCritical.value = false;
  AppState.artistProfile.societyCritical.value = false;
  AppState.artistProfile.publisherCritical.value = false;
  AppState.artistProfile.criticalChecksComplete.value = false;
  AppState.artistProfile.isEditingChild.value = false;
  AppState.artistProfile.isEditingCurrentPreset.value = false;

  AppState.profileLoaded.value = false;

  AppState.publishers.value = null;
  AppState.publisherPresets.value = [];

  AppState.compositions.value = [];

  AppState.composition.id.value = 0;
  AppState.composition.version.value = 0;
  AppState.composition.uuid.value = '';
  AppState.composition.did.value = '';
  AppState.composition.name.value = '';
  AppState.composition.tags.value = [];
  AppState.composition.alternateTitles.value = [];
  AppState.composition.date.value = '';
  AppState.composition.intendedUse.value = '';
  AppState.composition.hash.value = '';
  AppState.composition.isLocked.value = false;
  AppState.composition.shareableVersion.value = 0;
  AppState.composition.hasSamples.value = false;
  AppState.composition.samples.value = [];

  AppState.contracts.value = [];

  AppState.isRepresentative.value = false;

  AppState.repProfile.subscription.value = null;
  AppState.repProfile.maxArtistsReached.value = false;

  AppState.organizations.value = [];

  AppState.pubOrganization.id.value = 0;
  AppState.pubOrganization.uuid.value = '';
  AppState.pubOrganization.name.value = '';
  AppState.pubOrganization.logoURL.value = null;
  AppState.pubOrganization.customTags.value = [];
  AppState.pubOrganization.customStatuses.value = [];

  AppState.orgRegistrations.value = [];

  AppState.orgConnections.value = [];

  AppState.exceptions.errorPageError.value = null;

  AppState.exceptions.errorOverlay.display.value = false;
  AppState.exceptions.errorOverlay.errorTitle.value = null;
  AppState.exceptions.errorOverlay.userMessage.value = null;
  AppState.exceptions.errorOverlay.errorMessage.value = null;
  AppState.exceptions.errorOverlay.buttonText.value = null;
  AppState.exceptions.errorOverlay.buttonPath.value = null;
  AppState.exceptions.errorOverlay.bypassAuthCheck.value = false;

  AppState.navigation.backActionPath.value = null;
  AppState.navigation.defaultTabListener.value = 0;

  AppState.publisherLists.value = [];

  AppState.publisherActiveSongwriters.value = [];

  AppState.publisherCompositionsTable.headerSearchListener.value = 0;
  AppState.publisherCompositionsTable.activeConfig.isAscending.value = false;
  AppState.publisherCompositionsTable.activeConfig.sort.value = 'created';
  AppState.publisherCompositionsTable.activeConfig.toggle.value = 'all';
  AppState.publisherCompositionsTable.activeConfig.query.value = '';
  AppState.publisherCompositionsTable.activeConfig.filters.status.value = [];
  AppState.publisherCompositionsTable.activeConfig.filters.internalTags.value = [];
  AppState.publisherCompositionsTable.activeConfig.filters.compositionTags.value = [];
  AppState.publisherCompositionsTable.activeConfig.filters.connectedPublishers.value = [];
  AppState.publisherCompositionsTable.currentPage.value = 1;
  AppState.publisherCompositionsTable.itemsPerPage.value = 10;

  AppState.pagination.songwriterPage.value = 1;
  AppState.pagination.teamPage.value = 1;
  AppState.pagination.contractPage.value = 1;

  AppState.media.currentlyPlaying.value = null;
  AppState.media.isPlaying.value = false;

  AppState.messages.toastMessage.value = null;

  AppState.deepLinkUrl.value = '';
}
