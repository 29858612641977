import styled from 'styled-components';
import {
  space, typography, color, variant,
} from 'styled-system';

const StyledLinkButton = styled.button(
  ({ underline }) => `
    border: none;
    width: auto;
    padding: 0px;
    text-decoration: ${underline ? 'underline' : 'none'};
    color: var(--text-soft-mute);
    ${space};
    ${typography};
    ${color};

    &:disabled {
        opacity: 0.75;
    }
  `,
  space,
  typography,
  color,
  variant({
    variants: {
      small: {
        fontSize: '0.75rem',
      },
    },
  }),
);

export default StyledLinkButton;
