import styled from 'styled-components';
import Text from '@ui-kit/typography/text';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const StyledCardWrapper = styled.div`
    background: var(--background);
    border-radius: 1em;
    overflow: hidden;
    width: 100%;
   ${space};

   @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 0em 1em;
        border: 1px solid var(--border);
    }
`;

export const IdCardHeader = styled.div`
    border-bottom: 1px solid var(--border);
    margin-bottom: 1em;
    padding: 1.25em 1.25em 0.938em 1.25em;
`;

export const IdCardBody = styled.div`
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const DidText = styled(Text)`
    word-break: break-all;
    font-size: 0.75rem;
    text-align: center;
    padding: 0em 0.5em;
    font-weight: 200;
    color: var(--text-secondary);
`;
