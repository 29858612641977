import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Fragment } from 'preact';
import AppState from '@state/AppState';

import BaseButton from '@ui-kit/buttons/baseButton';
import AlertMessage from '@ui-kit/alert/Alert';
import Span from '@ui-kit/typography/span';

import {
  LoggedInWrapper,
} from './PublisherInvitationAcceptStyles';

function PublisherInvitationAccept({
  handleAcceptInvite,
  error,
  isLoading,
  handleSignOut,
}) {
  const {
    handleSubmit, formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data) => {
    handleAcceptInvite(data);
  };

  return (
    <Fragment>
      <LoggedInWrapper>
        <Span fontSize="0.75rem" mb="0.25em">
          {AppState.userProfile.email.value}
          &nbsp;&nbsp;
        </Span>
        <Span fontSize="0.75rem" mb="0.25em" cursor style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleSignOut()}>Sign Out</Span>
      </LoggedInWrapper>
      <form id="acceptForm" onSubmit={handleSubmit(onSubmit)} autoComplete="on">
        {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}
        <BaseButton
          mb="1.5em"
          type="submit"
          btnText="Accept Invitation"
          disabled={!isValid}
          fluid
          isLoading={isLoading}
        />
      </form>
    </Fragment>
  );
}

PublisherInvitationAccept.propTypes = {
  handleAcceptInvite: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSignOut: PropTypes.func.isRequired,
};

export default PublisherInvitationAccept;
