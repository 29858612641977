/* eslint-disable react/forbid-prop-types */
import { useState, useEffect, useRef } from 'preact/hooks';
import { Fragment } from 'preact';
import PropTypes from 'prop-types';

import { deleteArtistRepInvite } from '@api/restricted/artist-reps-invite-api';

import d from '@hooks/useIsoDateFormat';
import useWindowDimensions from '@hooks/useWindowDimensions';

import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Spinner from '@ui-kit/loaders/Spinner';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';
import Icon from '@ui-kit/icon';
import RemoveIcon from '@assets/icons/trash-outline.svg';

import DeleteConfirmationOverlay from '@shared-overlays/deleteConfirmationOverlay';

import { TableSearchEmptyPrompt } from './PendingPublisherConnectionsStyles';

function PendingPublisherConnections({
  isLoading,
  invites,
  // addNewConnection,
}) {
  const [inviteToCancel, setInviteToCancel] = useState();
  const [isCanceling, setIsCanceling] = useState(false);
  const [error, setError] = useState();
  const [searchActive, setSearchActive] = useState(false);
  const [invitations, setInvitations] = useState(invites);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  useEffect(() => {
    if (height < 1080) {
      setItemsPerPage(7);
    }
  }, []);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = invitations.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    const results = [];
    setCurrentPage(1);
    if (query) {
      invites.forEach((i) => {
        if (i.email.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(i);
        }
      });
      setInvitations(results);
      setSearchActive(true);
    } else {
      setInvitations(invites);
      setSearchActive(false);
    }
  };

  const handleCancelInvite = () => {
    setError('');
    setIsCanceling(true);

    deleteArtistRepInvite(inviteToCancel.inviteId)
      .then((response) => {
        if (response.status === 200) {
          // loadPubs().then(() => {
          //   setIsCanceling(false);
          //   setInviteToCancel(null);
          // });
        } else {
          setIsCanceling(false);
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      })
      .catch((err) => {
        setIsCanceling(false);
        setError(err.message);
      });
  };

  return (
    isLoading ? (
      <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
        <Spinner size="2em" variant="page" />
      </Box>
    ) : (
      <Box>
        <div ref={pageRef} />
        {invites.length === 0 ? (
          <Card variant="panel" mt="1.5em">
            <BaseEmptyState
              title="No Connections Pending"
              message="You can connect with a publisher to admin their catalog or have them perform admin functions for your organization."
              // action={addNewConnection}
              actionText="Add Connection"
              illustration="composition"
              minHeight="38em"
            />
          </Card>
        ) : (
          <Fragment>
            <TableControls
              itemName="Invitation"
              tableData={invites}
              currentResults={currentResults}
              indexOfFirst={indexOfFirst}
              indexOfLast={indexOfLast}
              showSearch
              searchPlaceholder="Search by email..."
              searchActive={searchActive}
              searchResults={invitations}
              handleSearch={onSearchResults}
            />
            <Table mb="1em">
              <TableHeader>
                <TableCell widthPercent="48%" pr="0.75em">Publisher Contact Email</TableCell>
                <TableCell>Sent</TableCell>
                <TableCell>Invite Expires</TableCell>
                <TableCell>{' '}</TableCell>
              </TableHeader>
              {currentResults.map((invite) => (
                <TableRow>
                  <TableCell widthPercent="48%" pr="0.75em">
                    {invite.email}
                  </TableCell>
                  <TableCell mobileLabel="Sent:">{d(invite.createdDate)}</TableCell>
                  <TableCell mobileLabel="Expires:">
                    {d((new Date(new Date(invite.createdDate).getTime() + 7 * 24 * 60 * 60 * 1000)).toISOString())}
                  </TableCell>
                  <TableCell pr="1em">
                    <Box display="flex" justifyContent="flex-end">
                      <Icon size="1em" pt="0.25em" pr="0.25em" cursor onClick={() => setInviteToCancel(invite)}><RemoveIcon /></Icon>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {(searchActive && currentResults.length === 0)
              && (
                <TableSearchEmptyPrompt>
                  <Text color="var(--text-muted-panel)">No Invitations Match Your Search</Text>
                </TableSearchEmptyPrompt>
              )}
            </Table>
            <TablePagination
              itemsPerPage={itemsPerPage}
              totalItems={invitations.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </Fragment>
        )}

        {inviteToCancel
        && (
        <DeleteConfirmationOverlay
          headerLabel="Are you sure?"
          header="Cancel Publisher Invitation"
          explanation="The existing invitation will be invalidated and the publisher will not be able to accept."
          closeFunction={() => setInviteToCancel(null)}
          handleDelete={handleCancelInvite}
          error={error}
          isLoading={isCanceling}
          confirmationPhrase={inviteToCancel.email}
          buttonText="Cancel Invitation"
        />
        )}
      </Box>
    )
  );
}

PendingPublisherConnections.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  invites: PropTypes.array.isRequired,
};

export default PendingPublisherConnections;
