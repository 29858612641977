/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';

import AppState from '@state';

import Box from '@ui-kit/box';
import Header from '@ui-kit/typography/header';
import BaseButton from '@ui-kit/buttons/baseButton';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';
import FilterStatus from './filterComponents/filterStatus';
import FilterInternalTags from './filterComponents/filterInternalTags';
import FilterCompositionTags from './filterComponents/filterCompositionTags';
import FilterPublisherConnection from './filterComponents/filterPublisherConnection';

function TableFilterModal({
  filterConfig,
  closeFunction,
  activeFilters,
}) {
  const [statusFilter, setStatusFilter] = useState(activeFilters.status);
  const [internalTagsFilter, setInternalTagsFilter] = useState(activeFilters.internalTags);
  const [compositionTagsFilter, setCompositionTagsFilter] = useState(activeFilters.compositionTags);
  const [connectedPublishersFilter, setconnectedPublishersFilter] = useState(activeFilters.connectedPublishers);

  const activeClientConnections = AppState.orgConnections.value.filter((connection) => connection.isActive && connection.connectionIsClient);

  const onClickSaveFilters = () => {
    const filters = {
      status: statusFilter,
      internalTags: internalTagsFilter,
      compositionTags: compositionTagsFilter,
      connectedPublishers: connectedPublishersFilter,
    };
    filterConfig.handleFilters(filters);
    closeFunction();
  };

  const handleStatusFilterChange = (status) => {
    setStatusFilter(status);
  };

  const handleInternalTagFilterChange = (internalTags) => {
    setInternalTagsFilter(internalTags);
  };

  const handleCompositionTagFilterChange = (compTags) => {
    setCompositionTagsFilter(compTags);
  };

  const handleConnectionApplied = (pubs) => {
    setconnectedPublishersFilter(pubs);
  };

  return (
    <BaseEditOverlay formWidth="26em" closeFunction={closeFunction}>
      <Header mb="1.5rem">{filterConfig.modalHeader}</Header>
      <Box minHeight="18em">
        <FilterStatus handleStatusApplied={handleStatusFilterChange} appliedStatus={statusFilter} />
        <FilterInternalTags handleInternalTagApplied={handleInternalTagFilterChange} appliedTags={internalTagsFilter} />
        <FilterCompositionTags handleCompositionTagsApplied={handleCompositionTagFilterChange} appliedTags={compositionTagsFilter} />
        {activeClientConnections.length > 0 && (
          <FilterPublisherConnection handleConnectionApplied={handleConnectionApplied} appliedConnections={connectedPublishersFilter} />
        )}
      </Box>
      <Box>
        <BaseButton
          mb="1.5em"
          btnText="Save Filters"
          fluid
          onClick={onClickSaveFilters}
        />
      </Box>
    </BaseEditOverlay>
  );
}

TableFilterModal.propTypes = {
  filterConfig: PropTypes.object.isRequired,
  closeFunction: PropTypes.func.isRequired,
  activeFilters: PropTypes.object.isRequired,
};

export default TableFilterModal;
