import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const ModalPage = styled.div`
  position:fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 3em;
  background: var(--modal-overlay);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
  backdrop-filter: blur(10px);

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 0em;
  }
`;

export const ModalContainer = styled.div`
  max-width: 90em;
  margin: 0 auto;
  width: 100%;
  min-height: 100%;
  background: var(--panel);
  border: 0.8px solid var(--card-border);
  box-shadow: 2px 4px 11px var(--card-shadow);
  border-radius: 2em;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 0em;
    height: auto;
    border-radius: 0em;
    background: var(--mobile-background);
  }
`;

export const ModalUtilityRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 2em 2em 0em 2em;

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 1.5em 1.5em 0.5em 1.5em;
  }
`;

export const ModalContentWrapper = styled.div(({
  formCentered,
}) => `
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: ${formCentered ? 'center' : 'flex-start'};
  flex-grow: 1;
  padding: 2em;

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 1.5em;
  }
`);

export const ModalFormWrapperCentered = styled.div(({
  formWidth,
}) => `
  width:  ${formWidth || '20rem'};
  transform: translateY(-4em);

  @media screen and (max-height: 50em) {
    transform: translateY(0em);
  }

  @media screen and (max-width: ${pointBreaks.sm}) {
    transform: translateY(0em);
  }
`);

export const ModalFormWrapper = styled.div(({
  formWidth,
}) => `
  width:  ${formWidth || '20rem'};

  @media screen and (max-width: ${pointBreaks.sm}) {
  }

`);
