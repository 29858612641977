/* eslint-disable react/forbid-prop-types */
import { useState, useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import { route } from 'preact-router';

import AppState from '@state';

import { deleteWorkspaceAccount } from '@api/restricted/workspace-account-api';

import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import Box from '@ui-kit/box';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import Spinner from '@ui-kit/loaders/Spinner';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';

import { COMPOSITION_EXPLANATIONS } from '@constants/supportingCopy';

function LeaveWorkspace({
  closeFunction,
  activeUser,
}) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState('Loading');

  useEffect(() => {
    const numAccounts = AppState.workspaceAccounts.value.length;
    const numAdmins = AppState.workspaceAccounts.value.filter((acc) => acc.isAdmin).length;
    if (numAccounts < 2) {
      setMode('SingleCantLeave');
    } else if (activeUser.isAdmin && numAdmins < 2) {
      setMode('AdminCantLeave');
    } else if (activeUser.isWriter && AppState.composition.isLocked.value) {
      const numWriters = AppState.workspaceAccounts.value.filter((acc) => acc.isWriter).length;
      if (numWriters > 1) {
        setMode('WriterCantLeave');
      } else {
        setMode('SingleWriterCantLeave');
      }
    } else {
      setMode('CanLeave');
    }
  }, []);

  const onCancelLeave = () => {
    setError(null);
    closeFunction();
  };

  const handleLeaveWorkspace = () => {
    setIsLoading(true);
    deleteWorkspaceAccount(AppState.composition.id.value, {
      id: AppState.accountId.value,
      hasJoined: true,
    })
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          route('/compositions');
        } else {
          response.json()
            .then((json) => setError(json.error ? json.error : response.statusText))
            .catch(() => setError(response.statusText));
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return (
    <BaseEditOverlay formCentered closeFunction={onCancelLeave}>
      {{
        Loading:
  <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
    <Spinner size="2em" variant="page" />
  </Box>,

        SingleCantLeave:
  <Fragment>
    <ConversationPrompt
      primaryMessage="You are the only one here…"
      promptLabel="Single Writer"
      labelColor="var(--red-300)"
      mb="1em"
    />
    <ConversationExplanation mb="2em" explanation={COMPOSITION_EXPLANATIONS.leaveWorkspace.singleParticipantBlocked} truncateAtCharacter={500} />
  </Fragment>,

        AdminCantLeave:
  <Fragment>
    <ConversationPrompt
      primaryMessage="You need another moderator"
      promptLabel="Single Moderator"
      labelColor="var(--red-300)"
      mb="1em"
    />
    <ConversationExplanation mb="2em" explanation={COMPOSITION_EXPLANATIONS.leaveWorkspace.singleAdminBlocked} truncateAtCharacter={500} />
  </Fragment>,

        WriterCantLeave:
  <Fragment>
    <ConversationPrompt
      primaryMessage="You can’t leave this Composition"
      promptLabel="Contract Exists"
      labelColor="var(--red-300)"
      mb="1em"
    />
    <ConversationExplanation mb="2em" explanation={COMPOSITION_EXPLANATIONS.leaveWorkspace.contractExistsBlocked} truncateAtCharacter={500} />
  </Fragment>,

        SingleWriterCantLeave:
  <Fragment>
    <ConversationPrompt
      primaryMessage="You can’t leave this Composition"
      promptLabel="Composition Locked"
      labelColor="var(--red-300)"
      mb="1em"
    />
    <ConversationExplanation mb="2em" explanation={COMPOSITION_EXPLANATIONS.leaveWorkspace.singleWriterBlocked} truncateAtCharacter={500} />
  </Fragment>,

        CanLeave:
  <Fragment>
    <ConversationPrompt
      primaryMessage="You are about to leave the Composition"
      promptLabel="Are you sure?"
      labelColor="var(--red-300)"
      mb="1em"
    />
    <ConversationExplanation mb="2em" explanation={COMPOSITION_EXPLANATIONS.leaveWorkspace.warning} truncateAtCharacter={500} />
    {error && (
    <AlertMessage
      variant="negative"
      message={error}
      mb="1.5em"
    />
    )}
    <BaseButton
      btnText="Leave Composition"
      variant="danger"
      spinnerVariant="danger"
      fluid
      isLoading={isLoading}
      onClick={handleLeaveWorkspace}
    />
  </Fragment>,
      }[mode]}
    </BaseEditOverlay>
  );
}

LeaveWorkspace.propTypes = {
  closeFunction: PropTypes.func.isRequired,
  activeUser: PropTypes.object.isRequired,
};

export default LeaveWorkspace;
