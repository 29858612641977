/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const StyledWriterCard = styled.div`
    background: var(--panel-card);
    border-radius: 1rem;
    width: 100%;
    padding: 0.75rem;
    cursor: pointer;
    border: 0.8px solid var(--card-border);
    margin-bottom: 1rem;
    position: relative;

    @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 1em;
    }
`;

export const WriterCardRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
`;

export const WriterCardDetail = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
`;

export const WriterCardOwnership = styled.div`
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
`;

export const writerContent = styled.div`
    background: var(--panel-card);
    border-radius: 0.5em;
    width: 100%;
    min-height: 20em;
    padding: 2em;
    cursor: pointer;

    @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 1em;
    }
`;

export const SignatureChip = styled.div`
    background: var(--action-purple);
    border-radius: 4px;
    position: absolute;
    z-index: 200;
    left: 14px;
    bottom: 8px;
    font-size: 0.7rem;
    font-weight: 900;
    color: var(--white);
    padding: 4px 8px;
    line-height: 1;
    width: 54px;
    text-align: center;

    @media screen and (max-width: ${pointBreaks.sm}) {
        left: 18px;
    }
`;

export const SuccessDot = styled.div`
    height: 8px;
    width: 8px;
    background: var(--green-300);
    border-radius: 50px;
    margin-right: 6px;
`;

export const WarningDot = styled.div`
    height: 8px;
    width: 8px;
    background: var(--orange-300);
    border-radius: 50px;
    margin-right: 6px;
`;
