/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import { route } from 'preact-router';
import AppState from '@state/AppState';

import BaseButton from '@ui-kit/buttons/baseButton';
import AlertMessage from '@ui-kit/alert/Alert';
import Span from '@ui-kit/typography/span';
import LockIcon from '@assets/icons/lock-closed.svg';
import PenIcon from '@assets/icons/sign-pen.svg';
import Text from '@ui-kit/typography/text';
import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import Avatar from '@ui-kit/avatar';
import BreakLine from '@ui-kit/dividers/breakLine';
import LinkButton from '@ui-kit/buttons/linkButton';

import {
  LoggedInWrapper,
  ItemCard,
} from './ActivateReviewStyles';

function ActivateReview({
  handleActivateRep,
  handleDeclineRep,
  repDetails,
  error,
  isLoading,
  isDeclining,
  handleSignOut,
}) {
  const onSubmit = () => {
    handleActivateRep();
  };

  return (
    <Fragment>
      <LoggedInWrapper>
        <Span fontSize="0.75rem" mb="0.25em">
          {AppState.userProfile.email.value}
          &nbsp;&nbsp;
        </Span>
        <Span fontSize="0.75rem" mb="0.25em" cursor style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleSignOut()}>Sign Out</Span>
      </LoggedInWrapper>

      <BreakLine mb="1.5em" mt="0.5em" color="var(--border)" />

      <Text pl="4px" fontSize="0.875rem" fontWeight="500" mb="0.25em">Verified Identity</Text>
      <ItemCard p="0.75rem">
        <Avatar
          backgroundColor="var(--inactive)"
          borderColor="var(--inactive)"
          size="3.5em"
          imgURL={`/api/account/profile/picture?id=${repDetails.accountId}&timestamp=${AppState.timestamp.value}`}
          isVerified
          checkSize="1.125em"
          checkY="4px"
        />
        <Box ml="1em">
          <Text fontWeight="500" fontFamily="var(--font-lexend)">{repDetails.verifiedName}</Text>
          <Text fontSize="0.813rem" lineHeight="1" color="var(--text-secondary)" mb="6px">{repDetails.email}</Text>
          {repDetails.verifiedCountry && <Text fontSize="0.813rem" lineHeight="1" color="var(--text-secondary)">{repDetails.verifiedCountry}</Text>}
        </Box>
      </ItemCard>

      <Text pl="4px" fontSize="0.875rem" fontWeight="500" mb="0.25em">Account Access</Text>
      <ItemCard p="0.75rem">
        <Box display="flex" justifyContent="center" alignItems="center" width="54px">
          <Icon size="1.6em"><LockIcon /></Icon>
        </Box>
        <Box ml="1em">
          <Text fontWeight="500">{repDetails.isFullAccess ? 'Full Access' : 'View Only Access'}</Text>
          <Text fontSize="0.813rem" lineHeight="1.4" color="var(--text-secondary)">
            {repDetails.isFullAccess
              ? 'They will have full access to manage your account.' : 'They will only be able to view your account and data.'}
          </Text>
        </Box>
      </ItemCard>

      <Text pl="4px" fontSize="0.875rem" fontWeight="500" mb="0.25em">Signing Splits</Text>
      <ItemCard p="0.75rem" mb="2.5rem">
        <Box display="flex" justifyContent="center" alignItems="center" width="54px">
          <Icon size="1em"><PenIcon /></Icon>
        </Box>
        <Box ml="1em">
          <Text fontWeight="500">{(repDetails.isSignSplits && !repDetails.isSignSplitsDeclined) ? 'Can Sign Your Splits' : 'No Signing Allowed'}</Text>
          {!repDetails.isSignSplits && <Text fontSize="0.813rem" lineHeight="1.4" color="var(--text-secondary)" style={{ maxWidth: '18rem' }}>You did not grant permission for signing split sheets. Requires power of attorney.</Text>}
          {(repDetails.isSignSplits && repDetails.isSignSplitsDeclined) && <Text fontSize="0.813rem" lineHeight="1.4" color="var(--text-secondary)" style={{ maxWidth: '18rem' }}>The representative could not prove power of attorney.</Text>}
          {(repDetails.isSignSplits && !repDetails.isSignSplitsDeclined) && <Text fontSize="0.813rem" lineHeight="1.4" color="var(--text-secondary)">The representative can sign your split sheets on your behalf.</Text>}
        </Box>
      </ItemCard>

      {(error) && (
      <AlertMessage
        variant="negative"
        message={error}
        mb="1.5em"
      />
      )}

      <BaseButton
        mb="1.5em"
        type="submit"
        btnText="Approve Connection"
        fluid
        isLoading={isLoading}
        disabled={isDeclining}
        onClick={onSubmit}
      />

      <Box mt="2rem" display="flex" justifyContent="center">
        <LinkButton btnText="Back to Representation" variant="small" isLoading={isDeclining} onClick={() => route('/representation')} />
      </Box>

      <Box mt="1.5rem" display="flex" justifyContent="center">
        <LinkButton btnText="Decline Connection" variant="small" spinnerVariant="danger" isLoading={isDeclining} color="var(--red-300)" onClick={handleDeclineRep} />
      </Box>

    </Fragment>
  );
}

ActivateReview.propTypes = {
  handleActivateRep: PropTypes.func.isRequired,
  handleDeclineRep: PropTypes.func.isRequired,
  repDetails: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDeclining: PropTypes.bool.isRequired,
  handleSignOut: PropTypes.func.isRequired,
};

export default ActivateReview;
