import { route } from 'preact-router';
import PropTypes from 'prop-types';

function Redirect({ to }) {
  route(to);

  return (
    null
  );
}

Redirect.propTypes = {
  to: PropTypes.string.isRequired,
};

export default Redirect;
