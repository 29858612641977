import { useState } from 'preact/hooks';
import { route } from 'preact-router';
import { useForm } from 'react-hook-form';

import resetGlobalState from '@state/reset';

import { postAdminLogin } from '@api/admin/admin-login-api';

import FullScreenModal from '@layouts/full-screen/full-screen-modal';

import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import Header from '@ui-kit/typography/header';

function AdminAuth() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const {
    register, handleSubmit, formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const handleLogin = (data) => {
    setIsLoading(true);
    postAdminLogin(data.user, data.password).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        response.json().then((json) => {
          if (json.error) {
            setError(json.error);
          } else {
            setError(response.statusText);
          }
          setIsLoading(false);
        });
      } else {
        response.json().then((json) => {
          setError('');
          resetGlobalState();
          localStorage.setItem('accessToken', json.accessToken);
          setIsLoading(false);
          route('/patchbay/organizations');
        });
      }
    }).catch((err) => {
      setError(err.message);
      setIsLoading(false);
    });
  };

  const onSubmit = (data) => {
    handleLogin(data);
  };

  return (
    <FullScreenModal formWidth="23em" formCentered>
      <Header mb="1em">Patchbay Access</Header>
      <form id="adminForm" onSubmit={handleSubmit(onSubmit)} autoComplete="chrome-off">
        <BaseInput
          {...register('user', { required: true, minLength: 1 })}
          placeholder="User"
          autoComplete="do-not-fill"
          type="text"
          fluid
          mb="1.5em"
        />
        <BaseInput
          {...register('password', { required: true })}
          placeholder="Password"
          autoComplete="one-time-code"
          type="password"
          fluid
          mb="1.5em"
        />
        {error && (
        <AlertMessage variant="negative" message={error} mb="1.5em" />
        )}
        <BaseButton
          mb="4.5em"
          type="submit"
          btnText="Submit"
          disabled={!isValid}
          fluid
          isLoading={isLoading}
        />
      </form>
    </FullScreenModal>
  );
}

export default AdminAuth;
