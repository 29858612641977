import { route } from 'preact-router';
import AppState from '@state';

import { getOrgActiveArtists } from '@api/restricted/org-artists-api';

export default async function loadOrgActiveWriters(orgId) {
  try {
    const response = await getOrgActiveArtists(orgId);
    if (response.status !== 200) {
      try {
        const json = await response.json();
        AppState.exceptions.errorPageError.value = `${json.error || response.statusText} when loading active writers`;
        route('/error/exception', true);
      } catch {
        AppState.exceptions.errorPageError.value = `${response.statusText} when loading active writers`;
        route('/error/exception', true);
      }
    } else {
      const json = await response.json();
      const formattedWriters = json.artists.map((w) => ({
        id: w.id,
        firstName: w.firstName,
        lastName: w.lastName,
        avatarURL: w.imgURL,
        expirationDate: w.expiry,
        totalCompositions: w.numComps,
        ipi: w.ipi,
        isni: w.isni,
        societies: w.societies,
        isVerified: w.isVerified,
      }));
      AppState.publisherActiveSongwriters.value = formattedWriters;
    }
  } catch (err) {
    AppState.exceptions.errorPageError.value = `${err.message} when loading active writers`;
    route('/error/exception', true);
  }
}
