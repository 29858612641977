/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import {
  StyledCredentialCardGrid,
} from './CredentialCardGridStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function CredentialCardGrid({
  children,
}) {
  return (
    <StyledCredentialCardGrid>
      {children}
    </StyledCredentialCardGrid>
  );
}

CredentialCardGrid.propTypes = {
  children: childrenPropTypes,
};

CredentialCardGrid.defaultProps = {
  children: null,
};

export default CredentialCardGrid;
