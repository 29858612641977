import { useState } from 'preact/hooks';
import { Fragment } from 'preact';
import PropTypes from 'prop-types';

import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseButton from '@ui-kit/buttons/baseButton';
import Header from '@ui-kit/typography/header';
import Text from '@ui-kit/typography/text';
import AlertMessage from '@ui-kit/alert/Alert';
import Box from '@ui-kit/box/Box';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';

import MailWedge from '@animations/wedges/MailWedge';

function SignUpForm({
  handleSignup,
  onClickBackButton,
  error,
  isLoading,
}) {
  const [userEmail, setUserEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [currentStep, setCurrentStep] = useState('enterEmail');

  const validateEmail = (email) => String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const handleEmailValue = (event) => {
    event.persist();
    setUserEmail(event.target.value);
    if (validateEmail(event.target.value)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const handleEmailSubmit = async () => {
    const cleanedEmail = userEmail.toLowerCase();
    const success = await handleSignup(cleanedEmail);
    if (success) setCurrentStep('emailSent');
  };

  const handleEmailResend = async () => {
    const cleanedEmail = userEmail.toLowerCase();
    const success = await handleSignup(cleanedEmail);
    if (success) setCurrentStep('emailResent');
  };

  const handleStartOver = () => {
    setCurrentStep('enterEmail');
    setUserEmail('');
    setEmailValid(false);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 && emailValid === true) {
      e.preventDefault();
      handleEmailSubmit();
    }
  };

  return (
    <Box>
      {{
        enterEmail:
        (
          <Fragment>
            <Header mb="0.5em">Sign Up</Header>
            <Text variant="thinPrompt" mb="1.5em">
              Enter your email and we will send you a magic link to get started.
            </Text>
            <BaseInput
              placeholder="you@yourdomain.com"
              id="email"
              name="email"
              type="email"
              autocomplete="on"
              defaultValue=""
              fluid
              mb="1.5rem"
              onChange={handleEmailValue}
              onKeyPress={(e) => handleKeyPress(e)}
            />
            {error && (
              <AlertMessage
                variant="negative"
                message={error}
                mb="1.5em"
              />
            )}
            <BaseButton mb="1.5em" btnText="Get Started" onClick={() => handleEmailSubmit()} fluid isLoading={isLoading} disabled={!emailValid} />
            <Box display="flex" justifyContent="center">
              {onClickBackButton ? (
                <LinkButton
                  btnText="Go Back"
                  onClick={onClickBackButton}
                />
              ) : (
                <Text><a href="/login">Back to Login</a></Text>
              )}
            </Box>
          </Fragment>
        ),
        emailSent:
        (
          <Fragment>
            <Box mb="1em" display="flex" alignItems="center">
              <MailWedge />
              <Header ml="1rem">Check Your Email</Header>
            </Box>
            <Text variant="thinPrompt" mb="1.5em">
              We sent a magic link to complete your registration to:
              <Text mt="0.5em" fontSize="1rem">{userEmail}</Text>
            </Text>
            <Box mb="0.5rem" display="flex" alignItems="center">
              <LinkButton mr="1em" btnText="Resend Email" onClick={() => handleEmailResend()} isLoading={isLoading} />
              <LinkButton btnText="Start Over" onClick={() => handleStartOver()} />
            </Box>
          </Fragment>
        ),
        emailResent:
        (
          <Fragment>
            <Box mb="1em" display="flex" alignItems="center">
              <MailWedge />
              <Header ml="1rem">On the way again...</Header>
            </Box>
            <Text variant="thinPrompt" mb="1.5em">
              We resent the email. This is usually pretty fast but give
              it a moment and check other mailboxes for:
              <Text mt="0.5em" fontSize="1rem">{userEmail}</Text>
            </Text>
            <Box mb="0.5rem" display="flex" alignItems="center">
              <LinkButton btnText="Start Over" onClick={() => handleStartOver()} />
            </Box>
          </Fragment>
        ),
      }[currentStep]}
    </Box>
  );
}

SignUpForm.propTypes = {
  handleSignup: PropTypes.func.isRequired,
  onClickBackButton: PropTypes.func,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

SignUpForm.defaultProps = {
  onClickBackButton: null,
};

export default SignUpForm;
