import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import Text from '@ui-kit/typography/text';

export const WriterProfileWrapper = styled.div`
    background: var(--panel-info);
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    border-radius: 0.5em;
    border-top-right-radius: 0em;
    border-top-left-radius: 0em;
`;

export const WriterProfileDetails = styled.div`
    min-height: 5em;
    padding: 1em;
    border-top: 1px solid var(--panel-info-border);
`;

export const ProfileDetailRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        margin-bottom: 0em;
    }
`;

export const ProfileDetailItem = styled.div`
    width: inherit;
    margin-right: 2em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        width: 100%; 
        margin-right: 0em;
        margin-bottom: 1em;
    }
`;

export const ProfileDataLabel = styled(Text)`
    font-weight: 600;
    font-size: 0.75em;
    margin-bottom: 0.25em;
`;

export const ProfileDataPoint = styled(Text)`
    font-size: 0.75em;
`;

export const ProfileEditPrompt = styled(Text)`
    text-align: right;
    font-size: 0.688rem;
    font-weight: 400;
    font-style: italic;
    color: var(--text-muted-panel);
`;

export const SuccessDot = styled.div`
    height: 8px;
    width: 8px;
    background: var(--green-300);
    border-radius: 50px;
    margin-right: 6px;
`;

export const WarningDot = styled.div`
    height: 8px;
    width: 8px;
    background: var(--orange-300);
    border-radius: 50px;
    margin-right: 6px;
`;
