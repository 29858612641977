/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Text from '@ui-kit/typography/text';

export const StyledAmountText = styled(Text)(({
  amount,
}) => `
    ${amount > 100 && 'color: var(--red-400)'};
    ${amount === 100 && 'color: var(--green-400)'};
    ${amount < 100 && 'color: var(--text-soft-mute)'};
    
`);
