/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useRef } from 'preact/hooks';
import useClickOutside from '@hooks/useClickOutside';
import useKeyPressAction from '@hooks/useKeyPressAction';

import CloseSquare from '@assets/icons/close-square.svg';
import Icon from '@ui-kit/icon';
import Text from '@ui-kit/typography/text';

import {
  ModalPage,
  ModalContainer,
  ModalUtilityRow,
  ModalContentWrapper,
} from './InformationModalStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function InformationModal({
  title,
  children,
  closeFunction,
  showCloseFunction,
  modalWidth,
}) {
  const ref = useRef();
  useClickOutside(ref, () => closeFunction());

  useKeyPressAction('Escape', () => {
    closeFunction();
  });

  return (
    <ModalPage id="modal-page">
      <ModalContainer ref={ref} modalWidth={modalWidth}>
        <ModalUtilityRow>
          <Text fontSize="1.125rem" fontWeight="600" color="var(--text-primary)">{title}</Text>
          {closeFunction && showCloseFunction
          && <Icon size="1.5em" cursor onClick={closeFunction}><CloseSquare /></Icon>}
        </ModalUtilityRow>
        <ModalContentWrapper>
          {children}
        </ModalContentWrapper>
      </ModalContainer>
    </ModalPage>
  );
}

InformationModal.propTypes = {
  children: childrenPropTypes.isRequired,
  closeFunction: PropTypes.any,
  showCloseFunction: PropTypes.bool,
  title: PropTypes.string.isRequired,
  modalWidth: PropTypes.string,
};

InformationModal.defaultProps = {
  closeFunction: null,
  showCloseFunction: true,
  modalWidth: '28em',
};

export default InformationModal;
