/* eslint-disable import/prefer-default-export */
import { repCheckoutUrl } from '../urls';

export async function postRepCheckout(priceId, successURL, cancelURL) {
  const data = {
    price: priceId,
    successURL,
    cancelURL,
  };
  const response = await fetch(repCheckoutUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
  return response;
}
