import { useState, useEffect } from 'preact/hooks';
import { Fragment } from 'preact';
import { route } from 'preact-router';
import anime from 'animejs/lib/anime.es';
import AppState from '@state';

import {
  getCompositionIdentity,
  getCompositionIdentityCode,
} from '@api/public/composition-identity-api';
import useIsoDateFormat from '@hooks/useIsoDateFormat';
import useFileSize from '@hooks/useFileSize';

import SwitchLogo from '@assets/logos/switchchord-logo-main.svg';
import BlueCheck from '@assets/icons/blue-check.svg';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Span from '@ui-kit/typography/span';
import Icon from '@ui-kit/icon';
import Header from '@ui-kit/typography/header';
import PanelCard from '@distinct-components/cards/panelCard';
import Card from '@distinct-components/cards/baseCard';
import WriterCompositionSummaryCard from '@distinct-components/cards/writerCompositionSummaryCard';
import ConnectingCopyright from '@animations/identity/ConnectingCopyright';
import AlertMessage from '@ui-kit/alert/Alert';
import AudioPlayer from '@distinct-components/audioPlayer';
import QuestionPrompt from '@distinct-components/feedback/questionPrompt';
import FileCard from '@distinct-components/files/fileCard/FileCard';
import PlayIcon from '@assets/icons/play-icon.svg';
import ActivePlayerAnimation from '@animations/general/ActivePlayerAnimation';

import { IDENTITY_EXPLANATIONS } from '@constants/supportingCopy';

import {
  StyledPublicHeader,
  ContentWrapper,
  Content,
  LyricViewWrapper,
  ItemPlayButton,
} from './CompositionIdentityPreviewStyles';

function CompositionIdentityPreview(params) {
  const { id, version } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [previewData, setPreviewData] = useState();
  const [latestVersion, setLatestVersion] = useState();
  const [recordings, setRecordings] = useState([]);
  const [tags, setTags] = useState([]);
  const [token, setToken] = useState(null);
  const [code, setCode] = useState(null);

  const d = useIsoDateFormat;

  const loadAnimation = anime.timeline({
    autoplay: true,
    delay: 200,
  });

  const useQuery = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams;
  };

  const loadContractData = (handler, secret) => {
    handler(id, secret, version).then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          const c = json.composition;
          const newContractData = {
            did: c.uuid,
            executedDate: c.executedDate,
            compositionDID: c.did,
            compositionName: c.name,
            compositionAlternateTitles: c.alternateTitles,
            compositionDate: c.writtenDate,
            compositionUse: c.intendedUse,
            isInstrumental: c.isInstrumental,
            lyrics: c.lyrics,
            signingParties: c.writers.map((p) => ({
              accountId: p.id,
              legalName: p.legalName,
              persona: p.persona,
              uuid: p.uuid,
              did: p.did,
              avatarURL: p.imgURL,
              share: p.share,
              contribution: p.contribution,
              ipi: p.ipi,
              isni: p.isni,
              isVerified: p.isVerified,
              verifiedFirstName: p.verifiedFirstName,
              verifiedLastName: p.verifiedLastName,
              verifiedCountry: p.verifiedCountry,
              verifiedOn: p.verifiedOn,
              societies: p.societies.join(', '),
              pubPreset: p.pubPreset?.name ? {
                name: p.pubPreset.name,
                owners: p.pubPreset.publishers.filter((pub) => pub.share > 0).map(({ territory, share, ...rest }) => ({ ownedPercentage: share, ...rest })),
                admins: p.pubPreset.publishers.filter((pub) => pub.territory).map(({ share, ...rest }) => rest),
              } : null,
            })),
          };
          setPreviewData(newContractData);

          if (json.latestVersion > version) {
            setLatestVersion(json.latestVersion);
          }
          setRecordings(json.recordings);
          setTags(json.tags.map(({ name }) => name));
        }).catch((err) => {
          AppState.exceptions.errorPageError.value = `${err.message} when loading composition identity`;
          route('/error/exception');
        });
      } else {
        AppState.exceptions.errorPageError.value = `${response.statusText} when loading composition identity`;
        route('/error/exception');
      }
    }).catch((err) => {
      AppState.exceptions.errorPageError.value = `${err.message} when loading composition identity`;
      route('/error/exception');
    });
  };

  useEffect(async () => {
    document.title = 'Switchchord - Identity Viewer';
    document.body.style.backgroundColor = 'var(--background)';
    setTimeout(() => {
      anime({
        targets: '#connecting-wrapper .loader-item',
        opacity: [0, 1],
        translateY: [16, 0],
        easing: 'easeOutExpo',
        duration: 700,
        delay: anime.stagger(100),
      });
    }, 200);

    const query = useQuery();
    if (query.get('code')) {
      setCode(query.get('code'));
      await loadContractData(getCompositionIdentityCode, query.get('code'));
    } else if (query.get('token')) {
      setToken(query.get('token'));
      await loadContractData(getCompositionIdentity, query.get('token'));
    } else {
      // TODO: Throw error?
    }

    setTimeout(() => {
      setIsLoading(false);
      setTimeout(() => {
        loadAnimation.add({
          targets: '#content .content-item',
          translateY: ['16px', 0],
          opacity: [0, 1],
          duration: 600,
          direction: 'normal',
          delay: anime.stagger(100),
        });
        loadAnimation.play();
      }, 500);
    }, 2700);
  }, []);

  const onClickLatestVersion = () => {
    if (token) {
      route(`/identity-viewer/composition/${id}/${latestVersion}?token=${token}`);
    } else if (code) {
      route(`/identity-viewer/composition/${id}/${latestVersion}?code=${code}`);
    }
    window.location.reload();
  };

  const handlePlayRecording = (rec) => {
    if (AppState.media.currentlyPlaying.value === rec && AppState.media.isPlaying.value === true) {
      AppState.media.currentlyPlaying.value = null;
      AppState.media.isPlaying.value = false;
    } else {
      AppState.media.currentlyPlaying.value = rec;
    }
  };

  return (
    <Box style={AppState.media.currentlyPlaying.value && { marginBottom: '3.5em' }}>
      <StyledPublicHeader>
        <Icon className="logo" size="172px" mr="5em"><SwitchLogo /></Icon>
        <Box>
          <a href="https://switchchord.com" target="_blank" rel="noreferrer">Learn More</a>
        </Box>
      </StyledPublicHeader>
      <ContentWrapper>
        <Content id="content">
          <Box display="flex" justifyContent="space-between">
            <Text mb="0.125em">Identity Viewer</Text>
            <QuestionPrompt questionPrompt={IDENTITY_EXPLANATIONS.previewSummary} />
          </Box>
          <Card mt="1em" mb="1.5em" variant="panel">
            {isLoading
              ? (
                <Box id="connecting-wrapper" className="identity-header" display="flex" justifyContent="center" alignItems="center" minHeight="10em">
                  <Box>
                    <ConnectingCopyright className="loader-item" style={{ opacity: 0 }} />
                    <Box display="flex" alignItems="center" flexDirection="column">
                      <Text mt="0.75em" fontWeight="600" color="var(--trust-text)" className="loader-item" style={{ opacity: 0 }}>
                        Connecting to Copyright Identity
                      </Text>
                    </Box>
                  </Box>
                </Box>
              )
              : (
                <Box id="info-wrapper" minHeight="7em">
                  <Header mb="0.125em">{previewData?.compositionName}</Header>
                  {previewData?.compositionDID
                && (
                <Text mt="0.75em" className="break-word" color="var(--text-secondary)">
                  {previewData.compositionDID}
                </Text>
                )}
                  <Box display="flex" alignItems="center" mt="0.5em">
                    <Icon size="1em" pt="3px" mr="0.5em"><BlueCheck /></Icon>
                    <Text fontWeight="600" color="var(--trust-text)">
                      Validated Composition Identity
                    </Text>
                  </Box>
                </Box>
              )}
          </Card>
          {!isLoading && latestVersion
           && (
           <AlertMessage
             variant="default"
             message="An Amendment to the Composition was signed. Newer data available. View Now."
             mb="1.5em"
             className="cursor-p"
             onClick={onClickLatestVersion}
           />
           )}
          {!isLoading
          && (
            <Fragment>
              <PanelCard
                mb="1.5em"
                title="Composition Details"
                className="content-item"
                style={{ opacity: 0 }}
              >
                <Box mb="0.5em">
                  <Span fontWeight="600">Alternate Titles:</Span>
                  &nbsp;&nbsp;
                  <Span>{previewData.compositionAlternateTitles.length > 0 ? previewData.compositionAlternateTitles.join(', ') : '(None)'}</Span>
                </Box>
                <Box mb="0.5em">
                  <Span fontWeight="600">Tags:</Span>
                  &nbsp;&nbsp;
                  <Span>{tags.length > 0 ? tags.join(', ') : '(None)'}</Span>
                </Box>
                <Box mb="0.5em">
                  <Span fontWeight="600">Date Written:</Span>
                  &nbsp;&nbsp;
                  <Span>{d(previewData.compositionDate)}</Span>
                </Box>
                <Box mb="0.5em">
                  <Span fontWeight="600">Intended Use:</Span>
                  &nbsp;&nbsp;
                  <Span>{previewData.compositionUse}</Span>
                </Box>
                <Box mb="0.5em">
                  <Span fontWeight="600">Songwriters:</Span>
                  &nbsp;&nbsp;
                  <Span>{previewData.signingParties.length}</Span>
                </Box>
                {previewData.executedDate
                && (
                <Box mb="0.5em">
                  <Span fontWeight="600">Split Sheet Executed:</Span>
                  &nbsp;&nbsp;
                  <Span>{d(previewData.executedDate)}</Span>
                </Box>
                )}
              </PanelCard>

              <PanelCard
                mb="1.5em"
                title="Earliest Recordings"
                className="content-item"
                description={recordings.length === 0
                  ? 'No Work Tapes or Recordings Added' : ''}
                style={{ opacity: 0 }}
              >
                {recordings.sort((a, b) => b.id - a.id).map((recording) => (
                  <FileCard
                    file={recording}
                    primaryDetail={useFileSize(recording.size)}
                    showMenu={false}
                  >
                    <ItemPlayButton onClick={() => handlePlayRecording(recording)}>
                      {(AppState.media.currentlyPlaying.value === recording && AppState.media.isPlaying.value === true)
                        ? (
                          <ActivePlayerAnimation />
                        )
                        : (
                          <Icon size="1em" pt="2px" pl="1px" cursor color="var(--white)"><PlayIcon /></Icon>
                        )}
                    </ItemPlayButton>
                  </FileCard>
                ))}
              </PanelCard>

              <PanelCard
                mb="1.5em"
                title="Songwriter Identities"
                className="content-item"
                style={{ opacity: 0 }}
              >
                <Box mt="0.25em" />
                {previewData.signingParties.map((p) => (
                  <WriterCompositionSummaryCard writer={p} mb="1.25em" />
                ))}
              </PanelCard>

              <PanelCard
                mb="1.5em"
                title="Composition Lyrics"
                className="content-item"
                style={{ opacity: 0 }}
                description={previewData.isInstrumental
                  ? 'This Composition was marked "Instrumental" by one of the writers.'
                  : 'If Lyrics have been entered they will appear below'}
              >
                {previewData.lyrics && (
                  <LyricViewWrapper mt="1em">{previewData.lyrics}</LyricViewWrapper>
                )}
              </PanelCard>

            </Fragment>
          )}
        </Content>
      </ContentWrapper>
      <AudioPlayer uuid={id} code={code} token={token} />
    </Box>
  );
}

export default CompositionIdentityPreview;
