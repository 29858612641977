import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import Text from '@ui-kit/typography/text';

export const StyledVerifyWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const StyledVerifyCard = styled.div`
    background: var(--panel-card);
    border: 0.8px solid (var--border);
    border-radius: 0.5em;
    padding: 1.75em 2em 2em 2em;
    width: 26em;
    margin-top: 2em;

    @media screen and (max-width: ${pointBreaks.md}) {
        width: 100%;
        margin-top: 0.5em;
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
        background: var(--card);
        padding: 1.25em 1.125em 1em 1.125em;
        margin-top: 0em;
        transform: translateY(-16px);
    }
`;

export const StyledPrompt = styled(Text)`
    font-size: 0.75rem;
    line-height: 2;
    font-weight: 300;
    color: var(--text-soft-mute);
    text-align: center;
`;
