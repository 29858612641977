import styled from 'styled-components';
import { space, typography } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const FeedbackLabel = styled.div(
  ({
    isValid,
  }) => `
    font-size: 0.75rem;
    color: ${isValid ? 'var(--green-400)' : 'var(--text-medium-mute)'};
    pointer-events: none;
    ${space}
    ${typography}

    @media screen and (max-width: ${pointBreaks.sm}) {
      margin-bottom: 1em;
    }
`,

  space,

  typography,
);

export const CheckWrapper = styled.div(
  ({
    isValid,
  }) => `
transform: translate(0.063em, -0.2em);
svg {
    height: 8px;
    width: auto;
    path {
        fill: ${isValid ? 'var(--green-400)' : 'var(--text-medium-mute)'};
    }
}
${space}
`,
);
