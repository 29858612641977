import { useEffect } from 'preact/hooks';
import AppState from '@state';

import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import WarningIcon from '@assets/icons/alert-hazard.svg';
import InfoIcon from '@assets/icons/info-circle.svg';
import SuccessIcon from '@assets/icons/success-circle.svg';
import CloseIcon from '@assets/icons/close-solo.svg';

import { Toast, ToastWrapper, ToastDismissWrapper } from './SimpleToastStyles';

function SimpleToast({
  ...restProps
}) {
  const MESSAGE = AppState.messages.toastMessage.value;

  const onClearMessage = () => {
    AppState.messages.toastMessage.value = null;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      onClearMessage();
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [MESSAGE]);

  return (
    <Box id="toast-wrap">
      {MESSAGE
      && (
        <ToastWrapper>
          <Toast {...restProps} variant={MESSAGE.variant}>
            <Box display="flex" alignItems="center">
              {MESSAGE.variant === 'success' && <Icon mr="0.5em" pt="3px" size="1em" color="var(--green-300)"><SuccessIcon /></Icon>}
              {MESSAGE.variant === 'error' && <Icon mr="0.5em" pt="3px" size="1em" color="var(--red-300)"><WarningIcon /></Icon>}
              {MESSAGE.variant === 'info' && <Icon mr="0.5em" pt="3px" size="1em" color="var(--blue-300)"><InfoIcon /></Icon>}
              <Text fontWeight="500" fontSize="0.875rem">{MESSAGE.text}</Text>
            </Box>
            <ToastDismissWrapper onClick={onClearMessage}>
              <Icon size="0.5em" color="var(--text-primary)" cursor><CloseIcon /></Icon>
            </ToastDismissWrapper>
          </Toast>
        </ToastWrapper>
      )}
    </Box>
  );
}

export default SimpleToast;
