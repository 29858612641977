import { Fragment } from 'preact';
import { route } from 'preact-router';
import AppState from '@state';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import BaseButton from '@ui-kit/buttons/baseButton';
import Text from '@ui-kit/typography/text';

function ActivateDeclined() {
  const onClickContinue = () => {
    route('/representation');
    AppState.navigation.backActionPath.value = '/representation';
  };
  return (
    <Fragment>
      <ConversationPrompt
        primaryMessage="You declined the connection"
        promptLabel="Not Activated"
        labelColor="var(--red-300)"
        mb="1em"
      />
      <Text mb="2em" fontSize="1rem">We sent an email to your representative letting them know that you declined their access. You can create a new request if you wish to add them again in the future.</Text>
      <BaseButton
        mt="2em"
        btnText="Go to Representation"
        fluid
        onClick={onClickContinue}
      />
    </Fragment>
  );
}

export default ActivateDeclined;
