/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';

export const StyledListInput = styled(BaseInput)(({
  itemCount,
}) => `
  padding-right: 2.75em;
  border-top-left-radius: ${itemCount > 0 ? '0em' : '0.5em'};
  border-top-right-radius: ${itemCount > 0 ? '0em' : '0.5em'};
  transition: none;

  &:focus {
    border: none;
  }
`);

export const StyledItemWrapper = styled.div`
  background: var(--input-bg);
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
`;

export const StyledItem = styled.div`
  border-bottom: 1px solid var(--panel-divider);
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.65em 0.75em;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
`;

export const IconIndicator = styled.div`
  height: 32px;
  width: 48px;
  position: absolute;
  top: 0px;
  left: 2px;
  border-radius: 6px;
  border: 1px solid var(--purple-400);
  transform: scale(0);
  opacity: 0;
`;

export const StyledAddBtn = styled.div`
    height: 32px;
    background: var(--input-bg);
    color: var(--text-primary);
    font-size: 0.813rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 0em 1em;
    border: 1px solid var(--active-nav);
    border-radius: 4px;
`;
