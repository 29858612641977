/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useEffect, useState, useRef } from 'preact/hooks';
import { route } from 'preact-router';
import AppState from '@state';
import resetGlobalState from '@state/reset';

import useClickOutside from '@hooks/useClickOutside';

import loadAccountProfile from '@actions/loadAccountProfile';

import Box from '@ui-kit/box';
import Avatar from '@ui-kit/avatar';
import SwitchLogo from '@assets/logos/switchchord-logo-main.svg';
import Icon from '@ui-kit/icon';
import ArrowRight from '@assets/icons/arrow-right.svg';
import PopoverMenu from '@ui-kit/menu/popoverMenu';

import {
  StyledHeader,
  HeaderContentWrapper,
  StyledNameText,
  StyledAccountWrapper,
  StyledSearchBar,
  ActionMenuItem,
  RunSearchChip,
} from './CorePublisherHeaderStyles';

import NavModeToggle from '../navModeToggle';

function CorePublisherHeader() {
  const [searchValue, setSearchValue] = useState('');
  const [showMenu, setShowMenu] = useState(false);

  const ref = useRef(null);
  useClickOutside(ref, () => setShowMenu(false));

  useEffect(() => {
    if (!AppState.profileLoaded.value) {
      loadAccountProfile();
    }
  }, []);

  const handleResetSearch = () => {
    setTimeout(() => {
      setSearchValue('');
    }, 200);
  };

  const onClickSearchComposition = () => {
    if (searchValue.length > 0) {
      AppState.publisherCompositionsTable.value = {
        ...AppState.publisherCompositionsTable.value,
        activeConfig: {
          ...AppState.publisherCompositionsTable.activeConfig.value,
          toggle: 'all',
          query: searchValue,
          filters: {
            status: [],
            internalTags: [],
            compositionTags: [],
          },
        },
        currentPage: 1,
      };
      const headerCount = AppState.publisherCompositionsTable.headerSearchListener.value;
      AppState.publisherCompositionsTable.headerSearchListener.value = headerCount + 1;
      route(`/publisher/${AppState.pubOrganization.uuid.value}/compositions`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 && searchValue.length > 0) {
      e.preventDefault();
      onClickSearchComposition();
    }
  };

  const onClickAvatar = () => {
    setShowMenu(!showMenu);
  };

  const onSignOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    resetGlobalState();
    route('/login');
  };

  return (
    <StyledHeader>
      <HeaderContentWrapper>
        <Box display="flex" alignItems="center">
          <Icon
            className="logo"
            size="172px"
            mr="5em"
            onClick={() => route(`/publisher/${AppState.pubOrganization.uuid.value}/dashboard`)}
            cursor
          >
            <SwitchLogo />
          </Icon>
          <Box position="relative">
            <StyledSearchBar
              value={searchValue}
              placeholder="Search Compositions..."
              onChange={(e) => setSearchValue((e.target.value))}
              onKeyPress={(e) => handleKeyPress(e)}
              onBlur={handleResetSearch}
            />
            {searchValue.length > 0
          && (
          <RunSearchChip onClick={onClickSearchComposition}>
            <Icon size="0.875em" color="var(--white)" cursor><ArrowRight /></Icon>
          </RunSearchChip>
          )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" position="relative">

          <NavModeToggle />

          <StyledAccountWrapper ref={ref} display="flex" onClick={onClickAvatar}>
            <Avatar imgURL={AppState.userProfile.imgURL.value} />
            <Box display="flex">
              <StyledNameText>
                {AppState.userProfile.firstName.value}
                {' '}
                {AppState.userProfile.lastName.value}
              </StyledNameText>
            </Box>
            {showMenu
              && (
              <PopoverMenu ref={ref} top="36px" right="0rem">
                <ActionMenuItem onClick={() => route(`/publisher/${AppState.pubOrganization.uuid.value}/settings`)}>Settings</ActionMenuItem>
                <ActionMenuItem onClick={() => route('/publisher/my-account')}>My Account</ActionMenuItem>
                <ActionMenuItem onClick={onSignOut}>Sign Out</ActionMenuItem>
              </PopoverMenu>
              )}
          </StyledAccountWrapper>
        </Box>
      </HeaderContentWrapper>
    </StyledHeader>
  );
}

export default CorePublisherHeader;
