import { Fragment } from 'preact';
import PropTypes from 'prop-types';

import {
  CardWrapper,
  ContentWrapper,
  IssueDotRow,
  IssueDot,
} from './PanelDataCardStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function PanelDataCard({
  children,
  variant,
  ...restProps
}) {
  return (
    <Fragment>
      {variant === 'warning'
      && (
      <IssueDotRow>
        <IssueDot className="dot">
          !
          <span className="toolTipText">You are a writer on a composition. Complete this item as soon as possible</span>
        </IssueDot>
      </IssueDotRow>
      )}
      <CardWrapper
        variant={variant}
        warning={variant === 'warning'}
        {...restProps}
      >
        <ContentWrapper>
          {children}
        </ContentWrapper>
      </CardWrapper>
    </Fragment>
  );
}

PanelDataCard.propTypes = {
  children: childrenPropTypes.isRequired,
  variant: PropTypes.string,
};

PanelDataCard.defaultProps = {
  variant: null,
};

export default PanelDataCard;
