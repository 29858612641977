/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState } from 'preact/hooks';
import useErrorOverlay from '@hooks/useErrorOverlay';

import { postOrganization, patchOrganizationName } from '@api/admin/admin-organizations-api';
import { postOrgLogo } from '@api/admin/admin-org-logo-api';
import postProfileImageCheck from '@api/sidecar/profile-image-check-api';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import Icon from '@ui-kit/icon';
import CameraIcon from '@assets/icons/camera-outline.svg';
import Spinner from '@ui-kit/loaders/Spinner';

import { ImageUploadButton, ImageContainer } from './AdminCreateEditOrganizationStyles';

function AdminCreateEditOrganization({
  closeFunction,
  organization,
  loadOrganizations,
}) {
  const {
    register, handleSubmit, formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: organization?.id ? organization.name : '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isUpdatingImg, setIsUpdatingImg] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const cleanName = (name) => name.trim().split(/\s+/).join(' ');

  const handleSaveOrganization = (data) => {
    const orgName = cleanName(data.name);
    if (!orgName) {
      setError('Invalid organization name');
      return;
    }

    const handleCreateEditOrganization = async () => (
      organization?.id
        ? patchOrganizationName(organization.id, orgName)
        : postOrganization(orgName)
    );

    setIsLoading(true);
    handleCreateEditOrganization()
      .then((response) => {
        if (response.status === 200) {
          loadOrganizations().then(() => {
            setIsLoading(false);
            setError('');
            closeFunction();
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const convertBase64 = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (err) => {
      reject(err);
    };
  });

  const handleImageUpload = async (event) => {
    setError(null);
    setIsUpdatingImg(true);

    const file = event.target.files[0];
    setPreviewImage(URL.createObjectURL(file));
    if (file.name.includes('.heic')) {
      setError('Heic Images are not supported');
      setIsUpdatingImg(false);
      return;
    }
    if (file.type.split('/')[0] !== 'image') {
      setError('Unsupported File Type');
      setIsUpdatingImg(false);
      return;
    }
    if (file.size >= 10000000) {
      setError('Images need to be less than 10 MB');
      setIsUpdatingImg(false);
      return;
    }

    const base64Img = await convertBase64(file);
    const res = await postProfileImageCheck(base64Img);
    if (res.status !== 200) {
      try {
        const json = await res.json();
        useErrorOverlay({ errorMessage: `${json.error || res.statusText} when performing content policy check` });
      } catch {
        useErrorOverlay({ errorMessage: `${res.statusText} when performing content policy check` });
      }
      return;
    }

    setError(null);
    const json = await res.json();
    if (json.adult === 'LIKELY' || json.adult === 'VERY_LIKELY' || json.racy === 'VERY_LIKELY' || json.violence === 'VERY_LIKELY') {
      setError('This Image Does Not Align with Our Content Policies. Select Another Image');
      setIsUpdatingImg(false);
      return;
    }

    postOrgLogo(organization.id, file.name, file.type, file.size, file)
      .then((response) => {
        if (response.status !== 200) {
          setPreviewImage(null);
          setIsUpdatingImg(false);
          response.json()
            .then((json2) => setError(json2.error || response.statusText))
            .catch(() => setError(response.statusText));
        } else {
          setIsUpdatingImg(false);
        }
      })
      .catch((err) => {
        setPreviewImage(null);
        setIsUpdatingImg(false);
        setError(err.message);
      });
  };

  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>
      <ConversationPrompt
        primaryMessage={organization ? 'Edit Organization' : 'Create Organization'}
        promptLabel="Publishers"
        mb="1.5em"
      />

      {organization && (
      <ImageContainer>
        {previewImage ? <img src={previewImage} alt="org logo" /> : <img src={organization.logoUrl} alt="org logo" />}
        <ImageUploadButton>
          {isUpdatingImg
            ? (
              <Spinner size="1em" width="2px" />
            )
            : (
              <Icon size="0.938em" pt="3px" color="var(--brand-primary)" cursor><CameraIcon /></Icon>
            )}
          <input
            type="file"
            onChange={handleImageUpload}
            disabled={isUpdatingImg}
          />
        </ImageUploadButton>
      </ImageContainer>
      )}

      <form onSubmit={handleSubmit(handleSaveOrganization)} autoComplete="off">
        <InputLabel label="Organization Name" />
        <BaseInput
          {...register('name', { required: true })}
          id="name"
          name="name"
          placeholder="Enter Org Name..."
          fluid
          type="text"
          mb="2.25em"
        />
        {error
        && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
        />
        )}
        <BaseButton mb="1.5em" type="submit" btnText="Save" disabled={!isValid} fluid isLoading={isLoading} />
      </form>
    </BaseEditOverlay>
  );
}

AdminCreateEditOrganization.propTypes = {
  closeFunction: PropTypes.func.isRequired,
  organization: PropTypes.object,
  loadOrganizations: PropTypes.func.isRequired,
};

AdminCreateEditOrganization.defaultProps = {
  organization: null,
};

export default AdminCreateEditOrganization;
