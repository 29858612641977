/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import RemoveIcon from '@assets/icons/close-solo.svg';

import {
  ResultsWrapper,
  ResultRow,
  Result,
  StyledInputWrapper,
  StyledInput,
  StyledItemWrapper,
  StyledItem,
} from './ListsTypeAheadStyles';

function ListsTypeAhead({
  currentLists,
  allLists,
  handleAssignToList,
  handleUnassignFromList,
  isLoadingLists,
}) {
  const [searchValue, setSearchValue] = useState('');
  const [focusedNumber, setFocusedNumber] = useState(-1);
  const [focusedItem, setFocusedItem] = useState();
  const [results, setResults] = useState();

  const onSelectItem = (l) => {
    setSearchValue('');
    handleAssignToList(l);
  };

  const onClickRemoveItem = (l) => {
    handleUnassignFromList(l);
  };

  const onSearch = (event) => {
    const query = event.target.value;
    setSearchValue(query);
    setFocusedNumber(-1);
    setFocusedItem(null);
    if (query) {
      const lowerQuery = query.toLowerCase();
      const availableLists = allLists.filter((list) => currentLists.every((compList) => compList.id !== list.id));
      const resultsToShow = availableLists.filter((l) => l.name.toLocaleLowerCase().includes(lowerQuery));
      // Prioritize names that start with query first, then sort alphabetically
      const sortedResultsToShow = resultsToShow
        .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
        .sort((a, b) => b.name.toLocaleLowerCase().startsWith(lowerQuery) - a.name.toLocaleLowerCase().startsWith(lowerQuery));
      setResults(sortedResultsToShow);
    } else {
      setResults(null);
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 40 && focusedNumber + 1 < results.length) {
      const focused = results[focusedNumber + 1];
      setFocusedNumber(focusedNumber + 1);
      setFocusedItem(focused);
      return;
    }
    if (event.keyCode === 38 && focusedNumber - 1 >= 0) {
      const focused = results[focusedNumber - 1];
      setFocusedNumber(focusedNumber - 1);
      setFocusedItem(focused);
      return;
    }
    if (event.keyCode === 13) {
      event.preventDefault();
      if (focusedItem) {
        setSearchValue('');
        handleAssignToList(focusedItem);
        setFocusedItem(null);
      }
    }
  };

  return (
    <Box style={{ position: 'relative' }}>
      <StyledItemWrapper>
        {currentLists && currentLists.map((l) => (
          <StyledItem>
            <Text color="var(--white)" fontWeight="500">{l.name}</Text>
            <Box onClick={() => onClickRemoveItem(l)}>
              <Icon size="0.5em" color="var(--white)" cursor><RemoveIcon /></Icon>
            </Box>
          </StyledItem>
        ))}
      </StyledItemWrapper>
      <StyledInputWrapper mt="0.45em">
        {!isLoadingLists && currentLists.length < 5
        && (
        <StyledInput
          placeholder="Add to List"
          value={searchValue}
          autoComplete="off"
          onKeyDown={onKeyDown}
          onChange={onSearch}
          onBlur={() => setSearchValue('')}
          style={currentLists.length === 0 ? { paddingLeft: '0.5em' } : { paddingLeft: '0.125em' }}
        />
        )}
        {isLoadingLists && <Text color="var(--text-placeholder)" pl="0.5em" pt="0.125em" lineHeight="1.7">Loading Lists...</Text>}
      </StyledInputWrapper>

      {searchValue.length > 0
      && (
      <ResultsWrapper>
        {results && results.map((l) => (
          <ResultRow
            onClick={() => onSelectItem(l)}
            onMouseDown={(e) => e.preventDefault()}
            style={l.id === focusedItem?.id && { background: 'var(--input-menu-highlight)' }}
          >
            <Result>
              {l.name}
            </Result>
          </ResultRow>
        ))}
        {results && results.length === 0
          && <Text p="1em" color="var(--text-muted-panel)">No Results</Text>}
      </ResultsWrapper>
      )}
    </Box>
  );
}

ListsTypeAhead.propTypes = {
  currentLists: PropTypes.array.isRequired,
  allLists: PropTypes.array.isRequired,
  handleAssignToList: PropTypes.func.isRequired,
  handleUnassignFromList: PropTypes.func.isRequired,
  isLoadingLists: PropTypes.bool.isRequired,
};

export default ListsTypeAhead;
