/* eslint-disable import/prefer-default-export */
import { adminOrgLogoUploadUrl } from '../urls';

export async function postOrgLogo(orgId, path, contentType, size, file) {
  const formData = new FormData();

  formData.append('org_id', orgId);
  formData.append('path', path);
  formData.append('content_type', contentType);
  formData.append('size', size);
  formData.append('img', file);

  const response = await fetch(adminOrgLogoUploadUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: formData,
  });
  return response;
}
