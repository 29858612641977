/* eslint-disable react/forbid-prop-types */
import { useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';

import { getTags } from '@api/restricted/tags-api';

import useErrorOverlay from '@hooks/useErrorOverlay';

import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BasePillTypeAhead from '@distinct-components/typeAheadInputs/basePillTypeAhead';

function FilterCompositionTags({
  handleCompositionTagsApplied,
  appliedTags,
}) {
  const [tagOptions, setTagOptions] = useState([]);

  const loadTags = async () => {
    try {
      const response = await getTags();
      if (response.status !== 200) {
        try {
          const json = await response.json();
          useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading tags` });
        } catch {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading tags` });
        }
      } else {
        const json = await response.json();
        setTagOptions(json.tags);
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading tags` });
    }
  };

  useEffect(() => {
    loadTags();
  }, []);

  return (
    <Box mb="2rem">
      <InputLabel label="Composition Tag is one of:" />
      <BasePillTypeAhead
        availableOptions={tagOptions}
        selectedOptions={appliedTags}
        handleChange={(data) => handleCompositionTagsApplied(data)}
      />
    </Box>
  );
}

FilterCompositionTags.propTypes = {
  handleCompositionTagsApplied: PropTypes.func.isRequired,
  appliedTags: PropTypes.array,
};

FilterCompositionTags.defaultProps = {
  appliedTags: null,
};

export default FilterCompositionTags;
