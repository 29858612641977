/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useLayoutEffect, useState } from 'preact/hooks';
import AppState from '@state';
import { Fragment } from 'preact';

import loadComposition from '@actions/loadComposition';
import { patchWorkspaceHasSamples } from '@api/restricted/workspace-samples-api';
import { patchWorkspaceSignature, patchWorkspaceSignaturePlain } from '@api/restricted/workspace-signature-api';

import AlertMessage from '@ui-kit/alert/Alert';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import BreakLine from '@ui-kit/dividers/breakLine';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';
import SingleSignatureBlock from './sections/singleSignatureBlock';

function LockSingleWriter({
  closeFunction,
}) {
  const [signatureError, setSignatureError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isOriginal, setIsOriginal] = useState(true);
  const [isSingleWriter, setIsSingleWriter] = useState(true);
  const [isToggling, setIsToggling] = useState(false);

  const hasInterpolation = AppState.composition.hasSamples.value;
  const interpolations = AppState.composition.samples.value;

  const useToastAlertError = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'error' };
  };

  useLayoutEffect(() => {
    if (!isOriginal || !isSingleWriter || (hasInterpolation && interpolations.length === 0)) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [isOriginal, isSingleWriter, hasInterpolation]);

  const onToggleHasInterpolation = (e) => {
    e.preventDefault();

    if (hasInterpolation && interpolations.length > 0) {
      useToastAlertError('You need to delete all added samples first');
      return;
    }

    setIsToggling(true);
    patchWorkspaceHasSamples(AppState.composition.id.value, !hasInterpolation)
      .then((response) => {
        if (response.status === 200) {
          loadComposition(AppState.composition.id.value).then(() => {
            setIsToggling(false);
          });
        } else {
          response.json()
            .then((json) => useToastAlertError(json.error || response.statusText))
            .catch(() => useToastAlertError(response.statusText));
          setIsToggling(false);
        }
      })
      .catch((err) => {
        setIsToggling(false);
        useToastAlertError(err.message);
      });
  };

  const onLockSingleWriter = (signatureImg, signatureText) => {
    setIsLoading(true);
    setSignatureError(null);

    if (signatureImg) {
      patchWorkspaceSignature(AppState.composition.id.value, signatureImg)
        .then((response) => {
          if (response.status === 200) {
            loadComposition(AppState.composition.id.value).then(() => {
              setSignatureError(null);
              setIsLoading(false);
              AppState.messages.toastMessage.value = { text: 'Attestation Signed', variant: 'success' };
              closeFunction();
            });
          } else {
            response.json()
              .then(({ error }) => setSignatureError(error || response.statusText))
              .catch(() => setSignatureError(response.statusText));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setSignatureError(err.message);
        });
    } else if (signatureText) {
      patchWorkspaceSignaturePlain(AppState.composition.id.value, signatureText)
        .then((response) => {
          if (response.status === 200) {
            loadComposition(AppState.composition.id.value).then(() => {
              setSignatureError(null);
              setIsLoading(false);
              AppState.messages.toastMessage.value = { text: 'Attestation Signed', variant: 'success' };
              closeFunction();
            });
          } else {
            response.json()
              .then(({ error }) => setSignatureError(error || response.statusText))
              .catch(() => setSignatureError(response.statusText));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setSignatureError(err.message);
        });
    }
  };

  return (
    <BaseEditOverlay closeFunction={closeFunction}>

      <ConversationPrompt
        primaryMessage="Sign and Finalize"
        promptLabel="Single Writer"
        labelColor="var(--purple-300)"
        mb="1em"
      />
      {/* <ConversationExplanation mb="1em" explanation={COMPOSITION_EXPLANATIONS.singleWriter.lockPrompt} truncateAtCharacter={500} /> */}

      <Box mb="1.5em">
        <Text mb="0.75em" fontWeight="500">
          I affirm that the following are true:
        </Text>

        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Text>I am the sole writer</Text>
            <ToggleSwitch id="singleWriter">
              <input
                type="checkbox"
                name="singleWriter"
                id="singleWriter"
                checked={isSingleWriter}
                onClick={() => setIsSingleWriter(!isSingleWriter)}
              />
            </ToggleSwitch>
          </Box>
          <BreakLine mt="0.5em" mb="0.5em" color="var(--panel-info-border)" />
        </Box>

        <Box>
          <Box display="flex" justifyContent="space-between">
            <Text>This is my original work</Text>
            <ToggleSwitch id="original">
              <input
                type="checkbox"
                name="original"
                id="original"
                checked={isOriginal}
                onClick={() => setIsOriginal(!isOriginal)}
              />
            </ToggleSwitch>
          </Box>
          <BreakLine mt="0.5em" mb="0.5em" color="var(--panel-info-border)" />
        </Box>

        <Box>
          <Box display="flex" justifyContent="space-between">
            {(interpolations.length === 0 || !hasInterpolation) ? (
              <Fragment>
                <Text>No works were sampled</Text>
                <ToggleSwitch id="noInterpolation" disabledCursor="wait">
                  <input
                    type="checkbox"
                    name="noInterpolation"
                    id="noInterpolation"
                    checked={!hasInterpolation}
                    disabled={isToggling}
                    onClick={onToggleHasInterpolation}
                  />
                </ToggleSwitch>
              </Fragment>
            ) : (
              <Text>
                Total Works Sampled:
                {' '}
                {interpolations.length}
              </Text>
            )}
          </Box>
          <BreakLine mt="0.5em" mb="0.5em" color="var(--panel-info-border)" />
        </Box>
      </Box>

      {showWarning && !isSingleWriter
      && (
      <AlertMessage
        variant="warning"
        message="Invite Co Writers to Create a Split Sheet and Finalize"
        mb="1em"
      />
      )}

      {showWarning && !isOriginal
      && (
      <AlertMessage
        variant="warning"
        message="You cannot finalize this Composition if it is not your creation"
        mb="1em"
      />
      )}

      {showWarning && (hasInterpolation && interpolations.length === 0)
      && (
      <AlertMessage
        variant="warning"
        message="Define sampled works in the Samples tab before finalizing"
        mb="1em"
      />
      )}

      {!showWarning
      && (
      <SingleSignatureBlock
        handleSignature={onLockSingleWriter}
        signatureError={signatureError}
        isLocking={isLoading || isToggling}
      />
      )}

    </BaseEditOverlay>
  );
}

LockSingleWriter.propTypes = {
  closeFunction: PropTypes.func.isRequired,
};

export default LockSingleWriter;
