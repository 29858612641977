import { adminOrganizationsUrl } from '../urls';

export async function getOrganizations() {
  const response = await fetch(adminOrganizationsUrl, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return response;
}

export async function getOrganization(orgId) {
  const response = await fetch(`${adminOrganizationsUrl}?${new URLSearchParams({
    org: orgId,
  })}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return response;
}

export async function postOrganization(name) {
  const data = {
    name,
  };
  const response = await fetch(adminOrganizationsUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

export async function patchOrganizationName(orgId, name) {
  const data = {
    org: orgId,
    name,
  };
  const response = await fetch(adminOrganizationsUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteOrganization(id) {
  const data = {
    id,
  };
  const response = await fetch(adminOrganizationsUrl, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
  return response;
}
