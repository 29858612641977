/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const CompositionSectionsGrid = styled.div`
    display: grid;
    grid-template-columns: 56% 1fr;
    gap: 1.5em;

    @media screen and (max-width: ${pointBreaks.sm}) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
`;

export const CompositionsToolsGrid = styled.div`
    display: block;

    @media screen and (min-width: ${pointBreaks.xxl}) {
      display: grid;
      gap: 1.5em;
      grid-template-columns: 75% 1fr;
      min-height: calc(100vh - 1.5em);
    }
`;

export const ToolsToggleWrapper = styled.div`
    display: none;

    @media screen and (max-width: ${pointBreaks.sm}) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
`;

export const ToolsStatusViewRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: ${pointBreaks.sm}) {
      display: none;
    }

    @media screen and (min-width: ${pointBreaks.xxl}) {
      display: none;
    }
`;

export const StyledStatusIndicator = styled.div(({
  statusColor,
}) => `
    background: ${statusColor || 'var(--inactive)'};
    height: 1.65rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5em;
    font-weight: 500;
    text-align: center;
    min-width: 6em;
    padding: 0em 1em;
    font-size: 0.813rem;
    color:${statusColor ? 'var(--white)' : 'var(--text-secondary)'};
`);
