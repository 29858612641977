import { useState, useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';
import { Fragment } from 'preact';

import AppState from '@state';

import { deleteWorkspace } from '@api/restricted/workspace-api';

import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import Box from '@ui-kit/box';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import InputLabel from '@ui-kit/inputs/inputLabel';
import Spinner from '@ui-kit/loaders/Spinner';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import { COMPOSITION_EXPLANATIONS } from '@constants/supportingCopy';
import { route } from 'preact-router';

function DeleteWorkspace({
  closeFunction,
}) {
  const [mode, setMode] = useState('Loading');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(null);

  useEffect(() => {
    const numWriters = AppState.workspaceAccounts.value.filter((acc) => acc.isWriter).length;
    if (numWriters < 2 && AppState.composition.isLocked.value) {
      setMode('SingleWriterLocked');
    } else if (AppState.composition.isLocked.value || AppState.composition.version.value > 1) {
      setMode('ContractExists');
    } else {
      setMode('CanDelete');
    }
  }, []);

  const onCancelDelete = () => {
    setDeleteConfirm(null);
    setError(null);
    closeFunction();
  };

  const onTypeToConfirm = (value) => {
    const validationText = value.toLowerCase();
    setDeleteConfirm(validationText);
  };

  const handleDeleteWorkspace = () => {
    setIsLoading(true);
    deleteWorkspace(AppState.composition.id.value)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          route('/compositions');
        } else {
          response.json()
            .then((json) => setError(json.error ? json.error : response.statusText))
            .catch(() => setError(response.statusText));
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return (
    <BaseEditOverlay formCentered closeFunction={onCancelDelete}>
      {{
        Loading:
  <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
    <Spinner size="2em" variant="page" />
  </Box>,

        SingleWriterLocked:
  <Fragment>
    <ConversationPrompt
      primaryMessage="You can’t delete this right now"
      promptLabel="Composition Locked"
      labelColor="var(--red-300)"
      mb="1em"
    />
    <ConversationExplanation mb="2em" explanation={COMPOSITION_EXPLANATIONS.deleteWorkspace.deleteBlockedSingleWriter} truncateAtCharacter={500} />
  </Fragment>,

        ContractExists:
  <Fragment>
    <ConversationPrompt
      primaryMessage="You can’t delete this right now"
      promptLabel="Contract Exists"
      labelColor="var(--red-300)"
      mb="1em"
    />
    <ConversationExplanation mb="2em" explanation={COMPOSITION_EXPLANATIONS.deleteWorkspace.deleteBlockedContractExists} truncateAtCharacter={500} />
  </Fragment>,

        CanDelete:
  <Fragment>
    <ConversationPrompt
      primaryMessage="Are you sure you want to delete this Composition?"
      promptLabel="Woah There..."
      labelColor="var(--red-300)"
      mb="1em"
    />
    <ConversationExplanation mb="2em" explanation={COMPOSITION_EXPLANATIONS.deleteWorkspace.warning} truncateAtCharacter={500} />

    <InputLabel label="Confirm by typing the phrase &quot;Delete Composition&quot;" />
    <BaseInput
      placeholder="Enter &quot;Delete Composition&quot;"
      type="text"
      onChange={(e) => onTypeToConfirm((e.target.value))}
      fluid
      mb="1.5em"
    />

    {error && (
    <AlertMessage
      variant="negative"
      message={error}
      mb="1.5em"
    />
    )}

    {deleteConfirm === 'delete composition' && (
    <BaseButton
      btnText="Delete Composition"
      variant="danger"
      spinnerVariant="danger"
      fluid
      isLoading={isLoading}
      onClick={handleDeleteWorkspace}
    />
    )}
  </Fragment>,
      }[mode]}
    </BaseEditOverlay>
  );
}

DeleteWorkspace.propTypes = {
  closeFunction: PropTypes.func.isRequired,
};

export default DeleteWorkspace;
