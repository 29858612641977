/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import { route } from 'preact-router';
import AppState from '@state';

import { postArtistRepInvite } from '@api/restricted/artist-reps-invite-api';

import useIsoDateFormat from '@hooks/useIsoDateFormat';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import BaseButton from '@ui-kit/buttons/baseButton';
import BreakLine from '@ui-kit/dividers/breakLine';
import AlertMessage from '@ui-kit/alert/Alert';
import Avatar from '@ui-kit/avatar';
import MailWedge from '@animations/wedges/MailWedge';
import { Fragment } from 'preact';

function ReviewRepInvite({
  recipient,
  terms,
  ...restProps
}) {
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);

  const d = useIsoDateFormat;

  const onClickSendInvitation = () => {
    setError('');
    setIsSaving(true);
    setTimeout(() => {
      postArtistRepInvite(recipient.email, terms.toDate, terms.fullAccess || false, terms.hasPowerOfAttorney || false)
        .then((response) => {
          if (response.status === 200) {
            setIsSaving(false);
            AppState.messages.toastMessage.value = { text: 'Invitation Sent', variant: 'success' };
            route('/representation?reps=pending');
          } else {
            setIsSaving(false);
            response.json()
              .then((json) => setError(json.error || response.statusText))
              .catch(() => setError(response.statusText));
          }
        })
        .catch((err) => {
          setIsSaving(false);
          setError(err.message);
        });
    }, 800);
  };

  return (
    <Box {...restProps}>
      <BreakLine mb="1.5em" mt="0.5em" color="var(--panel-info-border)" />

      <Fragment>
        {recipient.user?.id ? (
          <Box display="flex" mb="1.5em" alignItems="center">
            <Box mr="0.75em">
              <Avatar size="3em" mb="1em" imgURL={recipient.user.imgURL} isVerified={recipient.user.isVerified} checkSize="1em" checkY="4px" checkX="-2px" />
            </Box>
            <Box>
              <Text fontWeight="600" fontSize="0.813rem">
                Registered User
              </Text>
              <Text fontSize="0.813rem">
                {recipient.user.firstName}
                {' '}
                {recipient.user.lastName}
              </Text>
            </Box>
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <Box mr="0.75em">
              <MailWedge />
            </Box>
            <Box>
              <Text fontWeight="600" fontSize="0.813rem">
                Send to:
              </Text>
              <Text fontSize="0.813rem">{recipient.email}</Text>
            </Box>
          </Box>
        )}

        <BreakLine mt="1.5em" mb="1.5em" color="var(--panel-info-border)" />

        <Text mb="0.813em" fontWeight="800">Credential Terms</Text>

        <Text mb="0.5em" fontSize="0.813rem">
          Access Level:
          {' '}
          {terms.fullAccess ? 'Full Account Access' : 'View Only'}
        </Text>

        {terms.toDate ? (
          <Text mt="0.5em" fontSize="0.813rem">
            Valid Until:
            {' '}
            {d(terms.toDate)}
          </Text>
        ) : (
          <Text mt="0.5em" fontSize="0.813rem">
            Valid Until: Canceled by You
          </Text>
        )}

        <Text mt="0.5em" fontSize="0.813rem">
          Can Sign Split Sheets:
          {' '}
          {terms.hasPowerOfAttorney ? 'Yes. (Requires Proof from Rep)' : 'No'}
        </Text>

        <BreakLine mt="1.5em" mb="2.5em" color="var(--panel-info-border)" />

        {error && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
        )}

        <Box display="flex" justifyContent="flex-end">
          <BaseButton
            mb="1.5em"
            btnText="Send Invitation"
            onClick={onClickSendInvitation}
            disabled={isSaving}
            isLoading={isSaving}
            fluid
            decoration="arrow"
          />
        </Box>
      </Fragment>
    </Box>
  );
}

ReviewRepInvite.propTypes = {
  recipient: PropTypes.object.isRequired,
  terms: PropTypes.object.isRequired,
  coPub: PropTypes.object,
  requirements: PropTypes.object,
};

ReviewRepInvite.defaultProps = {
  coPub: null,
  requirements: null,
};

export default ReviewRepInvite;
