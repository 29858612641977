/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import { space } from 'styled-system';

export const LoggedInWrapper = styled.div`
    position: absolute;
    top: 32px;
    right: 32px;

    @media screen and (max-width: ${pointBreaks.sm}) {
        left: 32px;
        top: 68px;
    }
`;

export const StyledAccessContainer = styled.div`
    border: 1px solid var(--card-divider);
    border-radius: 1em;
    ${space}
`;
