/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const SignaturesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2em;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const SignatureWrapper = styled.div`
    border-radius: 0.5em;
    border: 1px solid var(--text-secondary);
    min-height: 8em;
    padding: 1em;
    width: 100%;
`;

export const SignatureRow = styled.div`
    border-bottom: 1px solid var(--text-secondary);
    min-height: 4em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const SignatureImage = styled.img`
    max-height: 4em;
    margin: 0em 2em;
    align-self: center;
`;

export const ShareReminderWrapper = styled.div`
    margin-bottom: 1.5rem;
    border: 1px solid var(--border);
    padding: 1em;
    border-radius: 16px;
    background: var(--panel-card);
`;

export const SignatureCTA = styled.button(({
  animate,
}) => `
    background: var(--active-nav);
    color: var(--white);
    width: 100%;
    margin-bottom: 1.25em;
    border-radius: 0.125em;
    font-weight: 800;
    ${animate && 'animation: pulse 2s infinite'};

    @keyframes pulse {
        0% {
            opacity: 1;
            border-radius: 0.125em;
            transform: translateY(0px);
        }
        50% {
            opacity: 0.7;
            border-radius: 1em;
            transform: translateY(2px);
        }
        100% {
            opacity: 1;
            border-radius: 0.125em;
            transform: translateY(0px);
        }
    }
`);

export const CreateSignatureWrapper = styled.div`
    border-radius: 1em;
    overflow: hidden;
    width: 320px;
    height: 130px;
    margin-bottom: 0.75em;
    background: var(--panel-card);
    border: 0.8px solid var(--panel-divider);
    box-shadow: 2px 4px 11px var(--card-shadow);
`;

export const SignatureLine = styled.div`
    border-top: 1px dashed var(--brand-primary);
    width: 290px;
    transform: translate(15px, -29px);
`;
