import styled from 'styled-components';
import { space, variant } from 'styled-system';

export const AlertWrapper = styled.div(
  ({ width }) => `
    border-radius: 0.5em;
    min-height: 2.5em;
    overflow: hidden;
    width: ${width || '100%'};
    border: 1px solid var(--border);
    position: relative;
`,
  space,
  variant({
    variants: {
      default: {
        borderColor: 'var(--blue-200)',
      },
      positive: {
        borderColor: 'var(--green-200)',
      },
      negative: {
        borderColor: 'var(--red-200)',
      },
      warning: {
        borderColor: 'var(--orange-300)',
      },
    },
  }),
);

export const AlertMessageWrapper = styled.div`
    display: flex;
    border-radius: 0.5em;
    width: 100%;
    position: relative;
    padding-top: 2px;
`;

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 20%;
    max-width: 2.5em;
`;

export const AlertMessage = styled.div(({
  display,
}) => `
    font-size: 0.813rem;
    padding: 0.5em;
    padding-left: 0em;
    display: ${display};
    align-items: center;
    line-height: 1.6;
    color: var(--text-primary);
    flex-wrap: wrap;
    flex-basis: 80%;

    & span {
      color: var(--text-primary);
    }
`);

export const DismissWrapper = styled.div`
    position: absolute;
    top: 8px;
    right: 14px;
    cursor: pointer;
`;

export const StyledMessageLink = styled.a`
  color: var(--text-primary) !important;
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.813rem;

  &:last-child {
    margin-bottom: 0em;
  }
`;
