/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import ClearIcon from '@assets/icons/close-solo.svg';

import {
  ResultsWrapper,
  ResultRow,
  Result,
  StyledTagsWrapper,
  StyledTagInput,
  InputTag,
} from './InternalTagTypeAheadStyles';

// Currently only used for PT Composition View. Need to refactor if used for other components
function InternalTagTypeAhead({
  currentTags,
  allTags,
  handleAddTag,
  handleRemoveTag,
  isLoadingTags,
}) {
  const [searchValue, setSearchValue] = useState('');
  const [focusedNumber, setFocusedNumber] = useState(-1);
  const [focusedItem, setFocusedItem] = useState();
  const [results, setResults] = useState();

  const onSelectItem = (t) => {
    setSearchValue('');
    handleAddTag(t);
  };

  const onClickRemoveTag = (t) => {
    handleRemoveTag(t);
  };

  const onSearch = (event) => {
    const query = event.target.value;
    setSearchValue(query);
    setFocusedNumber(-1);
    setFocusedItem(null);
    if (query) {
      const lowerQuery = query.toLocaleLowerCase();
      const availableTags = allTags.filter((internalTag) => currentTags.every((compTag) => compTag.id !== internalTag.id));
      const resultsToShow = availableTags.filter((t) => t.name.toLocaleLowerCase().includes(lowerQuery));
      // Prioritize names that start with query first, then sort alphabetically
      const sortedResultsToShow = resultsToShow
        .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
        .sort((a, b) => b.name.toLocaleLowerCase().startsWith(lowerQuery) - a.name.toLocaleLowerCase().startsWith(lowerQuery));
      setResults(sortedResultsToShow);
    } else {
      setResults(null);
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 40 && focusedNumber + 1 < results.length) {
      const focused = results[focusedNumber + 1];
      setFocusedNumber(focusedNumber + 1);
      setFocusedItem(focused);
      return;
    }
    if (event.keyCode === 38 && focusedNumber - 1 >= 0) {
      const focused = results[focusedNumber - 1];
      setFocusedNumber(focusedNumber - 1);
      setFocusedItem(focused);
      return;
    }
    if (event.keyCode === 13) {
      event.preventDefault();
      if (focusedItem) {
        setSearchValue('');
        handleAddTag(focusedItem);
        setFocusedItem(null);
      }
    }
  };

  return (
    <Box style={{ position: 'relative' }}>
      <StyledTagsWrapper mb="0.45em">
        {currentTags && currentTags.map((t) => (
          <InputTag color={t.color}>
            {t.name}
            <Icon ml="0.5em" color="var(--white)" cursor onClick={() => onClickRemoveTag(t)} size="0.5em" style={{ transform: 'translateY(-1px)' }}><ClearIcon /></Icon>
          </InputTag>
        ))}
        {!isLoadingTags
        && (
        <StyledTagInput
          placeholder={currentTags.length === 0 ? 'Add tags...' : 'Tag...'}
          value={searchValue}
          autoComplete="off"
          onKeyDown={onKeyDown}
          onChange={onSearch}
          onBlur={() => setSearchValue('')}
          style={currentTags.length === 0 ? { paddingLeft: '0.5em' } : { paddingLeft: '0.125em' }}
        />
        )}
        {isLoadingTags && <Text color="var(--text-placeholder)" pl="0.5em" pt="0.125em" lineHeight="1.7">Loading Tags...</Text>}
      </StyledTagsWrapper>

      {searchValue.length > 0
      && (
      <ResultsWrapper>
        {results && results.map((t) => (
          <ResultRow
            onClick={() => onSelectItem(t)}
            onMouseDown={(e) => e.preventDefault()}
            style={t.id === focusedItem?.id && { background: 'var(--input-menu-highlight)' }}
          >
            <Result>
              {t.name}
            </Result>
          </ResultRow>
        ))}
        {results && results.length === 0 && <Text p="1em" color="var(--text-muted-panel)">No Results</Text>}
      </ResultsWrapper>
      )}
    </Box>
  );
}

InternalTagTypeAhead.propTypes = {
  currentTags: PropTypes.array.isRequired,
  allTags: PropTypes.array.isRequired,
  handleAddTag: PropTypes.func.isRequired,
  handleRemoveTag: PropTypes.func.isRequired,
  isLoadingTags: PropTypes.bool.isRequired,
};

export default InternalTagTypeAhead;
