/* eslint-disable no-alert */
import PropTypes from 'prop-types';

import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import LockIcon from '@assets/icons/lock-closed.svg';

import { LockWrapper, MobileLockWrapper } from './LockPromptStyles';

function LockPrompt({
  lockPrompt,
}) {
  const onTapLockIcon = () => {
    alert(lockPrompt);
  };
  return (
    <Box>
      <LockWrapper>
        <span className="toolTipText">{lockPrompt}</span>
        <Icon size="1.125em" pt="0.25em" pr="0.25em" cursor><LockIcon /></Icon>
      </LockWrapper>
      <MobileLockWrapper>
        <Icon size="1.125em" pt="0.25em" pr="0.25em" cursor onClick={onTapLockIcon}><LockIcon /></Icon>
      </MobileLockWrapper>
    </Box>
  );
}

LockPrompt.propTypes = {
  lockPrompt: PropTypes.string.isRequired,
};

export default LockPrompt;
