/* eslint-disable react/forbid-prop-types */
import { useState, useEffect, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import anime from 'animejs/lib/anime.es';
// import dayjs from 'dayjs';

import d from '@hooks/useIsoDateFormat';

import useClickOutside from '@hooks/useClickOutside';
import useKeyPressAction from '@hooks/useKeyPressAction';
import useErrorOverlay from '@hooks/useErrorOverlay';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Logo from '@ui-kit/logo/Logo';
import Icon from '@ui-kit/icon';
import CloseIcon from '@assets/icons/close-solo.svg';
import Tabs from '@ui-kit/tabs/Tabs';
// import LinkButton from '@ui-kit/buttons/linkButton';

import ProfileOverlayStats from './sections/profileOverlayStats';
import ProfileOverlayCredentials from './sections/profileOverlayCredentials';

import {
  ModalContainer,
  ModalPage,
  ModalHeader,
  StyledCredentialBadge,
  StyledCloseButton,
  ProfileTabsSection,
} from './PublisherProfileOverlayStyles';

function PublisherProfileOverlay({
  closeFunction,
  pub,
  setCredToRevoke,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [profileTab, setProfileTab] = useState('stats');
  const [writerCount, setWriterCount] = useState(0);
  const [workCount, setWorkCount] = useState(0);
  const [credentials, setCredentials] = useState([]);
  const hasActiveCredential = credentials.some((c) => c.isActive);

  const ref = useRef();
  useClickOutside(ref, () => closeFunction());

  useKeyPressAction('Escape', () => {
    closeFunction();
  });

  const loadAnimation = anime.timeline({
    autoplay: false,
  });

  const loadPubProfile = async () => {
    try {
      setCredentials([]);
      setWriterCount(20);
      setWorkCount(100);
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading representative profile` });
    }
  };

  useEffect(() => {
    loadPubProfile().then(() => {
      loadAnimation.add({
        targets: '#overlay',
        backdropFilter: ['blur(0px)', 'blur(10px)'],
        duration: 2000,
      }, 100).add({
        targets: '#container',
        translateY: ['32px', 0],
        opacity: [0, 1],
        duration: 600,
        easing: 'easeOutQuad',
      }, 0);
      loadAnimation.play();
      setTimeout(() => {
        setIsLoading(false);
      }, 800);
    });
  }, []);

  const onProfileTabChange = (tab) => {
    setProfileTab(tab.value);
  };

  return (
    <ModalPage id="overlay">
      <Box ref={ref} m="0 auto" maxWidth="72em" minHeight="calc(100vh - 100px)">
        <ModalContainer id="container">
          <ModalHeader>

            <Box display="flex" alignItems="center">
              <Box mr="1.5em">
                <Logo orgImageURL={pub.logoUrl} />
              </Box>
              <Box>
                <Text fontSize="1.5rem" lineHeight="1" fontWeight="600" color="var(--text-primary)">
                  {pub.name}
                </Text>
                <Text mt="0.25rem" fontSize="0.75rem" color="var(--text-secondary)">
                  Connected Since:
                  {' '}
                  {d(pub.connectionStartDate)}
                </Text>
              </Box>
            </Box>

            <Box id="actions" display="flex" justifyContent="flex-end">
              <StyledCredentialBadge activeCredential={hasActiveCredential} onClick={() => setProfileTab('credentials')}>
                {hasActiveCredential ? 'Active Credential' : 'Inactive'}
              </StyledCredentialBadge>
              <StyledCloseButton onClick={closeFunction}>
                <Icon cursor size="0.75em"><CloseIcon /></Icon>
              </StyledCloseButton>
            </Box>
          </ModalHeader>

          <ProfileTabsSection>
            <Tabs
              tabs={[{ name: 'Stats', value: 'stats' }, { name: 'Credentials', value: 'credentials' }]}
              handleTabChange={onProfileTabChange}
              selectedTab={profileTab}
            />
            {{
              stats:
  <ProfileOverlayStats stats={{ totalWorks: workCount, totalWriters: writerCount }} isLoading={isLoading} />,
              credentials:
  <ProfileOverlayCredentials
    credentials={credentials}
    isLoading={isLoading}
    setCredToRevoke={setCredToRevoke}
  />,
            }[profileTab]}
          </ProfileTabsSection>

        </ModalContainer>

      </Box>

    </ModalPage>
  );
}

PublisherProfileOverlay.propTypes = {
  closeFunction: PropTypes.func.isRequired,
  pub: PropTypes.object.isRequired,
  setCredToRevoke: PropTypes.func.isRequired,
};

export default PublisherProfileOverlay;
