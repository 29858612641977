/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useRef } from 'preact/hooks';

import useClickOutside from '@hooks/useClickOutside';

import Box from '@ui-kit/box';

import { PopoverMenu, MenuItem } from './DotMenuStyles';

function Tabs({
  menuItems,
  menuWidth,
  menuTransform,
  color,
  ...restProps
}) {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  useClickOutside(menuRef, () => setShowMenu(false));

  const handleItemClicked = (method) => {
    setShowMenu(false);
    method();
  };

  return (
    <Box position="relative" {...restProps} width="1em" className="cursor-p">
      <Box display="flex" onClick={() => setShowMenu(true)} justifyContent="center">
        <svg width="4" height="20"><g fill={color} fillRule="nonzero"><path d="M2 16a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM2 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM2 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" /></g></svg>
      </Box>
      {showMenu
      && (
        <PopoverMenu ref={menuRef} width={menuWidth} transform={menuTransform}>
          {menuItems.map((item) => (
            <MenuItem
              onClick={() => handleItemClicked(item.method)}
            >
              {item.label}
            </MenuItem>
          ))}
        </PopoverMenu>
      )}
    </Box>
  );
}

Tabs.propTypes = {
  menuItems: PropTypes.array.isRequired,
  menuWidth: PropTypes.string,
  menuTransform: PropTypes.string,
  color: PropTypes.string,
};

Tabs.defaultProps = {
  menuWidth: '10em',
  menuTransform: 'translate(0px, 0px)',
  color: 'var(--icon)',
};

export default Tabs;
