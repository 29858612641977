import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import { route } from 'preact-router';
import PropTypes from 'prop-types';
import resetGlobalState from '@state/reset';

import {
  getArtistRepsInviteToActivate,
  postArtistRepsInviteActivate,
  deleteArtistRepsInviteActivate,
} from '@api/restricted/artist-reps-invite-activate-api';

import loadAccountProfile from '@actions/loadAccountProfile';

import useErrorOverlay from '@hooks/useErrorOverlay';
import useAuthTokensValidCheck from '@hooks/useAuthTokensValidCheck';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import Box from '@ui-kit/box';
import Spinner from '@ui-kit/loaders/Spinner';

import ConversationLayout from '@layouts/conversations';

import { REP_EXPLANATIONS } from '@constants/supportingCopy';

import ActivateReview from './sections/activateReview/ActivateReview';
import ActivateSuccess from './sections/activateSuccess/ActivateSuccess';
import ActivateDeclined from './sections/activateDeclined/ActivateDeclined';

function ArtistActivateRep({ id: inviteId }) {
  const [repDetails, setRepDetails] = useState({});

  const [activateMode, setActivateMode] = useState('Loading');
  const [isLoading, setIsLoading] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const [error, setError] = useState('');

  const loadRepDetails = async () => {
    try {
      const response = await getArtistRepsInviteToActivate(inviteId);
      if (response.status !== 200) {
        const json = await response.json();
        useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading rep details` });
      } else {
        const json = await response.json();
        setRepDetails(json.rep);
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading rep details` });
    }
  };

  useEffect(() => {
    document.title = 'Switchchord - Activate Rep';

    loadRepDetails().then(() => {
      if (useAuthTokensValidCheck()) {
        loadAccountProfile().then(() => {
          setError('');
          setActivateMode('Review');
        });
      } else {
        setError('');
        setActivateMode('Auth');
      }
    });
  }, []);

  const handleActivateRep = () => {
    setIsLoading(true);

    postArtistRepsInviteActivate(inviteId)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setError('');
          setActivateMode('Success');
        } else if (response.status === 400) {
          route('/error/invalidLink');
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const handleDeclineRep = () => {
    setIsDeclining(true);

    deleteArtistRepsInviteActivate(inviteId)
      .then((response) => {
        setIsDeclining(false);
        if (response.status === 200) {
          setError('');
          setActivateMode('Declined');
        } else if (response.status === 400) {
          route('/error/invalidLink');
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      }).catch((err) => {
        setIsDeclining(false);
        setError(err.message);
      });
  };

  const handleSignOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    resetGlobalState();
    setError('');
    setActivateMode('Auth');
  };

  return (
    <ConversationLayout>
      {activateMode === 'Review' && (
      <Fragment>
        <ConversationPrompt
          primaryMessage="Review Identity Information"
          promptLabel="Activate Rep"
          mb="0.875em"
          pt="1em"
        />
        <ConversationExplanation mb="1.5em" explanation={REP_EXPLANATIONS.activateRepConnection} truncateAtCharacter={500} />
      </Fragment>
      )}
      {
        {
          Loading:
  <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
    <Spinner size="2em" variant="page" />
  </Box>,

          Review: <ActivateReview
            repDetails={repDetails}
            handleActivateRep={handleActivateRep}
            handleDeclineRep={handleDeclineRep}
            isLoading={isLoading}
            isDeclining={isDeclining}
            error={error}
            handleSignOut={handleSignOut}
          />,

          Success: <ActivateSuccess />,
          Declined: <ActivateDeclined />,
        }[activateMode]
      }
    </ConversationLayout>
  );
}

ArtistActivateRep.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ArtistActivateRep;
