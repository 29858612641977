import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import { route } from 'preact-router';
import AppState from '@state';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import BaseButton from '@ui-kit/buttons/baseButton';
import Text from '@ui-kit/typography/text';

function InvitationSuccess({
  isNewUser,
  orgName,
  orgUuid,
}) {
  const onClickContinue = () => {
    if (isNewUser) {
      route(`/personalize?pub=${orgUuid}`);
    } else {
      route(`/publisher/${orgUuid}/dashboard`);
      AppState.navigation.backActionPath.value = `/publisher/${orgUuid}/dashboard`;
    }
  };
  return (
    <Fragment>
      <ConversationPrompt
        primaryMessage="You've joined: "
        promptLabel="Success"
        labelColor="var(--green-400)"
        secondaryMessage={orgName}
        mb="2em"
      />
      <Text mb="2em" fontSize="1rem">You can now access this Publisher Organization. If you are a member of multiple organizations, you can toggle between them at any time.</Text>
      {isNewUser && <Text fontSize="1rem">Next, finish setting up your account.</Text>}
      <BaseButton
        mt="2em"
        btnText={isNewUser ? 'Finish Setting Up' : 'Go to Dashboard'}
        fluid
        onClick={onClickContinue}
      />
    </Fragment>
  );
}

InvitationSuccess.propTypes = {
  isNewUser: PropTypes.bool.isRequired,
  orgName: PropTypes.string.isRequired,
  orgUuid: PropTypes.string.isRequired,
};

export default InvitationSuccess;
