/* eslint-disable react/forbid-prop-types */
// import { useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';
// import AppState from '@state';

import useIsoDateFormat from '@hooks/useIsoDateFormat';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import CompositionIcon from '@assets/illustrations/composition-chip-muted.svg';

import {
  StyledLabel,
  StyledData,
  StyledTag,
  StyledStatusBadge,
  IconWrapper,
  DetailWrapper,
  StyledHeaderCard,
} from './ViewCompositionHeaderStyles';

function ViewCompositionHeader({ composition }) {
  const d = useIsoDateFormat;

  return (

    <StyledHeaderCard className="sc-card-mobile-bg">
      <Box display="flex" justifyContent="space-between">

        <Box display="flex" justifyContent="space-between">
          <IconWrapper>
            <Icon size="4.5em" style={{ opacity: 0.8 }}><CompositionIcon /></Icon>
          </IconWrapper>
          <DetailWrapper>
            <Text mb="0.45em" fontWeight="800" fontSize="1.5em" lineHeight="1">{composition.name}</Text>
            <Box display="flex" mb="0.125rem" flexWrap="wrap">
              <StyledLabel fontSize="0.813rem">
                Date Written:
              </StyledLabel>
              &nbsp;
              <StyledData fontSize="0.813rem" pr="0.75em">
                {d(composition.writtenDate)}
              </StyledData>
              <StyledLabel>
                Alternate Titles:
              </StyledLabel>
              &nbsp;
              {composition.alternateTitles.length > 0 ? (
                <StyledData>
                  {composition.alternateTitles.join(', ')}
                </StyledData>
              ) : (
                <StyledData>
                  None
                </StyledData>
              )}
            </Box>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              {composition.tags.map((tag) => (
                <StyledTag>
                  #
                  {tag.name}
                </StyledTag>
              ))}
            </Box>
          </DetailWrapper>
        </Box>

        <Box display="flex">
          <StyledStatusBadge notFinalized={composition.finalizedDate === null}>
            {composition.finalizedDate ? `Finalized ${d(composition.finalizedDate)}` : 'Not Finalized'}
          </StyledStatusBadge>
        </Box>

      </Box>
    </StyledHeaderCard>
  );
}

ViewCompositionHeader.propTypes = {
  composition: PropTypes.object.isRequired,
};

export default ViewCompositionHeader;
