import anime from 'animejs/lib/anime.es';
import { useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';

function RegisterAnimation({ animationPlaying }) {
  const wrapperStyle = {
    transform: 'translateY(-3em)',
  };

  const circlesStyle = {
    display: 'flex',
    gap: '1em',
  };

  const middleStyle = {
    background: '#09080b',
    height: '0px',
    width: '0px',
    borderRadius: '8px 8px 8px 33px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    overflow: 'hidden',
  };

  const svgStyle = {
    opacity: 0,
  };

  const circleStyle = {
    height: '12px',
    width: '12px',
    background: '#FFFFFF',
    scale: 1,
    opacity: 0,
    translateX: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const primaryCircleStyle = {
    height: '12px',
    width: '12px',
    background: '#FFFFFF',
    scale: 1,
    opacity: 0,
    translateX: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '800',
  };

  const registerAnimation = anime.timeline({
    autoplay: true,
    loop: false,
  });

  useEffect(() => {
    const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
    if (reducedMotion.matches) {
      animationPlaying(false);
    }
    registerAnimation
      .add({
        targets: '#circles .circle',
        opacity: [0, 1],
        height: [16, 12],
        borderRadius: '50px 50px 50px 50px',
        duration: 1200,
        delay: anime.stagger(200),
        direction: 'alternate',
        rotate: 90,
        easing: 'easeOutElastic(2, .6)',
      })
      .add({
        targets: '.left',
        translateX: [0, -25, 28],
        scale: [1, 2, 1],
        background: ['#FFFFFF', '#9069FF'],
        easing: 'easeOutElastic(2, .6)',
        duration: 800,
      }, 1000)
      .add({
        targets: '.right',
        translateX: [0, 25, -28],
        scale: [1, 2, 1],
        background: ['#FFFFFF', '#9069FF'],
        duration: 800,
        easing: 'easeOutElastic(2, .6)',
      }, 1000)
      .add({
        targets: '.block',
        translateX: 0,
        translateY: 0,
        rotate: 0,
        height: 70,
        width: 76,
        duration: 800,
        borderRadius: '8px 8px 8px 16px',
      }, 2100)
      .add({
        targets: '.right',
        translateX: [24, -94],
        duration: 800,
        easing: 'easeOutElastic(2, .4)',
      }, 2600)
      .add({
        targets: '.left',
        translateX: [-24, 94],
        duration: 800,
        easing: 'easeOutElastic(2, .4)',
      }, 2600)
      .add({
        targets: '.middle',
        height: '61px',
        width: '67px',
        duration: 400,
        easing: 'easeOutElastic(1, .4)',
      }, '-=400')
      .add({
        targets: '.outer',
        scale: [-1, 0.9],
        opacity: [1, 0],
        duration: 900,
        easing: 'easeOutElastic(3, .7)',
      }, 3200)
      .add({
        targets: '.logo',
        opacity: [0, 1],
        scale: [2, 1],
        duration: 600,
      }, '-=700');

    registerAnimation.finished.then(() => {
      animationPlaying(false);
    });
  }, []);

  return (
    <div style={wrapperStyle}>
      <div id="circles" style={circlesStyle}>
        <div data-x="-48" data-y="17" className="circle left block outer" style={circleStyle} />
        <div data-x="-24" data-y="-64" className="circle block" style={primaryCircleStyle}>
          <div className="middle" style={middleStyle}>
            <svg style={svgStyle} className="logo" width="50px" height="48px" viewBox="0 0 25 23" version="1.1">
              <g id="Page-1" stroke="none" strokeWidth="1.8" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-179.000000, -147.000000)" stroke="#9069ff" strokeWidth="1.8">
                  <line x1="193.141958" y1="150.307199" x2="204" y2="150.307199" id="Path-29" />
                  <circle id="Oval" cx="191.5" cy="150.5" r="2.5" />
                  <circle id="Oval-Copy-2" cx="191.5" cy="159.5" r="2.5" />
                  <circle id="Oval-Copy" cx="182.5" cy="150.5" r="2.5" />
                  <circle id="Oval-Copy-3" cx="182.5" cy="159.5" r="2.5" />
                  <path d="M194.32863,159.126398 L195.946241,159.126398 C197.050811,159.126398 197.946241,160.021828 197.946241,161.126398 L197.946241,170 L197.946241,170" id="Path-30" strokeLinejoin="round" />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div className="circle block right outer" style={circleStyle} />
      </div>
    </div>
  );
}

RegisterAnimation.propTypes = {
  animationPlaying: PropTypes.func.isRequired,
};

export default RegisterAnimation;
