/* eslint-disable import/prefer-default-export */
import { accountStripePortalUrl } from '../urls';

export async function postAccountStripePortal(returnURL) {
  const data = {
    returnURL,
  };
  const response = await fetch(accountStripePortalUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
  return response;
}
