import Analytics from 'analytics';
import mixpanelPlugin from '@analytics/mixpanel';

const analytics = Analytics({
  app: 'song-identity',
  plugins: [
    mixpanelPlugin({
      token: process.env.MIXPANEL_ID,
    }),
  ],
});

export default function useAnalytics() {
  return analytics;
}
