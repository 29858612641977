/* eslint-disable react/no-unescaped-entities */
import PropTypes from 'prop-types';

import BaseButton from '@ui-kit/buttons/baseButton';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import PanelDataCard from '@distinct-components/cards/panelDataCard';
import Icon from '@ui-kit/icon';
import VerifyIdentityAnimation from '@animations/identity/VerifyIdentityAnimation';
import CardIcon from '@assets/icons/identity-card.svg';
import SmileIcon from '@assets/icons/smile-square.svg';
import AlertMessage from '@ui-kit/alert/Alert';
import StripeLogo from '@assets/logos/stripe-logo-mark.svg';

import {
  StyledVerifyWrapper, StyledVerifyCard, StyledPrompt,
} from './VerificationReadyStyles';

function VerificationReady({
  handleVerifyLink,
  isLoading,
  error,
}) {
  return (
    <StyledVerifyWrapper>

      <StyledVerifyCard>

        <Box display="flex" justifyContent="center">
          <VerifyIdentityAnimation />
        </Box>

        <Box mb="1em" display="flex" alignItems="center" flexDirection="column">
          <Text fontSize="1.75rem" fontWeight="800">Get Verified</Text>
          <StyledPrompt>You can start this now or return anytime to complete your verification. It generally takes less than 5 minutes</StyledPrompt>
        </Box>

        <PanelDataCard mb="1.25em" variant="base">
          <Box display="flex" alignItems="center" p="0.75em 0.75em">
            <Icon size="1.45em" mr="0.65em" pt="2px" color="var(--brand-primary)"><CardIcon /></Icon>
            <Box>
              <Text lineHeight="1" fontWeight="500" mb="0.25em">Have an ID Card Ready</Text>
              <Text lineHeight="1" color="var(--text-medium-mute)">Drivers License or Passport</Text>
            </Box>
          </Box>
        </PanelDataCard>

        <PanelDataCard mb="1.5em" mt="1em" variant="base">
          <Box display="flex" alignItems="center" p="0.75em 0.75em">
            <Icon size="1.45em" mr="0.65em" pt="2px" color="var(--brand-primary)"><SmileIcon /></Icon>
            <Box>
              <Text lineHeight="1" fontWeight="500" mb="0.25em">You Need a Smile</Text>
              <Text lineHeight="1" color="var(--text-medium-mute)">Selfies taken for biometric matching</Text>
            </Box>
          </Box>
        </PanelDataCard>

        {error
            && (
            <AlertMessage
              variant="negative"
              message={error}
              mb="1.5em"
            />
            )}

        <BaseButton
          mb="0.75em"
          type="submit"
          btnText="Start Human Verification"
          decoration="arrow"
          isLoading={isLoading}
          fluid
          onClick={handleVerifyLink}
        />

        <Box mt="1.75em" mb="0.75em" display="flex" justifyContent="center">
          <Icon size="8em" color="var(--text-soft-mute)"><StripeLogo /></Icon>
        </Box>

      </StyledVerifyCard>

    </StyledVerifyWrapper>
  );
}

VerificationReady.propTypes = {
  handleVerifyLink: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

VerificationReady.defaultProps = {
  error: null,
};

export default VerificationReady;
