/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import { space, variant } from 'styled-system';

export const OrgLogoWrapper = styled.div(
  ({
    orgImageURL,
    size,
    border,
  }) => `
      border: ${border};
      height: ${size};
      width:  ${size};
      border-radius: 16px;
      background-image: url('/assets/illustrations/make-it-rain.svg');
      ${orgImageURL && `background-image: url(${orgImageURL})`};
      background-size: cover;
      background-position: top center;
  
      @media screen and (max-width: ${pointBreaks.sm}) {
          height: 4em;
          width: 4em;
      }
  
      ${space};
  `,
  space,
  variant({
    variants: {
      sm: {
        height: '3.5em',
        width: '3.5em',
      },
    },
  }),
);
