import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const TableSearchWrapper = styled.div`
    width: 12em;
    position: relative;

    @media screen and (max-width: ${pointBreaks.sm}) {
        width: 100%;
        margin-top: 1em;
    }
`;

export const ClearSearchChip = styled.div`
    position: absolute;
    height: 24px;
    width: 24px;
    background: var(--active-nav);
    border-radius: 50px;
    top: 4px;
    right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: ${pointBreaks.sm}) {
        height: 28px;
        width: 28px;
     }
`;
