/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import AppState from '@state';

import { postWorkspaceContractAmendment } from '@api/restricted/workspace-contract-amendment-api';

import loadComposition from '@actions/loadComposition';

import BaseButton from '@ui-kit/buttons/baseButton';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import AlertMessage from '@ui-kit/alert/Alert';

import { COMPOSITION_EXPLANATIONS } from '@constants/supportingCopy';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

function UnlockSingleWriter({
  closeFunction,
}) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onUnlockComposition = () => {
    setError(null);
    setIsLoading(true);

    postWorkspaceContractAmendment(AppState.composition.id.value, '')
      .then((response) => {
        if (response.status === 200) {
          loadComposition(AppState.composition.id.value).then(() => {
            setError(null);
            setIsLoading(false);
            closeFunction();
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>

      <ConversationPrompt
        primaryMessage="Make Ownership Changes"
        promptLabel="Single Writer"
        labelColor="var(--purple-300)"
        mb="1em"
      />
      <ConversationExplanation mb="2em" explanation={COMPOSITION_EXPLANATIONS.singleWriter.unlockPrompt} truncateAtCharacter={500} />

      {error && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
        />
      )}

      <BaseButton
        btnText="Unlock Composition"
        fluid
        isLoading={isLoading}
        onClick={onUnlockComposition}
      />
    </BaseEditOverlay>
  );
}

UnlockSingleWriter.propTypes = {
  closeFunction: PropTypes.func.isRequired,
};

export default UnlockSingleWriter;
