/* eslint-disable object-curly-newline */
import { useEffect, useState } from 'preact/hooks';

import { getAdminMetrics } from '@api/admin/admin-metrics-api';

import useErrorOverlay from '@hooks/useErrorOverlay';

import CoreAdminLayout from '@layouts/coreAdmin/CoreAdminLayout';
import PageCard from '@layouts/pageCard/PageCard';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';

function AdminMetrics() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [metricsData, setMetricsData] = useState({});

  const handleLoadMetricsData = async () => {
    try {
      const response = await getAdminMetrics();
      if (response.status !== 200) {
        try {
          const json = await response.json();
          useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading metrics` });
        } catch (err) {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading metrics` });
        }
      } else {
        setError(null);
        const json = await response.json();
        setMetricsData(json);
        setIsLoading(false);
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading metrics` });
    }
  };

  useEffect(() => {
    handleLoadMetricsData();
  }, []);

  return (
    <CoreAdminLayout>
      <PageCard
        title="Metrics"
        isLoadingPage={isLoading}
      >

        {error && (
        <Box mb="1rem" mt="1rem">
          <Text color="var(--red-300)">
            Error Loading Metrics:
            {' '}
            {error}
          </Text>
        </Box>
        )}

        {!isLoading && (
          <Box mt="1rem">

            <Box mb="0.5rem">
              <Text fontWeight="600" fontSize="1rem">User Accounts Summary</Text>
            </Box>
            <Table mb="2em" tableMinHeight="0em">
              <TableHeader>
                <TableCell>Metric</TableCell>
                <TableCell>All Time</TableCell>
                <TableCell>Last 7 Days</TableCell>
                <TableCell>Last 30 Days</TableCell>
              </TableHeader>
              {metricsData && metricsData.accountsSummary
          && Object.keys(metricsData.accountsSummary).map((key) => {
            const metric = metricsData.accountsSummary[key];
            return (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{metric.allTime}</TableCell>
                <TableCell>{metric.last7Days}</TableCell>
                <TableCell>{metric.last30days}</TableCell>
              </TableRow>
            );
          })}
            </Table>

            <Box mb="0.5rem">
              <Text fontWeight="600" fontSize="1rem">Writer Counts by Organization</Text>
            </Box>
            <Table mb="2em" tableMinHeight="0em">
              <TableHeader>
                <TableCell>Metric</TableCell>
                <TableCell>All Time</TableCell>
                <TableCell>Last 7 Days</TableCell>
                <TableCell>Last 30 Days</TableCell>
                <TableCell>Current Outstanding Invites</TableCell>
              </TableHeader>
              {metricsData.writerOrgSummary.map((pub) => (
                <TableRow>
                  <TableCell>{pub.name}</TableCell>
                  <TableCell>{pub.counts.allTime}</TableCell>
                  <TableCell>{pub.counts.last7Days}</TableCell>
                  <TableCell>{pub.counts.last30days}</TableCell>
                  <TableCell>{pub.pendingInvites}</TableCell>
                </TableRow>
              ))}
            </Table>

            <Box mb="0.5rem">
              <Text fontWeight="600" fontSize="1rem">Writer Activity Summary</Text>
            </Box>
            <Table mb="2em" tableMinHeight="0em">
              <TableHeader>
                <TableCell>Metric</TableCell>
                <TableCell>All Time</TableCell>
                <TableCell>Last 7 Days</TableCell>
                <TableCell>Last 30 Days</TableCell>
              </TableHeader>
              {metricsData && metricsData.writerActivitySummary
          && Object.keys(metricsData.writerActivitySummary).map((key) => {
            const metric = metricsData.writerActivitySummary[key];
            return (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{metric.allTime}</TableCell>
                <TableCell>{metric.last7Days}</TableCell>
                <TableCell>{metric.last30days}</TableCell>
              </TableRow>
            );
          })}
            </Table>

          </Box>
        )}

      </PageCard>

    </CoreAdminLayout>
  );
}

export default AdminMetrics;
