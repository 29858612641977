/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import AppState from '@state';

import { patchRevertCompositionExportedStatus } from '@api/restricted/org-composition-status-api';

import useExportCSV from '@hooks/useExportCSV';
import d from '@hooks/useIsoDateFormat';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import BreakLine from '@ui-kit/dividers/breakLine';
import Icon from '@ui-kit/icon';
import DownloadIcon from '@assets/icons/download-doc.svg';
import Spinner from '@ui-kit/loaders/Spinner';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import DeleteConfirmationOverlay from '@shared-overlays/deleteConfirmationOverlay';

import { Fragment } from 'preact';
import {
  StatusBarDownloadWrapper,
  DownloadPrompt,
} from './ToolsExportsStyles';

function ToolsExports({ composition, loadInternalData, exportedStatus }) {
  const [isGeneratingCSV, setIsGeneratingCSV] = useState(false);
  const [isGeneratingLyrics, setIsGeneratingLyrics] = useState(false);
  const [selectedExportFlagToClear, setSelectedExportFlagToClear] = useState(null);
  const [removeFlagError, setRemoveFlagError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const data = composition.writers.map((writer) => ({
    Writer_Name: writer.legalName,
    Controlled_Writer: writer.isControlled ? 'Yes' : 'No',
    IPI: writer.ipi || 'Not Defined',
    Society: writer.societies || 'Not Defined',
    ISNI: writer.isni || 'Not Defined',
    Verified: writer.isVerified ? 'Verified' : 'Not Verified',
    Writer_Contribution: writer.contribution,
    Writer_Split: `${writer.share}%`,
    Publishing_Ownership: writer.pubPreset?.owners
      ? writer.pubPreset.owners
        .map((p) => `${p.name} \nIPI: ${p.ipi} \nAddress: ${p.address} \nOwnership Percentage: ${p.ownedPercentage}% \nVerified Publisher: ${p.isVerified ? 'Yes' : 'No'}`)
        .join('\n\n')
      : 'Not Defined',
    Publishing_Admin: writer.pubPreset?.admins
      ? writer.pubPreset.admins
        .map((p) => `${p.name} \nIPI: ${p.ipi} \nAddress: ${p.address} \nTerritory: ${p.territory} \nVerified Publisher: ${p.isVerified ? 'Yes' : 'No'}`)
        .join('\n\n')
      : 'Not Defined',
    Persona: writer.persona?.name || 'No Persona',
    Persona_IPI: writer.persona?.ipi || 'No Unique IPI',
    Persona_ISNI: writer.persona?.isni || 'No Unique ISNI',
  }));

  const headers = ['Writer_Name', 'Controlled_Writer', 'IPI', 'Society', 'ISNI', 'Verified', 'Writer_Contribution', 'Writer_Split', 'Publishing_Ownership', 'Publishing_Admin', 'Persona', 'Persona_IPI', 'Persona_ISNI'];

  const { csvBlob, error } = useExportCSV(data, headers);

  const handleExport = async () => {
    setIsGeneratingCSV(true);

    setTimeout(() => {
      const currentDate = new Date();
      const dateStamp = currentDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      if (csvBlob) {
        const url = URL.createObjectURL(csvBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${composition.name} - Summary - ${dateStamp}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setIsGeneratingCSV(false);
      } else if (error) {
        setIsGeneratingCSV(false);
      }
    }, 700);
  };

  const handleExportLyrics = () => {
    setIsGeneratingLyrics(true);
    setTimeout(() => {
      const currentDate = new Date();
      const dateStamp = currentDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const blob = new Blob([composition.lyrics.text], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${composition.name} - Lyrics - ${dateStamp}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsGeneratingLyrics(false);
    }, 700);
  };

  const onClickRevertExport = (type) => {
    setSelectedExportFlagToClear(type);
  };

  const handleClearExportFlag = () => {
    setIsLoading(true);
    setRemoveFlagError('');

    patchRevertCompositionExportedStatus(AppState.pubOrganization.id.value, composition.id, selectedExportFlagToClear)
      .then((response) => {
        if (response.status === 200) {
          loadInternalData(composition.id).then(() => {
            setIsLoading(false);
            setSelectedExportFlagToClear(null);
          });
        } else {
          response.json()
            .then((json) => setRemoveFlagError(json.error || response.statusText))
            .catch(() => setRemoveFlagError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setRemoveFlagError(err.message);
      });
  };

  return (
    <Fragment>
      <Box p="0.5em 1em 0em 1em" mt="0.5em">

        {exportedStatus.csv && (
        <Fragment>
          <Box>
            <Text>
              Composition Exported to CSV:
              {' '}
              {d(exportedStatus.csv)}
            </Text>
            <LinkButton
              type="button"
              color="var(--text-secondary)"
              btnText="Remove"
              onClick={() => onClickRevertExport('csv')}
            />
          </Box>
          <BreakLine mt="1rem" mb="1rem" color="var(--panel-info-border)" />
        </Fragment>
        )}

        {exportedStatus.cwr && (
        <Fragment>
          <Box>
            <Text>
              Composition Exported to CWR:
              {' '}
              {d(exportedStatus.cwr)}
            </Text>
            <LinkButton
              type="button"
              color="var(--text-secondary)"
              btnText="Remove"
              onClick={() => onClickRevertExport('cwr')}
            />
          </Box>
          <BreakLine mt="1rem" mb="1rem" color="var(--panel-info-border)" />
        </Fragment>
        )}

        <Box display="flex">
          <Box minHeight="1.75em" display="flex" alignItems="center" mr="1.5em">
            {isGeneratingCSV
              ? (
                <Box display="flex">
                  <Spinner size="1.125em" variant="page" />
                  <DownloadPrompt>Generating CSV</DownloadPrompt>
                </Box>
              )
              : (
                <StatusBarDownloadWrapper onClick={handleExport}>
                  <Icon
                    size="1.125em"
                    pt="4px"
                    cursor
                    color="var(--icon)"
                  >
                    <DownloadIcon />
                  </Icon>
                  <DownloadPrompt>Writer Summary CSV</DownloadPrompt>
                </StatusBarDownloadWrapper>
              )}
          </Box>

          {!composition.lyrics.instrumental && composition.lyrics.text?.length > 1
          && (
            <Box minHeight="1.75em" display="flex" alignItems="center" mr="1em">
              {isGeneratingLyrics
                ? (
                  <Box display="flex">
                    <Spinner size="1.125em" variant="page" />
                    <DownloadPrompt>Generating File</DownloadPrompt>
                  </Box>
                )
                : (
                  <StatusBarDownloadWrapper onClick={handleExportLyrics}>
                    <Icon
                      size="1.125em"
                      pt="4px"
                      cursor
                      color="var(--icon)"
                    >
                      <DownloadIcon />
                    </Icon>
                    <DownloadPrompt>Download Lyrics</DownloadPrompt>
                  </StatusBarDownloadWrapper>
                )}
            </Box>
          )}

        </Box>

        <BreakLine mt="1em" color="var(--panel-info-border)" />
      </Box>

      {selectedExportFlagToClear && (
      <DeleteConfirmationOverlay
        headerLabel="Are you sure?"
        header={`Remove Exported Flag (${selectedExportFlagToClear})`}
        explanation="Mark the composition as not exported. This allows the writer to make amendments. The composition will be included in the next export you create."
        closeFunction={() => setSelectedExportFlagToClear(null)}
        handleDelete={() => handleClearExportFlag()}
        error={removeFlagError}
        isLoading={isLoading}
        confirmationPhrase="Remove Export Flag"
        buttonText="Confirm"
      />
      )}
    </Fragment>
  );
}

ToolsExports.propTypes = {
  composition: PropTypes.object.isRequired,
  loadInternalData: PropTypes.func.isRequired,
  exportedStatus: PropTypes.object.isRequired,
};

export default ToolsExports;
