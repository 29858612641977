import PropTypes from 'prop-types';

import {
  StyledBaseCard,
} from './BaseCardStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function BaseCard({
  children,
  width,
  isSelected,
  cursor,
  padding,
  minHeight,
  ...restProps
}) {
  return (
    <StyledBaseCard
      width={width}
      isSelected={isSelected}
      cursor={cursor}
      padding={padding}
      minHeight={minHeight}
      {...restProps}
    >
      {children}
    </StyledBaseCard>
  );
}

BaseCard.propTypes = {
  children: childrenPropTypes.isRequired,
  width: PropTypes.string,
  isSelected: PropTypes.bool,
  cursor: PropTypes.string,
  padding: PropTypes.string,
  minHeight: PropTypes.string,
};

BaseCard.defaultProps = {
  width: null,
  isSelected: false,
  cursor: null,
  padding: null,
  minHeight: null,
};

export default BaseCard;
