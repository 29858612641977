import styled from 'styled-components';

export const ImageUploadButton = styled.label`
    height: 40px;
    width: 40px;
    background: var(--background);
    border: 2px solid var(--brand-primary);
    position: absolute;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    input {
        display: none;
    }
`;

export const ImageContainer = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    background: var(--input-bg);
    border-radius: 0.5em;
    margin-bottom: 1.5em;
    padding: 1em;
    position: relative;

    img {
        height: 90px;
        width: auto;
        z-index: 800;
        cursor: pointer;
        border-radius: 0.5em;
        overflow: hidden;
    }
`;
