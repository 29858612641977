import anime from 'animejs/lib/anime.es';
import { useEffect } from 'preact/hooks';

function MailWedge() {
  const pathStyle = {
    fill: 'none',
    stroke: '#FFFFFF',
    strokeWidth: 1.5,
  };

  const mailAnimation = anime.timeline({
    autoplay: true,
  });

  useEffect(() => {
    mailAnimation.add({
      targets: '#wedgeMask path',
      scale: [0.75, 1],
      translateX: ['-8px', 0],
      duration: 800,
      elasticity: 900,
      direction: 'reverse',
    }).add({
      targets: '#alt-lines path',
      translateY: ['32px', 0],
      opacity: [0, 1],
      duration: 800,
      elasticity: 400,
      delay: anime.stagger(100),
    }, 200).add({
      targets: '.line',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeOutSine',
      duration: 700,
      loop: false,
    }, 400);
  }, []);

  return (
    <div id="lineDrawing">
      <svg width="52px" height="46px" viewBox="0 0 52 46" version="1.1">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="01-C---Check-Your-Email" transform="translate(-890.000000, -205.000000)">
            <g className="lines" id="Group-3" transform="translate(890.314286, 205.000000)">
              <mask id="wedgeMask">
                <path d="M18.1720009,0 L44.8570066,0 C48.4548225,2.27270362e-16 51.3714286,2.91660605 51.3714286,6.51442194 L51.3714286,15.2703588 L51.3714286,38.6355781 C51.3714286,42.233394 48.4548225,45.15 44.8570066,45.15 L6.51442194,45.15 C2.91660605,45.15 -4.47573048e-16,42.233394 0,38.6355781 L0,18.1720009 C-1.2290701e-15,8.13588192 8.13588192,-1.70910853e-15 18.1720009,0 Z" fill="white" transform="translate(25.685714, 22.575000) scale(1, -1) translate(-25.685714, -22.575000) " />
              </mask>
              <path mask="url(#wedgeMask)" d="M18.1720009,0 L44.8570066,0 C48.4548225,2.27270362e-16 51.3714286,2.91660605 51.3714286,6.51442194 L51.3714286,15.2703588 L51.3714286,38.6355781 C51.3714286,42.233394 48.4548225,45.15 44.8570066,45.15 L6.51442194,45.15 C2.91660605,45.15 -4.47573048e-16,42.233394 0,38.6355781 L0,18.1720009 C-1.2290701e-15,8.13588192 8.13588192,-1.70910853e-15 18.1720009,0 Z" id="Rectangle-Copy-65" fill="#9069FF" transform="translate(25.685714, 22.575000) scale(1, -1) translate(-25.685714, -22.575000) " />
              <g id="alt-lines">
                <path d="M24.3445192,21.4549718 L21.2145144,18.9549717 C20.8945144,18.6949717 20.8345193,18.2249687 21.0945193,17.9049687 C21.3545193,17.5849687 21.8245071,17.5249736 22.1445071,17.7849735 L25.2745119,20.2849736 C26.0345118,20.8949736 27.3245094,20.8949736 28.0845094,20.2849736 L31.2145143,17.7849735 C31.5345143,17.5249736 32.0145021,17.5749687 32.2645021,17.9049687 C32.5245021,18.2249687 32.474507,18.7049717 32.144507,18.9549717 L29.0145021,21.4549718 C28.3745021,21.9849718 27.5245155,22.2449651 26.6845155,22.2449651 C25.8445155,22.2449651 24.9945192,21.9849718 24.3445192,21.4549718 Z" id="Vector" fill="#FBFAFF" fillRule="nonzero" />
                <path d="M16.6857143,26.625 C16.2757143,26.625 15.9357143,26.285 15.9357143,25.875 C15.9357143,25.465 16.2757143,25.125 16.6857143,25.125 L22.6857143,25.125 C23.0957143,25.125 23.4357143,25.465 23.4357143,25.875 C23.4357143,26.285 23.0957143,26.625 22.6857143,26.625 L16.6857143,26.625 Z" id="Vector" fill="#FBFAFF" fillRule="nonzero" />
                <path d="M16.6857143,22.625 C16.2757143,22.625 15.9357143,22.285 15.9357143,21.875 C15.9357143,21.465 16.2757143,21.125 16.6857143,21.125 L19.6857143,21.125 C20.0957143,21.125 20.4357143,21.465 20.4357143,21.875 C20.4357143,22.285 20.0957143,22.625 19.6857143,22.625 L16.6857143,22.625 Z" id="Vector" fill="#FBFAFF" fillRule="nonzero" />
              </g>
              <path className="line" style={pathStyle} d="M16.7181125,18.1151829 L16.7181125,16.8563671 C16.7181125,14.6472281 18.5089735,12.8563671 20.7181125,12.8563671 L32.6968685,12.8563671 C34.9060075,12.8563671 36.6968685,14.6472281 36.6968685,16.8563671 L36.6968685,25.8798714 C36.6968685,28.0890104 34.9060075,29.8798714 32.6968685,29.8798714 L21.870956,29.8798714 L21.870956,29.8798714" id="Path-28" strokeLinecap="round" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default MailWedge;
