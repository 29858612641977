/* eslint-disable no-plusplus */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'preact/hooks';

import Box from '@ui-kit/box';

import { StyledPageButton, StyledBaseSelect, StyledItemsPerPageText } from './TablePaginationStyles';

function Pagination({
  handleControlItemsPerPage,
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
  marginBottom,
}) {
  const [currentPageSet, setCurrentPageSet] = useState(0);
  const [pageSets, setPageSets] = useState();
  const [lastPage, setLastPage] = useState();

  useEffect(() => {
    const numbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      numbers.push(i);
    }
    const pageSetGroups = [];
    if (numbers.length > 0) {
      for (let i = 0; i < numbers.length; i += 3) {
        pageSetGroups.push(numbers.slice(i, i + 3));
      }
      setPageSets(pageSetGroups);
      const lastSet = pageSetGroups[pageSetGroups.length - 1];
      const lastNumber = lastSet[lastSet.length - 1];
      setLastPage(lastNumber);
      if (currentPage > 3) {
        pageSetGroups.forEach((set, index) => {
          if (set.some((page) => page === currentPage)) {
            setCurrentPageSet(index);
          }
        });
      } else {
        setCurrentPageSet(0);
      }
    }
  }, [itemsPerPage, totalItems]);

  const handlePaginate = (number) => {
    const current = pageSets[currentPageSet];
    const endOfSet = current[current.length - 1];
    const startOfSet = current[0];
    if (number > endOfSet) {
      setCurrentPageSet(currentPageSet + 1);
    } else if (number < startOfSet) {
      setCurrentPageSet(currentPageSet - 1);
    }
    paginate(number);
  };

  const handleShowPreviousSet = () => {
    const prevSet = pageSets[currentPageSet - 1];
    setCurrentPageSet(currentPageSet - 1);
    paginate(prevSet[prevSet.length - 1]);
  };

  const handleShowNextSet = () => {
    const nextSet = pageSets[currentPageSet + 1];
    setCurrentPageSet(currentPageSet + 1);
    paginate(nextSet[0]);
  };

  return (
    <Box display="flex" flexWrap="wrap" justifyContent={handleControlItemsPerPage ? 'space-between' : 'flex-end'} mb={marginBottom} alignItems="center">
      {handleControlItemsPerPage
      && (
        <Box display="flex" alignItems="center">
          <StyledBaseSelect
            defaultValue={itemsPerPage}
            onChange={(e) => handleControlItemsPerPage(parseInt(e.target.value, 10))}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={75}>75</option>
            <option value={100}>100</option>
          </StyledBaseSelect>
          <StyledItemsPerPageText>Items Per Page</StyledItemsPerPageText>
        </Box>
      )}

      {pageSets && pageSets.length >= 1 && pageSets[0].length > 1
      && (
        <Box display="flex" justifyContent="flex-end" flexWrap="wrap">
          <StyledPageButton disabled={currentPage === 1} onClick={() => handlePaginate(currentPage - 1)}>
            Prev
          </StyledPageButton>
          {currentPageSet > 0 && <StyledPageButton onClick={handleShowPreviousSet}>...</StyledPageButton>}
          {pageSets[currentPageSet] && pageSets[currentPageSet].map((number) => (
            <StyledPageButton key={number} isActive={number === currentPage} onClick={() => handlePaginate(number)}>
              {number}
            </StyledPageButton>
          ))}
          {currentPageSet < (pageSets.length - 1) && <StyledPageButton onClick={handleShowNextSet}>...</StyledPageButton>}
          <StyledPageButton disabled={currentPage === lastPage} onClick={() => handlePaginate(currentPage + 1)}>
            Next
          </StyledPageButton>
        </Box>
      )}
    </Box>
  );
}

Pagination.propTypes = {
  handleControlItemsPerPage: PropTypes.func,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  marginBottom: PropTypes.string,
};

Pagination.defaultProps = {
  handleControlItemsPerPage: null,
  marginBottom: '8em',
};

export default Pagination;
