import { accountPublisherUrl } from '../urls';
import refresh from '../refresh';

export async function getAccountPublishers() {
  return refresh(async () => {
    const response = await fetch(accountPublisherUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function postAccountPublisher(name, society, ipi, address) {
  const data = {
    name,
    society,
    ipi,
    address,
  };
  return refresh(async () => {
    const response = await fetch(accountPublisherUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function putAccountPublisher(publisherId, name, society, ipi, address) {
  const data = {
    publisher: publisherId,
    name,
    society,
    ipi,
    address,
  };
  return refresh(async () => {
    const response = await fetch(accountPublisherUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteAccountPublisher(publisherId) {
  const data = {
    publisher_id: publisherId,
  };
  return refresh(async () => {
    const response = await fetch(accountPublisherUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
