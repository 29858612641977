/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import AppState from '@state';
import { useLayoutEffect, useRef } from 'preact/hooks';

import StyledTable from './TableStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function Table({
  children,
  tableMinHeight,
  scrollContents,
  ...restProps
}) {
  const tableContentRef = useRef(null);

  useLayoutEffect(() => {
    if (scrollContents) {
      tableContentRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
  }, [
    AppState.publisherCompositionsTable.currentPage.value,
    AppState.publisherCompositionsTable.activeConfig.sort.value,
    AppState.publisherCompositionsTable.activeConfig.toggle.value,
    AppState.publisherCompositionsTable.itemsPerPage?.value,
  ]);

  return (
    <StyledTable tableMinHeight={tableMinHeight} {...restProps} scrollContents={scrollContents}>
      <div ref={tableContentRef} />
      {children}
    </StyledTable>
  );
}

Table.propTypes = {
  children: childrenPropTypes.isRequired,
  tableMinHeight: PropTypes.string,
  scrollContents: PropTypes.bool,
};

Table.defaultProps = {
  tableMinHeight: null,
  scrollContents: false,
};

export default Table;
