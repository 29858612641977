/* eslint-disable react/forbid-prop-types */
import { useState, useEffect, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import { route } from 'preact-router';
import AppState from '@state';

import useWindowDimensions from '@hooks/useWindowDimensions';
import useTruncateText from '@hooks/useTruncateText';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Spinner from '@ui-kit/loaders/Spinner';
import Card from '@distinct-components/cards/baseCard';
import Icon from '@ui-kit/icon';
import CompositionIcon from '@assets/illustrations/composition-chip.svg';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';
import TableIllustration from '@ui-kit/table/tableIllustration';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';

function ProfileOverlayCompositions({ compositions, isLoading }) {
  const [searchActive, setSearchActive] = useState(false);
  const [currentCompositions, setCurrentCompositions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  useEffect(() => {
    if (height < 1080) {
      setItemsPerPage(7);
    }
    setCurrentCompositions(compositions);
    setSearchActive(false);
  }, [compositions]);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = currentCompositions.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    const results = [];
    setCurrentPage(1);
    if (query) {
      compositions.forEach((c) => {
        if (c.compositionName.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(c);
        }
      });
      setCurrentCompositions(results);
      setSearchActive(true);
    } else {
      setCurrentCompositions(compositions);
      setSearchActive(false);
    }
  };

  const onClickComposition = (compUuid) => {
    AppState.navigation.backActionPath.value = `/publisher/${AppState.pubOrganization.uuid.value}/songwriters`;
    route(`/publisher/${AppState.pubOrganization.uuid.value}/compositions/${compUuid}`);
  };

  return (
    <Box>
      {isLoading ? (
        <Box>
          <Box width="100%" display="flex" justifyContent="center" alignItems="center" minHeight="260px">
            <Spinner size="1.25em" variant="page" />
          </Box>
        </Box>
      ) : (
        <Box>
          <div ref={pageRef} />
          {compositions.length === 0 ? (
            <Card variant="panel" mt="1.5em">
              <BaseEmptyState
                title="No Compositions"
                message="The Songwriter's works will appear here after delivery."
                illustration="composition"
                minHeight="38em"
              />
            </Card>
          ) : (
            <Fragment>
              <TableControls
                itemName="Composition"
                tableData={compositions}
                currentResults={currentResults}
                indexOfFirst={indexOfFirst}
                indexOfLast={indexOfLast}
                showSearch
                searchActive={searchActive}
                searchResults={currentCompositions}
                handleSearch={onSearchResults}
              />
              <Table mb="1em">
                <TableHeader>
                  <TableCell widthPercent="48%" pr="0.75em">Name</TableCell>
                  <TableCell>Date Written</TableCell>
                  <TableCell>Writers</TableCell>
                  <TableCell>Finalized</TableCell>
                  <TableCell align="right" pr="1em">Internal Status</TableCell>
                </TableHeader>
                {currentResults
                  .map((composition) => (
                    <TableRow onClick={() => { onClickComposition(composition.uuid); }} cursor>
                      <TableCell widthPercent="48%" pr="0.75em">
                        <TableIllustration>
                          <Icon className="icon" size="3.25em" pt="2px" mr="1em"><CompositionIcon /></Icon>
                          <Box>
                            <Text fontSize="0.938rem" color="var(--text-primary)" fontWeight="600">
                              {useTruncateText(composition.compositionName, { sm: 20, md: 10, lg: 45 })}
                            </Text>
                            <Text mt="0.125em" fontSize="0.688rem;" maxWidth="16em" truncate opacity="0.6">
                              id:
                              {composition.did}
                            </Text>
                          </Box>
                        </TableIllustration>
                      </TableCell>
                      <TableCell mobileLabel="Date Written:">{composition.writtenDate}</TableCell>
                      <TableCell mobileLabel="Writers:">{composition.writerCount}</TableCell>
                      <TableCell mobileLabel="Finalized:" color={composition.finalized ? 'var(--green-300)' : 'var(--text-medium-mute)'}>
                        {composition.finalized ? (
                          <Box>
                            {composition.writerCount > 1 ? 'Split Sheet Executed' : 'Single Writer Attested'}
                            <Text mt="0.125em" fontSize="0.75rem" maxWidth="24em" truncate opacity="0.8">
                              {composition.finalized}
                            </Text>
                          </Box>
                        ) : 'Not Finalized'}
                      </TableCell>
                      <TableCell
                        align="right"
                        pr="1em"
                        mobileLabel="Status:"
                        color={composition.status ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}
                      >
                        {composition.status || 'None'}
                      </TableCell>
                    </TableRow>
                  ))}
                {(currentResults.length === 0) && (
                <Box p="1em">
                  <Text color="var(--text-muted-panel)">No Compositions Match Your Search</Text>
                </Box>
                )}
              </Table>
              <TablePagination
                itemsPerPage={itemsPerPage}
                totalItems={currentCompositions.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </Fragment>
          )}

        </Box>
      )}
    </Box>
  );
}

ProfileOverlayCompositions.propTypes = {
  compositions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ProfileOverlayCompositions;
