/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';
import BaseButton from '@ui-kit/buttons/baseButton';

export const ActionWrapper = styled.div`
    width: 100%;
    background: var(--card);
    border: 1px solid ${(props) => (props.actionColor ? props.actionColor === 'green' ? 'var(--action-green)' : 'var(--action-purple)' : 'var(--border)')};
    padding: 1em;
    min-height: 8rem;
    border-radius: 16px;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
      display: block;
    }
`;

export const RejectionWrapper = styled.div`
    width: 100%;
    background: var(--card);
    border: 1px solid var(--action-orange);
    padding: 1em;
    border-radius: 16px;
    margin-bottom: 1.5rem;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
      display: block;
  }
`;

export const ActionTitle = styled.div`
   font-size: 1rem;
   color: var(--text-primary);
   margin-bottom: 0.5rem;
   font-weight: 600;
   ${space};
`;

export const ActionMsg = styled.div`
    font-size: 0.813rem;
    color: var(--text-primary);
    opacity: 0.8;
    min-height: 2rem;
    line-height: 1.5;
    margin-bottom: 0.75rem;
    ${space};
`;

export const ActionBtnRow = styled.div`
   display: flex;
   justify-content: space-between;
   gap: 1rem;
    ${space};
`;

export const SecondaryActionRow = styled.div`
   display: flex;
   border-top: 1px solid ${(props) => (props.actionColor === 'green' ? 'var(--action-green)' : 'var(--action-purple)')};
   gap: 1rem;
   margin-top: 1.5rem;
   padding-top: 1rem;
   justify-content: flex-end;
    ${space};
`;

export const StyledActionButton = styled(BaseButton)`
    background: ${(props) => (props.actionColor === 'green' ? 'var(--action-green)' : 'var(--action-purple)')};
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 0.813rem;
        padding-left: 4px;
        padding-right: 4px;
    }
`;

export const StyledSecondaryButton = styled(BaseButton)`
    border: 1px solid ${(props) => (props.actionColor === 'green' ? 'var(--action-green)' : 'var(--action-purple)')};
    background: transparent;
    color: var(--text-primary);
    font-weight: 400;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 0.813rem;
        padding-left: 4px;
        padding-right: 4px;
    }
`;
