import PropTypes from 'prop-types';
import { useRef, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import { postOrgCustomTag, deleteOrgCustomTag } from '@api/restricted/org-custom-tags-api';
import loadOrganization from '@actions/loadOrganization';

import useClickOutside from '@hooks/useClickOutside';
// import useErrorOverlay from '@hooks/useErrorOverlay';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import BaseCard from '@distinct-components/cards/baseCard';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import Icon from '@ui-kit/icon';
import ClearIcon from '@assets/icons/close-solo.svg';
import SearchInput from '@ui-kit/inputs/searchInput';
// import Spinner from '@ui-kit/loaders/Spinner';

import {
  TagCardHeader,
  TagCardBodyHeader,
  StyledBaseInput,
  StyledAddTagBtn,
  ColorPrefix,
  ColorDot,
  TagSelectorWrapper,
  TagsWrapper,
  StyledTag,
} from './ManageTagsOverlayStyles';

function ManageTagsOverlay({
  closeFunction,
}) {
  const [isSaving, setIsSaving] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [error, setError] = useState(false);
  const [searchedTags, setSearchedTags] = useState(AppState.pubOrganization.customTags.value);
  const [newTagText, setNewTagText] = useState();
  const [showColors, setShowColors] = useState(false);
  const [newTagColor, setNewTagColor] = useState('var(--pub-tag-blue)');
  const [searchActive, setSearchActive] = useState(false);

  const searchRef = useRef(null);
  const colorMenuRef = useRef(null);
  const inputRef = useRef(null);

  useClickOutside(colorMenuRef, () => {
    setTimeout(() => {
      setShowColors(false);
    }, 200);
  });

  const onClickTagColors = (e) => {
    setShowColors(!showColors);
    e.preventDefault();
  };

  const onClickNewTagColor = (color) => {
    setNewTagColor(color);
    setShowColors(false);
  };

  const handleSearch = (query) => {
    if (query) {
      setSearchedTags(AppState.pubOrganization.customTags.value.filter((t) => t.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())));
      setSearchActive(true);
    } else {
      setSearchedTags(AppState.pubOrganization.customTags.value);
      setSearchActive(false);
    }
  };

  const cleanTag = (name) => name.trim().split(/\s+/).join(' ');

  const onSaveNewTag = () => {
    if (newTagText && newTagColor) {
      const cleanedTagName = cleanTag(newTagText);

      if (!cleanedTagName) {
        setError('Invalid tag name');
        return;
      }
      if (AppState.pubOrganization.customTags.value.some((t) => t.name.toLocaleLowerCase().includes(cleanedTagName.toLocaleLowerCase()))) {
        setError('Tag already exists');
        return;
      }
      // Currently there is no limit to the number of tags

      setError('');
      setIsSaving(true);

      postOrgCustomTag(AppState.pubOrganization.id.value, cleanedTagName, newTagColor)
        .then((response) => {
          if (response.status === 200) {
            loadOrganization(AppState.pubOrganization.uuid.value, { forcedLoad: true }).then(() => {
              setNewTagText();
              inputRef.current.value = null;
              searchRef.current.base.value = '';
              handleSearch('');
              setError('');
              setIsSaving(false);
            });
          } else {
            response.json()
              .then((json) => setError(json.error || response.statusText))
              .catch(() => setError(response.statusText));
            setIsSaving(false);
          }
        })
        .catch((err) => {
          setIsSaving(false);
          setError(err.message);
        });
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 && newTagText !== null && newTagText?.length > 0) {
      e.preventDefault();
      onSaveNewTag();
    }
  };

  const onClickRemoveTag = (tag) => {
    setError('');
    setIsRemoving(true);

    deleteOrgCustomTag(AppState.pubOrganization.id.value, tag.id)
      .then((response) => {
        if (response.status === 200) {
          loadOrganization(AppState.pubOrganization.uuid.value, { forcedLoad: true }).then(() => {
            inputRef.current.value = null;
            searchRef.current.base.value = '';
            handleSearch('');
            setError('');
            setIsRemoving(false);
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsRemoving(false);
        }
      })
      .catch((err) => {
        setIsRemoving(false);
        setError(err.message);
      });
  };

  return (
    <BaseEditOverlay formWidth="40em" closeFunction={closeFunction}>
      <Fragment>
        <ConversationPrompt
          primaryMessage="Composition Tags"
          mb="0.5em"
        />
        <Text mb="1.5em">Use tags to organize and quickly find certain compositions</Text>

        <BaseCard variant="panel" p="0" mb="2em" minHeight="20em">
          <TagCardHeader>
            <Box display="flex" flexBasis="80%">
              <ColorPrefix className="cursor-p" onClick={onClickTagColors}>
                <ColorDot color={newTagColor} />
                {showColors && (
                <TagSelectorWrapper ref={colorMenuRef} className="cursor-p">
                  <ColorDot color="var(--pub-tag-green)" onClick={() => onClickNewTagColor('var(--pub-tag-green)')} />
                  <ColorDot color="var(--pub-tag-orange)" onClick={() => onClickNewTagColor('var(--pub-tag-orange)')} />
                  <ColorDot color="var(--pub-tag-pink)" onClick={() => onClickNewTagColor('var(--pub-tag-pink)')} />
                  <ColorDot color="var(--pub-tag-red)" onClick={() => onClickNewTagColor('var(--pub-tag-red)')} />
                  <ColorDot color="var(--pub-tag-blue)" onClick={() => onClickNewTagColor('var(--pub-tag-blue)')} />
                </TagSelectorWrapper>
                )}
              </ColorPrefix>
              <StyledBaseInput
                ref={inputRef}
                variant="panel"
                id="newTagText"
                name="newTagText"
                placeholder="New Tag..."
                onChange={(e) => setNewTagText(e.target.value)}
                onKeyPress={(e) => handleKeyPress(e)}
                type="text"
                maxLength="40"
                fluid
                autoComplete="off"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <StyledAddTagBtn onClick={onSaveNewTag} type="submit" btnText="Add" maxHeight="1em" disabled={isSaving || isRemoving} fluid isLoading={isSaving} />
            </Box>
          </TagCardHeader>

          <Box>
            <TagCardBodyHeader>
              <Text fontSize="0.875rem">
                Tags:
                {' '}
                {AppState.pubOrganization.customTags.value.length}
              </Text>
              <Box>
                <SearchInput
                  ref={searchRef}
                  id="search"
                  name="search"
                  placeholder="Search by name..."
                  type="text"
                  autoComplete="off"
                  fluid
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </Box>
            </TagCardBodyHeader>
            <TagsWrapper>
              {searchedTags.map((t) => (
                <StyledTag color={t.color}>
                  {t.name}
                  <Icon ml="0.5em" color="var(--white)" cursor onClick={() => onClickRemoveTag(t)} size="0.5em" style={{ transform: 'translateY(-1px)' }}><ClearIcon /></Icon>
                </StyledTag>
              ))}
              {searchActive && searchedTags.length === 0 && <Text color="var(--text-medium-mute)">No Results Match Your Search</Text>}
            </TagsWrapper>
          </Box>
        </BaseCard>

        {error && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
        />
        )}
        <BaseButton onClick={closeFunction} mb="1.5em" type="submit" btnText="Done" fluid />
      </Fragment>
    </BaseEditOverlay>
  );
}

ManageTagsOverlay.propTypes = {
  closeFunction: PropTypes.func.isRequired,
};

export default ManageTagsOverlay;
