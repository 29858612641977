import AppState from '@state';
import { getWorkspace } from '@api/restricted/workspace-api';
import useErrorOverlay from '@hooks/useErrorOverlay';

export default async function loadComposition(workspaceId) {
  try {
    const response = await getWorkspace(workspaceId);
    if (response.status !== 200) {
      const json = await response.json();
      if (json.error) {
        useErrorOverlay({ errorMessage: `${json.error} when loading workspace` });
      } else {
        useErrorOverlay({ errorMessage: `${response.statusText} when loading workspace` });
      }
    } else {
      const json = await response.json();
      AppState.composition.id.value = workspaceId;
      AppState.composition.uuid.value = json.uuid;
      AppState.composition.did.value = json.did;
      AppState.composition.name.value = json.metadata.compositionName;
      AppState.composition.tags.value = json.tags;
      AppState.composition.alternateTitles.value = json.alternate_titles;
      AppState.composition.date.value = json.written_on;
      AppState.composition.intendedUse.value = json.intended_use;
      AppState.composition.hash.value = json.hash;
      AppState.composition.isLocked.value = json.locked;
      AppState.composition.version.value = json.version;
      AppState.composition.hasSamples.value = json.has_samples;
      AppState.composition.samples.value = json.samples;
    }
  } catch (err) {
    useErrorOverlay({ errorMessage: `${err.message} when loading workspace` });
  }
}
