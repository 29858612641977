import { route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import loadAccountProfile from '@actions/loadAccountProfile';

import Card from '@distinct-components/cards/baseCard';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Logo from '@ui-kit/logo/Logo';
import Icon from '@ui-kit/icon';
import ChevronIcon from '@assets/icons/chevron-down.svg';
import Avatar from '@ui-kit/avatar';

import { LandingWrapper, StyledLandingHeader, ListItem } from './LandingStyles';

function Landing() {
  const [isCheckingAccount, setIsCheckingAccount] = useState(true);
  const isArtist = !!AppState.artistProfile?.uuid?.value;
  const isRep = AppState.isRepresentative.value;
  const numOrgs = AppState.organizations.value.length;

  useEffect(() => {
    document.body.style.backgroundColor = 'var(--background)';
    loadAccountProfile().then(() => {
      if (isArtist && !isRep && numOrgs === 0) {
        route('/home', true);
      } else if (!isArtist && isRep && numOrgs === 0) {
        route('/rep/dashboard', true);
      } else if (!isArtist && !isRep && numOrgs === 1) {
        const { uuid } = AppState.organizations.value[0];
        route(`/publisher/${uuid}/dashboard`, true);
      } else {
        setIsCheckingAccount(false);
      }
    });
  }, []);

  return (
    <LandingWrapper>
      {!isCheckingAccount
      && (
        <Fragment>
          <StyledLandingHeader>Select Account</StyledLandingHeader>
          <Card p="0em 0.5em 1.5em 0.5em">
            {AppState.organizations.value.map((org) => (
              <ListItem onClick={() => route(`/publisher/${org.uuid}/dashboard`)}>
                <Box display="flex" alignItems="center" flex="1">
                  <Logo variant="sm" mr="1.25em" mb="0em" orgImageURL={org.logoUrl} />
                  <Box>
                    <Text fontSize="1em" fontWeight="900">{org.name}</Text>
                    <Text fontSize="0.875em" color="var(--text-muted)">Manage Writers and View Delivered Compositions</Text>
                  </Box>
                </Box>
                <Box>
                  <Icon cursor size="0.5em" color="var(--purple-400)" style={{ transform: 'rotate(-90deg)' }}><ChevronIcon /></Icon>
                </Box>
              </ListItem>
            ))}
            {isArtist && (
              <ListItem onClick={() => route('/home')}>
                <Box display="flex" alignItems="center" flex="1">
                  <Box mr="1.25em">
                    <Avatar
                      backgroundColor="var(--inactive)"
                      borderColor="var(--inactive)"
                      size="3.5em"
                      imgURL={AppState.userProfile.imgURL.value}
                    />
                  </Box>
                  <Box>
                    <Text fontSize="1em" fontWeight="900">Songwriter Mode</Text>
                    <Text fontSize="0.875em" color="var(--text-muted)">Create Compositions and Document Splits</Text>
                  </Box>
                </Box>
                <Box>
                  <Icon cursor size="0.5em" color="var(--purple-400)" style={{ transform: 'rotate(-90deg)' }}><ChevronIcon /></Icon>
                </Box>
              </ListItem>
            )}
            {isRep && (
              <ListItem onClick={() => route('/rep/dashboard')}>
                <Box display="flex" alignItems="center" flex="1">
                  <Box mr="1.25em">
                    <Avatar
                      backgroundColor="var(--inactive)"
                      borderColor="var(--inactive)"
                      size="3.5em"
                      imgURL={AppState.userProfile.imgURL.value}
                    />
                  </Box>
                  <Box>
                    <Text fontSize="1em" fontWeight="900">Representative Mode</Text>
                    <Text fontSize="0.875em" color="var(--text-muted)">Manage Compositions for Your Writers</Text>
                  </Box>
                </Box>
                <Box>
                  <Icon cursor size="0.5em" color="var(--purple-400)" style={{ transform: 'rotate(-90deg)' }}><ChevronIcon /></Icon>
                </Box>
              </ListItem>
            )}
          </Card>
        </Fragment>
      )}
    </LandingWrapper>
  );
}

export default Landing;
