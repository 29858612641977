/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const StyledVerifiedDetailsRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0em 1em;
    margin-bottom: 0.75em;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;
    }
`;

export const StyledIdentifiersWrapper = styled.div`
    display: flex;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;
    }
`;

export const StyledDefaultsWrapper = styled.div`
    display: flex;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        margin-top: 0.75em;
        flex-direction: column;
        gap: 0.75em;
    }
`;

export const StyledVerifiedDataHeader = styled.div`
    background: var(--trust);
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    padding: 0.25em 1em;
    font-size: 0.813rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75em;
`;
