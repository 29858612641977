import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import PropTypes from 'prop-types';
import AppState from '@state';

import { postAccountSociety } from '@api/restricted/account-society-api';
import loadAccountProfile from '@actions/loadAccountProfile';

import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import Box from '@ui-kit/box';
import CloseSquare from '@assets/icons/close-square.svg';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import Header from '@ui-kit/typography/header';
import Icon from '@ui-kit/icon';
import PanelDataCard from '@distinct-components/cards/panelDataCard/PanelDataCard';
import SelectSociety from '@distinct-components/selectSociety';
import Text from '@ui-kit/typography/text';

import { ACCOUNT_EXPLANATIONS } from '@constants/supportingCopy';

function ManageSocietyOverlayCreate({ closeFunction }) {
  const [writerSociety, setWriterSociety] = useState(AppState.artistProfile.swCollSociety.value[0] || null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [noSocietyLinks, setNoSocietyLinks] = useState([]);

  const generateLinks = (value) => {
    switch (value) {
      case 'United States':
        return [
          { url: 'https://ome.ascap.com/en', name: 'Register with ASCAP' },
          { url: 'https://www.bmi.com/join', name: 'Register with BMI' },
        ];
      case 'Canada':
        return [
          { url: 'https://www.socan.com/sign-up-for-performing-rights/', name: 'Register with SOCAN' },
        ];
      case 'United Kingdom':
        return [
          { url: 'https://www.prsformusic.com/join', name: 'Register with PRS' },
        ];
      default:
        return [];
    }
  };

  useEffect(() => {
    const links = generateLinks(AppState.userProfile.country.value);
    setNoSocietyLinks(links);
  }, []);

  const handleDeselectSociety = () => {
    setWriterSociety(null);
    setError(null);
  };

  const handleWriterSocietySave = () => {
    setIsLoading(true);
    postAccountSociety(writerSociety.name, writerSociety.societyType, writerSociety.country)
      .then((response) => {
        if (response.status === 200) {
          loadAccountProfile().then(() => {
            if (AppState.artistProfile.societyCritical.value) {
              AppState.artistProfile.societyCritical.value = false;
            }
            setError(null);
            setIsLoading(false);
            closeFunction();
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return (
    <Box>
      <Header mb="0.75em">Add Writer Society</Header>
      <ConversationExplanation mb="2em" explanation={ACCOUNT_EXPLANATIONS.songwriting.writerSociety} />

      {!writerSociety ? (
        <SelectSociety
          societyType="pro"
          country={AppState.userProfile.country.value}
          handleSelectedSociety={setWriterSociety}
        />
      ) : (
        <Fragment>
          <Text fontWeight="660" fontSize="1rem" mb="0.5em">Selected Society</Text>
          <PanelDataCard variant="noCursor" mb={writerSociety?.name === 'NS' && noSocietyLinks.length > 0 ? '1em' : '2.5em'}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Text fontWeight="800">{writerSociety.name}</Text>
                {writerSociety.name === 'NS' ? (
                  <Text fontWeight="400" color="var(--text-muted-panel)">No Society</Text>
                ) : (
                  <Text fontWeight="400" color="var(--text-muted-panel)">{writerSociety.country}</Text>
                )}
              </Box>
              <Icon size="1.25em" cursor onClick={handleDeselectSociety}><CloseSquare /></Icon>
            </Box>
          </PanelDataCard>
        </Fragment>
      )}

      {writerSociety?.name === 'NS' && noSocietyLinks.length > 0
      && (
        <AlertMessage
          variant="warning"
          message="Get paid for your work. Based on your profile, here are some Society options:"
          messageLinks={noSocietyLinks}
          mb="1.5em"
        />
      )}

      {error && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
        />
      )}

      {writerSociety && <BaseButton mb="1.5em" onClick={handleWriterSocietySave} btnText="Save" fluid isLoading={isLoading} />}
    </Box>
  );
}

ManageSocietyOverlayCreate.propTypes = {
  closeFunction: PropTypes.func.isRequired,
};

export default ManageSocietyOverlayCreate;
