/* eslint-disable react/forbid-prop-types */
import { useRef } from 'preact/hooks';
import PropTypes from 'prop-types';

import d from '@hooks/useIsoDateFormat';

import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Logo from '@ui-kit/logo/Logo';
import Spinner from '@ui-kit/loaders/Spinner';

import {
  StyledPubCard,
  PubCardGrid,
} from './ActivePublisherConnectionsStyles';

function ActivePublisherConnections({
  setSelectedPub,
  // addNewConnection,
  isLoading,
  pubs,
}) {
  const pageRef = useRef(null);

  return (
    isLoading ? (
      <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
        <Spinner size="2em" variant="page" />
      </Box>
    ) : (
      <Box>
        <div ref={pageRef} />

        {pubs.length === 0 ? (
          <Card variant="panel" mt="1.5em">
            <BaseEmptyState
              title="No Connections"
              message="You can connect with a publisher to admin their catalog or have them perform admin functions for your organization."
              // action={addNewConnection}
              actionText="Add Connection"
              illustration="composition"
              minHeight="38em"
            />
          </Card>
        ) : (
          <PubCardGrid>
            {pubs.map((pub) => (
              <StyledPubCard onClick={() => setSelectedPub(pub)}>
                <Box display="flex" alignItems="center">
                  <Logo orgImageURL={pub.logoUrl} />
                  <Box ml="1em">
                    <Text fontSize="1rem" fontWeight="500" color="var(--text-primary)">
                      {pub.name}
                    </Text>
                    <Text mt="0.25rem" fontSize="0.75rem" color="var(--text-secondary)">
                      Connected On:
                      {' '}
                      {d(pub.createdDate)}
                    </Text>
                    <Text fontSize="0.75rem" color="var(--text-secondary)">
                      Connection Ends:
                      {' '}
                      {pub.inactiveDate ? d(pub.inactiveDate) : 'Until Revoked'}
                    </Text>
                  </Box>
                </Box>
              </StyledPubCard>
            ))}
          </PubCardGrid>
        )}
      </Box>
    )
  );
}

ActivePublisherConnections.propTypes = {
  setSelectedPub: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pubs: PropTypes.array.isRequired,
};

export default ActivePublisherConnections;
