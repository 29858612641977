import { useEffect, useState } from 'preact/hooks';

export const useScript = (url) => {
  const [useScriptState, setUseScriptState] = useState(url ? 'loading' : 'idle');

  useEffect(() => {
    if (!url) {
      setUseScriptState('idle');
      return () => {};
    }
    let script = document.querySelector(`script[src="${url}"]`);

    const handleScript = (e) => {
      setUseScriptState(e.type === 'load' ? 'ready' : 'error');
    };

    if (!script) {
      script = document.createElement('script');
      script.type = 'application/javascript';
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
    } else {
      setUseScriptState('ready');
    }

    return () => {
      if (script) {
        script.removeEventListener('load', handleScript);
        script.removeEventListener('error', handleScript);
      }
    };
  }, []);

  return useScriptState;
};

export default useScript;
