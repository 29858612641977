/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space, layout } from 'styled-system';
import Text from '@ui-kit/typography/text';
import pointBreaks from '@styles/styleConstants';

export const CardWrapper = styled.div`
    background: var(--card);
    border-radius: 1em;
    width: 100%;
    border: 1px solid var(--panel-info-border);
    display: flex;
    justify-content: space-between;
    ${space};
    ${layout};
`;

export const StyledCardText = styled(Text)`
    font-size: 0.75rem;
    color: var(--text-secondary);
    font-weight: 400;
`;

export const StyledDataRow = styled.div`
    display: flex;
    gap: 0.75em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;
        gap: 0.25em;
    }
`;

export const MenuWrapper = styled.div`
    display: flex;
    min-width: 2rem;
    justify-content: flex-end;
`;

export const ActionMenuItem = styled.div`
    border-bottom: 1px solid var(--border);
    color: var(--text-soft-mute);
    font-size: 0.75rem;
    font-weight: 500;
    width: 100%;
    padding-bottom: 1em;
    margin-bottom: 1.125em;
    cursor: pointer;
`;

export const MenuDot = styled.div`
    height: 4px;
    width: 4px;
    border-radius: 50px;
    margin-right: 4px;
    background: var(--icon);
    opacity: 0.8;
`;
