import styled from 'styled-components';
import {
  space,
} from 'styled-system';

const CountDownWrapper = styled.div(
  ({
    size,
    color,
  }) => `
  
  svg {
    width: ${size}px;
    height:${size}px;
  }
  
  svg circle {
    stroke-linecap: round;
    stroke-width: 2px;
    stroke: ${color || 'var(--white)'};
    fill: none;
  }
  
  ${space}
  `,
  space,
);

export default CountDownWrapper;
