import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const InvitationBarWrapper = styled.div`
    background: var(--table-bg);
    border: 1px solid var(--table-border);
    border-radius: 0.5em;
    margin-top: 1.5em;
`;

export const InvitationsHeader = styled.div(({
  isExpanded,
}) => `
    background: var(--table-header-bg);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 0.75em;
    border-top-right-radius: 0.5em;
    border-top-left-radius: 0.5em;
    border-bottom-right-radius: ${isExpanded ? '0em' : '0.5em'};
    border-bottom-left-radius: ${isExpanded ? '0em' : '0.5em'};
    cursor: pointer;
    min-height: 3em;

    @media screen and (max-width: ${pointBreaks.sm}) {
       display: Block;
    }
`);

export const InvitationsBody = styled.div`
    display: block;
`;

export const InvitationsBarItem = styled.div`
    padding: 1em;
    border-bottom: 1px solid var(--panel-divider);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--table-bg);
`;
