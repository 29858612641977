import { useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import AppState from '@state';

import getWorkspaceActivity from '@api/restricted/workspace-activity-api';
import useErrorOverlay from '@hooks/useErrorOverlay';
import { translateActivity } from '@hooks/translateNotificationActivity';

import EventList from '@distinct-components/eventList';
import Box from '@ui-kit/box';
import Card from '@distinct-components/cards/baseCard';

function ActivitiesSection({ workspace }) {
  const [activities, setActivities] = useState([]);

  const loadWorkspaceActivities = async () => {
    try {
      const response = await getWorkspaceActivity(workspace);
      if (response.status !== 200) {
        const json = await response.json();
        if (json.error) {
          useErrorOverlay({ errorMessage: `${json.error} when loading activities` });
        } else {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading activities` });
        }
      } else {
        const json = await response.json();
        const activitiesList = json.map((activity) => translateActivity(activity, AppState.accountId.value));
        setActivities(activitiesList);
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading activities` });
    }
  };

  useEffect(() => {
    loadWorkspaceActivities();
  }, []);

  return (
    <Box display="flex">
      <Card pt="0.5rem">
        <EventList events={activities} />
      </Card>
    </Box>
  );
}

ActivitiesSection.propTypes = {
  workspace: PropTypes.string.isRequired,
};

export default ActivitiesSection;
