import { accountSocietyUrl } from '../urls';
import refresh from '../refresh';

export async function postAccountSociety(name, type, country) {
  const data = {
    name,
    type,
    country,
  };
  return refresh(async () => {
    const response = await fetch(accountSocietyUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteAccountSociety(name) {
  const data = {
    name,
  };
  return refresh(async () => {
    const response = await fetch(accountSocietyUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
