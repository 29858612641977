/* eslint-disable no-useless-return */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import { Fragment } from 'preact';
import { useForm } from 'react-hook-form';
import AppState from '@state';

import loadComposition from '@actions/loadComposition';
import {
  patchWorkspaceHasSamples, postWorkspaceSample, putWorkspaceSample, deleteWorkspaceSample,
} from '@api/restricted/workspace-samples-api';

import Spinner from '@ui-kit/loaders/Spinner';
import Card from '@distinct-components/cards/baseCard';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Header from '@ui-kit/typography/header';
import BaseButton from '@ui-kit/buttons/baseButton';
import AlertMessage from '@ui-kit/alert/Alert';
import WorkspaceCard from '@distinct-components/cards/workspaceCard';
import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';
import CountDown from '@distinct-components/feedback/countDown';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState/BaseEmptyState';
import ItemListInput from '@ui-kit/inputs/itemListInput/ItemListInput';
import BaseTextArea from '@ui-kit/inputs/baseTextArea/BaseTextArea';

function CompositionInterpolationSection({ workspaceId, activeUser }) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedInterpolation, setSelectedInterpolation] = useState(null);
  const [writerNames, setWriterNames] = useState([]);
  const [isManageInterpolation, setIsManageInterpolation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const hasInterpolation = AppState.composition.hasSamples.value;
  const interpolations = AppState.composition.samples.value;

  const {
    register, handleSubmit, setValue, reset, formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const useToastAlertError = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'error' };
  };

  const onToggleHasInterpolation = (e) => {
    e.preventDefault();

    if (hasInterpolation && interpolations.length > 0) {
      useToastAlertError('You need to delete all added samples first');
      return;
    }

    setIsLoading(true);
    patchWorkspaceHasSamples(workspaceId, !hasInterpolation)
      .then((response) => {
        if (response.status === 200) {
          loadComposition(workspaceId).then(() => {
            setIsLoading(false);
          });
        } else {
          response.json()
            .then((json) => useToastAlertError(json.error || response.statusText))
            .catch(() => useToastAlertError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        useToastAlertError(err.message);
      });
  };

  const onClickManageInterpolation = (interpolation) => {
    if (AppState.composition.isLocked.value) {
      return;
    }
    if (interpolation?.id) {
      setValue('workName', interpolation.name);
      setValue('iswc', interpolation.iswc);
      setWriterNames(interpolation.writers);
      setValue('performedBy', interpolation.performers);
      setValue('useDescription', interpolation.description);
      setSelectedInterpolation(interpolation);
    } else {
      reset();
      setSelectedInterpolation(null);
    }
    setIsManageInterpolation(true);
  };

  const handleUpdateWriterNames = (names) => {
    setWriterNames(names);
  };

  const formatISWC = (iswc) => {
    if (!iswc) {
      return null;
    }
    const digits = iswc.replaceAll(/[^0-9]/g, '');
    if (digits.length !== 10) {
      return null;
    }
    return `T${digits}`;
  };

  const cleanWriterNames = (names) => names.map((n) => n.trim().split(/\s+/).join(' ')).filter((n) => n);

  const onClickSaveInterpolation = (data) => {
    setError(null);
    const cleanedData = {
      name: data.workName?.trim() || null,
      performers: data.performedBy?.trim().split(/\s+/).join(' ') || null,
      description: data.useDescription?.trim() || null,
    };
    if (!cleanedData.name) {
      setError('Invalid work name');
      return;
    }
    if (!cleanedData.description) {
      setError('You need to provide description of this work\'s usage');
      return;
    }
    const cleanedWriterNames = cleanWriterNames(writerNames);
    if (cleanedWriterNames.length === 0) {
      setError('You need to provide at least one writer name');
      return;
    }
    const inputISWC = data.iswc?.trim();
    const formattedISWC = inputISWC ? formatISWC(inputISWC) : null;
    if (inputISWC && !formattedISWC) {
      setError('ISWC should be the letter T followed by 10 digits');
      return;
    }

    const handleCreateEditSample = async () => (
      selectedInterpolation?.id
        ? putWorkspaceSample(workspaceId, selectedInterpolation.id, cleanedData.name, formattedISWC, cleanedWriterNames, cleanedData.performers, cleanedData.description)
        : postWorkspaceSample(workspaceId, cleanedData.name, formattedISWC, cleanedWriterNames, cleanedData.performers, cleanedData.description)
    );

    setIsLoading(true);
    handleCreateEditSample()
      .then((response) => {
        if (response.status === 200) {
          loadComposition(workspaceId).then(() => {
            setWriterNames([]);
            setSelectedInterpolation(null);
            setError('');
            setIsLoading(false);
            setIsManageInterpolation(false);
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const onClickRemoveInterpolation = () => {
    setShowDeleteConfirmation(true);
    setTimeout(() => {
      setShowDeleteConfirmation(false);
    }, 5000);
  };

  const handleRemoveInterpolation = () => {
    if (!selectedInterpolation?.id) {
      setError('Sampled Work to Delete does not exist');
      return;
    }
    setIsDeleting(true);
    deleteWorkspaceSample(workspaceId, selectedInterpolation.id)
      .then((response) => {
        if (response.status === 200) {
          loadComposition(workspaceId).then(() => {
            setWriterNames([]);
            setSelectedInterpolation(null);
            setError('');
            setIsDeleting(false);
            setIsManageInterpolation(false);
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsDeleting(false);
        }
      })
      .catch((err) => {
        setIsDeleting(false);
        setError(err.message);
      });
  };

  const onClickCancelManageInterpolation = () => {
    setWriterNames([]);
    setSelectedInterpolation(null);
    setIsManageInterpolation(false);
  };

  return (
    <Box display="flex">
      <WorkspaceCard
        title="Sampled Works"
        isCardLocked={!activeUser.isCanEdit || AppState.composition.isLocked.value}
        lockedText="You do not have permission to edit this section or the composition is locked."
        handleToggleSection={onToggleHasInterpolation}
        isToggled={hasInterpolation}
        isToggleLoading={isLoading}
        toggleLabel="Enable Section"
      >
        {isLoading
          ? <Box minHeight="16em"><Spinner size="1.25em" variant="page" /></Box>
          : (
            <Box>
              {hasInterpolation ? (
                <Box>
                  {interpolations.length > 0
                    ? (
                      <Box minHeight="14em">
                        {interpolations.map((interpolation) => (
                          <Card
                            variant="panel"
                            p="1em"
                            mb="1em"
                            cursor={AppState.composition.isLocked.value ? 'not-allowed' : 'cursor'}
                            onClick={() => onClickManageInterpolation(interpolation)}
                          >
                            <Text fontWeight="600">
                              {interpolation.name}
                            </Text>
                            <Box display="flex" flexWrap="wrap">
                              {interpolation.iswc && (
                                <Text fontSize="0.75rem" mr="0.875em">
                                  ISWC:
                                  {' '}
                                  {interpolation.iswc}
                                </Text>
                              )}
                              {interpolation.writers?.length > 0 && (
                                <Text fontSize="0.75rem" mr="0.875em">
                                  Writers:
                                  {' '}
                                  {interpolation.writers.map((w) => w).join(', ')}
                                </Text>
                              )}
                              {interpolation.performers && (
                                <Text fontSize="0.75rem" mr="0.875em">
                                  Performed by:
                                  {' '}
                                  {interpolation.performers}
                                </Text>
                              )}
                            </Box>
                            <Box display="flex">
                              <Text fontSize="0.75rem" mr="0.875em">
                                Usage Description:
                                {' '}
                                {interpolation.description || '(None)'}
                              </Text>
                            </Box>
                          </Card>
                        ))}
                        {!AppState.composition.isLocked.value && (
                        <Box display="flex" justifyContent="flex-end" mt="0.5em">
                          <LinkButton type="button" btnText="Add Another" onClick={() => onClickManageInterpolation(null)} />
                        </Box>
                        )}
                      </Box>
                    ) : (
                      <Box display="flex" justifyContent="center">
                        <BaseEmptyState
                          message="Define the works. If you have a connected Publisher, the Interpolation will be flagged to help them work through it.  Independent writers should seek guidance before recording or releasing."
                          showIllustration={false}
                          minHeight="16em"
                          mutedActions={false}
                          action={() => onClickManageInterpolation(null)}
                          actionText="Add a Work"
                        />
                      </Box>
                    )}

                </Box>
              ) : (
                <Box display="flex" justifyContent="center">
                  <BaseEmptyState
                    message="If you sampled another work (Interpolation), enable this section.  Examples include quoting lyrics or referencing recognizable music not written by you."
                    showIllustration={false}
                    minHeight="16em"
                    mutedActions={false}
                  />
                </Box>
              )}
            </Box>
          )}
      </WorkspaceCard>

      {isManageInterpolation
        && (
        <BaseEditOverlay closeFunction={onClickCancelManageInterpolation} formWidth="23em">
          <Header fontSize="1.25rem" mb="0.25em">
            {selectedInterpolation ? 'Edit' : 'Add'}
            {' '}
            Sampled Work
          </Header>
          <Text mb="1.5em">Provide everything you can to define the music you&apos;re referring to in your composition</Text>
          <Box>
            <form id="interpolationForm" onSubmit={handleSubmit(onClickSaveInterpolation)} autoComplete="off">
              <InputLabel label="Name of Musical Work" />
              <BaseInput
                {...register('workName', { required: true })}
                id="workName"
                name="workName"
                placeholder="What song was sampled or referenced?"
                type="text"
                fluid
                mb="1.5rem"
              />
              <InputLabel label="ISWC Code (Optional)" />
              <BaseInput
                {...register('iswc', { required: false })}
                id="iswc"
                name="iswc"
                placeholder="Enter ISWC..."
                type="text"
                maxLength="15" // T-000.000.000-0
                minLength="10" // 0000000000
                fluid
                mb="1.5rem"
              />
              <InputLabel label="Original Writer Names" />
              <ItemListInput
                mb="0.25em"
                placeholder="Writer Name..."
                handleListChange={handleUpdateWriterNames}
                listItems={writerNames}
                itemLimit={30}
              />
              <InputLabel mb="1.5em" color="var(--text-medium-mute)" label="Add as many as you know" />
              <InputLabel label="Performed by (Optional)" />
              <BaseInput
                {...register('performedBy', { required: false })}
                id="performedBy"
                name="performedBy"
                placeholder="Someone that recorded or performed it..."
                type="text"
                fluid
                mb="1.5rem"
              />
              <InputLabel label="What was referenced?" />
              <BaseTextArea
                {...register('useDescription', { required: true })}
                fluid
                mb="2.5em"
                id="useDescription"
                name="useDescription"
                placeholder="Ex: specific lyrics, instrumental pieces"
              />
              {error && (
              <AlertMessage
                variant="negative"
                message={error}
                mb="1.5em"
              />
              )}
              <BaseButton mb="1.5em" type="submit" btnText="Save" disabled={!isValid} fluid isLoading={isLoading} />
            </form>

            {selectedInterpolation
            && (
              <Box mb="0.5rem" display="flex" alignItems="center" justifyContent="center">
                {showDeleteConfirmation ? (
                  <Fragment>
                    <CountDown mr="0.25em" timeInSeconds={5} size={12} color="var(--red-300)" />
                    <LinkButton btnText="Yes, Delete Work" color="var(--red-300)" mr="1em" onClick={handleRemoveInterpolation} />
                  </Fragment>
                ) : (
                  <LinkButton
                    type="button"
                    color="var(--red-300)"
                    spinnerVariant="danger"
                    isLoading={isDeleting}
                    btnText="Delete Sampled Work"
                    onClick={onClickRemoveInterpolation}
                  />
                )}
              </Box>
            )}
          </Box>
        </BaseEditOverlay>
        )}
    </Box>
  );
}

CompositionInterpolationSection.propTypes = {
  workspaceId: PropTypes.number.isRequired,
  activeUser: PropTypes.object.isRequired,
};

export default CompositionInterpolationSection;
