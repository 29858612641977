/* eslint-disable react/forbid-prop-types */
import { route } from 'preact-router';
import PropTypes from 'prop-types';

import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import Text from '@ui-kit/typography/text';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import ClipboardIcon from '@assets/icons/clipboard-list.svg';

import {
  EventWrapper,
  EventIconWrapper,
  StyledEventListWrapper,
} from './TaskListStyles';

function TaskList({
  tasks,
  ...restProps
}) {
  const onClickTask = (link) => {
    route(link);
  };

  return (
    <StyledEventListWrapper id="event-list" {...restProps}>
      {tasks.map((task) => (
        <EventWrapper clickable onClick={() => onClickTask(task.link)}>
          <Box display="flex" alignItems="center" mb="0.5rem">
            <EventIconWrapper>
              <Icon size="1em" pt="2px" color="var(--text-soft-mute)"><ClipboardIcon /></Icon>
            </EventIconWrapper>
            <Box>
              <Text fontSize="0.813rem" lineHeight="1.5">{task.description}</Text>
            </Box>
          </Box>
        </EventWrapper>
      ))}
      {tasks.length === 0 && (
        <BaseEmptyState
          title="Nothing to See Here"
          message="Important tasks will appear here."
          illustration="activities"
          size="small"
        />
      )}
    </StyledEventListWrapper>
  );
}

TaskList.propTypes = {
  tasks: PropTypes.array.isRequired,
};

export default TaskList;
