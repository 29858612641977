import { useMemo } from 'preact/hooks';
import styled from 'styled-components';
import { space } from 'styled-system';

export const PopoverMenu = styled.div(({
  transform,
  width,
  padding,
  top,
  right,
}) => {
  const popoverWidth = useMemo(() => (width || '10em'), [width]);

  return `
  background: var(--tool-tip-background);
  z-index: 500;
  position: absolute;
  border: 1px solid  var(--border);
  box-shadow: 2px 4px 11px var(--tool-tip-shadow);
  border-radius: 0.5em;
  padding: ${padding || '1em'};;
  transform: ${transform};
  width: ${popoverWidth};
  top: ${top};
  right: ${right};
  `;
}, space);

export const MenuItem = styled.div`
    border-bottom: 1px solid var(--border);
    color: var(--text-soft-mute);
    font-size: 0.75rem;
    font-weight: 500;
    width: 100%;
    padding-bottom: 1em;
    margin-bottom: 1.125em;
    cursor: pointer;
    ${space};
`;
