import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  space, typography, variant, color,
} from 'styled-system';

const Text = styled.p(
  ({
    truncate,
    maxWidth,
    cursor,
  }) => `
    font-size: 14px;
    line-height: 1.7;
    font-weight: 400;
    margin: 0px;
    ${cursor && 'cursor: pointer'};
    ${truncate && 'white-space: nowrap'};
    ${truncate && 'overflow: hidden'};
    ${truncate && 'text-overflow: ellipsis'};
    ${maxWidth && `max-width:${maxWidth}`};

    ${space}
    ${typography}
    ${color}
`,
  variant({
    variants: {
      default: {
        color: 'var(--text-soft-mute)',
      },
      muted: {
        color: 'var(--text-muted)',
      },
      mediumMute: {
        color: 'var(--text-medium-mute)',
      },
      thinPrompt: {
        color: 'var(--text-soft-mute)',
        fontFamily: 'var(--font-secondary)',
        fontSize: '16px',
        fontWeight: '300',
        lineHeight: 1.8,
      },
    },
  }),
  space,
  typography,
  color,
);

Text.propTypes = {
  variant: PropTypes.oneOf(['default', 'muted', 'thinPrompt', 'mediumMute']),
};

Text.defaultProps = {
  variant: 'default',
};

export default Text;
