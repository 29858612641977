/* eslint-disable import/prefer-default-export */
import { adminMetricsUrl } from '../urls';

export async function getAdminMetrics() {
  const response = await fetch(adminMetricsUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return response;
}
