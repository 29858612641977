/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import {
  useState, useEffect, useRef, useCallback, useLayoutEffect,
} from 'preact/hooks';
import { Fragment } from 'preact';
import PropTypes from 'prop-types';
import { route } from 'preact-router';
import AppState from '@state';

import loadOrganization from '@actions/loadOrganization';
import { getOrgCompositions } from '@api/restricted/org-compositions-api';

import useIsoDateFormat from '@hooks/useIsoDateFormat';
// import useWindowDimensions from '@hooks/useWindowDimensions';
import useErrorOverlay from '@hooks/useErrorOverlay';
import useDebounce from '@hooks/useDebounce';
import useTruncateText from '@hooks/useTruncateText';

import CorePublisherLayout from '@layouts/corePublisher';
import PageCard from '@layouts/pageCard';

import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import CompositionIcon from '@assets/illustrations/composition-chip.svg';
import TableControlsAdvanced from '@ui-kit/table/tableControlsAdvanced';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';

import { TableIllustrationWrapper, TableSearchEmptyPrompt } from './PublisherCompositionStyles';

function PublisherCompositions({ uuid }) {
  const [isLoadingPage, setIsLoadingPage] = useState(true); // Initial component page loading
  const [isLoadingTable, setIsLoadingTable] = useState(true); // Table loading - TODO: Use this flag somehow
  const [totalCount, setTotalCount] = useState(0);
  const [compositions, setCompositions] = useState([]);

  // AppState shortcuts
  const tableState = AppState.publisherCompositionsTable.value;
  const currentPage = AppState.publisherCompositionsTable.currentPage.value;
  const itemsPerPage = AppState.publisherCompositionsTable.itemsPerPage.value;
  const activeConfig = AppState.publisherCompositionsTable.activeConfig.value;
  const defaultSearchValue = AppState.publisherCompositionsTable.activeConfig.query.value || '';

  const isFilteringResults = !!activeConfig.query || activeConfig.toggle !== 'all' || Object.values(activeConfig.filters).some((f) => f.length > 0);

  // const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  const d = useIsoDateFormat;

  const loadCompositions = async (state) => {
    setIsLoadingTable(true);
    try {
      const response = await getOrgCompositions(AppState.pubOrganization.id.value, state);
      if (response.status !== 200) {
        try {
          const json = await response.json();
          useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading compositions` });
        } catch {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading compositions` });
        }
      } else {
        const json = await response.json();
        setTotalCount(json.count);
        const formattedComps = json.compositions.map((c) => ({
          id: c.id,
          uuid: c.uuid,
          compositionName: c.name,
          writtenDate: d(c.written),
          createdAt: d(c.created),
          updatedAt: d(c.updated),
          writerCount: c.numWriters,
          controlledWriters: c.controlledWriters.sort(),
          uncontrolledWriterCount: c.numWriters - c.controlledWriters.length,
          finalized: c.finalized ? d(c.finalized) : null,
          status: c.status,
        }));
        setCompositions(formattedComps);
        setIsLoadingTable(false);
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading compositions` });
    }
  };

  useEffect(() => {
    document.title = 'Compositions';
    loadOrganization(uuid, { forcedLoad: false }).then(() => {
      loadCompositions(tableState).then(() => {
        setIsLoadingPage(false);
      });
    });
  }, []);

  useLayoutEffect(() => {
    if (uuid && AppState.publisherCompositionsTable.headerSearchListener.value > 0) {
      loadCompositions(tableState);
    }
  }, [AppState.publisherCompositionsTable.headerSearchListener.value]);

  const onClickComposition = (compUuid) => {
    AppState.navigation.backActionPath.value = `/publisher/${uuid}/compositions`;
    route(`/publisher/${uuid}/compositions/${compUuid}`);
  };

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;

  const paginate = (pageNumber) => {
    if (pageNumber < 1) {
      return;
    }
    const newTableState = {
      ...tableState,
      currentPage: pageNumber,
    };
    AppState.publisherCompositionsTable.value = newTableState;
    loadCompositions(newTableState);
    // pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
  };

  const handleControlItemsPerPage = (numItems) => {
    if (numItems < 5) {
      return;
    }
    const newTableState = {
      ...tableState,
      currentPage: 1,
      itemsPerPage: numItems,
    };
    AppState.publisherCompositionsTable.value = newTableState;
    loadCompositions(newTableState).then(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    });
  };

  const onSearchResults = (query) => {
    const lowerQuery = query?.toLocaleLowerCase() || '';
    const newTableState = {
      ...tableState,
      currentPage: 1,
      activeConfig: {
        ...activeConfig,
        query: lowerQuery,
      },
    };
    AppState.publisherCompositionsTable.value = newTableState;
    loadCompositions(newTableState);
  };

  const optimizedSearch = useCallback(useDebounce(onSearchResults), [onSearchResults]);

  const handleTableControlsSort = (sortOption) => {
    if (!sortConfig.options.map((o) => o.value).includes(sortOption)) {
      return;
    }
    const newTableState = {
      ...tableState,
      currentPage: 1,
      activeConfig: {
        ...activeConfig,
        sort: sortOption,
      },
    };
    AppState.publisherCompositionsTable.value = newTableState;
    loadCompositions(newTableState);
  };

  const handleTableControlSortOrder = () => {
    const newTableState = {
      ...tableState,
      currentPage: 1,
      activeConfig: {
        ...activeConfig,
        isAscending: !activeConfig.isAscending,
      },
    };
    AppState.publisherCompositionsTable.value = newTableState;
    loadCompositions(newTableState);
  };

  const handleTableControlsToggle = (value) => {
    if (!toggleConfig.options.map((o) => o.value).includes(value)) {
      return;
    }
    const newTableState = {
      ...tableState,
      currentPage: 1,
      activeConfig: {
        ...activeConfig,
        toggle: value,
      },
    };
    AppState.publisherCompositionsTable.value = newTableState;
    loadCompositions(newTableState);
  };

  const handleTableControlsFilter = (filters) => {
    const newTableState = {
      ...tableState,
      currentPage: 1,
      activeConfig: {
        ...activeConfig,
        filters,
      },
    };
    AppState.publisherCompositionsTable.value = newTableState;
    loadCompositions(newTableState);
  };

  const handleTableControlsResetFilters = () => {
    const newTableState = {
      ...tableState,
      currentPage: 1,
      activeConfig: {
        ...activeConfig,
        filters: {
          status: [],
          internalTags: [],
          compositionTags: [],
          connectedPublishers: [],
        },
      },
    };
    AppState.publisherCompositionsTable.value = newTableState;
    loadCompositions(newTableState);
  };

  // NEW ADVANCE TABLE CONFIG ITEMS THAT DON'T NEED STATE
  const sortConfig = {
    options: [{ name: 'Created Date', value: 'created' }, { name: 'Date Written', value: 'written' }, { name: 'Finalized Date', value: 'finalized' }],
    handleToggle: handleTableControlsSort,
    handleSortOrder: handleTableControlSortOrder,
  };
  const toggleConfig = {
    options: [{ name: 'All', value: 'all' }, { name: 'Finalized', value: true }, { name: 'In Creation', value: false }],
    handleToggle: handleTableControlsToggle,
  };
  const filterConfig = {
    options: ['internalStatus', 'internalTags'],
    modalHeader: 'Filter Compositions',
    handleFilters: handleTableControlsFilter,
    handleResetFilters: handleTableControlsResetFilters,
  };

  return (
    <CorePublisherLayout>
      <PageCard
        title="Compositions"
        isLoadingPage={isLoadingPage}
      >
        <div ref={pageRef} />
        {totalCount === 0 && !isFilteringResults && !isLoadingTable ? (
          <Card variant="panel" mt="1.5em">
            <BaseEmptyState
              title="No Compositions"
              message="Your writers have not created any compositions attributed to you"
              illustration="composition"
              minHeight="38em"
            />
          </Card>
        ) : (
          <Fragment>
            <TableControlsAdvanced
              handleSearch={optimizedSearch}
              filterConfig={filterConfig}
              sortConfig={sortConfig}
              toggleConfig={toggleConfig}
              activeConfig={activeConfig}
              initialSearchValue={defaultSearchValue}
              viewingLabel={
                totalCount > itemsPerPage ? `Viewing ${(indexOfFirst + 1).toLocaleString()} - ${(indexOfLast).toLocaleString()} of ${totalCount.toLocaleString()} Compositions`
                  : `Viewing ${totalCount.toLocaleString()} Composition${totalCount > 1 ? 's' : ''}`
              }
            />
            <Table mb="1em" scrollContents>
              <TableHeader sticky>
                <TableCell widthPercent="48%" pr="0.75em">Composition</TableCell>
                <TableCell>Written On</TableCell>
                <TableCell>Writers</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Finalized</TableCell>
                <TableCell align="right" pr="1em">Internal Status</TableCell>
              </TableHeader>
              {compositions.map((composition) => (
                <TableRow onClick={() => onClickComposition(composition.uuid)} cursor>
                  <TableCell widthPercent="48%" pr="0.75em">
                    <TableIllustrationWrapper>
                      <Icon className="icon" size="3.25em" pt="2px" mr="1em"><CompositionIcon /></Icon>
                      <Box pt="0.25rem">
                        <Text fontSize="0.938rem" color="var(--text-primary)" fontWeight="600" lineHeight="1.2">
                          {useTruncateText(composition.compositionName, { sm: 30, md: 20, lg: 55 })}
                        </Text>
                        <Text mt="0.125em" fontSize="0.75rem" maxWidth="24em" truncate color="var(--text-secondary)">
                          {/* TODO: Slice controlledWriters in case there are too many */}
                          {composition.controlledWriters.join(', ')}
                          {composition.uncontrolledWriterCount > 0
                          && ` and ${composition.uncontrolledWriterCount} other${composition.uncontrolledWriterCount > 1 ? 's' : ''}`}
                        </Text>
                      </Box>
                    </TableIllustrationWrapper>
                  </TableCell>
                  <TableCell mobileLabel="Date Written:">{composition.writtenDate}</TableCell>
                  <TableCell mobileLabel="Writers:">{composition.writerCount}</TableCell>
                  <TableCell mobileLabel="Created:">{composition.createdAt}</TableCell>
                  <TableCell mobileLabel="Finalized:" color={composition.finalized ? 'var(--green-200)' : 'var(--text-medium-mute)'}>
                    {composition.finalized ? (
                      <Box>
                        <span style={{ fontWeight: '500' }}>{composition.writerCount > 1 ? 'Split Sheet Executed' : 'Single Writer Attested'}</span>
                        <Text mt="0.125em" fontSize="0.75rem" maxWidth="24em" truncate opacity="0.8">
                          {composition.finalized}
                        </Text>
                      </Box>
                    ) : 'Not Finalized'}
                  </TableCell>
                  <TableCell
                    align="right"
                    pr="1em"
                    mobileLabel="Status:"
                    color={composition.status ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}
                  >
                    {composition.status || 'None'}
                  </TableCell>
                </TableRow>
              ))}
              {compositions.length === 0
              && (
                <TableSearchEmptyPrompt>
                  <Text color="var(--text-muted-panel)">No Compositions Match Your Search</Text>
                </TableSearchEmptyPrompt>
              )}
            </Table>
            <TablePagination
              handleControlItemsPerPage={handleControlItemsPerPage}
              itemsPerPage={itemsPerPage}
              totalItems={totalCount}
              paginate={paginate}
              currentPage={currentPage}
              marginBottom="0em"
            />
          </Fragment>
        )}

      </PageCard>
    </CorePublisherLayout>
  );
}

PublisherCompositions.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PublisherCompositions;
