import {
  StyledVerifiedBadge,
} from './VerifiedBadgeStyles';

function VerifiedBadge({
  ...restProps
}) {
  return (
    <StyledVerifiedBadge
      {...restProps}
    >
      Verified
    </StyledVerifiedBadge>
  );
}

export default VerifiedBadge;
