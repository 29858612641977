import { adminMockDataUrl } from '../urls';

export async function postMockData(orgId, count) {
  const data = {
    id: orgId,
    count,
  };
  const response = await fetch(adminMockDataUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteMockData() {
  const response = await fetch(adminMockDataUrl, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return response;
}
