import { Fragment } from 'preact';
import { route } from 'preact-router';
import AppState from '@state';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import BaseButton from '@ui-kit/buttons/baseButton';
import Text from '@ui-kit/typography/text';

function ActivateSuccess() {
  const onClickContinue = () => {
    route('/representation');
    AppState.navigation.backActionPath.value = '/representation';
  };
  return (
    <Fragment>
      <ConversationPrompt
        primaryMessage="Representative Activated"
        promptLabel="Access Granted"
        labelColor="var(--green-400)"
        mb="1em"
      />
      <Text mb="2em" fontSize="1rem">We sent an email to your representative letting them know that you approved their access.</Text>
      <BaseButton
        mt="2em"
        btnText="Go to Representation"
        fluid
        onClick={onClickContinue}
      />
    </Fragment>
  );
}

export default ActivateSuccess;
