import styled from 'styled-components';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Card from '@distinct-components/cards/baseCard';
import pointBreaks from '@styles/styleConstants';
import {
  space,
  variant,
} from 'styled-system';

export const WelcomeWrapper = styled(Box)`
   padding: 2.75em 0em 2em 0em;

   @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 2em 0em 1.75em 0em;
    }

    @media screen and (min-width: ${pointBreaks.lg}) {
        padding: 2.875em 1em 1em 1em;
    }
`;

export const StyledWelcomeHeader = styled(Text)`
    font-size: 1.5rem;
    font-weight: 800;
    font-family: var(--font-secondary);
    color: var(--text-primary);

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1.25rem;
    }
`;

export const StyledWelcomeMessage = styled(Text)`
    font-size: 1.125rem;
    font-weight: 300;
    font-family: var(--font-secondary);

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1rem;
    }
`;

export const StyledWelcomeBanner = styled.div`
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem 0.875rem;
    align-items: center;
    margin: 0rem;
    border-radius: 0.5rem;
    background: var(--action-blue);
    margin-bottom: 1.5rem;
    cursor: pointer;
    ${space}

     @media screen and (min-width: ${pointBreaks.lg}) {
        margin: 0rem 1rem;
        margin-bottom: 1.5rem;
    }
`;

export const StyledWelcomeBannerCopyWrapper = styled.div`
    display: flex;
    gap: 0.5rem;

    @media screen and (max-width: ${pointBreaks.md}) {
        flex-direction: column;
        gap: 0rem;
        margin-left: 0.35rem;

        p:first-child {
            line-height: 1.4;
            padding-top: 2px;
        }
    }
`;

export const HomeCardGrid = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.5em;
    ${space}
    
    .primary {
        flex-basis: 65%;
        transform: translateY(16px);
        opacity: 0;
    }

    .secondary {
        flex-basis: 35%;
        transform: translateY(16px);
        opacity: 0;
    }

    @media screen and (max-width: ${pointBreaks.md}) {
        flex-direction: column;

        .primary {
            flex-basis: 100%;
        }
    
        .secondary {
            flex-basis: 100%;
        }
    }

    @media screen and (min-width: ${pointBreaks.lg}) {
        gap: 2.5em;
        padding: 1em;
    }

`;

export const StyledHomeCard = styled(Card)`
    padding: 0em;
    margin-bottom: 1.5em;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: ${pointBreaks.lg}) {
        margin-bottom: 2.5em;
    }
`;

export const HomeCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--panel-divider);
    margin-left: 1.5em;
    padding: 0.75em 0.75em 0.75em 0em;
    ${space}
`;

export const HomeCardContent = styled.div`
    padding: 0.75em 1em 0.75em 1.5em;
    ${space}
`;

export const HomeCardItem = styled.div`
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    border-bottom: 1px solid var(--ghost);
    padding: 0.5em 0.5em 0.25em 0em;
    transition: all 400ms ease-in-out;
    ${space}
`;

export const StyledCardLabel = styled(Text)`
    font-size: 0.875rem;
    font-weight: 600;
`;

export const PromptLabel = styled(Text)`
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.75em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 0.875rem;
      }
`;

export const PromptMessage = styled(Text)`
    font-size: 0.875rem;
    font-weight: 300;
`;

export const PromptBar = styled(Text)`
    border-bottom: 4px solid var(--active-nav);
    max-width: 12em;
    margin-bottom: 1em;
    ${variant({
    variants: {
      warning: {
        borderColor: 'var(--orange-300)',
      },
      verification: {
        borderColor: 'var(--trust)',
      },
    },
  })}`;
