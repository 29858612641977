import { useEffect, useState } from 'preact/hooks';
import AppState from '@state';

import loadAccountProfile from '@actions/loadAccountProfile';

import PageCard from '@layouts/pageCard';
import CoreLayout from '@layouts/core/';

import Tabs from '@ui-kit/tabs/Tabs';
import BreakLine from '@ui-kit/dividers/breakLine';

import ProfileAccount from './sections/profileAccount';
import ProfilePrimaryMode from './modes/profilePrimaryMode';
import ProfileAliasMode from './modes/profileAliasMode';
import ProfileVerifiedDataMode from './modes/profileVerifiedDataMode';

function ArtistIdentityProfile() {
  const [selectedTab, setSelectedTab] = useState('Primary');
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);

  useEffect(() => {
    document.title = 'Profile';
    if (!AppState.profileLoaded.value) {
      loadAccountProfile().then(() => {
        setIsLoadingProfile(false);
      });
    } else {
      setIsLoadingProfile(false);
    }
  }, []);

  const onProfileTabChange = (tab) => {
    setSelectedTab(tab.value);
  };

  return (
    <CoreLayout>
      <PageCard
        title="My Identity"
        isLoadingPage={isLoadingProfile}
        pageCardId={AppState.artistProfile.did.value}
        pageCardIdLink={`/identity-viewer/artist/${AppState.artistProfile.uuid.value}`}
      >
        <ProfileAccount />
        <Tabs
          tabs={AppState.verified.value ? [{ name: 'Writer', value: 'Primary' }, { name: 'Personas', value: 'Personas' }, { name: 'Verified Data', value: 'Verifications' }] : [{ name: 'Writer', value: 'Primary' }, { name: 'Personas', value: 'Personas' }]}
          handleTabChange={onProfileTabChange}
          selectedTab={selectedTab}
        />
        <BreakLine mb="1.5em" mt="1em" color="var(--border)" />
        {
          {
            Primary: <ProfilePrimaryMode />,
            Personas: <ProfileAliasMode />,
            Verifications: <ProfileVerifiedDataMode />,
          }[selectedTab]
        }
      </PageCard>
    </CoreLayout>
  );
}

export default ArtistIdentityProfile;
