import styled from 'styled-components';
import Span from '@ui-kit/typography/span';
import pointBreaks from '@styles/styleConstants';
import Card from '@distinct-components/cards/baseCard';
import { space } from 'styled-system';

export const StyledHeaderCard = styled(Card)`
    margin-bottom: 1.5em;
    padding: 1rem;
    
    @media screen and (max-width: ${pointBreaks.sm}) {
        margin-bottom: 0.25em;
        padding: 1.5em 1em 1em 1em;
    }
`;

export const StyledLabel = styled(Span)`
    font-size: 0.813rem;
    font-weight: 600;
    white-space: nowrap;
`;

export const IconWrapper = styled.div`
    display: block;

    @media screen and (max-width: ${pointBreaks.sm}) {
        display: none;
    }
`;

export const DetailWrapper = styled.div`
    margin-left: 1.5em;
    margin-top: 0.5em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        margin-left: 0em;
        margin-top: 0em;
        padding-right: 0.5em;
    }
`;

export const StyledData = styled(Span)`
    font-size: 0.813rem;
`;

export const StyledTag = styled.div`
    background: var(--tag-valid); 
    margin-right: 0.938em;
    border-radius: 0.25em;
    padding: 0.5em 0.75em;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--off-white);
    text-transform: capitalize;
    margin-top: 0.5em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        margin-right: 0em;
    }
`;

export const StyledStatusBadge = styled.div(({
  notFinalized,
}) => `
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0em 1em;
    min-width: 9.25em;
    min-height: 2.5em;
    font-size: 0.75rem;
    background: ${notFinalized ? 'var(--inactive)' : 'var(--green-200)'};
    color: ${notFinalized ? 'var(--text-secondary)' : 'var(--white)'};
    font-weight: 500;
    border-radius: 0.5em;
    white-space: nowrap;
    align-self: flex-start;
    ${space}
  `);
