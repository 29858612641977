import { workspaceContractDownloadUrl } from '../urls';
import refresh from '../refresh';

export default async function postWorkspaceContractDownload(workspaceId, version) {
  const body = {
    workspace: workspaceId,
    version,
  };
  return refresh(async () => {
    const response = await fetch(`${workspaceContractDownloadUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(body),
    });
    return response;
  });
}
