import { orgRegistrationUrl } from '../urls';
import refresh from '../refresh';

export async function getOrgRegistrations(orgId) {
  return refresh(async () => {
    const response = await fetch(`${orgRegistrationUrl}?${new URLSearchParams({
      org: orgId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function postOrgRegistration(orgId, name, society, ipi, address, contactEmail) {
  const data = {
    org: orgId,
    name,
    society,
    ipi,
    address,
    contactEmail,
  };
  return refresh(async () => {
    const response = await fetch(orgRegistrationUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function patchOrgRegistration(regId, name, society, ipi, address, contactEmail) {
  const data = {
    reg: regId,
    name,
    society,
    ipi,
    address,
    contactEmail,
  };
  return refresh(async () => {
    const response = await fetch(orgRegistrationUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteOrgRegistration(regId) {
  const data = {
    reg: regId,
  };
  return refresh(async () => {
    const response = await fetch(orgRegistrationUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
