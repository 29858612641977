import styled from 'styled-components';
import { useMemo } from 'preact/hooks';
import {
  space, variant, size,
} from 'styled-system';

export const StyledButtonSegment = styled.button(
  ({
    fluid,
    width,
    maxHeight,
    active,
  }) => {
    const btnWidth = useMemo(() => (fluid ? '100%' : width), [fluid]);

    return `
  background: ${active ? 'var(--active-nav)' : 'var(--pill-default)'};
  width: ${btnWidth};
  color: ${active ? 'var(--white)' : 'var(--text-soft-mute)'};
  font-size: 0.813rem;
  font-weight: 600;
  min-height: 2.75em;
  max-height: ${maxHeight || '2.75em'};
  display: flex;
  border-radius: 0em;
  align-items: center;
  justify-content: center;
  touch-action: manipulation;
  ${space}
  ${size}

  &:disabled {
    cursor: not-allowed;
  }
  `;
  },
  variant({
    variants: {
      first: {
        borderRadius: '0.5em 0em 0em 0.5em',
      },
      last: {
        borderRadius: '0em 0.5em 0.5em 0em',
      },
      solo: {
        borderRadius: '0.5em',
        whiteSpace: 'nowrap',
      },
    },
  }),
  space,
  size,
);

export const StyledSubText = styled.div(
  ({
    active,
  }) => `
  color: ${active ? 'var(--white)' : 'var(--text-soft-mute)'};
  font-size: 0.688rem;
  font-weight: 300;
  margin-top: 0.25em;
  ${space}
  `,
);
