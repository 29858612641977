import { workspaceContractAmendmentUrl } from '../urls';
import refresh from '../refresh';

export async function postWorkspaceContractAmendment(workspaceId, reason) {
  const data = {
    workspace: workspaceId,
    reason,
  };
  return refresh(async () => {
    const response = await fetch(workspaceContractAmendmentUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteWorkspaceContractAmendment(workspaceId) {
  const data = {
    workspace: workspaceId,
  };
  return refresh(async () => {
    const response = await fetch(workspaceContractAmendmentUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
