/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'preact/hooks';
import useErrorOverlay from '@hooks/useErrorOverlay';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import Icon from '@ui-kit/icon';
import ClearIcon from '@assets/icons/close-solo.svg';

import { getTags } from '@api/restricted/tags-api';

import {
  ResultsWrapper,
  ResultRow,
  Result,
  StyledTagsWrapper,
  StyledTagInput,
  InputTag,
} from './TagTypeAheadStyles';

function TagTypeAhead({
  handleChange,
  selectedTags,
}) {
  const [searchValue, setSearchValue] = useState('');
  const [isLoadingTags, setIsLoadingTags] = useState(true);
  const [tagSet, setTagSet] = useState([]);
  const [selectedItems, setSelectedItems] = useState([...selectedTags]);
  const [filterTagIds, setFilterTagIds] = useState([]);
  const [focusedNumber, setFocusedNumber] = useState(-1);
  const [focusedItem, setFocusedItem] = useState();
  const [results, setResults] = useState();

  const loadTags = async () => {
    try {
      const response = await getTags();
      if (response.status !== 200) {
        try {
          const json = await response.json();
          useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading tags` });
        } catch {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading tags` });
        }
      } else {
        const json = await response.json();
        setTagSet(json.tags);
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading tags` });
    }
  };

  useEffect(() => {
    if (selectedTags.length > 0) {
      const existingTagIds = [];
      selectedItems.forEach((t) => {
        existingTagIds.push(t.id);
      });
      setFilterTagIds(existingTagIds);
    }
    loadTags().then(() => setIsLoadingTags(false));
  }, []);

  const onSelectItem = (t) => {
    const newTagSet = [...selectedItems, t];
    setSelectedItems(newTagSet);
    setFilterTagIds(() => [...filterTagIds, t.id]);
    handleChange(newTagSet);
    setSearchValue('');
  };

  const onClickRemoveTag = (t) => {
    const reducedSet = selectedItems.filter((a) => a.id !== t.id);
    const reducedFilter = filterTagIds.filter((v) => v !== t.id);
    setSelectedItems(reducedSet);
    handleChange(reducedSet);
    setFilterTagIds(reducedFilter);
  };

  const onSearch = (event) => {
    const query = event.target.value;
    setSearchValue(query);
    setFocusedNumber(-1);
    setFocusedItem(null);
    if (query) {
      const lowerQuery = query.toLowerCase();
      const availableTags = tagSet.filter((t) => !filterTagIds.includes(t.id));
      const resultsToShow = availableTags.filter((t) => t.name.includes(lowerQuery));
      setResults(resultsToShow.sort((a, b) => a.name.length - b.name.length).sort((a, b) => b.name.startsWith(lowerQuery) - a.name.startsWith(lowerQuery)));
    } else {
      setResults(null);
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 40 && focusedNumber + 1 < results.length) {
      const focused = results[focusedNumber + 1];
      setFocusedNumber(focusedNumber + 1);
      setFocusedItem(focused);
      return;
    }
    if (event.keyCode === 38 && focusedNumber - 1 >= 0) {
      const focused = results[focusedNumber - 1];
      setFocusedNumber(focusedNumber - 1);
      setFocusedItem(focused);
      return;
    }
    if (event.keyCode === 13) {
      event.preventDefault();
      if (focusedItem) {
        const tag = focusedItem;
        const newTagSet = [...selectedItems, tag];
        setSelectedItems(newTagSet);
        setFilterTagIds(() => [...filterTagIds, tag.id]);
        handleChange(newTagSet);
        setSearchValue('');
        setFocusedItem(null);
      }
    }
  };

  return (
    <Box style={{ position: 'relative' }}>
      <InputLabel label="How it sounds or may sound in the future (optional)" />
      <StyledTagsWrapper mb="0.45em">
        {selectedItems && selectedItems.map((t) => (
          <InputTag>
            {t.name}
            <Icon ml="0.5em" cursor onClick={() => onClickRemoveTag(t)} size="0.5em"><ClearIcon /></Icon>
          </InputTag>
        ))}
        {!isLoadingTags && selectedItems.length < 5
        && (
        <StyledTagInput
          placeholder={selectedItems.length === 0 ? 'Genre or vibe...' : 'Tag...'}
          value={searchValue}
          autoComplete="off"
          onKeyDown={onKeyDown}
          onChange={onSearch}
          onBlur={() => setSearchValue('')}
          style={selectedItems.length === 0 ? { paddingLeft: '0.5em' } : { paddingLeft: '0.125em' }}
        />
        )}
        {isLoadingTags && <Text color="var(--text-placeholder)" pl="0.5em" pt="0.125em" lineHeight="1.7">Loading Tags...</Text>}
      </StyledTagsWrapper>
      <InputLabel mb="1.75em" color="var(--text-medium-mute)" label={selectedItems && selectedItems.length >= 5 ? 'Limit of 5 Reached' : 'Tag Limit: 5'} />

      {searchValue.length > 0
      && (
      <ResultsWrapper>
        {results && results.map((t) => (
          <ResultRow onClick={() => onSelectItem(t)} onMouseDown={(e) => e.preventDefault()} style={t.id === focusedItem?.id && { background: 'var(--input-menu-highlight)' }}>
            <Result>
              {t.name}
            </Result>
          </ResultRow>
        ))}
        {results && results.length === 0
          && <Text p="1em" color="var(--text-muted-panel)">No Results</Text>}
      </ResultsWrapper>
      )}
    </Box>
  );
}

TagTypeAhead.propTypes = {
  handleChange: PropTypes.func.isRequired,
  selectedTags: PropTypes.array,
};

TagTypeAhead.defaultProps = {
  selectedTags: null,
};

export default TagTypeAhead;
