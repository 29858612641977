import PropTypes from 'prop-types';

import HeaderCard from '@distinct-components/cards/headerCard';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import CompositionIcon from '@assets/icons/composition-note.svg';

function ViewCompositionSamples({ composition }) {
  return (
    <HeaderCard mb="1.5em" headerText="Interpolation / Samples">
      {composition.hasSamples ? (
        <Box>
          {composition.samples.map((sample) => (
            <Box mb="0.875rem">
              <Box display="flex" alignItems="center">
                <Icon
                  pt="2px"
                  mr="0.75em"
                  size="0.875rem"
                  color="var(--text-secondary)"
                >
                  <CompositionIcon />
                </Icon>
                <Box>
                  <Text fontWeight="500" lineHeight="1">{sample.name}</Text>
                </Box>
              </Box>
              <Box mt="0.25rem">
                <Text mb="0.25rem" fontSize="0.813rem" color="var(--text-secondary)" fontWeight="400">
                  ISWC:
                  {' '}
                  {sample.iswc ? sample.iswc : 'Not Provided'}
                </Text>
                <Text mb="0.25rem" fontSize="0.813rem" color="var(--text-secondary)" fontWeight="400">
                  Writer Info:
                  {' '}
                  {sample.writers ? sample.writers.join(', ') : 'Not Provided'}
                </Text>
                <Text mb="0.25rem" fontSize="0.813rem" color="var(--text-secondary)" fontWeight="400">
                  Performer Info:
                  {' '}
                  {sample.performers ? sample.performers : 'Not Provided'}
                </Text>
                <Text mb="0.25rem" fontSize="0.813rem" color="var(--text-secondary)" fontWeight="400">
                  Description of Use:
                  {' '}
                  {sample.description ? sample.description : 'Not Provided'}
                </Text>
              </Box>

            </Box>
          ))}
        </Box>
      ) : (
        <Text color="var(--text-medium-mute)">No Sampled Works Referenced</Text>
      )}
    </HeaderCard>
  );
}

ViewCompositionSamples.propTypes = {
  composition: PropTypes.object.isRequired,
};

export default ViewCompositionSamples;
