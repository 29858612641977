import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import Box from '../../ui-kit/box/Box';

export const AuthContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 2em;
  display: flex;
  background: var(--background);
  touch-action: none;
`;

export const AuthBrandPanel = styled.div`
  width: 800px;
  height: 100%;
  background: var(--auth-brand-panel);
  background-image: linear-gradient(rgb(110 21 204 / 67%),hsl(266deg 97% 24% / 73%)),url(assets/illustrations/make-it-rain.svg);
  padding: 3em;
  border-radius: 1em;
  position: relative;

  svg {
    opacity: 0.9;
  }

  @media screen and (max-width: ${pointBreaks.sm}) {
    display: none;
  }
`;

export const AuthSteamrollerMark = styled.div`
color: white;
    bottom: 14px;
    font-size: 15px;
    opacity: 0.6;
    margin-top: 12px;
    font-weight: 600;
    position: absolute;
`;

export const AuthInfoBar = styled.div`
  background: #447a98;
  color: white;
  width: 100%;
  padding: 0.75em 1em;
  margin-bottom: 1em;
  border-radius: 0.5em;
  font-size: 0.813rem;
  font-weight: 400;

  @media screen and (max-width: ${pointBreaks.sm}) {
    position: absolute;
    top: 88px;
    width: calc(100% - 64px);
    line-height: 1.5
  }
`;

export const AuthBrandTitle = styled.div`
  margin-top: 1.75em;
  font-size: 4.5rem;
  font-weight: 800;
  font-family: var(--font-secondary);
  color: #9451dbcf;

  @media screen and (max-width: ${pointBreaks.lg}) {
    font-size: 4rem;
  }
`;

export const AuthBrandMessage = styled.div`
  font-size: 1.125rem;
  color: #be9ee3;
  line-height: 1.85;
  font-weight: 300;
  font-family: var(--font-secondary);
  margin-top: 1em;
  width: 90%;
`;

export const AuthMobileBrand = styled(Box)`
  display: none;
  @media screen and (max-width: ${pointBreaks.sm}) {
    display: block;
  }
`;

export const PromptWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media screen and (max-width: ${pointBreaks.sm}) {
    font-size: 0.875rem;
    justify-content: space-between;
  }
`;

export const StyledPrompt = styled.div`
  display: block;

  @media screen and (max-width: ${pointBreaks.sm}) {
    display: none;
  }
`;

export const AuthContentWrapper = styled.div`
  width: 100%;
  margin: 0em 0em 0em 3em;

  @media screen and (max-width: ${pointBreaks.sm}) {
    margin: 0;
  }
`;

// ADJUST HEIGHT
export const AuthContent = styled.div`
  display: flex;
  height: calc(100% - 42px); 
  justify-content: center;
  align-items: center;
`;

export const AuthFormWrapper = styled.div`
  width: 20rem;
  transform: translateY(-4em);

  @media screen and (max-height: 40em) {
    transform: translateY(0em);
  }

  @media screen and (max-width: ${pointBreaks.sm}) {
    transform: translateY(-2em);
  }
`;
