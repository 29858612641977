import styled from 'styled-components';
import Box from '@ui-kit/box';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';

export const ResultsWrapper = styled(Box)`
    background: var(--card);
    position: absolute;
    border: 1px solid var(--border);
    box-shadow: 4px 8px 11px var(--base-shadow);
    transform: translateY(-1.25em);
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    width: 100%;
    z-index: 500;
    max-height: 15em;
    overflow-y: scroll;
`;

export const ResultRow = styled.div`
    display: flex;
    padding: 1em;
    align-items: center;
    cursor: pointer;
    &:last-child {
        margin-bottom: 0em;
    }
`;

export const Result = styled.p`
    font-size: 0.875em;
    margin: 0em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-soft-mute);
`;

export const StyledBaseInput = styled(BaseInput)`
   
    &:disabled {
        background: var(--input-bg) !important;
        color: var(--text-input) !important;
        opacity: 1;
        -webkit-text-fill-color: var(--text-input);
        opacity: 1;
    }
`;

export const SelectedWrapper = styled.div`
    position: relative;
`;

export const ClearSearchChip = styled.div`
    position: absolute;
    height: 24px;
    width: 24px;
    background: var(--input-bg-panel);
    border-radius: 50px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.9;
`;
