import { workspaceAccountUrl } from '../urls';
import refresh from '../refresh';

export async function getWorkspaceAccounts(workspaceId) {
  return refresh(async () => {
    const response = await fetch(`${workspaceAccountUrl}?${new URLSearchParams({
      workspace: workspaceId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function postWorkspaceAccount(workspaceId, accountToAdd, firstName, lastName, permission, metadata, suggestions) {
  const data = {
    workspace: workspaceId,
    account: accountToAdd,
    firstName,
    lastName,
    permission,
    metadata,
    suggestions,
  };
  return refresh(async () => {
    const response = await fetch(workspaceAccountUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function patchWorkspaceAccountAlias(workspaceId, aliasId) {
  const data = {
    workspace: workspaceId,
    alias: aliasId,
  };
  return refresh(async () => {
    const response = await fetch(workspaceAccountUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function patchWorkspaceAccountPublisherPreset(workspaceId, presetId) {
  const data = {
    workspace: workspaceId,
    preset: presetId,
  };
  return refresh(async () => {
    const response = await fetch(workspaceAccountUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function putWorkspaceAccountPermissionsAndSuggestions(
  workspaceId,
  account,
  isAdmin,
  isWriter,
  canEdit,
  firstName,
  lastName,
  suggestions,
) {
  let permission = 'restricted';
  if (isAdmin) {
    permission = 'admin';
  } else if (canEdit) {
    permission = 'edit';
  }

  const data = {
    workspace: workspaceId,
    account,
    permission,
    firstName,
    lastName,
    suggestions,
  };
  return refresh(async () => {
    const response = await fetch(workspaceAccountUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function putWorkspaceAccountMetadata(workspaceId, account, metadata, hash) {
  const data = {
    workspace: workspaceId,
    account,
    metadata: { ...metadata, hash },
  };
  return refresh(async () => {
    const response = await fetch(workspaceAccountUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteWorkspaceAccount(workspaceId, account) {
  const data = {
    workspace: workspaceId,
    account,
  };
  return refresh(async () => {
    const response = await fetch(workspaceAccountUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
