import { artistRepsInviteActivateUrl } from '../urls';
import refresh from '../refresh';

export async function getArtistRepsInviteToActivate(inviteId) {
  const url = `${artistRepsInviteActivateUrl}?invite=${inviteId}`;
  return refresh(async () => {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function postArtistRepsInviteActivate(inviteId) {
  const data = {
    invite: inviteId,
  };
  return refresh(async () => {
    const response = await fetch(artistRepsInviteActivateUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteArtistRepsInviteActivate(inviteId) {
  const data = {
    invite: inviteId,
  };
  return refresh(async () => {
    const response = await fetch(artistRepsInviteActivateUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
