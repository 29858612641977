/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useRef } from 'preact/hooks';

import useClickOutside from '@hooks/useClickOutside';
import useKeyPressAction from '@hooks/useKeyPressAction';

import CloseSquare from '@assets/icons/close-square.svg';
import Icon from '@ui-kit/icon';

import {
  ModalPage,
  ModalContainer,
  ModalUtilityRow,
  ModalContentWrapper,
  ModalFormWrapper,
  ModalFormWrapperCentered,
} from './BaseEditOverlayStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function BaseEditOverlay({
  children,
  closeFunction,
  showCloseFunction,
  formWidth,
  formCentered,
}) {
  const ref = useRef();
  useClickOutside(ref, () => (closeFunction ? closeFunction() : {}));

  useKeyPressAction('Escape', () => (closeFunction ? closeFunction() : {}));

  const onClickCoseModal = () => (closeFunction ? closeFunction() : {});

  return (
    <ModalPage id="modal-page">
      <ModalContainer ref={ref}>
        <ModalUtilityRow>
          {closeFunction && showCloseFunction
          && <Icon size="1.5em" cursor onClick={onClickCoseModal}><CloseSquare /></Icon>}
        </ModalUtilityRow>
        <ModalContentWrapper id="baseModalContent" formCentered={formCentered}>
          {formCentered
            ? (
              <ModalFormWrapperCentered formWidth={formWidth}>
                {children}
              </ModalFormWrapperCentered>
            )
            : (
              <ModalFormWrapper formWidth={formWidth}>
                {children}
              </ModalFormWrapper>
            )}
        </ModalContentWrapper>
      </ModalContainer>
    </ModalPage>
  );
}

BaseEditOverlay.propTypes = {
  children: childrenPropTypes.isRequired,
  closeFunction: PropTypes.any,
  formWidth: PropTypes.string,
  formCentered: PropTypes.bool,
  showCloseFunction: PropTypes.bool,
};

BaseEditOverlay.defaultProps = {
  closeFunction: null,
  formWidth: null,
  formCentered: false,
  showCloseFunction: true,
};

export default BaseEditOverlay;
