import AppState from '@state';
import { getOrgRegistrations } from '@api/restricted/org-registration-api';
import useErrorOverlay from '@hooks/useErrorOverlay';

export default async function loadOrgRegistrations(orgId, { forcedLoad }) {
  if (forcedLoad || AppState.orgRegistrations.value.length === 0) {
    try {
      const response = await getOrgRegistrations(orgId);
      if (response.status !== 200) {
        try {
          const json = await response.json();
          useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading registrations` });
        } catch {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading registrations` });
        }
      } else {
        const json = await response.json();
        AppState.orgRegistrations.value = json.registrations;
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading registrations` });
    }
  }
}
