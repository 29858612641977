import PropTypes from 'prop-types';
import PlacesAutocomplete, {} from 'react-places-autocomplete';

import Icon from '@ui-kit/icon';
import LocationDot from '@assets/icons/location-dot.svg';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';

import {
  ResultsWrapper, ResultRow, Result, LoadingPrompt, AutoCompleteWrapper,
} from './AddressTypeAheadStyles';

function AddressTypeAhead({
  placesState,
  handleChange,
  address,
  label,
  id,
}) {
  return (
    <div>
      {placesState && placesState === 'ready'
        && (
        <PlacesAutocomplete
          debounce={400}
          value={address}
          onChange={handleChange}
          searchOptions={{ types: ['address'] }}
        >
          {({
            getInputProps, suggestions, getSuggestionItemProps, loading,
          }) => (
            <AutoCompleteWrapper>
              <InputLabel label={label} />
              <BaseInput
                {...getInputProps({
                  placeholder: 'Start typing address...',
                })}
                id={id}
                fluid
                mb="2.5em"
                autoComplete="off"
              />
              {suggestions.length > 0
                && (
                <ResultsWrapper className="autocomplete-dropdown-container">
                    {suggestions.map((suggestion) => (
                      <ResultRow {...getSuggestionItemProps(suggestion)}>
                        <Icon mr="0.75em" size="0.875em" color="var(--purple-300)"><LocationDot /></Icon>
                        <Result>{suggestion.description}</Result>
                      </ResultRow>
                    // </div>
                    ))}
                    {loading && <LoadingPrompt>loading suggestions...</LoadingPrompt>}
                </ResultsWrapper>
                )}
            </AutoCompleteWrapper>
          )}
        </PlacesAutocomplete>
        )}
    </div>
  );
}

AddressTypeAhead.propTypes = {
  placesState: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  address: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,

};

AddressTypeAhead.defaultProps = {
  address: null,
};

export default AddressTypeAhead;
