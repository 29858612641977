import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const ModalPage = styled.div`
  position:fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 3em;
  background: var(--modal-overlay);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
  backdrop-filter: blur(10px);

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 1em;
  }
`;

export const ModalContainer = styled.div(({
  modalWidth,
}) => `
  max-width: ${modalWidth};
  margin: 0 auto;
  width: 100%;
  background: var(--input-bg-panel);
  border: 0.8px solid var(--card-border);
  box-shadow: 2px 4px 11px var(--card-shadow);
  border-radius: 1em;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 0.5em;
  }
`);

export const ModalUtilityRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1em 0.75em 1.25em;
  border-bottom: 1px solid var(--panel-info-border);

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 0.5em 0.5em 0.75em 0.5em;
  }
`;

export const ModalContentWrapper = styled.div`
  width: 100%;
  padding: 1.25em;

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 0.5em;
  }
`;
