/* eslint-disable no-plusplus */
/* eslint-disable react/style-prop-object */
import anime from 'animejs/lib/anime.es';
import { useEffect } from 'preact/hooks';

function EqErrorAnimation() {
  const g1Animation = anime.timeline({
    autoplay: true,
    loop: true,
    direction: 'alternate',
  });
  const g2Animation = anime.timeline({
    autoplay: true,
    loop: true,
    direction: 'alternate',
  });
  const g3Animation = anime.timeline({
    autoplay: true,
    loop: true,
    direction: 'alternate',
    delay: 100,
  });
  const g4Animation = anime.timeline({
    autoplay: true,
    loop: true,
    direction: 'alternate',
  });

  const animateTheBreak = () => {
    anime({
      targets: '#G3 .break',
      stroke: '#A71818',
      duration: 1200,
    });
  };

  useEffect(() => {
    g1Animation.add({
      targets: '#G1 .led',
      stroke: ['var(--background)', '#5621CB'],
      duration: 100,
      delay: anime.stagger(75),
      direction: 'alternate',
    });
    g2Animation.add({
      targets: '#G2 .led',
      stroke: ['var(--background)', '#5621CB'],
      duration: 100,
      delay: anime.stagger(100),
      direction: 'alternate',
    });
    g3Animation.add({
      targets: '#G3 .led',
      stroke: ['var(--background)', '#5621CB'],
      duration: 100,
      delay: anime.stagger(40),
      direction: 'alternate',
    });
    g4Animation.add({
      targets: '#G4 .led',
      stroke: ['var(--background)', '#5621CB'],
      duration: 75,
      delay: anime.stagger(120),
      direction: 'alternate',
    });

    setTimeout(() => {
      animateTheBreak();
    }, 1900);
  }, []);

  return (
    <div id="eq-animation">
      <svg width="112px" height="100%" viewBox="0 0 160 160" style={{ overflow: 'visible' }}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group-4">
            <polygon id="eq-wrapper" fillRule="nonzero" points="0 0 160 0 160 105.190186 160 160 0 160" />
            <g id="G1" transform="translate(16.666667, 18.333333)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13.3333333">
              <line className="led" x1="0" y1="121.666667" x2="16.6666667" y2="121.666667" id="g1-1" stroke="#5621CB" />
              <line className="led" x1="0" y1="101.666667" x2="16.6666667" y2="101.666667" id="g1-2" stroke="#5621CB" />
              <line className="led" x1="0" y1="81.6666667" x2="16.6666667" y2="81.6666667" id="g1-3" stroke="#5621CB" />
              <line className="led" x1="0" y1="61.6666667" x2="16.6666667" y2="61.6666667" id="g1-4" stroke="#5621CB" />
              <line className="led" x1="0" y1="41.6666667" x2="16.6666667" y2="41.6666667" id="g1-5" stroke="#09080B" />
              <line className="led" x1="0" y1="21.6666667" x2="16.6666667" y2="21.6666667" id="g1-6" stroke="#09080B" />
            </g>
            <g id="G2" transform="translate(53.333333, 18.333333)" stroke="#5621CB" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13.3333333">
              <line className="led" x1="0" y1="121.666667" x2="16.6666667" y2="121.666667" id="g2-1" />
              <line className="led" x1="0" y1="101.666667" x2="16.6666667" y2="101.666667" id="g2-2" />
              <line className="led" x1="0" y1="81.6666667" x2="16.6666667" y2="81.6666667" id="g2-3" />
              <line className="led" x1="0" y1="61.6666667" x2="16.6666667" y2="61.6666667" id="g2-4" />
              <line className="led" x1="0" y1="41.6666667" x2="16.6666667" y2="41.6666667" id="g2-5" />
              <line className="led" x1="0" y1="21.6666667" x2="16.6666667" y2="21.6666667" id="g2-6" />
              <line className="led" x1="0" y1="1.66666667" x2="16.6666667" y2="1.66666667" id="g2-7" />
            </g>
            <g id="G3" transform="translate(90.000000, 18.333333)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13.3333333">
              <line x1="0" y1="121.666667" x2="16.6666667" y2="121.666667" id="g3-1" stroke="#5621CB" />
              <line className="led" x1="0" y1="101.666667" x2="16.6666667" y2="101.666667" id="g3-2" stroke="#5621CB" />
              <line className="led break" x1="0" y1="81.6666667" x2="16.6666667" y2="81.6666667" id="g3-3" stroke="#5621CB" />
            </g>
            <g transform="translate(126.666667, 18.333333)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13.3333333">
              <g id="G4" style="transform-box: fill-box; transform-origin: bottom">
                <line className="led" x1="0" y1="121.666667" x2="16.6666667" y2="121.666667" id="g4-1" stroke="#5621CB" style="transform-box: fill-box; transform-origin: 50% 50%" />
                <line className="led" x1="0" y1="101.666667" x2="16.6666667" y2="101.666667" id="g4-2" stroke="#5621CB" style="transform-box: fill-box; transform-origin: 50% 50%" />
                <line className="led" x1="0" y1="81.6666667" x2="16.6666667" y2="81.6666667" id="g4-3" stroke="#5621CB" style="transform-box: fill-box; transform-origin: 50% 50%" />
                <line className="led" x1="0" y1="61.6666667" x2="16.6666667" y2="61.6666667" id="g4-4" stroke="#5621CB" style="transform-box: fill-box; transform-origin: 50% 50%" />
                <line className="led" x1="0" y1="41.6666667" x2="16.6666667" y2="41.6666667" id="g4-5" stroke="#5621CB" style="transform-box: fill-box; transform-origin: 50% 50%" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default EqErrorAnimation;
