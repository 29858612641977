import { orgConnectionsUrl } from '../urls';
import refresh from '../refresh';

export async function getOrgConnections(orgId) {
  return refresh(async () => {
    const response = await fetch(`${orgConnectionsUrl}?${new URLSearchParams({
      org: orgId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function getOrgConnection(orgId, otherOrgId) {
  return refresh(async () => {
    const response = await fetch(`${orgConnectionsUrl}?${new URLSearchParams({
      org: orgId,
      other_org: otherOrgId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}
