import { accountPublisherPresetUrl } from '../urls';
import refresh from '../refresh';

export async function getAccountPublisherPresets() {
  return refresh(async () => {
    const response = await fetch(accountPublisherPresetUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function postAccountPublisherPreset(name, isDefault, publishers) {
  const data = {
    name,
    isDefault,
    publishers,
  };
  return refresh(async () => {
    const response = await fetch(accountPublisherPresetUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function putAccountPublisherPreset(presetId, name, publishers) {
  const data = {
    preset: presetId,
    name,
    publishers,
  };
  return refresh(async () => {
    const response = await fetch(accountPublisherPresetUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function patchAccountPublisherPresetDefault(presetId) {
  const data = {
    preset: presetId,
  };
  return refresh(async () => {
    const response = await fetch(accountPublisherPresetUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteAccountPublisherPreset(presetId) {
  const data = {
    preset: presetId,
  };
  return refresh(async () => {
    const response = await fetch(accountPublisherPresetUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
