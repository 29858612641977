export default function useFileSize(sizeInBytes) {
  if (sizeInBytes >= 1000 * 1000 * 1000) {
    return `${Math.round(sizeInBytes / 1000000000)} GB`;
  }
  if (sizeInBytes >= 1000 * 1000) {
    return `${Math.round(sizeInBytes / 1000000)} MB`;
  }
  if (sizeInBytes >= 1000) {
    return `${Math.round(sizeInBytes / 1000)} KB`;
  }
  return `${sizeInBytes} Bytes`;
}
