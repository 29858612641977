import { signupUrl } from '../urls';

export default async function postSignup(email) {
  const data = {
    email,
  };
  const response = await fetch(signupUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response;
}
