/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import dayjs from 'dayjs';
import AppState from '@state';

import { postOrgArtistInvite } from '@api/restricted/org-artist-invite-api';

import useIsoDateFormat from '@hooks/useIsoDateFormat';
import useRoundNumber from '@hooks/useRoundNumber';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import BaseButton from '@ui-kit/buttons/baseButton';
import BreakLine from '@ui-kit/dividers/breakLine';
import AlertMessage from '@ui-kit/alert/Alert';
import Avatar from '@ui-kit/avatar';
import Spinner from '@ui-kit/loaders/Spinner';
import MailWedge from '@animations/wedges/MailWedge';
import { Fragment } from 'preact';

function ReviewWriterInvitation({
  recipient,
  terms,
  coPub,
  requirements,
  ...restProps
}) {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [suggestions, setSuggestions] = useState({});
  const [claims, setClaims] = useState({});
  const [registration, setRegistation] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);

  const d = useIsoDateFormat;

  useEffect(() => {
    const suggestedData = {
      society: (recipient.suggestedSociety && recipient.suggestedSociety.name !== recipient.user?.society) ? recipient.suggestedSociety : null,
      ipi: (recipient.suggestedIPI && recipient.suggestedIPI !== recipient.user?.ipi) ? recipient.suggestedIPI : null,
      isni: (recipient.suggestedISNI && recipient.suggestedISNI !== recipient.user?.isni) ? recipient.suggestedISNI : null,
      coPub: coPub?.detailProvided ? [{
        name: coPub.name,
        society: coPub.society,
        ipi: coPub.ipi,
        address: coPub.address,
        share: terms.hasOwnership ? useRoundNumber(100 - terms.ownershipShare, 2) : 100,
        territory: null,
      }] : null,
    };
    setSuggestions(suggestedData);

    const claimedData = {
      validPeriod: terms.compositionsControlled === 'all' ? {
        from: terms.fromDate,
        to: terms.hasEndDate ? terms.toDate : null,
      } : null,
      undividedOwnership: terms.hasOwnership ? terms.ownershipShare : 0,
      adminTerritory: terms.territory,
      deliveryCommitment: requirements.detailProvided ? {
        totalCompositions: requirements.totalWorks,
        alreadyDelivered: requirements.deliveredToDate,
        qualifyingPercentage: requirements.qualifyingPercentage,
        aggregate: {
          allowed: requirements.allowCombineWorks,
          minimumPercentage: (requirements.allowCombineWorks && requirements.hasMinimum) ? requirements.minimumPercentage : null,
        },
      } : null,
    };
    setClaims(claimedData);

    setRegistation(AppState.orgRegistrations.value.find((reg) => reg.id === terms.publisherRegistrationId));

    setTimeout(() => {
      setIsPageLoading(false);
    }, 500);
  }, []);

  const onClickSendInvitation = () => {
    // In case user gets to this step 1 day after the 'terms' step
    if (claims.validPeriod?.to && dayjs(claims.validPeriod.to).isBefore(dayjs())) {
      setError('End date cannot be before today');
      return;
    }

    setError('');
    setIsSaving(true);
    setTimeout(() => {
      postOrgArtistInvite(terms.publisherRegistrationId, recipient.email, suggestions, claims)
        .then((response) => {
          if (response.status === 200) {
            setIsSaving(false);
            AppState.messages.toastMessage.value = { text: 'Invitation Sent', variant: 'success' };
            route(`/publisher/${AppState.pubOrganization.uuid.value}/songwriters?writers=pending`);
          } else {
            setIsSaving(false);
            response.json()
              .then((json) => setError(json.error || response.statusText))
              .catch(() => setError(response.statusText));
          }
        })
        .catch((err) => {
          setIsSaving(false);
          setError(err.message);
        });
    }, 800);
  };

  return (
    <Box {...restProps}>
      <BreakLine mb="1.5em" mt="0.5em" color="var(--panel-info-border)" />

      {isPageLoading ? (
        <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
          <Spinner size="2em" variant="page" />
        </Box>
      ) : (
        <Fragment>
          {recipient.user?.id ? (
            <Box display="flex" mb="1.5em" alignItems="center">
              <Box mr="0.75em">
                <Avatar size="3em" mb="1em" imgURL={recipient.user.imgURL} isVerified={recipient.user.isVerified} checkSize="1em" checkY="4px" checkX="-2px" />
              </Box>
              <Box>
                <Text fontWeight="600" fontSize="0.813rem">
                  Registered User
                </Text>
                <Text fontSize="0.813rem">
                  {recipient.user.firstName}
                  {' '}
                  {recipient.user.lastName}
                </Text>
              </Box>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <Box mr="0.75em">
                <MailWedge />
              </Box>
              <Box>
                <Text fontWeight="600" fontSize="0.813rem">
                  Send to:
                </Text>
                <Text fontSize="0.813rem">{recipient.email}</Text>
              </Box>
            </Box>
          )}

          <Box mb="1.5em">
            {suggestions.society?.name && (
            <Text mb="1em" fontSize="0.813rem">
              Suggested Collection Society:
              {' '}
              {suggestions.society.name}
            </Text>
            )}
            {suggestions.ipi && (
            <Text mb="1em" fontSize="0.813rem">
              Suggested IPI:
              {' '}
              {suggestions.ipi}
            </Text>
            )}
            {suggestions.isni && (
            <Text fontSize="0.813rem">
              Suggested ISNI:
              {' '}
              {suggestions.isni}
            </Text>
            )}
          </Box>

          <BreakLine mb="1.5em" color="var(--panel-info-border)" />

          <Text mb="0.813em" fontWeight="800">Credential Terms</Text>

          <Text mb="0.5em" fontSize="0.813rem">
            Works:
            {' '}
            {claims.validPeriod?.from ? 'All Works Created' : 'Only Select Works'}
          </Text>

          {claims.validPeriod?.from && (
          <Text fontSize="0.813rem">
            Effective Date:
            {' '}
            {d(claims.validPeriod.from)}
          </Text>
          )}

          {claims.validPeriod?.to && (
          <Text mt="0.5em" fontSize="0.813rem">
            To Date:
            {' '}
            {d(claims.validPeriod.to)}
          </Text>
          )}

          <BreakLine mb="1.5em" mt="1.5em" color="var(--panel-info-border)" />

          <Text mb="0.813em" fontWeight="800">Publisher Connection</Text>

          <Text mb="0.5em" fontSize="0.813rem" fontWeight="600">
            {registration.name}
          </Text>
          <Text mb="0.5em" fontSize="0.813rem">
            Society:
            {' '}
            {registration.society}
          </Text>
          <Text mb="0.5em" fontSize="0.813rem">
            IPI:
            {' '}
            {registration.ipi}
          </Text>
          <Text mb="1em" fontSize="0.813rem">
            {registration.address}
          </Text>

          <Text mb="0.5em" fontSize="0.813rem">
            Undivided Ownership Interest:
            {' '}
            {claims.undividedOwnership}
            %
          </Text>
          <Text mb="1.75em" fontSize="0.813rem">
            Administration Territory:
            {' '}
            {claims.adminTerritory}
          </Text>

          <BreakLine mb="1.5em" color="var(--panel-info-border)" />

          {suggestions.coPub && (
          <Fragment>
            <Text mb="0.813em" fontWeight="800">Suggested Writer Publisher</Text>

            <Text mb="0.5em" fontSize="0.813rem" fontWeight="600">
              {suggestions.coPub[0].name}
            </Text>
            <Text mb="0.5em" fontSize="0.813rem">
              Society:
              {' '}
              {suggestions.coPub[0].society}
            </Text>
            <Text mb="0.5em" fontSize="0.813rem">
              IPI:
              {' '}
              {suggestions.coPub[0].ipi}
            </Text>
            <Text mb="1em" fontSize="0.813rem">
              {suggestions.coPub[0].address}
            </Text>

            <Text mb="1.75em" fontSize="0.813rem">
              Undivided Ownership Interest:
              {' '}
              {suggestions.coPub[0].share}
              %
            </Text>
            <BreakLine mb="1.5em" color="var(--panel-info-border)" />
          </Fragment>
          )}

          {claims.deliveryCommitment && (
          <Fragment>
            <Text mb="0.813em" fontWeight="800">Commitment Tracking</Text>

            <Text mb="0.5em" fontSize="0.813rem">
              Works Required:
              {' '}
              {claims.deliveryCommitment.totalCompositions}
            </Text>
            <Text mb="0.5em" fontSize="0.813rem">
              Already Delivered:
              {' '}
              {claims.deliveryCommitment.alreadyDelivered}
            </Text>
            <Text mb="0.5em" fontSize="0.813rem">
              Qualifying Percentage:
              {' '}
              {claims.deliveryCommitment.qualifyingPercentage}
              %
            </Text>
            <Text mb="1.75em" fontSize="0.813rem">
              Combine Ownership Percentages:
              {' '}
              {claims.deliveryCommitment.aggregate.allowed
                ? `Yes (${claims.deliveryCommitment.aggregate.minimumPercentage
                  ? `at least ${claims.deliveryCommitment.aggregate.minimumPercentage}% ownership`
                  : 'no minimum ownership'})`
                : 'No'}
            </Text>

            <BreakLine mb="1.5em" color="var(--panel-info-border)" />
          </Fragment>
          )}

          {error && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}

          <Box display="flex" justifyContent="flex-end">
            <BaseButton
              mb="1.5em"
              btnText="Send Invitation"
              onClick={onClickSendInvitation}
              disabled={isSaving}
              isLoading={isSaving}
              fluid
              decoration="arrow"
            />
          </Box>
        </Fragment>
      )}
    </Box>
  );
}

ReviewWriterInvitation.propTypes = {
  recipient: PropTypes.object.isRequired,
  terms: PropTypes.object.isRequired,
  coPub: PropTypes.object,
  requirements: PropTypes.object,
};

ReviewWriterInvitation.defaultProps = {
  coPub: null,
  requirements: null,
};

export default ReviewWriterInvitation;
