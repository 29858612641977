/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useEffect, useState, useRef } from 'preact/hooks';
import { route } from 'preact-router';
import AppState from '@state';
import resetGlobalState from '@state/reset';

import useClickOutside from '@hooks/useClickOutside';
import useWindowDimensions from '@hooks/useWindowDimensions';
import loadAccountProfile from '@actions/loadAccountProfile';

import Box from '@ui-kit/box';
import Avatar from '@ui-kit/avatar';
import SwitchLogo from '@assets/logos/switchchord-logo-main.svg';
import Icon from '@ui-kit/icon';
import PopoverMenu from '@ui-kit/menu/popoverMenu';

import {
  StyledHeader,
  HeaderContentWrapper,
  StyledNameText,
  StyledAccountWrapper,
  ActionMenuItem,
} from './CoreRepHeaderStyles';

function CoreRepHeader() {
  const [showMenu, setShowMenu] = useState(false);
  const [menuOffset, setMenuOffset] = useState('translate(-50px,30px)');

  const { width } = useWindowDimensions();

  const ref = useRef(null);
  useClickOutside(ref, () => setShowMenu(false));

  useEffect(() => {
    if (!AppState.profileLoaded.value) {
      loadAccountProfile();
    }
    if (width < 780) {
      setMenuOffset('translate(-136px,30px)');
    } else {
      setMenuOffset('translate(-50px,30px)');
    }
  }, [width]);

  const onClickAvatar = () => {
    setShowMenu(!showMenu);
  };

  const onSignOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    resetGlobalState();
    route('/login');
  };

  return (
    <StyledHeader>
      <HeaderContentWrapper>
        <Box display="flex" alignItems="center">
          <Icon className="logo" size="172px" mr="5em" onClick={() => route('/rep/dashboard')} cursor><SwitchLogo /></Icon>
        </Box>
        <Box display="flex" alignItems="center">

          <StyledAccountWrapper ref={ref} display="flex" onClick={onClickAvatar}>
            <Avatar imgURL={AppState.userProfile.imgURL.value} />
            <Box display="flex">
              <StyledNameText>
                {AppState.userProfile.firstName.value}
                {' '}
                {AppState.userProfile.lastName.value}
              </StyledNameText>
            </Box>
            {showMenu
              && (
              <PopoverMenu ref={ref} transform={menuOffset}>
                <ActionMenuItem onClick={() => route('/rep/account')}>My Account</ActionMenuItem>
                <ActionMenuItem onClick={onSignOut}>Sign Out</ActionMenuItem>
              </PopoverMenu>
              )}
          </StyledAccountWrapper>
        </Box>
      </HeaderContentWrapper>
    </StyledHeader>
  );
}

export default CoreRepHeader;
