/* eslint-disable no-param-reassign */
// import { route } from 'preact-router';
import { useState, useEffect, useRef } from 'preact/hooks';
import { Fragment } from 'preact';
import PropTypes from 'prop-types';
import { route } from 'preact-router';
import AppState from '@state';

import { getOrgListById } from '@api/restricted/org-lists-api';
import { deleteOrgListContent } from '@api/restricted/org-list-contents';
import loadOrganization from '@actions/loadOrganization';
import loadOrgLists from '@actions/loadOrgLists';

import d from '@hooks/useIsoDateFormat';
import useWindowDimensions from '@hooks/useWindowDimensions';
import useTruncateText from '@hooks/useTruncateText';

import CorePublisherLayout from '@layouts/corePublisher';
import PageCard from '@layouts/pageCard';

import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import Spinner from '@ui-kit/loaders/Spinner';
import LeftIcon from '@assets/icons/arrow-left.svg';
import CompositionIcon from '@assets/illustrations/composition-chip.svg';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';

import TrashIcon from '@assets/icons/trash-outline.svg';
import LinkButton from '@ui-kit/buttons/linkButton';
import CountDown from '@distinct-components/feedback/countDown';

import { TableIllustrationWrapper, TableSearchEmptyPrompt } from './PublisherViewListStyles';

function PublisherViewList({ uuid, listId }) {
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [list, setList] = useState();
  const [currentCompositions, setCurrentCompositions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchActive, setSearchActive] = useState(false);

  const [timerId, setTimerId] = useState(null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [compIdToRemove, setCompIdToRemove] = useState();

  const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  const useToastAlertError = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'error' };
  };

  const loadList = async () => {
    try {
      const response = await getOrgListById(AppState.pubOrganization.id.value, listId);
      if (response.status === 200) {
        const json = await response.json();
        const formattedComps = json.compositions.map((c) => ({
          id: c.id,
          uuid: c.uuid,
          compositionName: c.name,
          writtenDate: c.written || null,
          createdAt: c.created || null,
          updatedAt: c.updated || null,
          writerCount: c.numWriters || null,
          controlledWriters: c.controlledWriters ? c.controlledWriters.sort() : null,
          uncontrolledWriterCount: (c.numWriters && c.controlledWriters) ? c.numWriters - c.controlledWriters.length : null,
          finalized: c.finalized || null,
          status: c.status || null,
        }));
        setList({
          ...json,
          compositions: formattedComps,
        });
        setCurrentCompositions(formattedComps);
        setSearchActive(false);
      } else {
        try {
          const json = await response.json();
          AppState.exceptions.errorPageError.value = `${json.error || response.statusText} when loading list`;
          route('/error/exception', true);
        } catch {
          AppState.exceptions.errorPageError.value = `${response.statusText} when loading list`;
          route('/error/exception', true);
        }
      }
    } catch (err) {
      AppState.exceptions.errorPageError.value = `${err.message} when loading list`;
      route('/error/exception', true);
    }
  };

  useEffect(() => {
    loadOrganization(uuid, { forcedLoad: false }).then(() => {
      loadList().then(() => {
        if (height < 1080) {
          setItemsPerPage(7);
        }
        setIsLoadingList(false);
      });
    });
  }, []);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = currentCompositions.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    const results = [];
    setCurrentPage(1);
    if (query) {
      list.compositions.forEach((composition) => {
        if (composition.compositionName.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(composition);
        }
      });
      setCurrentCompositions(results);
      setSearchActive(true);
    } else {
      setCurrentCompositions(list.compositions);
      setSearchActive(false);
    }
  };

  const onClickComposition = (compUuid) => {
    if (!compUuid) {
      useToastAlertError('Your organization no longer has access to this Composition');
    } else {
      AppState.navigation.backActionPath.value = `/publisher/${uuid}/lists/${listId}`;
      route(`/publisher/${uuid}/compositions/${compUuid}`);
    }
  };

  const onClickRemoveComposition = (id) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    setCompIdToRemove(id);
    const newTimerId = setTimeout(() => {
      setCompIdToRemove(null);
    }, 5000);
    setTimerId(newTimerId);
  };

  const handleRemoveComposition = (composition) => {
    const listIdAsInteger = parseInt(listId, 10);
    if (!listIdAsInteger) {
      useToastAlertError('Invalid List ID');
      return;
    }
    setIsRemoving(true);
    deleteOrgListContent(listIdAsInteger, AppState.pubOrganization.id.value, composition.id)
      .then((response) => {
        if (response.status === 200) {
          loadOrgLists(AppState.pubOrganization.id.value, { forcedLoad: true }).then(() => {
            loadList().then(() => {
              setIsRemoving(false);
            });
          });
        } else {
          response.json()
            .then((json) => useToastAlertError(json.error || response.statusText))
            .catch(() => useToastAlertError(response.statusText));
          setIsRemoving(false);
        }
      })
      .catch((err) => {
        setIsRemoving(false);
        useToastAlertError(err.message);
      });
  };

  return (
    <CorePublisherLayout>
      <Box display="flex" justifyContent="space-between" alignItems="center" className="page-back-wrapper">
        <Box display="flex" alignItems="center" className="cursor-p" onClick={() => route(`/publisher/${uuid}/lists`)}>
          <Icon size="1.125em" mr="0.5em" cursor><LeftIcon /></Icon>
          <Text color="var(--text-soft-mute)" fontSize="0.813rem" fontWeight="600">All Lists</Text>
        </Box>
      </Box>
      {isLoadingList ? (
        <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
          <Spinner size="2em" variant="page" />
        </Box>
      ) : (
        <PageCard
          title="List: "
          titleData={list.name}
          subTitle={list.description}
          isLoadingPage={isLoadingList}
        >
          <div ref={pageRef} />
          {list.compositions.length === 0
            ? (
              <Card variant="panel" mt="1.5em">
                <BaseEmptyState
                  title="Empty List"
                  message="No Compositions Added to this List Yet"
                  illustration="composition"
                  minHeight="38em"
                />
              </Card>
            )
            : (
              <Fragment>
                <TableControls
                  itemName="Composition"
                  tableData={list.compositions}
                  currentResults={currentResults}
                  indexOfFirst={indexOfFirst}
                  indexOfLast={indexOfLast}
                  showSearch
                  searchActive={searchActive}
                  searchResults={currentCompositions}
                  handleSearch={onSearchResults}
                />
                <Table mb="1em" scrollContents>
                  <TableHeader sticky>
                    <TableCell widthPercent="48%" pr="0.75em">Composition</TableCell>
                    <TableCell>Written On</TableCell>
                    <TableCell>Writers</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Finalized</TableCell>
                    <TableCell>Internal Status</TableCell>
                    <TableCell pr="1em">{' '}</TableCell>
                  </TableHeader>
                  {currentResults.map((composition) => (
                    <TableRow onClick={() => onClickComposition(composition.uuid)} cursor>
                      <TableCell widthPercent="48%" pr="0.75em">
                        <TableIllustrationWrapper>
                          <Icon className="icon" size="3.25em" pt="2px" mr="1em"><CompositionIcon /></Icon>
                          <Box pt="0.25rem">
                            <Text fontSize="0.938rem" color="var(--text-primary)" fontWeight="600" lineHeight="1.2">
                              {useTruncateText(composition.compositionName, { sm: 25, md: 15, lg: 50 })}
                            </Text>
                            <Text
                              mt="0.125em"
                              fontSize="0.75rem"
                              maxWidth="24em"
                              truncate
                              color={!composition.uuid && 'var(--text-secondary)'}
                            >
                              {/* TODO: Slice controlledWriters in case there are too many */}
                              {composition.uuid ? (
                                composition.controlledWriters.join(', ')
                                + (composition.uncontrolledWriterCount > 0
                                  ? ` and ${composition.uncontrolledWriterCount} other${composition.uncontrolledWriterCount > 1 ? 's' : ''}`
                                  : '')
                              ) : (
                                '(No Access)'
                              )}
                            </Text>
                          </Box>
                        </TableIllustrationWrapper>
                      </TableCell>
                      <TableCell mobileLabel="Date Written:" color={!composition.uuid && 'var(--text-medium-mute)'}>
                        {composition.uuid ? d(composition.writtenDate) : '(No Access)'}
                      </TableCell>
                      <TableCell mobileLabel="Writers:" color={!composition.uuid && 'var(--text-medium-mute)'}>
                        {composition.uuid ? composition.writerCount : '(No Access)'}
                      </TableCell>
                      <TableCell mobileLabel="Created:" color={!composition.uuid && 'var(--text-medium-mute)'}>
                        {composition.uuid ? d(composition.createdAt) : '(No Access)'}
                      </TableCell>
                      <TableCell mobileLabel="Finalized:" color={composition.finalized ? 'var(--green-300)' : 'var(--text-medium-mute)'}>
                        {composition.uuid && (
                          composition.finalized ? (
                            <Box>
                              {composition.writerCount > 1 ? 'Split Sheet Executed' : 'Single Writer Attested'}
                              <Text mt="0.125em" fontSize="0.75rem" maxWidth="24em" truncate opacity="0.8">
                                {d(composition.finalized)}
                              </Text>
                            </Box>
                          ) : 'Not Finalized'
                        )}
                        {!composition.uuid && '(No Access)'}
                      </TableCell>
                      <TableCell
                        mobileLabel="Status:"
                        color={composition.status ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}
                      >
                        {composition.uuid ? (composition.status || 'None') : '(No Access)'}
                      </TableCell>
                      <TableCell align="right" pr="1em">
                        {compIdToRemove === composition.id ? (
                          <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <CountDown mr="0.25em" mt="3px" timeInSeconds={5} size={12} color="var(--red-300)" />
                            <LinkButton
                              btnText="Yes, Remove"
                              variant="small"
                              color="var(--red-300)"
                              mr="1em"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveComposition(composition);
                              }}
                            />
                          </Box>
                        ) : (
                          <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <Icon size="0.875rem" pointerEvents={isRemoving ? 'none' : 'auto'} cursor>
                              <TrashIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onClickRemoveComposition(composition.id);
                                }}
                              />
                            </Icon>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {currentResults.length === 0
                  && (
                    <TableSearchEmptyPrompt>
                      <Text color="var(--text-muted-panel)">No Compositions Match Your Search</Text>
                    </TableSearchEmptyPrompt>
                  )}
                </Table>
                <TablePagination
                  itemsPerPage={itemsPerPage}
                  totalItems={currentCompositions.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </Fragment>
            )}

        </PageCard>
      )}

    </CorePublisherLayout>
  );
}

PublisherViewList.propTypes = {
  uuid: PropTypes.string.isRequired,
  listId: PropTypes.string.isRequired,
};

export default PublisherViewList;
