/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';

import BaseButton from '@ui-kit/buttons/baseButton';
import AlertMessage from '@ui-kit/alert/Alert';
import BreakLine from '@ui-kit/dividers/breakLine';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';

function ConnectPublisherSuggestions({
  suggestions,
  handleSuggestions,
  error,
  isLoading,
}) {
  const [acceptSuggestedSociety, setAcceptSuggestedSociety] = useState(true);
  const [acceptSuggestedIPI, setAcceptSuggestedIPI] = useState(true);
  const [acceptSuggestedISNI, setAcceptSuggestedISNI] = useState(true);

  const onSubmit = () => {
    handleSuggestions(acceptSuggestedIPI, acceptSuggestedISNI, acceptSuggestedSociety);
  };

  return (
    <Box>
      <Text mb="1.5em">
        The Publisher has recommended information for your profile.  You can accept these suggestions if you wish to add them to your Identity Profile.
      </Text>

      {suggestions.society?.name
      && (
        <Box>
          <BreakLine mb="1.5em" color="var(--panel-info-border)" />
          <Text fontWeight="800">Suggested Collection Society</Text>
          <Text fontSize="1.67em" mb="0.125em" fontWeight="200" color={acceptSuggestedSociety ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}>
            {suggestions.society.name}
          </Text>
          <Box display="flex" justifyContent="flex-end" mb="1.5em">
            <Text mr="1.25em" fontSize="0.75rem" color="var(--text-primary)">Add to Profile</Text>
            <ToggleSwitch id="society">
              <input
                type="checkbox"
                name="society"
                id="society"
                onChange={() => setAcceptSuggestedSociety(!acceptSuggestedSociety)}
                checked={acceptSuggestedSociety}
              />
            </ToggleSwitch>
          </Box>
        </Box>
      )}

      {suggestions.ipi
      && (
        <Box>
          <BreakLine mb="1.5em" color="var(--panel-info-border)" />
          <Text fontWeight="800">Suggested IPI Number</Text>
          <Text fontSize="1.67em" mb="0.125em" fontWeight="200" color={acceptSuggestedIPI ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}>
            {suggestions.ipi}
          </Text>
          <Box display="flex" justifyContent="flex-end" mb="1.5em">
            <Text mr="1.25em" fontSize="0.75rem" color="var(--text-primary)">Add to Profile</Text>
            <ToggleSwitch id="ipi">
              <input
                type="checkbox"
                name="ipi"
                id="ipi"
                onChange={() => setAcceptSuggestedIPI(!acceptSuggestedIPI)}
                checked={acceptSuggestedIPI}
              />
            </ToggleSwitch>
          </Box>
        </Box>
      )}

      {suggestions.isni
      && (
        <Box>
          <BreakLine mb="1.5em" color="var(--panel-info-border)" />
          <Text fontWeight="800">Suggested ISNI Number</Text>
          <Text fontSize="1.67em" mb="0.125em" fontWeight="200" color={acceptSuggestedISNI ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}>
            {suggestions.isni}
          </Text>
          <Box display="flex" justifyContent="flex-end" mb="1.5em">
            <Text mr="1.25em" fontSize="0.75rem" color="var(--text-primary)">Add to Profile</Text>
            <ToggleSwitch id="isni">
              <input
                type="checkbox"
                name="isni"
                id="isni"
                onChange={() => setAcceptSuggestedISNI(!acceptSuggestedISNI)}
                checked={acceptSuggestedISNI}
              />
            </ToggleSwitch>
          </Box>
        </Box>
      )}

      <BreakLine mb="1.75em" color="var(--panel-info-border)" />

      {error && (
      <AlertMessage
        variant="negative"
        message={error}
        mb="1.5em"
      />
      )}

      <BaseButton
        mb="1.5em"
        type="submit"
        btnText="Continue"
        onClick={onSubmit}
        fluid
        isLoading={isLoading}
      />
    </Box>

  );
}

ConnectPublisherSuggestions.propTypes = {
  suggestions: PropTypes.object.isRequired,
  handleSuggestions: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ConnectPublisherSuggestions;
