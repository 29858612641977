import styled from 'styled-components';
import { space } from 'styled-system';
import Text from '@ui-kit/typography/text';

export const DownloadPrompt = styled(Text)`
    font-size: 0.75rem;
    font-weight: 500;
    margin-left: 0.75em;
    padding-top: 1px
    color: var(--icon);
    ${space};
`;

export const StatusBarDownloadWrapper = styled.div`
    display:flex;
    align-items: center;
    cursor: pointer;
    ${space};
`;
