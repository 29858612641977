import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import { route } from 'preact-router';
import AppState from '@state';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import BaseButton from '@ui-kit/buttons/baseButton';
import Text from '@ui-kit/typography/text';

function InvitationSuccess({
  isNewUser,
  isWriter,
  workspaceTitle,
  workspaceId,
}) {
  const onClickContinue = () => {
    if (isNewUser) {
      route('/personalize');
    } else {
      route(`/workspace/${workspaceId}`);
      AppState.navigation.backActionPath.value = '/home';
    }
  };
  return (
    <Fragment>
      {(!isWriter) && (
      <ConversationPrompt
        primaryMessage="You've been added as an Manager or Representative on: "
        promptLabel="Success"
        labelColor="var(--green-400)"
        secondaryMessage={workspaceTitle}
        mb="2em"
      />
      )}
      {(isWriter) && (
        <Fragment>
          <ConversationPrompt
            primaryMessage="You've been added as a Writer to: "
            promptLabel="Success"
            labelColor="var(--green-400)"
            secondaryMessage={workspaceTitle}
            mb="2em"
          />
          <Text mb="2em" fontSize="1rem">You will receive an email when the split sheet is ready to sign.</Text>
        </Fragment>
      )}
      {isNewUser && <Text fontSize="1rem">Next, finish setting up your account.</Text>}
      <BaseButton
        mt="2em"
        btnText={isNewUser ? 'Finish Setting Up' : 'View the Composition'}
        fluid
        onClick={onClickContinue}
      />
    </Fragment>
  );
}

InvitationSuccess.propTypes = {
  isNewUser: PropTypes.bool.isRequired,
  isWriter: PropTypes.bool.isRequired,
  workspaceTitle: PropTypes.string.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

export default InvitationSuccess;
