/* eslint-disable import/prefer-default-export */
import { artistRepsInviteAcceptUrl } from '../urls';
import refresh from '../refresh';

export async function postArtistRepsInviteAccept(token, file) {
  if (file) {
    const data = new FormData();
    data.append('token', token);
    data.append('proof', file);

    return refresh(async () => {
      const response = await fetch(artistRepsInviteAcceptUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: data,
      });
      return response;
    });
  }

  const data = { token };
  return refresh(async () => {
    const response = await fetch(artistRepsInviteAcceptUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
