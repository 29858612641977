/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import { Fragment } from 'preact';

import AppState from '@state';

import loadComposition from '@actions/loadComposition';
import { patchWorkspaceHasSamples } from '@api/restricted/workspace-samples-api';

import BaseButton from '@ui-kit/buttons/baseButton';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import AlertMessage from '@ui-kit/alert/Alert';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseTextArea from '@ui-kit/inputs/baseTextArea/BaseTextArea';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';

import { CONTRACT_EXPLANATIONS } from '@constants/supportingCopy';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

function CreateSplitSheet({
  handleCreateContract,
  error,
  isLoading,
  closeFunction,
}) {
  const [contractMessage, setContractMessage] = useState(null);
  const [isToggling, setIsToggling] = useState(false);

  const hasInterpolation = AppState.composition.hasSamples.value;
  const interpolations = AppState.composition.samples.value;

  const useToastAlertError = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'error' };
  };

  const onCancelCreateContract = () => {
    setContractMessage(null);
    closeFunction();
  };

  const onToggleHasInterpolation = (e) => {
    e.preventDefault();

    if (hasInterpolation && interpolations.length > 0) {
      useToastAlertError('You need to delete all added samples first');
      return;
    }

    setIsToggling(true);
    patchWorkspaceHasSamples(AppState.composition.id.value, !hasInterpolation)
      .then((response) => {
        if (response.status === 200) {
          loadComposition(AppState.composition.id.value).then(() => {
            setIsToggling(false);
          });
        } else {
          response.json()
            .then((json) => useToastAlertError(json.error || response.statusText))
            .catch(() => useToastAlertError(response.statusText));
          setIsToggling(false);
        }
      })
      .catch((err) => {
        setIsToggling(false);
        useToastAlertError(err.message);
      });
  };

  return (
    <BaseEditOverlay formCentered closeFunction={onCancelCreateContract}>

      <ConversationPrompt
        primaryMessage="Create contract and send for signatures"
        promptLabel="New Contract"
        labelColor="var(--purple-300)"
        mb="1em"
      />
      <ConversationExplanation mb="2em" explanation={CONTRACT_EXPLANATIONS.lifecycle.createMessage} truncateAtCharacter={500} />

      <InputLabel label="Message to Co-Writers (Optional)" />
      <BaseTextArea
        placeholder="Share any important details..."
        type="textarea"
        value={contractMessage}
        onChange={(e) => setContractMessage((e.target.value))}
        fluid
        maxLength="250"
        mb="1.5em"
      />

      <Box>
        <Box display="flex" justifyContent="space-between" mb="1.75em">
          {(interpolations.length === 0 || !hasInterpolation) ? (
            <Fragment>
              <Text>I affirm, no works were sampled</Text>
              <ToggleSwitch id="noInterpolation" disabledCursor="wait">
                <input
                  type="checkbox"
                  name="noInterpolation"
                  id="noInterpolation"
                  checked={!hasInterpolation}
                  disabled={isToggling}
                  onClick={onToggleHasInterpolation}
                />
              </ToggleSwitch>
            </Fragment>
          ) : (
            <Text>
              Total Works Sampled:
              {' '}
              {interpolations.length}
            </Text>
          )}
        </Box>
      </Box>

      {error
      && (
      <AlertMessage
        variant="negative"
        message={error}
        mb="1.5em"
      />
      )}

      {(hasInterpolation && interpolations.length === 0) ? (
        <AlertMessage
          variant="warning"
          message="Define sampled works in the Samples tab before finalizing"
          mb="1em"
        />
      ) : (
        <BaseButton
          btnText="Create Contract"
          fluid
          isLoading={isLoading || isToggling}
          onClick={() => handleCreateContract(contractMessage)}
        />
      )}
    </BaseEditOverlay>
  );
}

CreateSplitSheet.propTypes = {
  handleCreateContract: PropTypes.func.isRequired,
  closeFunction: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

CreateSplitSheet.defaultProps = {
  error: null,
};

export default CreateSplitSheet;
