import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';

import BaseButton from '@ui-kit/buttons/baseButton';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import AlertMessage from '@ui-kit/alert/Alert';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

function DeleteConfirmationOverlay({
  closeFunction,
  handleDelete,
  error,
  isLoading,
  confirmationPhrase,
  header,
  headerLabel,
  explanation,
  buttonText,
}) {
  const [deleteConfirm, setDeleteConfirm] = useState(null);

  const onCancelDelete = () => {
    closeFunction();
  };

  const onTypeToConfirm = (value) => {
    const validationText = value.toLowerCase();
    setDeleteConfirm(validationText);
  };

  return (
    <BaseEditOverlay formCentered closeFunction={onCancelDelete}>

      <ConversationPrompt
        primaryMessage={header}
        promptLabel={headerLabel}
        labelColor="var(--red-300)"
        mb="1em"
      />
      <ConversationExplanation mb="2em" explanation={explanation} truncateAtCharacter={500} />

      <InputLabel label={`Confirm by typing the phrase "${confirmationPhrase}"`} />
      <BaseInput
        placeholder={`Enter "${confirmationPhrase}"`}
        type="text"
        onChange={(e) => onTypeToConfirm((e.target.value))}
        fluid
        mb="1.5em"
      />

      {error
      && (
      <AlertMessage
        variant="negative"
        message={error}
        mb="1.5em"
      />
      )}

      {deleteConfirm === confirmationPhrase.toLowerCase()
      && (
        <BaseButton
          btnText={buttonText}
          variant="danger"
          spinnerVariant="danger"
          fluid
          isLoading={isLoading}
          onClick={handleDelete}
        />
      )}

    </BaseEditOverlay>
  );
}

DeleteConfirmationOverlay.propTypes = {
  closeFunction: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  confirmationPhrase: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  headerLabel: PropTypes.string.isRequired,
  explanation: PropTypes.string.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
};

DeleteConfirmationOverlay.defaultProps = {
  buttonText: 'Delete',
  error: null,
};

export default DeleteConfirmationOverlay;
