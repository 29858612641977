/* eslint-disable import/prefer-default-export */

export const SPLIT_SHEET_PROSE = {
  /* Any changes need to be updated in renderContract.js in backend */
  thisAmendment: 'This amended and restated Split Sheet Agreement (this “Agreement”), effective as of the date of execution by all parties hereto, is made by and between ',
  thisAgreement: 'This Split Sheet Agreement (this “Agreement”), effective as of the date of execution by all parties hereto, is made by ',
  referredToAsWriters: ' (each, a “Writer” and collectively, the “Co-Writers”).',
  whereAs: 'WHEREAS, the Co-Writers have written and fixed in a tangible medium a new musical work titled ',
  whereAsAmend1: ' have entered into a Split Sheet Agreement, dated as of ',
  whereAsAmend2: 'WHEREAS, the Co-Writers have agreed, upon the terms and subject to the conditions set forth herein, to enter into this Agreement to amend and restate the Existing Split Sheet in its entirety as provided herein;',
  referredToAsComposition: ' (the “Composition”)',
  referredToAsAmendment: ' (the “Existing Split Sheet”)',
  relatingToComposition: ', relating to the musical work ',
  establishOwnership: ' and desire to establish initial ownership of the Composition;',
  nowTherefor: 'NOW, THEREFORE, the Co-Writers agree as follows:',
  contributionsAndOwnership: 'The music, lyrics, and other contributions to the Composition listed below shall be deemed to be merged into a single joint work:',
  definitionOfOwnership: '”Ownership Share,” as used above, means the percentage ownership to which each Writer is entitled of the right, title and interest in and to the Composition (including, without limitation, the copyright thereto, and any renewals thereof).',
  ownershipWithSamples: 'However, if any third party obtains an ownership interest in the Composition due to the existence of any Referenced Compositions (a “Sample Share”), the Ownership Shares will be deemed “Conditional Ownership Shares.” Such Conditional Ownership Shares represent ownership of the Composition before any changes that may occur due to the existence of any Referenced Compositions or Sample Shares.',
  representationsAndWarranties: 'Each Co-Writer represents and warrants that his or her contribution to the Composition is original and does not infringe upon the copyright or violate the rights of any third party.',
  noSamplesAttestation: 'No samples were used by the Co-Writers in the Composition.',
  indemnification: 'Each Co-Writer shall indemnify, defend, and hold harmless the other Co-Writers from and against any losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys\' fees and the cost of enforcing any right to indemnification hereunder, arising out of or in connection with any third-party claim, suit, action, or proceeding relating to any actual or alleged breach by a Co-Writer of his or her representations and warranties hereunder.',
  relationshipOfCowriters: 'Each Co-Writer shall have the right, throughout the world, to enter into any and all non-exclusive licenses and agreements with respect to the Composition, but only as to their respective interest in the Composition.',
  amendmentAndModification: 'No amendment or modification to this Agreement is effective unless it is in writing and signed by each Co-Writer or their authorized representatives.',
  counterparts: 'This Agreement may be executed in counterparts, each of which is deemed an original, but all of which together are deemed to be one and the same agreement. A signed copy of this Agreement delivered by email or other means of electronic transmission is deemed to have the same legal effect as delivery of an original signed copy of this Agreement.',
  inWitness: 'IN WITNESS WHEREOF, the parties hereto have executed this Agreement as of the day and year set forth below.',
};
