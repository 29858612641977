import { profileImageCheckUrl } from '../urls';

export default async function postProfileImageCheck(base64) {
  const body = {
    base64,
  };
  const response = await fetch(`${profileImageCheckUrl}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return response;
}
