import styled from 'styled-components';
import { space, typography, color } from 'styled-system';

export const StyledLabel = styled.p`
    font-size: 0.75rem;
    color: var(--text-soft-mute);
    margin: 0em 0em 0.75em 0em;
    ${space};
    ${color};
    ${typography};
`;

export default StyledLabel;
