import AppState from '@state';
import { getAccountPublishers } from '@api/restricted/account-publisher-api';
import useErrorOverlay from '@hooks/useErrorOverlay';

export default async function loadAccountPublishers() {
  try {
    const response = await getAccountPublishers();
    if (response.status !== 200) {
      const json = await response.json();
      useErrorOverlay({ errorMessage: `${json.error ? json.error : response.statusText} when loading publishers` });
    } else {
      const json = await response.json();
      AppState.publishers.value = json;
    }
  } catch (err) {
    useErrorOverlay({ errorMessage: `${err.message} when loading publishers` });
  }
}
