/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Spinner from '@ui-kit/loaders/Spinner';
import Card from '@distinct-components/cards/baseCard';

function ProfileOverlayStats({ stats, isLoading }) {
  return (
    <Box mt="1.5rem">
      {isLoading ? (
        <Box>
          <Box width="100%" display="flex" justifyContent="center" alignItems="center" minHeight="260px">
            <Spinner size="1.25em" variant="page" />
          </Box>
        </Box>
      ) : (
        <Box>

          {/* TODO: STYLE AFTER WIRING */}
          <Card variant="panel" mb="1rem">
            <Text>Total Connected Writers</Text>
            <Text>{stats.totalWriters}</Text>
          </Card>

          <Card variant="panel">
            <Text>Total Connected Works</Text>
            <Text>{stats.totalWorks}</Text>
          </Card>

        </Box>
      )}
    </Box>
  );
}

ProfileOverlayStats.propTypes = {
  stats: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ProfileOverlayStats;
