/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useEffect, useState, useRef } from 'preact/hooks';
import { route } from 'preact-router';
import { Fragment } from 'preact';
import AppState from '@state';
import resetGlobalState from '@state/reset';

import useAnalytics from '@hooks/useAnalytics';
import useClickOutside from '@hooks/useClickOutside';

import { getRepTokens } from '@api/restricted/rep-tokens-api';
import {
  getAccountNotifications,
  patchAccountNotificationsRead,
} from '@api/restricted/account-notifications-api';
import loadAccountProfile from '@actions/loadAccountProfile';

import { translateNotification } from '@hooks/translateNotificationActivity';
import usePollForData from '@hooks/usePollForData';

import Box from '@ui-kit/box';
import Avatar from '@ui-kit/avatar';
import SwitchLogo from '@assets/logos/switchchord-logo-main.svg';
import AlertMessage from '@ui-kit/alert/Alert';
import Icon from '@ui-kit/icon';
import Text from '@ui-kit/typography/text';
import LinkButton from '@ui-kit/buttons/linkButton';
import EventList from '@distinct-components/eventList';
import TaskList from '@distinct-components/taskList';
import CloseIcon from '@assets/icons/close-square.svg';
import AlertBell from '@assets/icons/alert-bell.svg';
import ClipboardIcon from '@assets/icons/clipboard-check.svg';
import CompIcon from '@assets/icons/composition-note.svg';
import NotificationListIcon from '@assets/icons/alert-list.svg';
import PopoverMenu from '@ui-kit/menu/popoverMenu';

import {
  StyledHeader,
  HeaderContentWrapper,
  StyledNameText,
  StyledAccountWrapper,
  StyledSearchBar,
  StyledNotificationWrapper,
  NotificationIndicator,
  NotificationTray,
  NotificationHeader,
  ResultsWrapper,
  ResultsItem,
  ActionMenuItem,
  StyledRepWrapper,
  StyledNameTextRep,
  StyledRepActionWrapper,
  RepNotificationIndicator,
  TasksTray,
  TasksHeader,
} from './CoreHeaderStyles';

function CoreHeader() {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [isRepTasksVisible, setIsRepTasksVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isUnreadNotifications, setIsUnreadNotifications] = useState(false);
  const [notificationError, setNotificationError] = useState(false);
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const analytics = useAnalytics();

  const ref = useRef(null);
  const notificationRef = useRef(null);
  const tasksRef = useRef(null);
  useClickOutside(ref, () => setShowMenu(false));
  useClickOutside(tasksRef, () => setIsRepTasksVisible(false));

  const isRepSession = AppState.repSession.isActive.value;
  const repTasks = AppState.repSession.repTasks.value;
  const isPendingRepTasks = repTasks.length > 0;

  const loadAccountNotifications = async () => {
    try {
      const response = await getAccountNotifications();
      if (response.status !== 200) {
        const json = await response.json();
        if (json.error) {
          setNotificationError(json.error);
        } else {
          setNotificationError(response.statusText);
        }
        setIsLoadingNotifications(false);
      } else {
        const json = await response.json();
        const unread = json.some((n) => n.read === false);
        setIsUnreadNotifications(unread);
        const translatedNotifications = json.map((n) => translateNotification(n));
        setNotifications(translatedNotifications);
        setIsLoadingNotifications(false);
      }
    } catch (err) {
      setNotificationError(err.message);
      setIsLoadingNotifications(false);
    }
  };

  const handleClickOutside = (event) => {
    if (notificationRef.current && !notificationRef.current.contains(event.target)) {
      setIsNotificationVisible(false);
      patchAccountNotificationsRead().then(() => {
        loadAccountNotifications();
      });
    } else if (notificationRef.current && notificationRef.current.contains(event.target)) {
      setIsNotificationVisible(true);
    }
  };

  usePollForData(!isLoadingNotifications, 5000, loadAccountNotifications, notificationError);

  useEffect(() => {
    if (!AppState.profileLoaded.value) {
      loadAccountProfile();
    }
    document.addEventListener('click', handleClickOutside, true);

    loadAccountNotifications();

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const onToggleNotifications = () => {
    if (window.innerWidth < 780) {
      route('/events/notifications');
      return;
    }
    const newVisible = !isNotificationVisible;
    setIsNotificationVisible(newVisible);
    if (newVisible && !isRepSession) {
      patchAccountNotificationsRead().then(() => {
        analytics.track('action-header-notificationTrayViewed-v1');
      });
    }
  };

  const onToggleTasks = () => {
    setIsRepTasksVisible(!isRepTasksVisible);
  };

  const onClickEndVerifiedSession = () => {
    getRepTokens()
      .then((response) => {
        if (response.status !== 200) {
          route('/error/invalidLink');
        } else {
          response.json().then((json) => {
            resetGlobalState();
            localStorage.setItem('accessToken', json.accessToken);
            localStorage.setItem('refreshToken', json.refreshToken);
            loadAccountProfile().then(() => {
              route('/rep/dashboard');
            });
          });
        }
      })
      .catch(() => {
        route('/error/invalidLink');
      });
  };

  const handleSelectedComposition = (comp) => {
    route(`/workspace/${comp.id}`);
  };

  const handleResetSearch = () => {
    setTimeout(() => {
      setSearchValue('');
    }, 200);
  };

  const onViewAllNotifications = () => {
    route('/events/notifications');
  };

  const onClickAvatar = () => {
    setShowMenu(!showMenu);
  };

  const onSignOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    resetGlobalState();
    route('/login');
  };

  return (
    <StyledHeader>
      <HeaderContentWrapper>
        <Box display="flex" alignItems="center">
          <Icon className="logo" size="172px" mr="5em" onClick={() => route('/home')} cursor><SwitchLogo /></Icon>
          <Box>
            <StyledSearchBar
              value={searchValue}
              placeholder="Search Compositions..."
              onChange={(e) => setSearchValue((e.target.value))}
              onBlur={handleResetSearch}
            />
            {searchValue.length > 0
            && (
            <ResultsWrapper>
              {AppState.compositions.value
                .filter((comp) => {
                  if (!searchValue) return true;
                  if (comp.compositionName.toLowerCase().includes(searchValue.toLowerCase())) {
                    return true;
                  }
                })
                .map((comp) => (
                  <ResultsItem onClick={() => handleSelectedComposition(comp)}>
                    <Icon size="0.875em" pt="2px" cursor color="var(--text-medium-mute)" mr="0.6em"><CompIcon /></Icon>
                    <p>{comp.compositionName}</p>
                  </ResultsItem>
                ))}
              {(AppState.compositions.value.filter((comp) => comp.compositionName.toLowerCase().includes(searchValue.toLowerCase())).length === 0)
              && (
              <ResultsItem>
                <p className="empty">No Matching Compositions</p>
              </ResultsItem>
              )}
            </ResultsWrapper>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">

          <StyledNotificationWrapper>
            {isUnreadNotifications && <NotificationIndicator />}
            <Icon size="1.125em" pt="4px" cursor onClick={onToggleNotifications}><AlertBell /></Icon>
          </StyledNotificationWrapper>

          {isRepSession && (
            <StyledRepActionWrapper onClick={onToggleTasks}>
              {isPendingRepTasks && <RepNotificationIndicator />}
              <Icon size="1.125em" color="var(--white)" pt="4px" cursor><ClipboardIcon /></Icon>
            </StyledRepActionWrapper>
          )}

          {isNotificationVisible
            && (
            <NotificationTray ref={notificationRef}>
              <NotificationHeader>
                <Text color="var(--white)" fontWeight="600">Notifications</Text>
                <Icon size="1.125em" pt="4px" color="var(--white)" cursor onClick={onToggleNotifications}><CloseIcon /></Icon>
              </NotificationHeader>
              <Box p="1em 1.5em">
                {!notificationError
                  ? (
                    <Fragment>
                      {notifications.length > 0
                        ? (
                          <EventList
                            events={notifications}
                            showUnreadIndicator
                            notifications
                            limit={4}
                          />
                        )
                        : (
                          <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" minHeight="14em">
                            <Icon size="2em" pt="3px" color="var(--empty-state-primary)" mb="0.5em"><NotificationListIcon /></Icon>
                            <Text color="var(--empty-state-primary)" fontSize="0.75em">No Notifications to Display</Text>
                          </Box>
                        )}
                      <div>
                        {notifications.length > 0 && (
                        <Box display="flex" justifyContent="flex-end" mt="0.75em">
                          <LinkButton btnText="View All" variant="small" onClick={onViewAllNotifications} />
                        </Box>
                        )}
                      </div>
                    </Fragment>
                  )
                  : (
                    <AlertMessage
                      variant="negative"
                      message={notificationError}
                      mb="1.5em"
                    />
                  )}
              </Box>
            </NotificationTray>
            )}

          {isRepTasksVisible
            && (
            <TasksTray ref={tasksRef}>
              <TasksHeader>
                <Text color="var(--white)" fontWeight="600">Representation Tasks</Text>
                <Icon size="1.125em" pt="4px" color="var(--white)" cursor onClick={onToggleTasks}><CloseIcon /></Icon>
              </TasksHeader>
              <Box p="0.25em 1em 1em 1em">
                <TaskList
                  tasks={repTasks}
                />
              </Box>
            </TasksTray>
            )}

          {isRepSession ? (
            <StyledRepWrapper ref={ref} onClick={onClickAvatar}>
              {AppState.userProfile.imgURL.value
                ? <Avatar borderColor="var(--off-white)" imgURL={AppState.userProfile.imgURL.value} />
                : <Avatar imgURL={null} />}
              <Box ml="0.5rem">
                <StyledNameTextRep color="var(--white)" ml="0">
                  <span style={{ fontWeight: 600 }}>Rep:</span>
                  {' '}
                  {AppState.userProfile.firstName.value}
                  {' '}
                  {AppState.userProfile.lastName.value}
                </StyledNameTextRep>
              </Box>
              {showMenu
               && (
               <PopoverMenu ref={ref} transform="translate(-8px,80px)">
                 <ActionMenuItem onClick={onClickEndVerifiedSession}>End Verified Session</ActionMenuItem>
               </PopoverMenu>
               )}
            </StyledRepWrapper>
          ) : (
            <StyledAccountWrapper ref={ref} display="flex" onClick={onClickAvatar}>
              <Avatar imgURL={AppState.userProfile.imgURL.value} />
              <Box display="flex">
                <StyledNameText>
                  {AppState.userProfile.firstName.value}
                  {' '}
                  {AppState.userProfile.lastName.value}
                </StyledNameText>
              </Box>
              {showMenu
              && (
              <PopoverMenu ref={ref} transform="translate(-50px,30px)">
                <ActionMenuItem onClick={() => route('/profile')}>Manage Profile</ActionMenuItem>
                <ActionMenuItem onClick={() => route('/settings')}>Settings</ActionMenuItem>
                <ActionMenuItem onClick={onSignOut}>Sign Out</ActionMenuItem>
              </PopoverMenu>
              )}
            </StyledAccountWrapper>
          )}

        </Box>
      </HeaderContentWrapper>
    </StyledHeader>
  );
}

export default CoreHeader;
