import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const StatusBarWrapper = styled.div(() => `
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0em;
    border-bottom: 1px solid var(--border);
    background: var(--background);
    position: sticky;
    top: 76px;
    z-index: 100;
    min-height: 4em;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;
     }
`);

export const StatusBarActions = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    white-space: nowrap;
    ${space};

    button {
        min-width: 6rem;
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
        margin-top: 1em;
        flex-direction: column-reverse;
        border-top: 1px solid var(--border);
        padding-top: 1em;
        width: 100%;

        button {
            width: 100%;
        }
     }
`;

export const SignatureChip = styled.div`
    background: var(--action-purple);
    border-radius: 4px;
    position: absolute;
    z-index: 200;
    left: -2px;
    bottom: -3px;
    font-size: 0.7rem;
    font-weight: 900;
    color: var(--white);
    padding: 3px 4px;
    line-height: 1;
    width: 44px;
    text-align: center;
`;
