import { useMemo } from 'preact/hooks';
import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

const BaseTextArea = styled.textarea(({
  hasError,
  fluid,
  minHeight,
}) => {
  const inputWidth = useMemo(() => (fluid ? '100%' : 'auto'), [fluid]);

  return `
    font-size: 0.875rem;
    font-family: var(--font-primary);
    padding: 0.75em 1.125em;
    border-radius: 0.5em;
    color: var(--text-input);
    border: 1px solid ${hasError ? 'var(--red-400)' : 'var(--input-border)'};
    transition: all 250ms ease-in-out;
    background-color: var(--input-bg);
    width: ${inputWidth};
    min-height: ${minHeight || '2.75em'};
    line-height: 1.8;
    resize: none;

    @media screen and (max-width: ${pointBreaks.sm}) {
      font-size: 1rem;
    }

    ::placeholder {
      color: var(--text-placeholder);
    }

    ${!hasError && `
      :focus {
        border-color: var(--blue-400);
      }
      :focus-visible {
        outline: none;
      }
      :disabled {
        cursor: not-allowed;
        background-color: var(--grey-ghost);
      }
    `};
  `;
}, space);

export default BaseTextArea;
