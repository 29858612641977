/* eslint-disable import/prefer-default-export */
import { orgInviteAcceptUrl } from '../urls';
import refresh from '../refresh';

export async function postOrgInviteAccept(token) {
  const data = {
    token,
  };
  return refresh(async () => {
    const response = await fetch(orgInviteAcceptUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
