import { useEffect, useState, useRef } from 'preact/hooks';
import useErrorOverlay from '@hooks/useErrorOverlay';

import { getOrganizations } from '@api/admin/admin-organizations-api';

import CoreAdminLayout from '@layouts/coreAdmin/CoreAdminLayout';
import PageCard from '@layouts/pageCard/PageCard';

import useWindowDimensions from '@hooks/useWindowDimensions';
import useIsoDateFormat from '@hooks/useIsoDateFormat';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';

import AdminCreateEditOrganization from './sections/adminCreateEditOrganization';
import AdminCreateOrgInvitation from './sections/adminCreateOrgInvitation';
import AdminOrgWriterOnboarding from './sections/adminOrgWriterOnboarding';

function AdminOrganizations() {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [searchActive, setSearchActive] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [searchedOrgs, setSearchedOrgs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showCreateOrg, setShowCreateOrg] = useState(false);
  const [showCreateInvite, setShowCreateInvite] = useState(false);
  const [showWriterOnboarding, setShowWriterOnboarding] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);

  const pageRef = useRef(null);
  const { height } = useWindowDimensions();
  const d = useIsoDateFormat;

  const loadOrganizations = async () => {
    try {
      const response = await getOrganizations();
      if (response.status !== 200) {
        const json = await response.json();
        useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading organizations` });
      } else {
        const json = await response.json();
        const sortedOrgs = json.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        const formattedOrgs = sortedOrgs.map((org) => ({
          id: org.id,
          uuid: org.uuid,
          name: org.name,
          createdAt: org.createdAt,
          logoUrl: org.logoURL,
        }));
        setOrganizations(formattedOrgs);
        setSearchedOrgs(formattedOrgs);
        setSearchActive(false);
        setIsLoadingPage(false);
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading organizations` });
    }
  };

  useEffect(() => {
    if (height < 1080) {
      setItemsPerPage(7);
    }
    loadOrganizations();
  }, []);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = searchedOrgs.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    const results = [];
    setCurrentPage(1);
    if (query) {
      organizations.forEach((org) => {
        if (org.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(org);
        }
      });
      setSearchedOrgs(results);
      setSearchActive(true);
    } else {
      setSearchedOrgs(organizations);
      setSearchActive(false);
    }
  };

  const onClickCreateOrg = () => {
    setShowCreateOrg(true);
  };

  const onClickEditOrg = (org) => {
    setSelectedOrg(org);
    setShowCreateOrg(true);
  };

  const handleCloseCreateEditOrg = () => {
    setSelectedOrg(null);
    setShowCreateOrg(false);
  };

  const onClickCreateInvite = (org) => {
    setSelectedOrg(org);
    setShowCreateInvite(true);
  };

  const handleCloseCreateInvite = () => {
    setSelectedOrg(null);
    setShowCreateInvite(false);
  };

  const onClickWriterOnboarding = (org) => {
    setSelectedOrg(org);
    setShowWriterOnboarding(true);
  };

  const handleCloseWriterOnboarding = () => {
    setSelectedOrg(null);
    setShowWriterOnboarding(false);
  };

  return (
    <CoreAdminLayout>
      <PageCard
        title="Organizations"
        primaryAction={{ method: onClickCreateOrg, text: '+ Organization' }}
        isLoadingPage={isLoadingPage}
      >

        <TableControls
          itemName="Organization"
          tableData={organizations}
          currentResults={currentResults}
          indexOfFirst={indexOfFirst}
          indexOfLast={indexOfLast}
          showSearch
          searchActive={searchActive}
          searchResults={searchedOrgs}
          handleSearch={onSearchResults}
        />
        <Table mb="1em">
          <TableHeader>
            <TableCell widthPercent="6%">Id</TableCell>
            <TableCell widthPercent="48%">Organization</TableCell>
            <TableCell>Created Date</TableCell>
            <TableCell />
          </TableHeader>
          {currentResults
            .map((org) => (
              <TableRow>
                <TableCell widthPercent="6%">{org.id}</TableCell>
                <TableCell widthPercent="48%">
                  <Text fontSize="0.813rem" color="var(--text-primary)" fontWeight="600">
                    {org.name}
                  </Text>
                  <Text fontSize="0.75rem" color="var(--text-medium-mute)">
                    {org.uuid}
                  </Text>
                </TableCell>
                <TableCell>{d(org.createdAt)}</TableCell>
                <TableCell>
                  <Box display="flex" justifyContent="flex-end">
                    <Text onClick={() => onClickCreateInvite(org)} className="cursor-p" mr="1.5em">Create Invite</Text>
                    <Text onClick={() => onClickWriterOnboarding(org)} className="cursor-p" mr="1.5em">Onboard</Text>
                    <Text onClick={() => onClickEditOrg(org)} className="cursor-p">Edit</Text>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          {(currentResults.length === 0)
          && (
            <Box p="1.25em 1em">
              <Text color="var(--text-muted-panel)" fontSize="0.875rem">No Orgs to Display</Text>
            </Box>
          )}
        </Table>

        <TablePagination
          itemsPerPage={itemsPerPage}
          totalItems={searchedOrgs.length}
          paginate={paginate}
          currentPage={currentPage}
        />

      </PageCard>

      {showCreateOrg
      && <AdminCreateEditOrganization closeFunction={handleCloseCreateEditOrg} organization={selectedOrg} loadOrganizations={loadOrganizations} />}

      {showCreateInvite
      && <AdminCreateOrgInvitation closeFunction={handleCloseCreateInvite} organization={selectedOrg} />}

      {showWriterOnboarding
      && <AdminOrgWriterOnboarding closeFunction={handleCloseWriterOnboarding} organization={selectedOrg} />}
    </CoreAdminLayout>
  );
}

export default AdminOrganizations;
