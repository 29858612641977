import anime from 'animejs/lib/anime.es';
import { useEffect } from 'preact/hooks';

function ActivePlayerAnimation() {
  const wrappersStyle = {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  };

  const mainSquareStyle = {
    width: '16px',
    height: '16px',
    borderRadius: '3px',
    background: 'var(--player-wave)',
    zIndex: 200,
  };

  const pulseStyle = {
    width: '32px',
    height: '32px',
    borderRadius: '50px',
    background: 'var(--inactive)',
    position: 'absolute',
    left: '-8px',
  };

  useEffect(() => {
    anime({
      targets: '.pulse',
      scale: [0.25, 1],
      opacity: [1, 0.8, 0],
      duration: 3000,
      easing: 'easeOutCubic',
      loop: true,
      delay: 400,
    });
  }, []);

  return (
    <div id="square-animation-wrapper" style={wrappersStyle}>
      <div className="main-square" style={mainSquareStyle} />
      <div className="pulse" style={pulseStyle} />
    </div>
  );
}

export default ActivePlayerAnimation;
