/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import { Fragment } from 'preact';

import BaseButton from '@ui-kit/buttons/baseButton';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import AlertMessage from '@ui-kit/alert/Alert';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseTextArea from '@ui-kit/inputs/baseTextArea/BaseTextArea';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import LinkButton from '@ui-kit/buttons/linkButton';
import Box from '@ui-kit/box';

import { CONTRACT_EXPLANATIONS } from '@constants/supportingCopy';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

function ContractReject({
  handleRejectContract,
  errorRejecting,
  isLoading,
  closeFunction,
}) {
  const [rejectionTopic, setRejectionTopic] = useState(null);
  const [rejectReason, setRejectReason] = useState(null);
  const [newLegalName, setNewLegalName] = useState(null);

  const onClickReject = () => {
    const rejectionType = {
      split: 'Contribution or Split',
      name: 'Incorrect Name',
      other: 'Other',
      nonWriter: 'Non-Participant',
    };
    handleRejectContract(rejectionType[rejectionTopic], newLegalName || rejectReason);
  };

  const onCancelRejectContract = () => {
    setRejectReason(null);
    setRejectionTopic(null);
    closeFunction();
  };

  const onClickWriterNotAssociated = () => {
    setRejectionTopic('nonWriter');
  };

  const onReasonChange = (value) => {
    setRejectReason(value);
  };

  return (
    <BaseEditOverlay formCentered closeFunction={onCancelRejectContract}>

      <ConversationPrompt
        promptLabel="Reject Contract"
        primaryMessage={rejectionTopic ? 'Confirm Rejection' : 'Why are you rejecting?'}
        labelColor="var(--red-300)"
        mb="1em"
      />

      {rejectionTopic && rejectionTopic !== 'nonWriter'
      && <ConversationExplanation mb="2em" explanation={CONTRACT_EXPLANATIONS.contractView.rejectionOutcomes} truncateAtCharacter={500} />}

      {rejectionTopic && rejectionTopic === 'nonWriter'
      && <ConversationExplanation mb="2em" explanation={CONTRACT_EXPLANATIONS.contractView.rejectAsNonWriter} truncateAtCharacter={500} />}

      {!rejectionTopic && (
      <Fragment>
        <BaseButton
          btnText="Contribution or Split"
          variant="secondaryDanger"
          spinnerVariant="danger"
          fontWeight="300"
          fluid
          onClick={() => setRejectionTopic('split')}
          mb="1.25em"
          mt="2em"
        />
        <BaseButton
          btnText="Name is Wrong"
          variant="secondaryDanger"
          spinnerVariant="danger"
          fontWeight="300"
          fluid
          onClick={() => setRejectionTopic('name')}
          mb="1.25em"
        />
        <BaseButton
          btnText="Other"
          variant="secondaryDanger"
          fontWeight="300"
          spinnerVariant="danger"
          fluid
          onClick={() => setRejectionTopic('other')}
          mb="2.5em"
        />
        <Box display="flex" justifyContent="center">
          <LinkButton btnText="I am not a writer on this composition" variant="small" color="var(--text-secondary)" mr="1em" onClick={onClickWriterNotAssociated} />
        </Box>
      </Fragment>
      )}

      {rejectionTopic && (
        <Fragment>

          {rejectionTopic === 'name' && (
            <Fragment>
              <InputLabel label="What is the legal name that should be used?" />
              <BaseInput
                placeholder="Legal Name..."
                onChange={(e) => setNewLegalName((e.target.value))}
                fluid
                maxLength="250"
                mb="2.5em"
              />
            </Fragment>
          )}

          {(rejectionTopic === 'split' || rejectionTopic === 'other') && (
          <Fragment>
            <InputLabel label="Explanation (Required)" />
            <BaseTextArea
              placeholder="What needs to be fixed..."
              type="textarea"
              value={rejectReason}
              onChange={(e) => onReasonChange((e.target.value))}
              fluid
              maxLength="250"
              mb="2.5em"
            />
          </Fragment>
          )}
        </Fragment>
      )}

      {errorRejecting
        && (
        <AlertMessage
          variant="negative"
          message={errorRejecting}
          mb="2.5em"
        />
        )}

      {(rejectionTopic === 'nonWriter'
      || (rejectionTopic === 'name' && newLegalName && newLegalName.length > 1)
      || (rejectionTopic !== 'name' && rejectReason && rejectReason.length > 0)
      ) && (
      <BaseButton
        btnText="Reject"
        variant="danger"
        spinnerVariant="danger"
        fluid
        isLoading={isLoading}
        onClick={onClickReject}
      />
      )}
    </BaseEditOverlay>
  );
}

ContractReject.propTypes = {
  handleRejectContract: PropTypes.func.isRequired,
  closeFunction: PropTypes.func.isRequired,
  errorRejecting: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

ContractReject.defaultProps = {
  errorRejecting: null,
};

export default ContractReject;
