import styled from 'styled-components';
import { space, typography } from 'styled-system';

export const SwitchWrapper = styled.div(({ large, disabledCursor }) => `
margin: 0px;
position: relative;

& input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
}

& input[type="checkbox"]:checked + label {
    background: var(--input-toggle-active);
}

& input[type="checkbox"]:disabled + label {
    cursor: ${disabledCursor};
}

& input[type="checkbox"]:checked + label::after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
}

& input[type="checkbox"] + label.loading {
    background: linear-gradient(90deg, var(--input-toggle-active), var(--purple-200), var(--purple-100));
    background-size: 200% 200%;
    background-position: 0% 100%;
    animation-name: toggleLoader;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
}

& label {
    cursor: pointer;
    width: ${large ? '64px' : '48px'};
    height: ${large ? '32px' : '24px'};
    background: var(--input-toggle-inactive);
    display: block;
    border-radius: 24px;
    position: relative;
}

& label::after {
    content: "";
    position: absolute;
    z-index: 200;
    top: 4px;
    left: 4px;
    width: ${large ? '24px' : '16px'};
    height: ${large ? '24px' : '16px'};
    background: white;
    border-radius: 16px;
    transition: all 300ms ease;
}

@keyframes toggleLoader {
    from {
        background-position: 0% 100%;
    }
    to {
        background-position: 100% 0%;
    }
  }


    ${space};
    ${typography};
`, space, typography);

export default SwitchWrapper;
