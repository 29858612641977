import styled from 'styled-components';
import { space, typography } from 'styled-system';

export const RadioRow = styled.div`
    display: flex;
    margin-bottom: 1em;
`;

export const RadioWrapper = styled.div(() => `
margin: 0px;

& input[type="radio"] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
}

& input[type="radio"]:checked + .circle {
    background: var(--brand-primary);
    border-color: var(--white);
}

& .circle {
    height: 1.125em;
    width: 1.125em;
    border-radius: 50%;
    border: 2px solid var(--grey-ash);
    background-color: transparent;
    margin-top: 3px;
}

& label {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--text-input);
    touch-action: manipulation;
    position: absolute;
    transform: translate(-32px,0px);
    padding: 0.25em 0em 0.25em 2.25em;
}

${space};
${typography};
`, space, typography);
