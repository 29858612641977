import { workspaceLyricsUrl } from '../urls';
import refresh from '../refresh';

export async function getWorkspaceLyrics(workspaceId) {
  return refresh(async () => {
    const response = await fetch(`${workspaceLyricsUrl}?${new URLSearchParams({
      workspace: workspaceId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function putWorkspaceLyrics(workspaceId, lyrics, updateActivity) {
  const data = {
    workspace: workspaceId,
    lyrics,
    updateActivity,
  };
  return refresh(async () => {
    const response = await fetch(workspaceLyricsUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function patchWorkspaceInstrumental(workspaceId, isInstrumental) {
  const data = {
    workspace: workspaceId,
    isInstrumental,
  };
  return refresh(async () => {
    const response = await fetch(workspaceLyricsUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteWorkspaceLyrics(workspaceId) {
  const data = {
    workspace: workspaceId,
  };
  return refresh(async () => {
    const response = await fetch(workspaceLyricsUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
