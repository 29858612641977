/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';

import { patchWorkspaceAccountPublisherPreset } from '@api/restricted/workspace-account-api';

import Box from '@ui-kit/box';
import ArrowLeftIcon from '@assets/icons/arrow-left.svg';
import Icon from '@ui-kit/icon';
import Text from '@ui-kit/typography/text';
import Header from '@ui-kit/typography/header';
import BaseButton from '@ui-kit/buttons/baseButton';
import AlertMessage from '@ui-kit/alert/Alert';
import SearchInput from '@ui-kit/inputs/searchInput';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';
import Spinner from '@ui-kit/loaders/Spinner';

import { StyledWorkspaceList, StyledListItem } from './ManagePublisherOverlayAssignStyles';

function ManagePublisherOverlayAssign({
  selectedPreset,
  workspaceId,
  writerWorkspaces,
  filterWorkspaces,
  setInitialPresetId,
  setManageMode,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [updatingId, setUpdatingID] = useState();

  useEffect(() => {
    filterWorkspaces();
  }, []);

  const handleUpdateAssignment = (event, id) => {
    setUpdatingID(id);
    event.preventDefault();
    setIsLoading(true);
    let presetId;
    if (event.target.checked) {
      presetId = selectedPreset.id;
    } else {
      presetId = null;
    }
    const assignedWorkspaceId = parseInt(id, 10);
    patchWorkspaceAccountPublisherPreset(assignedWorkspaceId, presetId)
      .then((response) => {
        if (response.status === 200) {
          filterWorkspaces().then(() => {
            setError('');
            if (assignedWorkspaceId === workspaceId) {
              if (presetId) {
                setInitialPresetId(presetId);
              } else {
                setInitialPresetId(null);
              }
            }
            setIsLoading(false);
          });
        } else {
          response.json()
            .then((json) => setError(json.error ? json.error : response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return (
    <Box>

      <Box display="flex" alignItems="center" className="cursor-p" onClick={() => setManageMode('listPresets')} mb="1em">
        <Icon size="1.25em" cursor><ArrowLeftIcon /></Icon>
        <Text ml="0.5em">Go Back</Text>
      </Box>

      <Fragment>
        <Header mb="0.25em">Assign Compositions:</Header>
        <Text fontSize="1em" mb="0.75em">{selectedPreset.name}</Text>
      </Fragment>

      <Box mb="1.5em">
        <Box>
          <Text>Add or remove this publishing info on your existing Compositions.</Text>
        </Box>
      </Box>
      <SearchInput
        id="search"
        name="search"
        placeholder="Filter by name..."
        value={searchValue}
        type="text"
        autoComplete="off"
        mb="1.5em"
        fluid
        onChange={(e) => setSearchValue((e.target.value))}
      />

      {writerWorkspaces.length > 0
      && (
      <StyledWorkspaceList>
        {writerWorkspaces.filter((c) => c.name.includes(searchValue)).sort((a, b) => a.name.localeCompare(b.name)).map((composition) => (
          <StyledListItem>
            <Box>
              <Text>{composition.name}</Text>
              {composition.presetId
                ? (
                  <Text opacity="0.8" fontSize="0.813rem">
                    {composition.presetName}
                  </Text>
                )
                : (
                  <Text opacity="0.8" fontSize="0.813rem">
                    (No Publishing Info)
                  </Text>
                )}
            </Box>
            <Box display="flex" alignItems="center">
              {(isLoading && updatingId === composition.id) && (
                <Spinner size="1em" mr="0.75em" variant="page" />
              )}
              <ToggleSwitch loading={isLoading && updatingId === composition.id} id={composition.id.toString()}>
                <input
                  type="checkbox"
                  name="workspace"
                  disabled={isLoading}
                  id={composition.id.toString()}
                  checked={composition.presetId === selectedPreset.id}
                  onChange={(e) => handleUpdateAssignment(e, composition.id)}
                />
              </ToggleSwitch>

            </Box>
          </StyledListItem>
        ))}
      </StyledWorkspaceList>
      )}

      {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}

      <Box>
        <BaseButton btnText="Done" fluid onClick={() => setManageMode('listPresets')} />
      </Box>

    </Box>
  );
}

ManagePublisherOverlayAssign.propTypes = {
  selectedPreset: PropTypes.object.isRequired,
  workspaceId: PropTypes.number,
  writerWorkspaces: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterWorkspaces: PropTypes.func.isRequired,
  setInitialPresetId: PropTypes.func.isRequired,
  setManageMode: PropTypes.func.isRequired,
};

ManagePublisherOverlayAssign.defaultProps = {
  workspaceId: null,
};

export default ManagePublisherOverlayAssign;
