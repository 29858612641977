/* eslint-disable react/jsx-boolean-value */
import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import { route } from 'preact-router';
import resetGlobalState from '@state/reset';

import { getOrgInviteToken } from '@api/public/org-invite-token-api';
import { postLoginWithEmail } from '@api/public/login-api';
import { postOrgInviteAccept } from '@api/restricted/org-invite-accept-api';
import { postUserRegister } from '@api/public/user-register-api';
import loadAccountProfile from '@actions/loadAccountProfile';

import useErrorOverlay from '@hooks/useErrorOverlay';
import useAuthTokensValidCheck from '@hooks/useAuthTokensValidCheck';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import BaseButton from '@ui-kit/buttons/baseButton';
import Header from '@ui-kit/typography/header';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import Spinner from '@ui-kit/loaders/Spinner';

import LoginForm from '@shared-forms/loginForm/LoginForm';

import ConversationLayout from '@layouts/conversations';

import AccountDetail from '../../auth/register/sections/account-detail';
import PublisherInvitationAccept from './sections/invitationAccept';
import PublisherInvitationSuccess from './sections/invitationSuccess';

function PublisherInvitation() {
  const [orgName, setOrgName] = useState('');
  const [orgUuid, setOrgUuid] = useState('');
  const [inviterName, setInviterName] = useState('');
  const [isNew, setIsNew] = useState(true);

  const [invitationMode, setInvitationMode] = useState('Loading');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const loadOrgInvitation = async (token) => {
    try {
      const response = await getOrgInviteToken(token);
      if (response.status !== 200) {
        const json = await response.json();
        useErrorOverlay({ errorMessage: `${json.error ? json.error : response.statusText} when loading this invitation` });
      } else {
        const json = await response.json();
        setOrgName(json.name);
        setOrgUuid(json.uuid);
        setInviterName(json.inviterName);
        setIsNew(json.isNew);
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading this invitation` });
    }
  };

  useEffect(() => {
    document.title = 'Switchchord - Publisher Invitation';
    const queryParams = new URLSearchParams(window.location.search);
    loadOrgInvitation(queryParams.get('token')).then(() => {
      if (useAuthTokensValidCheck()) {
        loadAccountProfile().then(() => {
          setError('');
          setInvitationMode('Accept');
        });
      } else {
        setError('');
        setInvitationMode('Landing');
      }
    });
  }, []);

  const onBackToLanding = () => {
    setError('');
    setInvitationMode('Landing');
  };

  const onClickLogin = () => {
    setError('');
    setInvitationMode('Auth');
  };

  const onClickRegister = () => {
    setError('');
    setInvitationMode('Register');
  };

  const handleAuthentication = (data) => {
    setIsLoading(true);
    postLoginWithEmail(data.email, data.password)
      .then((response) => {
        setIsLoading(false);
        if (response.status !== 200) {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        } else {
          response.json().then((json) => {
            resetGlobalState();
            localStorage.setItem('accessToken', json.accessToken);
            localStorage.setItem('refreshToken', json.refreshToken);
            loadAccountProfile().then(() => {
              setError('');
              setTimeout(() => {
                setInvitationMode('Accept');
              }, 200);
            });
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const cleanName = (name) => {
    const words = name.trim().split(/\s+/);
    return words.join(' ').replaceAll('@', '');
  };

  const handleAccountDetail = (data) => {
    const cleanedData = {
      firstName: data.firstName ? cleanName(data.firstName) : data.firstName,
      lastName: data.lastName ? cleanName(data.lastName) : data.lastName,
      password: data.password,
    };
    if (!cleanedData.firstName || !cleanedData.lastName) {
      setError('Invalid input');
    } else {
      setIsLoading(true);
      const queryParams = new URLSearchParams(window.location.search);
      postUserRegister(
        queryParams.get('token'),
        `${cleanedData.firstName} ${cleanedData.lastName}`,
        queryParams.get('email'),
        cleanedData.password,
        cleanedData.firstName,
        cleanedData.lastName,
      ).then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          response.json().then((json) => {
            resetGlobalState();
            localStorage.setItem('accessToken', json.accessToken);
            localStorage.setItem('refreshToken', json.refreshToken);
            loadAccountProfile().then(() => {
              setError('');
              setTimeout(() => {
                setInvitationMode('Accept');
              }, 200);
            });
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
    }
  };

  const handleAcceptInvite = () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    postOrgInviteAccept(queryParams.get('token'))
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setError('');
          setInvitationMode('Success');
        } else if (response.status === 400) {
          route('/error/invalidLink');
        } else {
          response.json()
            .then((json) => setError(json.error ? json.error : response.statusText))
            .catch(() => setError(response.statusText));
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const handleSignOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    resetGlobalState();
    setError('');
    setInvitationMode('Landing');
  };

  return (
    <ConversationLayout>
      {(invitationMode === 'Landing' || invitationMode === 'Accept')
        && (
        <ConversationPrompt
          primaryMessage={`${inviterName} invited you to join:`}
          promptLabel="Publisher Invitation"
          secondaryMessage={orgName}
          mb="2em"
          pt="1em"
        />
        )}
      {
        {
          Loading:
  <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
    <Spinner size="2em" variant="page" />
  </Box>,

          Landing:
  <Fragment>
    <Text mb="3em" fontSize="1rem">
      Login to view invitation.  If you are new here, we can create your free account in one quick step.
    </Text>
    <BaseButton
      mb="1.5em"
      btnText="Login"
      fluid
      onClick={onClickLogin}
    />
    <BaseButton
      variant="secondary"
      mb="1.5em"
      btnText="Create My Account"
      fluid
      onClick={onClickRegister}
    />
  </Fragment>,

          Auth:
  <Fragment>
    <Header mb="1em">Welcome Back</Header>
    <LoginForm
      isLoading={isLoading}
      error={error}
      handleAuthentication={handleAuthentication}
    />
    <Box mt="1.5em" display="flex" justifyContent="center">
      <LinkButton
        btnText="Go Back"
        onClick={onBackToLanding}
      />
    </Box>
  </Fragment>,

          Register:
  <Fragment>
    <AccountDetail
      isLoading={isLoading}
      error={error}
      handleAccountDetail={handleAccountDetail}
    />
    <Box mt="2em" display="flex" justifyContent="center">
      <LinkButton
        btnText="Go Back"
        onClick={onBackToLanding}
      />
    </Box>
  </Fragment>,

          Accept: <PublisherInvitationAccept
            handleAcceptInvite={handleAcceptInvite}
            isLoading={isLoading}
            error={error}
            handleSignOut={handleSignOut}
          />,

          Success: <PublisherInvitationSuccess
            isNewUser={isNew}
            orgName={orgName}
            orgUuid={orgUuid}
          />,
        }[invitationMode]
      }
    </ConversationLayout>
  );
}

export default PublisherInvitation;
