import styled from 'styled-components';
import { space, variant } from 'styled-system';

export const StyledAvatar = styled.div(({
  size,
}) => `
  position: relative;
  height:${size || '1.5em'};
  width: ${size || '1.5em'};
`);

export const AvatarWrapper = styled.div(
  ({
    size,
    borderColor,
    borderSize,
    backgroundColor,
  }) => `
    border: ${borderSize || '1px'} solid ${borderColor || 'var(--panel-divider)'};
    height: ${size || '1.5em'};
    width: ${size || '1.5em'};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${backgroundColor || 'var(--empty-avatar-bg)'};
    overflow: hidden;
    ${space};
`,
  space,
  variant({
    variants: {
      emptyState: {
        background: 'var(--panel)',
      },
      featured: {
        backgroundColor: 'var(--panel)',
        border: '1px solid var(--active-nav)',
      },
    },
  }),
);

export const StyledAvatarImg = styled.img`
  height: 105%;
  width: 105%;
  object-fit: cover;
`;

export const AvatarCheckWrapper = styled.div(({
  checkX,
  checkY,
}) => `
    position: absolute;
    right: ${checkX || '-3px'};
    top: ${checkY || '-1px'};
`);
