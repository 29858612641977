import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import Card from '@distinct-components/cards/baseCard';

export const StyledRepCard = styled(Card)`
    background: var(--panel-card);
    min-height: 10em;
    cursor: pointer;
    border: 0.8px solid var(--card-border);
    box-shadow: 2px 4px 11px var(--card-shadow);
`;

export const RepCardGrid = styled.div`
    padding-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5em;

    @media screen and (max-width: ${pointBreaks.lg}) {
    grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
    grid-template-columns: repeat(1, 1fr);
    }
`;
