/* eslint-disable react/no-unescaped-entities */
import AppState from '@state';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';

import { postAccountIdentityVerification } from '@api/restricted/account-identity-verification-api';
import { postAccountCheckoutPayment } from '@api/restricted/account-checkout-api';
import loadAccountProfile from '@actions/loadAccountProfile';

import Box from '@ui-kit/box';
import Spinner from '@ui-kit/loaders/Spinner';
import AlertMessage from '@ui-kit/alert/Alert';

import FullScreenModal from '@layouts/full-screen/full-screen-modal';

import VerificationPayment from './sections/verificationPayment';
import VerificationReady from './sections/verificationReady';

function HumanVerificationStart() {
  const [verificationMode, setVerificationMode] = useState();
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    document.title = 'Human Verification';
    loadAccountProfile().then(() => {
      if (!AppState.paid.value && !AppState.verified.value) {
        setVerificationMode('verify');
        setIsLoadingProfile(false);
      } else if (!AppState.paid.value) {
        setVerificationMode('payment');
        setIsLoadingProfile(false);
      } else {
        setVerificationMode('verified');
        setIsLoadingProfile(false);
      }
    }).catch((err) => {
      setIsLoadingProfile(false);
      setVerificationMode('profileError');
      setError(err.message);
    });
  }, []);

  const onClickVerificationStart = () => {
    const returnUrl = `${window.location.origin}/verify/processing`;
    setError('');
    setIsLoading(true);
    postAccountIdentityVerification(returnUrl).then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if (json.verification_url) {
            window.location.href = json.verification_url;
            setIsLoading(false);
          } else {
            setError('Unable to generate verification url. Try again.');
            setIsLoading(false);
          }
        });
      }
    }).catch((err) => {
      setError(err.message);
      setIsLoading(false);
    });
  };

  const onClickGoToCheckout = () => {
    setError('');
    setIsLoading(true);
    postAccountCheckoutPayment().then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if (json.checkout_url) {
            window.location.href = json.checkout_url;
          } else {
            setError('Unable to generate checkout url. Try again.');
            setIsLoading(false);
          }
        });
      }
    }).catch((err) => {
      setError(err.message);
      setIsLoading(false);
    });
  };

  return (
    <FullScreenModal formWidth="60em" closeFunction={() => route('/profile')}>
      {isLoadingProfile
        ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="24em">
            <Spinner variant="page" size="2em" />
          </Box>
        )
        : (
          <Box>
            {
              {
                verify: <VerificationReady handleVerifyLink={onClickVerificationStart} error={error} isLoading={isLoading} />,
                payment: <VerificationPayment handlePayment={onClickGoToCheckout} error={error} isLoading={isLoading} />,
                verified: <Box display="flex" justifyContent="center"><AlertMessage variant="default" message="You completed your identity verification" width="26em" /></Box>,
                profileError: <Box display="flex" justifyContent="center"><AlertMessage variant="negative" message={error} width="26em" /></Box>,
              }[verificationMode]
            }
          </Box>
        )}

    </FullScreenModal>
  );
}

export default HumanVerificationStart;
