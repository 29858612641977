/* eslint-disable react/forbid-prop-types */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import { useState, useEffect, useLayoutEffect } from 'preact/hooks';
import anime from 'animejs/lib/anime.es';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';

import {
  LyricTag, InvalidLyricTag, OptionTag, OptionGrid, AddLyricTag,
} from './LyricItemStyles';

const lyricsStyle = {
  whiteSpace: 'pre-wrap',
  color: 'var(--text-soft-mute)',
};

const lineBreakRegex = /\r?\n/;
const tagGroupRegex = /^\[#(.*)\]$/;

function LyricItem({
  lyricChunk, index, handleChunkTag, setResetExpanded, resetExpanded, isLoading, activeUser,
}) {
  const [lines, setLines] = useState([]);
  const [isLoadingChunk, setIsLoadingChunk] = useState(true);
  const [tag, setTag] = useState(false);
  const [showEditTagOptions, setShowEditTagOptions] = useState(false);
  const [showAddTagOptions, setShowAddTagOptions] = useState(false);

  const loadAnimation = anime.timeline({
    autoplay: false,
    delay: 0,
  });

  useEffect(() => {
    setShowAddTagOptions(false);
    setShowEditTagOptions(false);
    const tags = ['CHORUS', 'PRE-CHORUS', 'VERSE', 'INTRO', 'OUTRO', 'BRIDGE'];
    const cleanedLines = [];

    const chunkLines = lyricChunk.split(lineBreakRegex);
    chunkLines.forEach((line, lineIndex) => {
      const tagGroup = line.match(tagGroupRegex);
      if (lineIndex === 0 && tagGroup) {
        if (tags.includes(tagGroup[1])) {
          setTag(tagGroup[1]);
        } else {
          setTag('invalid');
        }
      } else {
        if (lineIndex === 0) {
          setTag(false);
        }
        cleanedLines.push(line);
      }
    });
    setLines(cleanedLines);
    setIsLoadingChunk(false);
  }, [lyricChunk]);

  useLayoutEffect(() => {
    setShowAddTagOptions(false);
    setShowEditTagOptions(false);
  }, [resetExpanded]);

  const onToggleAddTagOptions = () => {
    setResetExpanded(!resetExpanded);
    setTimeout(() => {
      setShowAddTagOptions(!showAddTagOptions);
    }, 100);
    setTimeout(() => {
      loadAnimation.add({
        targets: '#options .tag',
        translateY: ['16px', 0],
        opacity: [0, 1],
        duration: 300,
        direction: 'normal',
        delay: anime.stagger(50),
      }, 100);
      loadAnimation.play();
    }, 200);
  };

  const onToggleEditTagOptions = () => {
    setResetExpanded(!resetExpanded);
    setTimeout(() => {
      setShowEditTagOptions(!showEditTagOptions);
    }, 100);
    setTimeout(() => {
      loadAnimation.add({
        targets: '#options .tag',
        translateY: ['16px', 0],
        opacity: [0, 1],
        duration: 300,
        direction: 'normal',
        delay: anime.stagger(50),
      }, 100);
      loadAnimation.play();
    }, 200);
  };

  const onUpdateSectionTag = (newTag) => {
    const newChunk = `${newTag}\n${lines.join('\n')}`;
    handleChunkTag(newChunk, index);
    setTag(newTag.match(tagGroupRegex)[1]);
    setShowEditTagOptions(false);
    setShowAddTagOptions(false);
  };

  return (
    <Box>
      {!isLoadingChunk && (
      <Box style={lyricsStyle} mb="1em">
        {(!tag && activeUser && activeUser.isCanEdit) && <AddLyricTag onClick={onToggleAddTagOptions}>+ Tag</AddLyricTag>}
        {(showAddTagOptions && !isLoading) && (
          <OptionGrid id="options">
            <OptionTag className="tag" onClick={() => onUpdateSectionTag('[#INTRO]')}>Intro</OptionTag>
            <OptionTag className="tag" onClick={() => onUpdateSectionTag('[#VERSE]')}>Verse</OptionTag>
            <OptionTag className="tag" onClick={() => onUpdateSectionTag('[#PRE-CHORUS]')}>Pre Chorus</OptionTag>
            <OptionTag className="tag" onClick={() => onUpdateSectionTag('[#CHORUS]')}>Chorus</OptionTag>
            <OptionTag className="tag" onClick={() => onUpdateSectionTag('[#BRIDGE]')}>Bridge</OptionTag>
            <OptionTag className="tag" onClick={() => onUpdateSectionTag('[#OUTRO]')}>Outro</OptionTag>
          </OptionGrid>
        )}
        {tag && (
          <Box>
            {(tag !== 'invalid') ? (
              <Box>
                {activeUser && activeUser.isCanEdit ? (
                  <LyricTag style={{ cursor: 'pointer' }} onClick={onToggleEditTagOptions}>{tag.toLowerCase()}</LyricTag>
                ) : (
                  <LyricTag>{tag.toLowerCase()}</LyricTag>
                )}
              </Box>
            ) : (
              <Box>
                {activeUser && activeUser.isCanEdit ? (
                  <InvalidLyricTag style={{ cursor: 'pointer' }} onClick={onToggleEditTagOptions}>Invalid Tag</InvalidLyricTag>
                ) : (
                  <InvalidLyricTag>Invalid Tag</InvalidLyricTag>
                )}
              </Box>
            )}
          </Box>
        )}
        {(showEditTagOptions && !isLoading) && (
        <OptionGrid id="options">
          {tag !== 'INTRO' && <OptionTag className="tag" onClick={() => onUpdateSectionTag('[#INTRO]')}>Intro</OptionTag>}
          {tag !== 'VERSE' && <OptionTag className="tag" onClick={() => onUpdateSectionTag('[#VERSE]')}>Verse</OptionTag>}
          {tag !== 'PRE-CHORUS' && <OptionTag className="tag" onClick={() => onUpdateSectionTag('[#PRE-CHORUS]')}>Pre-Chorus</OptionTag>}
          {tag !== 'CHORUS' && <OptionTag className="tag" onClick={() => onUpdateSectionTag('[#CHORUS]')}>Chorus</OptionTag>}
          {tag !== 'BRIDGE' && <OptionTag className="tag" onClick={() => onUpdateSectionTag('[#BRIDGE]')}>Bridge</OptionTag>}
          {tag !== 'OUTRO' && <OptionTag className="tag" onClick={() => onUpdateSectionTag('[#OUTRO]')}>Outro</OptionTag>}
        </OptionGrid>
        )}

        {lines && lines.map((line) => (
          <Text>{line}</Text>
        ))}

      </Box>
      )}
    </Box>
  );
}

LyricItem.propTypes = {
  lyricChunk: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  handleChunkTag: PropTypes.func,
  setResetExpanded: PropTypes.func,
  resetExpanded: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  activeUser: PropTypes.object,
};

LyricItem.defaultProps = {
  handleChunkTag: null,
  setResetExpanded: null,
  resetExpanded: false,
  activeUser: null,
};

export default LyricItem;
