/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import AppState from '@state/AppState';

import useIsoDateFormat from '@hooks/useIsoDateFormat';

import BaseButton from '@ui-kit/buttons/baseButton';
import AlertMessage from '@ui-kit/alert/Alert';
import Span from '@ui-kit/typography/span';
import BreakLine from '@ui-kit/dividers/breakLine';
import Text from '@ui-kit/typography/text';

import {
  LoggedInWrapper,
} from './ConnectPublisherAcceptRequestStyles';

function ConnectPublisherAcceptRequest({
  handleAcceptRequest,
  isLoading,
  error,
  handleSignOut,
  claims,
  registration,
  coPub,
}) {
  const d = useIsoDateFormat;

  const onSubmit = (data) => {
    handleAcceptRequest(data);
  };

  return (
    <Fragment>
      <LoggedInWrapper>
        <Span fontSize="0.75rem" mb="0.25em">
          {AppState.userProfile.email.value}
          &nbsp;&nbsp;
        </Span>
        <Span fontSize="0.75rem" mb="0.25em" cursor style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleSignOut()}>Sign Out</Span>
      </LoggedInWrapper>

      <BreakLine mb="1.5em" color="var(--panel-info-border)" />

      <Text mb="0.813em" fontWeight="800">Credential Terms</Text>

      <Text mb="0.5em" fontWeight="600" fontSize="0.813rem">
        Compositions that this will apply to:
      </Text>
      <Text mb="0.5em" fontSize="0.813rem">
        {claims.validPeriod?.from ? 'All Compositions Created During the Date Range' : 'Only the Compositions you assign to the Publisher'}
      </Text>

      {claims.validPeriod?.from && (
        <Fragment>
          <Text fontSize="0.813rem">
            From Date:
            {' '}
            {d(claims.validPeriod.from)}
          </Text>
          <Text mt="0.5em" fontSize="0.813rem">
            To Date:
            {' '}
            {claims.validPeriod?.to ? d(claims.validPeriod.to) : 'Until Credential Revoked'}
          </Text>
        </Fragment>
      )}

      <BreakLine mb="1.5em" mt="1.5em" color="var(--panel-info-border)" />

      <Text mb="0.813em" fontWeight="800">Publisher Connection</Text>

      <Text mb="0.5em" fontSize="0.813rem" fontWeight="600">
        {registration.name}
      </Text>
      <Text mb="0.5em" fontSize="0.813rem">
        Society:
        {' '}
        {registration.society}
      </Text>
      <Text mb="0.5em" fontSize="0.813rem">
        IPI:
        {' '}
        {registration.ipi}
      </Text>
      <Text mb="1em" fontSize="0.813rem">
        {registration.address}
      </Text>

      <Text mb="0.5em" fontSize="0.813rem">
        Undivided Ownership Interest:
        {' '}
        {claims.undividedOwnership}
        %
      </Text>
      <Text mb="1.75em" fontSize="0.813rem">
        Administration Territory:
        {' '}
        {claims.adminTerritory}
      </Text>

      <BreakLine mb="1.5em" color="var(--panel-info-border)" />

      {coPub?.length > 0 && (
      <Fragment>
        <Text mb="0.813em" fontWeight="800">Your Publishing Information</Text>

        {coPub.map((pub) => (
          <Fragment>
            <Text mb="0.5em" fontSize="0.813rem" fontWeight="600">
              {pub.name}
            </Text>
            <Text mb="0.5em" fontSize="0.813rem">
              Society:
              {' '}
              {pub.society}
            </Text>
            <Text mb="0.5em" fontSize="0.813rem">
              IPI:
              {' '}
              {pub.ipi}
            </Text>
            <Text mb="1em" fontSize="0.813rem">
              {pub.address}
            </Text>

            {pub.share > 0 && (
            <Text mb="1.75em" fontSize="0.813rem">
              Undivided Ownership Interest:
              {' '}
              {pub.share}
              %
            </Text>
            )}

            {pub.territory && (
            <Text mb="1.75em" fontSize="0.813rem">
              Administered Territory:
              {' '}
              {pub.territory}
            </Text>
            )}
          </Fragment>
        ))}

        <BreakLine mb="1.5em" color="var(--panel-info-border)" />
      </Fragment>
      )}

      {claims.deliveryCommitment && (
      <Fragment>
        <Text mb="0.813em" fontWeight="800">Commitment Requirements</Text>

        <Text mb="0.5em" fontSize="0.813rem">
          Compositions to Deliver:
          {' '}
          {claims.deliveryCommitment.totalCompositions}
        </Text>
        <Text mb="0.5em" fontSize="0.813rem">
          Already Delivered to Date:
          {' '}
          {claims.deliveryCommitment.alreadyDelivered}
        </Text>
        <Text mb="0.5em" fontSize="0.813rem">
          Qualifying Percentage:
          {' '}
          {claims.deliveryCommitment.qualifyingPercentage}
          %
        </Text>
        <Text mb="1.75em" fontSize="0.813rem">
          Aggregate Composition Ownership:
          {' '}
          {claims.deliveryCommitment.aggregate?.allowed
            ? `Yes (${claims.deliveryCommitment.aggregate.minimumPercentage
              ? `at least ${claims.deliveryCommitment.aggregate.minimumPercentage}% ownership`
              : 'no minimum ownership'})`
            : 'No'}
        </Text>

        <BreakLine mb="1.5em" color="var(--panel-info-border)" />
      </Fragment>
      )}

      <Text mb="1.875em" fontSize="0.75rem" color="var(--text-medium-mute)">
        Accepting this request will add a Verifiable Credential to your Profile and give this Publisher access to compositions where you add them as an owner or admin.  Verifiable Credentials are like really secure merit badges signed with your digital identity.
      </Text>

      {error && (
      <AlertMessage
        variant="negative"
        message={error}
        mb="1.5em"
      />
      )}

      <BaseButton
        mb="1.5em"
        type="submit"
        btnText="Accept Connection"
        onClick={onSubmit}
        fluid
        isLoading={isLoading}
      />
    </Fragment>
  );
}

ConnectPublisherAcceptRequest.propTypes = {
  handleAcceptRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  handleSignOut: PropTypes.func.isRequired,
  claims: PropTypes.object.isRequired,
  registration: PropTypes.object.isRequired,
  coPub: PropTypes.array,
};

ConnectPublisherAcceptRequest.defaultProps = {
  coPub: null,
};

export default ConnectPublisherAcceptRequest;
