import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import AppState from '@state';
import anime from 'animejs/lib/anime.es';

import loadAccountProfile from '@actions/loadAccountProfile';
import postProfileImageCheck from '@api/sidecar/profile-image-check-api';
import { postAccountProfilePicture } from '@api/restricted/account-profile-picture-api';
import putAccountUserProfile from '@api/restricted/account-user-profile-api';

import useErrorOverlay from '@hooks/useErrorOverlay';

import BaseButton from '@ui-kit/buttons/baseButton';
import Header from '@ui-kit/typography/header';
import AlertMessage from '@ui-kit/alert/Alert';
import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import Icon from '@ui-kit/icon';
import CameraIcon from '@assets/icons/camera-outline.svg';
import Spinner from '@ui-kit/loaders/Spinner';

import FullScreenModal from '@layouts/full-screen/full-screen-modal';

import { COUNTRIES } from '@constants/sharedOptions';
import TIMEZONES from '@constants/timezones';
import NO_NO_WORDS from '@constants/sensitiveContent';

import {
  AvatarWrapper,
  AvatarPreviewWrapper,
  AvatarButtonWrapper,
  CircleProgress,
  InputPrefix,
  StyledBaseInput,
} from './PersonalizationStyles';

function Personalization() {
  const {
    register, handleSubmit, setValue, trigger, formState: { isValid },
  } = useForm({ mode: 'onChange' });
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingImg, setIsUpdatingImg] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [isUserNameOffensive, setIsUserNameOffensive] = useState(false);
  const [publisherUuid, setPublisherUuid] = useState();
  const [isRepMode, setIsRepMode] = useState(false);

  const completeAnimation = anime.timeline({ autoplay: false });

  useEffect(() => {
    document.title = 'Personalize';
    const queryParams = new URLSearchParams(window.location.search);
    const isRep = queryParams.get('isRep');
    if (isRep === 'true') { setIsRepMode(true); }
    setPublisherUuid(queryParams.get('pub'));
    loadAccountProfile().then(() => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let defaultCountry;
      if (timezone === '' || !timezone) {
        defaultCountry = undefined;
      }
      const countryCode = TIMEZONES[timezone].c[0];
      const country = COUNTRIES.filter((c) => c.code === countryCode);
      defaultCountry = country[0].name;
      setValue('username', AppState.userProfile.username.value);
      trigger('username');
      setValue('legalName', AppState.userProfile.legalName.value);
      trigger('legalName');
      setValue('country', defaultCountry);
      trigger('country');
    });
  }, []);

  const cleanName = (name) => {
    const words = name.trim().split(/\s+/);
    return words.join(' ').replaceAll('@', '');
  };

  const onSubmit = (data) => {
    const cleanedData = {
      username: data.username?.replaceAll(/\s/g, '').replaceAll('@', ''),
      legalName: data.legalName ? cleanName(data.legalName) : data.legalName,
      country: data.country,
    };
    if (!cleanedData.username || !cleanedData.legalName) {
      setError('Invalid input');
    } else {
      setIsLoading(true);
      putAccountUserProfile(
        cleanedData.username,
        AppState.userProfile.email.value,
        cleanedData.legalName,
        cleanedData.country,
        AppState.userProfile.firstName.value,
        AppState.userProfile.lastName.value,
      ).then((response) => {
        setIsLoading(false);
        if (response.status === 200 || response.status === 202) {
          response.json().then(() => {
            setError('');
            loadAccountProfile().then(() => {
              if (!publisherUuid) {
                route(`/get-started${isRepMode ? '?isRep=true' : ''}`);
              } else {
                route(`/publisher/${publisherUuid}/dashboard`);
              }
            });
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
    }
  };

  const handleUploadComplete = () => {
    setIsUpdatingImg(false);
    completeAnimation.add({
      opacity: [0, 1],
      targets: '#progress',
      duration: 100,
      direction: 'normal',
    }).add({
      targets: '.circle',
      strokeDashoffset: [anime.strokeDashoffset, 0],
      duration: 400,
      direction: 'normal',
      easing: 'linear',
    }).add({
      targets: '.circle',
      scale: [1, 1.25],
      strokeWidth: [6, 1],
      translateX: 2,
      translateY: 2,
      duration: 800,
      direction: 'normal',
    }).add({
      opacity: [1, 0],
      targets: '#progress',
      duration: 100,
      direction: 'normal',
    }, '-=600')
      .add({
        duration: 500,
        complete: () => {
          completeAnimation.restart();
          completeAnimation.pause();
        },
      });
    completeAnimation.play();
  };

  const convertBase64 = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (err) => {
      reject(err);
    };
  });

  const handleImageUpload = async (event) => {
    setError(null);
    setIsUpdatingImg(true);

    const file = event.target.files[0];
    setPreviewImage(URL.createObjectURL(file));
    if (file.name.includes('.heic')) {
      setError('Heic Images are not supported');
      setIsUpdatingImg(false);
      return;
    }
    if (file.type.split('/')[0] !== 'image') {
      setError('Unsupported File Type');
      setIsUpdatingImg(false);
      return;
    }
    if (file.size >= 10000000) {
      setError('Images need to be less than 10 MB');
      setIsUpdatingImg(false);
      return;
    }

    const base64Img = await convertBase64(file);
    const res = await postProfileImageCheck(base64Img);
    if (res.status !== 200) {
      const json = await res.json();
      if (json.error) {
        useErrorOverlay({ errorMessage: `${json.error} when performing content policy check` });
      } else {
        useErrorOverlay({ errorMessage: `${res.statusText} when performing content policy check` });
      }
    } else {
      setError(null);
      const json = await res.json();
      if (json.adult === 'LIKELY' || json.adult === 'VERY_LIKELY' || json.racy === 'VERY_LIKELY' || json.violence === 'VERY_LIKELY') {
        setError('This Image Does Not Align with Our Content Policies.  Select Another Image');
        setIsUpdatingImg(false);
        return;
      }
    }

    postAccountProfilePicture(file.name, file.type, file.size, file)
      .then((response) => {
        if (response.status !== 200) {
          setPreviewImage(null);
          setIsUpdatingImg(false);
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        } else {
          handleUploadComplete();
        }
      })
      .catch((err) => {
        setPreviewImage(null);
        setIsUpdatingImg(false);
        setError(err.message);
      });
  };

  const checkOffensive = (name) => {
    const lowerName = name.toLowerCase();
    if (NO_NO_WORDS.some((v) => lowerName.includes(v))) {
      setIsUserNameOffensive(true);
    } else {
      setIsUserNameOffensive(false);
    }
  };

  const onUserNameChange = (event) => {
    let userName = event.target.value;
    userName = userName.replace(/[^a-zA-Z0-9 ]/g, '');
    const treatedUserName = userName.trim();
    setValue('username', treatedUserName);
    checkOffensive(treatedUserName);
    trigger('username');
  };

  return (
    <FullScreenModal formWidth="23em">
      <AvatarWrapper>
        <AvatarPreviewWrapper previewImage={previewImage}>
          <CircleProgress>
            <svg id="progress" viewBox="0 0 112 112">
              <circle className="circle" cx="50" cy="50" r="50" stroke="var(--purple-300)" strokeWidth="6" fill="transparent" />
            </svg>
          </CircleProgress>
        </AvatarPreviewWrapper>
        <AvatarButtonWrapper>
          {isUpdatingImg
            ? (
              <Spinner size="1em" width="2px" />
            )
            : (
              <Icon size="0.938em" pt="3px" color="var(--brand-primary)" cursor><CameraIcon /></Icon>
            )}
          <input
            type="file"
            onChange={handleImageUpload}
            disabled={isUpdatingImg}
          />
        </AvatarButtonWrapper>
      </AvatarWrapper>
      <Box display="flex" justifyContent="center" mb="1.5em">
        <Header fontSize="1.25rem">Personalize Your Account</Header>
      </Box>
      <form id="accountForm" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <InputLabel label="User Name" />
        <Box display="flex" alignItems="center" mb={isUserNameOffensive ? '0em' : '1.5em'}>
          <InputPrefix>@</InputPrefix>
          <StyledBaseInput
            {...register('username', { required: true, minLength: 3 })}
            id="username"
            name="username"
            placeholder=""
            onChange={onUserNameChange}
            onPaste={(e) => e.preventDefault()}
            type="text"
            maxLength={30}
            fluid
          />
        </Box>
        {isUserNameOffensive && (
        <InputLabel
          mb="1.5em"
          mt="0.5em"
          color="var(--red-300)"
          label="This username violates our content policy. Choose another"
        />
        ) }
        <Box style={(publisherUuid || isRepMode) ? { display: 'none' } : { display: 'block' }}>
          <InputLabel label="Legal Name for Signing Splits" />
          <BaseInput
            {...register('legalName', { required: true })}
            id="legalName"
            name="legalName"
            type="text"
            fluid
            mb="0.5rem"
          />
          <InputLabel mb="1.75em" color="var(--text-medium-mute)" label="This should include your middle name if you have one" />
        </Box>
        <InputLabel label="Country of Residence" />
        <BaseSelect
          {...register('country', { required: true })}
          id="country"
          name="country"
          fluid
          mb="2.5em"
        >
          {COUNTRIES.map((c) => (
            <option value={c.name}>{c.name}</option>
          ))}
        </BaseSelect>
        {error
      && (
      <AlertMessage
        variant="negative"
        message={error}
        mb="1.5em"
      />
      )}
        <BaseButton
          mb="1.5em"
          type="submit"
          btnText="Next"
          disabled={!isValid && !isUserNameOffensive}
          fluid
          isLoading={isLoading}
        />
      </form>
    </FullScreenModal>
  );
}

export default Personalization;
