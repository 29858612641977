/* eslint-disable no-plusplus */
import PropTypes from 'prop-types';
import { useEffect } from 'preact/hooks';

import anime from 'animejs/lib/anime.es';
import CountDownWrapper from './CountDownStyles';

function CountDown({
  timeInSeconds,
  size,
  color,
  ...restProps
}) {
  const countDownAnimation = anime.timeline({
    autoplay: true,
    loop: false,
  });

  useEffect(() => {
    const time = timeInSeconds * 1000;
    countDownAnimation
      .add({
        targets: '#circle-timer',
        strokeDashoffset: [0, anime.setDashoffset],
        easing: 'linear',
        duration: time,
      });
  }, []);

  return (
    <CountDownWrapper
      {...restProps}
      timeInSeconds={timeInSeconds}
      size={size}
      color={color}
    >
      <svg xmlns="http://www.w3.org/2000/svg" style={{ transformBox: 'fill-box', transformOrigin: '50% 50%', transform: 'rotate(-90deg)' }}>
        <circle id="circle-timer" r="35%" cx="50%" cy="50%" style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }} />
      </svg>
    </CountDownWrapper>
  );
}

CountDown.propTypes = {
  timeInSeconds: PropTypes.number.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

CountDown.defaultProps = {
  size: null,
  color: null,
};

export default CountDown;
