/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import { useState, useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';

import useFileSize from '@hooks/useFileSize';

import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import PdfIcon from '@assets/illustrations/document-pdf.svg';
import DefaultIcon from '@assets/illustrations/document-default.svg';
import ImgIcon from '@assets/illustrations/document-img.svg';
import VideoIcon from '@assets/illustrations/document-video.svg';
import AudioIcon from '@assets/illustrations/document-audio.svg';
import LinkButton from '@ui-kit/buttons/linkButton';
import Text from '@ui-kit/typography/text';
import Spinner from '@ui-kit/loaders/Spinner';
import CountDown from '@distinct-components/feedback/countDown';
import { Fragment } from 'preact';
import {
  FileWrapper,
  FileDetailsWrapper,
  ActionsWrapper,
  FileInformationRow,
  FileDetailRow,
  StyledNoteText,
} from './FileListItemStyles';

function FileList({
  file,
  handleRemoveInvalidFile,
  handleFileDownload,
  handleAddFileNote,
  handleRemoveFile,
  borderBottom,
  activeUser,
  activeFileId,
  setActiveFileId,
}) {
  const [fileToDelete, setFileToDelete] = useState();

  useEffect(() => {
    if (activeFileId !== file.attachmentId) {
      setFileToDelete(null);
    }
  }, [activeFileId]);

  const onClickRemoveFile = (f) => {
    setActiveFileId(f.attachmentId);
    setFileToDelete(f.attachmentId);
    setTimeout(() => {
      setFileToDelete(null);
    }, 5000);
  };

  return (
    <FileWrapper borderBottom={borderBottom}>
      <FileDetailRow>
        <Box display="flex" alignItems="flex-start">
          <Icon pt="0.25em">
            {file.type === 'application/pdf'
              ? <PdfIcon />
              : file.type.split('/')[0] === 'audio'
                ? <AudioIcon />
                : file.type.split('/')[0] === 'image'
                  ? <ImgIcon />
                  : file.type.split('/')[0] === 'video'
                    ? <VideoIcon />
                    : <DefaultIcon />}
          </Icon>
          <FileDetailsWrapper>
            <Text lineHeight="1.7" fontWeight="500">
              {file.name}
            </Text>
            <Box display="flex">
              {file.type === '' && <Text mr="0.75em">Unknown</Text>}
              <Text mr="0.75em">
                {{
                  application: 'Document',
                  image: 'Image',
                  video: 'Video',
                  audio: 'Audio File',
                }[file.type.split('/')[0]]}
              </Text>
              <Text>{useFileSize(file.size)}</Text>
            </Box>
          </FileDetailsWrapper>
        </Box>

        <ActionsWrapper>
          {file.isProcessing
            && <Spinner size="1.25em" variant="page" />}
        </ActionsWrapper>

      </FileDetailRow>

      <FileInformationRow>
        {file.note && (
        <StyledNoteText>
          &quot;
          {file.note}
          &quot;
        </StyledNoteText>
        )}
        {file.invalid && (
        <Text fontSize="0.75rem" mt="0.125em" mb="0.125em" mr="0.25em" color="var(--red-300)">
          {file.invalidReason}
          {' '}
          <LinkButton btnText="Remove" variant="small" color="var(--red-300)" onClick={() => handleRemoveInvalidFile(file)} />
        </Text>
        )}
        {!file.isProcessing && !file.invalid
            && (
              <Box display="flex" mb="0.25em" minHeight="1.25em">
                <LinkButton btnText="Download" variant="small" color="var(--text-medium-mute)" mr="1em" onClick={() => handleFileDownload(file)} />
                {activeUser.isCanEdit
                && (
                  <Fragment>
                    <LinkButton btnText={file.note && file.note.length > 0 ? 'Edit Note' : 'Add Note'} variant="small" color="var(--text-medium-mute)" mr="1em" onClick={() => handleAddFileNote(file)} />
                    <Box display="flex">
                      {fileToDelete === file.attachmentId
                        ? (
                          <Box display="flex">
                            <CountDown mr="0.25em" timeInSeconds={5} size={12} color="var(--red-300)" />
                            <LinkButton btnText="Yes, Remove File" variant="small" color="var(--red-300)" mr="1em" onClick={() => handleRemoveFile(file)} />
                          </Box>
                        )
                        : <LinkButton btnText="Remove File" variant="small" color="var(--text-medium-mute)" mr="1em" onClick={() => onClickRemoveFile(file)} />}
                    </Box>
                  </Fragment>
                )}
              </Box>
            )}
      </FileInformationRow>
    </FileWrapper>
  );
}

FileList.propTypes = {
  file: PropTypes.object.isRequired,
  handleRemoveInvalidFile: PropTypes.func,
  handleFileDownload: PropTypes.func,
  handleAddFileNote: PropTypes.func,
  handleRemoveFile: PropTypes.func,
  borderBottom: PropTypes.bool,
  activeUser: PropTypes.object.isRequired,
  activeFileId: PropTypes.number,
  setActiveFileId: PropTypes.func.isRequired,
};

FileList.defaultProps = {
  handleRemoveInvalidFile: null,
  handleFileDownload: null,
  handleAddFileNote: null,
  handleRemoveFile: null,
  borderBottom: false,
  activeFileId: null,
};

export default FileList;
