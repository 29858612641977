import styled from 'styled-components';
import Box from '@ui-kit/box';
import pointBreaks from '@styles/styleConstants';
import { space } from 'styled-system';

export const StyledTagsWrapper = styled.div`
    padding: 0.5em 0.875em 0.5em 0.5em;
    border-radius: 0.5em;
    color: var(--text-input);
    border: 1px solid var(--input-border);
    transition: all 250ms ease-in-out;
    background-color: var(--input-bg);
    width: 100%;
    min-height: 44px;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    ${space}
`;

export const StyledTagInput = styled.input`
    font-size: 0.875rem;
    color: var(--text-primary);
    background: transparent;
    border: none;
    outline: none;
    flex: 1;
    width: 3em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1rem;
    }
`;

export const InputTag = styled.div`
    background: var(--active-nav);
    color: var(--white);
    padding: 6px 8px 6px 10px;
    border-radius: 0.5em;
    font-size: 0.813rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    align-self: center;
`;

export const ResultsWrapper = styled(Box)`
    background: var(--card);
    position: absolute;
    border: 1px solid var(--border);
    box-shadow: 4px 8px 11px var(--base-shadow);
    transform: translateY(-2.65em);
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    width: 100%;
    z-index: 500;
    max-height: 15em;
    overflow-y: scroll;
`;

export const ResultRow = styled.div`
    display: flex;
    padding: 1em;
    align-items: center;
    cursor: pointer;
    &:last-child {
        margin-bottom: 0em;
    }
`;

export const Result = styled.p`
    font-size: 0.875em;
    margin: 0em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-soft-mute);
`;
