/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import { useLayoutEffect, useRef, useState } from 'preact/hooks';
import anime from 'animejs/lib/anime.es';

import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import RemoveIcon from '@assets/icons/close-solo.svg';
import Text from '@ui-kit/typography/text';
import AlertMessage from '@ui-kit/alert/Alert';

import {
  StyledListInput,
  StyledItemWrapper,
  StyledItem,
  InputWrapper,
  ButtonWrapper,
  IconIndicator,
  StyledAddBtn,
} from './ItemListInputStyles';

function ItemListInput({
  listItems,
  itemLimit,
  handleListChange,
  ...restProps
}) {
  const [itemText, setItemText] = useState();
  const inputRef = useRef(null);
  const [error, setError] = useState(null);

  useLayoutEffect(() => {
    if (itemText && itemText.length > 2) {
      anime({
        targets: '.circle',
        scale: [0, 1.175],
        opacity: [0.2, 0.6, 0],
        duration: 2000,
        direction: 'normal',
        easing: 'easeInOutSine',
        loop: true,
        delay: 1800,
      });
    } else {
      anime({
        targets: '#icon',
        opacity: 1,
        duration: 300,
        easing: 'easeOutQuad',
      });
    }
  }, [itemText]);

  const onClickAddItem = () => {
    setError(null);
    if (listItems.length >= itemLimit) {
      setError('max number of items reached');
      return;
    }
    if (listItems.indexOf(itemText) > -1) {
      setError('item already exists');
      return;
    }
    const allItems = [...listItems];
    allItems.push(itemText);
    handleListChange(allItems);
    inputRef.current.value = null;
    setItemText(null);
  };

  const onClickRemoveItem = (l) => {
    setError(null);
    const newItems = listItems.filter((item) => item !== l);
    handleListChange(newItems);
    inputRef.current.value = null;
    setItemText(null);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 && itemText.length > 0) {
      e.preventDefault();
      onClickAddItem();
    }
  };

  return (
    <Fragment>
      <StyledItemWrapper>
        {listItems && listItems.map((l) => (
          <StyledItem>
            <Text>{l}</Text>
            <Box onClick={() => onClickRemoveItem(l)}>
              <Icon size="0.5em" color="var(--text-soft-mute)" cursor><RemoveIcon /></Icon>
            </Box>
          </StyledItem>
        ))}
      </StyledItemWrapper>
      <InputWrapper>
        <StyledListInput
          {...restProps}
          ref={inputRef}
          itemCount={listItems.length}
          autoComplete="off"
          fluid
          onChange={(e) => setItemText(e.target.value)}
          onKeyPress={(e) => handleKeyPress(e)}
        />
        {itemText && itemText.length > 1
        && (
        <ButtonWrapper onClick={onClickAddItem}>
          <StyledAddBtn>Add</StyledAddBtn>
          <IconIndicator className="circle" />
        </ButtonWrapper>
        )}
      </InputWrapper>
      {error
      && (
      <AlertMessage
        variant="negative"
        message={error}
        mb="1.5em"
      />
      )}
    </Fragment>
  );
}

ItemListInput.propTypes = {
  handleListChange: PropTypes.func.isRequired,
  listItems: PropTypes.array.isRequired,
  itemLimit: PropTypes.number,
};

ItemListInput.defaultProps = {
  itemLimit: 50,
};

export default ItemListInput;
