import { workspaceRejectionUrl } from '../urls';
import refresh from '../refresh';

export default async function postWorkspaceRejection(workspaceId, type, message) {
  const data = {
    workspace: workspaceId,
    type,
    message,
  };
  return refresh(async () => {
    const response = await fetch(workspaceRejectionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
