import { forgotPasswordUrl } from '../urls';

export async function postForgotPassword(email) {
  const data = {
    email,
  };
  const response = await fetch(forgotPasswordUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response;
}

export async function patchForgotPassword(email, token, password) {
  const data = {
    email,
    token,
    password,
  };
  const response = await fetch(forgotPasswordUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response;
}
