/* eslint-disable import/prefer-default-export */
import { adminOrgOnboardUrl } from '../urls';

export async function postOrgOnboarding(orgId, file) {
  const formData = new FormData();

  formData.append('org_id', orgId);
  formData.append('csv_file', file);

  const response = await fetch(adminOrgOnboardUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: formData,
  });
  return response;
}
