import PropTypes from 'prop-types';

import StyledSpinner from './SpinnerSyles';

function Spinner({
  mono,
  size,
  width,
  ...restProps
}) {
  return (
    <StyledSpinner
      mono={mono}
      size={size}
      width={width}
      {...restProps}
    />
  );
}

Spinner.propTypes = {
  mono: PropTypes.bool,
  size: PropTypes.string,
  width: PropTypes.string,
};

Spinner.defaultProps = {
  mono: false,
  size: null,
  width: null,
};

export default Spinner;
