/* eslint-disable import/prefer-default-export */
import { orgInviteTokenUrl } from '../urls';

export async function getOrgInviteToken(token) {
  const url = `${orgInviteTokenUrl}?token=${encodeURIComponent(token)}`;
  const response = await fetch(url, {
    method: 'GET',
  });
  return response;
}
