/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import HeaderRow from './TableHeaderStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function TableHeader({
  children,
  sticky,
  ...restProps
}) {
  return (
    <HeaderRow {...restProps} sticky={sticky}>
      {children}
    </HeaderRow>
  );
}

TableHeader.propTypes = {
  children: childrenPropTypes.isRequired,
  sticky: PropTypes.bool,
};

TableHeader.defaultProps = {
  sticky: false,
};

export default TableHeader;
