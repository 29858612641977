/* eslint-disable eqeqeq */
import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import { route } from 'preact-router';
import AppState from '@state';
import resetGlobalState from '@state/reset';

import { getArtistRepInviteToken } from '@api/public/artist-rep-invite-token-api';
import { postArtistRepsInviteAccept } from '@api/restricted/artist-reps-invite-accept-api';

import { postLoginWithEmail } from '@api/public/login-api';
import { postUserRegister } from '@api/public/user-register-api';
import { postAccountStripePortal } from '@api/restricted/account-portal-api';
import loadAccountProfile from '@actions/loadAccountProfile';

import useErrorOverlay from '@hooks/useErrorOverlay';
import useAuthTokensValidCheck from '@hooks/useAuthTokensValidCheck';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import BaseButton from '@ui-kit/buttons/baseButton';
import Header from '@ui-kit/typography/header';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import Spinner from '@ui-kit/loaders/Spinner';

import LoginForm from '@shared-forms/loginForm/LoginForm';

import ConversationLayout from '@layouts/conversations';

import AccountDetail from '../../auth/register/sections/account-detail';
import RepInvitationAccept from './sections/invitationAccept/RepInvitationAccept';
import RepInvitationIdv from './sections/invitationIdv/RepInvitationIdv';
import RepInvitationSuccess from './sections/invitationSuccess/RepInvitationSuccess';
import RepInvitationProcessIdv from './sections/InvitationProcessIdv/RepInvitationProcessIdv';

function RepInvitation() {
  const [inviterName, setInviterName] = useState('');
  const [isFullAccess, setIsFullAccess] = useState();
  const [isSignSplits, setIsSignSplits] = useState();
  const [isNew, setIsNew] = useState(true);

  const [invitationMode, setInvitationMode] = useState('Loading');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const useToastAlertError = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'error' };
  };

  const loadRepInvitation = async (token) => {
    try {
      const response = await getArtistRepInviteToken(token);
      if (response.status !== 200) {
        const json = await response.json();
        useErrorOverlay({ errorMessage: `${json.error ? json.error : response.statusText} when loading this invitation` });
      } else {
        const json = await response.json();
        setInviterName(json.inviterName);
        setIsFullAccess(json.canEdit);
        setIsSignSplits(json.canSign);
        setIsNew(json.isNew);
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading this invitation` });
    }
  };

  const loadProfileAndSwitchMode = () => {
    loadAccountProfile().then(() => {
      setError('');
      const queryParams = new URLSearchParams(window.location.search);
      const isProcessing = queryParams.get('processing') == 'true';
      const isNewAccount = queryParams.get('new') == 'true';

      if (isNewAccount) {
        setIsNew(true);
      }

      if (AppState.repProfile.maxArtistsReached.value) {
        setInvitationMode('Paywall');
      } else if (isProcessing) {
        setInvitationMode('ProcessIdv');
      } else if (!AppState.verified.value) {
        setInvitationMode('Idv');
      } else {
        setInvitationMode('Accept');
      }
    });
  };

  useEffect(() => {
    document.title = 'Switchchord - Representation';
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    loadRepInvitation(token).then(() => {
      if (useAuthTokensValidCheck()) {
        loadProfileAndSwitchMode();
      } else {
        setError('');
        setInvitationMode('Landing');
      }
    });
  }, []);

  const onBackToLanding = () => {
    setError('');
    setInvitationMode('Landing');
  };

  const onClickLogin = () => {
    setError('');
    setInvitationMode('Auth');
  };

  const onClickRegister = () => {
    setError('');
    setInvitationMode('Register');
  };

  const handleAuthentication = (data) => {
    setIsLoading(true);
    postLoginWithEmail(data.email, data.password)
      .then((response) => {
        setIsLoading(false);
        if (response.status !== 200) {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        } else {
          response.json().then((json) => {
            resetGlobalState();
            localStorage.setItem('accessToken', json.accessToken);
            localStorage.setItem('refreshToken', json.refreshToken);
            setTimeout(() => {
              loadProfileAndSwitchMode();
            }, 200);
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const cleanName = (name) => {
    const words = name.trim().split(/\s+/);
    return words.join(' ').replaceAll('@', '');
  };

  const handleAccountDetail = (data) => {
    const cleanedData = {
      firstName: data.firstName ? cleanName(data.firstName) : data.firstName,
      lastName: data.lastName ? cleanName(data.lastName) : data.lastName,
      password: data.password,
    };
    if (!cleanedData.firstName || !cleanedData.lastName) {
      setError('Invalid input');
    } else {
      setIsLoading(true);
      const queryParams = new URLSearchParams(window.location.search);
      postUserRegister(
        queryParams.get('token'),
        `${cleanedData.firstName} ${cleanedData.lastName}`,
        queryParams.get('email'),
        cleanedData.password,
        cleanedData.firstName,
        cleanedData.lastName,
      ).then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          response.json().then((json) => {
            resetGlobalState();
            localStorage.setItem('accessToken', json.accessToken);
            localStorage.setItem('refreshToken', json.refreshToken);
            setTimeout(() => {
              loadProfileAndSwitchMode();
            }, 200);
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
    }
  };

  const handleRouteToPortal = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const returnUrl = `${window.location.origin}/rep/invitation?${new URLSearchParams({
      email: queryParams.get('email'),
      token: queryParams.get('token'),
    })}`;

    postAccountStripePortal(returnUrl)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            window.location.href = json.portalURL;
          });
        } else {
          response.json()
            .then((json) => useToastAlertError(json.error || response.statusText))
            .catch(() => useToastAlertError(response.statusText));
        }
      })
      .catch((err) => {
        useToastAlertError(err.message);
      });
  };

  const onClickSubscription = () => {
    if (AppState.repProfile.subscription.value) {
      handleRouteToPortal();
    } else {
      route('/rep/subscribe');
    }
  };

  const handleAcceptInvite = (data) => {
    setIsLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    postArtistRepsInviteAccept(token, data.proofFile)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setError('');
          setInvitationMode('Success');
        } else if (response.status === 400) {
          route('/error/invalidLink');
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const handleSignOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    resetGlobalState();
    setError('');
    setInvitationMode('Landing');
  };

  return (
    <ConversationLayout logoPath="/rep/dashboard">
      {(invitationMode === 'Landing' || invitationMode === 'Accept')
        && (
        <ConversationPrompt
          primaryMessage={`${inviterName} invited you to manage their account.`}
          promptLabel="Representation"
          mb="2em"
          pt="1em"
        />
        )}
      {
        {
          Loading:
  <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
    <Spinner size="2em" variant="page" />
  </Box>,

          Landing:
  <Fragment>
    <Text mb="3em" fontSize="1rem">
      Login to view invitation.  If you are new here, we can create your free account in one quick step.
    </Text>
    <BaseButton
      mb="1.5em"
      btnText="Login"
      fluid
      onClick={onClickLogin}
    />
    <BaseButton
      variant="secondary"
      mb="1.5em"
      btnText="Create My Account"
      fluid
      onClick={onClickRegister}
    />
  </Fragment>,

          Auth:
  <Fragment>
    <Header mb="1em">Welcome Back</Header>
    <LoginForm
      isLoading={isLoading}
      error={error}
      handleAuthentication={handleAuthentication}
    />
    <Box mt="1.5em" display="flex" justifyContent="center">
      <LinkButton
        btnText="Go Back"
        onClick={onBackToLanding}
      />
    </Box>
  </Fragment>,

          Register:
  <Fragment>
    <AccountDetail
      isLoading={isLoading}
      error={error}
      handleAccountDetail={handleAccountDetail}
    />
    <Box mt="2em" display="flex" justifyContent="center">
      <LinkButton
        btnText="Go Back"
        onClick={onBackToLanding}
      />
    </Box>
  </Fragment>,

          Paywall:
  <Fragment>
    <ConversationPrompt
      primaryMessage="Upgrade to Continue"
      promptLabel="Limit Reached"
      mb="1em"
      pt="1em"
    />
    <Text mb="3em" fontSize="1rem">
      You have reached the limit of active artists that you can manage on your
      {' '}
      {AppState.repProfile.subscription.value ? 'current' : 'free'}
      {' '}
      subscription. Upgrade to add additional connections. This invitation will still be available after upgrading.
    </Text>
    <BaseButton
      mb="1.5em"
      btnText={AppState.repProfile.subscription.value ? 'Upgrade Your Subscription' : 'View Subscription Options'}
      fluid
      onClick={onClickSubscription}
    />
  </Fragment>,

          Idv: <RepInvitationIdv isNewUser={isNew} />,

          ProcessIdv: <RepInvitationProcessIdv setInvitationMode={setInvitationMode} />,

          Accept: <RepInvitationAccept
            handleAcceptInvite={handleAcceptInvite}
            isLoading={isLoading}
            error={error}
            handleSignOut={handleSignOut}
            isFullAccess={isFullAccess}
            isSignSplits={isSignSplits}
          />,

          Success: <RepInvitationSuccess
            isNewUser={isNew}
          />,
        }[invitationMode]
      }
    </ConversationLayout>
  );
}

export default RepInvitation;
