import styled from 'styled-components';
import {
  space,
  color,
  layout,
  border,
  flexbox,
  position,
} from 'styled-system';

const Box = styled.div(space, color, layout, border, flexbox, position);

export default Box;
