import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseButton from '@ui-kit/buttons/baseButton';

export const AvatarWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
    ${space};

    input {
        display: none;
    }
`;

export const AvatarPreviewWrapper = styled.div(({
  previewImage,
}) => `
    border: 2px solid var(--brand-primary);
    height: 6em;
    width: 6em;
    border-radius: 50%;
    background-image: url('/assets/illustrations/make-it-rain.svg');
    ${previewImage && `background-image: url(${previewImage})`};
    background-size: cover;
    background-position: top center;

    @media screen and (max-width: ${pointBreaks.sm}) {
        height: 6em;
        width: 6em;
    }

    ${space};
`);

export const AvatarButtonWrapper = styled.label`
    height: 40px;
    width: 40px;
    background: var(--background);
    border: 2px solid var(--brand-primary);
    position: absolute;
    border-radius: 50%;
    transform: translate(32px,60px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const CircleProgress = styled.div`
    position: absolute;
    transform: rotate(-90deg) translate(18px, -3px);
    display: block;

    & svg {
        height: 112px;
        width: auto;
        overflow: visible;
        opacity: 0;
        transition: all 2s ease;
       
        .circle {
           transform-origin: 50% 50%;
        }
      }
`;

export const InputPrefix = styled.div`
    color: var(--text-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75em;
    font-size: 1em;
    height: inherit;
    background: var(--input-append-bg);
    border-top-left-radius: 0.345em;
    border-bottom-left-radius: 0.345em;
    height: 42px;
    position: absolute;
    transform: translateX(1px);
`;

export const StyledBaseInput = styled(BaseInput)`
      padding-left: 4em;
`;

export const SubscriptionWrapper = styled.div`
    border: 1px solid var(--border);
    display: flex;
    justify-content: space-between;
    padding: 0.875rem;
    align-items: center;
    border-radius: 16px;
`;

export const StyledSubscriptionButton = styled(BaseButton)`
    font-size: 0.875rem;
    padding: 0.938rem;
    min-width: 5.25rem;
`;
