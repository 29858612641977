/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const ResultsWrapper = styled.div`
    min-height: 20em;
    max-height: 20em;
    overflow-y: scroll;
    margin-bottom: 1.5em;
    background: var(--panel-card);
    border: 1px solid var(--border);
    border-radius: 0.5em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        min-height: 14em;
        max-height: 14em;
    }
`;

export const ResultsItem = styled.div`
    cursor: pointer;
    border-bottom: 0.8px solid var(--panel-divider);
    padding: 0.75em;

    h4 {
        color: var(--text-primary);
        margin: 0em 0em 0.25em 0em;
    }
    p {
        color: var(--text-muted-panel);
        font-size: 0.75rem;
        margin: 0em;
    }
`;
