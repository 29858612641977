import { accountUrl } from '../urls';
import refresh from '../refresh';

export async function getAccount() {
  return refresh(async () => {
    const response = await fetch(accountUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function deleteAccount() {
  const response = await fetch(accountUrl, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return response;
}
