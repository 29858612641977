/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import { patchWorkspaceAccountAlias } from '@api/restricted/workspace-account-api';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import Header from '@ui-kit/typography/header';
import CountDown from '@distinct-components/feedback/countDown';
import AlertMessage from '@ui-kit/alert/Alert';
import Box from '@ui-kit/box';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';

function ManagePersona({
  writer,
  closeFunction,
  refreshWriters,
}) {
  const {
    register, handleSubmit, reset, formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (writer.alias?.id != null) {
      reset({
        alias: writer.alias.id,
      });
    }
  }, []);

  const handleAddAlias = (data) => {
    const aliasId = data.alias;
    setIsLoading(true);
    patchWorkspaceAccountAlias(
      AppState.composition.id.value,
      aliasId,
    ).then((response) => {
      setIsLoading(false);
      if (response.status === 200) {
        refreshWriters();
        setError(null);
        closeFunction();
      } else {
        response.json()
          .then((json) => setError(json.error || response.statusText))
          .catch(() => setError(response.statusText));
      }
    }).catch((err) => {
      setIsLoading(false);
      setError(err.message);
    });
  };

  const onClickRemoveAlias = () => {
    setShowDeleteConfirmation(true);
    setTimeout(() => {
      setShowDeleteConfirmation(false);
    }, 5000);
  };

  const handleRemoveAlias = () => {
    setIsDeleting(true);
    patchWorkspaceAccountAlias(
      AppState.composition.id.value,
      null,
    ).then((response) => {
      setIsLoading(false);
      if (response.status === 200) {
        refreshWriters();
        setIsDeleting(false);
        setError(null);
        closeFunction();
      } else {
        response.json().then((json) => {
          if (json.error) {
            setError(json.error);
          } else {
            setError(response.statusText);
          }
        });
      }
    }).catch((err) => {
      setIsDeleting(false);
      setError(err.message);
    });
  };

  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>
      <Box>
        {writer.alias
          ? <Header mb="1em">Edit Persona</Header>
          : <Header mb="1em">Apply Persona</Header>}
        <form id="aliasForm" onSubmit={handleSubmit(handleAddAlias)} autoComplete="off">
          <InputLabel label="Select Persona" />
          <BaseSelect
            {...register('alias', { required: true, valueAsNumber: true })}
            id="alias"
            name="alias"
            fluid
            mb="1.5rem"
          >
            <option value={null} default selected>
              None (
              {AppState.userProfile.firstName}
              {' '}
              {AppState.userProfile.lastName}
              )
            </option>
            {AppState.aliases.value.map((a) => (
              <option value={a.id}>{a.name}</option>
            ))}
          </BaseSelect>
          {error && (
            <AlertMessage
              variant="negative"
              message={error}
              mb="1.5em"
            />
          )}
          <BaseButton mb="1.5em" type="submit" btnText="Save" disabled={!isValid} fluid isLoading={isLoading} />
        </form>
        {writer.alias
            && (
            <Box mt="1.75em" display="flex" alignItems="center" justifyContent="center">
              {showDeleteConfirmation && !isDeleting
                ? (
                  <Fragment>
                    <CountDown mr="0.25em" timeInSeconds={5} size={12} color="var(--red-300)" />
                    <LinkButton btnText="Yes, Remove Persona" color="var(--red-300)" mr="1em" onClick={() => handleRemoveAlias()} />
                  </Fragment>
                )
                : <LinkButton type="button" color="var(--red-300)" spinnerVariant="danger" isLoading={isDeleting} btnText="Remove Persona" onClick={onClickRemoveAlias} />}
            </Box>
            )}
      </Box>
    </BaseEditOverlay>
  );
}

ManagePersona.propTypes = {
  writer: PropTypes.object.isRequired,
  closeFunction: PropTypes.func.isRequired,
  refreshWriters: PropTypes.func.isRequired,
};

export default ManagePersona;
