/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ItemPlayButton = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 50px;
    background: var(--player-button);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
`;
