/* eslint-disable import/prefer-default-export */
import { orgCompositionsUrl } from '../urls';
import refresh from '../refresh';

export async function getOrgCompositions(orgId, state) {
  return refresh(async () => {
    const response = await fetch(`${orgCompositionsUrl}?${new URLSearchParams({
      org: orgId,
      page: state?.currentPage || 1,
      num_items: state?.itemsPerPage || 10,
      sort: state?.activeConfig?.sort || 'created',
      order: state?.activeConfig?.isAscending ? 'asc' : 'desc',
      query: state?.activeConfig?.query || '',
      finalized: state?.activeConfig?.toggle == null ? 'all' : state?.activeConfig?.toggle, // == matches both null and undefined
      status: state?.activeConfig?.filters?.status?.map((s) => s.id) || [],
      org_tag: state?.activeConfig?.filters?.internalTags?.map((it) => it.id) || [],
      comp_tag: state?.activeConfig?.filters?.compositionTags?.map((ct) => ct.id) || [],
      connected_pub: state?.activeConfig?.filters?.connectedPublishers?.map((cp) => cp.id) || [],
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}
