/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const StyledDateWrapper = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;

        .dateBox {
            width: 100%;

            input {
                margin-bottom: 1.5em;
            }
        }

        hr {
            display: none;
        }
    }
`;
