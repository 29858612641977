/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'preact/hooks';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import ClearIcon from '@assets/icons/close-solo.svg';

import {
  ResultsWrapper,
  ResultRow,
  Result,
  StyledPillsWrapper,
  StyledPillInput,
  InputPill,
} from './BasePillTypeAheadStyles';

function BasePillTypeAhead({
  availableOptions,
  handleChange,
  selectedOptions,
  optionKey,
}) {
  const [searchValue, setSearchValue] = useState('');
  const [selectedItems, setSelectedItems] = useState(selectedOptions);
  const [excludeOptionIds, setExcludeOptionIds] = useState([]);
  const [focusedNumber, setFocusedNumber] = useState(-1);
  const [focusedItem, setFocusedItem] = useState();
  const [results, setResults] = useState();

  useEffect(() => {
    if (selectedOptions.length > 0) {
      const existingTagIds = [];
      selectedItems.forEach((o) => {
        existingTagIds.push(o.id);
      });
      setExcludeOptionIds(existingTagIds);
    }
  }, []);

  const onSelectItem = (o) => {
    const newTagSet = [...selectedItems, o];
    setSelectedItems(newTagSet);
    setExcludeOptionIds(() => [...excludeOptionIds, o.id]);
    handleChange(newTagSet);
    setSearchValue('');
  };

  const onClickRemoveTag = (o) => {
    const reducedSet = selectedItems.filter((a) => a.id !== o.id);
    const reducedFilter = excludeOptionIds.filter((v) => v !== o.id);
    setSelectedItems(reducedSet);
    handleChange(reducedSet);
    setExcludeOptionIds(reducedFilter);
  };

  const onSearch = (event) => {
    const query = event.target.value;
    const availableTags = availableOptions.filter((o) => !excludeOptionIds.includes(o.id));
    setSearchValue(query);
    if (query !== null) {
      const lowerQuery = query.toLowerCase();
      const resultsToShow = availableTags.filter((o) => o[optionKey].toLowerCase().includes(lowerQuery));
      setResults(resultsToShow.sort((a, b) => a[optionKey].length - b[optionKey].length).sort((a, b) => b[optionKey].startsWith(lowerQuery) - a[optionKey].startsWith(lowerQuery)));
      setFocusedNumber(-1);
      setFocusedItem(null);
    } else {
      setResults(null);
      setFocusedNumber(-1);
      setFocusedItem(null);
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 40 && focusedNumber + 1 < results.length) {
      const focused = results[focusedNumber + 1];
      setFocusedNumber(focusedNumber + 1);
      setFocusedItem(focused);
      return;
    }
    if (event.keyCode === 38 && focusedNumber - 1 >= 0) {
      const focused = results[focusedNumber - 1];
      setFocusedNumber(focusedNumber - 1);
      setFocusedItem(focused);
      return;
    }
    if (event.keyCode === 13) {
      event.preventDefault();
      if (focusedItem) {
        const option = focusedItem;
        const newItemSet = [...selectedItems, option];
        setSelectedItems(newItemSet);
        setExcludeOptionIds(() => [...excludeOptionIds, option.id]);
        handleChange(newItemSet);
        setSearchValue('');
        setFocusedItem(null);
      }
    }
  };

  return (
    <Box style={{ position: 'relative' }}>
      <StyledPillsWrapper mb="0.45em">
        {selectedItems && selectedItems.map((t) => (
          <InputPill color={t.color}>
            {t[optionKey]}
            <Icon ml="0.5em" color="var(--white)" cursor onClick={() => onClickRemoveTag(t)} size="0.5em" style={{ transform: 'translateY(-1px)' }}><ClearIcon /></Icon>
          </InputPill>
        ))}
        <StyledPillInput
          placeholder={selectedItems.length === 0 ? 'Add Option...' : 'Option..'}
          value={searchValue}
          autoComplete="off"
          onKeyDown={onKeyDown}
          onChange={onSearch}
          onBlur={() => setSearchValue('')}
          style={selectedItems.length === 0 ? { paddingLeft: '0.5em' } : { paddingLeft: '0.125em' }}
        />
      </StyledPillsWrapper>

      {searchValue.length > 0
      && (
      <ResultsWrapper>
        {results && results.map((t) => (
          <ResultRow onClick={() => onSelectItem(t)} onMouseDown={(e) => e.preventDefault()} style={t.id === focusedItem?.id && { background: 'var(--input-menu-highlight)' }}>
            <Result>
              {t[optionKey]}
            </Result>
          </ResultRow>
        ))}
        {results && results.length === 0
          && <Text p="1em" color="var(--text-muted-panel)">No Results</Text>}
      </ResultsWrapper>
      )}
    </Box>
  );
}

BasePillTypeAhead.propTypes = {
  availableOptions: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array,
  optionKey: PropTypes.string,
};

BasePillTypeAhead.defaultProps = {
  selectedOptions: null,
  optionKey: 'name',
};

export default BasePillTypeAhead;
