/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const StyledCredentialCardGrid = styled.div`
padding-top: 0.75rem;
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-gap: 1.5em;

@media screen and (max-width: ${pointBreaks.lg}) {
grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: ${pointBreaks.sm}) {
grid-template-columns: repeat(1, 1fr);
}
`;
