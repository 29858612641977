import { workspaceSamplesUrl } from '../urls';
import refresh from '../refresh';

export async function patchWorkspaceHasSamples(workspaceId, hasSamples) {
  const data = {
    workspace: workspaceId,
    hasSamples,
  };
  return refresh(async () => {
    const response = await fetch(workspaceSamplesUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function postWorkspaceSample(workspaceId, name, iswc, writers, performers, description) {
  const data = {
    workspace: workspaceId,
    name,
    iswc,
    writers,
    performers,
    description,
  };
  return refresh(async () => {
    const response = await fetch(workspaceSamplesUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function putWorkspaceSample(workspaceId, sampleId, name, iswc, writers, performers, description) {
  const data = {
    workspace: workspaceId,
    sample: sampleId,
    name,
    iswc,
    writers,
    performers,
    description,
  };
  return refresh(async () => {
    const response = await fetch(workspaceSamplesUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteWorkspaceSample(workspaceId, sampleId) {
  const data = {
    workspace: workspaceId,
    sample: sampleId,
  };
  return refresh(async () => {
    const response = await fetch(workspaceSamplesUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
