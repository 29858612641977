/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const TableSearchEmptyPrompt = styled.div`
    padding: 1em;

    @media screen and (max-width: ${pointBreaks.sm}) {
       text-align: center;
    }
`;

export const TableIllustrationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: ${pointBreaks.sm}) {
        align-items: flex-start;
        margin-bottom: 1.25em;
    }
`;
