import styled from 'styled-components';
import { space, layout } from 'styled-system';
import Text from '@ui-kit/typography/text';
import pointBreaks from '@styles/styleConstants';

export const CardWrapper = styled.div`
    background: var(--panel-card);
    border-radius: 0.5em;
    width: 100%;
    border: 0.8px solid var(--card-border);
    overflow: hidden;
    margin-bottom: 1em;
    position: relative;
    ${space};
    ${layout};

`;

export const CardHeader = styled.div(({
  isActive,
}) => `
    background: ${isActive ? 'var(--vc-card-header-bg)' : 'var(--vc-card-header-inactive-bg)'};
    padding: 1em;
    ${space};
`);

export const CardBodyRow = styled.div(({
  height,
}) => `
    padding: 0.938rem 1rem 0.938rem 0em;
    margin-left: 1em;
    height: ${height};
    overflow-y: scroll;
    ${space};

    &.no-border {
        border: none;
    }
`);

export const StyledHeaderLabel = styled(Text)`
    font-size: 0.688rem;
    font-family: var(--font-secondary);
    color: var(--white);
    font-weight: 600;
    opacity: 0.6;
`;

export const StyledHeaderData = styled(Text)`
    font-size: 0.75rem;
    font-family: var(--font-secondary);
    color: var(--white);
    font-weight: 600;
    opacity: 0.9;
`;

export const StyledBodyLabel = styled(Text)`
    font-size: 0.688rem;
    font-family: var(--font-secondary);
    color: var(--text-primary);
    font-weight: 600;
    opacity: 0.6;
`;

export const StyledBodyData = styled(Text)`
    font-size: 0.813rem;
    font-family: var(--font-secondary);
    color: var(--text-primary);
    font-weight: 600;
    opacity: 0.9;
`;

export const CardBodyRowData = styled.div`
    border-bottom: 1px solid var(--panel-info-border);
    margin-left: 1em;
    padding: 0em 1em 1em 0em;
    cursor: pointer;
    ${space};
`;

export const RowActionsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        justify-content: space-between;
    }
`;

export const RowBadge = styled.div`
    font-size: 12px;
    font-weight: 500;
    height: 24px;
    background: var(--trust);
    padding: 0em 1em;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5em;
    margin-right: 1em;
    color: var(--white);
`;

export const CodeBracketBtn = styled.div(({
  active,
}) => `
    background: ${active ? 'var(--purple-300)' : 'rgb(255 255 255 / 40%)'};
    opacity: 1;
    border-radius: 6px;
    padding: 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    cursor: pointer;
`);

export const ScrollOpacityPad = styled.div`
    height: 32px;
    width: 100%;
    background: var( --vc-scroll-buffer-gradient);
    position: absolute;
    bottom: 0;
    pointer-events: none;
`;

export const CodePreview = styled.pre`
    background: var(--panel);
    border-radius: 0.5em;
    padding: 1em;
    color: var(--text-soft-mute);
    white-space: pre-wrap;
    margin: 0;
    overflow-y: scroll;
    font-size: 0.75rem;
`;
