import styled from 'styled-components';
import {
  space,
} from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const TabRow = styled.div`
  display: flex;
`;

export const StyledTabButton = styled.button(({
  isSelected,
}) => `
  background: ${isSelected ? 'var(--active-nav)' : 'var(--inactive)'};
  min-width: 9em;
  color: ${isSelected ? 'var(--white)' : 'var(--text-soft-mute)'};
  font-size: 0.813rem;
  font-weight: ${isSelected ? '800' : '600'};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5em;
  margin-right: 1em;
  touch-action: manipulation;
  ${space}

  &:disabled {
    cursor: not-allowed;
  }

  @media screen and (max-width: ${pointBreaks.sm}) {
   min-width: 0em;
   padding: 1em 0.65em;
   font-size: 0.75rem;
  }

  &:last-child {
    margin-right: 0em;
  }

  `, space);
