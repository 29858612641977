/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
// import AppState from '@state';

import d from '@hooks/useIsoDateFormat';

import HeaderCard from '@distinct-components/cards/headerCard';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import ContractIcon from '@assets/illustrations/contract-quill.svg';
import SignatureIcon from '@assets/illustrations/single-writer-quill.svg';

function ViewCompositionLegal({ composition }) {
  return (
    <HeaderCard mb="1.5em" headerText="Legal Docs">
      <Box>
        <Box display="flex" alignItems="center">
          <Icon
            mr="0.75em"
            color="var(--white)"
          >
            {composition.writers.length > 1 ? <ContractIcon /> : <SignatureIcon />}
          </Icon>
          <Box>
            <Text fontWeight="600" lineHeight="1">{composition.writers.length > 1 ? 'Split Sheet' : 'Single Writer Attestation'}</Text>
            <Text fontSize="0.75rem" color="var(--text-medium-mute)">
              {composition.finalizedDate ? `${composition.writers.length > 1 ? 'Executed' : 'Signed'} ${d(composition.finalizedDate)}` : 'In Progress'}
            </Text>
          </Box>
        </Box>
      </Box>
    </HeaderCard>
  );
}

ViewCompositionLegal.propTypes = {
  composition: PropTypes.object.isRequired,
};

export default ViewCompositionLegal;
