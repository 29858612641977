/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import { TabRow, StyledTabButton } from './TabsStyles';

function Tabs({
  tabs,
  selectedTab,
  handleTabChange,
  ...restProps
}) {
  return (
    <TabRow {...restProps}>
      {tabs.map((tab) => (
        <StyledTabButton
          onClick={() => handleTabChange(tab)}
          isSelected={tab.value === selectedTab}
        >
          {tab.name}
        </StyledTabButton>
      ))}
    </TabRow>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  selectedTab: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
};

export default Tabs;
