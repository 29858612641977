/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const StyledWorkspaceTitle = styled.p`
    font-size: 1.875rem;
    margin: 0em 0em 0.25em 0em;
    font-family: var(--font-secondary);
    color: var(--text-primary);
    font-weight: 800;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1.65rem;
    }
`;

export const StyledMetadata = styled.p`
    font-size: 0.875rem;
    margin: 0em 0em 0.5em 0em;
    color: var(--text-secondary);
    font-weight: 400;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 0.75rem;
    }
`;

export const InputLockWrapper = styled.div`
    position: absolute;
    top: 8px;
    right: 11px;
`;
