import styled from 'styled-components';
import { space, layout } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const CardWrapper = styled.div(
  () => `
    border-radius: 0.5em;
    width: 100%;
    border: 1px solid var(--panel-divider);
    position: relative;
    overflow: hidden;
    ${space};
    ${layout};
`,
  space,
  layout,
);

export const CardHeader = styled.div`
    background: var(--panel-info);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0em 1em;
    border-bottom: 1px solid var(--panel-divider);

    p {
      text-overflow: ellipsis;
      max-width: 234px;
      white-space: nowrap;
      overflow: hidden;
      padding: 0.5em 0em;
    }
`;

export const CardBody = styled.div(({
  showCursor,
}) => `
    padding: 0.5em 1em;
    cursor: ${showCursor ? 'pointer' : 'default'};
`);

export const StyledDetailsRow = styled.div`
display: flex;
justify-content: space-between;
${space};

@media screen and (max-width: ${pointBreaks.sm}) {
    flex-direction: column;
}
`;

export const StyledDetailsWrapper = styled.div`
display: flex;
${space};

@media screen and (max-width: ${pointBreaks.sm}) {
    flex-direction: column;
}
`;

export const StyledPercentageInput = styled.input`
    height: 2em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0em;
    border: 1px solid var(--input-border);
    border-right: none;
    width: 3.5em;
    padding-left: 0.75em;
    background: var(--input-bg-panel);
    color: var(--text-primary);

    &:disabled {
      cursor: not-allowed;
      background-color: var(--input-disabled);
      color: var(--text-input-disabled);
    }
`;

export const StyledPercentBox = styled.div`
    color: var(--text-primary);
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75em;
    font-size: 1em;
    background: var(--input-append-bg);
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border: 1px solid var(--input-border);
    border-left: none;
`;

export const StyledTerritorySelect = styled.select`
    height: 2em;
    font-size: 1rem;
    border-top-left-radius: 0em;
    border-top-right-radius: 0.5em;
    border-bottom-left-radius: 0em;
    border-bottom-right-radius: 0.5em;
    border: 1px solid var(--input-border);
    border-right: none;
    padding: 0em 2em 0em 0.75em;
    cursor: pointer;
    background: var(--input-bg-panel);
    color: var(--text-primary);
    width: 100%;
    flex-basis: 85%;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjVweCIgaGVpZ2h0PSIxOHB4IiB2aWV3Qm94PSIwIDAgMjUgMTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+R3JvdXAgMjwvdGl0bGU+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtMiI+CiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSIyNSIgaGVpZ2h0PSIxOCI+PC9yZWN0PgogICAgICAgICAgICA8cGF0aCBkPSJNOC44MDQxMzMyOSw3LjIxODA0NjAzIEM5LjA3ODI1MzI5LDYuOTI2NzMzNzkgOS41MjIwNjAxLDYuOTI3NDA0NyA5Ljc5NTQwMzU0LDcuMjE5NTQ0NTUgQzEwLjA0MTQxMjYsNy40ODI0NzA0MiAxMC4wNjU1MDM2LDcuODkxODc3OTIgOS44NjgwMjgyNiw4LjE4MzQ5Njc5IEw5Ljc5Mzk5NzQ1LDguMjc1OTc4MDMgTDYuNDk0OTMyMDgsMTEuNzgxOTU0IEM2LjI0ODcxODg0LDEyLjA0MzYwOTIgNS44NjU0OTEzOCwxMi4wNjk3NzQ3IDUuNTkxODc1MjksMTEuODYwNDUwNSBMNS41MDUwNjc5MiwxMS43ODE5NTQgTDIuMjA2MDAyNTUsOC4yNzU5NzgwMyBDMS45MzE4ODI1NCw3Ljk4NDY2NTc4IDEuOTMxMjUzMDIsNy41MTE2ODQ0MSAyLjIwNDU5NjQ2LDcuMjE5NTQ0NTUgQzIuNDUwNjA1NTUsNi45NTY2MTg2OCAyLjgzNDY4OTk4LDYuOTI5NzgyNjYgMy4xMDg4ODAyNSw3LjEzOTQxMTM0IEwzLjE5NTg2NjcxLDcuMjE4MDQ2MDMgTDYsMTAuMTk4MzE4MiBMOC44MDQxMzMyOSw3LjIxODA0NjAzIFoiIGlkPSJWZWN0b3IiIGZpbGw9IiM5MDY5RkYiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;

    &:disabled {
      cursor: not-allowed;
      background-color: var(--input-disabled);
      color: var(--text-input-disabled);
    }

    :focus {
      border: none;
      outline: none;
    }
    :focus-visible {
      outline: none;
      border: none;
    }
`;

export const StyledTerritoryBox = styled.div`
    color: var(--text-primary);
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75em;
    font-size: 1em;
    background: var(--input-append-bg);
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    border: 1px solid var(--input-border);
    border-left: none;
    flex-basis: 15%;
`;
