import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';

export const StyledHeader = styled.div`
  border-bottom: 1px solid var(--border);
  background: var(--header-background);
  max-width: 1800px;
  margin: 0 auto;
`;

export const HeaderContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 1.75em 1.5em 1.25em 1.5em;

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 1em;
  }

  @media screen and (min-width: ${pointBreaks.xxl}) {
    padding: 1.75em 0.5em 1.25em 0.5em;
  }

`;

export const StyledNameText = styled(Text)`
  font-size: 0.875rem;
  color: var(--text-soft-mute);
  margin-left: 0.625em;
  font-weight: 500;
  font-family: var(--font-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 9em;
  letter-spacing: -0.5px;

  @media screen and (max-width: ${pointBreaks.sm}) {
    display: none;
  }
`;

export const StyledAccountWrapper = styled(Box)`
  cursor: pointer;
`;

export const ActionMenuItem = styled.div`
    border-bottom: 1px solid var(--border);
    color: var(--text-soft-mute);
    font-size: 0.75rem;
    font-weight: 500;
    width: 100%;
    padding-bottom: 1em;
    margin-bottom: 1.125em;
    cursor: pointer;
`;
