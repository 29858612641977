import styled from 'styled-components';
import {
  space,
} from 'styled-system';
import pointBreaks from '@styles/styleConstants';

const HeaderRow = styled.div(({
  sticky,
}) => `
    display: flex;
    flex-direction: row;
    background: var(--table-header-bg);
    border-bottom: 1px solid var(--table-header-border);
    padding: 0.75em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    position: ${sticky ? 'sticky' : 'relative'};
    top: -1px;
    z-index: 100;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        display: none;
    }

    .cell {
        font-size: 0.75rem;
        font-weight: 600;
    }
}
`);

export default HeaderRow;
