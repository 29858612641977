import { useEffect, useState } from 'preact/hooks';
import useAnalytics from '@hooks/useAnalytics';
import { route } from 'preact-router';
import AppState from '@state';
import resetGlobalState from '@state/reset';

import useAuthTokensValidCheck from '@hooks/useAuthTokensValidCheck';

import BaseButton from '@ui-kit/buttons/baseButton';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import EqErrorAnimation from '@animations/general/EqErrorAnimation';
import Card from '@distinct-components/cards/baseCard';
import Spinner from '@ui-kit/loaders/Spinner';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

function ExceptionOverlay() {
  const analytics = useAnalytics();
  const [isLoading, setIsLoading] = useState(true);

  const errorPayload = AppState.exceptions.errorOverlay.value;

  useEffect(() => {
    analytics.track('error-overlay-served');
    if (AppState.exceptions.errorOverlay?.errorMessage.value) {
      analytics.track(`error-overlay-message-${AppState.exceptions.errorOverlay?.errorMessage.value}`);
    }

    if (!AppState.exceptions.errorOverlay?.bypassAuthCheck.value && !useAuthTokensValidCheck()) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      resetGlobalState();
      AppState.deepLinkUrl.value = window.location.pathname + window.location.search;
      route('/login', true);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  });

  return (
    <BaseEditOverlay formCentered>

      {isLoading
        ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="24em">
            <Spinner variant="page" size="2em" />
          </Box>
        )
        : (
          <Box>
            <Box display="flex" justifyContent="center" mb="1em">
              <EqErrorAnimation />
            </Box>
            <Box display="flex" alignItems="center" flexDirection="column" mb="1em">
              <Text fontSize="1.25rem" mb="0.5em" fontWeight="600" style={{ textAlign: 'center' }}>
                {errorPayload.errorTitle || 'That was Unexpected'}
              </Text>
              <Text color="var(--text-soft-mute)" lineHeight="2" mb="1em" style={{ textAlign: 'center' }}>
                {errorPayload.userMessage || 'We captured a log of this error.  Our team uses these logs to prevent things like this in the future.'}
              </Text>
              {errorPayload.errorMessage
              && (
              <Card padding="1em" variant="panel">
                <Text color="var(--text-medium-mute)">
                  {errorPayload.errorMessage}
                </Text>
              </Card>
              )}
            </Box>

            {errorPayload.buttonPath
              ? (
                <BaseButton
                  mt="2em"
                  variant="secondary"
                  btnText={errorPayload.buttonText || 'Go Back'}
                  onClick={() => route(errorPayload.buttonPath)}
                  fluid
                />
              )
              : (
                <BaseButton
                  mt="2em"
                  variant="secondary"
                  btnText={errorPayload.buttonText || 'Go Back'}
                  onClick={() => window.history.back()}
                  fluid
                />
              )}
          </Box>

        )}

    </BaseEditOverlay>
  );
}

export default ExceptionOverlay;
