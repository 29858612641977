import styled from 'styled-components';
import { space, layout } from 'styled-system';
import Span from '@ui-kit/typography/span';
import Text from '@ui-kit/typography/text';
import pointBreaks from '@styles/styleConstants';

export const CardWrapper = styled.div`
    background: var(--card);
    border-radius: 0.5em;
    width: 100%;
    border: 1px solid var(--border);
    ${space};
    ${layout};
`;

export const CardHeader = styled.div`
    border-bottom: 1px solid var(--border);
    padding: 1em;
    ${space};

    @media screen and (max-width: ${pointBreaks.lg}) {
        #contribution-highlight {
            display: none;
        }
     }
`;

export const CardBodyRow = styled.div(({
  isExpanded,
}) => `
    border-bottom: ${!isExpanded ? '1px solid var(--border)' : ''};
    margin-left: 1em;
    padding: ${!isExpanded ? '1em 1em 1em 0em' : '1em 1em 0em 0em'};
    ${space};

    &.no-border {
        border: none;
    }
    &.contribution-row {
        display: none;
    }

    @media screen and (max-width: ${pointBreaks.lg}) {
        &.contribution-row {
            display: block;
        }
     }

    @media screen and (max-width: ${pointBreaks.sm}) {
       #isni {
        margin-top: 0.25em;
       }
    }
`);

export const RowLabelWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (max-width: ${pointBreaks.sm}) {
        margin-bottom: 0.25em;
        align-items: center;
        min-height: 1.875em;
    }
`;

export const StyledRowLabel = styled(Text)`
    font-size: 0.75rem;
    font-family: var(--font-secondary);
    margin-bottom: 0.25em;
    color: var(--text-soft-mute);

    @media screen and (max-width: ${pointBreaks.sm}) {
        margin-bottom: 0em;
    }
`;

export const CardBodyRowData = styled.div`
    border-bottom: 1px solid var(--border);
    margin-left: 1em;
    padding: 0em 1em 1em 0em;
    ${space};
`;

export const RowActionsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transform: translateY(0.75em);
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        justify-content: space-between;
        transform: translateY(0em);
    }
`;

export const StyledLabel = styled(Span)`
    font-size: 0.875rem;
    font-weight: 600;
`;

export const StyledData = styled(Span)`
    font-size: 0.875rem;
`;

export const RowBadge = styled.div`
    font-size: 12px;
    font-weight: 500;
    height: 24px;
    background: var(--trust);
    padding: 0em 1em;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5em;
    margin-right: 1em;
    color: var(--white);
`;
