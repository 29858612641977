// import { useState,  } from 'preact/hooks';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import AppState from '@state';

import Icon from '@ui-kit/icon';
import SwitchLogo from '@assets/logos/switchchord-logo-main.svg';
import Text from '@ui-kit/typography/text';

import {
  StyledCardWrapper,
  IdCardHeader,
  IdCardBody,
  DidText,
} from './WorkspaceIdentityCardStyles';

function WorkspaceIdentityCard({
  shareLink,
}) {
  return (
    <StyledCardWrapper mb="1.5em;">
      <IdCardHeader>
        <Icon size="172px"><SwitchLogo /></Icon>
      </IdCardHeader>
      <IdCardBody>

        <QRCodeSVG
          size={168}
          fgColor="var(--text-soft-mute)"
          bgColor="transparent"
          level="L"
          value={shareLink}
        />
        <Text fontWeight="600" mb="0.5em" mt="1.5em" color="var(--text-primary)" fontSize="0.813rem">COMPOSITION IDENTITY</Text>
        <Text fontWeight="400" mb="1em" color="var(--text-soft-mute)" fontSize="0.875rem">
          &quot;
          {AppState.composition.name.value}
          &quot;
        </Text>
        <DidText mb="1em" className="cursor-p" onClick={() => window.open(shareLink, '_blank')}>
          {AppState.composition.did.value}
        </DidText>
      </IdCardBody>
    </StyledCardWrapper>
  );
}

WorkspaceIdentityCard.propTypes = {
  shareLink: PropTypes.string.isRequired,
};

export default WorkspaceIdentityCard;
