import styled from 'styled-components';

export const ImageUploadButton = styled.label(({
  buttonVisible,
}) => `
    height: 40px;
    width: 40px;
    background: ${buttonVisible ? 'var(--background)' : 'transparent'};
    border: 2px solid ${buttonVisible ? 'var(--brand-primary)' : 'transparent'};
    position: absolute;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-6px);
    cursor: pointer;

    div {
        display: ${buttonVisible ? 'block' : 'none'}
    }

    input {
        display: none;
    }
`);

export const ImageContainer = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
`;
