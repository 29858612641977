/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import AppState from '@state';

import { putOrgCompositionStatus, deleteOrgCompositionStatus } from '@api/restricted/org-composition-status-api';

import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BreakLine from '@ui-kit/dividers/breakLine';

import {
  StyledStatusSelect,
} from './ToolsInternalStatusStyles';

function ToolsInternalStatus({ compositionId, loadInternalData, internalStatus }) {
  const useToastAlert = (message, type) => {
    AppState.messages.toastMessage.value = { text: message, variant: type };
  };

  const handleStatusChange = (event) => {
    if (compositionId < 1) {
      // VAPOR
      alert('Status Updated');
      return;
    }

    const newStatusId = AppState.pubOrganization.customStatuses.value.find((s) => s.name === event.target.value)?.id;

    const updateStatusFunction = async () => (
      newStatusId
        ? putOrgCompositionStatus(AppState.pubOrganization.id.value, compositionId, newStatusId)
        : deleteOrgCompositionStatus(AppState.pubOrganization.id.value, compositionId)
    );

    updateStatusFunction()
      .then((response) => {
        // Load in error case to prevent new option being selected
        loadInternalData(compositionId).then(() => {
          if (response.status === 200) {
            useToastAlert('Status Updated', 'success');
          } else {
            response.json()
              .then((json) => useToastAlert(json.error || response.statusText, 'error'))
              .catch(() => useToastAlert(response.statusText, 'error'));
          }
        });
      })
      .catch((err) => {
        // Load in error case to prevent new option being selected
        loadInternalData(compositionId).then(() => {
          useToastAlert(err.message, 'error');
        });
      });
  };

  return (
    <Box p="1em 1em 0em 1em" mt="0.5em">
      <InputLabel mb="1em" label="Internal Status" />
      <StyledStatusSelect
        statusColor={internalStatus?.color}
        fluid
        variant="small"
        mb="0.25rem"
        value={internalStatus?.name || ''}
        onChange={handleStatusChange}
      >
        <option value="">Add Status</option>
        {AppState.pubOrganization.customStatuses.value.map((s) => (
          <option value={s.name}>{s.name}</option>
        ))}
      </StyledStatusSelect>
      <BreakLine mt="1.5em" color="var(--panel-info-border)" />
    </Box>
  );
}

ToolsInternalStatus.propTypes = {
  compositionId: PropTypes.number.isRequired,
  loadInternalData: PropTypes.func.isRequired,
  internalStatus: PropTypes.object,
};

ToolsInternalStatus.defaultProps = {
  internalStatus: null,
};

export default ToolsInternalStatus;
