import { useEffect, useState } from 'preact/hooks';
import Papa from 'papaparse';

export default function useExportCSV(data, headers) {
  const [csvBlob, setCSVBlob] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!data || !Array.isArray(headers) || headers.length === 0) {
      setError('Invalid data or headers.');
      return;
    }

    const dataArray = [headers, ...data.map((item) => headers.map((header) => item[header]))];
    const csv = Papa.unparse(dataArray);
    const blob = new Blob([csv], { type: 'text/csv' });
    setCSVBlob(blob);
    setError(null);
  }, [data, headers]);

  return { csvBlob, error };
}
