/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'preact/hooks';
import AppState from '@state';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Avatar from '@ui-kit/avatar';

import useWindowDimensions from '@hooks/useWindowDimensions';

import {
  StyledWriterCard,
  WriterCardRow,
  WriterCardDetail,
  WriterCardOwnership,
  SuccessDot,
  WarningDot,
  SignatureChip,
} from './WriterCardStyles';

function WriterCard({
  onClickWriterCard,
  writer,
}) {
  const { width } = useWindowDimensions();
  const [showSigned, setShowSigned] = useState(false);

  useEffect(() => {
    const signatureData = AppState.workspace.writerSignatureData.value || null;
    if (signatureData) {
      const writerSignatureData = signatureData.find((w) => w.id === writer.id);
      if (writerSignatureData.signature !== null) {
        setShowSigned(true);
      }
    }
  }, [AppState.workspace.writerSignatureData.value]);

  return (
    <StyledWriterCard onClick={() => onClickWriterCard(writer)}>
      {showSigned && <SignatureChip>Signed</SignatureChip>}
      <WriterCardRow>
        <WriterCardDetail>
          <Avatar
            size="3.5em"
            borderSize="2px"
            imgURL={writer.imgURL}
            isVerified={writer.isVerified}
            checkSize="1rem"
            checkX="-1px"
            checkY="4px"
          />
          <Box>
            <Text fontWeight="500" fontFamily="var(--font-lexend)" color="var(--text-primary)" lineHeight="1.2" fontSize="0.875rem" maxWidth={width > 400 ? '400px' : '150px'} truncate>
              {writer.firstName}
              {' '}
              {writer.lastName}
            </Text>
            <Box display="flex">
              <Text fontSize="0.813rem" fontWeight="400" color="var(--text-secondary)" mr="1em">
                {writer.contribution || ''}
              </Text>
              {writer.ipi && writer.societies.length > 0 && writer.pubPreset.name
                ? (
                  <Box display="flex" alignItems="center" className="sc-no-mobile">
                    <SuccessDot />
                    <Text color="var(--text-secondary)" fontSize="0.813rem" fontWeight="400">Profile Complete</Text>
                  </Box>
                )
                : (
                  <Box display="flex" alignItems="center" className="sc-no-mobile">
                    <WarningDot />
                    <Text color="var(--text-secondary)" fontSize="0.813rem" fontWeight="400">Profile Incomplete</Text>
                  </Box>
                )}
            </Box>
          </Box>
        </WriterCardDetail>
        <WriterCardOwnership>
          <Text fontSize="1rem" fontWeight="800">
            {writer.share}
            %
          </Text>
        </WriterCardOwnership>
      </WriterCardRow>
    </StyledWriterCard>
  );
}

WriterCard.propTypes = {
  onClickWriterCard: PropTypes.func.isRequired,
  writer: PropTypes.object.isRequired,
};

export default WriterCard;
