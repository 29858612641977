/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
// import { space } from 'styled-system';

export const StatsCardGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5em;
    padding: 2em 2.5em;
    min-height: 260px;

    @media screen and (max-width: ${pointBreaks.sm}) {
        grid-template-columns: repeat(1, 1fr);
        padding: 1.5em;
    }
`;

export const StatsCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 1em;
    border-bottom: 1px solid var(--panel-divider);
    padding: 0.5em 0.75em 0.5em 0em;
`;

export const StatsCardBody = styled.div`
    margin-left: 1em;
    padding: 0.5em 0.75em 0.5em 0em;
`;

export const TagBarWrapper = styled.div(({
  width,
}) => `
    background: var(--chart-line-bg);
    min-height: 2em;
    display: flex;
    width: ${width}%;
    justify-content: space-between;
    align-items: center;
    margin: 0.125em 0em;
    padding: 0em 0.5em;
    border-radius: 0.25em;
    min-width: 0%;
    transform: transition all 800ms;
    animation: animateBar 1s;
    overflow: visible;
    white-space: nowrap;

    @keyframes animateBar {
        from {
          width: 0%;
        }
     }
`);

export const CommitmentContainer = styled.div`
    width: 100%
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6.25em;
    background: var(--chart-progress-bg);
    border-radius: 1em;
    overflow: hidden;
    position: relative;

    & p {
        z-index: 200;
    }
`;

export const CommitmentProgress = styled.div(({
  width,
}) => `
    background: var(--green-200);
    position: absolute;
    width: ${width}%;
    min-width: 1%;
    transition: all 800ms;
    animation: animateBar 1s;
    left: 0;
    height: 100%;

    @keyframes animateBar {
        from {
          width: 0%;
        }
      }
`);
