/* eslint-disable react/forbid-prop-types */
import { useState, useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';
import AppState from '@state';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';
import Box from '@ui-kit/box';
import Spinner from '@ui-kit/loaders/Spinner';

// import { patchAccountPublisherDefault, deleteAccountPublisher } from '@api/restricted/account-publisher-api';
// import { patchWorkspaceAccountPublisher } from '@api/restricted/workspace-account-api';
import loadAccountPublishers from '@actions/loadAccountPublishers';
import loadAccountPublisherPresets from '@actions/loadAccountPublisherPresets';
import loadCompositions from '@actions/loadCompositions';

import ManagePublisherOverlayAssign from './sections/managePublisherOverlayAssign';
import ManagePublisherOverlayCreateEditPreset from './sections/managePublisherOverlayCreateEditPreset';
import ManagePublisherOverlayPresetList from './sections/managePublisherOverlayPresetList';

function ManagePublisherOverlay({
  closeFunction,
  workspaceId,
  initPresetId,
  isAssign,
  showPresetList,
}) {
  const [manageMode, setManageMode] = useState('load');
  const [initialPresetId, setInitialPresetId] = useState(initPresetId);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [writerWorkspaces, setWriterWorkspaces] = useState([]);
  const [numAssociatedWorkspaces, setNumAssociatedWorkspaces] = useState(-1);

  useEffect(() => {
    loadAccountPublishers().then(() => {
      loadAccountPublisherPresets().then(() => {
        if (showPresetList) {
          setManageMode('listPresets');
        } else {
          setSelectedPreset(AppState.publisherPresets.value.filter((preset) => preset.id === initPresetId)[0]);
          if (initPresetId && isAssign) {
            setManageMode('assignPreset');
          } else {
            setManageMode('createEditPreset');
          }
        }
      });
    });
  }, []);

  const handleCloseOverlay = () => {
    AppState.artistProfile.isEditingCurrentPreset.value = false;
    setSelectedPreset(null);
    closeFunction();
  };

  const filterWorkspaces = async () => {
    await loadCompositions();
    const writerComp = AppState.compositions.value.filter((comp) => comp.participant).map((comp) => ({
      id: comp.id,
      name: comp.compositionName,
      presetId: comp.pub_preset.id,
      presetName: comp.pub_preset.name,
    }));
    setWriterWorkspaces(writerComp);
    setNumAssociatedWorkspaces(writerComp.filter((comp) => comp.presetId === selectedPreset.id).length);
  };

  const handlePresetCreatedEdited = () => {
    if (AppState.artistProfile.isEditingCurrentPreset.value) {
      handleCloseOverlay();
    } else if (workspaceId || AppState.publisherPresets.value.length > 1) {
      setManageMode('listPresets');
    } else {
      handleCloseOverlay();
    }
  };

  return (
    <BaseEditOverlay closeFunction={handleCloseOverlay}>
      {
        {
          listPresets: <ManagePublisherOverlayPresetList
            initialPresetId={initialPresetId}
            workspaceId={workspaceId}
            setManageMode={setManageMode}
            setSelectedPreset={setSelectedPreset}
            handleCloseOverlay={handleCloseOverlay}
          />,

          createEditPreset: <ManagePublisherOverlayCreateEditPreset
            selectedPreset={selectedPreset}
            numAssociatedWorkspaces={numAssociatedWorkspaces}
            filterWorkspaces={filterWorkspaces}
            handlePresetCreatedEdited={handlePresetCreatedEdited}
            setManageMode={setManageMode}
          />,

          assignPreset: <ManagePublisherOverlayAssign
            selectedPreset={selectedPreset}
            workspaceId={workspaceId}
            writerWorkspaces={writerWorkspaces}
            filterWorkspaces={filterWorkspaces}
            setInitialPresetId={setInitialPresetId}
            setManageMode={setManageMode}
          />,

          load:
  <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
    <Spinner size="2em" variant="page" />
  </Box>,
        }[manageMode]
      }
    </BaseEditOverlay>
  );
}

ManagePublisherOverlay.propTypes = {
  closeFunction: PropTypes.func.isRequired,
  workspaceId: PropTypes.number,
  initPresetId: PropTypes.number,
  isAssign: PropTypes.bool,
  showPresetList: PropTypes.bool,
};

ManagePublisherOverlay.defaultProps = {
  workspaceId: null,
  initPresetId: null,
  isAssign: false,
  showPresetList: false,
};

export default ManagePublisherOverlay;
