import PropTypes from 'prop-types';

import Box from '@ui-kit/box/';
import CheckMark from '@assets/icons/check-small.svg';
import { FeedbackLabel, CheckWrapper } from './PasswordValidationRowStyles';

function PasswordValidationRow({
  charactersValid,
  uppercaseValid,
  lowercaseValid,
  numberValid,
  specialValid,
  ...restProps
}) {
  return (
    <Box mt="0.75rem" display="flex" flexWrap="wrap" width="100%" {...restProps}>
      <Box display="flex" mr="0.75em">
        <FeedbackLabel isValid={charactersValid} mr="0.25em">Length</FeedbackLabel>
        <CheckWrapper isValid={charactersValid}><CheckMark /></CheckWrapper>
      </Box>
      <Box display="flex" mr="0.75em">
        <FeedbackLabel isValid={uppercaseValid} mr="0.25em">Uppercase</FeedbackLabel>
        <CheckWrapper isValid={uppercaseValid}><CheckMark /></CheckWrapper>
      </Box>
      <Box display="flex" mr="0.75em">
        <FeedbackLabel isValid={lowercaseValid} mr="0.25em">Lowercase</FeedbackLabel>
        <CheckWrapper isValid={lowercaseValid}><CheckMark /></CheckWrapper>
      </Box>
      <Box display="flex" mr="0.75em">
        <FeedbackLabel isValid={numberValid} mr="0.25em">Num</FeedbackLabel>
        <CheckWrapper isValid={numberValid}><CheckMark /></CheckWrapper>
      </Box>
      <Box display="flex" mr="0.75em">
        <FeedbackLabel isValid={specialValid} mr="0.25em">Special</FeedbackLabel>
        <CheckWrapper isValid={specialValid}><CheckMark /></CheckWrapper>
      </Box>
    </Box>
  );
}

PasswordValidationRow.propTypes = {
  charactersValid: PropTypes.bool.isRequired,
  uppercaseValid: PropTypes.bool.isRequired,
  lowercaseValid: PropTypes.bool.isRequired,
  numberValid: PropTypes.bool.isRequired,
  specialValid: PropTypes.bool.isRequired,
};

export default PasswordValidationRow;
