/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const TableSearchEmptyPrompt = styled.div`
    padding: 1em;

    @media screen and (max-width: ${pointBreaks.sm}) {
       text-align: center;
    }
`;

export const StyledMenuItem = styled.div`
    border-bottom: 1px solid var(--border);
    color: var(--text-soft-mute);
    font-size: 0.75rem;
    font-weight: 500;
    width: 100%;
    padding-bottom: 1em;
    margin-bottom: 1.125em;
    cursor: pointer;
`;

export const StyledOptionButton = styled.div`
    background: #282c35;
    cursor: pointer;
    padding: 3px 6px;
    border-radius: 4px;
`;
