import styled from 'styled-components';
import Span from '@ui-kit/typography/span';

export const StyledLabel = styled(Span)`
    font-size: 0.875rem;
    font-weight: 600;
`;

export const StyledData = styled(Span)`
    font-size: 0.875rem;
`;
