/* eslint-disable react/forbid-prop-types */
// import AppState from '@state';
import PropTypes from 'prop-types';

import Box from '@ui-kit/box';
import Card from '@distinct-components/cards/baseCard';
import Text from '@ui-kit/typography/text';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState/BaseEmptyState';
import Spinner from '@ui-kit/loaders/Spinner';

import {
  StyledDetailsRow,
  StyledIdentifiersWrapper,
} from './ProfileOverlayPersonasStyles';

function ProfileOverlayPersonas({ personas, isLoading }) {
  return (
    <Box mt="1.5em">
      {isLoading ? (
        <Box>
          <Box width="100%" display="flex" justifyContent="center" alignItems="center" minHeight="260px">
            <Spinner size="1.25em" variant="page" />
          </Box>
        </Box>
      ) : (
        <Box>
          {personas.length === 0 ? (
            <Card variant="panel" mt="1.7" mb="7em">
              <BaseEmptyState
                title="No Alternate Personas Setup"
                message="If the writer has defined alternate personas, they will appear here."
                illustration="personas"
              />
            </Card>
          ) : (
            <Box>
              <Text mb="0.75em">
                Alternate Personas:
                {' '}
                {personas.length}
              </Text>
              <Box>
                <Box minHeight="14em">
                  {personas.reverse().map((persona) => (
                    <Card variant="panel" p="1em" mb="1em">
                      <Text fontWeight="600">
                        {persona.name}
                      </Text>
                      <StyledDetailsRow>
                        <StyledIdentifiersWrapper>
                          {persona.ipi && (
                          <Text fontSize="0.75rem" mr="0.875em">
                            Unique IPI:
                            {' '}
                            {persona.ipi}
                          </Text>
                          )}
                          {persona.isni && (
                          <Text fontSize="0.75rem" mr="0.875em">
                            Unique ISNI:
                            {' '}
                            {persona.isni}
                          </Text>
                          )}
                          {persona.ipn && (
                          <Text fontSize="0.75rem" mr="0.875em">
                            Unique IPN:
                            {' '}
                            {persona.isni}
                          </Text>
                          )}
                        </StyledIdentifiersWrapper>
                      </StyledDetailsRow>
                    </Card>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

ProfileOverlayPersonas.propTypes = {
  personas: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ProfileOverlayPersonas;
