import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'preact/hooks';
import { Fragment } from 'preact';
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';

import * as accountUserAliasApi from '@api/restricted/account-user-alias-api';
import loadAccountProfile from '@actions/loadAccountProfile';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import Header from '@ui-kit/typography/header';
import CountDown from '@distinct-components/feedback/countDown';
import AlertMessage from '@ui-kit/alert/Alert';
import Box from '@ui-kit/box';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
// import PanelDataCard from '@distinct-components/cards/panelDataCard/PanelDataCard';
// import Text from '@ui-kit/typography/text';
// import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';

const maskGenerator = createDefaultMaskGenerator('9999 9999 9999 9999');

function EditAlias({
  selectedAlias,
  closeFunction,
}) {
  const {
    register, setValue, trigger, handleSubmit, formState: { isValid },
  } = useForm({
    mode: 'onChange',
    // defaultValues: {
    //   recordingDefault: false,
    //   compositionDefault: selectedAlias?.defaultComposition,
    // },
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletingAlias, setIsDeletingAlias] = useState(false);
  const [error, setError] = useState(false);
  const [isni, setIsni] = useState('');

  useEffect(() => {
    register('isni', { minLength: 19, maxLength: 19 });
    setValue('isni', selectedAlias?.isni);
    setIsni(selectedAlias?.isni);
  }, []);

  const handleInputChange = (data) => {
    setValue('isni', data);
    trigger('isni');
  };

  const handleEditAlias = (data) => {
    if (!data.ipi && !data.isni) {
      setError('At least one unique identifier is needed to save this persona.');
    } else {
      setIsLoading(true);
      let formattedIsni = data.isni;
      if (data.isni) {
        const trimmed = data.isni.replace(/\s/g, '');
        const sections = trimmed.match(/.{1,4}/g);
        formattedIsni = sections.join(' ');
      }
      accountUserAliasApi.putAccountUserAlias(
        selectedAlias.id,
        data.aliasName,
        data.ipi,
        '',
        formattedIsni,
        false,
      ).then((response) => {
        setIsLoading(false);
        if (response.status !== 200) {
          response.json()
            .then((json) => setError(json.error ? json.error : response.statusText))
            .catch(() => setError(response.statusText));
        } else {
          closeFunction();
          loadAccountProfile();
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
    }
  };

  const onClickRemoveAlias = () => {
    setShowDeleteConfirmation(true);
    setTimeout(() => {
      setShowDeleteConfirmation(false);
    }, 5000);
  };

  const handleDeleteAlias = (aliasId) => {
    setIsDeletingAlias(true);
    accountUserAliasApi.deleteAccountUserAlias(
      aliasId,
    ).then((response) => {
      setIsDeletingAlias(false);
      if (response.status !== 200) {
        response.json().then((json) => {
          if (json.error) {
            setError(json.error);
          } else {
            setError(response.statusText);
          }
        });
      } else {
        loadAccountProfile();
        closeFunction();
      }
    }).catch((err) => {
      setIsDeletingAlias(false);
      setError(err.message);
    });
  };

  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>
      <Header mb="0.25em" fontSize="1.125rem">Edit Persona</Header>
      {/* <Header mb="1em" fontWeight="300">{selectedAlias.name}</Header> */}
      <form onSubmit={handleSubmit(handleEditAlias)} autoComplete="on">
        <InputLabel label="Persona Name" />
        <BaseInput
          {...register('aliasName', { required: true })}
          id="aliasName"
          name="aliasName"
          placeholder="Persona or Stage Name"
          defaultValue={selectedAlias.name}
          type="text"
          fluid
          mb="1.5rem"
        />
        <InputLabel label="Unique IPI" />
        <BaseInput
          {...register('ipi', { required: false })}
          id="ipi"
          name="ipi"
          defaultValue={selectedAlias?.ipi}
          type="text"
          inputMode="decimal"
          placeholder="9-11 Digit IPI"
          pattern="[0-9]+"
          minLength="9"
          maxLength="11"
          fluid
          mb="1.5rem"
        />
        <InputLabel label="Unique ISNI" />
        <MaskedInput
          maskGenerator={maskGenerator}
          className="masked-input"
          value={isni}
          onChange={setIsni}
          onKeyUp={(e) => handleInputChange(e.target.value)}
          id="isni"
          name="isni"
          placeholder="16 Digit ISNI"
          maxLength="19"
          minLength="19"
          type="text"
          inputMode="decimal"
        />
        {/* <PanelDataCard mb="2.5em" p="0.625em 1em">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Text mr="1.25em" fontSize="0.75rem" color="var(--text-primary)">Default for Compositions</Text>
            <ToggleSwitch id="compositionDefault">
              <input
                {...register('compositionDefault', { required: false })}
                type="checkbox"
                name="compositionDefault"
                id="compositionDefault"
                checked={getValues().compositionDefault}
              />
            </ToggleSwitch>
          </Box>
        </PanelDataCard> */}
        {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}
        <BaseButton mb="1.5em" type="submit" btnText="Submit" disabled={!isValid} fluid isLoading={isLoading} />
      </form>
      <Box mt="1.75em" display="flex" alignItems="center" justifyContent="center">
        {showDeleteConfirmation
          ? (
            <Fragment>
              <CountDown mr="0.25em" timeInSeconds={5} size={12} color="var(--red-300)" />
              <LinkButton btnText="Yes, Remove Persona" color="var(--red-300)" mr="1em" onClick={() => handleDeleteAlias(selectedAlias.id)} />
            </Fragment>
          )
          : <LinkButton type="button" color="var(--red-300)" spinnerVariant="danger" isLoading={isDeletingAlias} btnText="Remove Persona" onClick={onClickRemoveAlias} />}
      </Box>
    </BaseEditOverlay>
  );
}

EditAlias.propTypes = {
  selectedAlias: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    ipi: PropTypes.string,
    isni: PropTypes.string,
    ipn: PropTypes.string,
    // defaultComposition: PropTypes.bool.isRequired,
  }).isRequired,
  closeFunction: PropTypes.func.isRequired,
};

export default EditAlias;
