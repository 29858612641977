import styled from 'styled-components';
import {
  space, variant,
} from 'styled-system';

const StyledSpinner = styled.div(
  ({
    mono,
    size,
    width,
  }) => `
  width: ${size || '32px'};
  height: ${size || '32px'};
  border: ${width || '3px'} solid ${mono ? 'var(--spinner-mono-background)' : 'var(--spinner-primary-background)'};
  border-radius: 50%;
  border-top-color: ${mono ? 'var(--spinner-mono-segment)' : 'var(--spinner-primary-segment)'};
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  ${space}

  @keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
  }
  `,
  space,
  variant({
    variants: {
      danger: {
        border: '3px solid var(--red-400)',
        borderTopColor: 'var(--red-200)',
      },
      fieldLoader: {
        border: '3px solid var(--purple-400)',
        borderTopColor: 'var(--purple-300)',
      },
      page: {
        border: '3px solid var(--purple-300)',
        borderTopColor: 'var(--card)',
      },
    },
  }),
);

export default StyledSpinner;
