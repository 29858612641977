import AppState from '@state';
import { getAccountPublisherPresets } from '@api/restricted/account-publisher-preset-api';
import useErrorOverlay from '@hooks/useErrorOverlay';
import useRoundNumber from '@hooks/useRoundNumber';

export default async function loadAccountPublisherPresets() {
  try {
    const response = await getAccountPublisherPresets();
    if (response.status !== 200) {
      const json = await response.json();
      useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading publisher presets` });
    } else {
      const json = await response.json();
      const formattedPresets = json.presets.map((preset) => {
        const owners = preset.publishers.filter((pub) => pub.share > 0).map(({ territory, share, ...rest }) => ({ ownedPercentage: share, ...rest }));
        const admins = preset.publishers.filter((pub) => pub.territory).map(({ share, ...rest }) => rest);

        return ({
          id: preset.id,
          name: preset.name,
          isDefault: preset.isDefault,
          isVerified: preset.isVerified,
          validPeriod: preset.validPeriod,
          isRevoked: preset.isRevoked,
          publishers: preset.publishers,
          owners,
          admins,
          shareIsValid: useRoundNumber(owners.reduce((n, { ownedPercentage }) => n + ownedPercentage, 0), 2) === 100,
        });
      });
      AppState.publisherPresets.value = formattedPresets;
    }
  } catch (err) {
    useErrorOverlay({ errorMessage: `${err.message} when loading publisher presets` });
  }
}
