import CoreLayout from '@layouts/core/';
import PageCard from '@layouts/pageCard';

function ArtistRecordings() {
  return (
    <CoreLayout globalAlert={null}>
      <PageCard
        title="Recordings"
        subTitle="Prepare your work for the world with anti-fraud and verifiable copyright data"
      >
        Placeholder Child Content
      </PageCard>
    </CoreLayout>
  );
}

export default ArtistRecordings;
