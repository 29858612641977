import PropTypes from 'prop-types';

import {
  ConversationTextWrapper,
  PrimaryText,
  SecondaryText,
  LabelText,
} from './ConversationPromptStyles';

function ConversationPrompt({
  promptLabel,
  labelColor,
  primaryMessage,
  highlightedWord,
  secondaryMessage,
  ...restProps
}) {
  const conversation = primaryMessage;

  const words = conversation.split(' ');
  const treatedWords = [];

  words.forEach((word) => {
    if (word === highlightedWord) {
      const newWord = {
        text: `${word} `,
        isHighlighted: true,
      };
      treatedWords.push(newWord);
    } else {
      const newWord = {
        text: `${word} `,
        isHighlighted: false,
      };
      treatedWords.push(newWord);
    }
  });

  return (
    <ConversationTextWrapper {...restProps}>
      {promptLabel && <LabelText labelColor={labelColor}>{promptLabel}</LabelText>}
      <PrimaryText>
        {treatedWords.map((word) => (
          <span className={word.isHighlighted && 'highlight'}>
            { word.text }
          </span>
        ))}
      </PrimaryText>
      {secondaryMessage && <SecondaryText>{secondaryMessage}</SecondaryText>}
    </ConversationTextWrapper>
  );
}

ConversationPrompt.propTypes = {
  promptLabel: PropTypes.string,
  labelColor: PropTypes.string,
  primaryMessage: PropTypes.string,
  highlightedWord: PropTypes.string,
  secondaryMessage: PropTypes.string,
};

ConversationPrompt.defaultProps = {
  promptLabel: null,
  labelColor: null,
  primaryMessage: null,
  highlightedWord: null,
  secondaryMessage: null,
};

export default ConversationPrompt;
