/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Box from '@ui-kit/box';
import pointBreaks from '@styles/styleConstants';

export const StyledSettingsBox = styled(Box)`
   display: flex;
   justify-content: space-between;
   align-items: flex-start;

   @media screen and (max-width: ${pointBreaks.sm}) {
    flex-direction: column;
    gap: 1em;
   }
`;

export const CenteredSettingsBox = styled(Box)`
   display: flex;
   justify-content: space-between;
   align-items: center;
`;

export const SunWrapper = styled.div`
   position: absolute;
   left: 10px;
   top: 8px;
   z-index: 100;
   pointer-events: none;
`;

export const MoonWrapper = styled.div`
   position: absolute;
   right: 10px;
   top: 8px;
   z-index: 100;
   pointer-events: none;
`;
