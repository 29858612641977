import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const ItemPlayButton = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 50px;
    background: var(--player-button);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
`;

export const FormInputWrapper = styled.div`
    display: flex;

    #name {
        width: 100%;
        margin-right: 1em;
    }
    #type {
        min-width: 12rem;
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;

        #name {
            width: 100%;
        }
        #type {
            width: 100%;
        }
      }
`;
