/* eslint-disable object-shorthand */
/* eslint-disable react/style-prop-object */
import anime from 'animejs/lib/anime.es';
import Box from '@ui-kit/box';
import { useEffect, useState } from 'preact/hooks';

function shareAnimation() {
  const [play, setPlay] = useState(true);
  const [complete, setComplete] = useState(false);
  const [count, setCount] = useState(0);
  const sharingAnimation = anime.timeline({
    autoplay: true,
    loop: false,
  });

  const dotsAnimation = anime.timeline({
    autoplay: false,
    loop: true,
  });

  const waveAnimation = anime.timeline({
    autoplay: false,
    loop: true,
    delay: 5000,
  });

  const replay = () => {
    if (complete) {
      setComplete(false);
      setPlay(false);

      setTimeout(() => {
        setPlay(true);
        setCount(count + 1);
      }, 300);
    }
  };

  useEffect(() => {
    dotsAnimation
      .add({
        targets: '#dot-1, #dot-2',
        translateX: -120,
        opacity: [0, 1],
        duration: 50,
      })
      .add({
        targets: '#dot-1, #dot-2',
        scale: [1, 1.85, 1, 1],
        translateX: [-90, 130],
        duration: 900,
        delay: anime.stagger(700),
        easing: 'easeInOutQuad',
      })
      .add({
        targets: '#like-circle',
        scale: [0, 1.5],
        duration: 900,
        elasticity: 900,
      })
      .add({
        duration: 1200,
      })
      .add({
        targets: '#like-circle',
        translateY: [0, 16],
        opacity: [1, 0],
        scale: [1.5, 0],
        duration: 100,
        easing: 'easeOutQuad',
      });

    waveAnimation
      .add({
        targets: '#inner-wave3, #outer-wave3',
        opacity: [0, 1],
        duration: 100,
      });

    sharingAnimation
      .add({
        targets: '#the-square',
        scale: [1.5, 1.35],
        duration: 900,
        elasticity: 900,
      })
      .add({
        targets: '#the-square',
        translateX: [0, -112],
        duration: 800,
        easing: 'spring(1, 80, 10, 0)',
      }, '-=700')
      .add({
        targets: '#connector',
        scale: [1, 1.3],
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInOutSine',
        duration: 600,
      }, '-=1600')
      .add({
        targets: '#arrow-line',
        opacity: [0, 1],
        scale: 1.3,
        easing: 'linear',
        duration: 100,
      }, '-=600')
      .add({
        targets: '#connector',
        opacity: [1, 0],
        easing: 'linear',
        duration: 300,
      }, '-=400')
      .add({
        targets: '#company',
        scale: [0, 1.5],
        opacity: [0, 1],
        translateX: 26,
        duration: 900,
        easing: 'spring(1, 50, 10, 10)',
      }, '-=800')
      .add({
        targets: '#like-circle',
        opacity: [0, 1],
        duration: 100,
      });

    sharingAnimation.finished.then(() => {
      waveAnimation.play();
      dotsAnimation.play();
      anime({
        targets: '#inner-wave3',
        scale: [0.5, 1.1],
        loop: true,
        delay: 100,
        duration: 4000,
        opacity: [1, 0.5, 0],
        easing: 'easeOutSine',
      });
      anime({
        targets: '#outer-wave3',
        scale: [0.5, 1.3],
        loop: true,
        delay: 100,
        duration: 6000,
        opacity: [1, 0.5, 0],
        easing: 'easeOutSine',
      });
      setComplete(true);
    });
  }, [count]);

  return (
    <Box position="relative" onClick={replay}>
      {play
      && (
      <svg xmlns="http://www.w3.org/2000/svg" width="196" height="100%" viewBox="0 0 370 386" style={{ overflow: 'visible' }}>
        <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="Artboard-Copy-8" transform="translate(-38 -3)">
            {/* SHARING ELEMENTS */}
            <g transform="translate(38 3)">
              <path id="arrow-line" fill="#9069FF" fillRule="nonzero" d="m235.988 190.953 8 4.5.775.436-.775.435-8 4.5-.49-.871 7.225-4.065-7.225-4.064.49-.871Zm-104.563 4.436v1h-5v-1h5Zm10 0v1h-5v-1h5Zm10 0v1h-5v-1h5Zm10 0v1h-5v-1h5Zm10 0v1h-5v-1h5Zm10 0v1h-5v-1h5Zm10 0v1h-5v-1h5Zm10 0v1h-5v-1h5Zm10 0v1h-5v-1h5Zm10 0v1h-5v-1h5Zm10 0v1h-5v-1h5Zm10 0v1h-5v-1h5Z" style="transform-box: fill-box; transform-origin: 50% 50%" />
              <circle id="dot-2" cx="171" cy="196" r="4" fill="var(--green-400)" style="transform-box: fill-box; transform-origin: 50% 50%" />
              <circle id="dot-1" cx="155" cy="196" r="4" fill="var(--green-400)" style="transform-box: fill-box; transform-origin: 50% 50%" />
            </g>
            <g transform="translate(240 2)">
              <circle id="outer-wave3" stroke="#5620CA" cx="139.090515" cy="186.344788" r="137" style="transform-box: fill-box; transform-origin: 50% 50%; opacity: 0;" />
              <circle id="inner-wave3" stroke="#5620CA" strokeWidth="0.89" cx="139.090515" cy="186.344788" r="122.055" style="transform-box: fill-box; transform-origin: 50% 50%; opacity: 0;" />
            </g>
            {/* MAIN SQUARE */}
            <g id="Group-4" transform="translate(38 3)">
              <path id="Rectangle" d="M0 0h370v386H0z" />
              <g id="Group-3" transform="translate(128 138)">
                <g id="the-square" style="transform-box: fill-box; transform-origin: 50% 50%">
                  <rect id="core-square" width="92" height="92" x="11" y="9" fill="#5620CA" rx="5" />
                  <g id="Group" transform="translate(86)">
                    <path id="Path-7" fill="#355DFF" d="m8.859.654 4.16 1.624h.227L17.417 0l2.814 4.062 4.403.411v5.064L28 12.764l-2.77 3.449.625 4.968-4.207 1.092-2.412 4.466-4.366-1.483-4.18 2.109-2.66-3.899-4.63-.583v-4.908L0 14.589l2.855-3.448-.809-4.685 4.616-1.554z" />
                    <ellipse id="Oval" cx="14.022" cy="13.768" stroke="#FAF8FF" strokeWidth="1.4" rx="7.606" ry="7.691" />
                    <path id="Path-8" fill="#FAF8FF" d="M9.998 14.597c1.655 2.29 2.65 3.372 2.986 3.244.336-.129 2.168-2.29 5.494-6.487-.04-.632-.159-1.194-1.071-1.051l-4.423 4.457h-.197l-1.731-1.214c-.874-.195-1.274.106-1.058 1.05Z" />
                    <path id="Path-9" fill="#355DFF" d="m6.065 26.912 1.796-1.099 2.706 3.963 4.313-2.411 4.574 1.589 1.803-2.929.601.302L22.065 43l-8.043-6.805L6.065 43z" />
                  </g>
                  <g id="Group-2" fill="#EDE5FF" transform="translate(38 35)">
                    <path id="music-note2" d="m25.827 7.153 3.12 1.096C30.12 8.656 31 9.949 31 11.255v.871c0 .886-.347 1.659-.96 2.122a2.2 2.2 0 0 1-1.347.45 2.75 2.75 0 0 1-.866-.155l-3.12-1.096-.054-.021v6.541c0 2.22-1.72 4.033-3.826 4.033C18.72 24 17 22.187 17 19.967c0-2.22 1.72-4.032 3.827-4.032.66 0 1.283.178 1.827.491V9.537l.006-.17c.048-.8.387-1.491.954-1.92.613-.463 1.413-.576 2.213-.294Zm-5 10.904c-1 0-1.827.857-1.827 1.924 0 1.054.813 1.925 1.827 1.925 1 0 1.826-.857 1.826-1.925 0-1.067-.826-1.924-1.826-1.924Zm4.12-8.951a.32.32 0 0 0-.173.042c-.064.044-.11.151-.127.292a.943.943 0 0 1 .006.116v1.003c.063.364.372.766.68.879l3.12 1.096c.174.056.334.056.414 0 .08-.056.133-.21.133-.408v-.87c0-.394-.347-.886-.693-1.012l-3.12-1.096a.782.782 0 0 0-.24-.042Z" />
                    <path id="Combined-Shape" fillRule="nonzero" d="M27.34 0C34.107 0 38 3.907 38 10.698v18.604C38 36.093 34.107 40 27.34 40h-9.269c-.76 0-1.39-.633-1.39-1.395 0-.763.63-1.396 1.39-1.396h9.27c5.301 0 7.878-2.586 7.878-7.907V10.698c0-5.321-2.577-7.907-7.879-7.907H12.51c-5.302 0-7.879 2.586-7.879 7.907V23.72c0 .763-.63 1.395-1.39 1.395-.76 0-1.39-.632-1.39-1.395V10.698C1.85 3.907 5.742 0 12.51 0h14.83ZM6.076 28.316c.538.54.538 1.433 0 1.972L3.35 33.023l2.725 2.735c.538.54.538 1.433 0 1.972-.278.28-.63.41-.983.41-.352 0-.704-.13-.982-.41L.403 34.01a1.407 1.407 0 0 1 0-1.973l3.708-3.72a1.395 1.395 0 0 1 1.965 0Zm5.561 0 3.708 3.721c.538.54.538 1.433 0 1.972l-3.708 3.721c-.278.28-.63.41-.982.41s-.705-.13-.983-.41a1.407 1.407 0 0 1 0-1.972l2.726-2.735-2.726-2.735a1.407 1.407 0 0 1 0-1.972 1.395 1.395 0 0 1 1.965 0Z" />
                  </g>
                </g>
                <path id="Rectangle" d="M0 8h11v93H0z" />
              </g>
              {/* COMPANY */}
              <g transform="translate(258 142)">
                <g id="company" style="transform-box: fill-box; transform-origin: 50% 50%; opacity: 0;">
                  <rect id="core-square-copy-8" width="92" height="92" x="0" y="0" fill="#5620CA" rx="46" />
                  <path id="Combined-Shape" transform="translate(-2 -1)" fill="#EDE5FF" d="M59.198 27C63.588 27 66 29.413 66 33.802v25.396C66 63.588 63.587 66 59.198 66H48.314l-.07-.001L33.802 66C29.412 66 27 63.587 27 59.198V44.686c0-3.913 1.917-6.255 5.442-6.717v-2.353a4.986 4.986 0 0 1 4.988-4.988h4.624c.94-2.364 3.082-3.628 6.26-3.628h10.884ZM41.512 40.605h-7.71c-2.866 0-4.081 1.215-4.081 4.081v14.512c0 2.866 1.215 4.081 4.081 4.081l8.705.001c-.553-.9-.88-2.013-.97-3.327l-.008-.12a9.71 9.71 0 0 1-.01-.226l-.003-.087a11.45 11.45 0 0 1-.004-.322V40.605ZM59.198 29.72H48.314c-2.24 0-3.473.743-3.903 2.437a1.05 1.05 0 0 1-.033.175l-.042.172c-.07.388-.103.82-.103 1.297v25.396c0 2.866 1.215 4.081 4.081 4.081h.453v-5.895a3.19 3.19 0 0 1 3.175-3.175h3.628a3.19 3.19 0 0 1 3.174 3.175v5.895h.454c2.866 0 4.081-1.215 4.081-4.081V33.802c0-2.866-1.215-4.081-4.081-4.081ZM55.57 56.93h-3.628a.45.45 0 0 0-.454.453v5.895h4.535v-5.895a.45.45 0 0 0-.453-.454Zm-19.954-9.978c.744 0 1.36.617 1.36 1.361v7.256a1.37 1.37 0 0 1-1.36 1.36 1.37 1.37 0 0 1-1.36-1.36v-7.256c0-.744.617-1.36 1.36-1.36Zm14.512-9.07c.744 0 1.36.617 1.36 1.361v9.07a1.37 1.37 0 0 1-1.36 1.36 1.37 1.37 0 0 1-1.36-1.36v-9.07c0-.744.616-1.36 1.36-1.36Zm7.256 0c.743 0 1.36.617 1.36 1.361v9.07a1.37 1.37 0 0 1-1.36 1.36 1.37 1.37 0 0 1-1.36-1.36v-9.07c0-.744.616-1.36 1.36-1.36ZM41.512 33.35H37.43a2.268 2.268 0 0 0-2.267 2.267v2.268h6.349v-4.535Z" />
                </g>
              </g>
              {/* LIKE CIRCLE */}
              <g transform="translate(376 124)">
                <g id="like-circle" style="transform-box: fill-box; transform-origin: 50% 50%; opacity: 0">
                  <circle id="Oval" cx="13" cy="13" r="13" fill="#355DFF" className="the-circle" />
                  <path id="Combined-Shape" fill="#EDE5FF" fillRule="nonzero" d="M15.184 7.09c.723.244 1.186 1.064 1.024 1.824l-.283 1.871c-.006.042-.006.101.034.148a.16.16 0 0 0 .116.048h2.314c.567 0 1.059.244 1.348.665.284.41.341.95.156 1.473l-1.382 4.325c-.214.861-1.105 1.556-2.037 1.556h-2.198c-.324 0-1.03-.101-1.406-.487l-1.233-.978c-.212.544-.698.812-1.468.812h-.578C8.52 18.347 8 17.83 8 16.772v-5.82c0-1.058.52-1.575 1.59-1.575h.58c.821 0 1.319.305 1.506.925l1.72-2.63c.365-.558 1.163-.825 1.788-.582Zm-1.07 1.075L11.76 11.76v4.744l1.683 1.338c.145.142.509.261.833.261h2.198c.52 0 1.082-.428 1.198-.909l1.4-4.366c.092-.261.075-.499-.047-.67-.127-.185-.358-.292-.642-.292H16.07c-.3 0-.578-.13-.77-.356a1.08 1.08 0 0 1-.236-.862l.289-1.906a.742.742 0 0 0-.463-.814c-.283-.107-.648.047-.775.237Zm-3.945 2.103h-.578c-.63 0-.723.155-.723.683v5.821c0 .53.092.684.723.684h.578c.631 0 .724-.155.724-.684v-5.82c0-.53-.093-.684-.724-.684Z" />
                </g>
              </g>
              <path id="connector" stroke="#9069FF" d="M126.425 196.025h116.818" style="transform-box: fill-box; transform-origin: 50% 50%" />
            </g>
          </g>
        </g>
      </svg>
      )}
    </Box>
  );
}

export default shareAnimation;
