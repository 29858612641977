export default function useTranslateRepActions(actions) {
  return actions.map((action) => {
    const newAction = {
      ...action,
    };

    switch (action.type) {
      case 'account.avatar.update':
        newAction.description = 'Update your profile picture';
        break;
      case 'account.details.update':
        newAction.description = 'Update your account details';
        break;
      case 'account.proxy.end':
        newAction.description = 'End a proxy session to your account';
        break;
      case 'account.proxy.start':
        newAction.description = 'Start a proxy session to your account';
        break;
      case 'account.verification.start':
        newAction.description = 'Start a verification process for your account';
        break;
      case 'composition.amendment.cancel':
        newAction.description = `Cancel an amendment for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.amendment.create':
        newAction.description = `Create an amendment for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.attachment.add':
        newAction.description = `Add an attachment in the composition: ${action.data.compositionName}`;
        break;
      case 'composition.attachment.delete':
        newAction.description = `Delete an attachment in the composition: ${action.data.compositionName}`;
        break;
      case 'composition.attestation.sign':
        newAction.description = `Sign an attestation on your behalf for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.contract.create':
        newAction.description = `Create a split sheet for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.contract.reject':
        newAction.description = `Reject a split sheet for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.contract.sign':
        newAction.description = `Sign a split sheet on your behalf for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.create':
        newAction.description = `Create the composition: ${action.data.compositionName}`;
        break;
      case 'composition.delete':
        newAction.description = `Delete the composition: ${action.data.compositionName}`;
        break;
      case 'composition.details.update':
        newAction.description = `Update details for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.invite.accept':
        newAction.description = `Accept an invitation to join the composition: ${action.data.compositionName}`;
        break;
      case 'composition.invite.create':
        newAction.description = `Create an invitation for a participant to join the composition: ${action.data.compositionName}`;
        break;
      case 'composition.invite.delete':
        newAction.description = `Delete a participant invitation in the composition: ${action.data.compositionName}`;
        break;
      case 'composition.lyrics.delete':
        newAction.description = `Delete the lyrics for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.lyrics.update':
        newAction.description = `Update the lyrics for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.participant.add':
        newAction.description = `Add a participant to the composition: ${action.data.compositionName}`;
        break;
      case 'composition.participant.contribution.update':
        newAction.description = `Update a participant's contribution for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.participant.guest.update':
        newAction.description = `Update a guest participant's information for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.participant.permissions.update':
        newAction.description = `Update a participant's permissions in the composition: ${action.data.compositionName}`;
        break;
      case 'composition.participant.remove':
        newAction.description = `Remove a participant from the composition: ${action.data.compositionName}`;
        break;
      case 'composition.recording.add':
        newAction.description = `Add a recording for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.recording.delete':
        newAction.description = `Delete a recording for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.sample.add':
        newAction.description = `Add a sample for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.sample.remove':
        newAction.description = `Remove a sample for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.sample.update':
        newAction.description = `Update a sample for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.self.contribution.update':
        newAction.description = `Update your contribution for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.self.persona.update':
        newAction.description = `Update your persona for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.self.publisher_preset.update':
        newAction.description = `Update your publisher relationship for the composition: ${action.data.compositionName}`;
        break;
      case 'composition.self.remove':
        newAction.description = `Remove your account from the composition: ${action.data.compositionName}`;
        break;
      case 'profile.identifiers.update':
        newAction.description = 'Update your artist identifiers';
        break;
      case 'profile.persona.create':
        newAction.description = `Create the persona: ${action.data.aliasName}`;
        break;
      case 'profile.persona.delete':
        newAction.description = `Delete the persona: ${action.data.aliasName}`;
        break;
      case 'profile.persona.update':
        newAction.description = `Update the persona: ${action.data.aliasName}`;
        break;
      case 'profile.publisher.create':
        newAction.description = `Create the publisher: ${action.data.publisherName}`;
        break;
      case 'profile.publisher.delete':
        newAction.description = `Delete the publisher: ${action.data.publisherName}`;
        break;
      case 'profile.publisher.update':
        newAction.description = `Update the publisher: ${action.data.publisherName}`;
        break;
      case 'profile.publisher_preset.create':
        newAction.description = `Create the publisher relationship: ${action.data.pubPresetName}`;
        break;
      case 'profile.publisher_preset.delete':
        newAction.description = `Delete the publisher relationship: ${action.data.pubPresetName}`;
        break;
      case 'profile.publisher_preset.make_default':
        newAction.description = `Make the publisher relationship default: ${action.data.pubPresetName}`;
        break;
      case 'profile.publisher_preset.update':
        newAction.description = `Update the publisher relationship: ${action.data.pubPresetName}`;
        break;
      case 'profile.society.add':
        newAction.description = `Add a society affiliation: ${action.data.societyName}`;
        break;
      case 'profile.society.remove':
        newAction.description = 'Remove all society affiliations';
        break;
      case 'publisher.invite.accept':
        newAction.description = `Connect your account with the publisher: ${action.data.regName}`;
        break;
      default:
        newAction.description = '';
    }

    return newAction;
  });
}
