import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const CoreWrapper = styled.div`
  background: var(--background);
`;

export const CorePageWrapper = styled.div`
  margin: 0 auto;
  padding: 0em 2em 0em 238px;

  @media screen and (max-width: ${pointBreaks.md}) {
    padding: 0em 2em 0em 56px;
  }

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 0em;
  }

`;

export const CoreAlertWrapper = styled.div`
  position: sticky;
  background: var(--background);
  width: 100%;
  height: 56px;
  display: flex;
  align-items: flex-end;
  top: 0;

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 0em 1em;
  }
`;

export const MockAlert = styled.div`
  height: 32px;
  background: aqua;
  width: 100%;
  border-radius: 8px;
`;

export const CoreHeaderWrapper = styled.div(({
  globalAlert,
}) => `
  background: var(--background);
  top: ${globalAlert ? '56px' : '0'};
  z-index: 500;
  position: fixed;
  left: 0;
  right: 0;
`);

export const CoreWorkSpaceWrapper = styled.div`
  display: flex;
  padding-top: 7.5em;

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding-top: 5em;
}
`;

export const CoreContentWrapper = styled.div`
  width: 100%;
  margin-left: 32px;

  @media screen and (max-width: ${pointBreaks.sm}) {
      margin-left: 0px;
      padding: 0em;
  }
`;
