/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState } from 'preact/hooks';
import AppState from '@state';

import { postAdminOrgInvite } from '@api/admin/admin-org-invite-api';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';

function AdminCreateOrgInvitation({
  closeFunction,
  organization,
}) {
  const {
    register, handleSubmit, formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [confirmationPhrase, setConfirmationPhrase] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    return emailRegex.test(String(email).toLowerCase());
  };

  const handleSendInvitation = (data) => {
    const email = data.email.toLowerCase();
    if (!validateEmail(email)) {
      setError('Invalid email');
    } else {
      setIsLoading(true);
      postAdminOrgInvite(organization.id, email)
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            // No need to load invites because invites are in another component
            setError('');
            AppState.messages.toastMessage.value = { text: 'Invitation Sent', variant: 'success' };
            closeFunction();
          } else {
            response.json()
              .then((json) => setError(json.error ? json.error : response.statusText))
              .catch(() => setError(response.statusText));
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err.message);
        });
    }
  };

  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>
      <ConversationPrompt
        primaryMessage={organization.name}
        promptLabel="New Invitation"
        mb="1.5em"
      />
      <form onSubmit={handleSubmit(handleSendInvitation)} autoComplete="off">
        <InputLabel label="Invite Email" />
        <BaseInput
          {...register('email', { required: true })}
          id="email"
          name="email"
          placeholder="user@publisher.com"
          fluid
          type="email"
          mb="1.5em"
        />
        <InputLabel label="Confirm Organization Name" />
        <BaseInput
          value={confirmationPhrase}
          onChange={(e) => setConfirmationPhrase(e.target.value)}
          id="phrase"
          name="phrase"
          placeholder="Type organization name to continue..."
          fluid
          type="text"
          mb="2.25em"
        />
        {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}
        {confirmationPhrase.toLowerCase() === organization.name.toLowerCase()
        && <BaseButton mb="1.5em" type="submit" btnText="Save" disabled={!isValid} fluid isLoading={isLoading} />}
      </form>
    </BaseEditOverlay>
  );
}

AdminCreateOrgInvitation.propTypes = {
  closeFunction: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};

export default AdminCreateOrgInvitation;
