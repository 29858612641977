/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import { route } from 'preact-router';
import AppState from '@state';

import BaseButton from '@ui-kit/buttons/baseButton';
import Text from '@ui-kit/typography/text';
import Logo from '@ui-kit/logo/Logo';
import Box from '@ui-kit/box';

function InvitationSuccess({
  isNewUser,
  publisher,
  allWorksCreated,
}) {
  const onClickContinue = () => {
    if (isNewUser) {
      route('/personalize');
    } else {
      route('/home');
      AppState.navigation.backActionPath.value = '/home';
    }
  };
  return (
    <Fragment>
      <Box display="flex" mb="1em">
        <Box mr="1.25em">
          <Logo mb="1em" orgImageURL={publisher.logoUrl} />
        </Box>
        <Box>
          <Text fontSize="0.938rem" fontWeight="800" color="var(--green-400)" lineHeight="1.5">Success!</Text>
          <Text fontSize="1.5em" fontWeight="900" lineHeight="1.45">Connected to Publisher</Text>
          <Text fontSize="0.875">{publisher.name}</Text>
        </Box>
      </Box>
      {allWorksCreated ? (
        <Box>
          <Text mb="2em" fontSize="1rem">This Publishing info is now your default preset and will be added to each new composition.</Text>
          {!isNewUser && <Text mb="2em" fontSize="1rem">You can also manually add it to any past compositions you have documented that need this verified publishing info.</Text>}
        </Box>
      ) : (
        <Box>
          <Text mb="2em" fontSize="1rem">This Publishing Info is now in your profile and can be added manually to any composition.</Text>
          <Text mb="2em" fontSize="1rem">You can also set it as your default Publishing Info if you wish to apply it to every composition.</Text>
        </Box>
      )}
      {isNewUser && <Text fontSize="1rem">Next, finish setting up your account.</Text>}
      <BaseButton
        mt="2em"
        btnText={isNewUser ? 'Finish Setting Up' : 'Continue to App'}
        fluid
        onClick={onClickContinue}
      />
    </Fragment>
  );
}

InvitationSuccess.propTypes = {
  isNewUser: PropTypes.bool.isRequired,
  publisher: PropTypes.object.isRequired,
  allWorksCreated: PropTypes.bool.isRequired,
};

export default InvitationSuccess;
