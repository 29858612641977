import { useState, useEffect } from 'preact/hooks';

import AuthLayout from '@layouts/auth/AuthLayout';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseButton from '@ui-kit/buttons/baseButton';
import Header from '@ui-kit/typography/header';
import Text from '@ui-kit/typography/text';
import AlertMessage from '@ui-kit/alert/Alert';
import Box from '@ui-kit/box/Box';

import SignUpForm from '@shared-forms/signUpForm/SignUpForm';

import postSignup from '@api/public/signup-api';

const AccessCode = process.env.ACCESS_CODE;

function SignUp() {
  const [isLoading, setIsLoading] = useState(false);
  const [betaKey, setBetaKey] = useState(false);
  const [betaKeyValid, setBetaKeyValid] = useState(true);
  const [error, setError] = useState('');

  // TEMP REPORTING FUNCTION

  const checkReportingValue = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tahId = urlParams.get('tahId');

    if (tahId) {
      const existingTahId = localStorage.getItem('tahId');
      const currentTime = new Date();
      const formattedTime = currentTime.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });

      localStorage.setItem('tahId', tahId);

      if (tahId !== existingTahId) {
        const data = {
          tahId,
          timestamp: currentTime.toISOString(),
          friendlyTime: formattedTime,
        };
        try {
          const response = await fetch('https://hooks.zapier.com/hooks/catch/12810267/2b87jtu/', {
            method: 'POST',
            body: JSON.stringify(data),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
        } catch (e) {
          console.error('Error sending data to Zapier:', e);
        }
      }
    }
  };

  useEffect(() => {
    checkReportingValue();
    document.title = 'Switchchord - Sign Up';
  }, []);

  const callPostSignup = async (email) => {
    setIsLoading(true);
    setError('');
    try {
      const response = await postSignup(email);
      if (response.status !== 200) {
        try {
          const json = response.json();
          setError(json.error || response.statusText);
        } catch {
          setError(response.statusText);
        }
        setIsLoading(false);
        return false;
      }
      setIsLoading(false);
      return true;
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
      return false;
    }
  };

  const handleCheckBetaKey = () => {
    setIsLoading(true);
    setError('');
    if (betaKey === AccessCode) {
      setTimeout(() => {
        setBetaKeyValid(true);
        setIsLoading(false);
      }, 300);
    } else {
      setError('Invalid Code');
      setIsLoading(false);
    }
  };

  const handleBetaKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleCheckBetaKey();
    }
  };

  return (
    <AuthLayout>
      {betaKeyValid ? (
        <SignUpForm
          isLoading={isLoading}
          error={error}
          handleSignup={callPostSignup}
        />
      ) : (
        <Box>
          <Header mb="0.5em">Enter Invite Code</Header>
          <Text variant="thinPrompt" mb="1.5em">
            We are currently in a closed beta with select Songwriters. If you have an invite code, enter it below.
          </Text>
          <BaseInput
            placeholder="Invitation Code"
            id="inviteCode"
            name="inviteCode"
            type="text"
            autocomplete="off"
            fluid
            mb="1.5rem"
            onChange={(e) => setBetaKey(e.target.value.toUpperCase())}
            onKeyPress={(e) => handleBetaKeyPress(e)}
          />
          {error && (
            <AlertMessage
              variant="negative"
              message={error}
              mb="1.5em"
            />
          )}
          <BaseButton mb="1.5em" btnText="Continue" onClick={() => handleCheckBetaKey()} fluid isLoading={isLoading} disabled={!betaKey} />
          <Box display="flex" justifyContent="center">
            <Text><a href="/login">Back to Login</a></Text>
          </Box>
        </Box>
      )}
    </AuthLayout>
  );
}

export default SignUp;
