/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';

export const LyricViewWrapper = styled.div`
    white-space: pre-wrap;
    color: var(--text-soft-mute);
    font-size: 0.938rem;
    line-height: 1.6;
    ${space};
`;
