/* eslint-disable import/prefer-default-export */
import { orgAccountsUrl } from '../urls';
import refresh from '../refresh';

export async function getOrgAccounts(orgId) {
  return refresh(async () => {
    const response = await fetch(`${orgAccountsUrl}?${new URLSearchParams({
      org: orgId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function deleteOrgAccount(orgId, accountId) {
  const data = {
    org: orgId,
    account: accountId,
  };
  return refresh(async () => {
    const response = await fetch(orgAccountsUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
