import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import { variant } from 'styled-system';

export const SideBarContainer = styled.div(
  ({
    globalAlert, showMobileMenu,
  }) => `
  width: 100%;
  max-width: 15em;
  background: var(--side-nav-panel);
  border: 0.8px solid var(--card-border);
  box-shadow: 2px 4px 11px var(--card-shadow);
  position: -webkit-sticky;
  position: sticky;
  max-height: 56em;
  height: calc(100vh - 13em);
  border-radius: 1em;
  top: 112px;
  top: ${globalAlert ? '168px' : '112px'};
  display: flex;
  overflow: hidden;

  @media screen and (max-width: ${pointBreaks.sm}) {
    display: ${showMobileMenu ? 'block' : 'block'};
    position: fixed;
    border: none;
    transform: translateX(${showMobileMenu ? '0em' : '-60em'});
    width: 100%;
    max-width: 100%;
    top: 4.175em;
    border-radius: 0em;
    border-top: none;
    max-height: 100%;
    height: 100%;
    z-index: 90000;
    transition: all 500ms ease-in-out;
  }

  @media screen and (min-width: ${pointBreaks.sm}) {
    max-width: 3.5em;
  }

  @media screen and (min-width: ${pointBreaks.md}) {
    max-width: 15em;
  }
`,
  variant({
    variants: {
      traditional: {
        position: 'fixed',
        left: 0,
        top: '90px',
        height: 'calc(100vh - 90px)',
        borderRadius: 0,
        borderRight: '1px solid var(--border)',
        maxHeight: 'inherit',
      },
    },
  }),
);

export const MobileNavToggleWrapper = styled.div`
  display: none;
  position: fixed;
  top: 1.545em;
  right: 1em;

  @media screen and (max-width: ${pointBreaks.sm}) {
    display: block;
    z-index: 700;
  }
`;

export const MobileNavToggle = styled.div`
  display: flex;
  flex-direction: column;
  height: 1.25em;
  justify-content: space-between;
  .bar {
      height: 0.125em;
      width: 1.5em;
      opacity: 1;
      background: var(--text-soft-mute);
      border-radius: 0.5em;
      &.hide {
          opacity: 0;
      }
      &.rotate-forward {
          -webkit-transform: translateY(8px) rotate(45deg);
          transform: translateY(8px) rotate(45deg);
          transition: all 650ms cubic-bezier(1, -0.16, 0.39, 0.95) 0ms;
      }
      &.rotate-back {
          -webkit-transform: translateY(-8px) rotate(-45deg);
          transform: translateY(-10px) rotate(-45deg);
          transition: all 650ms cubic-bezier(1, -0.16, 0.39, 0.95) 0ms;
      }
  }
`;
