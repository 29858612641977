/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import AppState from '@state';

import Box from '@ui-kit/box';

import AudioPlayer from '@distinct-components/audioPlayer';
import CompositionDetailsSection from './sections/detailsSection/CompositionDetailsSection';

import CompositionWriterShareSection from './sections/writersShareSection/CompositionWriterShareSection';
import ActivitiesSection from './sections/activitiesSection';
import CompositionInterpolationSection from './sections/interpolationSection/CompositionInterpolationSection';
import StructuredLyrics from './sections/structuredLyrics';
import WorkspaceFileSection from '../sharedSections/workspaceFileSection';
import WorkspaceRecordingSection from '../sharedSections/workspaceRecordingSection';
import CompositionOwnershipActions from './sections/compositionOwnershipActions/OwnershipActions';

import { ProofWrapper } from '../../WorkspaceStyles';

function CompositionMode({
  workspaceTab,
  workspaceId,
  activeUser,
}) {
  return (
    <Box width="100%">
      {{
        Main:
        (
          <Box pb="1.5em">
            <CompositionDetailsSection workspaceId={workspaceId} activeUser={activeUser} />
            <CompositionOwnershipActions workspaceId={workspaceId} activeUser={activeUser} />
            <CompositionWriterShareSection workspaceId={workspaceId} activeUser={activeUser} />
          </Box>
        ),
        Proof:
        (
          <ProofWrapper>
            <Box>
              <WorkspaceRecordingSection workspaceId={workspaceId} activeUser={activeUser} />
              <WorkspaceFileSection workspaceId={workspaceId} activeUser={activeUser} />
            </Box>
            <StructuredLyrics workspaceId={workspaceId} activeUser={activeUser} />
          </ProofWrapper>
        ),
        OtherData: <CompositionInterpolationSection workspaceId={workspaceId} activeUser={activeUser} />,
        Activity: <ActivitiesSection workspace={workspaceId} />,
      }[workspaceTab]}

      <AudioPlayer uuid={AppState.composition.uuid.value} />
    </Box>
  );
}

CompositionMode.propTypes = {
  workspaceTab: PropTypes.string.isRequired,
  workspaceId: PropTypes.number.isRequired,
  activeUser: PropTypes.object.isRequired,
};

export default CompositionMode;
