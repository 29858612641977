/* eslint-disable object-curly-newline */
/* eslint-disable react/forbid-prop-types */
import { useState, useEffect, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import AppState from '@state';
import { Fragment } from 'preact';

import useClickOutside from '@hooks/useClickOutside';

import loadAccountPublisherPresets from '@actions/loadAccountPublisherPresets';
import { patchAccountPublisherPresetDefault } from '@api/restricted/account-publisher-preset-api';
import { patchWorkspaceAccountPublisherPreset } from '@api/restricted/workspace-account-api';

import Header from '@ui-kit/typography/header';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import BaseButton from '@ui-kit/buttons/baseButton';
import PanelDataCard from '@distinct-components/cards/panelDataCard';
import PopoverMenu from '@ui-kit/menu/popoverMenu';
import BreakLine from '@ui-kit/dividers/breakLine';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';

import {
  PresetHeader,
  PresetBody,
  ActionMenuItem,
  MenuDot,
  DefaultChip,
  InvalidChip,
} from './ManagePublisherOverlayPresetListStyles';

function ManagePublisherOverlayPresetList({
  initialPresetId,
  workspaceId,
  setManageMode,
  setSelectedPreset,
  handleCloseOverlay,
}) {
  const [menuKey, setMenuKey] = useState(null);
  const [validPresets, setValidPresets] = useState([]);
  const [invalidPresets, setInvalidPresets] = useState([]);
  const [showInvalidPresets, setShowInvalidPresets] = useState(false);
  const ref = useRef(null);
  useClickOutside(ref, () => setMenuKey(null));

  const TODAY_DATE = new Date().toISOString();

  const useToastAlertError = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'error' };
  };

  useEffect(() => {
    setSelectedPreset(null);
    const invalidSets = AppState.publisherPresets.value.filter((p) => (p.isRevoked || (p.validPeriod.to !== null && TODAY_DATE > p.validPeriod.to)));
    const validSets = AppState.publisherPresets.value.filter((p) => (!p.isRevoked && (p.validPeriod.to == null || TODAY_DATE < p.validPeriod.to)));
    setValidPresets(validSets);
    setInvalidPresets(invalidSets);
  }, [AppState.publisherPresets.value]);

  const onClickSetAsDefault = (preset) => {
    // setIsLoading(true);
    patchAccountPublisherPresetDefault(preset.id)
      .then((response) => {
        // setIsLoading(false);
        if (response.status === 200) {
          loadAccountPublisherPresets().then(() => {
            handleCloseOverlay();
          });
        } else {
          response.json()
            .then((json) => useToastAlertError(json.error ? json.error : response.statusText))
            .catch(() => useToastAlertError(response.statusText));
        }
      })
      .catch((err) => {
        // setIsLoading(false);
        useToastAlertError(err.message);
      });
  };

  const onClickPreset = (preset) => {
    if (workspaceId) {
      if (preset.id === initialPresetId) {
        handleCloseOverlay();
      } else {
        // setIsLoading(true);
        patchWorkspaceAccountPublisherPreset(workspaceId, preset.id)
          .then((response) => {
            // setIsLoading(false);
            if (response.status === 200) {
              handleCloseOverlay();
            } else {
              response.json()
                .then((json) => useToastAlertError(json.error ? json.error : response.statusText))
                .catch(() => useToastAlertError(response.statusText));
            }
          })
          .catch((err) => {
            // setIsLoading(false);
            useToastAlertError(err.message);
          });
      }
    }
  };

  const onClickAssignPreset = (set) => {
    setSelectedPreset(set);
    setManageMode('assignPreset');
  };

  const onClickEditPreset = (set) => {
    setSelectedPreset(set);
    setManageMode('createEditPreset');
  };

  return (
    <Box>
      <Header mb="0.75em">{workspaceId ? 'Select Relationship' : 'Publisher Relationships' }</Header>

      {validPresets && validPresets.map((set) => (
        <PanelDataCard
          padding="0"
          variant="presetCard"
          mb="1.5em"
          key={set.id}
        >
          <PresetHeader>
            <Box flexBasis="80%" pr="1em" className={workspaceId ? 'cursor-p' : ''} onClick={() => onClickPreset(set)}>
              <Text color="var(--text-primary)" fontWeight="600">{set.name}</Text>
            </Box>
            <Box minWidth="2em" display="flex" flexDirection="column" alignItems="flex-end" className="cursor-p" pr="1em" onClick={() => setMenuKey(set.id)}>
              <MenuDot mb="4px" />
              <MenuDot mb="4px" />
              <MenuDot />
            </Box>
            {menuKey === set.id
              && (
              <PopoverMenu ref={ref} top="16px" right="12px" width="12em">
                <ActionMenuItem onClick={() => onClickEditPreset(set)}>Edit Info</ActionMenuItem>
                {workspaceId && set.id !== initialPresetId
                && <ActionMenuItem onClick={() => onClickPreset(set)}>Assign to this Composition</ActionMenuItem>}
                {!workspaceId
                && <ActionMenuItem onClick={() => onClickAssignPreset(set)}>Assign to Compositions</ActionMenuItem>}
                {!workspaceId && !set.isDefault
                && <ActionMenuItem onClick={() => onClickSetAsDefault(set)}>Set as Default</ActionMenuItem>}
              </PopoverMenu>
              )}
          </PresetHeader>
          <PresetBody className={workspaceId ? 'cursor-p' : ''} onClick={() => onClickPreset(set)}>
            {set.id === initialPresetId && <DefaultChip>{workspaceId ? 'Currently Selected' : 'Default for New Compositions'}</DefaultChip>}
            <Text mt="0.25em" fontSize="0.813rem" color="var(--brand-primary)" fontWeight="600">Owners:</Text>
            {set.owners.slice(0, 2).map((po) => (
              <Text mb="0.25em" fontSize="0.813rem">
                {po.name || AppState.publishers.value[po.id].name}
                {' '}
                :
                {' '}
                {po.ownedPercentage}
                %
              </Text>
            ))}
            {set.owners.length > 2 && (
            <Text color="var(--text-medium-mute)">
              And
              {' '}
              {set.owners.length - 2}
              {' '}
              More
            </Text>
            )}
            <Text mt="0.25em" fontSize="0.813rem" color="var(--brand-primary)" fontWeight="600">Admins:</Text>
            {set.admins.slice(0, 2).map((pa) => (
              <Text mb="0.25em" fontSize="0.813rem">
                {pa.name || AppState.publishers.value[pa.id].name}
                {' '}
                :
                {' '}
                {pa.territory}
              </Text>
            ))}
            {set.admins.length > 2 && (
            <Text color="var(--text-medium-mute)">
              And
              {' '}
              {set.admins.length - 2}
              {' '}
              More
            </Text>
            )}
          </PresetBody>
        </PanelDataCard>
      ))}

      {invalidPresets && invalidPresets.length > 0 && (
        <Fragment>
          <BreakLine mb="0.5em" mt="0.5em" color="var(--border)" />

          <Box mb={showInvalidPresets ? '1em' : '0.5em'} display="flex" justifyContent="space-between" alignItems="center">
            <Text color="var(--text-medium-mute)">
              Expired or Revoked:
              {' '}
              {invalidPresets.length}
            </Text>
            <LinkButton
              type="button"
              color="var(--text-medium-mute)"
              btnText={showInvalidPresets ? 'Hide' : 'View'}
              onClick={() => setShowInvalidPresets(!showInvalidPresets)}
            />
          </Box>
        </Fragment>
      )}

      {showInvalidPresets && invalidPresets.map((set) => (
        <PanelDataCard
          padding="0"
          variant="presetCard"
          mb="1.5em"
          key={set.id}
        >
          <PresetHeader>
            <Box flexBasis="80%" pr="1em">
              <Text color="var(--text-primary)" fontWeight="600">{set.name}</Text>
            </Box>
          </PresetHeader>
          <PresetBody>
            <InvalidChip>{set.isRevoked ? 'Information Revoked by Publisher' : 'Relationship Info Expired'}</InvalidChip>
            <Text mt="0.25em" fontSize="0.813rem" color="var(--brand-primary)" fontWeight="600">Owners:</Text>
            {set.owners.slice(0, 2).map((po) => (
              <Text mb="0.25em" fontSize="0.813rem">
                {po.name || AppState.publishers.value[po.id].name}
                {' '}
                :
                {' '}
                {po.ownedPercentage}
                %
              </Text>
            ))}
            {set.owners.length > 2 && (
            <Text color="var(--text-medium-mute)">
              And
              {' '}
              {set.owners.length - 2}
              {' '}
              More
            </Text>
            )}
            <Text mt="0.25em" fontSize="0.813rem" color="var(--brand-primary)" fontWeight="600">Admins:</Text>
            {set.admins.slice(0, 2).map((pa) => (
              <Text mb="0.25em" fontSize="0.813rem">
                {pa.name || AppState.publishers.value[pa.id].name}
                {' '}
                :
                {' '}
                {pa.territory}
              </Text>
            ))}
            {set.admins.length > 2 && (
            <Text color="var(--text-medium-mute)">
              And
              {' '}
              {set.admins.length - 2}
              {' '}
              More
            </Text>
            )}
          </PresetBody>
        </PanelDataCard>
      ))}

      {invalidPresets && invalidPresets.length > 0 && (
      <BreakLine mb="1.5em" mt="0em" color="var(--border)" />
      )}

      <BaseButton variant="secondary" btnText="Add Another" fluid onClick={() => setManageMode('createEditPreset')} />
    </Box>
  );
}

ManagePublisherOverlayPresetList.propTypes = {
  initialPresetId: PropTypes.number,
  workspaceId: PropTypes.number,
  setManageMode: PropTypes.func.isRequired,
  setSelectedPreset: PropTypes.func.isRequired,
  handleCloseOverlay: PropTypes.func.isRequired,
};

ManagePublisherOverlayPresetList.defaultProps = {
  initialPresetId: null,
  workspaceId: null,
};

export default ManagePublisherOverlayPresetList;
