import AppState from '@state';

export default async function useErrorOverlay(error) {
  AppState.exceptions.errorOverlay.display.value = true;
  AppState.exceptions.errorOverlay.errorTitle.value = error.title || null;
  AppState.exceptions.errorOverlay.userMessage.value = error.userMessage || null;
  AppState.exceptions.errorOverlay.errorMessage.value = error.errorMessage || null;
  AppState.exceptions.errorOverlay.buttonText.value = error.buttonText || null;
  AppState.exceptions.errorOverlay.buttonPath.value = error.buttonPath || null;
}
