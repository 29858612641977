/* eslint-disable react/no-unescaped-entities */
import { useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import AppState from '@state';

import { postAccountCheckoutSubscription } from '@api/restricted/account-checkout-api';

import BaseButton from '@ui-kit/buttons/baseButton';
import Header from '@ui-kit/typography/header';

import FullScreenModal from '@layouts/full-screen/full-screen-modal';

function Membership() {
  useEffect(() => {
    document.title = 'Become a Member';
  }, []);

  const onClickSubscribe = () => {
    postAccountCheckoutSubscription().then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if (json.checkout_url) {
            window.location.href = json.checkout_url;
          } else {
            AppState.exceptions.errorPageError.value = 'Missing checkout url';
          }
        });
      } else {
        response.json().then((json) => {
          AppState.exceptions.errorPageError.value = `${json.error ? json.error : response.statusText} when checking out`;
        });
      }
    }).catch((err) => {
      AppState.exceptions.errorPageError.value = `${err.message} when checking out`;
      route('/error/exception');
    });
  };

  return (
    <FullScreenModal formWidth="48em">
      <Header mb="1em">Become a Member</Header>
      <BaseButton
        mb="1.5em"
        type="submit"
        btnText="Pay the Money"
        // disabled={isLoading}
        fluid
        onClick={onClickSubscribe}
        // isLoading={isLoading}
      />

    </FullScreenModal>
  );
}

export default Membership;
