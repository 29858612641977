import { orgCustomTagsUrl } from '../urls';
import refresh from '../refresh';

export async function postOrgCustomTag(orgId, name, color) {
  const data = {
    org: orgId,
    name,
    color,
  };
  return refresh(async () => {
    const response = await fetch(orgCustomTagsUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteOrgCustomTag(orgId, tagId) {
  const data = {
    org: orgId,
    tag: tagId,
  };
  return refresh(async () => {
    const response = await fetch(orgCustomTagsUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
