import PropTypes from 'prop-types';
import { useEffect, useRef } from 'preact/hooks';
import { route } from 'preact-router';

import SwitchLogo from '@assets/logos/switchchord-logo-main.svg';
import LogoutIcon from '@assets/icons/logout.svg';
import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';

import {
  CoreWrapper,
  AdminNavWrapper,
  AdminNavItem,
  CoreHeaderWrapper,
  CoreWorkSpaceWrapper,
  CoreContentWrapper,
} from './CoreAdminLayoutStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function CoreAdminLayout({
  children,
}) {
  const topRef = useRef();
  useEffect(() => {
    topRef.current.scrollIntoView();
    document.body.style.backgroundColor = 'var(--background)';
  }, []);

  const handleEndSession = () => {
    localStorage.removeItem('accessToken');
    route('/patchbay');
  };

  return (
    <CoreWrapper>
      <CoreHeaderWrapper ref={topRef}>
        <Icon className="logo" size="172px" mr="5em" cursor><SwitchLogo /></Icon>
        <Box display="flex" alignItems="center" onClick={handleEndSession} className="cursor-p">
          <Icon size="1.25em" mr="0.5em" pt="3px" cursor><LogoutIcon /></Icon>
          <Text fontWeight="600" color="var(--icon)">Sign Out</Text>
        </Box>
      </CoreHeaderWrapper>
      <CoreWorkSpaceWrapper>
        <AdminNavWrapper>
          <AdminNavItem onClick={() => route('/patchbay/organizations')}>Organizations</AdminNavItem>
          <AdminNavItem onClick={() => route('/patchbay/organizations-users')}>Org Users</AdminNavItem>
          <AdminNavItem onClick={() => route('/patchbay/actions')}>Internal Actions</AdminNavItem>
          <AdminNavItem onClick={() => route('/patchbay/metrics')}>Internal Metrics</AdminNavItem>
        </AdminNavWrapper>
        <CoreContentWrapper>
          {children}
        </CoreContentWrapper>
      </CoreWorkSpaceWrapper>
    </CoreWrapper>
  );
}

CoreAdminLayout.propTypes = {
  children: childrenPropTypes.isRequired,
};

export default CoreAdminLayout;
