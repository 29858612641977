import PropTypes from 'prop-types';

import {
  OrgLogoWrapper,
} from './LogoStyles';

function Logo({
  imgURL,
  size,
  border,
  ...restProps
}) {
  return (

    <OrgLogoWrapper
      imgURL={imgURL}
      size={size}
      border={border}
      {...restProps}
    />

  );
}

Logo.propTypes = {
  imgURL: PropTypes.string,
  size: PropTypes.string,
  border: PropTypes.string,
};

Logo.defaultProps = {
  imgURL: null,
  size: '5rem',
  border: '2px solid var(--border)',
};

export default Logo;
