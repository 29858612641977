import { refreshUrl } from './urls';

async function refreshApi(refreshToken) {
  const data = {
    refresh: refreshToken,
  };

  const response = await fetch(refreshUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (response.status === 200) {
    response.json().then((json) => {
      localStorage.setItem('accessToken', json.accessToken);
      localStorage.setItem('refreshToken', json.refreshToken);
    });
  }
  return response;
}

export default async function refresh(callback) {
  const response = await callback();

  if (response.status < 200 || response.status > 299) {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    const accessTokenExpiry = accessToken ? parseInt(JSON.parse(atob(accessToken.split('.')[1])).exp, 10) : null;
    const refreshTokenExpiry = refreshToken ? parseInt(JSON.parse(atob(refreshToken.split('.')[1])).exp, 10) : null;

    const accessTokenIsValid = accessTokenExpiry && (accessTokenExpiry * 1000 - 10000 > Date.now());
    const refreshTokenIsValid = refreshTokenExpiry && (refreshTokenExpiry * 1000 - 10000 > Date.now());

    if (!accessTokenIsValid && refreshTokenIsValid) {
      await refreshApi(refreshToken);
      return callback();
    }
  }

  return response;
}
