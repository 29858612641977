/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import { Cell, StyledMobileLabel } from './TableCellStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function TableCell({
  children,
  widthPercent,
  align,
  cursor,
  mobileLabel,
  ...restProps
}) {
  return (
    <Cell className="cell" widthPercent={widthPercent} cursor={cursor} align={align} {...restProps}>
      {mobileLabel && <StyledMobileLabel>{mobileLabel}</StyledMobileLabel>}
      {children}
    </Cell>
  );
}

TableCell.propTypes = {
  children: childrenPropTypes.isRequired,
  widthPercent: PropTypes.string,
  cursor: PropTypes.bool,
  mobileLabel: PropTypes.string,
  align: PropTypes.string,
};

TableCell.defaultProps = {
  widthPercent: null,
  cursor: false,
  mobileLabel: null,
  align: null,
};

export default TableCell;
