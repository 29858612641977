/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';

import { getOrganizations } from '@api/admin/admin-organizations-api';
import { postMockData } from '@api/admin/admin-mock-data-api';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Spinner from '@ui-kit/loaders/Spinner';

function AdminActionsPopulateData({
  closeFunction,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isPopulating, setIsPopulating] = useState(false);
  const [populatingError, setPopulatingError] = useState({ message: null });
  const [orgUUID, setOrgUUID] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const [targetOrganization, setTargetOrganization] = useState();
  const [recordCount, setRecordCount] = useState(1000);
  const [confirmationPhrase, setConfirmationPhrase] = useState('');

  const loadOrganizations = async () => {
    try {
      const response = await getOrganizations();
      if (response.status !== 200) {
        const json = await response.json();
        setPopulatingError({ message: `${json.error || response.statusText} when loading organizations` });
      } else {
        const json = await response.json();
        const sortedOrgs = json.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        const formattedOrgs = sortedOrgs.map((org) => ({
          id: org.id,
          uuid: org.uuid,
          name: org.name,
          logoUrl: org.logoURL,
        }));
        setOrganizations(formattedOrgs);
      }
    } catch (err) {
      setPopulatingError({ message: `${err.message} when loading organizations` });
    }
  };

  useEffect(() => {
    loadOrganizations().then(() => {
      setIsLoading(false);
    });
  }, []);

  const handleFindOrg = () => {
    const target = organizations.find((org) => org.uuid === orgUUID);

    if (target) {
      setTargetOrganization(target);
    } else {
      setPopulatingError({ message: 'Organization not found' });
    }
  };

  // TODO: Make setError work
  const handlePopulateData = () => {
    setIsPopulating(true);
    postMockData(targetOrganization.id, recordCount)
      .then((response) => {
        setIsPopulating(false);
        if (response.status === 200) {
          closeFunction();
        } else {
          response.json()
            .then((json) => setPopulatingError({ message: json.error || response.statusText }))
            .catch(() => setPopulatingError({ message: response.statusText }));
        }
      })
      .catch((err) => {
        setIsPopulating(false);
        setPopulatingError({ message: err.message });
      });
  };

  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>

      {!isPopulating ? (
        <Fragment>
          <ConversationPrompt
            primaryMessage="Populate Demo Data"
            promptLabel="Admin Action"
            mb="1em"
          />

          {!targetOrganization ? (
            <Fragment>
              <InputLabel label="Organization UUID" />
              <BaseInput
                value={orgUUID}
                id="uuid"
                name="uuid"
                placeholder="Enter Organization UUID"
                fluid
                onChange={(e) => setOrgUUID(e.target.value)}
                type="text"
                mb="1.5em"
              />
              {populatingError.message && (
              <AlertMessage
                variant="negative"
                message={populatingError.message}
                mb="1.5em"
              />
              )}
              <BaseButton mb="1.5em" type="Lookup" btnText="Save" disabled={orgUUID.length < 1} fluid onClick={handleFindOrg} isLoading={isLoading} />
            </Fragment>
          ) : (
            <Fragment>
              <Text mb="1.25em">
                Populate demo data for:
                {' '}
                {targetOrganization?.name}
              </Text>
              <InputLabel label="Type Org Name to Confirm" />
              <BaseInput
                id="conf"
                name="conf"
                value={confirmationPhrase}
                onChange={(e) => setConfirmationPhrase(e.target.value)}
                placeholder={`Confirm by typing: ${targetOrganization?.name}`}
                fluid
                type="text"
                mb="1.5em"
              />
              <InputLabel label="Number of Compositions" />
              <BaseSelect
                fluid
                variant="small"
                onChange={(e) => setRecordCount(parseInt(e.target.value, 10))}
                mb="2.5em"
              >
                <option value={100}>100</option>
                <option value={1000} default selected>1,000</option>
                <option value={10000}>10,000</option>
                <option value={100000}>100,000 (Works but connection may be closed prematurely)</option>
              </BaseSelect>

              <BaseButton mb="1.5em" type="submit" btnText="Populate" disabled={targetOrganization?.name.toLowerCase() !== confirmationPhrase.toLowerCase()} onClick={handlePopulateData} fluid isLoading={isLoading} />
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Spinner size="1.25em" variant="page" />
          <Text fontSize="1.25rem" mt="1em">Data is Populating</Text>
          <Text mt="0.25em">Window will close when complete...</Text>
          {populatingError.message
          && (
          <AlertMessage
            variant="negative"
            message={populatingError.message}
            mb="1.5em"
          />
          )}
        </Box>
      )}
    </BaseEditOverlay>
  );
}

AdminActionsPopulateData.propTypes = {
  closeFunction: PropTypes.func.isRequired,
};

export default AdminActionsPopulateData;
