import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const FileWrapper = styled.div(({
  borderBottom,
}) => `
    padding: 0.5em 0em;
    border-bottom: 1px solid var(--card-divider);
    ${!borderBottom
        && `&:last-child {
        border-bottom: none;
    }`}
`);

export const FileDetailRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const FileDetailsWrapper = styled.div`
    margin-left: 1em;

    p {
        max-width: 34em;
        word-break: break-word;
    }
`;

export const ActionsWrapper = styled.div`
    padding: 1em;
    display: flex;
    align-items: center;

    @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 1em 0em 0.5em 0em;
    }
`;

export const FileInformationRow = styled.div`
    margin-left: 3em;
`;

export const StyledNoteText = styled.div`
    font-size: 0.75rem;
    font-style: italic;
    color: var(--text-medium-mute);
    line-height: 1.7;
    margin: 0.5em 2em 1em 0em;
`;
