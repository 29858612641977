/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'preact/hooks';
import AppState from '@state';
import { useForm } from 'react-hook-form';
import { Fragment } from 'preact';

import {
  postWorkspaceAttachment,
  deleteWorkspaceAttachment,
  putWorkspaceAttachmentNote,
  getFileAttachmentDownload,
} from '@api/restricted/workspace-attachment-api';
import getWorkspaceAttachments from '@api/restricted/workspace-attachments-api';

import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import Span from '@ui-kit/typography/span';
import Text from '@ui-kit/typography/text';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import BaseTextArea from '@ui-kit/inputs/baseTextArea/BaseTextArea';
import LinkButton from '@ui-kit/buttons/linkButton';
import WorkspaceCard from '@distinct-components/cards/workspaceCard';
import WorkspaceEditOverlay from '@layouts/full-screen/workspaceEditOverlay';
import DragAndDrop from '@distinct-components/files/dragAndDrop';
import FileList from '@distinct-components/files/fileList';

import { COMPOSITION_EXPLANATIONS } from '@constants/supportingCopy';

function WorkspaceFilesSection({ workspaceId, activeUser }) {
  const [error, setError] = useState(null);
  const [fileListError, setFileListError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessingFiles, setIsProcessingFiles] = useState(false);
  const [fileStageList, setFileStageList] = useState([]);
  const [invalidFileList, setInvalidFileList] = useState([]);
  const [addedFiles, setAddedFiles] = useState([]);
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [isEditingNote, setIsEditingNote] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDeletingNote, setIsDeletingNote] = useState(null);

  const {
    register, handleSubmit, setValue, formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const loadWorkspaceFiles = async (id) => {
    try {
      const response = await getWorkspaceAttachments(id);
      const json = await response.json();
      if (response.status !== 200) {
        setFileListError(json.error ? json.error : response.statusText);
      } else {
        setAddedFiles(json.files);
      }
    } catch (err) {
      setFileListError(err.message);
    }
  };

  useEffect(() => {
    loadWorkspaceFiles(workspaceId);
  }, []);

  const handleCancelEdit = () => {
    setIsAddingNote(!isAddingNote);
    AppState.workspace.isEditingChild.value = false;
  };

  const handleFilesAdded = (files) => {
    setError(null);
    if (files.length >= 10) {
      setError('Only 10 files can be uploaded at one time');
      return;
    }
    setIsProcessingFiles(true);
    const invalidFiles = [];
    const validFiles = [];
    const filesToProcess = [...files];

    filesToProcess.forEach((file) => {
      const fileName = file.name;
      const re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png|\.mov|\.mp4|\.docx|\.pdf|\.wav|\.mp3|\.aiff|\.heic)$/;
      if (!re.exec(fileName.toLowerCase())) {
        file.invalid = true;
        file.invalidReason = 'Invalid File Type';
        invalidFiles.push(file);
      } else if (file.size >= 20000000) {
        file.invalid = true;
        file.invalidReason = 'File must be less than 20 MB';
        invalidFiles.push(file);
      } else if (addedFiles.length + validFiles.length >= 10) {
        file.invalid = true;
        file.invalidReason = 'You can only upload a maximum of 10 files';
        invalidFiles.push(file);
      } else {
        file.isProcessing = true;
        validFiles.push(file);
      }
    });

    setFileStageList([...validFiles]);
    setInvalidFileList([...invalidFiles]);

    const responses = [];
    validFiles.forEach((file) => {
      responses.push(postWorkspaceAttachment(workspaceId, file.name, file.type, file.size, file));
    });

    Promise.all(responses).then(() => {
      setFileStageList([]);
      setIsProcessingFiles(false);
      loadWorkspaceFiles(workspaceId);
    });
  };

  const handleRemoveInvalidFile = (file) => {
    const invalidFiles = invalidFileList;
    const filteredFiles = invalidFiles.filter((obj) => obj.name !== file.name);
    setInvalidFileList([...filteredFiles]);
  };

  const handleRemoveFile = (file) => {
    deleteWorkspaceAttachment(workspaceId, file.attachmentId)
      .then((response) => {
        if (response.status === 200) {
          loadWorkspaceFiles(workspaceId).then(() => {
            setFileListError('');
          });
        } else {
          response.json().then((json) => {
            setFileListError(json.error ? json.error : response.statusText);
          });
        }
      })
      .catch((err) => {
        setFileListError(err.message);
      });
  };

  const handleFileDownload = (file) => {
    getFileAttachmentDownload(workspaceId, file.attachmentId)
      .then((response) => {
        if (response.status === 200) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            a.remove();
          });
        } else {
          response.json().then((json) => {
            setFileListError(json.error ? json.error : response.statusText);
          });
        }
      })
      .catch((err) => {
        setFileListError(err.message);
      });
  };

  const handleCreateFileNote = (file) => {
    setError(null);
    if (file.note && file.note.length > 0) {
      setValue('fileNote', file.note);
      setIsEditingNote(true);
    } else {
      setIsEditingNote(false);
    }
    setIsAddingNote(true);
    setSelectedFile(file);
  };

  const handleSaveFileNote = (data) => {
    const cleanedData = {
      fileNote: data.fileNote?.trim(),
    };
    if (!cleanedData.fileNote) {
      setError('Invalid note');
    } else {
      setIsLoading(true);
      putWorkspaceAttachmentNote(workspaceId, selectedFile.attachmentId, cleanedData.fileNote)
        .then(() => {
          setError('');
          setIsAddingNote(false);
          setSelectedFile(null);
          setIsLoading(false);
          setValue('fileNote', null);
          loadWorkspaceFiles(workspaceId);
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  };

  const handleDeleteFileNote = () => {
    setIsDeletingNote(true);

    putWorkspaceAttachmentNote(workspaceId, selectedFile.attachmentId, null)
      .then(() => {
        setError('');
        setIsAddingNote(false);
        setSelectedFile(null);
        setIsDeletingNote(false);
        setValue('fileNote', null);
        loadWorkspaceFiles(workspaceId);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <Box display="flex">
      <WorkspaceCard
        title="Supporting Files"
        isCardLocked={!activeUser.isCanEdit}
        lockedText={COMPOSITION_EXPLANATIONS.locks.manageSectionBlocked}
        minHeight="20em"
      >
        {activeUser.isCanEdit
        && (
        <Fragment>
          <Text mb="1em" fontSize="0.813rem">Add Sheet Music or Other Documentation</Text>
          <DragAndDrop
            handleFilesAdded={handleFilesAdded}
            disabled={isProcessingFiles}
            id="file-drop"
          />
          {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
            mt="1.5em"
          />
          )}
        </Fragment>
        )}

        {fileListError
          && (
          <AlertMessage
            variant="negative"
            message={fileListError}
            mb="1.5em"
            mt="1em"
          />
          )}

        {fileStageList.length > 0
        && (
        <Box mt="1em">
          <FileList
            borderBottom
            files={fileStageList}
            activeUser={activeUser}
          />
        </Box>
        )}
        {invalidFileList.length > 0
        && (
        <Box mt="1em">
          <FileList
            borderBottom
            files={invalidFileList}
            handleRemoveInvalidFile={handleRemoveInvalidFile}
            activeUser={activeUser}
          />
        </Box>
        )}
        {addedFiles.length > 0
        && (
          <Box id="box" mt="1em">
            <Text fontSize="0.75rem">
              Files Added:
              {' '}
              {addedFiles.length}
            </Text>
            <FileList
              files={addedFiles}
              handleRemoveFile={handleRemoveFile}
              handleFileDownload={handleFileDownload}
              handleAddFileNote={handleCreateFileNote}
              activeUser={activeUser}
            />
          </Box>
        )}

      </WorkspaceCard>

      {isAddingNote
      && (
      <WorkspaceEditOverlay
        closeFunction={handleCancelEdit}
        formCentered
      >
        <Text fontSize="1.5em" fontFamily="var(--font-secondary)" fontWeight="800">
          {isEditingNote ? 'Edit Note' : 'Add Note'}
        </Text>
        <Box mb="1.5em">
          <Span fontSize="0.813rem">Add context to this file to explain its purpose</Span>
        </Box>
        <form id="workspaceFileNoteForm" onSubmit={handleSubmit(handleSaveFileNote)} autoComplete="on">
          <InputLabel label="File Note" />
          <BaseTextArea
            {...register('fileNote', { required: true })}
            id="fileNote"
            name="fileNote"
            placeholder="Enter Note..."
            maxLength="150"
            fluid
            mb="1.5em"
          />
          {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}
          <BaseButton
            mb="1.5em"
            type="submit"
            btnText="Save Note"
            disabled={!isValid}
            fluid
            isLoading={isLoading}
          />
          {isEditingNote && (
          <Box display="flex" justifyContent="center">
            <LinkButton type="button" color="var(--red-300)" spinnerVariant="danger" isLoading={isDeletingNote} btnText="Delete Note" onClick={handleDeleteFileNote} />
          </Box>
          )}
        </form>
      </WorkspaceEditOverlay>
      )}
    </Box>
  );
}

WorkspaceFilesSection.propTypes = {
  workspaceId: PropTypes.number.isRequired,
  activeUser: PropTypes.object.isRequired,
};

export default WorkspaceFilesSection;
