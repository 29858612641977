/* eslint-disable react/forbid-prop-types */
import { useState, useEffect, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import { Fragment } from 'preact';

import useWindowDimensions from '@hooks/useWindowDimensions';
import d from '@hooks/useIsoDateFormat';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Spinner from '@ui-kit/loaders/Spinner';
import Card from '@distinct-components/cards/baseCard';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';

function ProfileOverlayActions({ actions, isLoading }) {
  const [searchActive, setSearchActive] = useState(false);
  const [currentActions, setCurrentActions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  useEffect(() => {
    if (height < 1080) {
      setItemsPerPage(7);
    }
    setCurrentActions(actions);
    setSearchActive(false);
  }, [actions]);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = currentActions.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    const results = [];
    setCurrentPage(1);
    if (query) {
      actions.forEach((a) => {
        if (a.description.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(a);
        }
      });
      setCurrentActions(results);
      setSearchActive(true);
    } else {
      setCurrentActions(actions);
      setSearchActive(false);
    }
  };

  return (
    <Box>
      {isLoading ? (
        <Box>
          <Box width="100%" display="flex" justifyContent="center" alignItems="center" minHeight="260px">
            <Spinner size="1.25em" variant="page" />
          </Box>
        </Box>
      ) : (
        <Box>
          <div ref={pageRef} />
          {actions.length === 0 ? (
            <Card variant="panel" mt="1.5em">
              <BaseEmptyState
                title="No Actions to Show"
                message="You will see a log of actions the representative makes in your account."
                illustration="personas"
                minHeight="38em"
              />
            </Card>
          ) : (
            <Fragment>
              <TableControls
                itemName="Action"
                tableData={actions}
                currentResults={currentResults}
                indexOfFirst={indexOfFirst}
                indexOfLast={indexOfLast}
                showSearch
                searchActive={searchActive}
                searchResults={currentActions}
                handleSearch={onSearchResults}
              />
              <Table mb="1em">
                <TableHeader>
                  <TableCell widthPercent="48%" pr="0.75em">Action</TableCell>
                  <TableCell>Action Date</TableCell>
                </TableHeader>
                {currentResults
                  .map((action) => (
                    <TableRow>
                      <TableCell widthPercent="48%" pr="0.75em">
                        {action.description}
                      </TableCell>
                      <TableCell mobileLabel="Date of Action:">{d(action.createdAt)}</TableCell>
                    </TableRow>
                  ))}
                {(currentResults.length === 0) && (
                <Box p="1em">
                  <Text color="var(--text-muted-panel)">No Actions Match Your Search</Text>
                </Box>
                )}
              </Table>
              <TablePagination
                itemsPerPage={itemsPerPage}
                totalItems={currentActions.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </Fragment>
          )}

        </Box>
      )}
    </Box>
  );
}

ProfileOverlayActions.propTypes = {
  actions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ProfileOverlayActions;
