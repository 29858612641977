/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import { SPLIT_SHEET_PROSE } from '@constants/legalProse';
import useLegalDateFormat from '@hooks/useLegalDateFormat';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Span from '@ui-kit/typography/span';
import BreakLine from '@ui-kit/dividers/breakLine';

import { Fragment } from 'preact';
import {
  DocumentWrapper,
  DocumentHeader,
  DocumentBody,
  StyledHeader,
  StyledAmendmentHeader,
  StyledProse,
  WriterTable,
  WriterTableItem,
  WriterReferenceItem,
  // SignaturesWrapper,
} from './ContractDocumentStyles';
import ContractSignatureBlock from '../contractSignatureBlock';

const PROSE = SPLIT_SHEET_PROSE;
const d = useLegalDateFormat;

const formatWriterNames = (names) => names.map((name, index) => {
  if (index === names.length - 1) {
    return name;
  }
  if (index === names.length - 2) {
    return `${name} and `;
  }
  return `${name}, `;
}).join('');

function ContractDocument({
  contractId,
  contractData,
  isScrollToSignature,
  loadContractData,
  signerRef,
  setIsSignatureComplete,
}) {
  return (
    /* Any changes need to be updated in renderContract.js in backend */
    <DocumentWrapper>
      <DocumentHeader>
        {contractData.isAmendment && <StyledAmendmentHeader>Amended & Restated</StyledAmendmentHeader>}
        <StyledHeader>Split Sheet Agreement</StyledHeader>
        <Text fontSize="1.125em">
          “
          {contractData.compositionName}
          ”
        </Text>
      </DocumentHeader>
      <DocumentBody>
        {contractData.isAmendment
          ? (
            <StyledProse>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {PROSE.thisAmendment}
            </StyledProse>
          )
          : (
            <StyledProse>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {PROSE.thisAgreement}
            </StyledProse>
          )}
        <StyledProse>
          {formatWriterNames(contractData.signingParties.map((writer) => writer.legalName))}
        </StyledProse>
        <StyledProse>
          {PROSE.referredToAsWriters}
        </StyledProse>
        <br />
        <br />
        {contractData.isAmendment
          ? (
            <Fragment>
              <StyledProse>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                WHEREAS,
                {' '}
                {contractData.signingParties.length === contractData.prevSigningParties.length
                  ? 'the Co-Writers'
                  : formatWriterNames(contractData.prevSigningParties)}
                {PROSE.whereAsAmend1}
                {d(contractData.previousExecutedDate)}
              </StyledProse>
              <StyledProse>
                {PROSE.referredToAsAmendment}
              </StyledProse>
              <StyledProse>
                {PROSE.relatingToComposition}
              </StyledProse>
              {contractData.compositionName === contractData.previousCompositionName
                ? (
                  <Fragment>
                    <StyledProse>
                      titled
                      {' '}
                    </StyledProse>
                    <StyledProse fontWeight="600">
                      “
                      {contractData.previousCompositionName}
                      ”
                    </StyledProse>
                  </Fragment>
                )
                : (
                  <Fragment>
                    <StyledProse>
                      previously titled
                      {' '}
                    </StyledProse>
                    <StyledProse fontWeight="600">
                      “
                      {contractData.previousCompositionName}
                      ”
                    </StyledProse>
                    <StyledProse>
                      {' '}
                      and now titled
                      {' '}
                    </StyledProse>
                    <StyledProse fontWeight="600">
                      “
                      {contractData.compositionName}
                      ”
                    </StyledProse>
                  </Fragment>
                )}
              <StyledProse>
                {PROSE.referredToAsComposition}
                ;
              </StyledProse>
              <br />
              <br />
              <StyledProse>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {PROSE.whereAsAmend2}
              </StyledProse>
            </Fragment>
          )
          : (
            <StyledProse>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {PROSE.whereAs}
            </StyledProse>
          )}
        {!contractData.isAmendment
        && (
          <Fragment>
            <StyledProse fontWeight="600">
              “
              {contractData.compositionName}
              ”
            </StyledProse>
            <StyledProse>
              {PROSE.referredToAsComposition}
            </StyledProse>
            <StyledProse>
              {PROSE.establishOwnership}
            </StyledProse>
          </Fragment>
        )}
        <br />
        <br />
        <StyledProse>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {PROSE.nowTherefor}
        </StyledProse>

        <Box mt="1.5em" mb="1.5em">
          <StyledProse>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            1.
            {' '}
            <Span fontWeight="600" fontSize="inherit">Contributions and Ownership.</Span>
            {' '}
            {PROSE.contributionsAndOwnership}
          </StyledProse>
        </Box>
        <Box mt="2em" mb="2em">
          <WriterTable>
            <WriterTableItem>
              <Text fontWeight="600">WRITER</Text>
              <Text fontWeight="600">CONTRIBUTION</Text>
              <Text fontWeight="600">SHARE</Text>
            </WriterTableItem>
            {contractData.signingParties.map((writer) => (
              <WriterTableItem>
                <Text fontWeight="600">
                  {writer.legalName}
                </Text>
                <Text>
                  Contribution:
                  {' '}
                  {writer.contribution}
                </Text>
                <Text>
                  Ownership Share:
                  {' '}
                  {writer.share}
                  %
                </Text>
              </WriterTableItem>
            ))}
          </WriterTable>
        </Box>

        <Box mb="1.5em">
          <StyledProse>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {PROSE.definitionOfOwnership}
            {contractData.compositionHasSamples && ` ${PROSE.ownershipWithSamples}`}
          </StyledProse>
        </Box>

        <Box mb="1.5em">
          <StyledProse>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            2.
            {' '}
            <Span fontWeight="600" fontSize="inherit">Representations and Warranties.</Span>
            {' '}
            {PROSE.representationsAndWarranties}
            {!contractData.compositionHasSamples && ` ${PROSE.noSamplesAttestation}`}
          </StyledProse>
        </Box>

        <Box mb="1.5em">
          <StyledProse>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            3.
            {' '}
            <Span fontWeight="600" fontSize="inherit">Indemnification.</Span>
            {' '}
            {PROSE.indemnification}
          </StyledProse>
        </Box>

        <Box mb="1.5em">
          <StyledProse>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            4.
            {' '}
            <Span fontWeight="600" fontSize="inherit">Relationship of the Co-Writers.</Span>
            {' '}
            {PROSE.relationshipOfCowriters}
          </StyledProse>
        </Box>

        <Box mb="1.5em">
          <StyledProse>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            5.
            {' '}
            <Span fontWeight="600" fontSize="inherit">Amendment and Modification.</Span>
            {' '}
            {PROSE.amendmentAndModification}
          </StyledProse>
        </Box>

        <Box mb="1.5em">
          <StyledProse>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            6.
            {' '}
            <Span fontWeight="600" fontSize="inherit">Counterparts.</Span>
            {' '}
            {PROSE.counterparts}
          </StyledProse>
        </Box>

        <StyledProse>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {PROSE.inWitness}
        </StyledProse>
        <br />
        <br />

        <ContractSignatureBlock
          contractId={contractId}
          writers={contractData.signingParties}
          isScrollToSignature={isScrollToSignature}
          loadContractData={loadContractData}
          signerRef={signerRef}
          setIsSignatureComplete={setIsSignatureComplete}
        />

        {/* <SignaturesWrapper mt="2em" mb="8em">
          {contractData.signingParties.map((writer) => (
            <ContractSignatureBlock
              writer={writer}
              isScrollToSignature={isScrollToSignature}
              handleContractSigned={handleContractSigned}
              signerRef={signerRef}
              signatureError={signatureError}
            />
          ))}
        </SignaturesWrapper> */}

        <BreakLine mb="4em" color="var(--panel-divider)" />

        <Box mt="2em" mb="3em">
          <Text mb="0.25em" fontSize="1.125rem">Songwriter Data Reference</Text>
          <Text mb="1em" fontSize="0.875rem" color="var(--text-muted-panel)">This table contains the most recent writer information.  It is not bound to the signatures of the document above.</Text>
          <WriterTable>
            {contractData.signingParties.map((writer) => (
              <WriterReferenceItem>
                <Text fontWeight="600" mb="0.25em" fontSize="1rem">
                  {writer.legalName}
                </Text>
                {writer.persona?.name && (
                  <Box display="flex" alignItems="center" flexWrap="wrap" mb="0.75em">
                    <Text mr="1.5em">
                      Persona:
                      {' '}
                      {writer.persona.name}
                    </Text>
                    <Text mr="1.5em">
                      Persona IPI:
                      {' '}
                      {writer.persona.ipi ? writer.persona.ipi : 'Not Defined'}
                    </Text>
                    <Text>
                      Persona ISNI:
                      {' '}
                      {writer.persona.isni ? writer.persona.isni : 'Not Defined'}
                    </Text>
                  </Box>
                )}
                <Box display="flex" alignItems="center" flexWrap="wrap" mb="1em">
                  <Text mr="1.5em">
                    Society:
                    {' '}
                    {writer.societies ? writer.societies : 'Not Defined'}
                  </Text>
                  <Text mr="1.5em">
                    Name IPI:
                    {' '}
                    {writer.ipi ? writer.ipi : 'Not Defined'}
                  </Text>
                  <Text>
                    ISNI:
                    {' '}
                    {writer.isni ? writer.isni : 'Not Defined'}
                  </Text>
                </Box>

                <Box display="flex" flexWrap="wrap">

                  <Box mr="2em" mb="0.5em">
                    <Text fontWeight="600">Original Publisher: </Text>
                    {writer.preset?.name ? (
                      <Box>
                        {writer.preset.owners.map((po) => (
                          <Box mb="0.75em" mt="0.5em">
                            <Text>{po.name}</Text>
                            <Box display="flex" flexWrap="wrap">
                              <Text mr="1.5em">
                                Society:
                                {' '}
                                {po.society}
                              </Text>
                              <Text mr="1.5em">
                                IPI:
                                {' '}
                                {po.ipi}
                              </Text>
                            </Box>
                            <Text>
                              {po.address}
                            </Text>
                            <Text mr="1.5em">
                              Owns:
                              {' '}
                              {po.ownedPercentage}
                              %
                            </Text>
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      <Text>Not Defined</Text>
                    )}
                  </Box>

                  <Box mr="2em" mb="0.5em">
                    <Text fontWeight="600">Publishing Administration: </Text>
                    {writer.preset?.name ? (
                      <Box>
                        {writer.preset.admins.map((pa) => (
                          <Box mb="0.75em" mt="0.5em">
                            <Text>{pa.name}</Text>
                            <Box display="flex" flexWrap="wrap">
                              <Text mr="1.5em">
                                Society:
                                {' '}
                                {pa.society}
                              </Text>
                              <Text mr="1.5em">
                                IPI:
                                {' '}
                                {pa.ipi}
                              </Text>
                            </Box>
                            <Text>
                              {pa.address}
                            </Text>
                            <Text mr="1.5em">
                              Territory:
                              {' '}
                              {pa.territory}
                            </Text>
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      <Text>Not Defined</Text>
                    )}
                  </Box>

                </Box>

              </WriterReferenceItem>
            ))}
          </WriterTable>
        </Box>

      </DocumentBody>

    </DocumentWrapper>
  );
}

ContractDocument.propTypes = {
  contractId: PropTypes.number.isRequired,
  contractData: PropTypes.object.isRequired,
  isScrollToSignature: PropTypes.bool.isRequired,
  loadContractData: PropTypes.func.isRequired,
  signerRef: PropTypes.any,
  setIsSignatureComplete: PropTypes.func.isRequired,
};

ContractDocument.defaultProps = {
  signerRef: null,
};

export default ContractDocument;
