import PropTypes from 'prop-types';
import { route } from 'preact-router';

import BaseButton from '@ui-kit/buttons/baseButton';

function PublisherGetStarted({ uuid }) {
  return (
    <BaseButton
      mb="1.5em"
      type="submit"
      btnText="Let's Go"
      fluid
      onClick={() => route(`/publisher/${uuid}/dashboard`)}
    />
  );
}

PublisherGetStarted.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PublisherGetStarted;
