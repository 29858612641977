import { useState, useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';
import anime from 'animejs/lib/anime.es';
import { Fragment } from 'preact';

import { getArtistIdentity } from '@api/public/artist-identity-api';

import useErrorOverlay from '@hooks/useErrorOverlay';

import ConnectingCopyright from '@animations/identity/ConnectingCopyright';
import SwitchLogo from '@assets/logos/switchchord-logo-main.svg';
import Card from '@distinct-components/cards/baseCard';
import QuestionPrompt from '@distinct-components/feedback/questionPrompt';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import Text from '@ui-kit/typography/text';
import Span from '@ui-kit/typography/span';
import PanelCard from '@distinct-components/cards/panelCard';
import BlueCheck from '@assets/icons/blue-check.svg';
import Header from '@ui-kit/typography/header';

import {
  StyledPublicHeader,
  ContentWrapper,
  Content,
  AvatarImgWrapper,
  AvatarCheckWrapper,
  DidWrapper,
} from './ArtistIdentityPreviewStyles';

function ArtistIdentityPreview({ uuid }) {
  const [previewData, setPreviewData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const loadArtistIdentity = async () => {
    try {
      const response = await getArtistIdentity(uuid);
      if (response.status !== 200) {
        try {
          const json = await response.json();
          useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading artist identity` });
        } catch {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading artist identity` });
        }
      } else {
        const {
          id, imgURL, artistProfile, ...rest
        } = await response.json();
        const data = {
          imgUrl: imgURL,
          artistProfile: {
            ...artistProfile,
            pubPreset: {
              owners: artistProfile.pubPreset.filter((pub) => pub.share > 0).map(({ territory, share, ...pubRest }) => ({ ownedPercentage: share, ...pubRest })),
              admins: artistProfile.pubPreset.filter((pub) => pub.territory).map(({ share, ...pubRest }) => pubRest),
            },
          },
          ...rest,
        };
        setPreviewData(data);
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading artist identity` });
    }
  };

  const loadAnimation = anime.timeline({
    autoplay: true,
    delay: 200,
  });

  useEffect(async () => {
    document.title = 'Switchchord - Artist Identity Viewer';
    document.body.style.backgroundColor = 'var(--background)';
    setTimeout(() => {
      anime({
        targets: '#connecting-wrapper .loader-item',
        opacity: [0, 1],
        translateY: [16, 0],
        easing: 'easeOutExpo',
        duration: 700,
        delay: anime.stagger(100),
      });
    }, 200);

    setTimeout(() => {
      loadArtistIdentity().then(() => {
        setIsLoading(false);
        setTimeout(() => {
          loadAnimation.add({
            targets: '#content .content-item',
            translateY: ['16px', 0],
            opacity: [0, 1],
            duration: 600,
            direction: 'normal',
            delay: anime.stagger(100),
          });
          loadAnimation.play();
        }, 500);
      });
    }, 2700);
  }, []);

  const onClickPubDid = (id) => {
    const link = `${window.location.origin}/identity-viewer/publisher/${id}`;
    window.open(link, '_blank');
  };

  return (
    <Box>
      <StyledPublicHeader id="test">
        <Icon className="logo" size="172px" mr="5em"><SwitchLogo /></Icon>
        <Box>
          <a href="https://switchchord.com" target="_blank" rel="noreferrer">Learn More</a>
        </Box>
      </StyledPublicHeader>
      <ContentWrapper>
        <Content id="content">
          <Box>
            <Box>
              <Box display="flex" justifyContent="space-between">
                <Text mb="0.125em">Identity Viewer</Text>
                <QuestionPrompt questionPrompt="This page represents the current state of this artist's persistent digital identity" />
              </Box>
              <Card mt="1em" mb="1.5em" variant="panel">
                {isLoading ? (
                  <Box id="connecting-wrapper" className="identity-header" display="flex" justifyContent="center" alignItems="center" minHeight="10em">
                    <Box>
                      <ConnectingCopyright className="loader-item" style={{ opacity: 0 }} />
                      <Box display="flex" alignItems="center" flexDirection="column">
                        <Text mt="0.75em" fontWeight="600" color="var(--trust-text)" className="loader-item" style={{ opacity: 0 }}>
                          Connecting to Artist Identity
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box id="info-wrapper" minHeight="7em">
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">

                      <Box position="relative">
                        {previewData.verifiedData && <AvatarCheckWrapper><Icon size="1.25em"><BlueCheck /></Icon></AvatarCheckWrapper>}
                        <AvatarImgWrapper avatarImg={previewData.imgUrl} />
                      </Box>

                      <Header mb="0.125em">
                        {previewData.userProfile.firstName}
                        {' '}
                        {previewData.userProfile.lastName}
                      </Header>

                      <Text mt="0.25em">
                        @
                        {previewData.userProfile.username}
                      </Text>

                      <DidWrapper mt="0.5em" color="var(--text-medium-mute)" textAlign="center">
                        {previewData.did}
                      </DidWrapper>

                    </Box>
                  </Box>
                )}
              </Card>
              {!isLoading
              && (
                <Fragment>

                  <PanelCard
                    mb="1.5em"
                    title="Primary Writer Information"
                    className="content-item"
                    style={{ opacity: 0 }}
                  >
                    <Box mb="0.5em">
                      <Span fontWeight="600">Name IPI:</Span>
                  &nbsp;&nbsp;
                      <Span>{previewData.artistProfile.ipi || '(Not Provided)'}</Span>
                    </Box>

                    <Box mb="0.5em">
                      <Span fontWeight="600">ISNI:</Span>
                  &nbsp;&nbsp;
                      <Span>{previewData.artistProfile.isni || '(Not Provided)'}</Span>
                    </Box>

                    <Box mb="0.5em">
                      <Span fontWeight="600">Writer Society:</Span>
                  &nbsp;&nbsp;
                      {previewData.artistProfile.societies.length > 0
                        ? (
                          <Span>{previewData.artistProfile.societies.join(', ')}</Span>
                        )
                        : (
                          <Span>(Not Provided)</Span>
                        )}
                    </Box>

                    <Box mb="0.5em">
                      <Span fontWeight="600">Legal Name:</Span>
                  &nbsp;&nbsp;
                      <Span>{previewData.userProfile.legalName}</Span>
                    </Box>

                    <Box mb="0.5em">
                      <Span fontWeight="600">Country of Residence:</Span>
                  &nbsp;&nbsp;
                      <Span>{previewData.userProfile.country || '(Not Provided)'}</Span>
                    </Box>
                  </PanelCard>

                  <PanelCard
                    mb="1.5em"
                    title="Additional Personas"
                    className="content-item"
                    style={{ opacity: 0 }}
                  >

                    {previewData.artistProfile.aliases.length > 0
                      ? (
                        <Box>
                          {previewData.artistProfile.aliases.map((a) => (
                            <Box mb="1em">
                              <Text fontSize="1rem" color="var(--text-primary)" mb="0.125em" fontWeight="800">{a.name}</Text>
                              <Box mb="0.5em">
                                <Span fontWeight="600">Unique IPI:</Span>
                                &nbsp;&nbsp;
                                <Span>{a.ipi || '(None)'}</Span>
                              </Box>
                              <Box mb="0.5em">
                                <Span fontWeight="600">Unique ISNI:</Span>
                                &nbsp;&nbsp;
                                <Span>{a.isni || '(None)'}</Span>
                              </Box>
                            </Box>
                          ))}

                        </Box>
                      )
                      : (
                        <Span color="var(--text-medium-mute)">No Additional Personas</Span>
                      )}

                  </PanelCard>

                  <PanelCard
                    mb="1.5em"
                    title="Current Publishing Relationship"
                    className="content-item"
                    style={{ opacity: 0 }}
                  >

                    {previewData.artistProfile.pubPreset.admins.length > 0 ? (
                      <Box>

                        <Text mb="0.25em" color="var(--brand-primary)" fontWeight="600">Original Publisher</Text>
                        {previewData.artistProfile.pubPreset.owners.map((po, index) => (
                          <Box mb={index + 1 !== previewData.artistProfile.pubPreset.owners.length ? '0.5em' : '0'}>
                            <Text fontWeight="600" color="var(--text-primary)">{po.name}</Text>
                            <Box mb="0.5em">
                              <Span fontWeight="600">Society:</Span>
                                &nbsp;&nbsp;
                              <Span>{po.society}</Span>
                            </Box>
                            <Box mb="0.5em">
                              <Span fontWeight="600">IPI:</Span>
                                &nbsp;&nbsp;
                              <Span>{po.ipi}</Span>
                            </Box>
                            <Box mb="0.5em">
                              <Span fontWeight="600">Address:</Span>
                                &nbsp;&nbsp;
                              <Span>{po.address}</Span>
                            </Box>

                            {po.isVerified && (
                              <Text color="var(--text-secondary)" className="cursor-p" onClick={() => onClickPubDid(po.uuid)}>{po.did}</Text>
                            )}
                          </Box>
                        ))}

                        <Text mb="0.25em" mt="1.5em" color="var(--brand-primary)" fontWeight="600">Administration</Text>
                        {previewData.artistProfile.pubPreset.admins.map((pa, index) => (
                          <Box mb={index + 1 !== previewData.artistProfile.pubPreset.admins.length ? '0.5em' : '0'}>
                            <Text fontWeight="600" color="var(--text-primary)">{pa.name}</Text>
                            <Box mb="0.5em">
                              <Span fontWeight="600">Society:</Span>
                                &nbsp;&nbsp;
                              <Span>{pa.society}</Span>
                            </Box>
                            <Box mb="0.5em">
                              <Span fontWeight="600">IPI:</Span>
                                &nbsp;&nbsp;
                              <Span>{pa.ipi}</Span>
                            </Box>
                            <Box mb="0.5em">
                              <Span fontWeight="600">Address:</Span>
                                &nbsp;&nbsp;
                              <Span>{pa.address}</Span>
                            </Box>

                            {pa.isVerified && (
                              <Text color="var(--text-secondary)" className="cursor-p" onClick={() => onClickPubDid(pa.uuid)}>{pa.did}</Text>
                            )}
                          </Box>
                        ))}
                      </Box>
                    )
                      : <Span color="var(--text-medium-mute)">Publishing Not Defined by Writer</Span>}

                  </PanelCard>

                </Fragment>

              )}
            </Box>
          </Box>
        </Content>
      </ContentWrapper>
    </Box>
  );
}

ArtistIdentityPreview.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default ArtistIdentityPreview;
