import { orgListsUrl } from '../urls';
import refresh from '../refresh';

export async function getOrgListsByOrg(orgId) {
  return refresh(async () => {
    const response = await fetch(`${orgListsUrl}?${new URLSearchParams({
      org: orgId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function getOrgListById(orgId, listId) {
  return refresh(async () => {
    const response = await fetch(`${orgListsUrl}?${new URLSearchParams({
      org: orgId,
      list: listId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function postOrgList(orgId, name, description) {
  const data = {
    org: orgId,
    name,
    description,
  };
  return refresh(async () => {
    const response = await fetch(orgListsUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function patchOrgList(listId, orgId, name, description) {
  const data = {
    list: listId,
    org: orgId,
    name,
    description,
  };
  return refresh(async () => {
    const response = await fetch(orgListsUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteOrgList(listId, orgId) {
  const data = {
    list: listId,
    org: orgId,
  };
  return refresh(async () => {
    const response = await fetch(orgListsUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
