/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'preact/hooks';
import { Fragment } from 'preact';

import AppState from '@state';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Span from '@ui-kit/typography/span';
import Avatar from '@ui-kit/avatar';
import AlertMessage from '@ui-kit/alert/Alert';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import BaseButton from '@ui-kit/buttons/baseButton';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import LockPrompt from '@distinct-components/feedback/lockPrompt';

import { COMPOSITION_EXPLANATIONS } from '@constants/supportingCopy';

import useRoundNumber from '@hooks/useRoundNumber';

import WriterListItemProfile from './sections/writerListItemProfile/WriterListItemProfile';

import {
  WriterContainer,
  WriterContainerHeader,
  StyledPercentageInput,
  StyledPercentBox,
  StyledInputRow,
  EditActionsWrapper,
  StyledReadOnlyRow,
  SplitsInfoWrapper,
} from './WriterListItemStyles';

function WriterListItem({
  isLoading,
  error,
  handleUpdateWriter,
  writer,
  refreshWriters,
  activeUser,
}) {
  const [isEditingWriterContribution, setIsEditingWriterContribution] = useState(false);
  const [isEditingWriterShare, setIsEditingWriterShare] = useState(false);
  const [share, setShare] = useState();
  const [contribution, setContribution] = useState();
  const [formError, setFormError] = useState();
  const shareRef = useRef();

  const TOTAL_WRITERS = AppState.workspaceAccounts.value.filter((account) => account.isWriter).length;

  useEffect(() => {
    setContribution(writer.contribution);
    setShare(writer.share.toString());
  }, []);

  const handlePercentageChange = (event) => {
    if (event.key === 'Backspace') {
      return;
    }
    const { value } = event.target;
    setShare(value);
    setFormError(null);

    if (event.target.valueAsNumber === writer.share) {
      setIsEditingWriterShare(false);
    } else {
      setIsEditingWriterShare(true);
    }
  };

  const handleContributionChange = (event) => {
    setContribution(event.target.value);
    setFormError(null);

    if (event.target.value === writer.contribution) {
      setIsEditingWriterContribution(false);
    } else {
      setIsEditingWriterContribution(true);
    }
  };

  const onCancelEditWriter = () => {
    setIsEditingWriterContribution(false);
    setIsEditingWriterShare(false);
  };

  const onSubmit = () => {
    let cleanedShare = +share;
    if (!Number.isInteger(cleanedShare)) {
      cleanedShare = useRoundNumber(cleanedShare, 2);
    }

    if (cleanedShare > 100) {
      setFormError('A valid share must be less than 100');
      return;
    }
    if (cleanedShare < 0.01) {
      setFormError('A valid share must be higher than 0.01');
      return;
    }
    if (!contribution) {
      setFormError('Select Writer\'s contribution');
      return;
    }
    setFormError(null);
    const data = {
      share: cleanedShare,
      contribution,
    };
    handleUpdateWriter({
      id: writer.id,
      email: writer.email,
      hasJoined: writer.hasJoined,
    }, data, writer.hash);
    setIsEditingWriterContribution(false);
    setIsEditingWriterShare(false);
  };

  return (
    <Fragment>

      <WriterContainer>
        <WriterContainerHeader>
          <Box display="flex" alignItems="center">
            <Avatar
              size="3.5em"
              borderSize="2px"
              imgURL={writer.imgURL}
              isVerified={writer?.isVerified}
              checkSize="1rem"
              checkX="-1px"
              checkY="4px"
            />
            <Box ml="1em">
              <Text fontWeight="500" fontFamily="var(--font-lexend)" color="var(--text-primary)" lineHeight="1.7" fontSize="0.875rem">
                {writer.firstName}
                {' '}
                {writer.lastName}
                {writer.alias && (
                <Span pl="0.5em">
                  (pka
                  {' '}
                  {writer.alias.name}
                  )
                </Span>
                )}
              </Text>
              <Text variant="mediumMute" lineHeight="1">
                {writer.isRegistered ? `@${writer.username}` : writer.email}
              </Text>
            </Box>
          </Box>
          <SplitsInfoWrapper>
            <Box width="100%">
              {AppState.composition.isLocked.value || (!activeUser.isAdmin && (activeUser.id !== writer.id)) || !activeUser.isCanEdit
                ? (
                  <StyledReadOnlyRow id="read-row">
                    <Box display="flex">
                      <Box mr="1.5em">
                        <InputLabel label="Primary Contribution" />
                        <Text>{contribution || 'Not Defined'}</Text>
                      </Box>
                      <Box mr="2em">
                        <InputLabel label="Ownership Share" />
                        <Text>
                          {share}
                          %
                        </Text>
                      </Box>
                    </Box>
                    <Box className="lockIcon">
                      {AppState.composition.isLocked.value
                        ? (
                          <LockPrompt lockPrompt={TOTAL_WRITERS > 1
                            ? COMPOSITION_EXPLANATIONS.locks.workspaceLocked
                            : COMPOSITION_EXPLANATIONS.locks.workspaceLockedNoContract}
                          />
                        )
                        : (
                          <LockPrompt lockPrompt={COMPOSITION_EXPLANATIONS.locks.editSplitInfoBlocked} />
                        )}
                    </Box>
                  </StyledReadOnlyRow>
                )
                : (
                  <StyledInputRow>
                    <Box width="100%">
                      <InputLabel label=" Primary Contribution" />
                      <BaseSelect
                        variant="panel"
                        defaultValue={contribution}
                        fluid
                        mb="0.25rem"
                        onChange={(event) => handleContributionChange(event)}
                      >
                        <option value="" default selected>Select one</option>
                        <option value="Music and Lyrics">Music and Lyrics</option>
                        <option value="Lyrics">Lyrics</option>
                        <option value="Music">Music</option>
                        <option value="Arrangement">Arrangement</option>
                        <option value="Translation">Translation</option>
                      </BaseSelect>
                    </Box>
                    <Box ml="1em">
                      <InputLabel label="Ownership Share" />
                      <Box display="flex">
                        <StyledPercentageInput
                          id="share"
                          name="share"
                          defaultValue={share}
                          type="number"
                          inputMode="decimal"
                          maxLength={5}
                          step="0.01"
                          max={100}
                          min={0.01}
                          autoComplete="off"
                          onChange={handlePercentageChange}
                          ref={shareRef}
                        />
                        <StyledPercentBox>%</StyledPercentBox>
                      </Box>
                    </Box>
                  </StyledInputRow>
                )}
            </Box>
          </SplitsInfoWrapper>
        </WriterContainerHeader>
        <WriterListItemProfile activeUser={activeUser} writer={writer} refreshWriters={refreshWriters} />
      </WriterContainer>
      {(isEditingWriterShare || isEditingWriterContribution)
      && (
        <Fragment>
          {error
          && (
            <AlertMessage
              variant="negative"
              message={error}
              mb="0.75em"
              mt="1em"
            />
          )}
          {formError
          && (
            <AlertMessage
              variant="negative"
              message={formError}
              mb="0.75em"
              mt="1em"
            />
          )}
          <EditActionsWrapper>
            <BaseButton
              type="button"
              btnText="Save Changes"
              disabled={!share}
              isLoading={isLoading}
              onClick={onSubmit}
            />
            <Box display="flex" alignItems="center" justifyContent="center">
              <LinkButton type="button" btnText="Cancel" onClick={onCancelEditWriter} />
            </Box>
          </EditActionsWrapper>
        </Fragment>
      )}
    </Fragment>
  );
}

WriterListItem.propTypes = {
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleUpdateWriter: PropTypes.func.isRequired,
  writer: PropTypes.object.isRequired,
  refreshWriters: PropTypes.func.isRequired,
  activeUser: PropTypes.object.isRequired,
};

export default WriterListItem;
