import { workspaceInviteAcceptUrl } from '../urls';
import refresh from '../refresh';

export default async function postWorkspaceInviteAccept(inviteToken, writerData, aliasId) {
  const data = {
    token: inviteToken,
    metadata: writerData,
  };
  if (typeof aliasId !== 'number') {
    data.alias = null;
  } else {
    data.alias = aliasId;
  }
  return refresh(async () => {
    const response = await fetch(workspaceInviteAcceptUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
