import styled from 'styled-components';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import pointBreaks from '@styles/styleConstants';
import {
  space,
} from 'styled-system';

export const WelcomeWrapper = styled(Box)`
    padding: 2.75em 0em 2em 0em;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 2em 0em 1.75em 0em;
    }

    @media screen and (min-width: ${pointBreaks.lg}) {
        padding: 2.875em 1em 1em 1em;
    }

    .help-prompt {
        display: block;

        @media screen and (max-width: ${pointBreaks.sm}) {
            display: none;
        }
    }
`;

export const HelpPrompt = styled(Box)`
    display: block;

    @media screen and (max-width: ${pointBreaks.sm}) {
        display: none;
    }
`;

export const ControlsRow = styled(Box)`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;
        gap: 1em;
    }
`;

export const SearchWrapper = styled(Box)`
    width: 12rem;

    @media screen and (max-width: ${pointBreaks.sm}) {
        width: 100%
    }
`;

export const StyledWelcomeHeader = styled(Text)`
    font-size: 1.5rem;
    font-weight: 800;
    font-family: var(--font-secondary);
    color: var(--text-primary);

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1.25rem;
    }
`;

export const StyledWelcomeMessage = styled(Text)`
    font-size: 1rem;
    font-weight: 300;
    font-family: var(--font-secondary);
    color: var(--text-secondary);

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1rem;
    }
`;

export const HomeCardContent = styled.div`
    ${space}
    
    @media screen and (min-width: ${pointBreaks.lg}) {
        padding: 1em;
    }

`;

export const WriterCardWrapper = styled.div`
    width: 100%;
    ${space}
`;

export const StyledEmptyMessage = styled.div`
    background: var(--card);
    border-radius: 1em;
    width: 100%;
    border: 1px solid var(--panel-info-border);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.813rem;
    color: var(--text-secondary);
    min-height: 88px;
    ${space}
    

`;
