import { useState } from 'preact/hooks';

import CoreAdminLayout from '@layouts/coreAdmin/CoreAdminLayout';
import PageCard from '@layouts/pageCard/PageCard';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import BaseCard from '@distinct-components/cards/baseCard';
import LinkButton from '@ui-kit/buttons/linkButton';
import BaseButton from '@ui-kit/buttons/baseButton';

import AdminActionsPopulateData from './sections/adminActionsPopulateData';
import AdminActionsClearData from './sections/adminActionsClearData';

function AdminActions() {
  const [showPopulateData, setShowPopulateData] = useState(false);
  const [showClearData, setShowClearData] = useState(false);

  return (
    <CoreAdminLayout>
      <PageCard
        title="Administrative Actions"
      >

        <BaseCard mt="1.5em" variant="panel">
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Text fontSize="1.125rem" fontWeight="600">Populate Demo Data</Text>
              <Text fontSize="0.813rem">Populate an organization with demo data for testing or presentation.</Text>
            </Box>
            <Box display="flex" alignItems="center">
              <LinkButton btnText="Clear Demo Data" variant="small" color="var(--red-300)" onClick={() => setShowClearData(true)} />
              <BaseButton
                ml="1em"
                variant="secondary"
                btnText="Setup"
                onClick={() => setShowPopulateData(true)}
              />
            </Box>
          </Box>
        </BaseCard>

      </PageCard>

      {showPopulateData
      && <AdminActionsPopulateData closeFunction={() => setShowPopulateData(false)} />}

      {showClearData
      && <AdminActionsClearData closeFunction={() => setShowClearData(false)} />}

    </CoreAdminLayout>
  );
}

export default AdminActions;
