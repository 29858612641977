/* eslint-disable import/prefer-default-export */
import { orgCompositionInternalCommentsUrl } from '../urls';
import refresh from '../refresh';

export async function postOrgCompositionComment(orgId, compId, message) {
  const data = {
    org: orgId,
    comp: compId,
    message,
  };
  return refresh(async () => {
    const response = await fetch(orgCompositionInternalCommentsUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteOrgCompositionComment(orgId, commentId) {
  const data = {
    org: orgId,
    comment: commentId,
  };
  return refresh(async () => {
    const response = await fetch(orgCompositionInternalCommentsUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
