import anime from 'animejs/lib/anime.es';
import Box from '@ui-kit/box';
import { useEffect } from 'preact/hooks';

function ConnectingCopyright() {
  const brickAnimation = anime.timeline({
    autoplay: true,
    loop: true,
  });

  const gridStyle = {
    width: '16px',
    height: '16px',
    background: 'var(--trust)',
    borderRadius: '2px',
    scale: 1,
  };

  const gridWrapper = {
    width: '56px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.25em',
  };

  useEffect(() => {
    brickAnimation.add({
      targets: '#grid .grid-box',
      scale: [
        { value: 0.5, easing: 'easeOutSine', duration: 500 },
        { value: 1, easing: 'easeInOutQuad', duration: 1200 },
      ],
      delay: anime.stagger(50, { grid: [15, 2], from: 'center' }),
      duration: 700,
    });
  }, []);

  return (
    <Box position="relative" width="100%" display="flex" justifyContent="center">
      <div id="grid" style={gridWrapper}>
        <div className="grid-box" style={gridStyle} />
        <div className="grid-box" style={gridStyle} />
        <div className="grid-box" style={gridStyle} />
        <div className="grid-box" style={gridStyle} />
        <div className="grid-box" style={gridStyle} />
        <div className="grid-box" style={gridStyle} />
        <div className="grid-box" style={gridStyle} />
        <div className="grid-box" style={gridStyle} />
        <div className="grid-box" style={gridStyle} />
      </div>
    </Box>
  );
}

export default ConnectingCopyright;
