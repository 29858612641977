/* eslint-disable react/jsx-boolean-value */
import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import { route } from 'preact-router';
import AppState from '@state/AppState';
import resetGlobalState from '@state/reset';

import { getOrgArtistInviteToken } from '@api/public/org-artist-invite-token-api';
import { postLoginWithEmail } from '@api/public/login-api';
import { postUserRegister } from '@api/public/user-register-api';
import { postOrgArtistInviteAccept } from '@api/restricted/org-artist-invite-accept-api';
import putAccountArtistProfile from '@api/restricted/account-artist-profile-api';
import { postAccountSociety } from '@api/restricted/account-society-api';
import loadAccountProfile from '@actions/loadAccountProfile';

import useAuthTokensValidCheck from '@hooks/useAuthTokensValidCheck';
import useAnalytics from '@hooks/useAnalytics';

import Logo from '@ui-kit/logo/Logo';
import BaseButton from '@ui-kit/buttons/baseButton';
import Header from '@ui-kit/typography/header';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import Spinner from '@ui-kit/loaders/Spinner';

import LoginForm from '@shared-forms/loginForm/LoginForm';

import ConversationLayout from '@layouts/conversations';

import AccountDetail from '../../auth/register/sections/account-detail';
import ConnectPublisherAcceptRequest from './sections/acceptRequest';
import ConnectPublisherRequestSuccess from './sections/requestSuccess';
import ConnectPublisherSuggestions from './sections/requestSuggestions';

function ConnectPublisherRequest() {
  const [isNew, setIsNew] = useState(false);
  const [org, setOrg] = useState({});
  const [reg, setReg] = useState({});
  const [claims, setClaims] = useState({});
  const [suggestions, setSuggestions] = useState({});
  const analytics = useAnalytics();

  const [requestMode, setRequestMode] = useState('Loading');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const loadPublisherConnectionInvite = async (token) => {
    try {
      const response = await getOrgArtistInviteToken(token);
      const json = await response.json();
      if (response.status === 200) {
        setIsNew(json.isNew);
        setOrg({
          name: json.org.name,
          logoUrl: json.org.logoURL,
        });
        setReg(json.reg);
        setClaims(json.claims);
        setSuggestions(json.suggestions);
      } else if (response.status === 202) {
        switch (json.errorType) {
          case 'not-found':
            AppState.exceptions.errorOverlay.value = {
              display: true,
              errorTitle: 'Invitation Not Available',
              userMessage: 'This connection request was removed by the publisher or already accepted. Reach out to the publisher for additional information.',
              errorMessage: null,
              buttonText: 'Go Home',
              buttonPath: '/home',
              bypassAuthCheck: true,
            };
            analytics.track('publisher-invite-issue:_already-accepted');
            break;
          case 'expired':
            AppState.exceptions.errorOverlay.value = {
              display: true,
              errorTitle: 'Invitation Expired',
              userMessage: 'Contact your publisher and ask them to send you a new invite. Invites expire after a certain period of time for security reasons.',
              errorMessage: null,
              buttonText: 'Go Home',
              buttonPath: '/home',
              bypassAuthCheck: true,
            };
            analytics.track('publisher-invite-issue:_invitation-expired');
            break;
          default:
            AppState.exceptions.errorOverlay.value = {
              display: true,
              errorTitle: 'Could Not Load Request',
              userMessage: 'This request is no longer valid',
              errorMessage: `${json.errorType} when loading connection request`,
              buttonText: 'Go Home',
              buttonPath: '/home',
              bypassAuthCheck: true,
            };
        }
      } else {
        AppState.exceptions.errorOverlay.value = {
          display: true,
          errorTitle: 'Could Not Load Request',
          userMessage: 'This request is no longer valid',
          errorMessage: `${json.error || response.statusText} when loading connection request`,
          buttonText: 'Go Home',
          buttonPath: '/home',
          bypassAuthCheck: true,
        };
      }
    } catch (err) {
      AppState.exceptions.errorOverlay.value = {
        display: true,
        errorTitle: 'Could Not Load Request',
        userMessage: 'This request is no longer valid',
        errorMessage: `${err.message} when loading connection request`,
        buttonText: 'Go Home',
        buttonPath: '/home',
        bypassAuthCheck: true,
      };
    }
  };

  useEffect(() => {
    document.title = 'Switchchord - Connect Publisher';
    const queryParams = new URLSearchParams(window.location.search);
    loadPublisherConnectionInvite(queryParams.get('token')).then(() => {
      if (useAuthTokensValidCheck()) {
        loadAccountProfile().then(() => {
          setError('');
          setRequestMode('Accept');
        });
      } else {
        setError('');
        setRequestMode('Landing');
      }
    });
  }, []);

  const onBackToLanding = () => {
    setError('');
    setRequestMode('Landing');
  };

  const onClickLogin = () => {
    setError('');
    setRequestMode('Auth');
  };

  const onClickRegister = () => {
    setError('');
    setRequestMode('Register');
  };

  const handleAuthentication = (data) => {
    setIsLoading(true);
    postLoginWithEmail(data.email, data.password)
      .then((response) => {
        setIsLoading(false);
        if (response.status < 200 || response.status > 299) {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        } else {
          response.json().then((json) => {
            resetGlobalState();
            localStorage.setItem('accessToken', json.accessToken);
            localStorage.setItem('refreshToken', json.refreshToken);
            loadAccountProfile().then(() => {
              setError('');
              setTimeout(() => {
                setRequestMode('Accept');
              }, 200);
            });
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const cleanName = (name) => {
    const words = name.trim().split(/\s+/);
    return words.join(' ').replaceAll('@', '');
  };

  const handleAccountDetail = (data) => {
    const cleanedData = {
      firstName: data.firstName ? cleanName(data.firstName) : data.firstName,
      lastName: data.lastName ? cleanName(data.lastName) : data.lastName,
      password: data.password,
    };
    if (!cleanedData.firstName || !cleanedData.lastName) {
      setError('Invalid input');
    } else {
      setIsLoading(true);
      const queryParams = new URLSearchParams(window.location.search);
      postUserRegister(
        queryParams.get('token'),
        `${cleanedData.firstName} ${cleanedData.lastName}`,
        queryParams.get('email'),
        cleanedData.password,
        cleanedData.firstName,
        cleanedData.lastName,
      ).then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          response.json().then((json) => {
            resetGlobalState();
            localStorage.setItem('accessToken', json.accessToken);
            localStorage.setItem('refreshToken', json.refreshToken);
            loadAccountProfile().then(() => {
              setError('');
              setTimeout(() => {
                setRequestMode('Accept');
              }, 200);
            });
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
    }
  };

  const handleAcceptRequest = () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    postOrgArtistInviteAccept(queryParams.get('token'))
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setError('');
          if (suggestions.ipi || suggestions.isni || suggestions.society?.name) {
            setRequestMode('Suggestions');
          } else {
            setRequestMode('Success');
          }
        } else if (response.status === 400) {
          route('/error/invalidLink');
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const handleSuggestions = (acceptIPI, acceptISNI, acceptSociety) => {
    const newIPI = (suggestions.ipi && acceptIPI) ? suggestions.ipi : AppState.artistProfile.ipi.value;
    const newISNI = (suggestions.isni && acceptISNI) ? suggestions.isni : AppState.artistProfile.isni.value;
    const newSociety = (suggestions.society && acceptSociety) ? suggestions.society : null;

    setIsLoading(true);
    putAccountArtistProfile(newIPI, AppState.artistProfile.ipn.value, newISNI)
      .then((response) => {
        if (response.status === 200) {
          if (!newSociety) {
            loadAccountProfile().then(() => {
              setError('');
              setIsLoading(false);
              setRequestMode('Success');
            });
          } else {
            postAccountSociety(newSociety.name, newSociety.type, newSociety.country)
              .then((response2) => {
                if (response2.status === 200) {
                  loadAccountProfile().then(() => {
                    setError('');
                    setIsLoading(false);
                    setRequestMode('Success');
                  });
                } else {
                  response2.json()
                    .then((json) => setError(json.error ? json.error : response.statusText))
                    .catch(() => setError(response.statusText));
                  setIsLoading(false);
                }
              }).catch((err) => {
                setIsLoading(false);
                setError(err.message);
              });
          }
        } else {
          response.json()
            .then((json) => setError(json.error ? json.error : response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const handleSignOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    resetGlobalState();
    setError('');
    setRequestMode('Landing');
  };

  return (
    <ConversationLayout>
      {(requestMode === 'Landing' || requestMode === 'Accept' || requestMode === 'Suggestions')
      && (
      <Box display="flex" alignItems="center" mb="1.5em">
        <Box mr="1.25em">
          <Logo mb="0rem" mt="0rem" orgImageURL={org.logoUrl} />
        </Box>
        <Box>
          <Text fontSize="1.5em" fontWeight="900">Connect to Publisher</Text>
          <Text fontSize="0.875">{org.name}</Text>
        </Box>
      </Box>
      )}
      {
        {
          Loading:
  <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
    <Spinner size="2em" variant="page" />
  </Box>,

          Landing:
  <Fragment>
    <Text mb="1.5em" fontSize="1rem">
      Once connected, any composition that you attribute to the publisher will automatically be delivered.
    </Text>
    <Text mb="3em" fontSize="1rem">
      Login to view the Publisher Connection Request.  If you are new here, we can create your free account in one quick step.
    </Text>
    <BaseButton
      mb="1.5em"
      btnText="Login"
      fluid
      onClick={onClickLogin}
    />
    <BaseButton
      variant="secondary"
      mb="1.5em"
      btnText="Create My Account"
      fluid
      onClick={onClickRegister}
    />
  </Fragment>,

          Auth:
  <Fragment>
    <Header mb="1em">Welcome Back</Header>
    <LoginForm
      isLoading={isLoading}
      error={error}
      handleAuthentication={handleAuthentication}
    />
    <Box mt="1.5em" display="flex" justifyContent="center">
      <LinkButton
        btnText="Go Back"
        onClick={onBackToLanding}
      />
    </Box>
  </Fragment>,

          Register:
  <Fragment>
    <AccountDetail
      isLoading={isLoading}
      error={error}
      handleAccountDetail={handleAccountDetail}
    />
    <Box mt="2em" display="flex" justifyContent="center">
      <LinkButton
        btnText="Go Back"
        onClick={onBackToLanding}
      />
    </Box>
  </Fragment>,

          Accept: <ConnectPublisherAcceptRequest
            handleAcceptRequest={handleAcceptRequest}
            isLoading={isLoading}
            error={error}
            handleSignOut={handleSignOut}
            registration={reg}
            claims={claims}
            coPub={suggestions.coPub}
          />,

          Suggestions: <ConnectPublisherSuggestions
            handleSuggestions={handleSuggestions}
            suggestions={suggestions}
            isLoading={isLoading}
            error={error}
          />,

          Success: <ConnectPublisherRequestSuccess
            isNewUser={isNew}
            publisher={org}
            allWorksCreated={!!claims.validPeriod?.from}
          />,
        }[requestMode]
      }
    </ConversationLayout>
  );
}

export default ConnectPublisherRequest;
