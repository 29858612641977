/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import { Fragment } from 'preact';

import { deleteMockData } from '@api/admin/admin-mock-data-api';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import Spinner from '@ui-kit/loaders/Spinner';

function AdminActionsClearData({
  closeFunction,
}) {
  const [isClearing, setIsClearing] = useState(false);
  const [clearingError, setClearingError] = useState({ message: null });

  // TODO: Make setError work
  const handleClearData = () => {
    setIsClearing(true);
    deleteMockData()
      .then((response) => {
        setIsClearing(false);
        if (response.status === 200) {
          closeFunction();
        } else {
          response.json()
            .then((json) => setClearingError({ message: json.error || response.statusText }))
            .catch(() => setClearingError({ message: response.statusText }));
        }
      })
      .catch((err) => {
        setIsClearing(false);
        setClearingError({ message: err.message });
      });
  };

  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>

      {!isClearing
        ? (
          <Fragment>
            <ConversationPrompt
              primaryMessage="Clear Demo Data"
              promptLabel="Admin Action"
              mb="1em"
            />
            <ConversationExplanation mb="1.5em" explanation="Demo data will be cleared for the current org that is assigned this data" />

            <BaseButton mb="1.5em" variant="danger" type="submit" btnText="Clear Demo Data" onClick={handleClearData} fluid />
          </Fragment>
        )
        : (
          <Box display="flex" alignItems="center" flexDirection="column">
            <Spinner size="1.25em" variant="page" />
            <Text fontSize="1.25rem" mt="1em">Data is Clearing</Text>
            <Text mt="0.25em">Window will close when complete...</Text>
            {clearingError.message && (
            <AlertMessage
              variant="negative"
              message={clearingError.message}
              mb="1.5em"
            />
            )}
          </Box>
        )}
    </BaseEditOverlay>
  );
}

AdminActionsClearData.propTypes = {
  closeFunction: PropTypes.func.isRequired,
};

export default AdminActionsClearData;
