import { useOrgLogoLink } from './useImageLink';

export function translateActivity(activity, currentUserAccountId) {
  const newActivity = {
    workspace: activity.workspace,
    user: activity.data.from?.name,
    userId: activity.data.from?.id,
    imgExists: activity.data.from?.img_exists,
    createdAt: activity.created_at,
  };

  if (activity.data.from?.id === currentUserAccountId) {
    newActivity.user = 'You';
  }

  let receiver;
  let ofReceiver;
  if (activity.data.to?.name) {
    receiver = (activity.data.to.id === currentUserAccountId) ? 'you' : activity.data.to.name;
    ofReceiver = (activity.data.to.id === currentUserAccountId) ? 'your' : `${activity.data.to.name}'s`;
  }

  // Cases are alphabetically ordered for visibility
  switch (activity.type) {
    case 'amendmentCancelled':
      newActivity.description = 'cancelled the amendment and locked the workspace';
      break;
    case 'amendmentCreated':
      newActivity.description = 'made an amendment and unlocked the workspace';
      break;
    case 'contractCreated':
      newActivity.description = 'created a contract and locked the workspace';
      break;
    case 'contractExecuted':
      newActivity.description = 'The contract was executed';
      break;
    case 'contractRejected':
      newActivity.description = 'rejected the contract and unlocked the workspace';
      newActivity.comment = activity.data.message;
      break;
    case 'contractSigned':
      newActivity.description = 'signed the contract';
      break;
    case 'contributionChanged':
      newActivity.description = `changed ${ofReceiver} contribution and splits`;
      break;
    case 'guestUpdated':
      newActivity.description = `updated information for ${receiver}`;
      break;
    case 'inviteDeleted':
      newActivity.description = `deleted invitation for ${receiver}`;
      break;
    case 'lyricsDeleted':
      newActivity.description = 'deleted the lyrics';
      break;
    case 'lyricsUpdated':
      newActivity.description = 'updated the lyrics';
      break;
    case 'markedInstrumental':
      newActivity.description = 'marked the composition as Instrumental';
      break;
    case 'markedNotInstrumental':
      newActivity.description = 'marked the composition as Not Instrumental';
      break;
    case 'participantLeft':
      newActivity.description = 'left the workspace';
      break;
    case 'participantRemoved':
      newActivity.description = `removed ${receiver} from the workspace`;
      break;
    case 'permissionChanged':
      newActivity.description = `changed ${ofReceiver} permissions`;
      break;
    case 'samplesUpdated':
      newActivity.description = 'updated samples information';
      break;
    case 'signatureRequested':
      newActivity.description = 'sent out signature requests';
      newActivity.comment = activity.data.message;
      break;
    case 'singleAttested':
      newActivity.description = 'signed the single writer attestation';
      break;
    case 'userAcceptedAndJoined':
      newActivity.description = 'joined the workspace';
      break;
    case 'userAddedFile':
      newActivity.description = `added the file ${activity.data.path}`;
      break;
    case 'userAddedRecording':
      newActivity.description = `added the recording ${activity.data.path}`;
      break;
    case 'userAddedUser':
      newActivity.description = `added ${receiver} to the workspace`;
      break;
    case 'userChangedCompDetails':
      newActivity.description = 'updated the composition\'s details';
      break;
    case 'userDeletedFile':
      newActivity.description = `deleted the file ${activity.data.path}`;
      break;
    case 'userDeletedRecording':
      newActivity.description = `deleted the recording ${activity.data.path}`;
      break;
    case 'userInvitedUser':
      newActivity.description = `invited ${receiver} to the workspace`;
      break;
    case 'workspaceCreated':
      newActivity.description = 'created the workspace';
      break;
    case 'workspaceLocked':
      newActivity.description = 'locked the workspace';
      break;
    case 'workspaceUnlocked':
      // Not exist anymore but remains for backward compatibility
      newActivity.description = 'unlocked the workspace';
      break;
    default:
      newActivity.description = (newActivity.user) ? 'did something unexpected' : 'Something unexpected happened';
  }

  if (activity.workspace.id) {
    newActivity.link = `/workspace/${activity.workspace.id}`;
  }

  return newActivity;
}

export function translateNotification(notification) {
  const newNotification = {
    id: notification.id,
    user: notification.data.account?.name,
    userId: notification.data.account?.id,
    imgExists: notification.data.account?.img_exists,
    createdAt: notification.created_at,
    read: notification.read,
  };

  // Cases are alphabetically ordered for visibility
  switch (notification.type) {
    case 'addedToWorkspace':
      newNotification.description = `added you to the composition: ${notification.data.workspace?.name}`;
      newNotification.link = `/workspace/${notification.data.workspace?.id}`;
      break;
    case 'artistRepActivated':
      newNotification.description = 'activated your representative credential';
      break;
    case 'artistRepDeclined':
      newNotification.description = 'cancelled your representative invite';
      break;
    case 'artistRepInvite':
      newNotification.description = 'invited you to become their representative';
      newNotification.link = `/rep/invitation?email=${encodeURIComponent(notification.data.email)}&token=${notification.data.token}`;
      break;
    case 'artistRepInviteAccepted':
      newNotification.description = 'accepted your invitation. Activate this connection to give them access.';
      newNotification.link = `/representation/activate/${notification.data.invite}`;
      break;
    case 'contractExecuted':
      newNotification.description = `The contract was executed for the composition: ${notification.data.workspace?.name}`;
      newNotification.link = `/contracts/contract/${notification.data.workspace?.id}/${notification.data.version}`;
      break;
    case 'invitedToOrg':
      newNotification.description = `You are invited to join the organization: ${notification.data.org.name}`;
      newNotification.orgLogoUrl = notification.data.org.logoExists ? useOrgLogoLink(notification.data.org.id) : null;
      newNotification.link = `/publisher/invitation?email=${encodeURIComponent(notification.data.email)}&token=${notification.data.token}`;
      break;
    case 'invitedToWorkspace':
      newNotification.description = `invited you to the composition: ${notification.data.workspace?.name}`;
      newNotification.link = `/invitation?email=${encodeURIComponent(notification.data.email)}&token=${notification.data.token}`;
      break;
    case 'nudgeForSignature':
      newNotification.description = `is nudging for your signature for the composition: ${notification.data.workspace?.name}`;
      newNotification.link = `/workspace/${notification.data.workspace?.id}`;
      break;
    case 'pubConnectionRequest':
      newNotification.description = `${notification.data.publisher.name} sent you a request to connect`;
      newNotification.orgLogoUrl = notification.data.publisher.logoExists ? useOrgLogoLink(notification.data.publisher.id) : null;
      newNotification.link = `/connect-publisher?email=${encodeURIComponent(notification.data.email)}&token=${notification.data.token}`;
      break;
    case 'removedFromOrg':
      newNotification.userId = null; // To display org logo instead of user avatar in EventList
      newNotification.description = `removed you from the organization: ${notification.data.org.name}`;
      newNotification.orgLogoUrl = notification.data.org.logoExists ? useOrgLogoLink(notification.data.org.id) : null;
      break;
    case 'removedFromWorkspace':
      newNotification.description = `removed you from the composition: ${notification.data.workspace?.name}`;
      break;
    case 'requestForSignature':
      newNotification.description = `requested your signature for the composition: ${notification.data.workspace?.name}`;
      newNotification.link = `/contracts/contract/${notification.data.workspace?.id}/${notification.data.version}`;
      break;
    case 'workspaceDeleted':
      newNotification.description = `deleted the composition: ${notification.data.comp_name}`;
      break;
    default:
      newNotification.description = (newNotification.user) ? 'did something unexpected' : 'Something unexpected happened';
  }

  return newNotification;
}
