import { useState, useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import PropTypes from 'prop-types';
import anime from 'animejs/lib/anime.es';

import AppState from '@state';

import { getPublisherIdentity } from '@api/public/publisher-identity-api';

import ConnectingCopyright from '@animations/identity/ConnectingCopyright';
import SwitchLogo from '@assets/logos/switchchord-logo-main.svg';
import Card from '@distinct-components/cards/baseCard';
import QuestionPrompt from '@distinct-components/feedback/questionPrompt';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import Text from '@ui-kit/typography/text';
import Span from '@ui-kit/typography/span';
import PanelCard from '@distinct-components/cards/panelCard';
import Header from '@ui-kit/typography/header';

import {
  StyledPublicHeader,
  ContentWrapper,
  Content,
  AvatarImgWrapper,
  DidWrapper,
} from './PublisherIdentityPreviewStyles';

function PublisherIdentityPreview({ uuid }) {
  const [previewData, setPreviewData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const loadPublisherIdentity = async () => {
    try {
      const response = await getPublisherIdentity(uuid);
      if (response.status !== 200) {
        try {
          const json = await response.json();
          AppState.exceptions.errorPageError.value = `${json.error || response.statusText} when loading publisher identity`;
          route('/error/exception', true);
        } catch {
          AppState.exceptions.errorPageError.value = `${response.statusText} when loading publisher identity`;
          route('/error/exception', true);
        }
      } else {
        const data = await response.json();
        setPreviewData(data);
      }
    } catch (err) {
      AppState.exceptions.errorPageError.value = `${err.message} when loading publisher identity`;
      route('/error/exception', true);
    }
  };

  const loadAnimation = anime.timeline({
    autoplay: true,
    delay: 200,
  });

  useEffect(async () => {
    document.title = 'Switchchord - Publisher Identity Viewer';
    document.body.style.backgroundColor = 'var(--background)';
    setTimeout(() => {
      anime({
        targets: '#connecting-wrapper .loader-item',
        opacity: [0, 1],
        translateY: [16, 0],
        easing: 'easeOutExpo',
        duration: 700,
        delay: anime.stagger(100),
      });
    }, 200);

    setTimeout(() => {
      loadPublisherIdentity().then(() => {
        setIsLoading(false);
        setTimeout(() => {
          loadAnimation.add({
            targets: '#content .content-item',
            translateY: ['16px', 0],
            opacity: [0, 1],
            duration: 600,
            direction: 'normal',
            delay: anime.stagger(100),
          });
          loadAnimation.play();
        }, 500);
      });
    }, 2700);
  }, []);

  return (
    <Box>
      <StyledPublicHeader id="test">
        <Icon className="logo" size="172px" mr="5em"><SwitchLogo /></Icon>
        <Box>
          <a href="https://switchchord.com" target="_blank" rel="noreferrer">Learn More</a>
        </Box>
      </StyledPublicHeader>
      <ContentWrapper>
        <Content id="content">
          <Box>
            <Box>
              <Box display="flex" justifyContent="space-between">
                <Text mb="0.125em">Identity Viewer</Text>
                <QuestionPrompt questionPrompt="This page represents the current state of this publisher's persistent digital identity" />
              </Box>
              <Card mt="1em" mb="1.5em" variant="panel">
                {isLoading ? (
                  <Box id="connecting-wrapper" className="identity-header" display="flex" justifyContent="center" alignItems="center" minHeight="10em">
                    <Box>
                      <ConnectingCopyright className="loader-item" style={{ opacity: 0 }} />
                      <Box display="flex" alignItems="center" flexDirection="column">
                        <Text mt="0.75em" fontWeight="600" color="var(--trust-text)" className="loader-item" style={{ opacity: 0 }}>
                          Connecting to Publisher Identity
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box id="info-wrapper" minHeight="7em">
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">

                      <Box position="relative">
                        <AvatarImgWrapper avatarImg={previewData.logoURL} />
                      </Box>

                      <Header mb="0.125em">
                        {previewData.name}
                      </Header>

                      <DidWrapper mt="0.5em" color="var(--text-medium-mute)" textAlign="center">
                        {previewData.did}
                      </DidWrapper>

                    </Box>
                  </Box>
                )}
              </Card>
              {!isLoading && (
                <PanelCard
                  mb="1.5em"
                  title="PRO Affiliations"
                  className="content-item"
                  style={{ opacity: 0 }}
                >
                  {previewData.registrations.length > 0 ? (
                    <Box>
                      {previewData.registrations.map((reg) => (
                        <Box mb="1em">
                          <Text fontSize="1rem" color="var(--text-primary)" mb="0.125em" fontWeight="800">{reg.name}</Text>
                          <Box mb="0.5em">
                            <Span fontWeight="600">Affiliated PRO:</Span>
                            &nbsp;&nbsp;
                            <Span>{reg.society || '(Not Provided)'}</Span>
                          </Box>
                          <Box mb="0.5em">
                            <Span fontWeight="600">IPI:</Span>
                            &nbsp;&nbsp;
                            <Span>{reg.ipi || '(Not Provided)'}</Span>
                          </Box>
                          <Box mb="0.5em">
                            <Span fontWeight="600">Address:</Span>
                            &nbsp;&nbsp;
                            <Span>{reg.address || '(Not Provided)'}</Span>
                          </Box>
                          <Box mb="0.5em">
                            <Span fontWeight="600">Contact Email:</Span>
                            &nbsp;&nbsp;
                            <Span>{reg.email || '(Not Provided)'}</Span>
                          </Box>
                        </Box>
                      ))}

                    </Box>
                  ) : (
                    <Span color="var(--text-medium-mute)">No Registration Provided</Span>
                  )}
                </PanelCard>
              )}
            </Box>
          </Box>
        </Content>
      </ContentWrapper>
    </Box>
  );
}

PublisherIdentityPreview.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PublisherIdentityPreview;
