import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const EventWrapper = styled.div(({
  clickable,
}) => `
    padding: 0.625em 0em;
    border-bottom: 1px solid var(--panel-divider);
    cursor: ${clickable ? 'pointer' : 'default'};

    &:last-child {
        border-bottom: none;
    }

    & time {
        font-size: 0.75rem;
        color: var(--text-medium-mute);
    }
`);

export const StyledEventListWrapper = styled.div`
    height: 100%;
    overflow-y: scroll;

    @media screen and (max-width: ${pointBreaks.sm}) {
        max-height: auto;
    }
`;

export const EventAvatarWrapper = styled.div`
    margin-right: 0.75em;
    margin-top: 0.125em;
    display: flex;
    justify-content: center;
`;

export const SystemAvatar = styled.div`
    height: 2em;
    width: 2em;
    border-radius: 50%;
    background: var(--inactive);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const UnreadDot = styled.div`
   height: 0.5em;
   width: 0.5em;
   background: var(--blue-300);
   border-radius: 50%;
   position: absolute;
   transform: translate(-23px, 1px);
`;

export const EventCommentWrapper = styled.p`
   color: var(--text-soft-mute);
   background: var(--inactive);
   border-radius: 0.5em;
   font-size: 0.75rem;
   line-height: 1.7;
   padding: 0.75em 1em;
   font-style: italic;
   font-weight: 500;
`;

export const WorkspaceIndicator = styled.div`
   height: 0.675em;
   width: 0.275em;
   background: var(--active-nav);
   border-radius: 0.125em;
   margin-right: 0.5em;
`;
