/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import TableIllustrationWrapper from './TableIllustrationStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function TableIllustration({
  children,
  ...restProps
}) {
  return (
    <TableIllustrationWrapper {...restProps}>
      {children}
    </TableIllustrationWrapper>
  );
}

TableIllustration.propTypes = {
  children: childrenPropTypes.isRequired,
};

export default TableIllustration;
