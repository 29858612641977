import { useState } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import PanelCard from '@distinct-components/cards/panelCard/PanelCard';
import InputLabel from '@ui-kit/inputs/inputLabel';
import PanelDataCard from '@distinct-components/cards/panelDataCard/PanelDataCard';

import { ACCOUNT_EXPLANATIONS } from '@constants/supportingCopy';

import EditISNI from './sections/editISNI';

function ProfileISNISection() {
  const [isEditingISNI, setIsEditingISNI] = useState(false);

  const onClickEditISNI = () => {
    setIsEditingISNI(!isEditingISNI);
    AppState.artistProfile.isEditingChild.value = !AppState.artistProfile.isEditingChild.value;
  };

  return (
    <Fragment>

      <PanelCard
        title="International Standard Name Identifier (ISNI)"
        description={ACCOUNT_EXPLANATIONS.isni.description}
        minHeight="18em"
      >
        <Box mt="1em" mb="0.5em">
          <InputLabel mb="0.75em" label="Your ISNI" />
          {!AppState.artistProfile.isni.value
            ? (
              <PanelDataCard
                variant="initialize"
                onClick={onClickEditISNI}
              >
                <Text fontWeight="600">Setup Your ISNI</Text>
              </PanelDataCard>
            )
            : (
              <PanelDataCard
                onClick={onClickEditISNI}
              >
                <Text fontWeight="600">{AppState.artistProfile.isni.value}</Text>
              </PanelDataCard>
            )}
        </Box>
      </PanelCard>

      {isEditingISNI && (
      <EditISNI closeFunction={onClickEditISNI} />
      )}

    </Fragment>
  );
}

export default ProfileISNISection;
