/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const NavToggleWrapper = styled.div`
    border: 1px solid var(--border);
    padding: 0.5em 1em;
    margin-right: 1.5em;
    border-radius: 0.5em;
    background: var(--card);
    cursor: pointer;
    min-width: 14em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media screen and (max-width: ${pointBreaks.md}) {
      min-width: 12em;
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
      display: none;
    }
`;

export const ModeToggleItem = styled.div`
    border-bottom: 1px solid var(--border);
    color: var(--text-soft-mute);
    font-size: 0.75rem;
    font-weight: 500;
    width: 100%;
    padding-bottom: 1em;
    margin-bottom: 1.125em;
    cursor: pointer;
`;
