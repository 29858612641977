import styled from 'styled-components';
import {
  space,
} from 'styled-system';
import pointBreaks from '@styles/styleConstants';

const StyledRow = styled.div(({
  cursor,
}) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.75em;
    cursor: ${cursor ? 'pointer' : 'default'};
    border-bottom: 1px solid var(--table-border);
    background:  var(--table-bg);
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
      flex-direction: column;
      border: 1px solid var(--card-divider);
      margin-bottom: 1em;
      border-radius: 0.5em;
      padding: 1em;
      align-items: flex-start;
    }

    .cell {
      font-size: 0.75rem;
      font-weight: 400;
    }
}
`);

export default StyledRow;
