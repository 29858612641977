/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';

import resetGlobalState from '@state/reset';

import FullScreenModal from '@layouts/full-screen/full-screen-modal';
import Box from '@ui-kit/box';

import { getUserRegisterInfo, postUserRegister } from '@api/public/user-register-api';

import loadAccountProfile from '@actions/loadAccountProfile';

import RegisterAnimation from '@animations/wedges/RegisterAnimation';

import AccountDetail from './sections/account-detail';

function Register() {
  const [guestFirstName, setGuestFirstName] = useState('');
  const [guestLastName, setGuestLastName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAnimating, setIsAnimating] = useState(true);
  const [error, setError] = useState('');

  // TEMP INTEGRATION REPORTING
  const handleReportingValue = async (id, email) => {
    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    const data = {
      tahId: id,
      email,
      timestamp: currentTime.toISOString(),
      friendlyTime: formattedTime,
    };

    try {
      const response = await fetch('https://hooks.zapier.com/hooks/catch/12810267/2bzzjbj/', {
        method: 'POST',
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (e) {
      console.error('Error sending data to Zapier:', e);
    }
  };

  useEffect(() => {
    document.title = 'Registration';
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (!token) {
      route('/');
    } else {
      getUserRegisterInfo(params.get('token'), params.get('email'))
        .then((response) => {
          if (response.status === 200) {
            response.json().then((json) => {
              setGuestFirstName(json.firstName);
              setGuestLastName(json.lastName);
            });
          }
        });
    }
  }, []);

  const cleanName = (name) => {
    const words = name.trim().split(/\s+/);
    return words.join(' ').replaceAll('@', '');
  };

  const handleAccountDetail = (data) => {
    const cleanedData = {
      firstName: data.firstName ? cleanName(data.firstName) : data.firstName,
      lastName: data.lastName ? cleanName(data.lastName) : data.lastName,
      password: data.password,
    };
    if (!cleanedData.firstName || !cleanedData.lastName) {
      setError('Invalid input');
    } else {
      setIsLoading(true);
      const queryParams = new URLSearchParams(window.location.search);

      // TEMP REPORTING
      const existingTahId = localStorage.getItem('tahId');
      if (existingTahId) {
        handleReportingValue(existingTahId, queryParams.get('email'));
      }
      // END TEMP REPORTING

      postUserRegister(
        queryParams.get('token'),
        `${cleanedData.firstName} ${cleanedData.lastName}`,
        queryParams.get('email'),
        cleanedData.password,
        cleanedData.firstName,
        cleanedData.lastName,
      ).then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            setError('');
            resetGlobalState();
            localStorage.setItem('accessToken', json.accessToken);
            localStorage.setItem('refreshToken', json.refreshToken);
            loadAccountProfile().then(() => {
              setIsLoading(false);
              route('/personalize');
            });
          });
        } else {
          response.json()
            .then((json) => setError(json.error ? json.error : response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
    }
  };

  return (
    <>
      {isAnimating
        ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
            <RegisterAnimation animationPlaying={setIsAnimating} />
          </Box>
        )
        : (
          <FullScreenModal formWidth="23em">
            <AccountDetail
              isLoading={isLoading}
              error={error}
              handleAccountDetail={handleAccountDetail}
              defaultFirstName={guestFirstName}
              defaultLastName={guestLastName}
            />
          </FullScreenModal>
        )}
    </>
  );
}

export default Register;
