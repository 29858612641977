/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import EditIcon from '@assets/icons/edit-line.svg';
import CloseIcon from '@assets/icons/close-solo.svg';
import Globe from '@assets/icons/globe-line.svg';

import PUBLISHER_TERRITORIES from '@constants/publisherTerritories';

import {
  CardWrapper,
  CardHeader,
  CardBody,
  StyledDetailsRow,
  StyledDetailsWrapper,
  StyledPercentageInput,
  StyledPercentBox,
  StyledTerritorySelect,
  StyledTerritoryBox,
} from './PublisherCardStyles';
import VerifiedBadge from '../../badges/verifiedBadge/VerifiedBadge';

function PublisherCard({
  publisher,
  showSelected,
  handlePublisherSelected,
  handleRemovePublisher,
  handlePublisherEdit,
  publisherOwnership,
  publisherTerritory,
  handlePublisherShareChange,
  handlePublisherTerritoryChange,
  ...restProps
}) {
  const onClickPublisher = () => {
    if (handlePublisherSelected) {
      handlePublisherSelected(publisher);
    }
  };
  return (
    <CardWrapper
      {...restProps}
    >
      <CardHeader>
        <Box flexBasis="80%" pr="1em" className={handlePublisherSelected ? 'cursor-p' : ''} onClick={onClickPublisher}>
          <Text fontWeight="600" color="var(--text-primary)">{publisher.name}</Text>
        </Box>
        {publisher?.isVerified
          ? (
            <VerifiedBadge style={{ transform: 'translateX(9px)' }} />
          )
          : (
            <Box display="flex" justifyContent="flex-end">
              {handlePublisherEdit
          && <Icon pt="3px" size="0.875em" cursor onClick={() => handlePublisherEdit(publisher)}><EditIcon /></Icon>}
              {handleRemovePublisher
          && <Icon size="0.65em" cursor ml="1em" onClick={() => handleRemovePublisher(publisher)}><CloseIcon /></Icon>}
            </Box>
          )}
      </CardHeader>
      <CardBody showCursor={handlePublisherSelected} onClick={onClickPublisher}>
        <StyledDetailsRow>
          <StyledDetailsWrapper>
            <Text fontSize="0.75rem" mr="0.875em">
              Society:
              {' '}
              {publisher.society}
            </Text>
            <Text fontSize="0.75rem" mr="0.875em">
              IPI:
              {' '}
              {publisher.ipi}
            </Text>
          </StyledDetailsWrapper>
        </StyledDetailsRow>
        <Text fontSize="0.75rem" mr="0.875em">
          Address:
          {' '}
          {publisher.address}
        </Text>
        {publisher.ownedPercentage != null
            && (
            <Box display="flex" justifyContent="flex-end" mt="0.5em" mb="0.5em">
              <StyledPercentageInput
                defaultValue={publisher.ownedPercentage}
                id="share"
                name="share"
                type="number"
                maxLength={5}
                inputMode="decimal"
                autoComplete="off"
                onChange={(e) => handlePublisherShareChange(e.target.valueAsNumber, publisher)}
                disabled={publisher.isVerified}
              />
              <StyledPercentBox>%</StyledPercentBox>
            </Box>
            )}
        {publisher.territory
          && (
          <Box display="flex" mb="0.5em" mt="0.5em">
            <StyledTerritoryBox>
              <Icon size="1em" pt="3px"><Globe /></Icon>
            </StyledTerritoryBox>
            <StyledTerritorySelect
              name="territory"
              value={publisher.territory}
              onChange={(e) => handlePublisherTerritoryChange(e.target.value, publisher)}
              disabled={publisher.isVerified}
            >
              <option value="Worldwide">Worldwide</option>
              {PUBLISHER_TERRITORIES.map((t) => (
                <option value={t}>{t}</option>
              ))}
            </StyledTerritorySelect>
          </Box>
          )}
      </CardBody>
    </CardWrapper>
  );
}

PublisherCard.propTypes = {
  publisher: PropTypes.object.isRequired,
  handlePublisherSelected: PropTypes.func,
  handlePublisherShareChange: PropTypes.func,
  handlePublisherTerritoryChange: PropTypes.func,
  handleRemovePublisher: PropTypes.func,
  handlePublisherEdit: PropTypes.func,
  showSelected: PropTypes.bool,
  publisherOwnership: PropTypes.number,
  publisherTerritory: PropTypes.string,
};

PublisherCard.defaultProps = {
  showSelected: false,
  handleRemovePublisher: null,
  handlePublisherSelected: null,
  handlePublisherShareChange: null,
  handlePublisherTerritoryChange: null,
  handlePublisherEdit: null,
  publisherOwnership: null,
  publisherTerritory: null,
};

export default PublisherCard;
