/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';

export const StyledVerifiedBadge = styled.div`
    font-size: 12px;
    font-weight: 500;
    height: 24px;
    background: var(--trust);
    padding: 0em 1em;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5em;
    color: var(--white);
    ${space}
`;
