import { useState } from 'preact/hooks';
import { Fragment } from 'preact';

import Box from '@ui-kit/box';
import Card from '@distinct-components/cards/baseCard';
import Text from '@ui-kit/typography/text';
import AppState from '@state';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState/BaseEmptyState';
// import BasePill from '@ui-kit/pills/basePill';
import PanelCard from '@distinct-components/cards/panelCard';
import PanelDataCard from '@distinct-components/cards/panelDataCard';

import { ACCOUNT_EXPLANATIONS } from '@constants/supportingCopy';

import EditAlias from './sections/editAlias';
import CreateAlias from './sections/createAlias/CreateAlias';

import {
  StyledDetailsRow,
  StyledIdentifiersWrapper,
  // StyledDefaultsWrapper,
} from './ProfileAliasModeStyles';

function ProfileAliasMode() {
  const [isEditingAlias, setIsEditingAlias] = useState(false);
  const [isCreatingAlias, setIsCreatingAlias] = useState(false);

  const [selectedAlias, setSelectedAlias] = useState({
    id: 0, username: '', ipi: '', isni: '', ipn: '', base: false,
  });

  const onClickEditAlias = (alias) => {
    setSelectedAlias(alias);
    setIsEditingAlias(true);
  };

  const onClickCreateAlias = () => {
    setIsCreatingAlias(true);
  };

  const handleResetManageAlias = () => {
    setIsEditingAlias(false);
    setIsCreatingAlias(false);
    setSelectedAlias(null);
  };

  return (
    <Fragment>
      {AppState.aliases.value.length === 0
        ? (
          <Card variant="panel" mb="7em">
            <BaseEmptyState
              title="No Alternate Personas Setup"
              message={ACCOUNT_EXPLANATIONS.aliases.description}
              action={onClickCreateAlias}
              actionText="Create Persona"
              illustration="personas"
            />
          </Card>
        )
        : (
          <PanelCard
            title="Personas:"
            titleCount={AppState.aliases.value.length}
            description={ACCOUNT_EXPLANATIONS.aliases.description}
            descriptionWidth="100%"
            actionText="Add Persona"
            action={onClickCreateAlias}
            mb="1.5em"
          >
            <Box mt="1.5em" minHeight="14em">
              {AppState.aliases.value.reverse().map((alias) => (
                <PanelDataCard mb="1.5em" onClick={() => onClickEditAlias(alias)}>
                  <Text fontWeight="600">
                    {alias.name}
                  </Text>
                  <StyledDetailsRow>
                    <StyledIdentifiersWrapper>
                      {alias.ipi && (
                      <Text fontSize="0.75rem" mr="0.875em">
                        Unique IPI:
                        {' '}
                        {alias.ipi}
                      </Text>
                      )}
                      {alias.isni && (
                      <Text fontSize="0.75rem" mr="0.875em">
                        Unique ISNI:
                        {' '}
                        {alias.isni}
                      </Text>
                      )}
                      {alias.ipn && (
                      <Text fontSize="0.75rem" mr="0.875em">
                        Unique IPN:
                        {' '}
                        {alias.isni}
                      </Text>
                      )}
                    </StyledIdentifiersWrapper>
                    {/* <StyledDefaultsWrapper>
                      {alias.defaultRecording && <BasePill fontSize="0.75rem" fontWeight="500" variant="blue" mr="1em" width="max-content">Recording Default</BasePill>}
                      {alias.defaultComposition && <BasePill fontSize="0.75rem" fontWeight="500" variant="blue" mr="1em" width="max-content">Composition Default</BasePill>}
                    </StyledDefaultsWrapper> */}
                  </StyledDetailsRow>
                </PanelDataCard>
              ))}
            </Box>
          </PanelCard>
        )}
      {isEditingAlias && (
      <EditAlias
        selectedAlias={selectedAlias}
        closeFunction={handleResetManageAlias}
      />
      )}
      {isCreatingAlias && <CreateAlias closeFunction={handleResetManageAlias} />}
    </Fragment>
  );
}

export default ProfileAliasMode;
