import { useState, useRef } from 'preact/hooks';
import AppState from '@state';
import { route } from 'preact-router';

import useClickOutside from '@hooks/useClickOutside';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import ChevronIcon from '@assets/icons/chevron-down.svg';
import PopoverMenu from '@ui-kit/menu/popoverMenu';

import {
  NavToggleWrapper,
  ModeToggleItem,
} from './NavModeToggleStyles';

function NavModeToggle() {
  const [showModeList, setShowModeList] = useState(false);

  const ref = useRef(null);
  useClickOutside(ref, () => setShowModeList(false));

  const handleOrgSelection = (uuid) => {
    route(`/publisher/${uuid}/dashboard`);
    window.location.reload();
  };

  return (
    <NavToggleWrapper onClick={() => setShowModeList(!showModeList)}>
      <Box>
        <Text fontSize="0.675rem" fontWeight="800" lineHeight="1" mb="0.25em">Organization</Text>
        <Text fontSize="0.813rem" lineHeight="1" truncate maxWidth="12em">{AppState.pubOrganization.name.value || '- - -'}</Text>
      </Box>
      <Icon cursor size="0.75em" color="var(--purple-400)"><ChevronIcon /></Icon>
      {showModeList
      && (
        <PopoverMenu ref={ref} transform="translate(-16px,58px)" width="14em">
          {AppState.organizations.value.length > 1 ? (
            AppState.organizations.value.filter((o) => o.uuid !== AppState.pubOrganization.uuid.value).map((org) => (
              <ModeToggleItem onClick={() => handleOrgSelection(org.uuid)}>{org.name}</ModeToggleItem>
            ))
          ) : (
            <Text color="var(--text-medium-mute)" fontSize="0.813rem">No Additional Organizations</Text>
          )}
        </PopoverMenu>
      )}
    </NavToggleWrapper>
  );
}

export default NavModeToggle;
