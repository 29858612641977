import AppState from '@state';
import getCompositions from '@api/restricted/compositions-api';
import useErrorOverlay from '@hooks/useErrorOverlay';

export default async function loadCompositions() {
  try {
    const response = await getCompositions();
    if (response.status !== 200) {
      try {
        const json = await response.json();
        useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading compositions` });
      } catch {
        useErrorOverlay({ errorMessage: `${response.statusText} when loading compositions` });
      }
    } else {
      const json = await response.json();
      AppState.compositions.value = json.compositions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }
  } catch (err) {
    useErrorOverlay({ errorMessage: `${err.message} when loading compositions` });
  }
}
