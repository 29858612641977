import PropTypes from 'prop-types';
import { useEffect } from 'preact/hooks';
import { route } from 'preact-router';

import SwitchLogo from '@assets/logos/switchchord-logo-main.svg';
import CloseSquare from '@assets/icons/close-square.svg';
import Icon from '@ui-kit/icon';

import {
  ModalPage,
  ModalContainer,
  ModalUtilityRow,
  ModalContentWrapper,
  ModalFormWrapper,
  ModalFormWrapperCentered,
} from './FullScreenModalStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function FullScreenModal({
  children,
  closeFunction,
  formWidth,
  formCentered,
  logoPath,
}) {
  useEffect(() => {
    document.body.style.backgroundColor = 'var(--background)';
  }, []);

  const onClickModalLogo = () => {
    if (logoPath) {
      route(logoPath);
    }
  };

  return (
    <ModalPage>
      <ModalContainer>
        <ModalUtilityRow>
          <Icon size="172px" onClick={onClickModalLogo} style={{ cursor: logoPath ? 'pointer' : 'default' }}><SwitchLogo /></Icon>
          {closeFunction
          && <Icon size="1.5em" cursor onClick={closeFunction}><CloseSquare /></Icon>}
        </ModalUtilityRow>
        <ModalContentWrapper formCentered={formCentered}>
          {formCentered
            ? (
              <ModalFormWrapperCentered formWidth={formWidth}>
                {children}
              </ModalFormWrapperCentered>
            )
            : (
              <ModalFormWrapper formWidth={formWidth}>
                {children}
              </ModalFormWrapper>
            )}
        </ModalContentWrapper>
      </ModalContainer>
    </ModalPage>
  );
}

FullScreenModal.propTypes = {
  children: childrenPropTypes.isRequired,
  closeFunction: PropTypes.func,
  formWidth: PropTypes.string,
  formCentered: PropTypes.bool,
  logoPath: PropTypes.string,
};

FullScreenModal.defaultProps = {
  closeFunction: null,
  formWidth: null,
  formCentered: false,
  logoPath: null,
};

export default FullScreenModal;
