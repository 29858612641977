/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
  useCallback, useState, useRef, useEffect,
} from 'preact/hooks';
import { Fragment } from 'preact';

import { getAccountLookupWithEmail } from '@api/restricted/account-lookup-api';

import useDebounce from '@hooks/useDebounce';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseButton from '@ui-kit/buttons/baseButton';
import Spinner from '@ui-kit/loaders/Spinner';
import BreakLine from '@ui-kit/dividers/breakLine';
import Avatar from '@ui-kit/avatar';
import AlertMessage from '@ui-kit/alert/Alert';
import MailWedge from '@animations/wedges/MailWedge';

function AddPubDetails({
  setRecipient,
  recipient,
  setStep,
  ...restProps
}) {
  const [emailToInvite, setEmailToInvite] = useState();
  const [existingUser, setExistingUser] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const [error, setError] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const searchRef = useRef(null);

  useEffect(() => {
    if (recipient) {
      searchRef.current.value = recipient.email;
      setEmailToInvite(recipient.email);
      if (recipient.user?.id) {
        setExistingUser(recipient.user);
      }
    }
  }, []);

  const validateEmail = (email) => String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const handleLookupApiCall = (email) => {
    getAccountLookupWithEmail(email)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            setError('');
            const existing = json.map((user) => ({
              id: user.accountId,
              firstName: user.firstName,
              lastName: user.lastName,
              username: user.username,
              imgURL: user.imgURL,
              isVerified: user.isVerified,
              email: user.email,
            }));
            setExistingUser(existing[0] || null);
            setIsSearching(false);
          });
        } else if (response.status === 202) {
          setExistingUser(null);
          setIsSearching(false);
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsSearching(false);
        }
      })
      .catch((err) => {
        setError(err.message);
        setIsSearching(false);
      });
  };

  const handleChangeEmailToInvite = (event) => {
    event.persist();
    const userInput = event.target.value;

    setExistingUser(null);

    if (validateEmail(userInput) != null) {
      setIsSearching(true);
      setEmailToInvite(userInput.toLowerCase());
      handleLookupApiCall(userInput.toLowerCase());
    } else {
      setEmailToInvite(null);
    }
  };

  const optimizedFn = useCallback(useDebounce(handleChangeEmailToInvite), []);

  const onClickContinue = () => {
    setError('');
    setIsSaving(true);
    const repDetails = {
      user: existingUser || null,
      email: emailToInvite,
    };
    setRecipient(repDetails);
    setTimeout(() => {
      setIsSaving(false);
      setStep('terms');
    }, 800);
  };

  return (
    <Box {...restProps}>
      <BreakLine mb="1.25em" mt="0.5em" color="var(--panel-info-border)" />
      <Box>
        <InputLabel label="Enter Email" />
        <Box display="flex" flexDirection="row-reverse" alignItems="center" mb="1.5em" position="relative">
          <BaseInput
            ref={searchRef}
            id="userTypeAhead"
            name="userTypeAhead"
            placeholder="pubContact@publisher.com"
            onChange={optimizedFn}
            type="email"
            fluid
            required
            autoComplete="off"
          />
          <Box style={{ position: 'absolute', right: 16 }}>
            {(isSearching) && <Spinner variant="fieldLoader" size="1.125em" />}
          </Box>
        </Box>
      </Box>

      {(emailToInvite && !isSearching) && (
        <Fragment>
          {!existingUser?.id ? (
            <Box display="flex" alignItems="center">
              <Box mr="0.75em">
                <MailWedge />
              </Box>
              <Box>
                <Text fontWeight="600" fontSize="0.875rem">
                  Organization Not Registered
                </Text>
                <Text fontSize="0.813rem">We will send an invite to get them registered.</Text>
              </Box>
            </Box>
          ) : (
            <Box display="flex" mb="1.5em" alignItems="center">

              {/* TODO: UPDATE WITH ORGANIZATION ELEMENTS */}
              <Box mr="0.75em">
                <Avatar size="3em" mb="1em" imgURL={existingUser.imgURL} isVerified={existingUser.isVerified} checkSize="1em" checkY="4px" checkX="-2px" />
              </Box>

              <Box>
                <Text fontWeight="600" fontSize="0.75rem">
                  Registered Organization Found
                </Text>

                {/* TODO: UPDATE WITH ORGANIZATION ELEMENTS */}
                <Text>
                  {existingUser.firstName}
                  {' '}
                  {existingUser.lastName}
                </Text>

              </Box>
            </Box>
          )}

          {error && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}

          <Fragment>
            <BreakLine mb="1.5em" mt="0.5em" color="var(--border)" />
            <Box display="flex" justifyContent="flex-end">
              <BaseButton
                mb="1.5em"
                btnText="Continue"
                onClick={onClickContinue}
                disabled={isSaving}
                isLoading={isSaving}
                fluid
                decoration="arrow"
              />
            </Box>
          </Fragment>
        </Fragment>
      )}
    </Box>
  );
}

AddPubDetails.propTypes = {
  setStep: PropTypes.func.isRequired,
  setRecipient: PropTypes.func.isRequired,
  recipient: PropTypes.object,
};

AddPubDetails.defaultProps = {
  recipient: null,
};

export default AddPubDetails;
