import { useState } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import Text from '@ui-kit/typography/text';
import Span from '@ui-kit/typography/span';
import Box from '@ui-kit/box';
import PanelCard from '@distinct-components/cards/panelCard';
import InputLabel from '@ui-kit/inputs/inputLabel';
import PanelDataCard from '@distinct-components/cards/panelDataCard';

import { ACCOUNT_EXPLANATIONS } from '@constants/supportingCopy';

import ManageSocietyOverlay from '@shared-overlays/manageSocietyOverlay/ManageSocietyOverlay';
import EditWriterIPI from './sections/editWriterIPI/EditWriterIPI';

function ProfileWriterSection() {
  const [isEditingSociety, setIsEditingSociety] = useState(false);
  const [isEditingIPI, setIsEditingIPI] = useState(false);

  const onClickEditSociety = () => {
    setIsEditingSociety(!isEditingSociety);
    AppState.artistProfile.isEditingChild.value = !AppState.artistProfile.isEditingChild.value;
  };

  const onClickEditIPI = () => {
    setIsEditingIPI(!isEditingIPI);
    AppState.artistProfile.isEditingChild.value = !AppState.artistProfile.isEditingChild.value;
  };

  return (
    <Fragment>

      <PanelCard
        title="Songwriter Information"
        description={ACCOUNT_EXPLANATIONS.songwriting.description}
        mb="1.5em"
      >
        <Box mt="1.5em" mb="1.5em">
          <InputLabel mb="0.75em" label="Collection Society" />
          {AppState.artistProfile.swCollSociety.value.length === 0
            ? (
              <PanelDataCard
                variant={AppState.artistProfile.societyCritical.value ? 'warning' : 'initialize'}
                onClick={onClickEditSociety}
              >
                <Text fontWeight="600">Setup Writer Society</Text>
              </PanelDataCard>
            )
            : (
              <PanelDataCard
                onClick={onClickEditSociety}
              >
                <Span fontWeight="600">{AppState.artistProfile.swCollSociety.value.map((society) => society.name).join(', ')}</Span>
              </PanelDataCard>
            )}
        </Box>

        <Box mt="1em" mb="0.5em">
          <InputLabel mb="0.75em" label="Songwriter IPI Number" />
          {!AppState.artistProfile.ipi.value
            ? (
              <PanelDataCard
                variant={AppState.artistProfile.ipiCritical.value ? 'warning' : 'initialize'}
                onClick={onClickEditIPI}
              >
                <Text fontWeight="600">Setup Writer IPI Number</Text>
              </PanelDataCard>
            )
            : (
              <PanelDataCard
                onClick={onClickEditIPI}
              >
                <Text fontWeight="600">{AppState.artistProfile.ipi.value}</Text>
              </PanelDataCard>
            )}
        </Box>
      </PanelCard>

      {isEditingSociety && (
        <ManageSocietyOverlay closeFunction={onClickEditSociety} />
      )}

      {isEditingIPI && (
      <EditWriterIPI closeFunction={onClickEditIPI} />
      )}

    </Fragment>
  );
}

export default ProfileWriterSection;
