/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useRef } from 'preact/hooks';
// import AppState from '@state';

import useClickOutside from '@hooks/useClickOutside';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import CloseIcon from '@assets/icons/close-solo.svg';

import {
  StyledToolsCard,
  ToolsCardHeader,
  ClosePanelWrapper,
} from './ViewCompositionToolsStyles';
import ToolsInternalStatus from './sections/toolsInternalStatus';
import ToolsExports from './sections/toolsExports';
import ToolsInternalTags from './sections/toolsInternalTags/ToolsInternalTags';
import ToolsManageLists from './sections/toolsManageLists/ToolsManageLists';
import ToolsComments from './sections/toolsComments/ToolsComments';

function ViewCompositionTools({
  compositionId,
  internalData,
  loadInternalData,
  showToolPanel,
  setShowToolsPanel,
  composition,
}) {
  const toolsRef = useRef();
  useClickOutside(toolsRef, () => setShowToolsPanel(false));

  return (
    <StyledToolsCard showTools={showToolPanel} variant="panel" mb="1.5em">
      <Box height="100%" ref={toolsRef}>

        <ToolsCardHeader>
          <Text fontSize="1rem" fontWeight="500" color="var(--text-primary)">Manage Composition</Text>
          <ClosePanelWrapper>
            <Icon onClick={() => setShowToolsPanel(false)} size="0.75em" color="var(--text-primary)" cursor><CloseIcon /></Icon>
          </ClosePanelWrapper>
        </ToolsCardHeader>

        <ToolsExports composition={composition} loadInternalData={loadInternalData} exportedStatus={internalData.exported} />

        <ToolsInternalStatus compositionId={compositionId} loadInternalData={loadInternalData} internalStatus={internalData.status} />

        {/* <ToolsAssignedUsers assignedUsers={internalData.assignedUsers} /> */}

        <ToolsInternalTags compositionId={compositionId} loadInternalData={loadInternalData} internalTags={internalData.tags} />

        <ToolsManageLists compositionId={compositionId} loadInternalData={loadInternalData} assignedLists={internalData.lists} />

        <ToolsComments compositionId={compositionId} loadInternalData={loadInternalData} comments={internalData.comments} />

      </Box>
    </StyledToolsCard>
  );
}

ViewCompositionTools.propTypes = {
  compositionId: PropTypes.number.isRequired,
  internalData: PropTypes.object.isRequired,
  loadInternalData: PropTypes.func.isRequired,
  showToolPanel: PropTypes.bool.isRequired,
  setShowToolsPanel: PropTypes.func.isRequired,
  composition: PropTypes.object.isRequired,
};

export default ViewCompositionTools;
