import { workspaceRecordingsUrl, workspaceRecordingPublicUrl } from '../urls';
import refresh from '../refresh';

export async function postWorkspaceRecording(workspaceId, name, path, contentType, size, type, isPrivate, file) {
  const formData = new FormData();

  formData.append('workspace_id', workspaceId);
  formData.append('name', name);
  formData.append('path', path);
  formData.append('content_type', contentType);
  formData.append('size', size);
  formData.append('type', type);
  formData.append('private', isPrivate);
  formData.append('recording', file);

  return refresh(async () => {
    const response = await fetch(workspaceRecordingsUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: formData,
    });
    return response;
  });
}

export async function deleteWorkspaceRecording(workspaceId, recordingId) {
  const data = {
    workspace: workspaceId,
    recording: recordingId,
  };
  return refresh(async () => {
    const response = await fetch(workspaceRecordingsUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function getWorkspaceRecordingFile(workspaceUUID, recordingId) {
  const url = `${workspaceRecordingPublicUrl}?${new URLSearchParams({
    uuid: workspaceUUID,
    id: recordingId,
  })}`;
  return refresh(async () => {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}
