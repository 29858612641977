import PropTypes from 'prop-types';

import Card from '@distinct-components/cards/baseCard';
import Icon from '@ui-kit/icon';
import EditIcon from '@assets/icons/edit-line.svg';
import AddIcon from '@assets/icons/add-chip.svg';
import InfoIcon from '@assets/icons/info-circle.svg';
import LockPrompt from '@distinct-components/feedback/lockPrompt';
import Box from '@ui-kit/box';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';
import Text from '@ui-kit/typography/text';

import {
  CardHeaderWrapper,
  WorkspaceCardHeader,
  CardActionsWrapper,
  CardBodyWrapper,
} from './WorkspaceCardStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function WorkspaceCard({
  children,
  title,
  titleCount,
  handleEditSection,
  handleAddToSection,
  handleInfoSection,
  handleToggleSection,
  isCardLocked,
  lockedText,
  showCardActions,
  isToggleLoading,
  isToggled,
  toggleLabel,
  ...restProps
}) {
  return (
    <Card padding="0em" variant="overflow" {...restProps}>
      <CardHeaderWrapper>
        <WorkspaceCardHeader>
          {title}
          {' '}
          {titleCount && titleCount}
        </WorkspaceCardHeader>
        {showCardActions
        && (
        <CardActionsWrapper>
          {(handleEditSection && !isCardLocked)
          && <Icon size="1em" pt="0.25em" pr="0.25em" cursor onClick={handleEditSection}><EditIcon /></Icon>}
          {(handleAddToSection && !isCardLocked)
          && <Icon size="1.45em" pt="0.25em" pr="0.25em" cursor onClick={handleAddToSection}><AddIcon /></Icon>}
          {handleInfoSection
          && <Icon size="1em" pt="0.25em" pr="0.25em" cursor onClick={handleInfoSection}><InfoIcon /></Icon>}
          {isCardLocked
          && (
          <LockPrompt
            lockPrompt={lockedText}
          />
          )}
          {(handleToggleSection && !isCardLocked)
          && (
            <Box display="flex">
              <Text mr="0.75em" color="var(--text-soft-mute)" fontSize="0.875rem">{toggleLabel || 'Enabled'}</Text>
              <ToggleSwitch id="sample">
                <input
                  type="checkbox"
                  name="workspace"
                  disabled={isToggleLoading}
                  id="sample"
                  checked={isToggled}
                  onClick={(e) => handleToggleSection(e)}
                />
              </ToggleSwitch>
            </Box>
          )}
        </CardActionsWrapper>
        )}
      </CardHeaderWrapper>
      <CardBodyWrapper>
        {children}
      </CardBodyWrapper>
    </Card>
  );
}

WorkspaceCard.propTypes = {
  children: childrenPropTypes.isRequired,
  title: PropTypes.string.isRequired,
  titleCount: PropTypes.number,
  handleEditSection: PropTypes.func,
  handleAddToSection: PropTypes.func,
  handleInfoSection: PropTypes.func,
  handleToggleSection: PropTypes.func,
  isCardLocked: PropTypes.bool,
  lockedText: PropTypes.string,
  showCardActions: PropTypes.bool,
  isToggleLoading: PropTypes.bool,
  isToggled: PropTypes.bool,
  toggleLabel: PropTypes.string,
};

WorkspaceCard.defaultProps = {
  titleCount: null,
  handleEditSection: null,
  handleAddToSection: null,
  handleInfoSection: null,
  handleToggleSection: null,
  isCardLocked: false,
  lockedText: null,
  showCardActions: true,
  isToggleLoading: null,
  isToggled: null,
  toggleLabel: null,
};

export default WorkspaceCard;
