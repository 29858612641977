/* eslint-disable object-curly-newline */
import AppState from '@state';
import PropTypes from 'prop-types';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Icon from '@ui-kit/icon';
import OrgLogoPlaceholderIcon from '@assets/logos/music-org-empty-logo.svg';
import Logo from '@ui-kit/logo/Logo';
import BlueCheck from '@assets/icons/blue-check.svg';

import { EmptyOrgWrapper } from './ProfilePublisherCardStyles';

function ProfilePublisherCard({ publisher, ...restProps }) {
  return (
    <Box display="flex" {...restProps}>
      <Box mr="0.938rem" position="relative">
        {publisher.isVerified
        && <Icon size="1.125rem" style={{ position: 'absolute', top: '-3px', right: '-6px' }}><BlueCheck /></Icon>}
        {publisher.logoURL ? (
          <Logo orgImageURL={publisher.logoURL} size="4.5rem" border="none" />
        ) : (
          <EmptyOrgWrapper>
            <Icon><OrgLogoPlaceholderIcon /></Icon>
          </EmptyOrgWrapper>
        )}
      </Box>
      <Box>
        <Text fontWeight="600">{publisher.name || AppState.publishers.value[publisher.id].name}</Text>

        <Box display="flex">
          <Text color="var(--text-secondary)" fontSize="0.75rem" mr="0.875em">
            Society:
            {' '}
            {publisher.society || (publisher.id ? AppState.publishers.value[publisher.id].society : '')}
          </Text>
          <Text color="var(--text-secondary)" fontSize="0.75rem" mr="0.875em">
            IPI:
            {' '}
            {publisher.ipi || (publisher.id ? AppState.publishers.value[publisher.id].ipi : '')}
          </Text>
        </Box>

        {publisher.share > 0
          && (
          <Text color="var(--text-secondary)" fontSize="0.75rem">
            Share:
            {' '}
            {publisher.share}
            %
          </Text>
          )}

        {publisher.territory
          && (
          <Text color="var(--text-secondary)" fontSize="0.75rem">
            Administration:
            {' '}
            {publisher.territory}
          </Text>
          )}
      </Box>
    </Box>
  );
}

ProfilePublisherCard.propTypes = {
  publisher: PropTypes.object.isRequired,
};

export default ProfilePublisherCard;
