/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import AppState from '@state';

import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BreakLine from '@ui-kit/dividers/breakLine';
import BasePillTypeAhead from '@distinct-components/typeAheadInputs/basePillTypeAhead';

function FilterStatus({
  handleStatusApplied,
  appliedStatus,
}) {
  return (
    <Box mb="1.5rem">
      <InputLabel mb="1em" label="Internal Status is one of:" />
      <BasePillTypeAhead
        availableOptions={AppState.pubOrganization.customStatuses.value}
        selectedOptions={appliedStatus}
        handleChange={(data) => handleStatusApplied(data)}
      />
      <BreakLine mt="1.5em" color="var(--panel-info-border)" />
    </Box>
  );
}

FilterStatus.propTypes = {
  handleStatusApplied: PropTypes.func.isRequired,
  appliedStatus: PropTypes.array,
};

FilterStatus.defaultProps = {
  appliedStatus: null,
};

export default FilterStatus;
