/* eslint-disable import/prefer-default-export */
import { accountProfilePictureUploadUrl } from '../urls';
import refresh from '../refresh';

export async function postAccountProfilePicture(path, contentType, size, file) {
  const formData = new FormData();

  formData.append('path', path);
  formData.append('content_type', contentType);
  formData.append('size', size);
  formData.append('img', file);

  return refresh(async () => {
    const response = await fetch(accountProfilePictureUploadUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: formData,
    });
    return response;
  });
}
