/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import { Fragment } from 'preact';

import useIsoDateFormat from '@hooks/useIsoDateFormat';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Avatar from '@ui-kit/avatar';
import Icon from '@ui-kit/icon';
import ExpandIcon from '@assets/icons/list-expand.svg';
import CollapseIcon from '@assets/icons/list-collapse.svg';
import VerifiedBadge from '@distinct-components/badges/verifiedBadge/VerifiedBadge';
import IdentifierDataRow from '@distinct-components/dataDisplay/identifierDataRow';

import {
  CardWrapper,
  CardHeader,
  CardBodyRow,
  StyledRowLabel,
  StyledLabel,
  StyledData,
  RowActionsWrapper,
  CardBodyRowData,
  RowLabelWrapper,
} from './WriterCompositionSummaryCardStyles';

function WriterCompositionSummaryCard({
  writer,
  handleSelectedWriter,
  ...restProps
}) {
  const [expandedOwnerRow, setExpandedOwnerRow] = useState();
  const [expandedAdminRow, setExpandedAdminRow] = useState();
  const [isIdentityExpanded, setIsIdentityExpanded] = useState(false);

  const d = useIsoDateFormat;

  const onClickToggleOwner = (index) => {
    setExpandedAdminRow(null);
    setIsIdentityExpanded(false);
    if (expandedOwnerRow === index) {
      setExpandedOwnerRow(null);
    } else {
      setExpandedOwnerRow(index);
    }
  };

  const onClickToggleAdmin = (index) => {
    setExpandedOwnerRow(null);
    setIsIdentityExpanded(false);
    if (expandedAdminRow === index) {
      setExpandedAdminRow(null);
    } else {
      setExpandedAdminRow(index);
    }
  };

  const onClickToggleExpandIdentity = () => {
    setExpandedOwnerRow(null);
    setExpandedAdminRow(null);
    setIsIdentityExpanded(!isIdentityExpanded);
  };

  const onClickWriter = (w) => {
    if (handleSelectedWriter) {
      handleSelectedWriter(w);
    }
  };

  const onClickWriterDid = () => {
    const link = `${window.location.origin}/identity-viewer/artist/${writer.uuid}`;
    window.open(link, '_blank');
  };

  const onClickPubDid = (uuid) => {
    const link = `${window.location.origin}/identity-viewer/publisher/${uuid}`;
    window.open(link, '_blank');
  };

  return (
    <CardWrapper
      {...restProps}
    >
      <CardHeader>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" className={handleSelectedWriter ? 'cursor-p' : ''} onClick={() => onClickWriter(writer)}>
            <Box mr="1em" alignSelf="flex-start">
              <Avatar
                backgroundColor="var(--inactive)"
                borderColor="var(--inactive)"
                size="3.5em"
                imgURL={writer.avatarURL}
                isVerified={writer?.isVerified}
                checkSize="1.125em"
                checkY="4px"
              />
            </Box>
            <Box>
              <Text fontSize="1rem" fontWeight="600">
                {writer.legalName}
              </Text>
              <IdentifierDataRow writer={writer} />
            </Box>
          </Box>
          <Box id="contribution-highlight" display="flex" flexDirection="column" alignItems="flex-end">
            <Text fontWeight="800" fontSize="1rem">
              {writer.share}
              %
            </Text>
            <Text fontSize="0.813rem" color="var(--text-medium-mute)">
              {writer.contribution}
            </Text>
          </Box>
        </Box>
      </CardHeader>

      <CardBodyRow className="contribution-row">
        <Box>
          <RowLabelWrapper>
            <StyledRowLabel>Composition</StyledRowLabel>
          </RowLabelWrapper>
          <Box display="flex">
            <Box mr="0.75em">
              <StyledLabel>Contribution:</StyledLabel>
                &nbsp;
              <StyledData>{writer.contribution}</StyledData>
            </Box>
            <Box mr="0.75em">
              <StyledLabel>Split:</StyledLabel>
                &nbsp;
              <StyledData>
                {writer.share}
                %
              </StyledData>
            </Box>
          </Box>
        </Box>
      </CardBodyRow>

      {writer.persona?.name && (
        <CardBodyRow>
          <Box>
            <RowLabelWrapper>
              <StyledRowLabel>
                Written Using Persona
              </StyledRowLabel>
            </RowLabelWrapper>
            <Box display="flex" flexWrap="wrap">
              <Box mr="0.75em">
                <StyledLabel>Persona:</StyledLabel>
                &nbsp;
                <StyledData>{writer.persona.name}</StyledData>
              </Box>
              {writer.persona.ipi
              && (
              <Box mr="0.75em">
                <StyledLabel>IPI:</StyledLabel>
                &nbsp;
                <StyledData>{writer.persona.ipi}</StyledData>
              </Box>
              )}
              {writer.persona.isni
              && (
              <Box mr="0.75em" id="isni">
                <StyledLabel>ISNI:</StyledLabel>
                &nbsp;
                <StyledData>
                  {writer.persona.isni}
                </StyledData>
              </Box>
              )}
            </Box>
          </Box>
        </CardBodyRow>

      )}

      {writer.pubPreset?.name ? (
        <Box>
          {writer.pubPreset.owners.map((ownerPub, index) => (
            <Fragment>
              <CardBodyRow className="cursor-p" isExpanded={expandedOwnerRow === index} onClick={() => onClickToggleOwner(index)}>

                <RowLabelWrapper>
                  <StyledRowLabel>Original Publisher</StyledRowLabel>
                  <RowActionsWrapper>
                    {ownerPub.isVerified
                    && <VerifiedBadge mr="1em" />}
                    <Box pt="2px">
                      {expandedOwnerRow === index
                        ? <Icon size="1.5em" cursor color="var(--collapse-toggle)"><CollapseIcon /></Icon>
                        : <Icon size="1.5em" cursor color="var(--collapse-toggle)"><ExpandIcon /></Icon>}
                    </Box>
                  </RowActionsWrapper>
                </RowLabelWrapper>
                <Box mr="0.75em" mt={expandedOwnerRow === index ? '0.5em' : '0em'}>
                  <StyledLabel>{ownerPub.name}</StyledLabel>
                </Box>

              </CardBodyRow>
              {expandedOwnerRow === index
              && (
              <CardBodyRowData>
                <Box className="cursor-p" onClick={() => onClickToggleOwner(index)}>
                  <Text mt="0.25em">
                    Society:
                    {' '}
                    {ownerPub.society}
                  </Text>
                  <Text>
                    IPI:
                    {' '}
                    {ownerPub.ipi}
                  </Text>
                  <Text>
                    Address:
                    {' '}
                    {ownerPub.address}
                  </Text>
                  <Text mb="0.75em">
                    Owns:
                    {' '}
                    {ownerPub.ownedPercentage}
                    %
                  </Text>
                </Box>

                {ownerPub.isVerified
                && (
                  <Fragment>
                    <Box>
                      <StyledLabel color="var(--trust-text)">Verification Source:</StyledLabel>
                  &nbsp;
                      <StyledData>
                        Verifiable Credential issued to Songwriter.
                      </StyledData>
                    </Box>
                    <Box mt="0.5em">
                      <StyledData color="var(--text-medium-mute)" className="break-word cursor-p" onClick={() => onClickPubDid(ownerPub.uuid)}>
                        {ownerPub.did}
                      </StyledData>
                    </Box>
                  </Fragment>
                )}

              </CardBodyRowData>
              )}
            </Fragment>
          ))}
        </Box>
      ) : (
        <CardBodyRow>
          <Box>
            <RowLabelWrapper>
              <StyledRowLabel>Original Publisher</StyledRowLabel>
            </RowLabelWrapper>
            <StyledData>Not Defined</StyledData>
          </Box>
        </CardBodyRow>
      )}

      {writer.pubPreset?.name ? (
        <Box>
          {writer.pubPreset.admins.map((adminPub, index) => (
            <Fragment>
              <CardBodyRow className="cursor-p" isExpanded={expandedAdminRow === index} onClick={() => onClickToggleAdmin(index)}>

                <RowLabelWrapper>
                  <StyledRowLabel>Publishing Administration</StyledRowLabel>
                  <RowActionsWrapper>
                    {adminPub.isVerified
                    && <VerifiedBadge mr="1em" />}
                    <Box pt="2px">
                      {expandedAdminRow === index
                        ? <Icon size="1.5em" cursor color="var(--collapse-toggle)"><CollapseIcon /></Icon>
                        : <Icon size="1.5em" cursor color="var(--collapse-toggle)"><ExpandIcon /></Icon>}
                    </Box>
                  </RowActionsWrapper>
                </RowLabelWrapper>
                <Box mr="0.75em" mt={expandedAdminRow === index ? '0.5em' : '0em'}>
                  <StyledLabel>{adminPub.name}</StyledLabel>
                </Box>

              </CardBodyRow>
              {expandedAdminRow === index
              && (
              <CardBodyRowData>
                <Box className="cursor-p" onClick={() => onClickToggleAdmin(index)}>
                  <Text mt="0.25em">
                    Society:
                    {' '}
                    {adminPub.society}
                  </Text>
                  <Text>
                    IPI:
                    {' '}
                    {adminPub.ipi}
                  </Text>
                  <Text>
                    Address:
                    {' '}
                    {adminPub.address}
                  </Text>
                  <Text mb="0.75em">
                    Territory:
                    {' '}
                    {adminPub.territory}
                  </Text>
                </Box>

                {adminPub.isVerified
                 && (
                 <Fragment>
                   <Box>
                     <StyledLabel color="var(--trust-text)">Verification Source:</StyledLabel>
                    &nbsp;
                     <StyledData>
                       Verifiable Credential issued to Songwriter.
                     </StyledData>
                   </Box>
                   <Box mt="0.5em">
                     <StyledData color="var(--text-medium-mute)" className="break-word cursor-p" onClick={() => onClickPubDid(adminPub.uuid)}>
                       {adminPub.did}
                     </StyledData>
                   </Box>
                 </Fragment>
                 )}

              </CardBodyRowData>
              )}
            </Fragment>
          ))}
        </Box>
      ) : (
        <CardBodyRow>
          <Box>
            <RowLabelWrapper>
              <StyledRowLabel>Publishing Administration</StyledRowLabel>
            </RowLabelWrapper>
            <StyledData>Not Defined</StyledData>
          </Box>
        </CardBodyRow>
      )}

      {writer.isVerified ? (
        <Box>
          <CardBodyRow className="cursor-p" isExpanded={isIdentityExpanded} onClick={onClickToggleExpandIdentity}>
            <RowLabelWrapper>
              <StyledRowLabel>Human Verification</StyledRowLabel>
              <RowActionsWrapper>
                <VerifiedBadge mr="1em" />
                <Box pt="2px">
                  {isIdentityExpanded
                    ? <Icon size="1.5em" cursor color="var(--collapse-toggle)"><CollapseIcon /></Icon>
                    : <Icon size="1.5em" cursor color="var(--collapse-toggle)"><ExpandIcon /></Icon>}
                </Box>
              </RowActionsWrapper>
            </RowLabelWrapper>
            <Box mr="0.75em" mt={isIdentityExpanded ? '0.5em' : '0em'}>
              <StyledLabel>
                Verified Name:
              </StyledLabel>
                  &nbsp;
              <StyledData>
                {writer.verifiedFirstName}
                {' '}
                {writer.verifiedLastName}
              </StyledData>
            </Box>

          </CardBodyRow>
          {isIdentityExpanded
              && (
              <CardBodyRowData onClick={() => onClickToggleExpandIdentity}>
                <Text mt="0.25em">
                  Country:
                  {' '}
                  {writer.verifiedCountry}
                </Text>
                <Text>
                  Address: Verified (Private)
                </Text>
                <Text>
                  Date of Birth: Verified (Private)
                </Text>
                <Text mb="0.75em">
                  Verified On:
                  {' '}
                  {d(writer.verifiedOn)}
                </Text>

                <Box>
                  <StyledLabel color="var(--trust-text)">Verification Source:</StyledLabel>
                    &nbsp;
                  <StyledData>
                    Stripe Biometric and Document Verification
                  </StyledData>
                </Box>

              </CardBodyRowData>
              )}
        </Box>
      ) : (
        <CardBodyRow>
          <Box>
            <RowLabelWrapper>
              <StyledRowLabel>Human Verification</StyledRowLabel>
            </RowLabelWrapper>
            <StyledData>Not Verified</StyledData>
          </Box>
        </CardBodyRow>
      )}

      <CardBodyRow className="no-border">
        <Box>
          <StyledData color="var(--text-medium-mute)" className="break-word cursor-p" onClick={onClickWriterDid}>
            {writer.did}
          </StyledData>
        </Box>
      </CardBodyRow>

    </CardWrapper>
  );
}

WriterCompositionSummaryCard.propTypes = {
  writer: PropTypes.object.isRequired,
  handleSelectedWriter: PropTypes.func,
};

WriterCompositionSummaryCard.defaultProps = {
  handleSelectedWriter: null,
};

export default WriterCompositionSummaryCard;
