/* eslint-disable no-param-reassign */
import AppState from '@state';
import { getAccount } from '@api/restricted/account-api';
import useErrorOverlay from '@hooks/useErrorOverlay';

export default async function loadAccountProfile() {
  try {
    const response = await getAccount();
    if (response.status !== 200) {
      try {
        const json = await response.json();
        useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading profile` });
      } catch {
        useErrorOverlay({ errorMessage: `${response.statusText} when loading profile` });
      }
    } else {
      const json = await response.json();

      AppState.accountId.value = json.account_id;

      if (json.repSession?.repTasks) {
        AppState.repSession.isActive.value = true;
        AppState.repSession.repName.value = json.repSession.repName;
        AppState.repSession.repTasks.value = json.repSession.repTasks;
        AppState.repSession.powerOfAttorney.value = json.repSession.powerOfAttorney;
      }

      AppState.aliases.value = json.aliases;
      AppState.organizations.value = json.organizations.map((org) => ({
        uuid: org.uuid,
        name: org.name,
        logoUrl: org.logoURL,
      }));
      AppState.writer.value = json.writer;

      AppState.isRepresentative.value = json.isRep;
      AppState.repProfile.subscription.value = json.repProfile.subscription;
      AppState.repProfile.maxArtistsReached.value = json.repProfile.maxArtistsReached;

      AppState.subscribed.value = json.subscribed;
      AppState.verified.value = json.verified;
      AppState.paid.value = json.paid;
      AppState.verifyError.value = json.verify_error;

      AppState.userProfile.email.value = json.userProfile.email;
      AppState.userProfile.phone.value = json.userProfile.phone;
      AppState.userProfile.legalName.value = json.userProfile.legalName;
      AppState.userProfile.firstName.value = json.userProfile.firstName;
      AppState.userProfile.lastName.value = json.userProfile.lastName;
      AppState.userProfile.country.value = json.userProfile.country;
      AppState.userProfile.username.value = json.userProfile.username;
      AppState.userProfile.verifiedData.value = json.verifiedUser;
      AppState.userProfile.imgURL.value = json.userProfile.imgURL;

      if (json.artist_profile) {
        AppState.artistProfile.uuid.value = json.artist_profile.uuid;
        AppState.artistProfile.did.value = json.artist_profile.did;
        AppState.artistProfile.swCollSociety.value = json.artist_profile.societies;
        AppState.artistProfile.ipi.value = json.artist_profile.ipi;
        AppState.artistProfile.ipn.value = json.artist_profile.ipn;
        AppState.artistProfile.isni.value = json.artist_profile.isni;
      } else {
        AppState.artistProfile.uuid.value = null;
        AppState.artistProfile.swCollSociety.value = [];
        AppState.artistProfile.ipi.value = null;
        AppState.artistProfile.ipn.value = null;
        AppState.artistProfile.isni.value = null;
      }

      AppState.profileLoaded.value = true;
    }
  } catch (err) {
    useErrorOverlay({ errorMessage: `${err.message} when loading profile` });
  }
}
