import { accountCheckoutUrl } from '../urls';

export async function postAccountCheckoutSubscription() {
  const data = {
    mode: 'subscription',
  };
  const response = await fetch(accountCheckoutUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

export async function postAccountCheckoutPayment() {
  const data = {
    mode: 'payment',
  };
  const response = await fetch(accountCheckoutUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
  return response;
}
