import {
  StyledSearchInput,
} from './SearchInputStyles';

function SearchInput({
  ...restProps
}) {
  return (
    <StyledSearchInput {...restProps} />
  );
}

export default SearchInput;
