/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'preact/hooks';
import AppState from '@state';
import { Fragment } from 'preact';

import useIsoDateFormat from '@hooks/useIsoDateFormat';
import useWindowDimensions from '@hooks/useWindowDimensions';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import BaseButton from '@ui-kit/buttons/baseButton';
import Avatar from '@ui-kit/avatar';

import loadAccountProfile from '@actions/loadAccountProfile';
import postWorkspaceContractDownload from '@api/restricted/workspace-contract-download-api';

import {
  StatusBarWrapper,
  StatusBarActions,
  SignatureChip,
} from './ContractStatusBarStyles';

function ContractStatusBar({
  contractData,
  onClickRejectContract,
  onClickSignNow,
}) {
  const [userNeedToSign, setUserNeedToSign] = useState(true);
  const [showActions, setShowActions] = useState(true);
  const [currentWriter, setCurrentWriter] = useState({});
  const [totalWriters, setTotalWriters] = useState(0);
  const [totalWritersSigned, setTotalWritersSigned] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const d = useIsoDateFormat;
  let hasScrolled = false;

  const { width } = useWindowDimensions();

  const useToastAlertError = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'error' };
  };

  const handleScroll = () => {
    if (showActions && !hasScrolled) {
      hasScrolled = true;
      setShowActions(true);
    }
  };

  useEffect(async () => {
    let currentUserWriter;

    if (localStorage.getItem('accessToken')) {
      await loadAccountProfile();
      currentUserWriter = contractData.signingParties.filter((p) => p.accountId === AppState.accountId.value);
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      currentUserWriter = contractData.signingParties.filter((p) => p.email === queryParams.get('email'));
    }

    if (currentUserWriter) {
      setCurrentWriter(currentUserWriter[0]);
    }

    if (currentUserWriter.length === 0 || currentUserWriter[0].signed) {
      setUserNeedToSign(false);
    } else {
      setUserNeedToSign(true);
    }

    setTotalWriters(contractData.signingParties.length);
    const signedParties = contractData.signingParties.filter((p) => p.signed);
    setTotalWritersSigned(signedParties.length);
    setIsLoading(false);
  }, []);

  const handleDownloadContract = async () => {
    setIsGeneratingPDF(true);
    postWorkspaceContractDownload(contractData.id, contractData.version)
      .then((response) => {
        setIsGeneratingPDF(false);
        if (response.status === 200) {
          response.blob()
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = contractData.compositionName;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove();
            })
            .catch((err) => {
              useToastAlertError(err.message);
            });
        } else {
          response.json()
            .then((json) => {
              useToastAlertError(json.error ? json.error : response.statusText);
            })
            .catch(() => {
              useToastAlertError(response.statusText);
            });
        }
      })
      .catch((err) => {
        setIsGeneratingPDF(false);
        useToastAlertError(err.message);
      });
  };

  const handleClickSignNow = () => {
    if (width < 768) {
      setShowActions(false);
      setTimeout(() => {
        window.addEventListener('scroll', handleScroll, true);
      }, 2000);
    }
    onClickSignNow();
  };

  return (
    <StatusBarWrapper status={contractData.status}>
      {!isLoading && (
        <Fragment>

          <Box width="100%">
            {currentWriter && (
            <Box display="flex" alignItems="center">
              <Box style={{ position: 'relative' }}>
                {!userNeedToSign && <SignatureChip>Signed</SignatureChip>}
                <Avatar
                  size="2.5em"
                  borderSize="2px"
                  imgURL={AppState.userProfile.imgURL.value}
                />
              </Box>
              <Box ml="1.125rem" mr="0.938rem">
                <Text fontSize="0.75rem" fontWeight="800" color="var(--text-soft-mute)" lineHeight="1.7" mb="1px">Your Contribution</Text>
                <Text fontSize="1.125rem" lineHeight="1">{currentWriter.contribution}</Text>
              </Box>
              <Box>
                <Text fontSize="0.75rem" fontWeight="800" color="var(--text-soft-mute)" lineHeight="1.7" mb="1px">Share</Text>
                <Text fontSize="1.125rem" lineHeight="1">
                  {currentWriter.share}
                  %
                </Text>
              </Box>
            </Box>
            )}
          </Box>

          {contractData.status === 'signature' && showActions && (
          <StatusBarActions>
            {!userNeedToSign
          && (
          <Text fontSize="0.813rem" color="var(--text-secondary)">
            Awaiting
            {' '}
            {totalWriters - totalWritersSigned}
            {' '}
            Signatures
          </Text>
          )}
            <BaseButton variant="secondaryDanger" btnText="Reject" color="var(--red-300)" onClick={onClickRejectContract} />
            {userNeedToSign && <BaseButton btnText="Sign" onClick={handleClickSignNow} />}
          </StatusBarActions>
          )}

          {contractData.status === 'rejected' && (
          <StatusBarActions>
            <Text fontSize="0.813rem" color="var(--text-secondary)">
              Contract rejected on:
              {' '}
              {d(contractData.rejection.rejectedDate)}
            </Text>
          </StatusBarActions>
          )}

          {contractData.status === 'executed' && (
          <StatusBarActions>
            <Text fontSize="0.813rem" color="var(--text-secondary)">
              Executed on:
              {' '}
              {d(contractData.executedDate)}
            </Text>
            <BaseButton decoration="pdf" btnText="Download PDF" onClick={handleDownloadContract} isLoading={isGeneratingPDF} disabled={isGeneratingPDF} />
            {userNeedToSign && <BaseButton btnText="Sign" onClick={handleClickSignNow} />}
          </StatusBarActions>
          )}
        </Fragment>
      )}
    </StatusBarWrapper>
  );
}

ContractStatusBar.propTypes = {
  contractData: PropTypes.object.isRequired,
  onClickRejectContract: PropTypes.func.isRequired,
  onClickSignNow: PropTypes.func.isRequired,
};

export default ContractStatusBar;
