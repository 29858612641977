import styled from 'styled-components';
import BaseButton from '@ui-kit/buttons/baseButton';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';

export const TagCardHeader = styled.label`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--panel-divider);
    padding: 0.75em;
`;

export const StyledAddTagBtn = styled(BaseButton)`
    font-size: 0.875rem;
    min-height: 2.5em;
`;

export const StyledBaseInput = styled(BaseInput)`
    min-height: 2.5em;
    width: 100%;
`;

export const TagCardBodyHeader = styled.label`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75em 0.875em;
`;

export const TagsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.75em;
    padding: 0.75em;
    padding-top: 0em;
`;

export const ColorDot = styled.div(({
  color,
}) => `
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: ${color || 'var(--pub-tag-blue'};
`);

export const ColorPrefix = styled.div`
    color: var(--text-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.25em;
    background: var(--input-append-bg);
    border-radius: 0.5em;
    height: auto;
    margin-right: 0.875em;
    position: relative;
`;

export const TagSelectorWrapper = styled.div`
    background: var(--tool-tip-background);
    border: 1px solid var(--border);
    position: absolute;
    top: 36px;
    width: 2.5em;
    border-radius: 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em 0em;
    box-shadow: 2px 4px 11px var(--tool-tip-shadow);
`;

export const StyledTag = styled.div(({
  color,
}) => `
    background: ${color || 'var(--pub-tag-blue)'};
    color: var(--white);
    padding: 6px 8px 6px 10px;
    border-radius: 0.5em;
    font-size: 0.813rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    align-self: center;
    text-transform: capitalize;
`);
