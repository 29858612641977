import AppState from '@state';
import { getAccountContracts } from '@api/restricted/account-contracts-api';
import useErrorOverlay from '@hooks/useErrorOverlay';

export default async function loadAccountContracts() {
  try {
    const response = await getAccountContracts();
    if (response.status !== 200) {
      try {
        const json = await response.json();
        useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading contracts` });
      } catch {
        useErrorOverlay({ errorMessage: `${response.statusText} when loading contracts` });
      }
    } else {
      const json = await response.json();
      AppState.contracts.value = json.contracts.sort((a, b) => new Date(b.lastSigned) - new Date(a.lastSigned));
    }
  } catch (err) {
    useErrorOverlay({ errorMessage: `${err.message} when loading contracts` });
  }
}
