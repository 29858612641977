/* eslint-disable import/prefer-default-export */

export const ACCOUNT_EXPLANATIONS = {
  songwriting: {
    description: 'This information is applied to every composition you join.  If you create alternate personas, the persona can override your Name IPI number.',
    writerSociety: 'This society, that you joined, will pay you the “writers share” of the royalty when you are involved in writing a song. It\'s important that you get this in place and documented. If you don\'t have this yet, just select the "I Don\'t Have a Membership" option below and you can update it later.',
    noSociety: 'We will list No Society on your work for now. Join a society and update this as soon as you can.',
    writerIPI: 'The Interested Party Information number is maybe one of the most critical identifiers in your identity. If this isn\'t set up, you will have a hard time getting paid. If you don\'t have one, make sure you do this soon and update it on your profile.',
  },
  performing: {
    description: 'This information is applied to every sound recording you join and is the most important info to make sure you get paid.',
    performerSociety: 'This society, that you joined, is responsible for collecting and paying sound recording related royalties. It\'s important that you get this in place and documented. If you don\'t have this yet, just select the "I don\'t have one" option below and you can update it later.',
    noSociety: 'We will list No Society on your recording identities for now. Join a society and update this as soon as you can.',
    performerIPN: 'The International Performer Number is used to identify you on sound recordings. If this isn\'t set up, you will have a hard time getting paid. If you don\'t have one, make sure you do this soon and update it on your profile.',
  },
  isni: {
    description: 'This identifier is used by various parties to identify people and their creative works. We recommend getting one if you don\'t already have one.',
    explanation: 'This identifier is used for various purposes and is not limited to just compositions and recordings. By adding this to your identity and the identity of your work, it will help make sure you are properly identified in various different scenarios.',
  },
  aliases: {
    description: 'When you write or perform using an alias or stage name, you can apply this persona to use the correct name and identifiers.',
  },
  publishers: {
    description: 'Your publisher information will be attached to any composition you create or join. Keep this information up to date.',
    additionalPublishersDescription: 'You can define alternate publishers in the event you have more than one owner or admin.  You can select the publisher you want to use when accepting invites or by viewing each composition and editing your Publisher info.',
    createExplanation: 'Enter the Publisher\'s information. You can use publishers as admins or owners when defining new publishing relationships.',
    deleteWarning: 'This publisher will be removed from any compositions where it is listed as an owner or admin.  This action cannot be undone!',
    presetExplanation: 'Define who owns and administers the publishing for the songs you write. You can create multiple relationships over time as your publishing changes.',
  },
  criticalItems: 'Critical Items Missing: ',
  verifiedData: {
    description: 'This list will grow as you receive additional verifications from Switchchord or 3rd Parties like Publishers',
  },
};

export const COMPOSITION_EXPLANATIONS = {
  list: {
    emptyState: 'Create Compositions to document the songs you write.  You can invite co-writers and generate split sheets.',
  },
  locks: {
    manageSectionBlocked: 'You do not have permission to edit this section',
    workspaceLocked: 'Contract Exists. This information is locked',
    workspaceLockedNoContract: 'Unlock Composition to edit this item',
    writerEditRestricted: 'Your permissions limit you to only editing your profile details',
    editSplitInfoBlocked: 'Your permissions do not allow you to edit this information',
    lyricsForInstrumentalBlocked: 'This composition is marked as Instrumental',
  },
  shareWorkspace: {
    singleWriterNotLocked: 'Lock the Composition with 100% ownership or add writers and create a Split Sheet. Then you can share the details of this composition.',
    noExecutedContract: 'Finalize ownership and create a Split Sheet. Once, your Split Sheet is fully signed, you can share the details of this composition.',
  },
  leaveWorkspace: {
    singleParticipantBlocked: 'You can only leave a Composition that has more than one writer. If you no longer need this Composition, you can simply delete it.',
    singleAdminBlocked: 'You cannot leave a Composition as the only moderator. To leave, you need to give moderator permission to another writer or invite another user with moderator permission.',
    contractExistsBlocked: 'Once you are on a generated Contract, you must remain on the Composition. If you need further assistance, contact support@switchchord.com',
    singleWriterBlocked: 'This Composition is locked.  A moderator needs to unlock the Composition if you wish to leave or make changes.',
    warning: 'This cannot be undone. A user will need to send you a new invite if you wish to access this Composition again in the future.',
  },
  deleteWorkspace: {
    warning: 'You are about to remove this Composition.  This action cannot be undone. All writers on this Composition will be notified that you deleted it. Just a reminder, compositions that have signed split sheets cannot be deleted.',
    deleteBlockedSingleWriter: 'You have locked the Composition. Unlock the Composition if you need to delete or make changes.',
    deleteBlockedContractExists: 'A Composition cannot be deleted once a Split Sheet is in the Signature process or is fully executed.',
  },
  singleWriter: {
    lockPrompt: 'Once signed, you will be unable to edit some details of this composition',
    unlockPrompt: 'You can unlock the composition to make changes or add co-writers. However, the changes will not be valid until you sign another attestation or a split sheet if additional writers are added.',
  },
};

// TODO: SEE IF CAN BE REMOVED AFTER UPDATED GUEST MODE
export const CONTRACT_EXPLANATIONS = {
  contractView: {
    contractRejected: 'Details have been unlocked to allow changes. Once a new contract has been generated, all writers will be notified to sign.',
    rejectionOutcomes: 'Existing signatures will be voided. After issues are resolved, a new contract will be created.',
    rejectAsNonWriter: 'An admin will be notified to remove you from this workspace. Click reject to confirm and continue.',
  },
  list: {
    emptyState: 'Writers on Compositions create and sign Split Sheet Agreements with Co-Writers. Fully executed contracts will appear here for quick access.',
  },
  lifecycle: {
    createMessage: 'This will lock several data points, create a new contract, and notify all writers that it\'s time to sign.',
    amendMessage: 'This will unlock your composition so changes can be made. Once you have made adjustments, you everyone will need to sign the new contract. Until then, the previous contract is still valid.',
    deleteAmendmentMessage: 'This will revert all the locked information back to the way it was when the workspace was last finalized.',
  },
};

export const CONTRACT_ACTION_EXPLANATIONS = {
  singleWriter: {
    pending: 'You are the only writer. Set ownership to 100% to finalize your ownership or add additional writers to create a split sheet.',
    ready: 'Looks like you are the sole writer.  Sign your single writer attestation to finalize your ownership.',
    signed: 'You signed a single writer attestation. If you want to add co-writers, click Make Changes.',
  },
  multiWriter: {
    pending: 'Add more writers or define 100% ownership to create a split sheet.',
    splitRangeExceeded: 'Ownership is greater than 100%. Reduce writer shares to equal exactly 100% to send out a signature link.',
    ready: 'Create and sign your split sheet. Co writers will get an email with a signature link.',
    readyNonAdmin: 'An admin will create the split sheet. You will get an email with a link to sign your splits.',
    readyToSign: 'The contract is ready for your signature.  A link to sign was sent to all writers.',
    awaitingSignatures: 'You will receive an email when all writers have signed the split sheet.',
    signed: 'All writers have signed the splits. An Admin can revert the contract to make changes if needed.',
    amending: 'The previous contract is being amended. An admin can make changes and generate a new version of the contract. If the amendment is canceled, the previous contract is still valid.',
    rejected: 'The last contract was rejected. Make adjustments and create a new contract.',
  },
};

export const SETTINGS_EXPLANATIONS = {
  support: 'Need assistance?  We are here to help.',
  deleteWarning: 'Per our terms of service, some information related to signed legal agreements and compositions will remain intact. All other account and personal information will be removed.',
  supportMailTo: 'mailto:artist-support@switchchord.com?cc=&bcc=&subject=I%20need%20some%20help&body=We%20are%20here%20to%20help.%20%20Make%20sure%20to%20include%20information%20about%20your%20account%20in%20addition%20to%20the%20problem%20you%20are%20having.%20%20We%20will%20get%20back%20with%20you%20as%20soon%20as%20possible',
};

export const IDENTITY_EXPLANATIONS = {
  previewSummary: 'This preview shows a portion of data from the latest state of this asset, its ownership, and metadata',
};

export const PUB_WRITER_EXPLANATIONS = {
  revokeCredentialConsequence: 'This will revoke the current writer credential and no additional works will flow into your system. You will have to issue another credential to resume delivery. If you wish to remove the writer completely, revoke all active credentials and then Delete Writer from the inactive writers tab.',
  deleteCredentialConsequence: 'This will remove all compositions from this writer with this credential attached. This should only be used if you wish to no longer be attached to any of the writer\'s compositions or added the writer by mistake. This action cannot be undone.',
  nukeWriterWarning: 'This will remove all compositions for this writer! This should only be used if you wish to no longer be attached to any of the writer\'s compositions or added the writer by mistake. This action cannot be undone.',
  cancelInvitation: 'This will cancel your connection request. The writer has likely received an email but the link to connect will no longer be valid. You will have to create another request to connect.',
};

export const REP_EXPLANATIONS = {
  revokeCredentialConsequence: 'This will revoke the current representation credential. This means your representative will immediately lose access to manage your account.',
  deleteCredentialConsequence: 'This will remove the representative from your account completely.  You will have to go through the entire invite process again to give them access. This action cannot be undone.',
  nukeRepWarning: 'This will remove the representative from your account completely.  You will have to go through the entire invite process again to give them access. This action cannot be undone.',
  cancelInvitation: 'This will cancel the invitation you sent to the representative.  They will not be able to accept the request or access your account.',
  activateRepConnection: 'Your representative has verified their identity and accepted your request. For security, review their verified information before activating the connection.',
};

export const PUB_EXPORTS_EXPLANATIONS = {
  createExportInstruction: 'Select export type to generate file. Exported works are locked and will not be included in subsequent exports.',
  createExportHowToRevert: 'The entire export can be reverted.  You can also lookup single works and revert the export flag if changes need to be made.',
};
