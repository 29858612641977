import { orgArtistsUrl } from '../urls';
import refresh from '../refresh';

export async function getOrgActiveArtists(orgId) {
  return refresh(async () => {
    const response = await fetch(`${orgArtistsUrl}?${new URLSearchParams({
      org: orgId,
      type: 'active',
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function getOrgInactiveArtists(orgId) {
  return refresh(async () => {
    const response = await fetch(`${orgArtistsUrl}?${new URLSearchParams({
      org: orgId,
      type: 'inactive',
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function deleteOrgArtist(orgId, artistId) {
  const data = {
    org: orgId,
    artist: artistId,
  };
  return refresh(async () => {
    const response = await fetch(orgArtistsUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
