import AppState from '@state';
import { route } from 'preact-router';
import { useState, useEffect } from 'preact/hooks';
import anime from 'animejs/lib/anime.es';

import loadCompositions from '@actions/loadCompositions';
import loadAccountContracts from '@actions/loadAccountContracts';
import loadAccountPublishers from '@actions/loadAccountPublishers';
import loadAccountPublisherPresets from '@actions/loadAccountPublisherPresets';

import getAccountActivities from '@api/restricted/account-activities-api';

import { translateActivity } from '@hooks/translateNotificationActivity';
import useIsoDateFormat from '@hooks/useIsoDateFormat';
import useErrorOverlay from '@hooks/useErrorOverlay';
// import useAnalytics from '@hooks/useAnalytics';

import CoreLayout from '@layouts/core/';
import PageCard from '@layouts/pageCard';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import AddIcon from '@assets/icons/plus-square.svg';
import GoToIcon from '@assets/icons/goto-square.svg';
import EmptyIcon from '@assets/icons/science.svg';
import CircleArrowIcon from '@assets/icons/arrow-right-circle.svg';
import CompositionIcon from '@assets/illustrations/composition-chip.svg';
import ContractIcon from '@assets/illustrations/contract-chip.svg';
// import VideoReelIcon from '@assets/icons/video-reel-outline.svg';
import EventList from '@distinct-components/eventList';

import {
  WelcomeWrapper,
  StyledWelcomeHeader,
  // StyledWelcomeBanner,
  // StyledWelcomeBannerCopyWrapper,
  StyledWelcomeMessage,
  HomeCardGrid,
  StyledHomeCard,
  HomeCardHeader,
  StyledCardLabel,
  HomeCardContent,
  HomeCardItem,
  PromptLabel,
  PromptMessage,
  PromptBar,
} from './HomeStyles';

function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [recentCompositions, setRecentComposition] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [activities, setActivities] = useState([]);
  const [profileCritical, setProfileCritical] = useState();

  // const analytics = useAnalytics();

  const loadAnimation = anime.timeline({
    autoplay: false,
    delay: 400,
  });

  const loadAccountActivities = async () => {
    try {
      const response = await getAccountActivities();
      if (response.status !== 200) {
        const json = await response.json();
        if (json.error) {
          useErrorOverlay({ errorMessage: `${json.error} when loading activities` });
        } else {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading activities` });
        }
      } else {
        const json = await response.json();
        const translatedActivities = json.map((activity) => translateActivity(activity, AppState.accountId.value));
        setActivities(translatedActivities);
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading activities` });
    }
  };

  useEffect(() => {
    document.title = 'Home';
    loadCompositions().then(() => {
      const comps = AppState.compositions.value;
      const sortedComps = comps.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      const recentComps = sortedComps.slice(0, 3);
      if (!AppState.artistProfile.criticalChecksComplete.value && comps.length > 0) {
        loadAccountPublishers().then(() => {
          loadAccountPublisherPresets().then(() => {
            if (AppState.compositions.value.length > 0) {
              if (AppState.writer.value) {
                if (AppState.artistProfile.swCollSociety.value.length === 0) {
                  AppState.artistProfile.societyCritical.value = true;
                } else {
                  AppState.artistProfile.societyCritical.value = false;
                }
                if (!AppState.artistProfile.ipi.value) {
                  AppState.artistProfile.ipiCritical.value = true;
                } else {
                  AppState.artistProfile.ipiCritical.value = false;
                }
                if (AppState.publisherPresets.value.filter((preset) => preset.isDefault).length === 0) {
                  AppState.artistProfile.publisherCritical.value = true;
                } else {
                  AppState.artistProfile.publisherCritical.value = false;
                }
              }
              AppState.artistProfile.criticalChecksComplete.value = true;
            }
          });
        });
      }
      setRecentComposition(recentComps);
      loadAccountActivities().then(() => {
        loadAccountContracts().then(() => {
          const contractsNotSigned = AppState.contracts.value.filter((c) => !c.signedByUser);
          setContracts(contractsNotSigned);
          setProfileCritical(AppState.artistProfile.societyCritical.value || AppState.artistProfile.ipiCritical.value || AppState.artistProfile.publisherCritical.value);
          setIsLoading(false);
          setTimeout(() => {
            loadAnimation.add({
              targets: '#grid .grid-section',
              translateY: ['16px', 0],
              opacity: [0, 1],
              duration: 600,
              direction: 'normal',
              delay: anime.stagger(100),
            });
            loadAnimation.play();
          }, 250);
        });
      });
    });
  }, []);

  const onClickComposition = (id, name) => {
    route(`/workspace/${id}`);
    AppState.composition.name.value = name;
    AppState.navigation.backActionPath.value = '/home';
  };

  const onClickCompositions = () => {
    // AppState.pagination.compositionPage.value = 1;
    route('/compositions');
  };

  const onClickContract = (id, version) => {
    route(`/contracts/contract/${id}/${version}`);
    AppState.navigation.backActionPath.value = '/home';
  };

  // const onClickTutorialBanner = () => {
  //   analytics.track('artist-home-click-view-tutorials');
  //   route('/tutorials');
  // };

  return (
    <CoreLayout>
      <PageCard
        title=""
        showHeader={false}
        isLoadingPage={isLoading}
      >
        <WelcomeWrapper>
          <StyledWelcomeHeader>
            Welcome
            {' '}
            {AppState.userProfile.firstName.value}
          </StyledWelcomeHeader>
          <StyledWelcomeMessage>
            Here&apos;s what&apos;s happening with your works
          </StyledWelcomeMessage>
        </WelcomeWrapper>

        {/* {AppState.compositions.value.length === 0 && (
        <StyledWelcomeBanner onClick={onClickTutorialBanner}>
          <Icon size="1.125em" pt="3px" cursor color="var(--white)"><VideoReelIcon /></Icon>
          <StyledWelcomeBannerCopyWrapper display="flex">
            <Text fontSize="0.75rem" color="var(--white)" fontWeight="600">Explore Switchchord</Text>
            <Text fontSize="0.75rem" color="var(--white)" opacity="0.8" fontWeight="400">Become an expert at documenting your work. Browse tutorial videos for tips and tricks.</Text>
          </StyledWelcomeBannerCopyWrapper>
        </StyledWelcomeBanner>
        )} */}

        <HomeCardGrid id="grid">

          <Box className="primary grid-section">

            <StyledHomeCard variant="panel" minHeight="14em">
              <HomeCardHeader>
                <StyledCardLabel>
                  Recent Compositions
                </StyledCardLabel>
                <Box display="flex">
                  <Icon size="1.125em" mr="1em" pt="3px" cursor onClick={() => route('/compositions/create')}><AddIcon /></Icon>
                  <Icon size="1.125em" pt="3px" cursor onClick={onClickCompositions}><GoToIcon /></Icon>
                </Box>
              </HomeCardHeader>
              <HomeCardContent>
                {recentCompositions.length && recentCompositions.map((composition) => (
                  <HomeCardItem onClick={() => { onClickComposition(composition.id, composition.compositionName); }}>
                    <Icon size="2.25em" pt="3px" mr="1em"><CompositionIcon /></Icon>
                    <Box>
                      <Text fontSize="0.938rem" color="var(--text-primary)" fontWeight="600">{composition.compositionName}</Text>
                      <Box display="flex" alignItems="center">
                        <Text mt="0.125em" fontSize="0.688rem;" mr="1em">
                          Writers:
                          {' '}
                          {composition.writerCount}
                        </Text>
                        <Text mt="0.125em" fontSize="0.688rem;">
                          Updated:
                          {' '}
                          {useIsoDateFormat(composition.updatedAt)}
                        </Text>
                      </Box>
                    </Box>
                  </HomeCardItem>
                ))}
                {recentCompositions.length === 0 && (
                <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" minHeight="8.75em">
                  <Icon size="2em" pt="3px" color="var(--panel-divider)" mb="0.25em"><EmptyIcon /></Icon>
                  <Text color="var(--panel-divider)" fontSize="0.75em">No Compositions Created</Text>
                </Box>
                )}
              </HomeCardContent>
            </StyledHomeCard>

            <StyledHomeCard variant="panel" minHeight="14em">
              <HomeCardHeader>
                <StyledCardLabel>
                  Split Sheets Ready to Sign
                </StyledCardLabel>
                <Box display="flex">
                  <Icon size="1.125em" pt="3px" cursor onClick={() => route('/contracts')}><GoToIcon /></Icon>
                </Box>
              </HomeCardHeader>
              <HomeCardContent>
                {contracts.length !== 0 && contracts.slice(0, 3).map((contract) => (
                  <HomeCardItem onClick={() => { onClickContract(contract.id, contract.version); }}>
                    <Icon size="2.25em" pt="3px" mr="1em"><ContractIcon /></Icon>
                    <Box>
                      <Text fontSize="0.938rem" color="var(--text-primary)" fontWeight="600">{contract.compositionName}</Text>
                      <Text mt="0.125em" fontSize="0.688rem;">
                        {contract.totalPartiesSigned}
                        {' '}
                        of
                        {' '}
                        {contract.totalParties}
                        {' '}
                        Signed
                      </Text>
                    </Box>
                  </HomeCardItem>
                ))}
                {contracts.length === 0 && (
                  <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" minHeight="8.75em">
                    <Icon size="2em" pt="3px" color="var(--panel-divider)" mb="0.25em"><EmptyIcon /></Icon>
                    <Text color="var(--panel-divider)" fontSize="0.75em">Nothing Ready to Sign</Text>
                  </Box>
                )}
              </HomeCardContent>
            </StyledHomeCard>
          </Box>

          <Box className="secondary grid-section">

            <StyledHomeCard variant="panel">

              {AppState.compositions.value.length === 0 && (
              <Box display="flex" alignItems="center" justifyContent="space-between" minHeight="8.75em" p="2em" className="cursor-p" onClick={() => route('/compositions/create')}>
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  <Box>
                    <PromptLabel>
                      Get Started
                    </PromptLabel>
                    <PromptBar />
                    <PromptMessage>Document your first Composition.</PromptMessage>
                  </Box>
                  <Box>
                    <Icon size="1.25em" pt="3px" mb="0.25em"><CircleArrowIcon /></Icon>
                  </Box>
                </Box>
              </Box>
              )}

              {(AppState.compositions.value.length > 0 && profileCritical) && (
              <Box display="flex" alignItems="center" justifyContent="space-between" minHeight="8.75em" p="2em" className="cursor-p" onClick={() => route('/profile')}>
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  <Box>
                    <PromptLabel>
                      Complete Your Profile
                    </PromptLabel>
                    <PromptBar variant="warning" />
                    <PromptMessage>Information that gets you paid is missing.</PromptMessage>
                  </Box>
                  <Box>
                    <Icon size="1.25em" pt="3px" mb="0.25em"><CircleArrowIcon /></Icon>
                  </Box>
                </Box>
              </Box>
              )}

              {(AppState.compositions.value.length > 0 && !profileCritical && !AppState.verified.value) && (
              <Box display="flex" alignItems="center" justifyContent="center" minHeight="8em" p="1.25em" className="cursor-p" onClick={() => route('/verify/get-started')}>
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  <Box>
                    <PromptLabel>
                      Get Verified
                    </PromptLabel>
                    <PromptBar variant="verification" />
                    <PromptMessage>
                      Make your data stronger with Human Verification
                    </PromptMessage>
                  </Box>
                  <Box>
                    <Icon size="1.25em" pt="3px" mb="0.25em"><CircleArrowIcon /></Icon>
                  </Box>
                </Box>
              </Box>
              )}

              {(AppState.compositions.value.length > 0 && !profileCritical && AppState.verified.value) && (
              <Box display="flex" alignItems="center" justifyContent="center" minHeight="8em" p="1.25em" className="cursor-p" onClick={() => route('/compositions')}>
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                  <Box mr="1.25em">
                    <Text fontSize="3.75em" lineHeight="0" fontFamily="var(--font-secondary)" fontWeight="300">{AppState.compositions.value.length}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="1em" mb="0em" fontWeight="600">
                      Compositions
                    </Text>
                    <PromptMessage>Identities Created or Joined</PromptMessage>
                  </Box>
                </Box>
              </Box>
              )}

            </StyledHomeCard>

            <StyledHomeCard variant="panel" minHeight="18.25em">
              <HomeCardHeader>
                <StyledCardLabel>
                  Recent Activity
                </StyledCardLabel>
                <Box display="flex">
                  <Icon size="1.125em" pt="3px" onClick={() => route('/events/activities')} cursor><GoToIcon /></Icon>
                </Box>
              </HomeCardHeader>
              <HomeCardContent>
                {activities.length > 0 ? (
                  <EventList events={activities} limit={5} currentPage="/home" showWorkspace />
                ) : (
                  <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" minHeight="18em">
                    <Icon size="2em" pt="3px" color="var(--panel-divider)" mb="0.25em"><EmptyIcon /></Icon>
                    <Text color="var(--panel-divider)" fontSize="0.75em">No Activity to Show</Text>
                  </Box>
                )}
              </HomeCardContent>
            </StyledHomeCard>
          </Box>
        </HomeCardGrid>
      </PageCard>
    </CoreLayout>
  );
}

export default Home;
