/* eslint-disable react/no-unescaped-entities */
import PropTypes from 'prop-types';

import BaseButton from '@ui-kit/buttons/baseButton';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import PanelDataCard from '@distinct-components/cards/panelDataCard';
import Icon from '@ui-kit/icon';
import BlueCheck from '@assets/icons/blue-check.svg';
import AlertMessage from '@ui-kit/alert/Alert';

import {
  StyledPaymentWrapper, StyledCheckoutBox, StyledHeaderText, StyledCopy, StyledPrompt,
} from './VerificationPaymentStyles';

function VerificationPayment({
  handlePayment,
  isLoading,
  error,
}) {
  return (
    <StyledPaymentWrapper>

      <Box>
        <Text fontSize="1.125rem" fontWeight="600" color="var(--brand-primary)" mb="0.25em">Make Your Data Authentic</Text>
        <StyledHeaderText>Verify the human "You". We will do the rest.</StyledHeaderText>

        {/* <StyledCopy>There are many things we can do moving forward with authentic Songwriters and Verifiable data.</StyledCopy> */}
        <StyledCopy className="desktop">We will never charge you to document compositions or to create Split Sheets with your co-writers.</StyledCopy>
        <StyledCopy>This one time payment goes toward identity verification fees and initializing your digital identity. </StyledCopy>
      </Box>

      <Box>
        <StyledCheckoutBox>
          <Text fontSize="1.125rem" fontWeight="600">Human Verification</Text>
          <Text color="var(--text-medium-mute)">Increase the value of your ownership data</Text>

          <PanelDataCard mb="1.25em" mt="1em" variant="base">
            <Box display="flex" alignItems="center" p="0.75em 0.75em">
              <Icon size="1.45em" mr="0.65em" pt="2px"><BlueCheck /></Icon>
              <Box>
                <Text lineHeight="1" fontWeight="500" mb="0.5em">Your Blue Check</Text>
                <Text lineHeight="1" color="var(--text-medium-mute)">Verification by ID and Selfie Check</Text>
              </Box>
            </Box>
          </PanelDataCard>

          <Box mb="1.5em">
            <StyledPrompt>After successful payment, you will be routed to your Human Identification workflow.  It&apos;s quick and painless.</StyledPrompt>
          </Box>

          {error
            && (
            <AlertMessage
              variant="negative"
              message={error}
              mb="1.5em"
            />
            )}

          <BaseButton
            mb="0.75em"
            type="submit"
            btnText="$1.99 / One-Time"
            decoration="arrow"
            isLoading={isLoading}
            fluid
            onClick={handlePayment}
          />

        </StyledCheckoutBox>
      </Box>

    </StyledPaymentWrapper>
  );
}

VerificationPayment.propTypes = {
  handlePayment: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

VerificationPayment.defaultProps = {
  error: null,
};

export default VerificationPayment;
