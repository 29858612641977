/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import AppState from '@state';
import { Fragment } from 'preact';

import BaseButton from '@ui-kit/buttons/baseButton';
import Spinner from '@ui-kit/loaders/Spinner';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';

import {
  StyledPageCard,
  PageCardHeader,
  StyledHeaderWrapper,
  StyledTitleWrapper,
  StyledActionsWrapper,
  StyledContentArea,
  StyledPageTab,
  StyledPageCardId,
} from './PageCardStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function PageCard({
  children,
  title,
  subTitle,
  titleData,
  primaryAction,
  isLoadingPage,
  showHeader,
  tabs,
  pageCardId,
  pageCardIdLink,
}) {
  const onClickPageCardIdLink = () => {
    const baseURL = window.location.origin;
    window.open(baseURL + pageCardIdLink, '_blank');
  };

  return (
    <StyledPageCard>
      {showHeader
      && (
        <StyledHeaderWrapper>
          <StyledTitleWrapper>
            {title
              && (
                <Fragment>
                  <Box display="flex" alignItems="center">
                    <PageCardHeader>
                      {title}
                    </PageCardHeader>
                    {titleData && <Text fontSize="1.5rem" ml="0.5em">{titleData}</Text>}
                  </Box>
                  {subTitle && <Text fontSize="0.813rem" mt="0.325rem" color="var(--text-secondary)" maxWidth="480px">{subTitle}</Text>}
                </Fragment>
              )}
            {pageCardId
          && (
          <StyledPageCardId fontSize="0.813rem" color="var(--text-medium-mute)" className={pageCardIdLink ? 'cursor-p' : ''} onClick={pageCardIdLink ? onClickPageCardIdLink : null}>
            {pageCardId}
          </StyledPageCardId>
          )}
            {tabs
            && (
              <Box display="flex" mt="1em">
                {tabs.map((tab) => (
                  <StyledPageTab isSelected={tab.isSelected} onClick={tab.method}>
                    {tab.text}
                    {tab.count > 0 && ` (${tab.count})`}
                  </StyledPageTab>
                ))}
              </Box>
            )}
          </StyledTitleWrapper>
          <StyledActionsWrapper tabsExist={tabs !== null}>
            {primaryAction
          && (
          <BaseButton
            type="submit"
            btnText={primaryAction.text}
            fluid
            onClick={primaryAction.method}
            maxHeight="2em"
          />
          )}
          </StyledActionsWrapper>
        </StyledHeaderWrapper>
      )}
      {!isLoadingPage
        ? (
          <StyledContentArea isEditingChild={AppState.artistProfile.isEditingChild.value}>
            {children}
          </StyledContentArea>
        )
        : (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="24em">
            <Spinner variant="page" size="2em" />
          </Box>
        )}
    </StyledPageCard>
  );
}

PageCard.propTypes = {
  children: childrenPropTypes,
  showHeader: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  titleData: PropTypes.string,
  primaryAction: PropTypes.object,
  tabs: PropTypes.array,
  isLoadingPage: PropTypes.bool,
  pageCardId: PropTypes.string,
  pageCardIdLink: PropTypes.string,
};

PageCard.defaultProps = {
  children: null,
  showHeader: true,
  title: null,
  subTitle: null,
  titleData: null,
  primaryAction: null,
  tabs: null,
  isLoadingPage: false,
  pageCardId: null,
  pageCardIdLink: null,
};

export default PageCard;
