/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useRef } from 'preact/hooks';
import AppState from '@state/AppState';
import SignatureCanvas from 'react-signature-canvas';

import { patchWorkspaceSignature, patchWorkspaceSignaturePlain } from '@api/restricted/workspace-signature-api';
import { patchWorkspaceSignaturePublic } from '@api/public/workspace-contract-public-api';

import useIsoDateFormat from '@hooks/useIsoDateFormat';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import BaseButton from '@ui-kit/buttons/baseButton';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import Icon from '@ui-kit/icon';
import BrushIcon from '@assets/icons/draw-brush.svg';
import KeyboardIcon from '@assets/icons/keyboard.svg';
import TrashIcon from '@assets/icons/trash-outline.svg';
import Avatar from '@ui-kit/avatar';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import {
  SignaturesWrapper,
  SignatureWrapper,
  SignatureRow,
  SignatureImage,
  SignatureCTA,
  CreateSignatureWrapper,
  SignatureLine,
  ShareReminderWrapper,
} from './ContractSignatureBlockStyles';

function ContractSignatureBlock({
  contractId,
  writers,
  isScrollToSignature,
  loadContractData,
  signerRef,
  setIsSignatureComplete,
}) {
  const [writerSigning, setWriterSigning] = useState(null);
  const [signingLegalName, setSigningLegalName] = useState(null);
  const [isDrawingSignature, setIsDrawingSignature] = useState(true);
  const [textSignature, setTextSignature] = useState(null);
  const sigCanvas = useRef({});
  const currentUserId = AppState.accountId.value;
  const currentUserEmail = (new URLSearchParams(window.location.search)).get('email');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const d = useIsoDateFormat;

  const useToastAlertError = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'error' };
  };

  const repSession = AppState.repSession.value;

  const onToggleSignSignature = (writer) => {
    setWriterSigning(writer);
    if (writer) {
      if (repSession.isActive) {
        setSigningLegalName(repSession.repName);
      } else {
        setSigningLegalName(writer.legalName);
      }
    } else {
      setSigningLegalName(null);
    }
    setIsDrawingSignature(true);
    setTextSignature(null);
    setIsLoading(false);
    setError('');
  };

  const onClickAddSignature = (writer) => {
    if (writer && repSession.isActive && !repSession.powerOfAttorney) {
      useToastAlertError('You do not have Power of Attorney to sign on behalf of this writer');
    } else {
      onToggleSignSignature(writer);
    }
  };

  const onClickTrashSignatures = () => {
    sigCanvas.current.clear();
  };

  const onSaveSignature = () => {
    if (isDrawingSignature && sigCanvas.current.isEmpty()) {
      setError('Draw a signature in the box to sign');
      return;
    }
    if (textSignature !== signingLegalName && !isDrawingSignature) {
      setError(`You need to enter your legal name: ${signingLegalName}`);
      return;
    }

    let signaturePng = null;
    if (isDrawingSignature) {
      const trimmed = sigCanvas.current.getTrimmedCanvas();
      signaturePng = trimmed.toDataURL();
    }

    const queryParams = new URLSearchParams(window.location.search);
    const handleContractSigned = () => {
      if (localStorage.getItem('accessToken')) {
        if (isDrawingSignature) {
          return patchWorkspaceSignature(contractId, signaturePng);
        }
        return patchWorkspaceSignaturePlain(contractId, textSignature);
      }
      return patchWorkspaceSignaturePublic(contractId, queryParams.get('email'), queryParams.get('token'), signaturePng, textSignature);
    };

    setIsLoading(true);
    setError('');
    handleContractSigned()
      .then((response) => {
        if (response.status === 200) {
          loadContractData().then(() => {
            onToggleSignSignature(null);
            setIsSignatureComplete(true);
          });
        } else {
          response.json()
            .then(({ error: sigError }) => setError(sigError || response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  };

  const onToggleDrawSignature = () => {
    setIsDrawingSignature(!isDrawingSignature);
    setTextSignature(null);
    setError('');
  };

  return (
    <SignaturesWrapper mt="2em" mb="8em">
      {writers.map((writer) => (
        <SignatureWrapper ref={((currentUserId && writer.accountId === currentUserId) || (currentUserEmail && writer.email === currentUserEmail)) && signerRef}>
          <SignatureRow>
            {(writer.signed && writer.signatureText) && <Text fontWeight="800">{writer.signatureText}</Text>}
            {(writer.signed && writer.signatureImg) && <SignatureImage src={writer.signatureImg} alt="signature" />}
            {(!writer.signed && ((currentUserId && writer.accountId === currentUserId) || (currentUserEmail && writer.email === currentUserEmail))) && (
              <SignatureCTA animate={isScrollToSignature} onClick={() => onClickAddSignature(writer)}>Add Signature</SignatureCTA>
            )}
          </SignatureRow>
          <Box>
            {writer.signatureRep && (
              <Box>
                <Text fontWeight="600">{writer.signatureRep}</Text>
                <Text>On behalf of:</Text>
                <Text fontWeight="600">{writer.legalName}</Text>
              </Box>
            )}
            {!writer.signatureRep && (writer.accountId === currentUserId && repSession.powerOfAttorney) && (
              <Box>
                <Text fontWeight="600">{repSession.repName}</Text>
                <Text>On behalf of:</Text>
                <Text fontWeight="600">{writer.legalName}</Text>
              </Box>
            )}
            {!writer.signatureRep && (writer.accountId !== currentUserId || !repSession.powerOfAttorney) && (
              <Text fontWeight="600">{writer.legalName}</Text>
            )}
            {writer.signed && (
              <Text>
                Signed:
                {' '}
                {d(writer.signedDate)}
              </Text>
            )}
          </Box>
        </SignatureWrapper>
      ))}
      {writerSigning && (
        <BaseEditOverlay formCentered closeFunction={() => onToggleSignSignature(null)}>
          <ConversationPrompt
            primaryMessage="Add your signature"
            promptLabel="Split Sheet"
            mb="1em"
          />
          <ConversationExplanation
            mb="1em"
            explanation="Once you sign, you will not be able to modify your signature."
            truncateAtCharacter={500}
          />
          <ShareReminderWrapper>
            <Box display="flex" alignItems="center">
              <Avatar
                size="2.5em"
                borderSize="2px"
                imgURL={AppState.userProfile.imgURL.value}
              />
              <Box ml="1rem" mr="0.938rem">
                <Text fontSize="0.75rem" fontWeight="800" color="var(--text-soft-mute)" lineHeight="1.7">Your Contribution</Text>
                <Text fontSize="1.125rem" lineHeight="1">{writerSigning.contribution}</Text>
              </Box>
              <Box>
                <Text fontSize="0.75rem" fontWeight="800" color="var(--text-soft-mute)" lineHeight="1.7">Share</Text>
                <Text fontSize="1.125rem" lineHeight="1">
                  {writerSigning.share}
                  %
                </Text>
              </Box>
            </Box>
          </ShareReminderWrapper>
          {isDrawingSignature ? (
            <CreateSignatureWrapper>
              <SignatureCanvas
                ref={sigCanvas}
                penColor="#9069FF"
                canvasProps={{ width: 320, height: 130, className: 'sigCanvas' }}
              />
              <SignatureLine />
            </CreateSignatureWrapper>
          ) : (
            <BaseInput
              placeholder={`Enter "${signingLegalName}" to Sign`}
              type="text"
              value={textSignature}
              onChange={(e) => setTextSignature((e.target.value))}
              fluid
              mb="0.75em"
            />
          )}
          <Box mb="1.25em">
            {isDrawingSignature ? (
              <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
                <Icon size="1.5em" mr="1em" cursor><KeyboardIcon onClick={onToggleDrawSignature} /></Icon>
                <Icon size="1.25em" mr="0.25em" cursor><TrashIcon onClick={onClickTrashSignatures} /></Icon>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
                <Icon size="1.25em" mr="1em" cursor><BrushIcon onClick={onToggleDrawSignature} /></Icon>
              </Box>
            )}
          </Box>
          <Box mb="1em" display="flex" justifyContent="center">
            <Text fontSize="0.75rem" color="var(--text-medium-mute)">
              By Signing You Affirm You Agree to Our
              {' '}
              <a href="https://switchchord.com/terms" target="blank">Terms of Use</a>
            </Text>
          </Box>
          {error && (
            <AlertMessage
              variant="negative"
              message={error}
              mb="1.5em"
            />
          )}
          <BaseButton
            btnText="Sign Contract"
            fluid
            onClick={onSaveSignature}
            isLoading={isLoading}
          />
        </BaseEditOverlay>
      )}
    </SignaturesWrapper>
  );
}

ContractSignatureBlock.propTypes = {
  contractId: PropTypes.number.isRequired,
  writers: PropTypes.array.isRequired,
  isScrollToSignature: PropTypes.bool.isRequired,
  loadContractData: PropTypes.func.isRequired,
  signerRef: PropTypes.any,
  setIsSignatureComplete: PropTypes.func.isRequired,
};

ContractSignatureBlock.defaultProps = {
  signerRef: null,
};

export default ContractSignatureBlock;
