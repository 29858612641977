import dayjs from 'dayjs';
import 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

// eslint-disable-next-line import/prefer-default-export
export function useTimeAgo(eventTime) {
  const nowTimestamp = dayjs();
  const actionTimestamp = dayjs(eventTime);

  const timeAgo = nowTimestamp.to(actionTimestamp);

  return `${timeAgo}`;
}
