/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import anime from 'animejs/lib/anime.es';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import BreakLine from '@ui-kit/dividers/breakLine';
import Icon from '@ui-kit/icon';
import ContractIcon from '@assets/illustrations/contract-illustration.svg';
import BaseButton from '@ui-kit/buttons/baseButton';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import { AdditionalContractRow, StyledSignatureButton } from './ContractSignedSuccessStyles';

function ContractSignedSuccess({
  contractData,
  contractsToSign,
  setIsSignatureComplete,
  isGuest,
}) {
  const [totalWriters, setTotalWriters] = useState(0);
  const [totalWritersSigned, setTotalWritersSigned] = useState(0);

  const animateLogo = () => {
    anime({
      targets: '.pen',
      rotate: [23, 0],
      opacity: [0, 1],
      duration: 800,
      delay: 400,
      easing: 'easeOutCubic',
    });
  };

  const handleContractData = () => {
    setTotalWriters(contractData.signingParties.length);
    const signedParties = contractData.signingParties.filter((p) => p.signed);
    setTotalWritersSigned(signedParties.length);
  };

  useEffect(() => {
    animateLogo();
    handleContractData();
  }, []);

  const onClickCompleteRegistration = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get('email');
    const token = queryParams.get('token');
    route(`/register?email=${encodeURIComponent(email)}&token=${token}`);
  };

  const handleGoToContract = (link) => {
    route(link);
    window.location.reload();
  };

  return (
    <BaseEditOverlay>

      <Box mt="4rem" display="flex" alignItems="center" flexDirection="column">
        <Icon
          color="var(--active-nav)"
          size="4rem"
          mb="0.5rem"
        >
          <ContractIcon />
        </Icon>
        <Text mb="1.25rem" fontWeight="900" fontSize="1.5rem" color="var(--text-primary)">Signature Complete</Text>
      </Box>

      <BreakLine mb="1.25em" color="var(--border)" />

      <Box>
        {contractData.status === 'executed'
          ? (
            <Box mb="1.25rem">
              <Text fontWeight="600">Split Sheet Finalized</Text>
              <Text fontSize="0.813rem" color="var(--text-secondary)">You will receive a copy of the contract shortly</Text>
            </Box>
          )
          : (
            <Box mb="1rem">
              <Text fontWeight="600">
                {totalWriters - totalWritersSigned}
                {' '}
                Writer Signatures Remaining
              </Text>
              <Text fontSize="0.813rem" color="var(--text-secondary)">You&apos;ll receive an email when the contract is finalized.</Text>
            </Box>
          )}
      </Box>

      {contractsToSign.length > 0 && <BreakLine mb="1.25em" color="var(--border)" />}

      {contractsToSign.length > 0 && (
        <Box mb="1.25rem">
          <Text fontWeight="600">Additional Splits to Sign</Text>
          {contractsToSign.map((c) => (
            <AdditionalContractRow>
              <Text fontSize="0.813rem">{c.name}</Text>
              <StyledSignatureButton onClick={() => handleGoToContract(c.link)}>Sign</StyledSignatureButton>
            </AdditionalContractRow>
          ))}
        </Box>
      )}

      <BreakLine mb="1.5em" color="var(--border)" />

      {isGuest
        ? (
          <Box>
            <Text fontWeight="600">Setup Your Free Account</Text>
            <Text fontSize="0.813rem" color="var(--text-secondary)" mb="1.5rem">View all your work and access more features</Text>
            <BaseButton
              variant="secondary"
              type="submit"
              btnText="Finish Registration"
              fluid
              onClick={onClickCompleteRegistration}
            />
          </Box>
        ) : (
          <Box>
            <BaseButton
              type="submit"
              btnText="Close"
              fluid
              onClick={() => setIsSignatureComplete(false)}
            />
          </Box>
        )}

    </BaseEditOverlay>
  );
}

ContractSignedSuccess.propTypes = {
  contractsToSign: PropTypes.array.isRequired,
  isGuest: PropTypes.bool.isRequired,
  setIsSignatureComplete: PropTypes.func.isRequired,
  contractData: PropTypes.object.isRequired,
};

export default ContractSignedSuccess;
