import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import Text from '@ui-kit/typography/text';

export const StyledPaymentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 3em;

    @media screen and (max-width: ${pointBreaks.md}) {
        flex-direction: column;
        gap: 0em;
    }
`;

export const StyledCheckoutBox = styled.div`
    background: var(--background);
    border: 0.8px solid (var--border);
    border-radius: 0.5em;
    padding: 1.75em 2em 2em 2em;
    width: 26em;
    margin-top: 2em;

    @media screen and (max-width: ${pointBreaks.md}) {
        width: 100%;
        margin-top: 0.5em;
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
        background: var(--card);
        padding: 1.25em 1.125em 1em 1.125em;
    }
`;

export const StyledHeaderText = styled(Text)`
    font-family: var(--font-secondary);
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 200;
    color: var(--text-primary);
    margin-bottom: 0.75em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1.35rem;
        margin-bottom: 0.5em;
    }
`;

export const StyledCopy = styled(Text)`
    font-size: 0.938rem;
    line-height: 2.25;
    font-weight: 300;
    color: var(--text-soft-mute);
    margin-bottom: 1em;
    max-width: 29em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 0.875rem;
        line-height: 1.7;

        &.desktop {
            display: none;
        }
    }
`;

export const StyledPrompt = styled(Text)`
    font-size: 0.75rem;
    line-height: 2;
    font-weight: 300;
    color: var(--text-soft-mute);
    margin-bottom: 1em;
    text-align: center;
`;
