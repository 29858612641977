import { compositionIdentityUrl } from '../urls';

export async function getCompositionIdentity(uuid, token, version) {
  const url = `${compositionIdentityUrl}?${new URLSearchParams({
    uuid,
    token,
    version,
  })}`;
  const response = await fetch(url, {
    method: 'GET',
  });
  return response;
}

export async function getCompositionIdentityCode(uuid, code, version) {
  const url = `${compositionIdentityUrl}?${new URLSearchParams({
    uuid,
    code,
    version,
  })}`;
  const response = await fetch(url, {
    method: 'GET',
  });
  return response;
}
