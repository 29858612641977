/* eslint-disable object-curly-newline */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useEffect } from 'preact/hooks';
import AppState from '@state';

import Header from '@ui-kit/typography/header';
import Box from '@ui-kit/box';
import BaseButton from '@ui-kit/buttons/baseButton';
import PublisherCard from '@distinct-components/cards/publisherCard';
import Text from '@ui-kit/typography/text';
import ArrowLeftIcon from '@assets/icons/arrow-left.svg';
import Icon from '@ui-kit/icon';

function ManagePublisherOverlayList({
  onSelectPublisher,
  setSelectedPublisherId,
  setMode,
}) {
  useEffect(() => {
    setSelectedPublisherId(null);
  }, []);

  const onClickEditPub = (publisher) => {
    setSelectedPublisherId(publisher.id);
    setMode('edit');
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" className="cursor-p" onClick={() => setMode('presetForm')} mb="1em">
        <Icon size="1.25em" cursor><ArrowLeftIcon /></Icon>
        <Text ml="0.5em">Back to Relationship</Text>
      </Box>
      <Header mb="0.75em">Publishers</Header>

      {Object.values(AppState.publishers.value).map((pub) => (
        <PublisherCard
          publisher={pub}
          handlePublisherSelected={onSelectPublisher}
          handlePublisherEdit={onClickEditPub}
          mb="1.5em"
        />
      ))}

      <BaseButton variant="secondary" btnText="Create Another Publisher" fluid onClick={() => setMode('create')} />
    </Box>
  );
}

ManagePublisherOverlayList.propTypes = {
  setMode: PropTypes.func.isRequired,
  onSelectPublisher: PropTypes.func.isRequired,
  setSelectedPublisherId: PropTypes.func.isRequired,
};

export default ManagePublisherOverlayList;
