/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';

import Box from '@ui-kit/box';

import FileListItem from './fileListItem';

function FileList({
  files,
  handleRemoveInvalidFile,
  handleFileDownload,
  handleAddFileNote,
  handleRemoveFile,
  borderBottom,
  activeUser,
  ...restProps
}) {
  const [activeFileId, setActiveFileId] = useState();
  return (
    <Box {...restProps}>
      {files.sort((a, b) => b.attachmentId - a.attachmentId).map((file) => (
        <FileListItem
          file={file}
          borderBottom={borderBottom}
          handleRemoveFile={handleRemoveFile}
          handleRemoveInvalidFile={handleRemoveInvalidFile}
          handleFileDownload={handleFileDownload}
          handleAddFileNote={handleAddFileNote}
          activeUser={activeUser}
          activeFileId={activeFileId}
          setActiveFileId={setActiveFileId}
        />
      ))}
    </Box>
  );
}

FileList.propTypes = {
  files: PropTypes.array.isRequired,
  handleRemoveInvalidFile: PropTypes.func,
  handleFileDownload: PropTypes.func,
  handleAddFileNote: PropTypes.func,
  handleRemoveFile: PropTypes.func,
  borderBottom: PropTypes.bool,
  activeUser: PropTypes.object.isRequired,
};

FileList.defaultProps = {
  handleRemoveInvalidFile: null,
  handleFileDownload: null,
  handleAddFileNote: null,
  handleRemoveFile: null,
  borderBottom: false,
};

export default FileList;
