import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import Box from '../../ui-kit/box/Box';

export const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 2em;
  background: var(--background);
  position: fixed;
  overflow-y: auto;
`;

export const BrandWrapper = styled(Box)`
  display: block;
  margin-bottom: 3rem;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 24em;
  margin: 0 auto;

  @media screen and (max-width: ${pointBreaks.sm}) {
    margin: 0 auto;
  }
`;

export const ConversationContent = styled.div`
  justify-content: center;
  align-items: center;
`;

export const AuthFormWrapper = styled.div`
  width: 20rem;
  transform: translateY(-4em);

  @media screen and (max-height: 40em) {
    transform: translateY(0em);
  }

  @media screen and (max-width: ${pointBreaks.sm}) {
    transform: translateY(-2em);
  }
`;
