/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';
import Card from '@distinct-components/cards/baseCard';
import BaseTextArea from '@ui-kit/inputs/baseTextArea/BaseTextArea';

export const StyledCommentCard = styled(Card)`
  border-radius: 1em;
  background: var(--panel-info);
  padding: 0.75em;
  margin-bottom: 0.75em;
  position: relative;
   ${space};
`;

export const StyledTextArea = styled(BaseTextArea)(({
  isActive,
}) => `
  height: ${isActive ? '8em' : '2.75em'};
  background: var(--input-bg-panel);
  padding: ${isActive ? 'inherit' : '0.5em 0.75em'};
   ${space};
`);

export const DeleteCommentWrapper = styled.div(({
  isDeleting,
}) => `
  position: absolute;
  top: 6px;
  right: 4px;
  height: 2em;
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${isDeleting ? 0.5 : 1};
  cursor: ${isDeleting ? 'not-allowed' : 'pointer'};
`);
