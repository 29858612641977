import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const WriterContainer = styled.div`
    background: var(--panel-card);
    border-radius: 0.5em;
    border: 1px solid var(--border);
    border-bottom: none;
    margin-bottom: 1em;
`;

export const WriterContainerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 1em;
    padding: 1em 1em 1em 0em;
    flex-wrap: wrap;
    gap: 0.5em;
`;

export const StyledPercentageInput = styled.input`
    height: 2.75em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0em;
    border: 1px solid var(--input-border);
    border-right: none;
    width: 4em;
    padding-left: 0.75em;
    background: var(--input-bg-panel);
    color: var(--text-primary);
`;

export const StyledPercentBox = styled.div`
    color: var(--text-primary);
    height: 2.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75em;
    font-size: 1em;
    background: var(--input-append-bg);
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border: 1px solid var(--input-border);
    border-left: none;
`;

export const SplitsInfoWrapper = styled.div`
    display: flex;

    @media screen and (max-width: ${pointBreaks.sm}) {
        width: 100%;
    }
`;

export const StyledInputRow = styled.div`
    display: flex;
    margin-top: 0.5em;
    width: 18em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        justify-content: space-between;
        width: 100%;
    }
`;

export const StyledReadOnlyRow = styled.div`
    display: flex;
    width: 100%;
    margin-top: 0.5em;

    .lockIcon {
        transform: translate(-4px, -6px);
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
        justify-content: space-between; 
    }
`;

export const EditActionsWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column; 

        button {
            width: 100%;
        }
    }
`;
