import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Fragment } from 'preact';
import AppState from '@state/AppState';

import BaseButton from '@ui-kit/buttons/baseButton';
import AlertMessage from '@ui-kit/alert/Alert';
import Text from '@ui-kit/typography/text';
import Span from '@ui-kit/typography/span';
import InputLabel from '@ui-kit/inputs/inputLabel';
import Box from '@ui-kit/box';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import LockIcon from '@assets/icons/lock-closed.svg';
import Icon from '@ui-kit/icon';

import {
  StyledPercentageInput,
  StyledPercentBox,
  StyledAccessContainer,
  LoggedInWrapper,
} from './InvitationAcceptStyles';

function InvitationAccept({
  handleAcceptInvite,
  isWriter,
  isAdmin,
  isCanEdit,
  error,
  isLoading,
  handleSignOut,
}) {
  const {
    register, handleSubmit, formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data) => {
    handleAcceptInvite(data);
  };

  return (
    <Fragment>
      <LoggedInWrapper>
        <Span fontSize="0.75rem" mb="0.25em">
          {AppState.userProfile.email.value}
          &nbsp;&nbsp;
        </Span>
        <Span fontSize="0.75rem" mb="0.25em" cursor style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleSignOut()}>Sign Out</Span>
      </LoggedInWrapper>
      <StyledAccessContainer mb="2em" p="1em">
        <Box display="flex" alignItems="center" mb="0.5em">
          <Icon size="1em" mr="0.75em"><LockIcon /></Icon>
          <Text fontWeight="600">Your Access</Text>
        </Box>
        {(isAdmin && !isWriter) && <Text fontSize="0.75rem" mb="0.25em">You have been added as a Moderator and can edit anything on the composition</Text>}
        {(isAdmin && isWriter) && <Text fontSize="0.75rem" mb="0.25em">You have been added as a Writer and Moderator. You can edit anything on the composition</Text>}
        {(isWriter && !isAdmin && !isCanEdit)
         && (
         <Fragment>
           <Text fontSize="0.75rem" mb="0.25em">- A Moderator will enter your ownership info</Text>
           <Text fontSize="0.75rem" mb="0.25em">- Only the Moderators can edit the composition</Text>
         </Fragment>
         )}
        {(!isWriter && !isAdmin && isCanEdit)
         && (
         <Fragment>
           <Text fontSize="0.75rem" mb="0.25em">- You can edit data and files</Text>
           <Text fontSize="0.75rem" mb="0.25em">- You cannot edit splits, create contracts, or manage users</Text>
         </Fragment>
         )}
        {(!isWriter && !isAdmin && !isCanEdit)
         && <Text fontSize="0.75rem" mb="0.25em">- You have been given View Only access</Text>}
        {(isWriter && !isAdmin && isCanEdit)
        && (
        <Fragment>
          <Text fontSize="0.75rem" mb="0.25em">- You can edit your ownership details</Text>
          <Text fontSize="0.75rem">- You can edit basic information about the composition</Text>
        </Fragment>
        )}
      </StyledAccessContainer>
      <form id="acceptForm" onSubmit={handleSubmit(onSubmit)} autoComplete="on">
        {isWriter && AppState.aliases.value.length > 0
        && (
          <Fragment>
            <InputLabel label="Use a Persona (Optional)" />
            <BaseSelect
              {...register('persona', { required: false, valueAsNumber: true })}
              id="persona"
              name="persona"
              fluid
              mb="1.5rem"
            >
              <option value={null} default selected>
                None (
                {AppState.userProfile.firstName}
                {' '}
                {AppState.userProfile.lastName}
                )
              </option>
              {AppState.aliases.value.map((a) => (
                <option value={a.id}>{a.name}</option>
              ))}
            </BaseSelect>
          </Fragment>
        )}
        {(isWriter && isCanEdit) && (
          <Fragment>
            <InputLabel label="Contribution to Composition" />
            <BaseSelect
              {...register('contribution', { required: false })}
              fluid
              mb="0.25em"
            >
              <option value="Music and Lyrics" default selected>Music and Lyrics</option>
              <option value="Lyrics">Lyrics</option>
              <option value="Music">Music</option>
              <option value="Arrangement">Arrangement</option>
              <option value="Translation">Translation</option>
            </BaseSelect>
            <InputLabel mb="1.5em" color="var(--text-medium-mute)" label="Production / Beats is Considered Music" />

            <InputLabel label="Ownership Share" />
            <Box display="flex" mb="0.25em">
              <StyledPercentageInput
                {...register('share', { required: false, valueAsNumber: true })}
                id="share"
                name="share"
                placeholder={0.00}
                type="number"
                inputMode="decimal"
                step="0.01"
                max={100}
                min={0.01}
              />
              <StyledPercentBox>%</StyledPercentBox>
            </Box>
            <InputLabel mb="4em" color="var(--text-medium-mute)" label="You can review your share before signing the Split Sheet" />

          </Fragment>
        )}
        {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}
        <BaseButton
          mb="1.5em"
          type="submit"
          btnText="Accept Invitation"
          disabled={!isValid}
          fluid
          isLoading={isLoading}
        />
      </form>
    </Fragment>
  );
}

InvitationAccept.propTypes = {
  handleAcceptInvite: PropTypes.func.isRequired,
  isWriter: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isCanEdit: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSignOut: PropTypes.func.isRequired,
};

export default InvitationAccept;
