/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import Span from '@ui-kit/typography/span';
import WarningIcon from '@assets/icons/alert-callout.svg';
import InfoIcon from '@assets/icons/info-circle.svg';
import SuccessIcon from '@assets/icons/success-circle.svg';
import CloseIcon from '@assets/icons/close-solo.svg';
import LinkButton from '@ui-kit/buttons/linkButton';

import {
  AlertWrapper, AlertMessageWrapper, IconContainer, AlertMessage, DismissWrapper, StyledMessageLink,
} from './AlertStyles';

function Alert({
  variant,
  width,
  message,
  messageLabel,
  dismissAlert,
  messageAction,
  messageActionText,
  messageLinks,
  display,
  ...restProps
}) {
  return (
    <AlertWrapper
      variant={variant}
      display={display}
      width={width}
      {...restProps}
    >
      <AlertMessageWrapper>
        <IconContainer variant={variant}>
          {
        {
          negative: <Icon pt="3px" size="1.125em" color="var(--red-300)"><WarningIcon /></Icon>,
          warning: <Icon pt="3px" size="1.125em" color="var(--orange-300)"><WarningIcon /></Icon>,
          positive: <Icon pt="3px" size="1.3em" color="var(--green-300)"><SuccessIcon /></Icon>,
          default: <Icon pt="3px" size="1.3em" color="var(--blue-300)"><InfoIcon /></Icon>,
        }[variant]
      }
        </IconContainer>
        <AlertMessage display={display}>
          {messageLabel && <Span fontSize="0.813rem" fontWeight="500" pr="0.75em">{messageLabel}</Span>}
          <Span>{message}</Span>
          {messageAction && <Span pl="0.5em"><LinkButton variant="small" btnText={messageActionText} color="var(--text-primary)" onClick={messageAction} /></Span> }
        </AlertMessage>

      </AlertMessageWrapper>

      {messageLinks
      && (
        <Box p="0.75em" pt="0em">
          {messageLinks.map((link) => (
            <StyledMessageLink href={link.url} target="blank">{link.name}</StyledMessageLink>
          ))}
        </Box>
      )}

      {dismissAlert && <DismissWrapper onClick={dismissAlert}><Icon size="0.5em" color="var(--text-primary)" cursor><CloseIcon /></Icon></DismissWrapper>}

    </AlertWrapper>
  );
}

Alert.propTypes = {
  variant: PropTypes.string,
  width: PropTypes.string,
  message: PropTypes.string,
  messageLabel: PropTypes.string,
  messageAction: PropTypes.func,
  messageActionText: PropTypes.string,
  display: PropTypes.string,
  dismissAlert: PropTypes.func,
  messageLinks: PropTypes.array,
};

Alert.defaultProps = {
  variant: 'default',
  display: 'flex',
  width: null,
  message: null,
  messageLabel: null,
  dismissAlert: null,
  messageAction: null,
  messageActionText: null,
  messageLinks: null,
};

export default Alert;
