/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import Box from '@ui-kit/box';
import BaseButton from '@ui-kit/buttons/baseButton';

function ActionButtonPair({
  primaryText,
  primaryFunction,
  secondaryText,
  secondaryFunction,
  ...restProps
}) {
  return (
    <Box display="flex" {...restProps}>
      <BaseButton fontSize="0.875rem" mr="1em" variant="secondary" fontWeight="400" btnText={secondaryText} onClick={secondaryFunction} fluid />
      <BaseButton fontSize="0.875rem" btnText={primaryText} onClick={() => primaryFunction()} fluid />
    </Box>
  );
}

ActionButtonPair.propTypes = {
  primaryText: PropTypes.string.isRequired,
  primaryFunction: PropTypes.func.isRequired,
  secondaryText: PropTypes.string.isRequired,
  secondaryFunction: PropTypes.func.isRequired,
};

export default ActionButtonPair;
