/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'preact/hooks';
import { useForm } from 'react-hook-form';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseButton from '@ui-kit/buttons/baseButton';
import BreakLine from '@ui-kit/dividers/breakLine';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseTypeAhead from '@distinct-components/typeAheadInputs/baseTypeAhead';
import AddressTypeAhead from '@distinct-components/typeAheadInputs/addressTypeAhead';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';

import { SOCIETIES } from '@constants/collectionSocieties';

import useRoundNumber from '@hooks/useRoundNumber';

import {
  StyledPercentBox,
  StyledPercentageInput,
} from './AddWriterCoPubStyles';

const WriterSocieties = SOCIETIES.filter((s) => s.societyType === 'pro');

function AddWriterCoPub({
  terms,
  coPub,
  setCoPub,
  setStep,
  placesState,
  ...restProps
}) {
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [provideDetails, setProvideDetails] = useState(true);
  const [typeAheadValue, setTypeAheadValue] = useState(coPub?.address || '');

  const {
    register, handleSubmit, getValues, setValue, trigger, formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    register('address', { required: true });
    register('society', { required: true });
    if (coPub) {
      setProvideDetails(coPub.detailProvided);
      setValue('address', coPub.address);
      setValue('society', coPub.society);
    }
  }, []);

  const handleAddressSelected = (data) => {
    setValue('address', data);
    trigger('address');
    setTypeAheadValue(data);
  };

  const handleSocietySelected = (data) => {
    setValue('society', data);
    trigger('society');
  };

  const onClickContinue = (data) => {
    const cleanedData = {
      name: data.name.trim().split(/\s+/).join(' '),
      society: data.society,
      ipi: data.ipi,
      address: data.address.trim().split(/\s+/).join(' '),
    };

    if (!cleanedData.name) {
      setError('Invalid publisher name');
      return;
    }

    setError('');
    setIsSaving(true);
    const coPubInfo = {
      detailProvided: true,
      ...cleanedData,
    };
    setCoPub(coPubInfo);
    setTimeout(() => {
      setIsSaving(false);
      setStep('commitment');
    }, 800);
  };

  const onClickSkip = () => {
    const coPubInfo = {
      detailProvided: false,
      name: getValues('name'),
      society: getValues('society'),
      ipi: getValues('ipi'),
      address: getValues('address'),
    };
    setCoPub(coPubInfo);
    setStep('commitment');
  };

  return (
    <Box {...restProps}>
      <BreakLine mb="1.5em" mt="0.5em" color="var(--panel-info-border)" />

      <Box display="flex" justifyContent="space-between" mb="1.25em">
        <Box>
          <Text fontWeight="600" mb="0.125em">Add Publisher Details</Text>
          <Text fontSize="0.813rem" pr="0.5em" color={provideDetails ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}>
            The writer will be prompted to add this suggested information to their profile.
          </Text>
        </Box>
        <ToggleSwitch id="copub">
          <input
            id="copub"
            name="copub"
            type="checkbox"
            checked={provideDetails}
            onChange={() => setProvideDetails(!provideDetails)}
          />
        </ToggleSwitch>
      </Box>

      <BreakLine mb="1.5em" color="var(--panel-info-border)" />

      {provideDetails ? (
        <form id="coPubForm" onSubmit={handleSubmit(onClickContinue)} autoComplete="off">

          <InputLabel label="Publisher Name" />
          <BaseInput
            {...register('name', { required: true })}
            id="name"
            name="name"
            placeholder="Name of Publisher"
            defaultValue={coPub?.name}
            type="text"
            pattern=".*[^\s].*"
            fluid
            mb="1.5rem"
          />
          <BaseTypeAhead
            label="Publisher Collection Society"
            id="societies"
            data={WriterSocieties}
            nameValue="name"
            handleChange={handleSocietySelected}
            initialValue={coPub?.society}
          />
          <InputLabel label="Publisher IPI" />
          <BaseInput
            {...register('ipi', { required: true })}
            id="ipi"
            name="ipi"
            placeholder="9-11 Digit IPI"
            defaultValue={coPub?.ipi}
            type="text"
            inputMode="decimal"
            pattern="[0-9]+"
            maxLength="11"
            minLength="9"
            fluid
            mb="1.5rem"
          />
          <AddressTypeAhead
            placesState={placesState}
            handleChange={handleAddressSelected}
            address={typeAheadValue}
            label="Publisher Address"
            id="pubAddress"
            mb="1.5em"
          />
          <Box mb="1.5em">
            <InputLabel label="Undivided Ownership Interest" />
            <Box display="flex" mb="0.25em">
              <StyledPercentageInput
                id="ownershipShare"
                name="ownershipShare"
                disabled
                value={terms.hasOwnership ? useRoundNumber(100 - terms.ownershipShare, 2) : 100}
              />
              <StyledPercentBox>%</StyledPercentBox>
            </Box>
          </Box>

          {error && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}

          <BreakLine mb="1.5em" color="var(--border)" />
          <Box display="flex" justifyContent="flex-end">
            <BaseButton
              mb="1.5em"
              btnText="Continue"
              disabled={isSaving || !isValid}
              isLoading={isSaving}
              fluid
              decoration="arrow"
            />
          </Box>
        </form>
      ) : (
        <Box display="flex" justifyContent="flex-end">
          <BaseButton
            mb="1.5em"
            btnText="Skip"
            onClick={onClickSkip}
            fluid
            decoration="arrow"
          />
        </Box>
      )}

    </Box>
  );
}

AddWriterCoPub.propTypes = {
  setStep: PropTypes.func.isRequired,
  setCoPub: PropTypes.func.isRequired,
  terms: PropTypes.object.isRequired,
  coPub: PropTypes.object,
  placesState: PropTypes.any.isRequired,
};

AddWriterCoPub.defaultProps = {
  coPub: null,
};

export default AddWriterCoPub;
