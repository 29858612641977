/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Box from '@ui-kit/box';
import pointBreaks from '@styles/styleConstants';

export const EmptyStateWrapper = styled(Box)(({
  size,
}) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .empty-title {
        font-size: ${size === 'small' ? '0.875rem' : '1.125rem'};
        font-weight: 500;
        margin-bottom: 0.25em;
    }

    .empty-message {
        font-size: ${size === 'small' ? '0.75rem' : '0.875rem'};
        margin-bottom: 0.25em;
        text-align: center;
        opacity: 0.8;
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
        justify-content: flex-start;
        padding-top: 2em;

        svg {
            height: 6em;
        }
    }
`);

export const IconWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
