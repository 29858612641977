/* eslint-disable react/no-unescaped-entities */
import AppState from '@state';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';

import loadAccountProfile from '@actions/loadAccountProfile';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Spinner from '@ui-kit/loaders/Spinner';
import AlertMessage from '@ui-kit/alert/Alert';
import Icon from '@ui-kit/icon';
import BlueCheck from '@assets/icons/blue-check.svg';
import RedCheck from '@assets/icons/red-check.svg';
import BaseButton from '@ui-kit/buttons/baseButton';

import FullScreenModal from '@layouts/full-screen/full-screen-modal';

import {
  StyledVerifyWrapper, StyledVerifyCard, StyledPrompt, UserImageWrapper, AvatarCheckWrapper,
} from './HumanVerificationProcessingStyles';

function HumanVerificationProcessing() {
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);
  const [verificationFailure, setVerificationFailure] = useState(false);
  const [userImgUrl, setUserImgUrl] = useState();
  const [error, setError] = useState();
  const [dataPoll, setDataPoll] = useState();

  let pollCount = 0;

  const checkProfileStatus = () => {
    if (!AppState.verified.value && !AppState.verifyError.value && pollCount < 15) {
      loadAccountProfile();
      pollCount += 1;
    } else if (pollCount >= 15) {
      setVerificationFailure(true);
      clearInterval(dataPoll);
      setError('We did not get a response from Stripe or your connection was interrupted. Try again.');
    } else {
      setIsCheckingStatus(false);
      clearInterval(dataPoll);
    }
  };

  const pollForProfileUpdate = () => {
    setIsCheckingStatus(true);
    setDataPoll(setInterval(() => checkProfileStatus(), 5000));
  };

  useEffect(() => {
    document.title = 'Processing Identity';
    setTimeout(() => {
      loadAccountProfile().then(() => {
        if (AppState.userProfile.imgURL.value) {
          setUserImgUrl(AppState.userProfile.imgURL.value);
        }
        setIsLoadingProfile(false);
        if (!AppState.verified.value) {
          pollForProfileUpdate();
        }
      }).catch((err) => {
        setIsLoadingProfile(false);
        setError(err.message);
      });
    }, 2000);
  }, []);

  const onClickRetryVerification = () => {
    AppState.verifyError.value = null;
    route('/verify/get-started');
  };

  return (
    <FullScreenModal formWidth="60em">
      {isLoadingProfile
        ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="24em">
            <Spinner variant="page" size="2em" />
          </Box>
        )
        : (
          <StyledVerifyWrapper>
            <StyledVerifyCard>

              <Box display="flex" justifyContent="center" mb="1.5em" mt="1.5em">
                {userImgUrl
                  ? (
                    <Box position="relative">
                      {!isCheckingStatus && AppState.verified.value && <AvatarCheckWrapper><Icon size="1.75em"><BlueCheck /></Icon></AvatarCheckWrapper>}
                      {!isCheckingStatus && AppState?.verifyError.value && <AvatarCheckWrapper><Icon size="1.75em"><RedCheck /></Icon></AvatarCheckWrapper>}
                      <UserImageWrapper avatarImg={userImgUrl} />
                    </Box>
                  )
                  : (
                    <Box position="relative">
                      {!isCheckingStatus && AppState.verified.value && <AvatarCheckWrapper><Icon size="1.75em"><BlueCheck /></Icon></AvatarCheckWrapper>}
                      {!isCheckingStatus && AppState?.verifyError.value && <AvatarCheckWrapper><Icon size="1.75em"><RedCheck /></Icon></AvatarCheckWrapper>}
                      <UserImageWrapper avatarImg={null} />
                    </Box>
                  )}
              </Box>

              {!isCheckingStatus && AppState.verified.value
              && (
              <Box mb="1em" display="flex" alignItems="center" flexDirection="column">
                <Text fontSize="1.75rem" fontWeight="800" color="var(--text-primary)">You are Verified!</Text>
                <StyledPrompt>That's it. Your data will now include verified details and messaging when shared.</StyledPrompt>
                <StyledPrompt>We are working on exciting things we can offer our verified users in the future.</StyledPrompt>
              </Box>
              )}

              {isCheckingStatus
              && (
              <Box display="flex" alignItems="center" flexDirection="column">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Spinner variant="page" size="1em" />
                  <Text fontSize="1.5rem" fontWeight="800" color="var(--text-primary)" ml="0.5em">Waiting for Results...</Text>
                </Box>
                <StyledPrompt>Waiting for verification results. This is usually fast but can take up to 60 seconds.</StyledPrompt>
              </Box>
              )}

              {!isCheckingStatus && AppState.verifyError.value
              && (
              <Box mb="1em" display="flex" alignItems="center" flexDirection="column">
                <Text fontSize="1.5rem" fontWeight="800" color="var(--text-primary)">Verification Failed</Text>
                <StyledPrompt>Stripe was unable to verify your identity. See the message below and try again. If you are unable to resolve the issue below, contact us for assistance.</StyledPrompt>
              </Box>
              )}

              {!isCheckingStatus && AppState?.verifyError.value
                && (
                <AlertMessage
                  variant="negative"
                  message={AppState?.verifyError.value}
                  mb="1.5em"
                />
                )}

              {error
                && (
                <AlertMessage
                  variant="negative"
                  message={error}
                  mb="1.5em"
                />
                )}

              {((!isCheckingStatus && AppState.verifyError.value) || verificationFailure)
              && (
                <BaseButton
                  mb="0.75em"
                  type="submit"
                  btnText="Try Again"
                  fluid
                  onClick={onClickRetryVerification}
                />
              )}

              {!isCheckingStatus && AppState.verified.value
              && (
                <BaseButton
                  mb="0.75em"
                  type="submit"
                  btnText="Return to Profile"
                  fluid
                  onClick={() => route('/profile')}
                />
              )}

            </StyledVerifyCard>
          </StyledVerifyWrapper>
        )}

    </FullScreenModal>
  );
}

export default HumanVerificationProcessing;
