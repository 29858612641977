import { accountArtistProfileUrl } from '../urls';
import refresh from '../refresh';

export default async function putAccountArtistProfile(
  ipi,
  ipn,
  isni,
) {
  const data = {
    ipi,
    ipn,
    isni,
  };
  return refresh(async () => {
    const response = await fetch(accountArtistProfileUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
