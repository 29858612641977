import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  space, typography, variant, color,
} from 'styled-system';

const Span = styled.span(
  ({
    truncate,
  }) => `
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0px;
    ${truncate && 'white-space: nowrap'};
    ${truncate && 'overflow: hidden'};
    ${truncate && 'text-overflow: ellipsis'};
    ${space}
    ${typography}
    ${color}
`,
  variant({
    variants: {
      default: {
        color: 'var(--text-soft-mute)',
        fontFamily: 'var(--font-primary)',
      },
      muted: {
        color: 'var(--text-muted)',
      },
      mediumMute: {
        color: 'var(--text-medium-mute)',
      },
      thinPrompt: {
        color: 'var(--text-soft-mute)',
        fontFamily: 'var(--font-secondary)',
        fontSize: '16px',
        fontWeight: '300',
        lineHeight: 1.8,
      },
    },
  }),
  space,
  typography,
  color,
);

Span.propTypes = {
  variant: PropTypes.oneOf(['default', 'muted', 'thinPrompt', 'mediumMute']),
};

Span.defaultProps = {
  variant: 'default',
};

export default Span;
