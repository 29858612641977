/* eslint-disable import/prefer-default-export */
import { artistIdentityUrl } from '../urls';

export async function getArtistIdentity(uuid) {
  const url = `${artistIdentityUrl}?${new URLSearchParams({
    uuid,
  })}`;
  const response = await fetch(url, {
    method: 'GET',
  });
  return response;
}
