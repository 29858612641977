/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const SignatureWrapper = styled.div`
    display: block;
`;

export const CreateSignatureWrapper = styled.div`
    border-radius: 1em;
    overflow: hidden;
    width: 320px;
    height: 130px;
    margin-bottom: 0.75em;
    background: var(--panel-card);
    border: 0.8px solid var(--panel-divider);
    box-shadow: 2px 4px 11px var(--card-shadow);
`;

export const SignatureLine = styled.div`
    border-top: 1px dashed var(--brand-primary);
    width: 290px;
    transform: translate(15px, -29px);
`;
