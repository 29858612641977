import { workspaceSignatureUrl } from '../urls';
import refresh from '../refresh';

export async function patchWorkspaceSignature(workspaceId, signature) {
  const data = {
    workspace: workspaceId,
    signature,
  };
  return refresh(async () => {
    const response = await fetch(workspaceSignatureUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function patchWorkspaceSignaturePlain(workspaceId, plainSignature) {
  const data = {
    workspace: workspaceId,
    plainSignature,
  };
  return refresh(async () => {
    const response = await fetch(workspaceSignatureUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
