/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-unescaped-entities */
import { route } from 'preact-router';
import FullScreenModal from '@layouts/full-screen/full-screen-modal';
import {
  useEffect, useLayoutEffect, useRef, useState,
} from 'preact/hooks';
import PropTypes from 'prop-types';
import config from '@config';

import loadOrganization from '@actions/loadOrganization';

import useScript from '@hooks/useScript';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Header from '@ui-kit/typography/header';
import WizardProgress from '@distinct-components/feedback/wizardProgress';

import AddWriterDetails from './sections/addWriterDetails';
import AddWriterTerms from './sections/addWriterTerms';
import AddWriterRequirements from './sections/addWriterRequirements';
import AddWriterCoPub from './sections/addWriterCoPub';
import ReviewWriterInvitation from './sections/reviewWriterInvitation';

function PublisherAddSingleWriter({ uuid }) {
  const placesSrc = `https://maps.googleapis.com/maps/api/js?key=${config.google.placesKey}&libraries=places`;
  const placesState = useScript(placesSrc);
  const [step, setStep] = useState('writer');
  const [progressWidth, setProgressWidth] = useState(10);
  const [progressSubHeader, setProgressSubHeader] = useState('Get Started');
  const [recipient, setRecipient] = useState();
  const [terms, setTerms] = useState();
  const [requirements, setRequirements] = useState();
  const [coPub, setCoPub] = useState();

  const pageRef = useRef(null);

  useEffect(() => {
    document.title = 'Publisher - Add Writer';
    loadOrganization(uuid, { forcedLoad: false });
  }, []);

  useLayoutEffect(() => {
    pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    switch (step) {
      case 'review':
        setProgressWidth(100);
        setProgressSubHeader('Ready to Send');
        break;
      case 'commitment':
        setProgressWidth(80);
        break;
      case 'copub':
        setProgressWidth(60);
        break;
      case 'terms':
        setProgressWidth(40);
        if (recipient?.user) {
          setProgressSubHeader(`${recipient.user?.firstName} ${recipient.user?.lastName}`);
        } else {
          setProgressSubHeader(recipient?.email);
        }
        break;
      default:
        setProgressWidth(20);
    }
  }, [step]);

  const handleCancelInvitation = () => {
    route(`/publisher/${uuid}/songwriters`);
  };

  const onClickStepBack = () => {
    switch (step) {
      case 'review':
        setStep('commitment');
        break;
      case 'commitment':
        if (!terms?.hasOwnership || terms?.ownershipShare < 100) {
          setStep('copub');
        } else {
          setStep('terms');
        }
        break;
      case 'copub':
        setStep('terms');
        break;
      case 'terms':
        setStep('writer');
        break;
      default:
        setProgressWidth(30);
    }
  };

  return (
    <FullScreenModal formWidth="24em" closeFunction={handleCancelInvitation}>
      <div ref={pageRef} />
      <Box mb="1em">
        {{
          writer: <Box>
            <Header mb="0.25em">Get Started</Header>
            <Text>Enter your writer’s email.  If they do not have an account, we will send your invite and get them signed up.</Text>
          </Box>,
          terms: <Box>
            <Header mb="0.25em">Credential Terms</Header>
            <Text>If accepted, this credential info will be added to their profile and used on compositions they create.</Text>
          </Box>,
          copub: <Box mb="0.25em">
            <Header mb="0.25em">{terms?.hasOwnership ? 'Co-Pub Information' : 'Writer Publisher Info'}</Header>
            <Text>If the Writer’s self-owned publisher owns the remaining percentage, you can help define that info below.</Text>
          </Box>,
          commitment: <Box>
            <Header mb="0.25em">Commitment Tracking</Header>
            <Text>You can monitor the progress of works delivered by adding commitment requirements to the credential.</Text>
          </Box>,
          review: <Box mb="0.25em">
            <Header mb="0.25em">Review and Send</Header>
            <Text>Confirm the information is correct and send the invite.  You can monitor invites from the “pending” tab.</Text>
          </Box>,
        }[step]}
      </Box>

      <WizardProgress
        headerText="Invite Writer"
        subHeaderText={progressSubHeader || 'WRITER_NAME_EMAIL'}
        progressWidth={progressWidth}
        handleStepBack={onClickStepBack}
        showBackButton={step !== 'writer'}
        progressIcon="send"
        mb="1.5em"
      />

      <Box>
        {{
          writer: <AddWriterDetails setStep={setStep} recipient={recipient} setRecipient={setRecipient} />,
          terms: <AddWriterTerms setStep={setStep} terms={terms} setTerms={setTerms} />,
          copub: <AddWriterCoPub setStep={setStep} terms={terms} coPub={coPub} setCoPub={setCoPub} placesState={placesState} />,
          commitment: <AddWriterRequirements setStep={setStep} requirements={requirements} setRequirements={setRequirements} />,
          review: <ReviewWriterInvitation setStep={setStep} recipient={recipient} terms={terms} requirements={requirements} coPub={coPub} />,
        }[step]}
      </Box>
    </FullScreenModal>
  );
}

PublisherAddSingleWriter.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PublisherAddSingleWriter;
