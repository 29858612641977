/* eslint-disable import/prefer-default-export */
import { adminOrgInviteUrl } from '../urls';

export async function postAdminOrgInvite(orgId, email) {
  const data = {
    org: orgId,
    email,
  };
  const response = await fetch(adminOrgInviteUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
  return response;
}
