/* eslint-disable react/forbid-prop-types */
/* eslint-disable object-curly-newline */
import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import AppState from '@state';

import { deleteOrgInvite } from '@api/restricted/org-invite-api';

import useIsoDateFormat from '@hooks/useIsoDateFormat';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import EmailIcon from '@assets/icons/email-send.svg';
import Icon from '@ui-kit/icon';
import ExpandIcon from '@assets/icons/list-expand.svg';
import CollapseIcon from '@assets/icons/list-collapse.svg';
import TrashIcon from '@assets/icons/trash-outline.svg';
import LinkButton from '@ui-kit/buttons/linkButton';
import CountDown from '@distinct-components/feedback/countDown';

import { InvitationBarWrapper, InvitationsHeader, InvitationsBody, InvitationsBarItem } from './PendingTeamInviteBarStyles';

function PublisherTeam({
  loadPendingInvites,
  invitations,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [inviteIdToDelete, setInviteIdToDelete] = useState();
  const [timerId, setTimerId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const d = useIsoDateFormat;

  const onToggleViewInvites = () => {
    setIsExpanded(!isExpanded);
  };

  const onClickDeleteInvite = (id) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    setInviteIdToDelete(id);
    const newTimerId = setTimeout(() => {
      setInviteIdToDelete(null);
    }, 5000);
    setTimerId(newTimerId);
  };

  const handleDeleteInvite = (invite) => {
    setIsDeleting(true);
    deleteOrgInvite(AppState.pubOrganization.id.value, invite.email)
      .then((response) => {
        if (response.status === 200) {
          loadPendingInvites().then(() => {
            setIsDeleting(false);
            AppState.messages.toastMessage.value = { text: 'Invitation Deleted', variant: 'success' };
          });
        } else {
          const error = response.json()
            .then((json) => (json.error || response.statusText))
            .catch(() => (response.statusText));
          AppState.messages.toastMessage.value = { text: error, variant: 'error' };
          setIsDeleting(false);
        }
      })
      .catch(() => {
        setIsDeleting(false);
        AppState.messages.toastMessage.value = { text: 'Error Deleting Invitation', variant: 'error' };
      });
  };

  return (
    <InvitationBarWrapper>
      <InvitationsHeader isExpanded={isExpanded} onClick={onToggleViewInvites}>
        <Box display="flex" alignItems="center">
          <Icon size="1em" mr="0.75em" color="var(--active-nav)"><EmailIcon /></Icon>
          <Text>
            Pending:
            {' '}
            {invitations.length}
          </Text>
        </Box>
        <Box pt="2px">
          {isExpanded
            ? <Icon size="1.5em" cursor color="var(--collapse-toggle)"><CollapseIcon /></Icon>
            : <Icon size="1.5em" cursor color="var(--collapse-toggle)"><ExpandIcon /></Icon>}
        </Box>
      </InvitationsHeader>
      {isExpanded
      && (
      <InvitationsBody>
        {invitations.map((invite) => (
          <InvitationsBarItem>
            <Box>
              <Text fontWeight="500">{invite.email}</Text>
              <Text fontSize="0.75em">
                Sent:
                {' '}
                {d(invite.createdAt)}
              </Text>
            </Box>
            <Box>
              {inviteIdToDelete === invite.id
                ? (
                  <Box display="flex" alignItems="center">
                    <CountDown mr="0.25em" mt="3px" timeInSeconds={5} size={12} color="var(--red-300)" />
                    <LinkButton btnText="Yes, Cancel Invite" variant="small" color="var(--red-300)" mr="1em" onClick={() => handleDeleteInvite(invite)} />
                  </Box>
                )
                : <Icon size="0.875rem" pointerEvents={isDeleting ? 'none' : 'auto'} cursor><TrashIcon onClick={() => onClickDeleteInvite(invite.id)} /></Icon>}
            </Box>
          </InvitationsBarItem>
        ))}
      </InvitationsBody>
      )}

    </InvitationBarWrapper>
  );
}

PublisherTeam.propTypes = {
  loadPendingInvites: PropTypes.func.isRequired,
  invitations: PropTypes.array.isRequired,
};

export default PublisherTeam;
