/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';

import BaseButton from '@ui-kit/buttons/baseButton';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import AlertMessage from '@ui-kit/alert/Alert';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseTextArea from '@ui-kit/inputs/baseTextArea/BaseTextArea';

import { CONTRACT_EXPLANATIONS } from '@constants/supportingCopy';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

function CreateAmendment({
  handleCreateAmendment,
  error,
  isLoading,
  closeFunction,
}) {
  const [amendmentMessage, setAmendmentMessage] = useState(null);

  const onCancelCreateAmendment = () => {
    setAmendmentMessage(null);
    closeFunction();
  };

  const onClickCreateAmendment = () => {
    handleCreateAmendment(amendmentMessage);
  };

  return (
    <BaseEditOverlay formCentered closeFunction={onCancelCreateAmendment}>

      <ConversationPrompt
        primaryMessage="How to make changes to your contract"
        promptLabel="Create Amendment"
        labelColor="var(--purple-300)"
        mb="1em"
      />
      <ConversationExplanation mb="2em" explanation={CONTRACT_EXPLANATIONS.lifecycle.amendMessage} truncateAtCharacter={500} />

      <InputLabel label="Message to Co-Writers" />
      <BaseTextArea
        placeholder="Explain why you are making changes..."
        type="textarea"
        value={amendmentMessage}
        onChange={(e) => setAmendmentMessage((e.target.value))}
        fluid
        maxLength="250"
        mb="2.5em"
      />

      {error
        && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
        />
        )}

      <BaseButton
        btnText="Create Amendment"
        fluid
        isLoading={isLoading}
        onClick={onClickCreateAmendment}
      />
    </BaseEditOverlay>
  );
}

CreateAmendment.propTypes = {
  handleCreateAmendment: PropTypes.func.isRequired,
  closeFunction: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

CreateAmendment.defaultProps = {
  error: null,
};

export default CreateAmendment;
