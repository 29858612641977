import PropTypes from 'prop-types';

import Card from '@distinct-components/cards/baseCard';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import YesIcon from '@assets/icons/check-square.svg';
import NoIcon from '@assets/icons/close-square.svg';

import {
  StyledCardHeader,
  StyledCardBody,
} from './LyricSampleCardStyles';

function LyricSampleCard({ topic, doText, doNotText }) {
  return (
    <Card p="0em">
      <StyledCardHeader><Text fontSize="0.813rem">{topic}</Text></StyledCardHeader>
      <StyledCardBody>
        <Box display="flex" mb="0.5em" alignItems="center">
          <Icon size="1.25em" mr="0.5em" color="var(--red-300)"><NoIcon /></Icon>
          <Text fontSize="0.875rem">{doNotText}</Text>
        </Box>
        <Box display="flex" alignItems="center">
          <Icon size="1.25em" mr="0.5em" color="var(--purple-300)"><YesIcon /></Icon>
          <Text fontSize="0.875rem">{doText}</Text>
        </Box>
      </StyledCardBody>
    </Card>
  );
}

LyricSampleCard.propTypes = {
  topic: PropTypes.string.isRequired,
  doText: PropTypes.string.isRequired,
  doNotText: PropTypes.string.isRequired,
};

export default LyricSampleCard;
