import { accountCompositionsUrl } from '../urls';
import refresh from '../refresh';

export default async function getCompositions() {
  return refresh(async () => {
    const response = await fetch(accountCompositionsUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}
