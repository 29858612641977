import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';
import Text from '@ui-kit/typography/text';

export const StyledPublicHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border);
    min-height: 5em;
    padding: 0em 2em;

    a {
        color: var(--text-primary);
        font-weight: 500;
        font-size: 0.75rem;
    }
`;

export const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2em 1em;
`;

export const Content = styled.div`
    width: 100%;
    max-width: 44em;

    .identity-header {
        transition: all 700ms ease-in;
    }
`;

export const AvatarImgWrapper = styled.div(({
  avatarImg,
}) => `
        border: 2px solid ${avatarImg ? 'var(--panel-divider)' : 'var(--brand-primary)'};
        height: 6em;
        width: 6em;
        border-radius: 50%;
        background-image: url('/assets/illustrations/make-it-rain.svg');
        ${avatarImg && `background-image: url(${avatarImg})`};
        background-size: cover;
        background-position: top center;
        margin-bottom: 1em;
    
        @media screen and (max-width: ${pointBreaks.sm}) {
          margin-right: 0em;
          margin-bottom: 0.5em;
          height: 6em;
          width: 6em;
        }
    
        ${space};
    `);

export const AvatarCheckWrapper = styled.div`
      position: absolute;
      right: 3px;
      top: 8px;
  
      @media screen and (max-width: ${pointBreaks.sm}) {
          right: 4px;
          top: 8px;
        }
  `;

export const DidWrapper = styled(Text)`
    word-break: break-all;
  `;
