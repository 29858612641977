import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const StyledWorkspaceList = styled.div`
    min-height: 8em;
    max-height: 22em;
    border-bottom: 0.8px solid var(--card-border);
    margin-bottom: 1.5em;
    overflow: scroll;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        min-height: 14em;
        max-height: 14em;
    }
`;

export const StyledListItem = styled.div`
    display: flex;
    padding: 1em 0em;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.8px solid var(--card-border);

    &:last-child {
        border-bottom: none;
    }

    .feedback {
        animation: fade-out 2s ease forwards;
    }

    @keyframes fade-out {
        0% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    ${space};

`;
