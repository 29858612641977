import { workspaceRecordingsUrl } from '../urls';
import refresh from '../refresh';

export default async function getWorkspaceRecordings(workspaceId) {
  return refresh(async () => {
    const response = await fetch(`${workspaceRecordingsUrl}?workspace=${workspaceId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}
