import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import { space } from 'styled-system';

export const MenuWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 0em;
  }

  @media screen and (min-width: ${pointBreaks.sm}) {
    padding: 0.5em 0.5em;
  }

  @media screen and (min-width: ${pointBreaks.md}) {
    padding: 2em 1.75em;
  }
  
`;

export const VerifiedSessionWrapper = styled.div`
  background: var(--trust);
  display: none;
  padding: 1em;
  justify-content: space-between;

  @media screen and (max-width: ${pointBreaks.sm}) {
    display: flex;
  }
`;

export const MenuItem = styled.div(({
  isActive,
}) => `
  display: flex;
  align-items: center;
  padding: 0.5em 0.75em;
  cursor: pointer;
  border-radius: 0.5em;
  margin-bottom: 1.5em;
  ${space},

  &:last-child {
    margin-bottom: 0em;
  }

  & svg {
    height: 18px;
    transform: translateY(2px);
    color: ${isActive ? 'var(--white)' : 'var(--text-soft-mute)'};
  }

  & p {
    font-weight: ${isActive ? '600' : 'inherit'};
    color: ${isActive ? 'var(--white)' : 'var(--text-soft-mute)'};
  }

  @media screen and (max-width: ${pointBreaks.sm}) {
    border-bottom: 0.8px solid var(--card-border);
    padding: 1.25em 1em;
    background: transparent;
    border-radius: none;
    margin-bottom: 0em;
    
    & svg {
      height: 18px;
      transform: translateY(2px);
      color: ${isActive ? 'var(--purple-300)' : 'var(--text-soft-mute)'};
    }

    & p {
      color: ${isActive ? 'var(--text-primary)' : 'var(--text-soft-mute)'};
    }
  }

  @media screen and (min-width: ${pointBreaks.sm}) {
    background: ${isActive ? 'var(--active-nav)' : 'transparent'};
    padding: 0.5em 0.125em;
    margin-bottom: 1em;
    justify-content: center;
  }

  @media screen and (min-width: ${pointBreaks.md}) {
    background: ${isActive ? 'var(--active-nav)' : 'transparent'};
    justify-content: flex-start;
    padding: 0.625em 0.625em;
  }
`, space);

export const MenuName = styled.p`
  font-size: 13px;
  margin: 0em;
  padding-left: 0.5em;
  display: block;

  @media screen and (max-width: ${pointBreaks.sm}) {
    display: block
  }

  @media screen and (min-width: ${pointBreaks.sm}) {
    display: none
  }

  @media screen and (min-width: ${pointBreaks.md}) {
    display: block;
  }
`;

export const IssueIndicator = styled.div`
  height: 0.5em;
  width: 0.5em;
  background: var(--orange-300);
  border-radius: 50%;
  position: absolute;
  transform: translate(3px, -3px);
  z-index: 200;

  @media screen and (max-width: ${pointBreaks.sm}) {
    transform: translate(-25px,28px);
  }

  @media screen and (min-width: ${pointBreaks.sm}) {
    transform: translate(2px,-1px);
  }

  @media screen and (min-width: ${pointBreaks.md}) {
    transform: translateY(12px);
  }
`;

export const IssueIndicatorWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: ${pointBreaks.sm}) {
    width: 100%;
  }

  @media screen and (min-width: ${pointBreaks.sm}) {
    width: 38px;
  }

  @media screen and (min-width: ${pointBreaks.md}) {
    width: 122px;
  }
`;

export const OrganizationMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${pointBreaks.md}) {
    display: none;
  }
`;

// TODO: VAR
export const BetaChip = styled.div`
  background: #99a2aa;
  border-radius: 2px;
  position: absolute;
  z-index: 200;
  right: -88px;
  font-size: 11px;
  font-weight: 600;
  padding: 2px 4px;
  color: var(--side-nav-panel);
`;

export const TRASH = styled.div`
@media screen and (max-width: ${pointBreaks.sm}) {
  transform: translate(-25px,28px);
}

@media screen and (min-width: ${pointBreaks.sm}) {
  transform: translate(2px,-1px);
}

@media screen and (min-width: ${pointBreaks.md}) {
  transform: translateY(12px);
}`;
