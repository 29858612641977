/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';

import Icon from '@ui-kit/icon';
import BlueCheck from '@assets/icons/blue-check.svg';
import {
  StyledAvatar, AvatarWrapper, StyledAvatarImg, AvatarCheckWrapper,
} from './AvatarStyles';

function Avatar({
  size,
  imgURL,
  borderSize,
  borderColor,
  backgroundColor,
  variant,
  isVerified,
  checkSize,
  checkX,
  checkY,
  ...restProps
}) {
  let placeholderURL = '/assets/illustrations/avatar_placeholder.png';
  const theme = document.documentElement.getAttribute('data-theme');
  if (theme === 'light') {
    placeholderURL = '/assets/illustrations/avatar_placeholder_light.png';
  }

  return (
    <StyledAvatar size={size}>
      {isVerified && <AvatarCheckWrapper checkX={checkX} checkY={checkY}><Icon size={checkSize}><BlueCheck /></Icon></AvatarCheckWrapper>}
      <AvatarWrapper
        size={size}
        imgURL={imgURL}
        borderSize={borderSize}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        variant={variant}
        {...restProps}
      >
        {imgURL && (
        <StyledAvatarImg
          src={imgURL}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = placeholderURL;
          }}
          alt="user profile image"
        />
        )}
        {!imgURL
         && (
         <StyledAvatarImg
           src={placeholderURL}
           alt="user profile image"
         />
         )}
      </AvatarWrapper>
    </StyledAvatar>
  );
}

Avatar.propTypes = {
  size: PropTypes.string,
  imgURL: PropTypes.string,
  borderSize: PropTypes.string,
  borderColor: PropTypes.string,
  variant: PropTypes.string,
  backgroundColor: PropTypes.string,
  isVerified: PropTypes.bool,
  checkSize: PropTypes.string,
  checkX: PropTypes.string,
  checkY: PropTypes.string,
};

Avatar.defaultProps = {
  size: null,
  imgURL: null,
  borderSize: null,
  borderColor: null,
  variant: null,
  backgroundColor: null,
  isVerified: false,
  checkSize: '0.875em',
  checkX: null,
  checkY: null,
};

export default Avatar;
