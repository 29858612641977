import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import { route } from 'preact-router';
import AppState from '@state';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import BaseButton from '@ui-kit/buttons/baseButton';
import Text from '@ui-kit/typography/text';

function RepInvitationSuccess({
  isNewUser,
}) {
  const onClickContinue = () => {
    if (isNewUser) {
      route('/personalize?isRep=true');
    } else {
      route('/rep/dashboard');
      AppState.navigation.backActionPath.value = '/rep/dashboard';
    }
  };
  return (
    <Fragment>
      <ConversationPrompt
        primaryMessage="The Artist needs to activate your connection."
        promptLabel="Awaiting Activation"
        labelColor="var(--orange-400)"
        mb="2em"
      />
      <Text mb="2em" fontSize="1rem">We sent the artist a notification. For security reasons, they need to review your verified information one more time and activate the connection.</Text>
      {isNewUser && <Text fontSize="1rem">Next, finish setting up your account.</Text>}
      <BaseButton
        mt="2em"
        btnText={isNewUser ? 'Finish Setting Up' : 'Go to Dashboard'}
        fluid
        onClick={onClickContinue}
      />
    </Fragment>
  );
}

RepInvitationSuccess.propTypes = {
  isNewUser: PropTypes.bool.isRequired,
};

export default RepInvitationSuccess;
