/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import AppState from '@state';
import { route } from 'preact-router';
import { useState, useEffect } from 'preact/hooks';
import anime from 'animejs/lib/anime.es';

import { getRepProxyTokens } from '@api/restricted/rep-proxy-tokens-api';
import loadAccountProfile from '@actions/loadAccountProfile';
import resetGlobalState from '@state/reset';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Avatar from '@ui-kit/avatar';
import Icon from '@ui-kit/icon';
import KeyIcon from '@assets/icons/key-square.svg';
import Spinner from '@ui-kit/loaders/Spinner';

import {
  TransitionWrapper,
  TransitionContent,
} from './RepTransitionOverlayStyles';

function RepTransitionOverlay({
  artist,
  ...restProps
}) {
  const [statusMessage, setStatusMessage] = useState('Checking for Credential');
  const [keyColor, setKeyColor] = useState('var(--inactive)');

  const loadAnimation = anime.timeline({
    autoplay: false,
    delay: 400,
  });

  const routeUser = () => {
    anime({
      targets: '#avatars',
      opacity: [1, 0],
      duration: 500,
      delay: 2000,
      loop: false,
      easing: 'easeOutCubic',
    });
    setTimeout(() => {
      getRepProxyTokens(artist.accountId)
        .then((response) => {
          if (response.status !== 200) {
            route('/error/invalidLink');
          } else {
            response.json().then((json) => {
              resetGlobalState();
              localStorage.setItem('accessToken', json.accessToken);
              localStorage.setItem('refreshToken', json.refreshToken);
              loadAccountProfile().then(() => {
                route('/home');
              });
            });
          }
        })
        .catch(() => {
          route('/error/invalidLink');
        });
    }, 2500);
  };

  const handleSessionStart = () => {
    setTimeout(() => {
      setKeyColor('var(--trust)');
      setStatusMessage('Handling Authentication');
      routeUser();
    }, 2500);
  };

  useEffect(() => {
    setTimeout(() => {
      setKeyColor('var(--green-200)');
      setStatusMessage('Validating Digital Signatures');
      handleSessionStart();
    }, 2500);

    setTimeout(() => {
      loadAnimation.add({
        targets: '#avatars .element',
        translateY: ['16px', 0],
        opacity: [0, 1],
        duration: 600,
        direction: 'normal',
        delay: anime.stagger(100),
      }, 200)
        .add({
          targets: '#avatars .element2',
          translateY: ['16px', -10],
          opacity: [0, 1],
          duration: 600,
          direction: 'normal',
          delay: anime.stagger(100),
        }, 600)
        .add({
          targets: '.copy',
          translateY: ['16px', 0],
          opacity: [0, 1],
          duration: 600,
          direction: 'normal',
          delay: anime.stagger(100),
        }, 800);
      loadAnimation.play();
    }, 500);
  }, []);

  return (
    <TransitionWrapper
      {...restProps}
    >
      <TransitionContent>
        <Box id="avatars" display="flex" justifyContent="space-around" alignItems="center">
          <Box className="element" ml="1em" display="flex" flexDirection="column" alignItems="center" style={{ opacity: 0 }}>
            <Avatar
              backgroundColor="var(--inactive)"
              borderColor="var(--inactive)"
              size="4.5em"
              imgURL={AppState.userProfile.imgURL.value}
              checkSize="1.125em"
              checkY="4px"
            />
            <Text mt="0.5em" fontSize="0.875rem" color="var(--text-secondary)">You</Text>
          </Box>

          <Box className="element2" display="flex" flexDirection="column" alignItems="center" style={{ transform: 'translateY(-10px)', opacity: 0 }}>
            <Icon size="2rem" color={keyColor}><KeyIcon /></Icon>
          </Box>

          <Box className="element" mr="1em" display="flex" flexDirection="column" alignItems="center" style={{ opacity: 0 }}>
            <Avatar
              backgroundColor="var(--inactive)"
              borderColor="var(--inactive)"
              size="4.5em"
              imgURL={`/api/account/profile/picture?id=${artist.accountId}&timestamp=${AppState.timestamp.value}`}
              checkSize="1.125em"
              checkY="4px"
            />
            <Text mt="0.5em" fontSize="0.875rem" color="var(--text-secondary)">{artist.firstName}</Text>
          </Box>
        </Box>

        <Box className="copy" display="flex" flexDirection="column" alignItems="center" style={{ opacity: 0 }}>
          <Text mt="1.5em" fontSize="1.25rem" color="var(--text-primary)" fontWeight="600">Creating Verified Session</Text>
        </Box>

        <Box display="flex" justifyContent="center">
          <Box className="copy" display="flex" alignItems="center" mt="1em" style={{ opacity: 0 }}>
            <Spinner size="1em" variant="page" />
            <Text ml="0.5em" fontSize="0.875rem" color="var(--text-soft-mute)" fontWeight="300">{statusMessage}</Text>
          </Box>
        </Box>

      </TransitionContent>
    </TransitionWrapper>
  );
}

RepTransitionOverlay.propTypes = {
  artist: PropTypes.object.isRequired,
};

export default RepTransitionOverlay;
