import styled from 'styled-components';
import Box from '@ui-kit/box';

export const ResultsWrapper = styled(Box)`
    background: var(--card);
    position: absolute;
    border: 1px solid var(--border);
    box-shadow: 4px 8px 11px var(--base-shadow);
    transform: translateY(-2em);
    padding: 1em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    width: 100%;
    z-index: 300;
`;

export const ResultRow = styled.div`
    display: flex;
    margin-bottom: 0.75em;
    align-items: center;
    cursor: pointer;
    &:last-child {
        margin-bottom: 0em;
    }
`;

export const Result = styled.p`
    font-size: 0.875em;
    margin: 0em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-soft-mute);
`;

export const LoadingPrompt = styled.p`
    font-size: 0.75em;
    margin: 0.25em 0em;
    color: var(--text-soft-mute);
    animation: fade 500ms ease;
    animation-fill-mode: forwards;
    animation-delay: 500ms;
    visibility: collapse;

    @keyframes fade {
        99% {
            visibility: collapse;
        }
        100% {
            visibility: visible;
        }
    }
`;

export const AutoCompleteWrapper = styled.div`
    position: relative;
`;
