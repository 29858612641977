import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState } from 'preact/hooks';
import AppState from '@state';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';

import loadAccountProfile from '@actions/loadAccountProfile';
import putAccountArtistProfile from '@api/restricted/account-artist-profile-api';

import { ACCOUNT_EXPLANATIONS } from '@constants/supportingCopy';

function EditWriterIPI({
  closeFunction,
}) {
  const {
    register, handleSubmit, formState: { isValid },
  } = useForm({ mode: 'onChange' });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleWriterIPISave = (data) => {
    setIsLoading(true);
    putAccountArtistProfile(
      data.ipi,
      AppState.artistProfile.ipn.value,
      AppState.artistProfile.isni.value,
    ).then((response) => {
      setIsLoading(false);
      if (response.status === 200) {
        response.json().then(() => {
          if (AppState.artistProfile.ipiCritical.value) {
            AppState.artistProfile.ipiCritical.value = false;
          }
          loadAccountProfile();
          setError('');
          closeFunction();
        });
      } else {
        response.json().then((json) => {
          if (json.error) {
            setError(json.error);
          } else {
            setError(response.statusText);
          }
        });
      }
    }).catch((err) => {
      setIsLoading(false);
      setError(err.message);
    });
  };

  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>
      <ConversationPrompt
        primaryMessage="Your Collection Society provides you an IPI number"
        promptLabel="Writer IPI Number"
        mb="1em"
      />
      <ConversationExplanation mb="2em" truncateAtCharacter={140} explanation={ACCOUNT_EXPLANATIONS.songwriting.writerIPI} />
      <form onSubmit={handleSubmit(handleWriterIPISave)} autoComplete="off">
        <InputLabel label="Your Writer IPI" />
        <BaseInput
          {...register('ipi', { required: true })}
          id="ipi"
          name="ipi"
          placeholder="Enter 9-11 Digit IPI"
          defaultValue={AppState.artistProfile.ipi.value}
          pattern="[0-9]+"
          maxLength="11"
          minLength="9"
          fluid
          type="text"
          inputMode="decimal"
          mb="2.25em"
        />
        {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}
        <BaseButton mb="1.5em" type="submit" btnText="Save" disabled={!isValid} fluid isLoading={isLoading} />
      </form>
    </BaseEditOverlay>
  );
}

EditWriterIPI.propTypes = {
  closeFunction: PropTypes.func.isRequired,
};

export default EditWriterIPI;
