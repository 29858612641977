/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const StyledDetailsRow = styled.div`
    display: flex;
    justify-content: space-between;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;
    }
`;

export const StyledIdentifiersWrapper = styled.div`
    display: flex;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;
    }
`;

export const StyledDefaultsWrapper = styled.div`
    display: flex;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        margin-top: 0.75em;
        flex-direction: column;
        gap: 0.75em;
    }
`;
