/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import AppState from '@state';

import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BreakLine from '@ui-kit/dividers/breakLine';
import BasePillTypeAhead from '@distinct-components/typeAheadInputs/basePillTypeAhead';

function FilterPublisherConnection({
  handleConnectionApplied,
  appliedConnections,
}) {
  const activeClientConnections = AppState.orgConnections.value.filter((connection) => connection.isActive && connection.connectionIsClient);

  return (
    <Box mb="1.5rem">
      <BreakLine mt="1.5em" mb="1.5rem" color="var(--panel-info-border)" />
      <InputLabel mb="1em" label="Original Publisher Connection" />
      <BasePillTypeAhead
        availableOptions={activeClientConnections}
        selectedOptions={appliedConnections}
        handleChange={(data) => handleConnectionApplied(data)}
      />
      <BreakLine mt="1.5em" color="var(--panel-info-border)" />
    </Box>
  );
}

FilterPublisherConnection.propTypes = {
  handleConnectionApplied: PropTypes.func.isRequired,
  appliedConnections: PropTypes.array,
};

FilterPublisherConnection.defaultProps = {
  appliedConnections: null,
};

export default FilterPublisherConnection;
