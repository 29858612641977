import { useState } from 'preact/hooks';
import { route } from 'preact-router';
import AppState from '@state';

import { postRepCheckout } from '@api/restricted/rep-checkout-api';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import BreakLine from '@ui-kit/dividers/breakLine';
import BaseButton from '@ui-kit/buttons/baseButton';

import FullScreenModal from '@layouts/full-screen/full-screen-modal';

import { REP_SUBSCRIPTION_PLANS } from '@constants/subscriptionTiers.js';

import {
  SubCardContent,
  SubCardWrapper,
  SubCard,
} from './RepSubscribeStyles.jsx';

// TODO: Filter by testMode once there are real plans
const ACTIVE_PLANS = REP_SUBSCRIPTION_PLANS.filter((plan) => plan.isActive);

function RepSubscribe() {
  const [isLoadingStripe, setIsLoadingStripe] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState(null);

  const useToastAlertError = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'error' };
  };

  const onClickSubscribe = (priceId) => {
    setSelectedPriceId(priceId);
    setIsLoadingStripe(true);
    postRepCheckout(priceId, `${window.location.origin}/rep/dashboard`, `${window.location.origin}/rep/subscribe`)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            window.location.href = json.checkoutURL;
          });
        } else {
          setIsLoadingStripe(false);
          setSelectedPriceId(null);
          response.json()
            .then((json) => useToastAlertError(json.error || response.statusText))
            .catch(() => useToastAlertError(response.statusText));
        }
      })
      .catch((err) => {
        useToastAlertError(err.message);
        setIsLoadingStripe(false);
        setSelectedPriceId(null);
      });
  };

  return (
    <FullScreenModal formWidth="100%" closeFunction={() => route('/rep/account')} logoPath="/rep/dashboard">
      <SubCardContent>
        <Text fontSize="2rem" fontWeight="800" lineHeight="1">Connect With More Artists</Text>
        <SubCardWrapper>
          {ACTIVE_PLANS.map((plan) => (
            <SubCard>
              <Box display="flex" style={{ justifyContent: 'space-between' }}>
                <Box>
                  <Text color="var(--text-primary)" fontSize="1.25rem" fontWeight="600">{plan.name}</Text>
                  <Text color="var(--text-soft-mute)">{plan.description}</Text>
                </Box>
                <Text mt="2px" fontSize="18px" fontWeight="300">{plan.price}</Text>
              </Box>
              <Text color="var(--text-secondary)" lineHeight="1.7" mt="0.5rem">
                This subscription allows you to have up to
                {' '}
                {plan.metadata.maxArtists}
                {' '}
                active writer connections at the same time. Get more done with secure connections with your writers.
              </Text>
              <BreakLine mt="2em" color="var(--panel-info-border)" />
              <BaseButton
                mt="2.5em"
                onClick={() => onClickSubscribe(plan.stripePriceId)}
                isLoading={isLoadingStripe && selectedPriceId === plan.stripePriceId}
                btnText="Subscribe"
                fluid
              />
            </SubCard>
          ))}
        </SubCardWrapper>
      </SubCardContent>

    </FullScreenModal>
  );
}

export default RepSubscribe;
