import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const CoreWrapper = styled.div`
  background: var(--background);
`;

export const CoreAlertWrapper = styled.div`
  position: sticky;
  background: var(--background);
  width: 100%;
  height: 56px;
  display: flex;
  align-items: flex-end;
  top: 0;

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 0em 1em;
  }
`;

export const AdminNavWrapper = styled.div` 
  width: 16em;
  background: var(--card);
  border-right: 1px solid var(--border);
  height: 100vh;
  position: fixed;
`;

export const AdminNavItem = styled.div` 
  width: 100%;
  background: var(--panel-card);
  border-bottom: 1px solid var(--border);
  padding: 1.5em 2em;
  display: flex;
  align-items: center;
  color: var(--icon);
  font-weight: 600;
  cursor: pointer;
  font-size: 0.875rem;

  &:hover {
    background: var(--card);
  }
`;

export const CoreHeaderWrapper = styled.div`
  background: var(--background);
  top: 0;
  z-index: 500;
  position: fixed;
  left: 0;
  right: 0;
  border-bottom: 1px solid var(--border);
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0em 1.5em;
`;

export const CoreWorkSpaceWrapper = styled.div`
  display: flex;
  padding-top: 5em;
`;

export const CoreContentWrapper = styled.div`
  width: 100%;
  margin-left: 32px;
  margin-right: 32px;
  padding-top: 2em;
  padding-left: 16em;
`;
