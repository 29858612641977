/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState } from 'preact/hooks';
import { Fragment } from 'preact';

import AppState from '@state/AppState';
import loadOrgLists from '@actions/loadOrgLists';
import { postOrgList, patchOrgList, deleteOrgList } from '@api/restricted/org-lists-api';

import AlertMessage from '@ui-kit/alert/Alert';
import Header from '@ui-kit/typography/header';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseTextArea from '@ui-kit/inputs/baseTextArea/BaseTextArea';
import Box from '@ui-kit/box';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import CountDown from '@distinct-components/feedback/countDown';

import FullScreenModal from '@layouts/full-screen/full-screen-modal';

function CreateEditList({
  handleCloseManageList,
  existingList,
}) {
  const {
    register, handleSubmit, formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeletingList, setIsDeletingList] = useState(false);

  const handleSaveList = (data) => {
    const cleanedData = {
      name: data.name.trim().split(/\s+/).join(' '),
      description: data.description || null,
    };
    if (!cleanedData.name) {
      setError('Invalid list name');
      return;
    }

    const handleCreateEditList = async () => (
      existingList?.id
        ? patchOrgList(existingList.id, AppState.pubOrganization.id.value, cleanedData.name, cleanedData.description)
        : postOrgList(AppState.pubOrganization.id.value, cleanedData.name, cleanedData.description)
    );

    setIsLoading(true);
    handleCreateEditList()
      .then((response) => {
        if (response.status === 200) {
          loadOrgLists(AppState.pubOrganization.id.value, { forcedLoad: true }).then(() => {
            setIsLoading(false);
            setError('');
            handleCloseManageList(true);
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const onClickRemoveList = () => {
    setShowDeleteConfirmation(true);
    setTimeout(() => {
      setShowDeleteConfirmation(false);
    }, 5000);
  };

  const handleRemoveList = () => {
    if (!existingList?.id) {
      setError('List to Delete does not exist');
      return;
    }
    setIsDeletingList(true);
    deleteOrgList(existingList.id, AppState.pubOrganization.id.value)
      .then((response) => {
        if (response.status === 200) {
          loadOrgLists(AppState.pubOrganization.id.value, { forcedLoad: true }).then(() => {
            setIsDeletingList(false);
            setError('');
            setShowDeleteConfirmation(false);
            handleCloseManageList(true);
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsDeletingList(false);
        }
      })
      .catch((err) => {
        setIsDeletingList(false);
        setError(err.message);
      });
  };

  return (
    <FullScreenModal formWidth="23em" closeFunction={() => handleCloseManageList(false)}>
      <Header mb="0.75em">{existingList?.id ? 'Edit List' : 'Create a List'}</Header>
      <form onSubmit={handleSubmit(handleSaveList)} autoComplete="off">
        <InputLabel label="Name" />
        <BaseInput
          {...register('name', { required: true })}
          id="name"
          name="name"
          defaultValue={existingList?.name}
          placeholder="Name this List..."
          type="text"
          pattern=".*[^\s].*"
          fluid
          mb="1.5rem"
        />
        <InputLabel label="Description" />
        <BaseTextArea
          {...register('description', { required: false })}
          id="description"
          name="description"
          defaultValue={existingList?.description}
          placeholder="What compositions will you save here..."
          maxLength="200"
          fluid
          mb="2.5em"
        />

        {error && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
        />
        )}

        <BaseButton mb="1.5em" type="submit" btnText="Save" disabled={!isValid || isDeletingList} fluid isLoading={isLoading} />
      </form>

      {existingList
      && (
        <Box mt="2rem" display="flex" alignItems="center" justifyContent="center">
          {showDeleteConfirmation
            ? (
              <Fragment>
                <CountDown mr="0.25em" timeInSeconds={5} size={12} color="var(--red-300)" />
                <LinkButton btnText="Yes, Remove List" color="var(--red-300)" mr="1em" onClick={handleRemoveList} />
              </Fragment>
            )
            : <LinkButton type="button" color="var(--red-300)" spinnerVariant="danger" isLoading={isDeletingList} btnText="Remove List" onClick={onClickRemoveList} />}
        </Box>
      )}
    </FullScreenModal>
  );
}

CreateEditList.propTypes = {
  handleCloseManageList: PropTypes.func.isRequired,
  existingList: PropTypes.object,
};

CreateEditList.defaultProps = {
  existingList: null,
};

export default CreateEditList;
