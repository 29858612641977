import { workspaceAccountInviteTokenUrl } from '../urls';

export async function getWorkspaceAccountInviteToken(token) {
  const url = `${workspaceAccountInviteTokenUrl}?token=${encodeURIComponent(token)}`;
  const response = await fetch(url, {
    method: 'GET',
  });
  return response;
}

export async function postWorkspaceAccountInviteToken(email, token) {
  const data = {
    email,
    token,
  };
  const response = await fetch(workspaceAccountInviteTokenUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response;
}
