import PropTypes from 'prop-types';

import Text from '@ui-kit/typography/text';

function Checkout({ success }) {
  return (
    <div>
      {success ? (
        <div>
          <Text>Congrats you are a Switchchord member!</Text>
        </div>
      ) : (
        <div>
          <Text>Don&apos;t worry you can always be a member later.</Text>
        </div>
      )}
      <Text><a href="/settings">Go Back To Settings</a></Text>
    </div>
  );
}

Checkout.propTypes = {
  success: PropTypes.bool.isRequired,
};

export default Checkout;
