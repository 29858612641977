import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import Box from '@ui-kit/box';

export const NotificationsPageWrapper = styled.div`
    display: block;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background: var(--background);
`;

export const NotificationsContentWrapper = styled.div`
      display: flex;
      width: 100%;
      max-width: 42.5em;
      flex-direction: column;

      @media screen and (max-width: ${pointBreaks.sm}) {
        margin-top: 1em;
        padding: 1em;
     }
`;

export const NotificationsListWrapper = styled.div`
      width: 100%;
      background: var(--card);
      border: 0.8px solid var(--card-border);
      border-radius: 0.5em;
      padding: 1em 2em;
      flex: 1;
      margin-top: 1.5em;

      @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 1em 1.5em;
      }
`;

export const NotificationsHeader = styled(Box)`
     position: sticky;
     background: var(--background);
     top: 0;
     z-index: 500;
     padding-top: 6em;
     padding-bottom: 1.5em;

    @media screen and (max-width: ${pointBreaks.sm}) {
      padding-top: 1em;
    }
`;
