/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import AppState from '@state';

import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BreakLine from '@ui-kit/dividers/breakLine';
import BasePillTypeAhead from '@distinct-components/typeAheadInputs/basePillTypeAhead';

function FilterInternalTags({
  handleInternalTagApplied,
  appliedTags,
}) {
  return (
    <Box mb="1.5rem">
      <InputLabel mb="1em" label="Internal Tag is one of:" />
      <BasePillTypeAhead
        availableOptions={AppState.pubOrganization.customTags.value}
        selectedOptions={appliedTags}
        handleChange={(data) => handleInternalTagApplied(data)}
      />
      <BreakLine mt="1.5em" color="var(--panel-info-border)" />
    </Box>
  );
}

FilterInternalTags.propTypes = {
  handleInternalTagApplied: PropTypes.func.isRequired,
  appliedTags: PropTypes.array,
};

FilterInternalTags.defaultProps = {
  appliedTags: null,
};

export default FilterInternalTags;
