import { loginUrl } from '../urls';

export async function postLoginWithEmail(email, secret) {
  const data = {
    email,
    secret,
  };
  const response = await fetch(loginUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response;
}

export async function postLoginWithUsername(username, secret) {
  const data = {
    username,
    secret,
  };
  const response = await fetch(loginUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response;
}

export async function postLoginWithClientUuid(clientUuid, secret) {
  const data = {
    client_uuid: clientUuid,
    secret,
  };
  const response = await fetch(loginUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response;
}
