import PropTypes from 'prop-types';

import Card from '@distinct-components/cards/baseCard';

import {
  CardHeaderWrapper,
  CardHeader,
  CardBodyWrapper,
} from './HeaderCardStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function HeaderCard({
  children,
  headerText,
  ...restProps
}) {
  return (
    <Card padding="0em" {...restProps} className="sc-card-mobile-bg">
      <CardHeaderWrapper>
        <CardHeader>
          {headerText}
        </CardHeader>
      </CardHeaderWrapper>
      <CardBodyWrapper>
        {children}
      </CardBodyWrapper>
    </Card>
  );
}

HeaderCard.propTypes = {
  children: childrenPropTypes.isRequired,
  headerText: PropTypes.string.isRequired,
};

export default HeaderCard;
