/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';

export const ConversationTextWrapper = styled.div(
  () => `
    ${space};
`,
  space,
);

export const LabelText = styled.div(
  ({
    labelColor,
  }) => `
    font-size: 16px;
    color: ${labelColor || 'var(--brand-primary)'};
    margin-bottom: 0.5em;
    font-weight: 600;
    ${space};
`,
  space,
);

export const PrimaryText = styled.span(
  () => `
    font-size: 1.5rem;
    color: var(--text-soft-mute);
    font-weight: 800;
    line-height: 1.5;
    ${space}

    .highlight {
      color: var(--brand-primary);
    }
`,
  space,
);

export const SecondaryText = styled.span(
  () => `
    font-size: 1.5rem;
    color: var(--text-soft-mute);
    font-weight: 300;
`,
  space,
);
