import { useMemo } from 'preact/hooks';
import styled from 'styled-components';
import { space } from 'styled-system';

const BreakLine = styled.hr(({
  color,
  width,
  opacity,
}) => {
  const breakWidth = useMemo(() => (width || '100%'), [width]);

  return `
    margin: 0em;
    width: ${breakWidth};
    border-top: 0.8px solid ${color || 'var(--card-border)'};
    border-bottom: none;
    background-color: transparent;
    border-right: none;
    border-left: none;
    opacity: ${opacity || 1};
  `;
}, space);

export default BreakLine;
