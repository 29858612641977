/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-unescaped-entities */
import { route } from 'preact-router';
import FullScreenModal from '@layouts/full-screen/full-screen-modal';
import {
  useEffect, useLayoutEffect, useRef, useState,
} from 'preact/hooks';
import AppState from '@state';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Header from '@ui-kit/typography/header';
import WizardProgress from '@distinct-components/feedback/wizardProgress';

import AddPubDetails from './sections/addPubDetails';
import AddPubTerms from './sections/addPubTerms';
import ReviewPubInvite from './sections/reviewPubInvite';

function PublisherAddConnection() {
  const [step, setStep] = useState('publisher');
  const [progressWidth, setProgressWidth] = useState(10);
  const [progressSubHeader, setProgressSubHeader] = useState('Get Started');
  const [recipient, setRecipient] = useState();
  const [terms, setTerms] = useState();

  const pageRef = useRef(null);

  useEffect(() => {
    document.title = 'Add Publisher Connection';
  }, []);

  useLayoutEffect(() => {
    pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    switch (step) {
      case 'review':
        setProgressWidth(100);
        setProgressSubHeader('Ready to Send');
        break;
      case 'terms':
        setProgressWidth(40);
        if (recipient?.user?.id) {
          setProgressSubHeader(`${recipient.user?.firstName} ${recipient.user?.lastName}`);
        } else {
          setProgressSubHeader(recipient?.email);
        }
        break;
      default:
        setProgressWidth(20);
    }
  }, [step]);

  const handleCancelInvitation = () => {
    route(`/publisher/${AppState.pubOrganization.uuid.value}/connections`);
  };

  const onClickStepBack = () => {
    switch (step) {
      case 'review':
        setStep('terms');
        break;
      case 'terms':
        setStep('publisher');
        break;
      default:
        setProgressWidth(30);
    }
  };

  return (
    <FullScreenModal formWidth="24em" closeFunction={handleCancelInvitation}>
      <div ref={pageRef} />
      <Box mb="1em">
        {{
          publisher: <Box>
            <Header mb="0.25em">Get Started</Header>
            <Text>Enter your publisher contact's email.  If they do not have an account, we will send your invite and get them signed up.</Text>
          </Box>,
          terms: <Box>
            <Header mb="0.25em">Connection Terms</Header>
            <Text>Define how long the connection will remain active and the territories covered.</Text>
          </Box>,
          review: <Box mb="0.25em">
            <Header mb="0.25em">Review and Send</Header>
            <Text>Confirm the information is correct and send the invite.  You can monitor invites from the “pending” tab.</Text>
          </Box>,
        }[step]}
      </Box>

      <WizardProgress
        headerText="Connect Publisher"
        subHeaderText={progressSubHeader}
        progressWidth={progressWidth}
        handleStepBack={onClickStepBack}
        showBackButton={step !== 'publisher'}
        progressIcon="send"
        mb="1.5em"
      />

      <Box>
        {{
          publisher: <AddPubDetails setStep={setStep} recipient={recipient} setRecipient={setRecipient} />,
          terms: <AddPubTerms setStep={setStep} terms={terms} setTerms={setTerms} />,
          review: <ReviewPubInvite setStep={setStep} recipient={recipient} terms={terms} />,
        }[step]}
      </Box>
    </FullScreenModal>
  );
}

export default PublisherAddConnection;
