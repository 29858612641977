/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
  useEffect, useLayoutEffect, useState,
} from 'preact/hooks';
import AppState from '@state';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { getWorkspaceRecordingFile } from '@api/restricted/workspace-recording-api';

import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import CloseIcon from '@assets/icons/close-solo.svg';
import Spinner from '@ui-kit/loaders/Spinner';
import Text from '@ui-kit/typography/text';

import {
  PlayerContainer,
  TitleContainer,
} from './AudioPlayerStyles';

function MusicPlayer({ uuid }) {
  const [recordingError, setRecordingError] = useState(null);
  const [isLoadingRecording, setIsLoadingRecording] = useState(false);
  const [source, setSource] = useState('');

  const recording = AppState.media.currentlyPlaying.value;

  useEffect(() => {
    if (AppState.media.currentlyPlaying.value) {
      setIsLoadingRecording(true);
      getWorkspaceRecordingFile(uuid, recording.id)
        .then((response) => {
          if (response.status === 200) {
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(blob);
              setSource(url);
              setIsLoadingRecording(false);
            });
          } else {
            response.json()
              .then((json) => setRecordingError(json.error || response.statusText))
              .catch(() => setRecordingError(response.statusText));
            setIsLoadingRecording(false);
          }
        })
        .catch((err) => {
          setRecordingError(err.message);
          setIsLoadingRecording(false);
        });
    }
  }, [recording]);

  useLayoutEffect(() => {
    if (AppState.media.isPlaying.value === false) {
      setSource('');
    }
  }, [AppState.media.isPlaying.value]);

  const onPlayAudio = () => {
    AppState.media.isPlaying.value = true;
  };

  const onClickClosePlayer = () => {
    AppState.media.currentlyPlaying.value = null;
    AppState.media.isPlaying.value = false;
    setSource('');
  };

  return (
    <PlayerContainer isActive={recording !== null}>
      {AppState.media.currentlyPlaying.value
      && (
        <Box width="100%">
          <TitleContainer>
            <Box>
              {recording?.name.slice(0, 50)}
              {recording?.name.length > 50 && '...'}
              {recordingError && <Text fontSize="0.75rem" color="var(--red-300)">Unable to Load Audio</Text>}
            </Box>
            <Box display="flex" alignItems="center">
              <Icon pt="3px" size="0.875em" cursor onClick={onClickClosePlayer}><CloseIcon /></Icon>
            </Box>
          </TitleContainer>
        </Box>
      )}
      {isLoadingRecording
        ? (
          <Box width="100%" display="flex" justifyContent="center" pt="1.25em">
            <Spinner size="1.25em" variant="page" />
          </Box>
        )
        : (
          <AudioPlayer
            autoPlay
            src={source}
            onPlay={onPlayAudio}
            onEnded={onClickClosePlayer}
          />
        )}
    </PlayerContainer>

  );
}

MusicPlayer.propTypes = {
  uuid: PropTypes.string,
};

MusicPlayer.defaultProps = {
  uuid: null,
};

export default MusicPlayer;
