/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';

import useIsoDateFormat from '@hooks/useIsoDateFormat';

import Box from '@ui-kit/box';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import Text from '@ui-kit/typography/text';
import JsonIcon from '@assets/icons/code-bracket.svg';
import Icon from '@ui-kit/icon';
import DotMenu from '@distinct-components/menus/dotMenu';

import {
  CardWrapper,
  CardHeader,
  CardBodyRow,
  StyledHeaderLabel,
  StyledHeaderData,
  StyledBodyLabel,
  StyledBodyData,
  CodePreview,
  CodeBracketBtn,
  ScrollOpacityPad,
} from './CredentialCardStyles';

function CredentialCard({
  credential,
  handleRevoke,
  handleDelete,
  label,
  title,
  bodyHeight,
  ...restProps
}) {
  const [showJSON, setShowJSON] = useState(false);

  const d = useIsoDateFormat;

  return (
    <CardWrapper
      {...restProps}
    >
      <Box>
        <CardHeader isActive={credential.isActive}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box pt="3px">
                <Text fontSize="0.75rem" fontWeight="500" opacity="0.7" mb="0.125rem" color="var(--white)">
                  {label}
                </Text>
                <Text fontSize="1.25rem" lineHeight="1.2" fontWeight="800" mb="0.5em" color="var(--white)">
                  {title}
                </Text>
                <Box display="flex">
                  <Box mr="1.75em">
                    <StyledHeaderLabel>
                      Issued On
                    </StyledHeaderLabel>
                    <StyledHeaderData>
                      {d(credential.createdAt)}
                    </StyledHeaderData>
                  </Box>
                  <Box>
                    <StyledHeaderLabel>
                      Issued By
                    </StyledHeaderLabel>
                    <StyledHeaderData>
                      {credential.issuedBy}
                    </StyledHeaderData>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box display="flex">
              <CodeBracketBtn active={showJSON} onClick={() => setShowJSON(!showJSON)}>
                <Icon size="1em" color="var(--white)" cursor style={{ transform: 'translateY(-1px)' }}><JsonIcon /></Icon>
              </CodeBracketBtn>
              {(credential.isActive && handleRevoke) && (
                <DotMenu
                  color="var(--white)"
                  mt="2px"
                  menuTransform="translate(-140px, -10px)"
                  menuItems={[
                    { label: 'Revoke Credential', method: () => handleRevoke() },
                  ]}
                  style={{ transform: 'translateX(6px)' }}
                />
              )}

            </Box>
          </Box>
        </CardHeader>

        <CardBodyRow height={bodyHeight}>
          {!showJSON ? (
            <Box>

              <Box mb="0.5em">
                <StyledBodyLabel>Issued To</StyledBodyLabel>
                <StyledBodyData>{credential.issuedTo}</StyledBodyData>
              </Box>

              {credential.featuredData.map((data) => (
                <Box mb="0.5em">
                  <StyledBodyLabel>{data.label}</StyledBodyLabel>
                  <StyledBodyData>{data.value}</StyledBodyData>
                </Box>
              ))}

            </Box>
          )
            : (
              <Box>
                <CodePreview id="code">
                  {JSON.stringify(credential.credentialJSON, null, 2)}
                </CodePreview>
                {!credential.isActive && handleDelete
                    && (
                    <Box display="flex" justifyContent="center" width="100%" pt="8px" pb="4px">
                      <LinkButton
                        type="button"
                        color="var(--red-300)"
                        spinnerVariant="danger"
                        btnText="Delete Credential"
                        onClick={handleDelete}
                      />
                    </Box>
                    )}
              </Box>
            )}
        </CardBodyRow>

      </Box>
      <ScrollOpacityPad />
    </CardWrapper>
  );
}

CredentialCard.propTypes = {
  credential: PropTypes.object.isRequired,
  handleRevoke: PropTypes.func,
  handleDelete: PropTypes.func,
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  bodyHeight: PropTypes.string,
};

CredentialCard.defaultProps = {
  handleRevoke: null,
  handleDelete: null,
  label: 'Verifiable Credential',
  bodyHeight: '15em',
};

export default CredentialCard;
