import { orgArtistCredentialsUrl } from '../urls';
import refresh from '../refresh';

export async function patchOrgArtistCredentialRevoked(orgId, credentialId) {
  const data = {
    org: orgId,
    credential: credentialId,
  };
  return refresh(async () => {
    const response = await fetch(orgArtistCredentialsUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteOrgArtistCredential(orgId, credentialId) {
  const data = {
    org: orgId,
    credential: credentialId,
  };
  return refresh(async () => {
    const response = await fetch(orgArtistCredentialsUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
