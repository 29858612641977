/* eslint-disable no-alert */
import PropTypes from 'prop-types';

import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import QuestionIcon from '@assets/icons/question-mark.svg';

import { MarkWrapper, MobileMarkWrapper } from './QuestionPromptStyles';

function QuestionPrompt({
  questionPrompt,
}) {
  const onTapPrompt = () => {
    alert(questionPrompt);
  };
  return (
    <Box>
      <MarkWrapper>
        <span className="toolTipText">{questionPrompt}</span>
        <Icon size="0.45em" pt="1px" cursor><QuestionIcon /></Icon>
      </MarkWrapper>
      <MobileMarkWrapper>
        <Icon size="0.45em" pt="1px" cursor onClick={onTapPrompt}><QuestionIcon /></Icon>
      </MobileMarkWrapper>
    </Box>
  );
}

QuestionPrompt.propTypes = {
  questionPrompt: PropTypes.string.isRequired,
};

export default QuestionPrompt;
