import { useState, useEffect, useRef } from 'preact/hooks';
import styled from 'styled-components';
import AppState from '@state';
import anime from 'animejs/lib/anime.es';

import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import ContractIcon from '@assets/illustrations/contract-illustration.svg';
import WritersIcon from '@assets/icons/writers-icon.svg';
import Text from '@ui-kit/typography/text';
import { Fragment } from 'preact';

const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 192px;
  padding-top: 1.25rem;
`;

const InfoTitleWrapper = styled.div`
    width: 100%;
    margin-top: 0.875rem;
    margin-bottom: 0.5rem;
`;

const InfoTitle = styled.div`
    border: 1px solid var(--active-nav);
    color: var(--text-primary);
    max-width: 160px;
    margin: 0 auto;
    border-radius: 50px;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 6px 8px;
`;

function CompositionSignatureStatus() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSigning, setIsSigning] = useState(false);
  const [isSingleWriter, setIsSingleWriter] = useState(false);
  const [totalWriters, setTotalWriters] = useState(0);
  const [totalSigned, setTotalSigned] = useState(0);
  const isAnimatedRef = useRef(false);

  const animateLogo = () => {
    if (!isAnimatedRef.current) {
      isAnimatedRef.current = true;
      anime({
        targets: '.pen',
        rotate: [23, 0],
        opacity: [0, 1],
        duration: 800,
        easing: 'easeOutCubic',
      });
    }
  };

  const checkSignatureStatus = () => {
    const sigData = AppState.workspace.writerSignatureData.value;
    const isSignatureMissing = sigData.some((w) => !w.signature);
    setIsSigning(isSignatureMissing);
    const totalSignatures = sigData.filter((w) => !!w.signature);
    setTotalSigned(totalSignatures.length);
    setIsLoading(false);
  };

  useEffect(() => {
    animateLogo();
    if (AppState.workspaceAccounts.value.filter((account) => account.isWriter).length === 1) {
      setIsSingleWriter(true);
      setIsLoading(false);
    }
    if (AppState.workspace.writerSignatureData.value) {
      checkSignatureStatus();
      setTotalWriters(AppState.workspace.writerSignatureData.value.length);
    }
  }, [AppState.workspace.writerSignatureData.value]);

  return (
    <InfoWrapper>
      <Box>
        <Icon
          color="var(--active-nav)"
          size="4rem"
        >
          <ContractIcon />
        </Icon>
      </Box>

      {!isLoading && (
      <Fragment>
        <InfoTitleWrapper>
          <InfoTitle>{isSigning ? 'Contract in Progress' : 'Ownership Finalized'}</InfoTitle>
        </InfoTitleWrapper>

        <Box>
          {isSingleWriter
            ? (
              <Box display="flex" alignItems="center">
                <Icon size="1.125rem" color="var(--text-secondary)" mr="0.5em"><WritersIcon /></Icon>
                <Text fontSize="0.813rem" color="var(--text-secondary)">
                  Single Writer Composition
                </Text>
              </Box>
            )
            : (
              <Box display="flex" alignItems="center">
                <Icon size="1.125rem" color="var(--text-secondary)" mr="0.5em"><WritersIcon /></Icon>
                {totalSigned === totalWriters ? (
                  <Text fontSize="0.813rem" color="var(--text-secondary)">
                    {totalWriters}
                    {' '}
                    Writers
                  </Text>
                ) : (
                  <Text fontSize="0.813rem" color="var(--text-secondary)">
                    {totalSigned}
                    {' '}
                    of
                    {' '}
                    {totalWriters}
                    {' '}
                    Signed
                  </Text>
                )}
              </Box>
            )}
        </Box>
      </Fragment>
      )}
    </InfoWrapper>
  );
}

export default CompositionSignatureStatus;
