/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useRef } from 'preact/hooks';
import useClickOutside from '@hooks/useClickOutside';
import useKeyPressAction from '@hooks/useKeyPressAction';

import CloseSquare from '@assets/icons/close-square.svg';
import Icon from '@ui-kit/icon';

import {
  ModalPage,
  ModalContainer,
  ModalUtilityRow,
  ModalContentWrapper,
  ModalFormWrapper,
  ModalFormWrapperCentered,
} from './WorkspaceEditOverlayStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function WorkspaceEditOverlay({
  children,
  closeFunction,
  showCloseFunction,
  formWidth,
  formCentered,
  isClickOutsideDisabled,
}) {
  const ref = useRef();
  useClickOutside(ref, () => {
    if (!isClickOutsideDisabled) {
      closeFunction();
    }
  });

  useKeyPressAction('Escape', () => {
    closeFunction();
  });

  return (
    <ModalPage id="modal-page">
      <ModalContainer ref={ref}>
        <ModalUtilityRow>
          {closeFunction && showCloseFunction
          && <Icon size="1.5em" cursor onClick={closeFunction}><CloseSquare /></Icon>}
        </ModalUtilityRow>
        <ModalContentWrapper formCentered={formCentered}>
          {formCentered
            ? (
              <ModalFormWrapperCentered formWidth={formWidth}>
                {children}
              </ModalFormWrapperCentered>
            )
            : (
              <ModalFormWrapper formWidth={formWidth}>
                {children}
              </ModalFormWrapper>
            )}
        </ModalContentWrapper>
      </ModalContainer>
    </ModalPage>
  );
}

WorkspaceEditOverlay.propTypes = {
  children: childrenPropTypes.isRequired,
  closeFunction: PropTypes.any,
  formWidth: PropTypes.string,
  formCentered: PropTypes.bool,
  showCloseFunction: PropTypes.bool,
  isClickOutsideDisabled: PropTypes.bool,
};

WorkspaceEditOverlay.defaultProps = {
  closeFunction: null,
  formWidth: null,
  formCentered: false,
  showCloseFunction: true,
  isClickOutsideDisabled: false,
};

export default WorkspaceEditOverlay;
