/* eslint-disable no-param-reassign */
import { useState, useEffect, useRef } from 'preact/hooks';
import { Fragment } from 'preact';
import PropTypes from 'prop-types';
import { route } from 'preact-router';

import AppState from '@state';

import loadOrganization from '@actions/loadOrganization';
import loadOrgLists from '@actions/loadOrgLists';

// import useIsoDateFormat from '@hooks/useIsoDateFormat';
import useWindowDimensions from '@hooks/useWindowDimensions';

import CorePublisherLayout from '@layouts/corePublisher';
import PageCard from '@layouts/pageCard';

import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import ListIcon from '@assets/illustrations/list-chip.svg';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';
import EditIcon from '@assets/icons/edit-line.svg';

import CreateEditList from './sections/createEditList';

import { TableIllustrationWrapper, TableSearchEmptyPrompt } from './PublisherListsStyles';

function PublisherViewList({ uuid }) {
  const [isLoading, setIsLoading] = useState(true);
  const [searchActive, setSearchActive] = useState(false);
  const [currentLists, setCurrentLists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedList, setSelectedList] = useState();
  const [isCreateEditList, setIsCreateEditList] = useState(false);

  const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  useEffect(() => {
    if (height < 1080) {
      setItemsPerPage(7);
    }

    loadOrganization(uuid, { forcedLoad: false }).then(() => {
      loadOrgLists(AppState.pubOrganization.id.value, { forcedLoad: false }).then(() => {
        setCurrentLists(AppState.publisherLists.value);
        setSearchActive(false);
        setIsLoading(false);
      });
    });
  }, []);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = currentLists.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    const results = [];
    setCurrentPage(1);
    if (query) {
      AppState.publisherLists.value.forEach((list) => {
        if (list.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(list);
        }
      });
      setCurrentLists(results);
      setSearchActive(true);
    } else {
      setCurrentLists(AppState.publisherLists.value);
      setSearchActive(false);
    }
  };

  const onClickList = (list) => {
    route(`/publisher/${uuid}/lists/${list.id}`);
  };

  const onClickCreateList = () => {
    setSelectedList(null);
    setIsCreateEditList(true);
  };

  const onClickEditList = (list) => {
    setSelectedList(list);
    setIsCreateEditList(true);
  };

  const handleCloseManageModal = (dataUpdated) => {
    if (dataUpdated) {
      setCurrentLists(AppState.publisherLists.value);
      setSearchActive(false);
      setCurrentPage(1);
    }
    setSelectedList(null);
    setIsCreateEditList(false);
  };

  return (
    <CorePublisherLayout>
      <PageCard
        title="Lists"
        primaryAction={{ method: onClickCreateList, text: '+ New' }}
        isLoadingPage={isLoading}
      >
        <div ref={pageRef} />
        {AppState.publisherLists.value.length === 0
          ? (
            <Card variant="panel" mt="1.5em">
              <BaseEmptyState
                title="No Lists"
                message="Create and Manage Lists of Prioritized Compositions"
                illustration="activities"
                minHeight="38em"
              />
            </Card>
          )
          : (
            <Fragment>
              <TableControls
                itemName="List"
                tableData={AppState.publisherLists.value}
                currentResults={currentResults}
                indexOfFirst={indexOfFirst}
                indexOfLast={indexOfLast}
                showSearch
                searchActive={searchActive}
                searchResults={currentLists}
                handleSearch={onSearchResults}
              />
              <Table mb="1em">
                <TableHeader>
                  <TableCell widthPercent="48%" pr="0.75em">List Name</TableCell>
                  <TableCell>{' '}</TableCell>
                </TableHeader>
                {currentResults
                  .map((list) => (
                    <TableRow>
                      <TableCell widthPercent="100%" pr="0.75em" style={{ width: '100%' }}>
                        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                          <TableIllustrationWrapper className="cursor-p" onClick={() => { onClickList(list); }}>
                            <Icon className="icon" size="3.25em" pt="2px" mr="1em"><ListIcon /></Icon>
                            <Box pt="0.25rem">
                              <Text fontSize="0.938rem" color="var(--text-primary)" fontWeight="600" lineHeight="1.2">{list.name}</Text>
                              <Text mt="0.125em" fontSize="0.75rem;" maxWidth="16em" color="var(--text-secondary)" truncate>
                                {list.numComps}
                                {' '}
                                Compositions
                              </Text>
                            </Box>
                          </TableIllustrationWrapper>
                          <Box display="flex" justifyContent="flex-end">
                            <Icon size="1em" pt="0.25em" pr="0.25em" cursor onClick={() => onClickEditList(list)}><EditIcon /></Icon>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                {(currentResults.length === 0)
              && (
                <TableSearchEmptyPrompt>
                  <Text color="var(--text-muted-panel)">No Lists Match Your Search</Text>
                </TableSearchEmptyPrompt>
              )}
              </Table>
              <TablePagination
                itemsPerPage={itemsPerPage}
                totalItems={currentLists.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </Fragment>
          )}

      </PageCard>

      {isCreateEditList
      && (
        <CreateEditList uuid={uuid} handleCloseManageList={handleCloseManageModal} existingList={selectedList} />
      )}
    </CorePublisherLayout>
  );
}

PublisherViewList.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PublisherViewList;
