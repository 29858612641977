import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';

import { route } from 'preact-router';

import BaseButton from '@ui-kit/buttons/baseButton';
import Header from '@ui-kit/typography/header';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import BreakLine from '@ui-kit/dividers/breakLine';
import SwitchMachine from '@animations/onboarding/SwitchMachine';
import WritersAnimation from '@animations/onboarding/WritersAnimation';
import DataContainer from '@animations/onboarding/DataContainer';
import ShareAnimation from '@animations/onboarding/ShareAnimation';

import FullScreenModal from '@layouts/full-screen/full-screen-modal';

function WriterGetStarted() {
  const [onboardSection, setOnboardSection] = useState(1);
  const [isRepMode, setIsRepMode] = useState(false);

  useEffect(() => {
    document.title = 'Getting Started';
    const queryParams = new URLSearchParams(window.location.search);
    const isRep = queryParams.get('isRep');
    if (isRep === 'true') { setIsRepMode(true); }
  }, []);

  const onContinue = (section) => {
    setOnboardSection(section);
  };

  const onClickComplete = () => {
    if (isRepMode) {
      route('/rep/dashboard');
    } else {
      route('/home');
    }
  };

  return (
    <FullScreenModal formWidth="28em">
      <div id="topRef" />
      {
        {
          1:
  <Fragment>
    <Box display="flex" justifyContent="center" alignItems="flex-start" height="240px" width="100%" pb="2em">
      <WritersAnimation />
    </Box>
    <Box display="flex" justifyContent="center">
      <Header fontSize="1.25rem">
        {isRepMode ? 'Protect Your Writer\'s Work' : 'Give Your Songs Identity'}
      </Header>
    </Box>
    <BreakLine mb="1.5em" mt="1.5em" color="var(--active-nav)" />
    <Box minHeight="6.5em" mb="2.5em">
      <Text mb="1em">
        {isRepMode ? 'Document Works for Your Writer.' : 'Document Composition info and ownership.'}
      </Text>
      <Text mb="1em">
        {isRepMode ? 'Create and send out digital Split Sheets.' : 'Generate and e-sign your Split Sheets.'}
      </Text>
      {/* <Text>
        Share Composition data with 3rd parties
      </Text> */}
    </Box>
    <Box>
      <BaseButton
        mb="1.5em"
        type="submit"
        btnText="Next"
        fluid
        onClick={() => onContinue(2)}
      />
    </Box>
  </Fragment>,
          2:
  <Fragment>
    <Box display="flex" justifyContent="center" alignItems="flex-start" height="240px" width="100%" pb="2em">
      <DataContainer />
    </Box>
    <Box display="flex" justifyContent="center">
      <Header fontSize="1.25rem">
        {isRepMode ? 'Future Proof Your Writer\'s Work' : 'Future Proof Your Work'}
      </Header>
    </Box>
    <BreakLine mb="1.5em" mt="1.5em" color="var(--active-nav)" />
    <Box minHeight="6.5em" mb="2.5em">
      <Text mb="1em">
        We are using some really cool emerging technologies.
      </Text>
      <Text>
        This ensures the data and split sheets are verifiable and tamper-proof.
      </Text>
    </Box>
    <Box>
      <BaseButton
        mb="1.5em"
        type="submit"
        btnText="Next"
        fluid
        onClick={() => onContinue(3)}
      />
    </Box>
  </Fragment>,
          3:
  <Fragment>
    <Box display="flex" justifyContent="center" alignItems="center" height="240px" width="100%" pb="2em">
      <ShareAnimation />
    </Box>
    <Box display="flex" justifyContent="center">
      <Header fontSize="1.25rem">Share with Publishers</Header>
    </Box>
    <BreakLine mb="1.5em" mt="1.5em" color="var(--active-nav)" />
    <Box minHeight="6.5em" mb="2.5em">
      <Text mb="1em">
        If your publisher sent you an invite, your song info is automatically delivered when you sign a split sheet.
      </Text>
      <Text mb="1em">
        You can also use Share Links or Summary PDFs to share data.
      </Text>
      {/* <Text>
        Don&apos;t let your split information be mixed up in an email copy and paste.
      </Text> */}
    </Box>
    <Box>
      <BaseButton
        mb="1.5em"
        type="submit"
        btnText="Next"
        fluid
        onClick={() => onContinue(4)}
      />
    </Box>
  </Fragment>,
          4:
  <Fragment>
    <Box display="flex" justifyContent="center" alignItems="center" height="238px" width="100%" pb="2em">
      <SwitchMachine />
    </Box>
    <Box display="flex" justifyContent="center">
      <Header fontSize="1.25rem">Built by Musicians for Musicians</Header>
    </Box>
    <BreakLine mb="1.5em" mt="1.5em" color="var(--active-nav)" />
    <Box minHeight="6.5em" mb="2.5em">
      <Text mb="1em">
        We are a group of songwriters, musicians, and lawyers.
      </Text>
      <Text>
        Thanks for being a part of our mission to protect your work and make music data verifiable.
      </Text>
    </Box>
    <Box>
      <BaseButton
        mb="1.5em"
        type="submit"
        btnText="Get Started"
        fluid
        onClick={onClickComplete}
      />
    </Box>
  </Fragment>,
        }[onboardSection]
      }
    </FullScreenModal>
  );
}

export default WriterGetStarted;
