/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'preact/hooks';
import { Fragment } from 'preact';

import d from '@hooks/useIsoDateFormat';
import useClickOutside from '@hooks/useClickOutside';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Avatar from '@ui-kit/avatar';
import Icon from '@ui-kit/icon';
import WarningIcon from '@assets/icons/alert-callout.svg';
import ClipboardIcon from '@assets/icons/clipboard-check.svg';
import PopoverMenu from '@ui-kit/menu/popoverMenu';

import {
  CardWrapper,
  StyledCardText,
  StyledDataRow,
  MenuWrapper,
  ActionMenuItem,
  MenuDot,
} from './RepArtistCardStyles';

function RepArtistCard({
  artist,
  handleSelectedArtist,
  handleSelectedOption,
  menuOptions,
  ...restProps
}) {
  const [showOptionMenu, setShowOptionMenu] = useState();
  const [daysRemaining, setDaysRemaining] = useState();

  useEffect(() => {
    if (artist.validTo) {
      const currentDate = new Date().toISOString().split('T')[0];
      const currentDateObj = new Date(currentDate);
      const targetDateObj = new Date(artist.validTo);
      const timeDifference = targetDateObj - currentDateObj;
      const numberOfDays = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
      setDaysRemaining(numberOfDays);
    }
  }, []);

  const menuRef = useRef(null);
  useClickOutside(menuRef, () => setShowOptionMenu(false));

  const onClickCardOption = (option, selectedArtist) => {
    setShowOptionMenu(false);
    handleSelectedOption(option, selectedArtist);
  };

  return (
    <CardWrapper
      {...restProps}
    >
      <Box>
        <Box
          display="flex"
          alignItems="center"
          style={{ opacity: artist.disabled ? '0.5' : 1 }}
          className={handleSelectedArtist ? 'cursor-p' : 'cursor-na'}
          onClick={() => handleSelectedArtist(artist)}
        >
          <Avatar
            backgroundColor="var(--inactive)"
            borderColor="var(--inactive)"
            size="3.5em"
            imgURL={artist.imgURL}
            checkSize="1.125em"
            checkY="4px"
          />
          <Box ml="0.875em">
            <Text fontSize="1rem" fontFamily="var(--font-lexend)" fontWeight="500" color="var(--text-soft-mute)">
              {artist.firstName}
              {' '}
              {artist.lastName}
            </Text>
            {artist.isActive && (
              <StyledDataRow>
                <Fragment>
                  <StyledCardText>
                    {artist.numTasks > 0 ? (
                      <Box display="flex">
                        <Icon pt="2px" size="0.8rem" color="var(--orange-300)" mr="0.35rem"><ClipboardIcon /></Icon>
                        <StyledCardText>
                          Rep Tasks:
                          {' '}
                          {artist.numTasks}
                        </StyledCardText>
                      </Box>
                    ) : 'No Action Items'}
                  </StyledCardText>

                  {daysRemaining && daysRemaining < 30 && (
                    <StyledCardText>
                      Access Expires in:
                      {' '}
                      <span style={{ color: 'var(--red-300)' }}>
                        {daysRemaining}
                        {' '}
                        {daysRemaining === 1 ? 'Day' : 'Days'}
                      </span>
                    </StyledCardText>
                  )}
                </Fragment>
              </StyledDataRow>
            )}
            {artist.isPending && (
              <StyledDataRow>
                <Box display="flex">
                  {artist.repAccepted && (
                    <Icon pt="2px" size="0.8rem" color="var(--red-300)" mr="0.35rem"><WarningIcon /></Icon>
                  )}
                  {artist.repAccepted ? (
                    <StyledCardText>Awaiting Artist Activation</StyledCardText>
                  ) : (
                    <StyledCardText>View Invitation from Artist</StyledCardText>
                  )}
                </Box>
              </StyledDataRow>
            )}
            {!artist.isActive && !artist.isPending && (
              <StyledDataRow>
                <StyledCardText>
                  {artist.artistRevokedAt && `Credential Revoked by Artist on ${d(artist.artistRevokedAt)}`}
                  {!artist.artistRevokedAt && artist.repRevokedAt && `Credential Revoked by You on ${d(artist.repRevokedAt)}`}
                  {!artist.artistRevokedAt && !artist.repRevokedAt && `Credential Expired on ${d(artist.validTo)}`}
                </StyledCardText>
              </StyledDataRow>
            )}
          </Box>
        </Box>
      </Box>

      {menuOptions !== null && (
        <MenuWrapper>
          <Box display="flex" className="cursor-p" onClick={() => setShowOptionMenu(!showOptionMenu)} style={{ alignSelf: 'baseline', minHeight: '0.75rem', paddingTop: '2px' }}>
            <MenuDot />
            <MenuDot />
            <MenuDot />
          </Box>
          {showOptionMenu && (
          <PopoverMenu ref={menuRef} width="8em" transform="translate(2px,0px)">
            {menuOptions.map((item) => (
              <ActionMenuItem
                onClick={() => onClickCardOption(item.action, artist)}
              >
                {item.label}
              </ActionMenuItem>
            ))}
          </PopoverMenu>
          )}
        </MenuWrapper>
      )}

    </CardWrapper>
  );
}

RepArtistCard.propTypes = {
  artist: PropTypes.object.isRequired,
  handleSelectedArtist: PropTypes.func,
  handleSelectedOption: PropTypes.func,
  menuOptions: PropTypes.array,
};

RepArtistCard.defaultProps = {
  handleSelectedArtist: null,
  handleSelectedOption: null,
  menuOptions: null,
};

export default RepArtistCard;
