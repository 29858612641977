/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'preact/hooks';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import InputLabel from '@ui-kit/inputs/inputLabel';
import ButtonSegment from '@ui-kit/buttons/buttonSegment/ButtonSegment';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseButton from '@ui-kit/buttons/baseButton';
import BreakLine from '@ui-kit/dividers/breakLine';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';
import AlertMessage from '@ui-kit/alert/Alert';

import {
  StyledDateWrapper,
} from './AddPubTermsStyles';

const d = dayjs().format();
const TODAY_DATE = d.substring(0, 10);

function AddPubTerms({
  terms,
  setTerms,
  setStep,
  ...restProps
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [hasEndDate, setHasEndDate] = useState(false);
  const [fullAccess, setFullAccess] = useState(true);
  const [hasPowerOfAttorney, setHasPowerOfAttorney] = useState(false);

  const {
    register, handleSubmit, setValue, formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (terms) {
      setHasEndDate(terms.hasEndDate);
      setValue('toDate', terms.toDate?.substring(0, 10) || null);
      setFullAccess(terms.fullAccess);
      setHasPowerOfAttorney(terms.hasPowerOfAttorney);
    }
    setIsLoading(false);
  }, []);

  const onTogglePowerOfAttorney = () => {
    setHasPowerOfAttorney(!hasPowerOfAttorney);
  };

  const onClickContinue = (data) => {
    const utcToDate = data.toDate ? dayjs(data.toDate).endOf('day') : null;
    if (hasEndDate) {
      if (!utcToDate) {
        setError('Enter an end date or set "Until I Cancel"');
        return;
      }
    }

    if (hasPowerOfAttorney && !fullAccess) {
      setError('Your Representative needs Full Access to sign your splits');
      return;
    }

    setError('');
    setIsSaving(true);
    const termSet = {
      hasEndDate,
      toDate: utcToDate?.format() || null,
      fullAccess,
      hasPowerOfAttorney,
    };
    setTerms(termSet);
    setTimeout(() => {
      setIsSaving(false);
      setStep('review');
    }, 800);
  };

  return (
    <Box {...restProps}>
      <BreakLine mb="1.5em" mt="0.5em" color="var(--panel-info-border)" />

      <form id="termsForm" onSubmit={handleSubmit(onClickContinue)} autoComplete="off">

        <InputLabel label="What is the term of this connection?" />
        <Box display="flex" mb="1.5em" width="100%">
          <ButtonSegment onClick={() => setHasEndDate(false)} btnText="Until I Cancel" variant="first" active={!hasEndDate} fluid maxHeight="inherit" />
          <ButtonSegment onClick={() => setHasEndDate(true)} btnText="Until a Date" variant="last" active={hasEndDate} fluid maxHeight="inherit" />
        </Box>

        <StyledDateWrapper>
          {hasEndDate && (
            <Box className="dateBox" width="12em">
              <InputLabel label="To Date" />
              <BaseInput
                {...register('toDate', { required: false })}
                id="toDate"
                name="toDate"
                type="date"
                min={TODAY_DATE}
                fluid
                mb="2.5em"
                required
              />
            </Box>
          )}
        </StyledDateWrapper>

        <BreakLine mb="1.5em" color="var(--panel-info-border)" />

        {/* TODO: HANDLE PUB CONNECTION ELEMENTS LIKE TERM ETC */}
        <InputLabel label="What actions can they take in your account?" />
        <Box display="flex" mb="0.5em" width="100%">
          <ButtonSegment onClick={() => setFullAccess(true)} btnText="Full Access" variant="first" active={fullAccess} fluid maxHeight="inherit" />
          <ButtonSegment onClick={() => setFullAccess(false)} btnText="View Only" variant="last" active={!fullAccess} fluid maxHeight="inherit" />
        </Box>

        {fullAccess ? (
          <Text fontSize="0.813rem" mb="1.5rem" color="var(--text-medium-mute)">Full Access does not include power to sign your splits or edit your account level settings.</Text>
        ) : (
          <Text fontSize="0.813rem" mb="1.5rem" color="var(--text-medium-mute)">Your representative can view everything in your account but cannot create or edit any data.</Text>
        )}

        <BreakLine mb="1.5em" color="var(--panel-info-border)" />

        <Box display="flex" justifyContent="space-between" mb="1.25em">
          <Box>
            <Text fontWeight="600">Can Sign Split Sheets</Text>
            <Text fontSize="0.813rem" color="var(--text-medium-mute)" pr="1em">Allows them to sign Split Sheets on your behalf. They will need to provide proof for Power of Attorney.</Text>
          </Box>
          <ToggleSwitch id="attorney">
            <input
              id="attorney"
              name="attorney"
              type="checkbox"
              disabled={isLoading}
              checked={hasPowerOfAttorney}
              onChange={onTogglePowerOfAttorney}
            />
          </ToggleSwitch>
        </Box>

        <BreakLine mb="1.5em" color="var(--panel-info-border)" />

        {error
        && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
        />
        )}

        <Box display="flex" justifyContent="flex-end">
          <BaseButton
            mb="1.5em"
            btnText="Continue"
            disabled={isSaving || !isValid}
            isLoading={isSaving}
            fluid
            decoration="arrow"
          />
        </Box>
      </form>

    </Box>
  );
}

AddPubTerms.propTypes = {
  setStep: PropTypes.func.isRequired,
  setTerms: PropTypes.func.isRequired,
  terms: PropTypes.object,
};

AddPubTerms.defaultProps = {
  terms: null,
};

export default AddPubTerms;
