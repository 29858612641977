/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const LyricViewWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5em;
    background: var(--panel-card);
    border: 1px solid var(--border);
    color: var(--text-soft-mute);
    margin-bottom: 1.5em;
    padding: 1.5em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;


    @media screen and (max-width: ${pointBreaks.sm}) {
        grid-template-columns: repeat(1, 1fr);
        padding: 1em;
        grid-gap: 1em;
    }
`;
