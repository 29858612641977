/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import AppState from '@state';

import { postOrgCompositionTag, deleteOrgCompositionTag } from '@api/restricted/org-composition-tags-api';

import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import InternalTagTypeAhead from '@distinct-components/typeAheadInputs/internalTagTypeAhead';
import BreakLine from '@ui-kit/dividers/breakLine';

function ToolsInternalTags({ compositionId, loadInternalData, internalTags }) {
  const [isLoadingTags, setIsLoadingTags] = useState(false);

  const useToastAlertError = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'error' };
  };

  const handleAddTag = (tag) => {
    if (compositionId < 1) {
      // VAPOR
      alert(`add new tag:${tag.name}`);
      return;
    }

    setIsLoadingTags(true);
    postOrgCompositionTag(AppState.pubOrganization.id.value, compositionId, tag.id)
      .then((response) => {
        if (response.status === 200) {
          loadInternalData(compositionId).then(() => {
            // Might want to have success toast alert
            setIsLoadingTags(false);
          });
        } else {
          response.json()
            .then((json) => useToastAlertError(json.error || response.statusText))
            .catch(() => useToastAlertError(response.statusText));
          setIsLoadingTags(false);
        }
      })
      .catch((err) => {
        setIsLoadingTags(false);
        useToastAlertError(err.message);
      });
  };

  const handleRemoveTag = (tag) => {
    if (compositionId < 1) {
      // VAPOR
      alert(`remove tag:${tag.name}`);
      return;
    }

    setIsLoadingTags(true);
    deleteOrgCompositionTag(AppState.pubOrganization.id.value, compositionId, tag.id)
      .then((response) => {
        if (response.status === 200) {
          loadInternalData(compositionId).then(() => {
            // Might want to have success toast alert
            setIsLoadingTags(false);
          });
        } else {
          response.json()
            .then((json) => useToastAlertError(json.error || response.statusText))
            .catch(() => useToastAlertError(response.statusText));
          setIsLoadingTags(false);
        }
      })
      .catch((err) => {
        setIsLoadingTags(false);
        useToastAlertError(err.message);
      });
  };

  return (
    <Box p="1em 1em 0em 1em">
      <InputLabel mb="1em" label="Internal Tags" />
      <InternalTagTypeAhead
        currentTags={internalTags}
        allTags={AppState.pubOrganization.customTags.value}
        handleAddTag={handleAddTag}
        handleRemoveTag={handleRemoveTag}
        isLoadingTags={isLoadingTags}
      />
      <BreakLine mt="1.5em" color="var(--panel-info-border)" />
    </Box>
  );
}

ToolsInternalTags.propTypes = {
  compositionId: PropTypes.number.isRequired,
  loadInternalData: PropTypes.func.isRequired,
  internalTags: PropTypes.array.isRequired,
};

export default ToolsInternalTags;
