import PropTypes from 'prop-types';

import Spinner from '@ui-kit/loaders/Spinner';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import ArrowIcon from '@assets/icons/arrow-right-circle.svg';
import PdfIcon from '@assets/icons/pdf-page.svg';

import StyledBasedButton from './BaseButtonStyles';

function BaseButton({
  isLoading,
  isDisabled,
  onClick,
  fluid,
  btnText,
  width,
  maxHeight,
  decoration,
  spinnerVariant,
  ...restProps
}) {
  return (
    <StyledBasedButton
      isLoading={isLoading}
      fluid={fluid}
      width={width}
      maxHeight={maxHeight}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      {...restProps}
    >
      {isLoading
        ? <Spinner variant={spinnerVariant} mono size="1.125em" width="2px" />
        : (
          <Box display="flex" justifyContent="center" alignItems="center">
            {decoration === 'pdf' && <Icon size="1.2em" mr="0.75em" pt="3px" color="var(--white)" pointerEvents="none"><PdfIcon /></Icon>}
            { btnText }
            {decoration === 'arrow' && <Icon size="1.25em" ml="0.75em" pt="3px" color="var(--white)" pointerEvents="none"><ArrowIcon /></Icon>}
          </Box>
        )}
    </StyledBasedButton>
  );
}

BaseButton.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  fluid: PropTypes.bool,
  onClick: PropTypes.func,
  btnText: PropTypes.string,
  width: PropTypes.string,
  maxHeight: PropTypes.string,
  decoration: PropTypes.string,
  spinnerVariant: PropTypes.string,
};

BaseButton.defaultProps = {
  isLoading: null,
  isDisabled: null,
  fluid: null,
  btnText: null,
  onClick: null,
  width: 'auto',
  maxHeight: null,
  decoration: null,
  spinnerVariant: null,
};

export default BaseButton;
