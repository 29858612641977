import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import { space } from 'styled-system';

export const ModalPage = styled.div`
  position:fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 3em;
  background: var(--modal-overlay);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
  backdrop-filter: blur(10px);

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 0em;
  }
`;

export const ModalContainer = styled.div`
  max-width: 72em;
  margin: 0 auto;
  width: 100%;
  min-height: calc(100vh - 90px);
  background: var(--panel);
  border: 0.8px solid var(--card-border);
  box-shadow: 2px 4px 11px var(--card-shadow);
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  opacity: 0;

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 0em;
    height: auto;
    border-radius: 0em;
    background: var(--mobile-background);
    border: none;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2.5em 2.5em 2em 2.5em;
  border-bottom: 1px solid var(--panel-divider);

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 1.5em;
    flex-wrap: wrap-reverse;

    #actions {
      width: 100%;
      margin-bottom: 2em;
    }
  }
`;

export const StyledCredentialBadge = styled.div(({
  activeCredential,
}) => `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0em 1em;
  min-height: 2.5em;
  font-size: 0.875rem;
  background: ${activeCredential ? 'var(--trust)' : 'var(--red-200)'};
  color: var(--white);
  font-weight: 600;
  border-radius: 0.5em;
  margin-right: 1.25em;
  cursor: pointer;
`);

export const StyledCloseButton = styled.div`
    border: 1px solid var(--icon-btn-border);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 2.5em;
    min-width: 2.5em;
    border-radius: 0.5em;
    padding: 0em 0.75em;
    cursor: pointer;
    ${space};
`;

export const ProfileTabsSection = styled.div`
  border-top: 1px solid var(--panel-divider);
  padding: 2em 2.5em 2em 2.5em;

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 1.5em;
  }
`;
