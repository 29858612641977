/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
import { useState, useEffect, useRef } from 'preact/hooks';
import { Fragment } from 'preact';
import PropTypes from 'prop-types';
import config from '@config';
import AppState from '@state';

import loadOrganization from '@actions/loadOrganization';
import loadOrgRegistrations from '@actions/loadOrgRegistrations';

import useScript from '@hooks/useScript';
// import useIsoDateFormat from '@hooks/useIsoDateFormat';
import useWindowDimensions from '@hooks/useWindowDimensions';

import CorePublisherLayout from '@layouts/corePublisher';
import PageCard from '@layouts/pageCard';

import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';
import Icon from '@ui-kit/icon';
import EditIcon from '@assets/icons/edit-line.svg';

import { TableSearchEmptyPrompt } from './PublisherRegistrationsStyles';

import CreateEditRegistration from './sections/createEditRegistration';

function PublisherRegistrations({ uuid }) {
  const placesSrc = `https://maps.googleapis.com/maps/api/js?key=${config.google.placesKey}&libraries=places`;
  const placesState = useScript(placesSrc);
  const [isLoadingRegistrations, setIsLoadingRegistrations] = useState(true);
  const [selectedRegistration, setSelectedRegistration] = useState();
  const [isManageRegistration, setIsManageRegistration] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [registrations, setRegistrations] = useState([]);
  const [currentPage, setCurrentPage] = useState(AppState.pagination.teamPage.value);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  // const d = useIsoDateFormat;

  useEffect(() => {
    if (height < 1080) {
      setItemsPerPage(7);
    }
    loadOrganization(uuid, { forcedLoad: false }).then(() => {
      loadOrgRegistrations(AppState.pubOrganization.id.value, { forcedLoad: false }).then(() => {
        const regs = AppState.orgRegistrations.value;
        setRegistrations(regs);
        setIsLoadingRegistrations(false);
      });
    });
  }, []);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = registrations.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    setCurrentPage(1);
    if (query) {
      const results = [];
      AppState.orgRegistrations.value.forEach((reg) => {
        if (reg.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(reg);
        }
      });
      setRegistrations(results);
      setSearchActive(true);
    } else {
      const regs = AppState.orgRegistrations.value;
      setRegistrations(regs);
      setSearchActive(false);
    }
  };

  const addNew = () => {
    setSelectedRegistration(null);
    setIsManageRegistration(true);
  };

  const onClickEditRegistration = (reg) => {
    setSelectedRegistration(reg);
    setIsManageRegistration(true);
  };

  const handleCloseManageRegistration = (dataUpdated) => {
    if (dataUpdated) {
      setRegistrations(AppState.orgRegistrations.value);
      setSearchActive(false);
      setCurrentPage(1);
    }
    setSelectedRegistration(null);
    setIsManageRegistration(false);
  };

  return (
    <CorePublisherLayout>
      <PageCard
        title="PRO Affiliations"
        primaryAction={{ method: addNew, text: '+ New' }}
        isLoadingPage={isLoadingRegistrations}
      >
        <div ref={pageRef} />
        {registrations.length === 0 && !searchActive
          ? (
            <Card variant="panel" mt="1.5em">
              <BaseEmptyState
                title="No Affiliations"
                message="You need at least one Affiliation in order to connect with a writer"
                action={addNew}
                actionText="Add Affiliation"
                illustration="activities"
                minHeight="38em"
              />
            </Card>
          )
          : (
            <Fragment>
              <TableControls
                itemName="Affiliation"
                tableData={registrations}
                currentResults={currentResults}
                indexOfFirst={indexOfFirst}
                indexOfLast={indexOfLast}
                showSearch
                searchActive={searchActive}
                searchResults={registrations}
                handleSearch={onSearchResults}
              />
              <Table mb="1em">
                <TableHeader>
                  <TableCell widthPercent="40%">Name</TableCell>
                  <TableCell>IPI</TableCell>
                  <TableCell>Society</TableCell>
                  <TableCell>{' '}</TableCell>
                </TableHeader>
                {currentResults
                  .map((reg) => (
                    <TableRow>
                      <TableCell widthPercent="40%">
                        <Text fontSize="0.813rem" color="var(--text-primary)" fontWeight="600">
                          {reg.name}
                        </Text>
                      </TableCell>
                      <TableCell mobileLabel="IPI:">{reg.ipi}</TableCell>
                      <TableCell mobileLabel="Society:">{reg.society}</TableCell>
                      <TableCell pr="1em">
                        <Box display="flex" justifyContent="flex-end">
                          <Icon size="1em" pt="0.25em" pr="0.25em" cursor onClick={() => onClickEditRegistration(reg)}><EditIcon /></Icon>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                {(currentResults.length === 0)
              && (
                <TableSearchEmptyPrompt>
                  <Text color="var(--text-muted-panel)">No PRO Affiliations Match Your Search</Text>
                </TableSearchEmptyPrompt>
              )}
              </Table>
              <TablePagination
                itemsPerPage={itemsPerPage}
                totalItems={registrations.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </Fragment>
          )}

      </PageCard>

      {isManageRegistration
      && (
      <CreateEditRegistration
        usePlacesState={placesState}
        handleCloseRegistration={handleCloseManageRegistration}
        registration={selectedRegistration}
      />
      )}
    </CorePublisherLayout>
  );
}

PublisherRegistrations.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PublisherRegistrations;
