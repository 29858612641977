/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState } from 'preact/hooks';
import AppState from '@state';

import { postOrgInvite } from '@api/restricted/org-invite-api';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';

function InviteTeamMember({
  loadPendingInvites,
  closeFunction,
}) {
  const {
    register, handleSubmit, formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    return emailRegex.test(String(email).toLowerCase());
  };

  const handleSendInvitation = (data) => {
    const email = data.email.toLowerCase();
    if (!validateEmail(email)) {
      setError('Invalid email');
    } else {
      setIsLoading(true);
      postOrgInvite(AppState.pubOrganization.id.value, email)
        .then((response) => {
          if (response.status === 200) {
            loadPendingInvites().then(() => {
              setIsLoading(false);
              setError('');
              AppState.messages.toastMessage.value = { text: 'Invitation Sent', variant: 'success' };
              closeFunction();
            });
          } else {
            response.json()
              .then((json) => setError(json.error || response.statusText))
              .catch(() => setError(response.statusText));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err.message);
        });
    }
  };

  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>
      <ConversationPrompt
        primaryMessage="Send Invitation"
        promptLabel="New Team Member"
        mb="1.5em"
      />
      <form onSubmit={handleSubmit(handleSendInvitation)} autoComplete="off">
        <InputLabel label="Team Member Email" />
        <BaseInput
          {...register('email', { required: true })}
          id="email"
          name="email"
          placeholder="user@domain.com"
          fluid
          type="email"
          mb="1.5em"
        />
        {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="2.5em"
          />
          )}

        <BaseButton mb="1.5em" type="submit" btnText="Save" disabled={!isValid} fluid isLoading={isLoading} />
      </form>
    </BaseEditOverlay>
  );
}

InviteTeamMember.propTypes = {
  loadPendingInvites: PropTypes.func.isRequired,
  closeFunction: PropTypes.func.isRequired,
};

export default InviteTeamMember;
