/* eslint-disable react/forbid-prop-types */
import { useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';
import anime from 'animejs/lib/anime.es';

import Box from '@ui-kit/box';

import LyricSampleCard from './items/LyricSampleCard';

import {
  LyricViewWrapper,
} from './LyricSampleStyles';

function LyricSample({ setShowSample, lyricEditorRef }) {
  const loadAnimation = anime.timeline({
    autoplay: false,
    delay: 0,
  });

  useEffect(() => {
    loadAnimation.add({
      targets: '.noTags',
      translateY: ['24px', 0],
      opacity: [0, 1],
      duration: 700,
      direction: 'normal',
    });
    loadAnimation.play();
  }, []);

  const onDismissSample = () => {
    setShowSample(false);
    setTimeout(() => {
      const { current } = lyricEditorRef;
      if (current) {
        current.focus();
      }
    }, 400);
  };

  return (
    <Box>
      <LyricViewWrapper onClick={onDismissSample}>
        <LyricSampleCard topic="Separate Sections" doNotText="One giant paragraph of text" doText="Blank line between verses, etc" />
        <LyricSampleCard topic="No All Caps" doNotText="NOT LIKE THIS" doText="Ah, this is much better" />
        <LyricSampleCard topic="Limit Punctuation" doNotText="Don't end lines with punctuation," doText="Question marks “?” are ok" />
        <LyricSampleCard topic="Type Repeated Lines" doNotText="(Chorus X2)" doText="Type each line as you would hear it" />
        <LyricSampleCard topic="Background Vocals" doNotText="Don’t just type out background vocals" doText="Wrap them like this (oh yeah)" />
        <LyricSampleCard topic="Explicit Lyrics" doNotText="Don't type [Bleep]" doText="Type the actual word" />
      </LyricViewWrapper>
    </Box>
  );
}

LyricSample.propTypes = {
  setShowSample: PropTypes.func.isRequired,
  lyricEditorRef: PropTypes.any.isRequired,
};

export default LyricSample;
