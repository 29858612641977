import styled from 'styled-components';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Card from '@distinct-components/cards/baseCard';
import pointBreaks from '@styles/styleConstants';
import {
  space,
} from 'styled-system';

export const WelcomeWrapper = styled(Box)`
   padding: 2.75em 0em 2em 0em;

   @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 2em 0em 1.75em 0em;
    }

    @media screen and (min-width: ${pointBreaks.lg}) {
        padding: 2.875em 1em 1em 1em;
    }
`;

export const StyledWelcomeHeader = styled(Text)`
    font-size: 1.5rem;
    font-weight: 800;
    font-family: var(--font-secondary);
    color: var(--text-primary);

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1.25rem;
    }
`;

export const StyledWelcomeMessage = styled(Text)`
    font-size: 1.125rem;
    font-weight: 300;
    font-family: var(--font-secondary);

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1rem;
    }
`;

export const HomeCardGrid = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.5em;
    ${space}
    
    .primary {
        flex-basis: 65%;
        transform: translateY(16px);
        opacity: 0;
    }

    .secondary {
        flex-basis: 35%;
        transform: translateY(16px);
        opacity: 0;
    }

    @media screen and (max-width: ${pointBreaks.md}) {
        flex-direction: column;

        .primary {
            flex-basis: 100%;
        }
    
        .secondary {
            flex-basis: 100%;
        }
    }

    @media screen and (min-width: ${pointBreaks.lg}) {
        gap: 2.5em;
        padding: 1em;
    }

`;

export const StyledHomeCard = styled(Card)`
    padding: 0em;
    margin-bottom: 1.5em;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: ${pointBreaks.lg}) {
        margin-bottom: 2.5em;
    }
`;

export const StatCardGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5em;
    margin-bottom: 1.5em;
    ${space}

    @media screen and (max-width: ${pointBreaks.md}) {
        grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (min-width: ${pointBreaks.lg}) {
        gap: 2.5em;
        margin-bottom: 2.5em;
    }
`;

export const StyledStatCard = styled(Card)`
    padding: 0em;
    min-height: 3em;
`;

export const HomeCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--panel-divider);
    margin-left: 1.5em;
    padding: 0.75em 0.75em 0.75em 0em;
    ${space}
`;

export const HomeCardContent = styled.div`
    padding: 0.75em 1em 0.75em 1.5em;
    ${space}
`;

export const HomeCardItem = styled.div`
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    border-bottom: 1px solid var(--ghost);
    padding: 0.5em 0.5em 0.25em 0em;
    transition: all 400ms ease-in-out;
    ${space}
`;

export const StyledCardLabel = styled(Text)`
    font-size: 0.875rem;
    font-weight: 600;
`;
