const pointBreaks = {
  xxl: '1920px',
  xl: '1600px',
  lg: '1441px',
  md: '1025px',
  sm: '780px',
  xs: '420px',
};

export default pointBreaks;
