/* eslint-disable react/forbid-prop-types */
import { useState, useEffect, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import anime from 'animejs/lib/anime.es';
// import dayjs from 'dayjs';

import { getArtistRep } from '@api/restricted/artist-reps-api';

import useClickOutside from '@hooks/useClickOutside';
import useKeyPressAction from '@hooks/useKeyPressAction';
import useTranslateRepActions from '@hooks/useTranslateRepActions';
import useErrorOverlay from '@hooks/useErrorOverlay';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Avatar from '@ui-kit/avatar';
import Icon from '@ui-kit/icon';
import CloseIcon from '@assets/icons/close-solo.svg';
import Tabs from '@ui-kit/tabs/Tabs';
// import LinkButton from '@ui-kit/buttons/linkButton';

import ProfileOverlayActions from './sections/profileOverlayActions';
import ProfileOverlayCredentials from './sections/profileOverlayCredentials';

import {
  ModalContainer,
  ModalPage,
  ModalHeader,
  StyledCredentialBadge,
  StyledCloseButton,
  ProfileTabsSection,
} from './RepresentativeProfileOverlayStyles';

function SongwriterProfileOverlay({
  closeFunction,
  rep,
  // setRepToNuke,
  setCredToRevoke,
  // setCredToDelete,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [profileTab, setProfileTab] = useState('actions');
  const [actions, setActions] = useState([]);
  const [credentials, setCredentials] = useState([]);
  const hasActiveCredential = credentials.some((c) => c.isActive);

  const ref = useRef();
  useClickOutside(ref, () => closeFunction());

  useKeyPressAction('Escape', () => {
    closeFunction();
  });

  const loadAnimation = anime.timeline({
    autoplay: false,
  });

  const loadRepProfile = async () => {
    try {
      const response = await getArtistRep(rep.repId);
      if (response.status !== 200) {
        try {
          const json = await response.json();
          useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading representative profile` });
        } catch {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading representative profile` });
        }
      } else {
        const json = await response.json();
        const credData = json.credentials.map((cred) => ({
          ...cred,
          issuedBy: cred.credentialJSON.issuer.name,
          issuedTo: cred.credentialJSON.credentialSubject.name,
          featuredData: [
            { label: 'Access Level', value: cred.canEdit ? 'Full Account Access' : 'Read Only Access' },
            { label: 'Signing Permission', value: cred.canSign ? 'Has Power of Attorney' : 'No Power of Attorney' },
          ],
        }));
        setCredentials(credData);
        setActions(useTranslateRepActions(json.actions));
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading representative profile` });
    }
  };

  useEffect(() => {
    loadRepProfile().then(() => {
      loadAnimation.add({
        targets: '#overlay',
        backdropFilter: ['blur(0px)', 'blur(10px)'],
        duration: 2000,
      }, 100).add({
        targets: '#container',
        translateY: ['32px', 0],
        opacity: [0, 1],
        duration: 600,
        easing: 'easeOutQuad',
      }, 0);
      loadAnimation.play();
      setTimeout(() => {
        setIsLoading(false);
      }, 800);
    });
  }, []);

  const onProfileTabChange = (tab) => {
    setProfileTab(tab.value);
  };

  return (
    <ModalPage id="overlay">
      <Box ref={ref} m="0 auto" maxWidth="72em" minHeight="calc(100vh - 100px)">
        <ModalContainer id="container">
          <ModalHeader>

            <Box display="flex" alignItems="center">
              <Box mr="1.5em">
                <Avatar
                  size="4.25em"
                  imgURL={rep.avatarURL}
                  isVerified={rep.isVerified}
                  checkSize="1.25em"
                  checkY="5px"
                  checkX="-3px"
                />
              </Box>
              <Box>
                <Text fontSize="1.5rem" lineHeight="1" mb="0.5em" mt="0.25em" fontWeight="600" color="var(--text-primary)">
                  {rep.firstName}
                  {' '}
                  {rep.lastName}
                </Text>
              </Box>
            </Box>

            <Box id="actions" display="flex" justifyContent="flex-end">
              <StyledCredentialBadge activeCredential={hasActiveCredential} onClick={() => setProfileTab('credentials')}>
                {hasActiveCredential ? 'Active Credential' : 'Inactive'}
              </StyledCredentialBadge>
              <StyledCloseButton onClick={closeFunction}>
                <Icon cursor size="0.75em"><CloseIcon /></Icon>
              </StyledCloseButton>
            </Box>
          </ModalHeader>

          <ProfileTabsSection>
            <Tabs
              tabs={[{ name: 'Actions', value: 'actions' }, { name: 'Credentials', value: 'credentials' }]}
              handleTabChange={onProfileTabChange}
              selectedTab={profileTab}
            />
            {{
              actions: <ProfileOverlayActions actions={actions} isLoading={isLoading} />,
              credentials:
  <ProfileOverlayCredentials
    credentials={credentials}
    isLoading={isLoading}
    setCredToRevoke={setCredToRevoke}
  />,
            }[profileTab]}
          </ProfileTabsSection>

        </ModalContainer>

        {/* {setRepToNuke && (
        <Box mt="1em" display="flex" justifyContent="center" alignItems="center" minHeight="3em">
          {!hasActiveCredential && <LinkButton btnText="Delete Writer & Compositions" color="var(--red-300)" onClick={() => setRepToNuke(rep)} />}
        </Box>
        )} */}

      </Box>

    </ModalPage>
  );
}

SongwriterProfileOverlay.propTypes = {
  closeFunction: PropTypes.func.isRequired,
  rep: PropTypes.object.isRequired,
  // setRepToNuke: PropTypes.func,
  setCredToRevoke: PropTypes.func.isRequired,
  // setCredToDelete: PropTypes.func.isRequired,
};

// SongwriterProfileOverlay.defaultProps = {
//   setRepToNuke: null,
// };

export default SongwriterProfileOverlay;
