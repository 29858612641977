/* eslint-disable no-restricted-globals */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS, ArcElement, Tooltip,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import AppState from '@state';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import EmptyIcon from '@assets/icons/science.svg';
import useLightenColors from '@hooks/useLightenColors';

ChartJS.register(ArcElement, Tooltip);

// Having 3 sets to ensure adjacent slices cannot have same color. This works as long as there are at most 9*8*7 = 504 tag labels.
const BACKGROUND_COLOR_SET_1 = ['#4e349a', '#6646a6', '#7b59b2', '#906cbe', '#a480ca', '#b895d7', '#ccaae4', '#dec0f1', '#f1d6ff'];
const BACKGROUND_COLOR_SET_2 = ['#4e349a', '#6646a6', '#7b59b2', '#906cbe', '#a480ca', '#b895d7', '#ccaae4', '#dec0f1'];
const BACKGROUND_COLOR_SET_3 = ['#4e349a', '#6646a6', '#7b59b2', '#906cbe', '#a480ca', '#b895d7', '#ccaae4'];

const LIGHTER_BACKGROUND_COLOR_SET_1 = BACKGROUND_COLOR_SET_1.map((color) => useLightenColors(color, 1800));
const LIGHTER_BACKGROUND_COLOR_SET_2 = BACKGROUND_COLOR_SET_2.map((color) => useLightenColors(color, 1800));
const LIGHTER_BACKGROUND_COLOR_SET_3 = BACKGROUND_COLOR_SET_3.map((color) => useLightenColors(color, 1800));

const selectColorSet = (dataLength, theme) => {
  if (dataLength <= 9 || (dataLength - 1) % 9 !== 0) {
    return theme === 'dark' ? BACKGROUND_COLOR_SET_1 : LIGHTER_BACKGROUND_COLOR_SET_1;
  }
  if ((dataLength - 1) % 8 !== 0) {
    return theme === 'dark' ? BACKGROUND_COLOR_SET_2 : LIGHTER_BACKGROUND_COLOR_SET_2;
  }
  return theme === 'dark' ? BACKGROUND_COLOR_SET_3 : LIGHTER_BACKGROUND_COLOR_SET_3;
};

function DashboardGenreChart({ genres }) {
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [parentCategories, setParentCategories] = useState([]);
  const [chartData, setChartData] = useState(true);

  useEffect(() => {
    const htmlElement = document.querySelector('html');
    const currentTheme = htmlElement.getAttribute('data-theme');

    setTimeout(() => {
      const style = getComputedStyle(document.body);
      const chartColor = style.getPropertyValue('--panel-card');

      // Construct array of parents
      const categories = [];
      genres.forEach((t) => {
        const foundCategory = categories.find((c) => c.name === t.tag.category);
        if (!foundCategory) {
          categories.push({
            name: t.tag.category,
            tags: [t.tag],
            count: t.count,
          });
        } else {
          foundCategory.tags.push(t.tag);
          foundCategory.count += t.count;
        }
      });
      setParentCategories(categories);

      const doughnutData = {
        datasets: [
          {
            data: categories.map((d) => d.count),
            labels: categories.map((d) => `  ${d.name}`),
            backgroundColor: selectColorSet(categories.length, currentTheme),
            borderColor: chartColor,
            borderWidth: 3,
          },
          {
            data: genres.map((d) => d.count),
            labels: genres.map((d) => `  ${d.tag.name}`), // TODO: Make first letter uppercase
            backgroundColor: selectColorSet(genres.length, currentTheme),
            borderColor: chartColor,
            borderWidth: 3,
          },
        ],
      };
      setChartData(doughnutData);
      setIsLoadingChart(false);
    }, 800);
  }, [genres]);

  const handleClickOnChartItem = (item) => {
    if (item) {
      let selectedTags;
      if (item.datasetIndex === 0) {
        // Click on parent tag
        selectedTags = parentCategories[item.index].tags;
      } else if (item.datasetIndex === 1) {
        // Click on child tag
        selectedTags = [genres[item.index].tag];
      }
      if (selectedTags) {
        AppState.publisherCompositionsTable.value = {
          ...AppState.publisherCompositionsTable.value,
          activeConfig: {
            ...AppState.publisherCompositionsTable.activeConfig.value,
            toggle: 'all',
            query: '',
            filters: {
              status: [],
              internalTags: [],
              compositionTags: selectedTags,
            },
          },
          currentPage: 1,
        };
        route(`/publisher/${AppState.pubOrganization.uuid.value}/compositions`);
      }
    }
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (item) => {
            const index = item.dataIndex;
            return `${item.dataset.labels[index]}: ${item.formattedValue}`;
          },
        },
      },
    },
    onHover: (e, chartElements) => {
      const canvas = e.native.target;
      if (chartElements && chartElements.length > 0) {
        canvas.style.cursor = 'pointer';
      } else {
        canvas.style.cursor = 'default';
      }
    },
    onClick: (e, chartElements) => handleClickOnChartItem(chartElements[0]),
  };

  return (
    <Box p="1em">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text fontWeight="600" mb="0.5em">Genre Insights</Text>
      </Box>

      {!isLoadingChart && genres.length > 0
      && (
      <Box p="1em" maxHeight="20.5em" display="flex" justifyContent="center">
        <Doughnut data={chartData} options={options} />
      </Box>
      )}

      {genres.length === 0 && (
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" minHeight="14rem">
          <Icon size="2em" pt="3px" color="var(--text-muted)" mb="0.25em"><EmptyIcon /></Icon>
          <Text color="var(--text-muted)" fontSize="0.75em">No Data Available</Text>
        </Box>
      )}

    </Box>
  );
}

DashboardGenreChart.propTypes = {
  genres: PropTypes.array.isRequired,
};

export default DashboardGenreChart;
