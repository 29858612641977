import PropTypes from 'prop-types';
import IconMonster from './IconStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function Icon({
  children,
  size,
  color,
  cursor,
  pointerEvents,
  ...restProps
}) {
  return (
    <IconMonster
      size={size}
      color={color}
      cursor={cursor}
      pointerEvents={pointerEvents}
      {...restProps}
    >
      {children}
    </IconMonster>
  );
}

Icon.propTypes = {
  children: childrenPropTypes.isRequired,
  size: PropTypes.string,
  cursor: PropTypes.bool,
  color: PropTypes.string,
  pointerEvents: PropTypes.string,
};

Icon.defaultProps = {
  size: null,
  color: null,
  cursor: false,
  pointerEvents: null,
};

export default Icon;
