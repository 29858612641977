import { Fragment } from 'preact';
import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';
// import { route } from 'preact-router';
// import AppState from '@state';

import { postAccountIdentityVerification } from '@api/restricted/account-identity-verification-api';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import BaseButton from '@ui-kit/buttons/baseButton';
import Text from '@ui-kit/typography/text';

import AlertMessage from '@ui-kit/alert/Alert';
import Box from '@ui-kit/box';
import PanelDataCard from '@distinct-components/cards/panelDataCard';
import Icon from '@ui-kit/icon';
import CardIcon from '@assets/icons/identity-card.svg';
import SmileIcon from '@assets/icons/smile-square.svg';
import StripeLogo from '@assets/logos/stripe-logo-mark.svg';

function RepInvitationIdv({
  isNewUser,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const onClickContinue = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const returnUrl = `${window.location.origin}/rep/invitation?${new URLSearchParams({
      email: queryParams.get('email'),
      token: queryParams.get('token'),
      processing: true,
      new: isNewUser,
    })}`;
    setError('');
    setIsLoading(true);
    postAccountIdentityVerification(returnUrl).then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if (json.verification_url) {
            window.location.href = json.verification_url;
            setIsLoading(false);
          } else {
            setError('Unable to generate verification url. Try again.');
            setIsLoading(false);
          }
        });
      }
    }).catch((err) => {
      setError(err.message);
      setIsLoading(false);
    });
  };

  return (
    <Fragment>
      <ConversationPrompt
        primaryMessage="Managing an artist account requires identity verification"
        promptLabel="Identity Verification"
        labelColor="var(--purple-400)"
        mb="2em"
      />
      <Text mb="2em" fontSize="1rem">We use Stripe to verify your human identity. The entire process takes less than a few minutes.</Text>

      <PanelDataCard variant="noCursor" mb="1.25em">
        <Box display="flex" alignItems="center" p="0.75em 0.75em">
          <Icon size="1.45em" mr="0.65em" pt="2px" color="var(--brand-primary)"><CardIcon /></Icon>
          <Box>
            <Text lineHeight="1" fontWeight="500" mb="0.25em">Have an ID Card Ready</Text>
            <Text lineHeight="1" color="var(--text-medium-mute)">Drivers License or Passport</Text>
          </Box>
        </Box>
      </PanelDataCard>

      <PanelDataCard variant="noCursor" mb="1.5em" mt="1em">
        <Box display="flex" alignItems="center" p="0.75em 0.75em">
          <Icon size="1.45em" mr="0.65em" pt="2px" color="var(--brand-primary)"><SmileIcon /></Icon>
          <Box>
            <Text lineHeight="1" fontWeight="500" mb="0.25em">You Need a Smile</Text>
            <Text lineHeight="1" color="var(--text-medium-mute)">Selfies taken for biometric matching</Text>
          </Box>
        </Box>
      </PanelDataCard>

      {error
      && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
        />
      )}

      <BaseButton
        mt="2em"
        btnText="Go to Stripe"
        fluid
        onClick={onClickContinue}
        isLoading={isLoading}
      />

      <Box mt="1.75em" mb="0.75em" display="flex" justifyContent="center">
        <Icon size="8em" color="var(--text-soft-mute)"><StripeLogo /></Icon>
      </Box>
    </Fragment>
  );
}

RepInvitationIdv.propTypes = {
  isNewUser: PropTypes.bool.isRequired,
};

export default RepInvitationIdv;
