import styled from 'styled-components';
import { space, typography } from 'styled-system';

const BaseHeader = styled.div`
    font-size: 1.5rem;
    color: var(--text-primary);
    font-family: var(--font-secondary);
    font-weight: 800;
    ${space};
    ${typography};
`;

export default BaseHeader;
