/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const TableSearchEmptyPrompt = styled.div`
    padding: 1em;

    @media screen and (max-width: ${pointBreaks.sm}) {
       text-align: center;
    }
`;
