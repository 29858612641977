import styled from 'styled-components';
import { space } from 'styled-system';

export const SubCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
`;

export const SubCardWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2em;
    max-width: 1180px;
    margin: 0 auto;
    ${space};
`;

export const SubCard = styled.div`
    padding: 1em;
    border-radius: 16px;
    min-width: 24rem;
    background: var(--panel-card);
    max-width: 24rem;
    ${space};
`;
