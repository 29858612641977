/* eslint-disable react/forbid-prop-types */
import { route } from 'preact-router';
import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import AppState from '@state';

import { patchAccountNotificationsRead } from '@api/restricted/account-notifications-api';

import { useProfilePictureLink } from '@hooks/useImageLink';

import { useTimeAgo } from '@hooks/useTimeAgo';

import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import Text from '@ui-kit/typography/text';
import Span from '@ui-kit/typography/span';
import Avatar from '@ui-kit/avatar';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import SolidLogo from '@assets/logos/logo-symbol-solid.svg';

import {
  EventWrapper,
  UnreadDot,
  EventAvatarWrapper,
  SystemAvatar,
  EventCommentWrapper,
  StyledEventListWrapper,
  WorkspaceIndicator,
} from './EventListStyles';

function EventList({
  events,
  notifications,
  limit,
  listTitle,
  showUnreadIndicator,
  showAvatars,
  showWorkspace,
  currentPage,
  ...restProps
}) {
  const [eventsList, setEventsList] = useState([]);

  const handleEventClick = (link) => {
    if ((notifications || showWorkspace) && link) {
      if (notifications) {
        patchAccountNotificationsRead();
      }
      route(link);
      AppState.navigation.backActionPath.value = currentPage;
    }
  };

  useEffect(() => {
    let eventData = events;
    if (limit != null) {
      eventData = events.slice(0, limit);
    }
    setEventsList(eventData);
  }, [events]);

  return (
    <Fragment>
      {listTitle && <Text fontWeight="600" fontSize="1.25em">{listTitle}</Text>}
      <StyledEventListWrapper id="event-list" {...restProps}>
        {eventsList.map((event) => (
          <EventWrapper clickable={(notifications || showWorkspace) && event.link} onClick={() => handleEventClick(event.link)}>
            <Box display="flex">
              <EventAvatarWrapper>
                {(!event.read && showUnreadIndicator) && <UnreadDot />}
                {event.userId && event.imgExists && (
                  <Avatar
                    size="2em"
                    imgURL={useProfilePictureLink(event.userId, true)}
                  />
                )}
                {event.userId && !event.imgExists && (
                  <Avatar
                    borderColor="var(--empty-avatar-bg)"
                    size="2em"
                    imgURL={null}
                  />
                )}
                {!event.userId && event.orgLogoUrl && (
                  <Avatar
                    borderColor="var(--empty-avatar-bg)"
                    size="2em"
                    imgURL={event.orgLogoUrl}
                  />
                )}
                {!event.userId && !event.orgLogoUrl && (
                  <SystemAvatar><Icon size="1.125em" pt="4px" color="var(--text-soft-mute)"><SolidLogo /></Icon></SystemAvatar>
                )}
              </EventAvatarWrapper>

              <Box>
                {event.user && <Span fontSize="0.813rem" fontWeight="600" mr="0.5em">{event.user}</Span>}
                <Span fontSize="0.813rem" lineHeight="1.5">{event.description}</Span>
                {showWorkspace && (
                  <Box display="flex" mt="0.25em" alignItems="center">
                    <WorkspaceIndicator />
                    <Text fontSize="0.75rem" fontWeight="400" lineHeight="1.5" opacity="0.9">
                      {event.workspace.name.slice(0, 32)}
                      {event.workspace.name.length > 32 && '...'}
                    </Text>
                  </Box>
                )}
                {event.comment
                && (
                <EventCommentWrapper>
                  &quot;
                  {event.comment}
                  &quot;
                </EventCommentWrapper>
                )}
                <Text fontSize="0.75rem" color="var(--text-medium-mute)">
                  {useTimeAgo(event.createdAt)}
                </Text>
              </Box>
            </Box>
          </EventWrapper>
        ))}
        {eventsList.length === 0 && (
        <BaseEmptyState
          animate={false}
          title="Nothing to See Here"
          message="Your personal notifications will appear here"
          illustration="activities"
          size={limit ? 'small' : 'default'}
        />
        )}
      </StyledEventListWrapper>
    </Fragment>
  );
}

EventList.propTypes = {
  events: PropTypes.array.isRequired,
  notifications: PropTypes.bool,
  listTitle: PropTypes.string,
  limit: PropTypes.number,
  showUnreadIndicator: PropTypes.bool,
  showAvatars: PropTypes.bool,
  showWorkspace: PropTypes.bool,
  currentPage: PropTypes.string,
};

EventList.defaultProps = {
  notifications: false,
  listTitle: null,
  limit: null,
  showUnreadIndicator: false,
  showAvatars: false,
  showWorkspace: false,
  currentPage: null,
};

export default EventList;
