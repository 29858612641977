import PropTypes from 'prop-types';

import Spinner from '@ui-kit/loaders/Spinner';
import Box from '@ui-kit/box';
import { StyledButtonSegment, StyledSubText } from './ButtonSegmentStyles';

function ButtonSegment({
  isLoading,
  isDisabled,
  onClick,
  fluid,
  btnText,
  subText,
  width,
  maxHeight,
  active,
  ...restProps
}) {
  return (
    <StyledButtonSegment
      type="button"
      isLoading={isLoading}
      fluid={fluid}
      width={width}
      maxHeight={maxHeight}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      active={active}
      {...restProps}
    >
      {isLoading
        ? <Spinner mono size="1.125em" width="2px" />
        : (
          <Box>
            <div>{ btnText }</div>
            {subText && <StyledSubText active={active}>{subText}</StyledSubText>}
          </Box>
        )}
    </StyledButtonSegment>
  );
}

ButtonSegment.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  fluid: PropTypes.bool,
  onClick: PropTypes.func,
  btnText: PropTypes.string,
  subText: PropTypes.string,
  width: PropTypes.string,
  maxHeight: PropTypes.string,
  active: PropTypes.bool,
};

ButtonSegment.defaultProps = {
  isLoading: null,
  isDisabled: null,
  fluid: null,
  btnText: null,
  onClick: null,
  width: 'auto',
  maxHeight: null,
  active: false,
  subText: null,
};

export default ButtonSegment;
