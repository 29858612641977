/* eslint-disable react/forbid-prop-types */
import { useState, useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';
import { Fragment } from 'preact';

import useIsoDateFormat from '@hooks/useIsoDateFormat';
import useRoundNumber from '@hooks/useRoundNumber';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Spinner from '@ui-kit/loaders/Spinner';
import Card from '@distinct-components/cards/baseCard';
import DotMenu from '@distinct-components/menus/dotMenu';
import Icon from '@ui-kit/icon';
import EmptyIcon from '@assets/icons/science.svg';

import {
  StatsCardGrid,
  StatsCardHeader,
  StatsCardBody,
  TagBarWrapper,
  CommitmentContainer,
  CommitmentProgress,
} from './ProfileOverlayStatsRowStyles';

function ProfileOverlayStatsRow({ numCompositions, credentials, tags }) {
  const [isLoadingStats, setIsLoadingStats] = useState(true);
  const [allCommitments, setAllCommitments] = useState([]);
  const [commitmentVisual, setCommitmentVisual] = useState();
  const [topTags, setTopTags] = useState([]);

  const d = useIsoDateFormat;

  useEffect(() => {
    const credentialsWithCommitment = credentials.filter((c) => c.commitment);
    setAllCommitments(credentialsWithCommitment);
    setCommitmentVisual(credentialsWithCommitment[0]);

    setTopTags(tags.sort((a, b) => b.count - a.count).slice(0, 4).map((t) => ({
      tag: t.tag,
      progress: useRoundNumber((t.count * 100) / numCompositions, 0),
    })));

    setTimeout(() => {
      setIsLoadingStats(false);
    }, 800);
  }, [credentials, tags]);

  const constructMenuLabel = (commitment) => {
    if (!commitment.terms?.fromDate) {
      return `Select Works - Accepted on ${d(commitment.acceptedDate)}`;
    } if (!commitment.terms?.toDate) {
      return `Open Term: Beginning ${d(commitment.terms.fromDate)}`;
    }
    return `Term: ${d(commitment.terms.fromDate)} - ${d(commitment.terms.toDate)}`;
  };

  return (
    <Box>
      {isLoadingStats ? (
        <Box>
          <Box width="100%" display="flex" justifyContent="center" alignItems="center" minHeight="260px">
            <Spinner size="1.25em" variant="page" />
          </Box>
        </Box>
      ) : (
        <StatsCardGrid>

          <Card variant="panel" p="0" maxHeight="196px">
            <StatsCardHeader>
              <Text fontWeight="600">Top Tags</Text>
              <Text fontSize="0.75rem">{`${numCompositions.toLocaleString()} Composition${numCompositions > 1 ? 's' : ''}`}</Text>
            </StatsCardHeader>
            <StatsCardBody>
              {tags.length > 0 && topTags.map((t) => (
                <TagBarWrapper width={t.progress}>
                  <Text style={{ textTransform: 'capitalize' }} color="var(--chart-line-label)">{t.tag.name}</Text>
                  <Text pl="0.5em" color="var(--chart-line-label)">{`${t.progress}%`}</Text>
                </TagBarWrapper>
              ))}

              {tags.length === 0 && (
                <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" minHeight="9rem">
                  <Icon size="2em" pt="3px" color="var(--text-muted)" mb="0.25em"><EmptyIcon /></Icon>
                  <Text color="var(--text-muted)" fontSize="0.75em">No Tagged Compositions</Text>
                </Box>
              )}

            </StatsCardBody>
          </Card>

          <Card variant="panel" p="0" maxHeight="196px" className="overflow-visible">
            <StatsCardHeader>
              <Text fontWeight="600">Commitment</Text>
              {allCommitments.length > 0 && (
                <DotMenu
                  menuWidth="14em"
                  menuTransform="translate(-205px, 0px)"
                  menuItems={allCommitments.map((c) => ({
                    label: constructMenuLabel(c),
                    method: () => setCommitmentVisual(c),
                  }))}
                />
              )}
            </StatsCardHeader>
            <StatsCardBody>
              {commitmentVisual ? (
                <Fragment>
                  <Box display="flex" justifyContent="space-between">
                    <Text mb="0.5em" fontSize="0.75rem">
                      {constructMenuLabel(commitmentVisual)}
                    </Text>
                    <Text mb="0.5em" fontSize="0.75rem">
                      {commitmentVisual.commitment.delivered.external + commitmentVisual.commitment.delivered.internal}
                      {' '}
                      of
                      {' '}
                      {commitmentVisual.commitment.totalWorks}
                      {' '}
                      Delivered
                    </Text>
                  </Box>
                  <CommitmentContainer>
                    <Text fontWeight="400" fontSize="1.5rem">
                      {commitmentVisual.commitment.progress}
                      %
                    </Text>
                    <CommitmentProgress width={commitmentVisual.commitment.progress} />
                  </CommitmentContainer>
                </Fragment>
              ) : (
                <Fragment>
                  <Text color="var(--text-medium-mute)" mb="0.5em" fontSize="0.75rem">No Active Commitment Tracking</Text>
                  <CommitmentContainer>
                    <Text fontWeight="400" color="var(--text-medium-mute)" fontSize="0.875rem">Add or Edit Writer Credential to Track</Text>
                  </CommitmentContainer>
                </Fragment>
              )}
            </StatsCardBody>
          </Card>

        </StatsCardGrid>
      )}
    </Box>
  );
}

ProfileOverlayStatsRow.propTypes = {
  numCompositions: PropTypes.number.isRequired,
  credentials: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
};

export default ProfileOverlayStatsRow;
