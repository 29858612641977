import PropTypes from 'prop-types';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import ManageSocietyOverlayCreate from './sections/manageSocietyOverlayCreate';

function ManageSocietyOverlay({ closeFunction }) {
  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>
      <ManageSocietyOverlayCreate closeFunction={closeFunction} />
    </BaseEditOverlay>
  );
}

ManageSocietyOverlay.propTypes = {
  closeFunction: PropTypes.func.isRequired,
};

export default ManageSocietyOverlay;
