/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';

import { SwitchWrapper } from './ToggleSwitchStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function ToggleSwitch({
  id,
  checked,
  children,
  large,
  loading,
  disabledCursor,
  ...restProps
}) {
  return (
    <SwitchWrapper large={large} {...restProps} disabledCursor={disabledCursor}>
      {children}
      <label className={loading && 'loading'} htmlFor={id} />
    </SwitchWrapper>
  );
}

ToggleSwitch.propTypes = {
  children: childrenPropTypes.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  large: PropTypes.bool,
  loading: PropTypes.bool,
  disabledCursor: PropTypes.string,
};

ToggleSwitch.defaultProps = {
  checked: false,
  large: false,
  loading: false,
  disabledCursor: 'not-allowed',
};

export default ToggleSwitch;
