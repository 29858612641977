import { useState, useEffect } from 'preact/hooks';
import AppState from '@state';
import { Fragment } from 'preact';
import { route } from 'preact-router';

import { getRepArtists, patchRepArtistsRevoked } from '@api/restricted/rep-artists-api';
import loadAccountProfile from '@actions/loadAccountProfile';

import CoreRepLayout from '@layouts/coreRep/CoreRepLayout';
import PageCard from '@layouts/pageCard';

import Box from '@ui-kit/box';
import LinkButton from '@ui-kit/buttons/linkButton';
import Tabs from '@ui-kit/tabs/Tabs';
import SearchInput from '@ui-kit/inputs/searchInput';
import RepArtistCard from '@distinct-components/cards/repArtistCard';
import Text from '@ui-kit/typography/text';

import DeleteConfirmationOverlay from '@shared-overlays/deleteConfirmationOverlay';
import InformationModal from '@layouts/full-screen/informationModal/InformationModal';

import RepTransitionOverlay from './sections/repTransitionOverlay/RepTransitionOverlay';

import {
  WelcomeWrapper,
  StyledWelcomeHeader,
  StyledWelcomeMessage,
  HomeCardContent,
  HelpPrompt,
  WriterCardWrapper,
  StyledEmptyMessage,
  SearchWrapper,
  ControlsRow,
} from './RepDashboardStyles';

function RepDashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [isRemoving, setIsRemoving] = useState(false);
  const [error, setError] = useState(null);
  const [repTab, setRepTab] = useState('Connections');
  const [searchValue, setSearchValue] = useState('');
  const [activeArtists, setActiveArtists] = useState([]);
  const [inactiveArtists, setInactiveArtists] = useState([]);
  const [pendingArtists, setPendingArtists] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [showAddArtistInfo, setShowAddArtistInfo] = useState(false);
  const [showAwaitingActivationInfo, setShowAwaitingActivationInfo] = useState(false);
  const [showRepTransition, setShowRepTransition] = useState(false);
  const [showDisabledModal, setShowDisabledModal] = useState(false);

  const activeArtistOptions = [
    { label: 'Remove Artist', action: 'remove' },
    { label: 'Manage Artist', action: 'manage' },
  ];

  const loadRepArtists = async () => {
    try {
      const response = await getRepArtists();
      if (response.status !== 200) {
        const json = await response.json();
        AppState.exceptions.errorPageError.value = `${json.error || response.statusText} when loading artists`;
        route('/error/exception', true);
      } else {
        const json = await response.json();

        const maxArtists = AppState.repProfile.subscription.value?.maxArtists || 2;
        const artistList = json.artists
          .filter((a) => a.isActive)
          .sort((a, b) => a.validFrom - b.validFrom)
          .map((artist, index) => ({
            ...artist,
            disabled: index >= maxArtists,
          }));

        setActiveArtists(artistList);
        setInactiveArtists(json.artists.filter((a) => !a.isActive));
        setPendingArtists(json.invites);
      }
    } catch (err) {
      AppState.exceptions.errorPageError.value = `${err.message} when loading artists`;
      route('/error/exception', true);
    }
  };

  useEffect(() => {
    loadAccountProfile().then(() => {
      if (!AppState.isRepresentative.value) {
        route('/landing');
      } else {
        document.title = 'Representation - Dashboard';
        loadRepArtists().then(() => {
          setIsLoading(false);
        });
      }
    });
  }, []);

  const handleTabChange = (tab) => {
    setRepTab(tab.value);
    setSearchValue('');
  };

  const onClickArtistCard = (artist) => {
    if (artist.disabled) {
      setShowDisabledModal(true);
    } else if (artist.isActive) {
      setSelectedArtist(artist);
      setShowRepTransition(true);
    } else if (artist.isPending) {
      if (artist.repAccepted) {
        setShowAwaitingActivationInfo(true);
      } else {
        route(`/rep/invitation?email=${encodeURIComponent(AppState.userProfile.email.value)}&token=${artist.inviteToken}`);
      }
    }
  };

  const onClickArtistOption = (option, artist) => {
    setSelectedArtist(artist);

    if (option === 'remove') {
      setShowDeleteConfirmation(true);
    } else if (option === 'manage') {
      onClickArtistCard(artist);
    }
  };

  const handleRemoveArtist = () => {
    setIsRemoving(true);
    setError('');
    setTimeout(() => {
      patchRepArtistsRevoked(selectedArtist.accountId)
        .then((response) => {
          if (response.status === 200) {
            loadRepArtists().then(() => {
              setIsRemoving(false);
              setShowDeleteConfirmation(false);
              setSelectedArtist(null);
              AppState.messages.toastMessage.value = { text: 'Artist Removed Successfully', variant: 'success' };
            });
          } else {
            response.json()
              .then((json) => setError(json.error || response.statusText))
              .catch(() => setError(response.statusText));
            setIsRemoving(false);
          }
        })
        .catch((err) => {
          setIsRemoving(false);
          setError(err.message);
        });
    }, 800);
  };

  return (
    <CoreRepLayout>
      <PageCard
        title=""
        showHeader={false}
        isLoadingPage={isLoading}
      >
        <WelcomeWrapper>
          <Box>
            <StyledWelcomeHeader>
              Welcome
              {' '}
              {AppState.userProfile.firstName.value}
            </StyledWelcomeHeader>
            <StyledWelcomeMessage color="var(--text-soft-mute)">
              Select a writer to start a secure session.
            </StyledWelcomeMessage>
          </Box>

          <HelpPrompt>
            <LinkButton btnText="How do I add an artist?" variant="small" color="var(--text-medium-mute)" onClick={() => setShowAddArtistInfo(true)} />
          </HelpPrompt>
        </WelcomeWrapper>

        <HomeCardContent id="content">

          <ControlsRow>
            <Tabs
              tabs={[{ name: 'Connections', value: 'Connections' }, { name: 'Inactive', value: 'Inactive' }]}
              handleTabChange={handleTabChange}
              selectedTab={repTab}
            />

            <SearchWrapper>
              <SearchInput
                id="searchField"
                name="searchField"
                placeholder="Writer Name..."
                value={searchValue}
                type="text"
                autoComplete="off"
                fluid
                mb="1.5em"
                onChange={(e) => setSearchValue((e.target.value))}
              />
            </SearchWrapper>
          </ControlsRow>

          {repTab === 'Connections' && (
            <WriterCardWrapper>
              {pendingArtists.length > 0 && (
                <Fragment>
                  {pendingArtists.filter((a) => (a.firstName.concat(` ${a.lastName}`)).includes(searchValue))
                    .map((artist) => (
                      <RepArtistCard handleSelectedArtist={onClickArtistCard} artist={artist} mb="1em" p="1em" />
                    ))}
                </Fragment>
              )}

              {activeArtists.length > 0 && (
                <Fragment>
                  {activeArtists.filter((a) => (a.firstName.concat(` ${a.lastName}`)).includes(searchValue))
                    .map((artist) => (
                      <RepArtistCard
                        menuOptions={activeArtistOptions}
                        handleSelectedOption={onClickArtistOption}
                        handleSelectedArtist={onClickArtistCard}
                        artist={artist}
                        mb="1em"
                        p="1em"
                      />
                    ))}
                </Fragment>
              )}

              {(searchValue.length > 0 && activeArtists.filter((a) => (a.firstName.concat(` ${a.lastName}`)).includes(searchValue)).length === 0
              && pendingArtists.filter((a) => (a.firstName.concat(` ${a.lastName}`)).includes(searchValue)).length === 0) && (
              <StyledEmptyMessage>Nothing Matches Your Search</StyledEmptyMessage>
              )}

              {(searchValue.length === 0 && pendingArtists.length === 0 && activeArtists.length === 0)
               && <StyledEmptyMessage>No Artist Connections to Display</StyledEmptyMessage>}
            </WriterCardWrapper>
          )}
          {repTab === 'Inactive' && (
            <WriterCardWrapper>
              {inactiveArtists.length > 0 ? (
                <Fragment>
                  {inactiveArtists.filter((a) => (a.firstName.concat(` ${a.lastName}`)).includes(searchValue))
                    .map((artist) => (
                      <RepArtistCard artist={artist} mb="1em" p="1em" />
                    ))}

                  {inactiveArtists.filter((a) => (a.firstName.concat(` ${a.lastName}`)).includes(searchValue)).length === 0 && (
                  <StyledEmptyMessage>Nothing Matches Your Search</StyledEmptyMessage>
                  )}
                </Fragment>
              ) : (
                <StyledEmptyMessage>No Inactive Artist Connections to Show</StyledEmptyMessage>
              )}
            </WriterCardWrapper>
          )}

        </HomeCardContent>
      </PageCard>

      {showDeleteConfirmation
        && (
        <DeleteConfirmationOverlay
          headerLabel="Are you sure?"
          header={`Cancel connection with artist: ${selectedArtist.firstName} ${selectedArtist.lastName}`}
          explanation="You will lose access to manage this artist account. The artist will have to send a new invitation to restore the connection."
          closeFunction={() => setShowDeleteConfirmation(false)}
          handleDelete={handleRemoveArtist}
          error={error}
          isLoading={isRemoving}
          confirmationPhrase="Remove Artist"
          buttonText="Remove Artist"
        />
        )}

      {showAddArtistInfo
      && (
      <InformationModal
        title="Adding Additional Artists"
        closeFunction={() => setShowAddArtistInfo(false)}
      >
        <Text mb="1.5em">Artists can sign up for Switchchord and invite you to manage their account in their Representation panel.</Text>
        <Text mb="1.5em">As we continue to expand our Representation offerings, you will be able to invite the artist you manage from this tool.</Text>
      </InformationModal>
      )}

      {showAwaitingActivationInfo
      && (
      <InformationModal
        title="Awaiting Activation"
        closeFunction={() => setShowAwaitingActivationInfo(false)}
      >
        <Text mb="1.5em">You have accepted the invite but your artist must review your identity information before activating the connection.</Text>
        <Text mb="1.5em">This is a security measure. You will receive an email when the artist completes the activation.</Text>
      </InformationModal>
      )}

      {showRepTransition
      && <RepTransitionOverlay artist={selectedArtist} />}

      {showDisabledModal && (
      <InformationModal
        title="Artist Disabled"
        closeFunction={() => setShowDisabledModal(false)}
      >
        <Text mb="1.5em">Your subscription has been downgraded or canceled. This connection has been disabled.</Text>
        <Text mb="1.5em">Upgrade or restore your subscription to continue managing this artist. If you need further assistance, contact support@switchchord.com</Text>
      </InformationModal>
      )}

    </CoreRepLayout>
  );
}

export default RepDashboard;
