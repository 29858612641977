/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import Span from '@ui-kit/typography/span';

export const StyledOwnerShipWrapper = styled.div`
  display: block;

  @media screen and (max-width: ${pointBreaks.lg}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${pointBreaks.sm}) {
    grid-template-columns: repeat(1, 1fr);
  }

`;

export const StylePercentageText = styled(Span)(({
  total,
}) => `
  ${total === 100 && 'color: var(--green-300)'};
  ${total > 100 && 'color: var(--orange-300)'};
  font-weight: 700;
`);

export const EditUserWrapper = styled.div`
      width: 100%;
      display: flex;
      background: transparent;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      justify-content: center;
      align-items: flex-start;
      overflow-y: scroll;
      height: 100%;
      padding-top: 7em;
      backdrop-filter: blur(10px);
      z-index: 9000;

      @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 1em;
        background: var(--background);
      }

      @media screen and (max-height: ${pointBreaks.sm}) {
        padding: 1em;
      }
`;

export const UserContainer = styled.div`
    max-width: 23em;
    width: 100%;
    background: var(--panel-card);
    border: 1px solid var(--border);
    padding: 1.5em;
    border-radius: 0.5em;
    height: fit-content;
    position: relative;

    flex-direction: flex-start;

    @media screen and (max-width: ${pointBreaks.sm}) {
        max-width: 100%;
      }
`;
