import { workspaceAttachmentsUrl } from '../urls';
import refresh from '../refresh';

export async function postWorkspaceAttachment(workspaceId, path, contentType, size, file) {
  const formData = new FormData();

  formData.append('workspace_id', workspaceId);
  formData.append('path', path);
  formData.append('content_type', contentType);
  formData.append('size', size);
  formData.append('attachment', file);

  return refresh(async () => {
    const response = await fetch(workspaceAttachmentsUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: formData,
    });
    return response;
  });
}

export async function putWorkspaceAttachmentNote(workspaceId, attachmentId, note) {
  const data = {
    workspace: workspaceId,
    attachment: attachmentId,
    note,
  };
  return refresh(async () => {
    const response = await fetch(workspaceAttachmentsUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteWorkspaceAttachment(workspaceId, attachmentId) {
  const data = {
    workspace: workspaceId,
    attachment: attachmentId,
  };
  return refresh(async () => {
    const response = await fetch(workspaceAttachmentsUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function getFileAttachmentDownload(workspaceId, attachmentId) {
  return refresh(async () => {
    const response = await fetch(`${workspaceAttachmentsUrl}?workspace=${workspaceId}&attachment=${attachmentId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}
