import PropTypes from 'prop-types';

import Box from '@ui-kit/box/';
import CheckMark from '@assets/icons/check-small.svg';
import { FeedbackLabel, CheckWrapper } from './PasswordMatchesRowStyles';

function PasswordMatchesRow({
  passwordMatches,
  ...restProps
}) {
  return (
    <Box mt="0.75rem" display="flex" width="100%" {...restProps}>
      <Box display="flex" mr="0.75em">
        <FeedbackLabel isValid={passwordMatches} mr="0.25em">Password Matches</FeedbackLabel>
        <CheckWrapper isValid={passwordMatches}><CheckMark /></CheckWrapper>
      </Box>
    </Box>
  );
}

PasswordMatchesRow.propTypes = {
  passwordMatches: PropTypes.bool.isRequired,
};

export default PasswordMatchesRow;
