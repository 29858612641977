import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'preact/hooks';
import AppState from '@state';
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';

import loadAccountProfile from '@actions/loadAccountProfile';
import putAccountArtistProfile from '@api/restricted/account-artist-profile-api';

import { ACCOUNT_EXPLANATIONS } from '@constants/supportingCopy';

const maskGenerator = createDefaultMaskGenerator('9999 9999 9999 9999');

function EditISNI({
  closeFunction,
}) {
  const {
    register, setValue, trigger, handleSubmit, formState: { isValid },
  } = useForm({ mode: 'onChange' });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isni, setIsni] = useState('');

  useEffect(() => {
    register('isni', { required: true, minLength: 19, maxLength: 19 });
    setValue('isni', AppState.artistProfile.isni.value);
    setIsni(AppState.artistProfile.isni.value);
  }, []);

  const handleInputChange = (data) => {
    setValue('isni', data);
    trigger('isni');
  };

  const handleSaveArtistISNI = (data) => {
    setIsLoading(true);
    setError(null);
    const trimmed = data.isni.replace(/\s/g, '');
    const sections = trimmed.match(/.{1,4}/g);
    const formatted = sections.join(' ');
    putAccountArtistProfile(
      AppState.artistProfile.ipi.value,
      AppState.artistProfile.ipn.value,
      formatted,
    ).then((response) => {
      setIsLoading(false);
      if (response.status === 200) {
        response.json().then(() => {
          loadAccountProfile();
          setError('');
          closeFunction();
        });
      } else {
        response.json().then((json) => {
          if (json.error) {
            setError(json.error);
          } else {
            setError(response.statusText);
          }
        });
      }
    }).catch((err) => {
      setIsLoading(false);
      setError(err.message);
    });
  };

  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>
      <ConversationPrompt
        primaryMessage="International Standard Name Identifier"
        promptLabel="Artist ISNI"
        mb="1em"
      />
      <ConversationExplanation mb="2em" truncateAtCharacter={140} explanation={ACCOUNT_EXPLANATIONS.isni.explanation} />
      <form onSubmit={handleSubmit(handleSaveArtistISNI)} autoComplete="off">
        <InputLabel label="Your ISNI" />
        <MaskedInput
          maskGenerator={maskGenerator}
          className="masked-input"
          value={isni}
          onChange={setIsni}
          onKeyUp={(e) => handleInputChange(e.target.value)}
          id="isni"
          name="isni"
          placeholder="Enter 16 Digit ISNI"
          maxLength="19"
          minLength="19"
          type="text"
          inputMode="decimal"
        />
        {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}
        <BaseButton mb="1.5em" type="submit" btnText="Save" disabled={!isValid} fluid isLoading={isLoading} />
      </form>
    </BaseEditOverlay>
  );
}

EditISNI.propTypes = {
  closeFunction: PropTypes.func.isRequired,
};

export default EditISNI;
