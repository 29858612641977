import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';

export const ControlsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1em;
    align-items: center;

    & input:focus {
        border-color: var(--purple-300);
    }
    & select:focus {
        border-color: var(--purple-300);
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
        width: 100%;
        flex-wrap: wrap;
        gap: 0.5em;flex-wrap: wrap;
        gap: 0.75em;

        & select {
            width: 100%;
            height: 2.25em;
        }
    }
`;

export const SortWrapper = styled.div`
    display: flex;

    @media screen and (max-width: ${pointBreaks.sm}) {
        width: 100%;
    }
`;

export const StyledSortButton = styled.div`
    background: var(--input-toggle-button-bg);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    border: 1px solid var(--input-border);
    border-right: none;
`;

export const StyledSortSelect = styled(BaseSelect)`
    height: 2rem;
    padding: 0.5em 1.125em;
    min-width: fit-content;
    width: auto;
    padding-right: 2rem;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border-top-left-radius: 0em;
    border-bottom-left-radius: 0em;
    font-size: 0.75rem;
`;

export const FeaturedToggleWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 2rem;

    @media screen and (max-width: ${pointBreaks.sm}) {
        width: 100%;
    }
`;

export const ToggleOption = styled.div(({
  isSelected,
}) => `
    border: 1px solid var(--border);
    display: flex;
    background: ${isSelected ? 'var(--active-nav)' : 'var(--input-bg)'};
    font-weight: ${isSelected ? '600' : '400'};
    align-items: center;
    justify-content: center;
    min-width: 4em;
    height: 2rem;
    padding: 0em 1em;
    color: ${isSelected ? 'var(--white)' : 'var(--text-soft-mute)'};
    font-size: 0.75rem;
    cursor: pointer;

    &:first-child {
        border-right: none;
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
    }
    &:last-child {
        border-left: none;
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
        width: 100%;
    }
`);

export const TableFilterButton = styled.div`
    position: relative;
    background: var(--input-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 2rem;
    border: 1px solid var(--border);
    height: 2rem;
    border-radius: 0.5em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        width: 100%;
    }
`;

export const TableFilterIndicator = styled.div`
    height: 18px;
    width: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: var(--orange-300);
    color: var(--white);
    top: -6px;
    right: -8px;
    font-size: 0.813rem;
    font-weight: 800;
`;

export const TableSearchWrapper = styled.div`
    width: 12em;
    position: relative;

    @media screen and (max-width: ${pointBreaks.sm}) {
        width: 100%;
    }
`;

export const ClearSearchChip = styled.div`
    position: absolute;
    height: 24px;
    width: 24px;
    background: var(--active-nav);
    border-radius: 50px;
    top: 4px;
    right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: ${pointBreaks.sm}) {
        height: 28px;
        width: 28px;
     }
`;
