import styled from 'styled-components';
import { space, layout, variant } from 'styled-system';

export const CardWrapper = styled.div(
  ({ warning }) => `
    border-radius: 0.5em;
    width: 100%;
    border: 1px solid var(--panel-info-border);
    padding: 0.5em 1em;
    cursor: pointer;
    ${space};
    ${layout};
    ${warning && 'transform: translateY(-8px)'};
`,
  space,
  layout,
  variant({
    variants: {
      initialize: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      critical: {
        borderColor: 'var(--alert-negative-bg)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      warning: {
        borderColor: 'var(--orange-300)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      centered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      base: {
        padding: '0',
      },
      noCursor: {
        cursor: 'default',
      },
      presetCard: {
        cursor: 'default',
        background: 'var(--panel-card)',
      },
    },
  }),
);

export const ContentWrapper = styled.div`
    display: block;
`;

export const IssueDotRow = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;

  .toolTipText {
    visibility: hidden;
    background-color: var(--orange-200);
    color: var(--text-soft-mute);
    text-align: left;
    position: absolute;
    z-index: 100;
    width: 120px;
    bottom: 100%;
    margin-left: -112px;
    font-size: 0.6em;
    line-height: 1.6;
    padding: 0.5em 1em;
    border-radius: 0.5em 1em;
    box-shadow: 2px 4px 11px var(--background);
    cursor: pointer;
    color: var(--white);
  }

`;

export const IssueDot = styled.div`
   height: 1em;
   width: 1em;
   background: var(--orange-300);
   border-radius: 50%;
   transform: translate(7px,3px);
   display: flex;
   justify-content: center;
   align-items: center;
   color: var(--orange-100);
   font-weight: 600;
   z-index: 100;
   cursor: pointer;
   position: relative;

   &:hover .toolTipText {
    visibility: visible;
  }
`;
