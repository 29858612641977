import { route } from 'preact-router';
import AppState from '@state';
import { getOrgListsByOrg } from '@api/restricted/org-lists-api';

export default async function loadOrgLists(orgId, { forcedLoad }) {
  if (forcedLoad || AppState.publisherLists.value.length === 0) {
    try {
      const response = await getOrgListsByOrg(orgId);
      if (response.status !== 200) {
        try {
          const json = await response.json();
          AppState.exceptions.errorPageError.value = `${json.error || response.statusText} when loading personal lists`;
          route('/error/exception', true);
        } catch {
          AppState.exceptions.errorPageError.value = `${response.statusText} when loading personal lists`;
          route('/error/exception', true);
        }
      } else {
        const json = await response.json();
        AppState.publisherLists.value = json.lists;
      }
    } catch (err) {
      AppState.exceptions.errorPageError.value = `${err.message} when loading personal lists`;
      route('/error/exception', true);
    }
  }
}
