import styled from 'styled-components';
import {
  space,
} from 'styled-system';
import pointBreaks from '@styles/styleConstants';

const StyledTable = styled.div(({
  tableMinHeight,
  scrollContents,
}) => `
    background:  var(--table-bg);
    min-height: ${tableMinHeight || '22em'};
    border: 1px solid var(--card-divider);
    border-radius: 0.5em;
    overflow: hidden;
    overflow-y: ${scrollContents ? 'scroll' : 'visible'};
    max-height: ${scrollContents ? 'calc(100% - 224px)' : 'none'};;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
      background: none;
      border: none;
      min-height: 0em;
    }

    .row:last-child {
      border-bottom: none;
    }
}
`, space);

export default StyledTable;
