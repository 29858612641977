import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const StyledPercentageInput = styled.input`
    height: 2.75em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0em;
    border: none;
    width: 100%;
    padding-left: 0.75em;
    background: var(--input-bg);
    color: var(--text-primary);
`;

export const StyledPercentBox = styled.div`
    color: var(--white);
    height: 2.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75em;
    font-size: 1em;
    background: var(--input-append-bg);
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
`;

export const StyledAccessContainer = styled.div`
    border: 1px solid var(--card-divider);
    border-radius: 1em;
    ${space}
`;

export const LoggedInWrapper = styled.div`
    position: absolute;
    top: 32px;
    right: 32px;

    @media screen and (max-width: ${pointBreaks.sm}) {
        left: 32px;
        top: 68px;
    }
`;
