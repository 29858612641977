/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import { putAccountPublisher, deleteAccountPublisher } from '@api/restricted/account-publisher-api';
import loadAccountPublishers from '@actions/loadAccountPublishers';

import { SOCIETIES } from '@constants/collectionSocieties';

import Header from '@ui-kit/typography/header';
import AlertMessage from '@ui-kit/alert/Alert';
import Box from '@ui-kit/box';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import AddressTypeAhead from '@distinct-components/typeAheadInputs/addressTypeAhead';
import BaseTypeAhead from '@distinct-components/typeAheadInputs/baseTypeAhead';
import Text from '@ui-kit/typography/text';
import ArrowLeftIcon from '@assets/icons/arrow-left.svg';
import Icon from '@ui-kit/icon';
import CountDown from '@distinct-components/feedback/countDown';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';

const WriterSocieties = SOCIETIES.filter((s) => s.societyType === 'pro');

function ManagePublisherOverlayEdit({
  selectedPublisherId,
  setMode,
  usePlacesState,
}) {
  const {
    register, handleSubmit, setValue, trigger, formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);
  const [typeAheadValue, setTypeAheadValue] = useState(AppState.publishers.value[selectedPublisherId]?.address || '');

  const [numAssociatedPresets, setNumAssociatedPresets] = useState(-1);

  const filterPresets = () => {
    const numPresets = AppState.publisherPresets.value.filter((preset) => preset.owners.some((pub) => pub.id === selectedPublisherId) || preset.admins.some((pub) => pub.id === selectedPublisherId)).length;
    setNumAssociatedPresets(numPresets);
  };

  useEffect(() => {
    filterPresets();
    register('address', { required: true });
    setValue('address', AppState.publishers.value[selectedPublisherId]?.address);
    register('society', { required: true });
    setValue('society', AppState.publishers.value[selectedPublisherId]?.society);
  }, []);

  const handleAddressSelected = (data) => {
    setValue('address', data);
    trigger('address');
    setTypeAheadValue(data);
  };

  const handleSocietySelected = (data) => {
    setValue('society', data);
    trigger('society');
  };

  const handleSavePublisher = (data) => {
    const cleanedData = {
      name: data.name?.trim().split(/\s+/).join(' '),
      society: data.society,
      ipi: data.ipi,
      address: data.address?.trim().split(/\s+/).join(' '),
    };
    if (!cleanedData.name || !cleanedData.address) {
      setError('Invalid input');
    } else {
      setIsLoading(true);
      putAccountPublisher(selectedPublisherId, cleanedData.name, cleanedData.society, cleanedData.ipi, cleanedData.address)
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            response.json().then(() => {
              setError(false);
              loadAccountPublishers().then(() => {
                setMode('list');
              });
            });
          } else {
            response.json()
              .then((json) => setError(json.error ? json.error : response.statusText))
              .catch(() => setError(response.statusText));
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err.message);
        });
    }
  };

  const onClickDeletePublisher = () => {
    if (numAssociatedPresets > 0) {
      setError('This publisher is used in one of your existing relationships');
      return;
    }
    setShowDeleteConfirmation(true);
    setTimeout(() => {
      setShowDeleteConfirmation(false);
    }, 5000);
  };

  const handleDeletePublisher = () => {
    setIsDeleting(true);
    deleteAccountPublisher(selectedPublisherId)
      .then((response) => {
        setIsDeleting(false);
        if (response.status === 200) {
          response.json().then(() => {
            setError(false);
            loadAccountPublishers().then(() => {
              setMode('list');
            });
          });
        } else {
          response.json()
            .then((json) => setError(json.error ? json.error : response.statusText))
            .catch(() => setError(response.statusText));
        }
      })
      .catch((err) => {
        setIsDeleting(false);
        setError(err.message);
      });
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" className="cursor-p" onClick={() => setMode('list')} mb="1em">
        <Icon size="1.25em" cursor><ArrowLeftIcon /></Icon>
        <Text ml="0.5em">Go Back</Text>
      </Box>
      <Header mb="1em">Edit Publisher</Header>

      {numAssociatedPresets > 0
      && (
      <AlertMessage
        variant="default"
        message={`This edit will apply to ${numAssociatedPresets} relationship(s)`}
        mb="1.5em"
      />
      )}

      <form onSubmit={handleSubmit(handleSavePublisher)} autoComplete="off">
        <InputLabel label="Publisher Name" />
        <BaseInput
          {...register('name', { required: true })}
          id="name"
          name="name"
          defaultValue={AppState.publishers.value[selectedPublisherId]?.name}
          type="text"
          fluid
          mb="1.5rem"
        />
        <BaseTypeAhead
          label="Publishers Collection Society"
          id="societies"
          data={WriterSocieties}
          nameValue="name"
          handleChange={handleSocietySelected}
          initialValue={AppState.publishers.value[selectedPublisherId]?.society}
        />
        <InputLabel label="Publisher IPI" />
        <BaseInput
          {...register('ipi', { required: true })}
          id="ipi"
          name="ipi"
          defaultValue={AppState.publishers.value[selectedPublisherId]?.ipi}
          type="text"
          inputMode="decimal"
          pattern="[0-9]+"
          maxLength="11"
          minLength="9"
          fluid
          mb="1.5rem"
        />
        <AddressTypeAhead
          placesState={usePlacesState}
          handleChange={handleAddressSelected}
          address={typeAheadValue}
          label="Publisher Address"
          id="pubAddress"
        />

        {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}

        <BaseButton mb="2.5em" type="submit" btnText="Save" disabled={!isValid} fluid isLoading={isLoading} />
      </form>
      <Box mb="0.5rem" display="flex" alignItems="center" justifyContent="center">
        {showDeleteConfirmation
          ? (
            <Fragment>
              <CountDown mr="0.25em" timeInSeconds={5} size={12} color="var(--red-300)" />
              <LinkButton btnText="Yes, Delete Publisher" color="var(--red-300)" mr="1em" onClick={handleDeletePublisher} />
            </Fragment>
          )
          : <LinkButton type="button" color="var(--red-300)" spinnerVariant="danger" isLoading={isDeleting} btnText="Delete Publisher" onClick={onClickDeletePublisher} />}
      </Box>
    </Box>
  );
}

ManagePublisherOverlayEdit.propTypes = {
  selectedPublisherId: PropTypes.number.isRequired,
  setMode: PropTypes.func.isRequired,
  usePlacesState: PropTypes.string.isRequired,
};

export default ManagePublisherOverlayEdit;
