/* eslint-disable react/forbid-prop-types */
import { useRef } from 'preact/hooks';
import { route } from 'preact-router';
import PropTypes from 'prop-types';

import d from '@hooks/useIsoDateFormat';

import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Avatar from '@ui-kit/avatar';
import Spinner from '@ui-kit/loaders/Spinner';

import {
  StyledRepCard,
  RepCardGrid,
} from './ActiveRepsStyles';

function ActiveReps({
  setSelectedRep,
  isLoading,
  reps,
  invites,
}) {
  const pageRef = useRef(null);
  const repsAndInvites = reps.concat(invites);

  const addNew = () => {
    route('/representation/invite');
  };

  return (
    isLoading ? (
      <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
        <Spinner size="2em" variant="page" />
      </Box>
    ) : (
      <Box>
        <div ref={pageRef} />

        {repsAndInvites.length === 0 ? (
          <Card variant="panel" mt="1.5em">
            <BaseEmptyState
              title="No Representatives"
              message="You can give your manager, lawyer, or other representative access to manage your account."
              action={addNew}
              actionText="Add Representative"
              illustration="personas"
              minHeight="38em"
            />
          </Card>
        ) : (
          <RepCardGrid>
            {invites.map((invite) => (
              <StyledRepCard onClick={() => route(`/representation/activate/${invite.inviteId}`)}>
                <Box display="flex" alignItems="center">
                  <Avatar
                    size="3.5em"
                    imgURL={invite.avatarURL}
                    isVerified={invite.isVerified}
                    checkSize="1em"
                    checkY="4px"
                  />
                  <Box ml="1em">
                    <Text fontWeight="600" fontSize="0.813rem" color="var(--orange-300)">Requires Review</Text>
                    <Text fontSize="1rem" fontWeight="500">
                      {`${invite.firstName} ${invite.lastName}`}
                    </Text>
                  </Box>
                </Box>
                <Box mt="1.5em">
                  <Text mb="0.5rem" fontSize="0.75rem" color="var(--text-secondary)">
                    Accepted On:
                    {' '}
                    {d(invite.repAcceptedAt)}
                  </Text>
                  <Text mb="0.5rem" fontSize="0.75rem" color="var(--text-secondary)">
                    Review their information to activate connection
                  </Text>
                </Box>
              </StyledRepCard>
            ))}

            {reps.map((rep) => (
              <StyledRepCard onClick={() => setSelectedRep(rep)}>
                <Box display="flex" alignItems="center">
                  <Avatar
                    size="3.5em"
                    imgURL={rep.avatarURL}
                    isVerified={rep.isVerified}
                    checkSize="1em"
                    checkY="4px"
                  />
                  <Box ml="1em">
                    <Text fontWeight="600" fontSize="0.813rem" color="var(--green-300)">Active</Text>
                    <Text fontSize="1rem" fontWeight="500" color="var(--text-primary)">
                      {`${rep.firstName} ${rep.lastName}`}
                    </Text>
                  </Box>
                </Box>
                <Box mt="1.5em">
                  <Text mb="0.5rem" fontSize="0.75rem" color="var(--text-secondary)">
                    Activated On:
                    {' '}
                    {d(rep.createdAt)}
                  </Text>
                  <Text mb="0.5rem" fontSize="0.75rem" color="var(--text-secondary)">
                    Access Allowed Until:
                    {' '}
                    {rep.expiredAt ? d(rep.expiredAt) : 'Until Revoked'}
                  </Text>
                </Box>
              </StyledRepCard>
            ))}
          </RepCardGrid>
        )}
      </Box>
    )
  );
}

ActiveReps.propTypes = {
  setSelectedRep: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  reps: PropTypes.array.isRequired,
  invites: PropTypes.array.isRequired,
};

export default ActiveReps;
