/* eslint-disable react/forbid-prop-types */
import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';

import { RadioRow, RadioWrapper } from './RadioListStyles';

function RadioList({
  options,
  defaultValue,
  inputPadding,
  handleChange,
  ...restProps
}) {
  const [selectedRadio, setSelectedRadio] = useState(defaultValue);

  const onRadioToggle = (value) => {
    handleChange(value);
    setSelectedRadio(value);
  };

  return (
    <RadioWrapper {...restProps}>
      {options.map((option) => (
        <RadioRow>
          <Box mr="0.75em" pt={inputPadding}>
            <input
              type="radio"
              value={option.value}
              id={option.value}
              onChange={() => onRadioToggle(option.value)}
              checked={option.value === selectedRadio}
            />
            <div htmlFor={option.value} className="circle" />
          </Box>
          <Box>
            <label htmlFor={option.value}>{option.label}</label>
            <Text mt="1.75em" fontSize="0.75rem">{option.explanation}</Text>
          </Box>
        </RadioRow>
      ))}
    </RadioWrapper>
  );
}

RadioList.propTypes = {
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  inputPadding: PropTypes.string,
  defaultValue: PropTypes.any,
};

RadioList.defaultProps = {
  defaultValue: null,
  inputPadding: null,
};

export default RadioList;
