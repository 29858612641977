import { useMemo } from 'preact/hooks';
import styled from 'styled-components';
import { space } from 'styled-system';

const PopoverMenu = styled.div(({
  transform,
  width,
  padding,
  top,
  right,
}) => {
  const popoverWidth = useMemo(() => (width || '10em'), [width]);

  return `
  background: var(--tool-tip-background);
  z-index: 500;
  position: absolute;
  border: 1px solid  var(--border);
  box-shadow: 2px 4px 11px var(--tool-tip-shadow);
  border-radius: 0.5em;
  padding: ${padding || '1em'};;
  transform: ${transform || 'translate(0px, 0px)'};
  width: ${popoverWidth};
  top: ${top};
  right: ${right};
  `;
}, space);

export default PopoverMenu;
