import PropTypes from 'prop-types';
import { route } from 'preact-router';
import { useState } from 'preact/hooks';
import AppState from '@state';
import resetGlobalState from '@state/reset';

import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import DashboardIcon from '@assets/icons/graph-outline.svg';
import CompositionIcon from '@assets/icons/composition-note.svg';
import FolderIcon from '@assets/icons/folder-open.svg';
import UserCard from '@assets/icons/user-card-outline.svg';
import TeamIcon from '@assets/icons/team-outline.svg';
import LogoutIcon from '@assets/icons/logout.svg';
import SettingsIcon from '@assets/icons/settings-square.svg';
import MachineIcon from '@assets/icons/machine-stack.svg';
import BoxIcon from '@assets/icons/box-focused-outline.svg';
import GlobeIcon from '@assets/icons/globe-line.svg';
import BreakLine from '@ui-kit/dividers/breakLine';
import Logo from '@ui-kit/logo/Logo';

import {
  MenuItem,
  MenuName,
  MenuWrapper,
  OrganizationMenuWrapper,
  BetaChip,
} from '../MenuStyles';

function MusicOwnerMenu({
  handleMenuChange,
}) {
  const [currentRoute, setCurrentRoute] = useState(window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  const handleRouteChange = (path) => {
    route(path);
    const trimmedRoute = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    const fullPath = window.location.pathname;
    setCurrentPath(fullPath);
    setCurrentRoute(trimmedRoute);
    if (trimmedRoute === 'compositions') {
      AppState.publisherCompositionsTable.currentPage.value = 1;
    }
    const tabListenerValue = AppState.navigation.defaultTabListener.value;
    AppState.navigation.defaultTabListener.value = tabListenerValue + 1;
    handleMenuChange();
  };

  const handleSignOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    resetGlobalState();
    route('/login');
  };

  return (
    <MenuWrapper>
      <Box>
        <OrganizationMenuWrapper display="flex" flexDirection="column" alignItems="center">
          <Logo mb="1em" orgImageURL={AppState.pubOrganization?.logoURL.value || ''} />
          <Text fontSize="0.813rem" fontWeight="600">{AppState?.pubOrganization.name.value || '- - -'}</Text>
          <BreakLine mt="1em" color="var(--panel-info-border)" />
        </OrganizationMenuWrapper>
        <MenuItem mt="1.5em" onClick={() => handleRouteChange(`/publisher/${AppState.pubOrganization.uuid.value}/dashboard`)} isActive={currentRoute === 'dashboard'}>
          <Icon cursor><DashboardIcon /></Icon>
          <MenuName>Dashboard</MenuName>
        </MenuItem>
        <MenuItem onClick={() => handleRouteChange(`/publisher/${AppState.pubOrganization.uuid.value}/compositions`)} isActive={currentPath.includes('compositions')}>
          <Icon cursor><CompositionIcon /></Icon>
          <MenuName>Compositions</MenuName>
        </MenuItem>
        <MenuItem onClick={() => handleRouteChange(`/publisher/${AppState.pubOrganization.uuid.value}/lists`)} isActive={currentPath.includes('lists')}>
          <Icon cursor><FolderIcon /></Icon>
          <MenuName>My Lists</MenuName>
        </MenuItem>
        <MenuItem onClick={() => handleRouteChange(`/publisher/${AppState.pubOrganization.uuid.value}/songwriters`)} isActive={currentRoute === 'songwriters'}>
          <Icon cursor><UserCard /></Icon>
          <MenuName>Songwriters</MenuName>
        </MenuItem>
        <MenuItem onClick={() => handleRouteChange(`/publisher/${AppState.pubOrganization.uuid.value}/team`)} isActive={currentRoute === 'team'}>
          <Icon cursor><TeamIcon /></Icon>
          <MenuName>Manage Team</MenuName>
        </MenuItem>
        <MenuItem onClick={() => handleRouteChange(`/publisher/${AppState.pubOrganization.uuid.value}/pro-affiliations`)} isActive={currentRoute === 'pro-affiliations'}>
          <Icon cursor><BoxIcon /></Icon>
          <MenuName>PRO Affiliations</MenuName>
        </MenuItem>
        {AppState.orgConnections.value.length > 0 && (
        <MenuItem onClick={() => handleRouteChange(`/publisher/${AppState.pubOrganization.uuid.value}/connections`)} isActive={currentRoute === 'connections'}>
          <Icon cursor><GlobeIcon /></Icon>
          <MenuName>Pub Connections</MenuName>
        </MenuItem>
        )}
        <MenuItem onClick={() => handleRouteChange(`/publisher/${AppState.pubOrganization.uuid.value}/settings`)} isActive={currentRoute === 'settings'}>
          <Icon cursor><SettingsIcon /></Icon>
          <MenuName>Settings</MenuName>
        </MenuItem>
        <MenuItem onClick={() => handleRouteChange(`/publisher/${AppState.pubOrganization.uuid.value}/exports`)} isActive={currentRoute === 'exports'}>
          <Icon cursor><MachineIcon /></Icon>
          <Box position="relative">
            {currentRoute !== 'exports' && <BetaChip>Beta</BetaChip>}
            <MenuName>Exports</MenuName>
          </Box>
        </MenuItem>
      </Box>
      <Box>
        <MenuItem mb="0em" onClick={handleSignOut}>
          <Icon cursor><LogoutIcon /></Icon>
          <MenuName>Sign Out</MenuName>
        </MenuItem>
      </Box>
    </MenuWrapper>
  );
}

MusicOwnerMenu.propTypes = {
  handleMenuChange: PropTypes.func.isRequired,
};

export default MusicOwnerMenu;
