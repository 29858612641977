import dayjs from 'dayjs';
import AppState from '@state';
import { getOrgConnections } from '@api/restricted/org-connections-api';
import useErrorOverlay from '@hooks/useErrorOverlay';

export default async function loadOrgConnections(orgId, { forcedLoad }) {
  if (forcedLoad || AppState.orgRegistrations.value.length === 0) {
    try {
      const response = await getOrgConnections(orgId);
      if (response.status !== 200) {
        try {
          const json = await response.json();
          useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading publisher connections` });
        } catch {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading publisher connections` });
        }
      } else {
        const json = await response.json();
        AppState.orgConnections.value = json.connections.map((pub) => ({
          ...pub,
          logoUrl: pub.logoURL,
          isActive: !pub.inactiveDate || dayjs(pub.inactiveDate).isAfter(dayjs()),
        }));
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading publisher connections` });
    }
  }
}
