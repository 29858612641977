/* eslint-disable import/prefer-default-export */
import { orgCompositionsTagsUrl } from '../urls';
import refresh from '../refresh';

export async function getOrgCompositionsTags(orgId) {
  return refresh(async () => {
    const response = await fetch(`${orgCompositionsTagsUrl}?${new URLSearchParams({
      org: orgId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}
