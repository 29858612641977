import styled from 'styled-components';

export const AdditionalContractRow = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid var(--border);
   padding: 0.875rem 0rem;

   &:last-child {
    border: none;
   }

`;

export const StyledSignatureButton = styled.button`
    border: 1px solid var(--action-purple);
    color: var(--text-primary);
    font-size: 0.75rem;
    padding: 0.5rem 0.75rem;
    font-weight: 600;
`;
