import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import AppState from '@state/AppState';

import BaseButton from '@ui-kit/buttons/baseButton';
import AlertMessage from '@ui-kit/alert/Alert';
import Span from '@ui-kit/typography/span';
import LockIcon from '@assets/icons/lock-closed.svg';
import PenIcon from '@assets/icons/sign-pen.svg';
import DocIcon from '@assets/illustrations/document-default.svg';
import Text from '@ui-kit/typography/text';
import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import BreakLine from '@ui-kit/dividers/breakLine';
import DragAndDrop from '@distinct-components/files/dragAndDrop';
import LinkButton from '@ui-kit/buttons/linkButton';

import { useState } from 'preact/hooks';
import {
  LoggedInWrapper, StyledAccessContainer,
} from './RepInvitationAcceptStyles';

function RepInvitationAccept({
  handleAcceptInvite,
  error,
  isLoading,
  handleSignOut,
  isFullAccess,
  isSignSplits,
}) {
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [isSigningDeclined, setIsSigningDeclined] = useState(false);
  const [fileError, setFileError] = useState(null);
  const [fileAdded, setFileAdded] = useState(null);

  const handleFileAdded = (files) => {
    setIsProcessingFile(true);
    setFileError(null);

    const file = files[0];
    const re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png|\.docx|\.pdf|\.heic)$/;

    if (!re.exec(file.name.toLowerCase())) {
      setFileError('Invalid File Type');
      setIsProcessingFile(false);
      return;
    }

    if (file.size >= 20000000) {
      setFileError('File must be less than 20 MB');
      setIsProcessingFile(false);
      return;
    }

    setFileAdded(file);
    setIsProcessingFile(false);
  };

  const onClickDeclineSigning = () => {
    setIsSigningDeclined(true);
  };

  const onClickRemoveFile = () => {
    // DECIDE IF WE WANT TO ACTUALLY CLEAR THE FILE IN STORAGE
    setFileAdded(null);
  };

  const onClickRestoreSigning = () => {
    setIsSigningDeclined(false);
  };

  const onSubmit = () => {
    const data = {
      isSigningDeclined,
      proofFile: fileAdded,
    };
    handleAcceptInvite(data);
  };

  return (
    <Fragment>
      <LoggedInWrapper>
        <Span fontSize="0.75rem" mb="0.25em">
          {AppState.userProfile.email.value}
          &nbsp;&nbsp;
        </Span>
        <Span fontSize="0.75rem" mb="0.25em" cursor style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleSignOut()}>Sign Out</Span>
      </LoggedInWrapper>

      <StyledAccessContainer mb="2em" p="1em">
        <Box display="flex" alignItems="center" mb="0.5em">
          <Icon size="1em" mr="0.75em"><LockIcon /></Icon>
          <Text fontWeight="600">Your Access</Text>
        </Box>
        {(isFullAccess) && <Text fontSize="0.75rem" mb="0.25em">The artist is giving you full access to manage their account.</Text>}
        {(!isFullAccess) && <Text fontSize="0.75rem" mb="0.25em">The artist is giving you Read-Only access to their account.</Text>}
      </StyledAccessContainer>

      {isSignSplits && (
      <Box mb="2em">
        <BreakLine mb="1.5em" mt="0.5em" color="var(--border)" />
        <Box display="flex" alignItems="center" mb="0.5em">
          <Icon size="0.75em" mr="0.75em"><PenIcon /></Icon>
          <Text fontWeight="600">Signing Split Sheets</Text>
        </Box>

        {!isSigningDeclined ? (
          <Fragment>
            <Text mb="1em" fontSize="0.75rem">
              The artist has given you the right to sign their Split Sheets. To activate this permission, you need to provide proof that you have Power of Attorney.
            </Text>

            {fileAdded ? (
              <Fragment>
                <Text fontWeight="600" mb="0.25rem">File Added:</Text>
                <Box display="flex" alignItems="center" mb="0.5rem">
                  <Icon size="0.938em" mr="0.5em"><DocIcon /></Icon>
                  <Text color="var(--text-soft-mute)">{fileAdded.name}</Text>
                </Box>
              </Fragment>
            ) : (
              <Fragment>
                <Text mb="0.5em" fontSize="0.813rem">Power of Attorney Documentation</Text>
                <DragAndDrop
                  handleFilesAdded={handleFileAdded}
                  disabled={isProcessingFile}
                  id="file-drop"
                  mb="1em"
                />
              </Fragment>
            )}

            {fileAdded && <LinkButton btnText="Remove File" variant="small" color="var(--text-primary)" onClick={onClickRemoveFile} />}
            {!fileAdded && <LinkButton btnText="I do not have Power of Attorney" variant="small" color="var(--text-primary)" onClick={onClickDeclineSigning} />}
          </Fragment>

        ) : (
          <Fragment>
            <Text mb="1em" fontSize="0.75rem">
              You can still accept this request but will not have permission to sign Split Sheets for this artist.
            </Text>

            <LinkButton btnText="Enable Power of Attorney" variant="small" color="var(--text-primary)" onClick={onClickRestoreSigning} />
          </Fragment>
        )}

      </Box>
      )}

      {(error || fileError) && (
      <AlertMessage
        variant="negative"
        message={error || fileError}
        mb="1.5em"
      />
      )}

      <BaseButton
        mb="1.5em"
        type="submit"
        btnText="Accept Invitation"
        fluid
        isLoading={isLoading}
        disabled={isSignSplits && (!isSigningDeclined && !fileAdded)}
        onClick={onSubmit}
      />
    </Fragment>
  );
}

RepInvitationAccept.propTypes = {
  handleAcceptInvite: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSignOut: PropTypes.func.isRequired,
  isFullAccess: PropTypes.bool.isRequired,
  isSignSplits: PropTypes.bool.isRequired,
};

export default RepInvitationAccept;
