/* eslint-disable object-curly-newline */
import { useState, useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import dayjs from 'dayjs';

import AppState from '@state';

import { getArtistReps } from '@api/restricted/artist-reps-api';
import { patchArtistRepsCredentialsRevoked } from '@api/restricted/artist-reps-credentials-api';

import RepresentativeProfileOverlay from '@shared-overlays/representativeProfileOverlay';
import CoreLayout from '@layouts/core/';
import PageCard from '@layouts/pageCard';

import DeleteConfirmationOverlay from '@shared-overlays/deleteConfirmationOverlay';

import { REP_EXPLANATIONS } from '@constants/supportingCopy';

import ActiveReps from './sections/activeReps';
import PendingReps from './sections/pendingReps';
import ExpiredReps from './sections/expiredReps';

const validModes = {
  active: 'connections',
  pending: 'invites',
  inactive: 'inactive',
};

function Reps() {
  const [repsMode, setRepsMode] = useState('connections');
  const [selectedRep, setSelectedRep] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  const [activeReps, setActiveReps] = useState([]);
  const [inactiveReps, setInactiveReps] = useState([]);
  const [acceptedInvites, setAcceptedInvites] = useState([]);
  const [pendingInvites, setPendingInvites] = useState([]);

  const [credToRevoke, setCredToRevoke] = useState(null);
  const [isRevoking, setIsRevoking] = useState(false);

  const loadReps = async () => {
    try {
      const response = await getArtistReps();
      if (response.status !== 200) {
        const json = await response.json();
        AppState.exceptions.errorPageError.value = `${json.error || response.statusText} when loading representatives`;
        route('/error/exception', true);
      } else {
        const json = await response.json();
        const formattedReps = json.reps.map((rep) => ({
          ...rep,
          avatarURL: rep.imgURL,
          isActive: !rep.expiredAt || dayjs(rep.expiredAt).isAfter(dayjs()),
        }));
        const formattedInvites = json.invites.map((invite) => ({
          ...invite,
          avatarURL: invite.imgURL,
        }));

        setActiveReps(formattedReps.filter((r) => r.isActive));
        setInactiveReps(formattedReps.filter((r) => !r.isActive));
        setAcceptedInvites(formattedInvites.filter((i) => i.repAcceptedAt));
        setPendingInvites(formattedInvites.filter((i) => !i.repAcceptedAt));
      }
    } catch (err) {
      AppState.exceptions.errorPageError.value = `${err.message} when loading representatives`;
      route('/error/exception', true);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('reps');
    if (token && Object.keys(validModes).includes(token)) {
      setRepsMode(validModes[token]);
    } else {
      setRepsMode('connections');
    }
    loadReps().then(() => {
      setIsLoading(false);
    });
  }, [AppState.navigation.defaultTabListener.value]);

  const addNew = () => {
    route('/representation/invite');
  };

  const handleRevokeCredential = () => {
    setIsRevoking(true);
    setError('');

    setTimeout(() => {
      patchArtistRepsCredentialsRevoked(credToRevoke.id)
        .then((response) => {
          if (response.status === 200) {
            setIsRevoking(false);
            setCredToRevoke(null);
            alert('Credential Revoked Successfully!');
            window.location.reload();
          } else {
            response.json()
              .then((json) => setError(json.error || response.statusText))
              .catch(() => setError(response.statusText));
            setIsRevoking(false);
          }
        })
        .catch((err) => {
          setIsRevoking(false);
          setError(err.message);
        });
    }, 800);
  };

  return (
    <CoreLayout>
      <PageCard
        title="Representation"
        primaryAction={{ method: addNew, text: '+ New' }}
        isLoadingPage={isLoading}
        tabs={[
          { method: () => setRepsMode('connections'), text: 'Connections', count: activeReps.length + acceptedInvites.length, isSelected: repsMode === 'connections' },
          { method: () => setRepsMode('invites'), text: 'Invites', count: pendingInvites.length, isSelected: repsMode === 'invites' },
          { method: () => setRepsMode('inactive'), text: 'Inactive', count: inactiveReps.length, isSelected: repsMode === 'inactive' },
        ]}
      >
        {
          {
            connections: <ActiveReps setSelectedRep={setSelectedRep} isLoading={isLoading} reps={activeReps} invites={acceptedInvites} />,
            invites: <PendingReps isLoading={isLoading} invites={pendingInvites} loadReps={loadReps} />,
            inactive: <ExpiredReps setSelectedRep={setSelectedRep} isLoading={isLoading} reps={inactiveReps} />,
          }[repsMode]
        }
      </PageCard>

      {selectedRep && (
      <RepresentativeProfileOverlay
        closeFunction={() => setSelectedRep(null)}
        rep={selectedRep}
        setCredToRevoke={setCredToRevoke}
      />
      )}

      {credToRevoke
      && (
      <DeleteConfirmationOverlay
        headerLabel="Are you sure?"
        header="Revoke Verifiable Credential"
        explanation={REP_EXPLANATIONS.revokeCredentialConsequence}
        closeFunction={() => setCredToRevoke(null)}
        handleDelete={handleRevokeCredential}
        error={error}
        isLoading={isRevoking}
        confirmationPhrase="Revoke Credential"
        buttonText="Confirm and Revoke"
      />
      )}
    </CoreLayout>
  );
}

export default Reps;
