/* eslint-disable object-curly-newline */
import { useEffect, useState, useRef } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import CoreAdminLayout from '@layouts/coreAdmin/CoreAdminLayout';
import PageCard from '@layouts/pageCard/PageCard';

import useWindowDimensions from '@hooks/useWindowDimensions';
import useIsoDateFormat from '@hooks/useIsoDateFormat';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';
import BreakLine from '@ui-kit/dividers/breakLine';
import LinkButton from '@ui-kit/buttons/linkButton';

import DeleteConfirmationOverlay from '@shared-overlays/deleteConfirmationOverlay';

const MOCK_INVITES = [
  { id: 1, email: 'steve@steve.com', organization: 'Concord', createdDate: '2023-03-15T01:41:28+0000' },
  { id: 1, email: 'brick@stetson.com', organization: 'Concord', createdDate: '2023-03-15T01:41:28+0000' },
];

const MOCK_USERS = [
  { id: 1, firstName: 'Jenny', lastName: 'Patterson', organization: 'Concord', createdDate: '2023-03-15T01:41:28+0000' },
  { id: 1, firstName: 'Jenny', lastName: 'Patterson', organization: 'Concord', createdDate: '2023-03-15T01:41:28+0000' },
];

function AdminOrganizationsUsers() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [orgUsers, setOrgUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showCancelInvite, setShowCancelInvite] = useState(false);
  const [selectedInvite, setSelectedInvite] = useState();

  const pageRef = useRef(null);
  const { height } = useWindowDimensions();
  const d = useIsoDateFormat;

  useEffect(() => {
    if (height < 1080) {
      setItemsPerPage(7);
    }
    setOrgUsers(MOCK_USERS);

    setError('');
    setIsLoading(false);
    console.log(error);
  }, []);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = orgUsers.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    const results = [];
    setCurrentPage(1);
    if (query) {
      MOCK_USERS.forEach((org) => {
        if (org.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(org);
        }
      });
      results.sort((a, b) => a.createdAt < b.createdAt);
      setOrgUsers(results);
      setSearchActive(true);
    } else {
      setOrgUsers(MOCK_USERS);
      setSearchActive(false);
    }
  };

  const onClickCancelInvite = (invite) => {
    setShowCancelInvite(true);
    setSelectedInvite(invite);
  };

  const handleCancelInvite = () => {
    console.log(`cancel invite for: ${selectedInvite.email}`);
    AppState.messages.toastMessage.value = { text: 'Invitation Canceled', variant: 'success' };
    setShowCancelInvite(false);
    setSelectedInvite(null);
  };

  return (
    <CoreAdminLayout>
      <PageCard
        title="Organization Users"
        isLoadingPage={isLoading}
      >

        {MOCK_INVITES.length > 0
        && (
          <Fragment>
            <Box mt="1.5em" mb="1em">
              <Text>
                Invitations:
                {' '}
                {MOCK_INVITES.length}
              </Text>
            </Box>
            <Table mb="2em" tableMinHeight="0em">
              <TableHeader>
                <TableCell widthPercent="1%">Id</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Organization</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>{' '}</TableCell>
              </TableHeader>
              {MOCK_INVITES
                .map((invite) => (
                  <TableRow>
                    <TableCell widthPercent="1%">{invite.id}</TableCell>
                    <TableCell>
                      <Text fontSize="0.813rem" color="var(--text-primary)" fontWeight="600">
                        {invite.email}
                      </Text>
                    </TableCell>
                    <TableCell>{invite.organization}</TableCell>
                    <TableCell>{d(invite.createdDate)}</TableCell>
                    <TableCell align="right">
                      <LinkButton btnText="Cancel Invite" variant="small" color="var(--text-soft-mute)" mr="1em" onClick={() => onClickCancelInvite(invite)} />
                    </TableCell>
                  </TableRow>
                ))}
            </Table>
            <BreakLine mb="1.5em" mt="0.5em" color="var(--border)" />
          </Fragment>
        )}

        <TableControls
          itemName="User"
          tableData={MOCK_USERS}
          currentResults={currentResults}
          indexOfFirst={indexOfFirst}
          indexOfLast={indexOfLast}
          showSearch
          searchActive={searchActive}
          searchResults={orgUsers}
          handleSearch={onSearchResults}
        />
        <Table mb="1em" tableMinHeight="0em">
          <TableHeader>
            <TableCell widthPercent="1%">Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Organization</TableCell>
            <TableCell>Created Date</TableCell>
          </TableHeader>
          {currentResults
            .map((user) => (
              <TableRow>
                <TableCell widthPercent="1%">{user.id}</TableCell>
                <TableCell>
                  {user.firstName}
                  {' '}
                  {user.lastName}
                </TableCell>
                <TableCell>
                  <Text fontSize="0.813rem" color="var(--text-primary)" fontWeight="600">
                    {user.organization}
                  </Text>
                </TableCell>
                <TableCell>{d(user.createdDate)}</TableCell>
              </TableRow>
            ))}
          {(currentResults.length === 0)
          && (
            <Box p="1.25em 1em">
              <Text color="var(--text-muted-panel)" fontSize="0.875rem">No Users to Display</Text>
            </Box>
          )}
        </Table>

        <TablePagination
          itemsPerPage={itemsPerPage}
          totalItems={orgUsers.length}
          paginate={paginate}
          currentPage={currentPage}
        />

        {showCancelInvite
        && (
        <DeleteConfirmationOverlay
          headerLabel="Are you sure?"
          header={`Cancel invite to organization: ${selectedInvite.organization}`}
          explanation={`You are about to cancel the invitation sent to: ${selectedInvite.email}`}
          closeFunction={() => setShowCancelInvite(false)}
          handleDelete={() => handleCancelInvite(selectedInvite)}
          error={error}
          isLoading={isLoading}
          confirmationPhrase="Cancel Invite"
          buttonText="Cancel Invite"
        />
        )}

      </PageCard>

    </CoreAdminLayout>
  );
}

export default AdminOrganizationsUsers;
