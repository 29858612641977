/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const AddLyricTag = styled.div`
    background: var(--panel-stat);
    padding: 0.5em;
    border-radius: 0.5em;
    cursor: pointer;
    flex-grow: 0;
    width: fit-content;
    text-transform: capitalize;
    font-size: 0.893em;
    font-weight: 400;
    margin-bottom: 0.25em;
    line-height: 1;
`;

export const LyricTag = styled.div`
    background: var(--tag-valid);
    padding: 0.5em;
    border-radius: 0.5em;
    flex-grow: 0;
    width: fit-content;
    text-transform: capitalize;
    font-size: 0.893em;
    font-weight: 400;
    margin-bottom: 0.25em;
    line-height: 1;
    color: var(--white);
`;

export const InvalidLyricTag = styled.div`
    background: var(--tag-invalid);
    padding: 0.5em;
    border-radius: 0.5em;
    width: fit-content;
    text-transform: capitalize;
    font-size: 0.893em;
    font-weight: 600;
    margin-bottom: 0.25em;
    line-height: 1;
    color: var(--white);
`;

export const OptionTag = styled.div`
    opacity: 0;
    background: var(--purple-300);
    padding: 0.5em;
    border-radius: 0.5em;
    cursor: pointer;
    width: fit-content;
    text-transform: capitalize;
    font-size: 0.893em;
    font-weight: 600;
    display: flex;
    align-items: center;
    line-height: 1;
    color: var(--white);

    @media screen and (max-width: ${pointBreaks.sm}) {
        width: 100%;
    }
`;

export const OptionGrid = styled.div`
    margin-top: 0.5em;
    display: flex;
    gap: 0.5em;
    margin-bottom: 0.5em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
`;
