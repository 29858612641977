import PropTypes from 'prop-types';
import Box from '@ui-kit/box/Box';
import Spinner from '@ui-kit/loaders/Spinner';

import StyledLinkButton from './LinkButtonStyles';

function LinkButton({
  isLoading,
  isDisabled,
  onClick,
  btnText,
  spinnerVariant,
  underline,
  ...restProps
}) {
  return (
    <StyledLinkButton
      disabled={isDisabled || isLoading}
      onClick={onClick}
      underline={underline}
      {...restProps}
    >
      <Box display="flex" alignItems="center">
        {isLoading
      && <Spinner variant={spinnerVariant} size="13px" mono mr="6px" />}
        { btnText }
      </Box>
    </StyledLinkButton>
  );
}

LinkButton.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  btnText: PropTypes.string,
  spinnerVariant: PropTypes.string,
  underline: PropTypes.bool,
};

LinkButton.defaultProps = {
  isLoading: null,
  isDisabled: null,
  btnText: null,
  onClick: null,
  spinnerVariant: null,
  underline: true,
};

export default LinkButton;
