export const REP_SUBSCRIPTION_PLANS = [
  {
    name: 'Representation - Basic',
    stripePriceId: 'price_1OXokuGVcZXZyFKG9bFvEuia',
    stripeProductId: 'prod_PMXqUavKJMjckk',
    testMode: true,
    isActive: true,
    price: '$14.99 /mo',
    description: 'Manage up to 25 Artists',
    metadata: {
      maxArtists: 25,
    },
  },
  {
    name: 'Representation - Pro',
    stripePriceId: 'price_1OXolsGVcZXZyFKGQ4l0XSg5',
    stripeProductId: 'prod_PMXrYMiANF3YUd',
    testMode: true,
    isActive: true,
    price: '$49.99 /mo',
    description: 'Manage up to 100 Artists',
    metadata: {
      maxArtists: 100,
    },
  },
];

export const ARTIST_SUBSCRIPTION_PLANS = [];
