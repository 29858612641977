import PropTypes from 'prop-types';
import { useEffect } from 'preact/hooks';
import { route } from 'preact-router';

import Icon from '@ui-kit/icon';
import SwitchLogo from '@assets/logos/switchchord-logo-main.svg';
import {
  PageWrapper,
  ContentWrapper,
  ConversationContent,
  BrandWrapper,
} from './ConversationLayoutStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function ConversationLayout({
  children,
  logoPath,
}) {
  useEffect(() => { document.body.style.backgroundColor = 'var(--white)'; }, []);

  const onClickModalLogo = () => {
    if (logoPath) {
      route(logoPath);
    }
  };

  return (
    <PageWrapper>
      <BrandWrapper><Icon size="156px" onClick={onClickModalLogo} style={{ cursor: logoPath ? 'pointer' : 'default' }}><SwitchLogo /></Icon></BrandWrapper>
      <ContentWrapper>
        <ConversationContent>
          {children}
        </ConversationContent>
      </ContentWrapper>
    </PageWrapper>
  );
}

ConversationLayout.propTypes = {
  children: childrenPropTypes.isRequired,
  logoPath: PropTypes.string,
};

ConversationLayout.defaultProps = {
  logoPath: null,
};

export default ConversationLayout;
