/* eslint-disable import/prefer-default-export */
import { repTokensUrl } from '../urls';
import refresh from '../refresh';

export async function getRepTokens() {
  return refresh(async () => {
    const response = await fetch(repTokensUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}
