/* eslint-disable object-curly-newline */
import { useState, useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';
import { route } from 'preact-router';

import AppState from '@state';

import loadOrganization from '@actions/loadOrganization';
import loadOrgConnections from '@actions/loadOrgConnections';

import PublisherProfileOverlay from '@shared-overlays/publisherProfileOverlay';
import CorePublisherLayout from '@layouts/corePublisher';
import PageCard from '@layouts/pageCard';

import DeleteConfirmationOverlay from '@shared-overlays/deleteConfirmationOverlay';

import ActivePublisherConnections from './sections/activePublisherConnections';
import PendingPublisherConnections from './sections/pendingPublisherConnections';
import ExpiredPublisherConnections from './sections/expiredPublisherConnections';

const validModes = {
  active: 'connections',
  pending: 'invites',
  inactive: 'inactive',
};

function PublisherConnections({ uuid }) {
  const [pubsMode, setPubsMode] = useState('connections');
  const [selectedPub, setSelectedPub] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  const [activePubs, setActivePubs] = useState([]);
  const [inactivePubs, setInactivePubs] = useState([]);
  const [pendingInvites, setPendingInvites] = useState([]);

  const [credToRevoke, setCredToRevoke] = useState(null);
  const [isRevoking, setIsRevoking] = useState(false);

  useEffect(() => {
    loadOrganization(uuid, { forcedLoad: false }).then(() => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('pubs');
      if (token && Object.keys(validModes).includes(token)) {
        setPubsMode(validModes[token]);
      } else {
        setPubsMode('connections');
      }
      loadOrgConnections(AppState.pubOrganization.id.value, { forcedLoad: false }).then(() => {
        const connections = AppState.orgConnections.value;
        setActivePubs(connections.filter((pub) => pub.isActive));
        setInactivePubs(connections.filter((pub) => !pub.isActive));
        setPendingInvites(connections.filter((pub) => !pub.credentialJSON));
        setIsLoading(false);
      });
    });
  }, [AppState.navigation.defaultTabListener.value]);

  const addNewConnection = () => {
    route(`/publisher/${AppState.pubOrganization.uuid.value}/connections/invite`);
  };

  const handleRevokeCredential = () => {
    setIsRevoking(true);
    setError('');

    setTimeout(() => {
      // patchArtistRepsCredentialsRevoked(credToRevoke.id)
      //   .then((response) => {
      //     if (response.status === 200) {
      //       setIsRevoking(false);
      //       setCredToRevoke(null);
      //       alert('Credential Revoked Successfully!');
      //       window.location.reload();
      //     } else {
      //       response.json()
      //         .then((json) => setError(json.error || response.statusText))
      //         .catch(() => setError(response.statusText));
      //       setIsRevoking(false);
      //     }
      //   })
      //   .catch((err) => {
      //     setIsRevoking(false);
      //     setError(err.message);
      //   });
    }, 800);
  };

  return (
    <CorePublisherLayout>
      <PageCard
        title="Publisher Connections"
        // primaryAction={{ method: addNewConnection, text: '+ New' }}
        isLoadingPage={isLoading}
        tabs={[
          { method: () => setPubsMode('connections'), text: 'Connections', count: activePubs.length, isSelected: pubsMode === 'connections' },
          { method: () => setPubsMode('invites'), text: 'Invites', count: pendingInvites.length, isSelected: pubsMode === 'invites' },
          { method: () => setPubsMode('inactive'), text: 'Inactive', count: inactivePubs.length, isSelected: pubsMode === 'inactive' },
        ]}
      >
        {
          {
            connections:
              (<ActivePublisherConnections
                setSelectedPub={setSelectedPub}
                addNewConnection={addNewConnection}
                isLoading={isLoading}
                pubs={activePubs}
              />),
            invites: <PendingPublisherConnections addNewConnection={addNewConnection} isLoading={isLoading} invites={pendingInvites} />,
            inactive: <ExpiredPublisherConnections setSelectedPub={setSelectedPub} isLoading={isLoading} pubs={inactivePubs} />,
          }[pubsMode]
        }
      </PageCard>

      {selectedPub && (
      <PublisherProfileOverlay
        closeFunction={() => setSelectedPub(null)}
        pub={selectedPub}
        setCredToRevoke={setCredToRevoke}
      />
      )}

      {credToRevoke
      && (
      <DeleteConfirmationOverlay
        headerLabel="Are you sure?"
        header="Revoke Verifiable Credential"
        explanation="This will revoke the credential and break your connection with this publisher."
        closeFunction={() => setCredToRevoke(null)}
        handleDelete={handleRevokeCredential}
        error={error}
        isLoading={isRevoking}
        confirmationPhrase="Revoke Credential"
        buttonText="Confirm and Revoke"
      />
      )}
    </CorePublisherLayout>
  );
}

PublisherConnections.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PublisherConnections;
