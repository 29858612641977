import styled from 'styled-components';
import { space } from 'styled-system';
// import pointBreaks from '@styles/styleConstants';

// export const FileWrapper = styled.div(({
//   borderBottom,
// }) => `
//     padding: 0.5em 0em;
//     border-bottom: 1px solid var(--card-divider);
//     ${!borderBottom
//         && `&:last-child {
//         border-bottom: none;
//     }`}
// `);

export const FileCardWrapper = styled.div`
   background: var(--player-bg);
   border-radius: 1em;
   border: 1px solid var(--player-border);
   padding: 1em;
   display: flex;
   align-items: center;
   position: relative;
   padding-right: 3em;
   ${space};
`;

export const DynamicWrapper = styled.div`
   display: block;
`;

export const FileContentWrapper = styled.div`
    display: block;
`;

export const MenuWrapper = styled.div`
    position: absolute;
    top: 12px;
    right: 16px;
`;

export const MenuDot = styled.div`
    height: 4px;
    width: 4px;
    border-radius: 50px;
    margin-right: 4px;
    background: var(--icon);
    opacity: 0.8;
`;

export const MenuItem = styled.div`
    font-size: 0.75rem;
    padding: 0.5rem;
    cursor: pointer;
    color: var(--text-secondary);
`;
