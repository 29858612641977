/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const LoggedInWrapper = styled.div`
    position: absolute;
    top: 32px;
    right: 32px;

    @media screen and (max-width: ${pointBreaks.sm}) {
        left: 32px;
        top: 68px;
    }
`;
