/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space, layout } from 'styled-system';
// import pointBreaks from '@styles/styleConstants';

export const TransitionWrapper = styled.div`
    background: var(--card);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid var(--panel-info-border);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 1rem;
    ${space};
    ${layout};
`;

export const TransitionContent = styled.div`
    width: 100%;
    max-width: 20rem;
    transform: translateY(-8rem);
    ${space};
    ${layout};
`;
