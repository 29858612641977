/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Spinner from '@ui-kit/loaders/Spinner';
import CredentialCard from '@distinct-components/cards/credentialCard';
import CredentialCardGrid from '@layouts/sharedGrids/credentialCardGrid/CredentialCardGrid';

function ProfileOverlayCredentials({
  credentials,
  isLoading,
  setCredToRevoke,
}) {
  return (
    <Box mt="1.5em">
      {isLoading ? (
        <Box>
          <Box width="100%" display="flex" justifyContent="center" alignItems="center" minHeight="260px">
            <Spinner size="1.25em" variant="page" />
          </Box>
        </Box>
      ) : (
        <Box>
          <Box mb="2em">
            <Text mb="0.75em">
              Active Credentials:
              {' '}
              {(credentials.filter((c) => c.isActive)).length}
            </Text>
            <CredentialCardGrid>
              {credentials.filter((c) => c.isActive).map((credential) => (
                <CredentialCard
                  credential={credential}
                  title="Publisher Connection"
                  handleRevoke={() => setCredToRevoke(credential)}
                />
              ))}
            </CredentialCardGrid>
          </Box>
          <Box mb="2em">
            {credentials.filter((c) => !c.isActive).length > 0 && (
            <Text mb="0.75em">
              Inactive Credentials:
              {' '}
              {(credentials.filter((c) => !c.isActive)).length}
            </Text>
            )}
            <CredentialCardGrid>
              {credentials.filter((c) => !c.isActive).map((credential) => (
                <CredentialCard
                  credential={credential}
                  title="Publisher Connection"
                />
              ))}
            </CredentialCardGrid>
          </Box>
        </Box>
      )}
    </Box>
  );
}

ProfileOverlayCredentials.propTypes = {
  credentials: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setCredToRevoke: PropTypes.func.isRequired,
};

export default ProfileOverlayCredentials;
