import styled from 'styled-components';

export const PlayerContainer = styled.div(({
  isActive,
}) => `
    background: var(--player-bg);
    border: 1px solid var(--player-border);
    padding: 0.875em;
    height: 8em;
    overflow: hidden;
    border-radius: 0.5em;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 900;
    max-width: 1770px;
    margin: 0 auto;
    transform: translateY(${isActive ? '0px' : '130px'});
    transition: all 500ms cubic-bezier(0.26, 1.5, 0.5, 1.11) 0ms;
`);

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    color: var(--text-soft-mute);
    font-size: 0.875rem;
`;
