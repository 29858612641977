import styled from 'styled-components';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import pointBreaks from '@styles/styleConstants';

export const LandingWrapper = styled(Box)`
    padding: 16em 1em 1em 1em;
    max-width: 28em;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

   @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 2em 0em 1.75em 0em;
    }
`;

export const StyledLandingHeader = styled(Text)`
    font-size: 1.5rem;
    font-weight: 800;
    font-family: var(--font-secondary);
    color: var(--text-primary);
    margin-bottom: 1em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1.25rem;
    }
`;

export const ListItem = styled(Box)`
    border-bottom: 1px solid var(--card-divider);
    padding: 1.25em 0.5em 1.25em 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
`;
