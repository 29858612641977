/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'preact/hooks';
import config from '@config';
import { Fragment } from 'preact';
import AppState from '@state';

import useScript from '@hooks/useScript';

import { postAccountPublisherPreset, putAccountPublisherPreset, deleteAccountPublisherPreset } from '@api/restricted/account-publisher-preset-api';
import loadAccountPublisherPresets from '@actions/loadAccountPublisherPresets';

import useDebounce from '@hooks/useDebounce';
import useRoundNumber from '@hooks/useRoundNumber';

import AlertMessage from '@ui-kit/alert/Alert';
import Header from '@ui-kit/typography/header';
import Text from '@ui-kit/typography/text';
import Span from '@ui-kit/typography/span';
import Box from '@ui-kit/box';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';
import BreakLine from '@ui-kit/dividers/breakLine';
import PanelDataCard from '@distinct-components/cards/panelDataCard';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import LinkButton from '@ui-kit/buttons/linkButton';
import PublisherCard from '@distinct-components/cards/publisherCard';
import ArrowLeftIcon from '@assets/icons/arrow-left.svg';
import Icon from '@ui-kit/icon';
import CountDown from '@distinct-components/feedback/countDown';

import { ACCOUNT_EXPLANATIONS } from '@constants/supportingCopy';

import ManagePublisherOverlayCreate from './sections/managePublisherOverlayCreate';
import ManagePublisherOverlayList from './sections/managePublisherOverlayList';
import ManagePublisherOverlayEdit from './sections/managePublisherOverlayEdit';

import { StyledAmountText } from './ManagePublisherOverlayCreateEditPresetStyles';

function ManagePublisherOverlayCreateEditPreset({
  selectedPreset,
  numAssociatedWorkspaces,
  filterWorkspaces,
  handlePresetCreatedEdited,
  setManageMode,
}) {
  const placesSrc = `https://maps.googleapis.com/maps/api/js?key=${config.google.placesKey}&libraries=places`;
  const placesState = useScript(placesSrc);
  const [presetName, setPresetName] = useState(selectedPreset?.id ? selectedPreset.name : `My Publishing Info${AppState.publisherPresets.value.length > 0 ? ` ${AppState.publisherPresets.value.length + 1}` : ''}`);

  // Need JSON functions to create deep copy
  const [presetOwners, setPresetOwners] = useState(selectedPreset?.id ? JSON.parse(JSON.stringify(selectedPreset.owners)) : []);
  const [presetAdmins, setPresetAdmins] = useState(selectedPreset?.id ? JSON.parse(JSON.stringify(selectedPreset.admins)) : []);

  const [presetIsDefault, setPresetIsDefault] = useState(selectedPreset?.id ? selectedPreset.isDefault : false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [mode, setMode] = useState('presetForm');
  const [totalOwnership, setTotalOwnership] = useState(0);
  const [publisherContext, setPublisherContext] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [selectedPublisherId, setSelectedPublisherId] = useState(null);

  const calculateOwnership = (owners) => {
    const total = owners.reduce((n, { ownedPercentage }) => n + ownedPercentage, 0);
    setTotalOwnership(useRoundNumber(total, 2));
  };

  useEffect(() => {
    if (selectedPreset) {
      filterWorkspaces().then(() => {
        calculateOwnership(presetOwners);
      });
    }
  }, []);

  const handlePercentageChanges = (share, pub) => {
    let cleanedShare = share;
    if (!Number.isInteger(cleanedShare)) {
      cleanedShare = useRoundNumber(cleanedShare, 2);
    }

    if (cleanedShare > 100) {
      setError('Enter a value less than 100');
      return;
    }
    if (cleanedShare < 0.01) {
      setError('Enter a value higher than 0.01');
      return;
    }

    setError(null);

    const pubToUpdate = presetOwners.findIndex((p) => p.id === pub.id);
    const owners = presetOwners;
    owners[pubToUpdate].ownedPercentage = cleanedShare || 0;
    setPresetOwners(owners);
    calculateOwnership(owners);
  };

  const onChangePercentage = useCallback(useDebounce(handlePercentageChanges), []);

  const handleTerritoryChange = (selection, pub) => {
    const pubToUpdate = presetAdmins.findIndex((p) => p.id === pub.id);
    const admins = presetAdmins;
    admins[pubToUpdate].territory = selection;
    setPresetAdmins(admins);
  };

  const onClickAddOwnerPublisher = () => {
    setPublisherContext('owner');
    if (Object.keys(AppState.publishers.value).length > 0) {
      setMode('list');
    } else {
      setMode('create');
    }
  };

  const onClickAddAdminPublisher = () => {
    setPublisherContext('admin');
    if (Object.keys(AppState.publishers.value).length > 0) {
      setMode('list');
    } else {
      setMode('create');
    }
  };

  const handleAddPublisher = (pub) => {
    if (publisherContext === 'owner') {
      if (presetOwners.some((p) => p.id === pub.id)) {
        setMode('presetForm');
        setPublisherContext(null);
        return;
      }
      const pubToAdd = {
        id: pub.id,
        ownedPercentage: 100,
      };
      const publishers = presetOwners;
      publishers.push(pubToAdd);
      setPresetOwners(publishers);
      setMode('presetForm');
      calculateOwnership(publishers);
    } else if (publisherContext === 'admin') {
      if (presetAdmins.some((p) => p.id === pub.id)) {
        setMode('presetForm');
        setPublisherContext(null);
        return;
      }
      const pubToAdd = {
        id: pub.id,
        territory: 'Worldwide',
      };
      const publishers = presetAdmins;
      publishers.push(pubToAdd);
      setPresetAdmins(publishers);
      setMode('presetForm');
    }
    setPublisherContext(null);
  };

  const onClickRemovePublisher = (pub) => {
    if (pub.ownedPercentage) {
      const filteredPublishers = presetOwners.filter((p) => p.id !== pub.id);
      setPresetOwners(filteredPublishers);
      calculateOwnership(filteredPublishers);
    } else {
      const filteredPublishers = presetAdmins.filter((p) => p.id !== pub.id);
      setPresetAdmins(filteredPublishers);
    }
  };

  const handleCreatePreset = (name, publishers) => {
    const isDefault = AppState.publisherPresets.value.length === 0 ? true : presetIsDefault;
    setIsLoading(true);
    postAccountPublisherPreset(name, isDefault, publishers)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200 || response.status === 202) {
          AppState.artistProfile.publisherCritical.value = false;
          setError('');
          loadAccountPublisherPresets().then(() => {
            handlePresetCreatedEdited();
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const handleEditPreset = (name, publishers) => {
    setIsLoading(true);
    putAccountPublisherPreset(selectedPreset.id, name, publishers)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setError('');
          loadAccountPublisherPresets().then(() => {
            handlePresetCreatedEdited();
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const onClickSavePreset = () => {
    if (presetOwners.length === 0 || presetAdmins.length === 0) {
      setError('You need at least one publisher for Original Publisher and Administration');
      return;
    }

    if (!presetOwners.every((p) => p.ownedPercentage > 0)) {
      setError('An owner cannot have 0% ownership');
      return;
    }

    if (totalOwnership !== 100) {
      setError('Total ownership must be exactly 100%');
      return;
    }

    const cleanedName = presetName.trim().split(/\s+/).join(' ');

    if (!cleanedName) {
      setError('Invalid publisher relationship name');
      return;
    }

    // Combine owners and admins
    const publishers = [];
    presetOwners.filter((pub) => !pub?.isVerified).map(({ ownedPercentage, ...rest }) => ({ share: ownedPercentage, ...rest })).forEach((pub) => {
      const foundIndex = publishers.findIndex((p) => p.id === pub.id);
      if (foundIndex > -1) {
        publishers[foundIndex] = { ...publishers[foundIndex], share: pub.share };
      } else {
        publishers.push({ territory: null, ...pub });
      }
    });
    presetAdmins.filter((pub) => !pub?.isVerified).forEach((pub) => {
      const foundIndex = publishers.findIndex((p) => p.id === pub.id);
      if (foundIndex > -1) {
        publishers[foundIndex] = { ...publishers[foundIndex], territory: pub.territory };
      } else {
        publishers.push({ share: 0, ...pub });
      }
    });

    if (selectedPreset?.id) {
      handleEditPreset(cleanedName, publishers);
    } else {
      handleCreatePreset(cleanedName, publishers);
    }
  };

  const onClickDeletePreset = () => {
    if (selectedPreset?.isVerified) {
      setError('You cannot delete a verified publisher relationship');
      return;
    }
    if (numAssociatedWorkspaces > 0) {
      setError('This publisher relationship is currently assigned to your Compositions');
      return;
    }

    setError('');
    setShowDeleteConfirmation(true);
    setTimeout(() => {
      setShowDeleteConfirmation(false);
    }, 5000);
  };

  const handleDeletePreset = () => {
    setIsDeleting(true);
    deleteAccountPublisherPreset(selectedPreset.id)
      .then((response) => {
        setIsDeleting(false);
        if (response.status === 200) {
          setError('');
          loadAccountPublisherPresets().then(() => {
            setManageMode('listPresets');
          });
        } else {
          response.json()
            .then((json) => setError(json.error ? json.error : response.statusText))
            .catch(() => setError(response.statusText));
        }
      })
      .catch((err) => {
        setIsDeleting(false);
        setError(err.message);
      });
  };

  const onClickEditPub = (publisher) => {
    setSelectedPublisherId(publisher.id);
    setMode('edit');
  };

  return (
    <Box>
      {
        {
          presetForm:
  <Box>
    {selectedPreset?.id
    && (
    <Box display="flex" alignItems="center" className="cursor-p" onClick={() => setManageMode('listPresets')} mb="1em">
      <Icon size="1.25em" cursor><ArrowLeftIcon /></Icon>
      <Text ml="0.5em">Go Back</Text>
    </Box>
    )}

    <Header mb="0.75em">{selectedPreset?.id ? 'Edit Publisher Relationship' : 'Publishing Information'}</Header>
    {!selectedPreset?.id
    && <ConversationExplanation mb="2em" explanation={ACCOUNT_EXPLANATIONS.publishers.presetExplanation} />}

    {selectedPreset?.id && numAssociatedWorkspaces > 0
    && (
    <AlertMessage
      variant="default"
      message={`This edit will apply to ${numAssociatedWorkspaces} composition(s)`}
      mb="1.5em"
    />
    )}

    <InputLabel label="Give this relationship a name:" />
    <BaseInput
      id="preset"
      name="preset"
      placeholder="Name for future reference..."
      value={presetName}
      onChange={(e) => setPresetName(e.target.value)}
      defaultValue=""
      type="text"
      maxLength="150"
      fluid
      mb="1.5rem"
    />

    <BreakLine mb="1.25em" color="var(--border)" />

    <Box display="flex" justifyContent="space-between" mb="0.75em">
      <Text fontWeight="600" color="var(--brand-primary)">Original Publisher</Text>
      <StyledAmountText amount={totalOwnership}>
        {totalOwnership}
        %
        {' '}
        <Span>
          Defined
        </Span>
      </StyledAmountText>
    </Box>

    <Box>
      {presetOwners && presetOwners.map((po) => ({ ...po, ...AppState.publishers.value[po.id] })).map((po) => (
        <PublisherCard
          publisher={po}
          handleRemovePublisher={onClickRemovePublisher}
          handlePublisherShareChange={onChangePercentage}
          handlePublisherEdit={selectedPreset ? onClickEditPub : null}
          mb="1em"
        />
      ))}
    </Box>

    {presetOwners.length === 0
      ? (
        <PanelDataCard
          variant="initialize"
          onClick={onClickAddOwnerPublisher}
          mb="1.5em"
        >
          <Text fontWeight="600">Add Publisher</Text>
        </PanelDataCard>
      )
      : (
        <Box display="flex" justifyContent="flex-end" mb="1.5em">
          {(!selectedPreset?.isVerified || (selectedPreset?.isVerified && totalOwnership !== 100))
            ? (
              <LinkButton btnText="Add Another" variant="small" color="var(--text-primary)" onClick={onClickAddOwnerPublisher} />
            ) : (
              <Text color="var(--text-medium-mute)" fontSize="0.75rem" lineHeight="1">Relationship Verified. Cannot add Owners</Text>
            )}
        </Box>
      )}

    <BreakLine mb="1.25em" color="var(--border)" />

    <Box>
      <Box display="flex" justifyContent="space-between" mb="0.75em">
        <Text fontWeight="600" color="var(--brand-primary)">Administration</Text>
      </Box>

      <Box>
        {presetAdmins && presetAdmins.map((pa) => ({ ...pa, ...AppState.publishers.value[pa.id] })).map((pa) => (
          <PublisherCard
            publisher={pa}
            handleRemovePublisher={onClickRemovePublisher}
            handlePublisherTerritoryChange={handleTerritoryChange}
            handlePublisherEdit={selectedPreset ? onClickEditPub : null}
            mb="1em"
          />
        ))}
      </Box>

      {presetAdmins.length === 0
        ? (
          <PanelDataCard
            variant="initialize"
            onClick={onClickAddAdminPublisher}
            mb="1.5em"
          >
            <Text fontWeight="600">Add Publisher</Text>
          </PanelDataCard>
        )
        : (
          <Box display="flex" justifyContent="flex-end" mb="1.5em">
            {!selectedPreset?.isVerified
              ? (
                <LinkButton btnText="Add Another" variant="small" color="var(--text-primary)" onClick={onClickAddAdminPublisher} />
              ) : (
                <Text color="var(--text-medium-mute)" fontSize="0.75rem" lineHeight="1">Relationship Verified. Cannot add Admins</Text>
              )}
          </Box>
        )}
    </Box>

    {!selectedPreset?.id && AppState.publisherPresets.value.length > 0 && (
    <Box display="flex" mb="2em" justifyContent="flex-end">
      <Text mr="1.25em" fontSize="0.75rem" color="var(--text-primary)">Make Default for New Compositions</Text>
      <ToggleSwitch id="isDefault">
        <input
          type="checkbox"
          name="isDefault"
          id="isDefault"
          checked={presetIsDefault}
          onChange={() => setPresetIsDefault(!presetIsDefault)}
        />
      </ToggleSwitch>
    </Box>
    )}

    {error
    && (
    <AlertMessage
      variant="negative"
      message={error}
      mb="1.5em"
    />
    )}

    <BaseButton mb="2.5em" type="submit" btnText="Save" fluid isLoading={isLoading} onClick={onClickSavePreset} />
    {selectedPreset?.id
    && (
    <Box mb="0.5rem" display="flex" alignItems="center" justifyContent="center">
      {showDeleteConfirmation
        ? (
          <Fragment>
            <CountDown mr="0.25em" timeInSeconds={5} size={12} color="var(--red-300)" />
            <LinkButton btnText="Yes, Delete Relationship" color="var(--red-300)" mr="1em" onClick={handleDeletePreset} />
          </Fragment>
        )
        : <LinkButton type="button" color="var(--red-300)" spinnerVariant="danger" isLoading={isDeleting} btnText="Delete Relationship" onClick={onClickDeletePreset} />}
    </Box>
    )}
  </Box>,

          list:
  <ManagePublisherOverlayList
    onSelectPublisher={handleAddPublisher}
    setSelectedPublisherId={setSelectedPublisherId}
    setMode={setMode}
  />,

          create:
  <ManagePublisherOverlayCreate
    handleAddPublisher={handleAddPublisher}
    setMode={setMode}
    usePlacesState={placesState}
  />,

          edit:
  <ManagePublisherOverlayEdit
    selectedPublisherId={selectedPublisherId}
    setMode={setMode}
    usePlacesState={placesState}
  />,
        }[mode]
      }
    </Box>
  );
}

ManagePublisherOverlayCreateEditPreset.propTypes = {
  selectedPreset: PropTypes.object,
  numAssociatedWorkspaces: PropTypes.number.isRequired,
  filterWorkspaces: PropTypes.func.isRequired,
  handlePresetCreatedEdited: PropTypes.func.isRequired,
  setManageMode: PropTypes.func.isRequired,
};

ManagePublisherOverlayCreateEditPreset.defaultProps = {
  selectedPreset: null,
};

export default ManagePublisherOverlayCreateEditPreset;
