/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import { space, layout } from 'styled-system';

export const LoggedInWrapper = styled.div`
    position: absolute;
    top: 32px;
    right: 32px;

    @media screen and (max-width: ${pointBreaks.sm}) {
        left: 32px;
        top: 68px;
    }
`;

export const ItemCard = styled.div`
    background: var(--background);
    border-radius: 1em;
    width: 100%;
    border: 1px solid var(--panel-info-border);
    display: flex;
    align-items: center;
    min-height: 88px;
    margin-bottom: 1em;
    ${space};
    ${layout};
`;
