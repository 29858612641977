import { artistLookupUrl } from '../urls';
import refresh from '../refresh';

export async function getArtistLookupWithUsername(username, account, workspace) {
  const url = `${artistLookupUrl}?${new URLSearchParams({
    username,
    account,
    workspace,
  })}`;
  return refresh(async () => {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function getArtistLookupWithEmail(email, account, workspace) {
  const url = `${artistLookupUrl}?${new URLSearchParams({
    email,
    account,
    workspace,
  })}`;
  return refresh(async () => {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}
