/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledCardHeader = styled.div`
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    background: var(--panel-divider);
    padding: 0.5em 1em;
    font-size: 0.75rem;
`;

export const StyledCardBody = styled.div`
    padding: 1.5em;
    font-size: 0.75em;
`;
