import { useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';

import AppState from '@state';

import Header from '@ui-kit/typography/header';
import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import CloseIcon from '@assets/icons/close-square.svg';
import EventList from '@distinct-components/eventList/EventList';
import AlertMessage from '@ui-kit/alert/Alert';
import Spinner from '@ui-kit/loaders/Spinner';
import Tabs from '@ui-kit/tabs/Tabs';

import getAccountActivities from '@api/restricted/account-activities-api';
import { getAccountNotifications, patchAccountNotificationsRead } from '@api/restricted/account-notifications-api';

import { translateNotification, translateActivity } from '@hooks/translateNotificationActivity';

import { Fragment } from 'preact';
import {
  NotificationsPageWrapper,
  NotificationsContentWrapper,
  NotificationsListWrapper,
  NotificationsHeader,
} from './NotificationsStyles';

function Notifications({ tab }) {
  const [notifications, setNotifications] = useState([]);
  const [activities, setActivities] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState('notifications');
  const [notificationsSeen, setNotificationsSeen] = useState(false);

  const loadAccountNotifications = async () => {
    try {
      const response = await getAccountNotifications();
      if (response.status !== 200) {
        const json = await response.json();
        if (json.error) {
          setError(json.error);
        } else {
          setError(response.statusText);
        }
      } else {
        const json = await response.json();
        const translatedNotifications = json.map((n) => translateNotification(n));
        setNotifications(translatedNotifications);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const loadAccountActivities = async () => {
    try {
      const response = await getAccountActivities();
      if (response.status !== 200) {
        const json = await response.json();
        if (json.error) {
          setError(json.error);
        } else {
          setError(response.statusText);
        }
      } else {
        const json = await response.json();
        const translatedActivities = json.map((activity) => translateActivity(activity, AppState.accountId.value));
        setActivities(translatedActivities);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    document.title = 'Events & Notifications';
    loadAccountNotifications().then(() => {
      loadAccountActivities().then(() => {
        setIsLoading(false);
        setSelectedTab(tab);
        if (tab === 'notifications') {
          setNotificationsSeen(true);
        }
      });
    });
  }, []);

  const handleTabChange = (newTab) => {
    setSelectedTab(newTab.value);
    if (newTab.value === 'notifications') {
      setNotificationsSeen(true);
    }
  };

  const handleBack = () => {
    if (notificationsSeen) {
      patchAccountNotificationsRead();
    }
    window.history.back();
  };

  return (
    <NotificationsPageWrapper>
      <NotificationsContentWrapper>
        <NotificationsHeader display="flex" justifyContent="space-between">
          <Header>Notifications & Activity</Header>
          <Icon size="1.5em" cursor><CloseIcon onClick={handleBack} /></Icon>
        </NotificationsHeader>
        <Tabs
          tabs={[{ name: 'My Notifications', value: 'notifications' }, { name: 'All Activity', value: 'activities' }]}
          handleTabChange={handleTabChange}
          selectedTab={selectedTab}
        />
        <NotificationsListWrapper>
          {error
            && (
              <AlertMessage
                variant="negative"
                message={error}
                mt="1.5em"
              />
            )}
          {isLoading
            ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="24em">
                <Spinner variant="page" size="2em" />
              </Box>
            )
            : (
              <Fragment>
                {selectedTab === 'notifications' && <EventList events={notifications} showUnreadIndicator notifications />}
                {selectedTab === 'activities' && <EventList events={activities} showWorkspace />}
              </Fragment>
            )}
        </NotificationsListWrapper>
      </NotificationsContentWrapper>
    </NotificationsPageWrapper>
  );
}

Notifications.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default Notifications;
