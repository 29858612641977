/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const SectionsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5em;
    margin-bottom: 1.5em;
    padding-bottom: 5em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        grid-template-columns: repeat(1, 1fr);
        padding-bottom: 0em;
    }
    ${space};
`;
