/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';

export const StyledStatusSelect = styled(BaseSelect)(({
  statusColor,
}) => `
    background: ${statusColor || 'var(--inactive)'};
    font-weight: 600;
    text-align: center;
    color: ${statusColor ? 'var(--white)' : 'var(--text-secondary)'};
    border: none;

    &:focus {
      border: none;
    }
`);
