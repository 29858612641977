import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';

import AuthLayout from '@layouts/auth/AuthLayout';
import Header from '@ui-kit/typography/header';
import LoginForm from '@shared-forms/loginForm/LoginForm';

import { postLoginWithEmail } from '@api/public/login-api';
import loadAccountProfile from '@actions/loadAccountProfile';

import AppState from '@state';
import resetGlobalState from '@state/reset';

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    document.title = 'Switchchord - Login';
  }, []);

  const handleAuthentication = (data) => {
    setIsLoading(true);
    const cleanedEmail = data.email.toLowerCase();
    postLoginWithEmail(cleanedEmail, data.password)
      .then((response) => {
        if (response.status !== 200) {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        } else {
          response.json().then((json) => {
            setError('');
            const deepLink = AppState.deepLinkUrl.value;
            resetGlobalState();
            localStorage.setItem('accessToken', json.accessToken);
            localStorage.setItem('refreshToken', json.refreshToken);
            loadAccountProfile().then(() => {
              if (!deepLink || deepLink.includes('login')) {
                route('/landing');
              } else {
                route(deepLink);
              }
            });
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return (
    <AuthLayout prompt="Don't have an account?" action="Sign Up" actionRoute="/signup">
      <Header mb="1em">Login</Header>
      <LoginForm
        isLoading={isLoading}
        error={error}
        handleAuthentication={handleAuthentication}
      />
    </AuthLayout>
  );
}

export default Login;
