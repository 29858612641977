/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Icon from '@ui-kit/icon';
import Globe from '@assets/icons/globe-line.svg';

import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import LinkButton from '@ui-kit/buttons/linkButton';

import { SOCIETIES } from '@constants/collectionSocieties';

import { ResultsWrapper, ResultsItem } from './selectSocietyStyles';

function SelectSociety({
  societyType,
  country,
  handleSelectedSociety,
  ...restProps
}) {
  const [isSearching, setIsSearching] = useState(false);
  const [societiesList, setSocietiesList] = useState([]);
  const [suggestedSocieties, setSuggestedSocieties] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const societies = SOCIETIES.filter((s) => s.societyType === societyType);
    setSocietiesList(societies);
    const suggestions = societies.filter((s) => s.country === country);
    setSuggestedSocieties(suggestions);
  }, []);

  const onClickToggleSearch = () => {
    setIsSearching(!isSearching);
  };

  return (
    <Box {...restProps}>
      {isSearching
        ? (
          <Fragment>
            <Text fontWeight="660" fontSize="1rem">Select Society</Text>
            <Box display="flex" justifyContent="space-between" mb="1em">
              <Box display="flex">
                <Icon size="1em" pt="3px" mr="0.5em"><Globe /></Icon>
                <Text>Searching Globally</Text>
              </Box>
              <LinkButton btnText="In My Country" variant="small" onClick={onClickToggleSearch} />
            </Box>
            <BaseInput
              id="writerSociety"
              name="writerSociety"
              placeholder="Start typing..."
              value={searchValue}
              type="text"
              fluid
              mb="1em"
              onChange={(e) => setSearchValue((e.target.value))}
            />
            <ResultsWrapper>
              {societiesList
                .filter((society) => {
                  if (!searchValue) return true;
                  if (society.name.includes(searchValue.toUpperCase())) {
                    return true;
                  }
                })
                .map((society) => (
                  <ResultsItem onClick={() => handleSelectedSociety(society)}>
                    <h4>{society.name}</h4>
                    <p>{society.country}</p>
                  </ResultsItem>
                ))}
              {(societiesList.filter((society) => society.name.includes(searchValue.toUpperCase())).length === 0)
              && <Text p="1em" color="var(--text-muted-panel)">No Results for Your Search</Text>}
            </ResultsWrapper>
          </Fragment>
        )
        : (
          <Fragment>
            <Text fontWeight="660" fontSize="1rem">Select Society</Text>
            <Box display="flex" justifyContent="space-between" mb="1em">
              <Box display="flex">
                <Icon size="1em" pt="3px" mr="0.5em"><Globe /></Icon>
                <Text>{country}</Text>
              </Box>
              <LinkButton btnText="Search Globally" variant="small" onClick={onClickToggleSearch} />
            </Box>
            <ResultsWrapper>
              {suggestedSocieties.map((society) => (
                <ResultsItem onClick={() => handleSelectedSociety(society)}>
                  <h4>{society.name}</h4>
                  <p>{society.country}</p>
                </ResultsItem>
              ))}
            </ResultsWrapper>
          </Fragment>
        )}
      <Box display="flex" justifyContent="center">
        <Text
          fontWeight="400"
          fontSize="0.75rem"
          color="var(--orange-300)"
          className="cursor-p"
          onClick={() => handleSelectedSociety({ name: 'NS', country: 'Not Defined', societyType: 'Not Defined' })}
        >
          I Don&apos;t Have a Membership
        </Text>
      </Box>
    </Box>
  );
}

SelectSociety.propTypes = {
  societyType: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  handleSelectedSociety: PropTypes.func.isRequired,
};

export default SelectSociety;
