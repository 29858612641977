import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import Text from '@ui-kit/typography/text';

export const StyledPageButton = styled.button(({
  isActive,
}) => `
    color: ${isActive ? 'var(--white)' : 'var(--text-soft-mute)'};
    background: ${isActive ? 'var(--active-nav)' : 'transparent'};
    height: 1.75em;
    min-width: 1.75em;
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5em;
    touch-action: manipulation;

    &:disabled {
        color: var(--text-muted-panel);
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
        text-align: center;
        height: 2.5em;
        min-width: 2.5em;
        margin-right: 0.75em;
     }
`);

export const StyledBaseSelect = styled(BaseSelect)`
  height: auto;
  padding: 0.5em 1.5em 0.5em 0.5em;
  background-position-x: 128%;
  text-align: center;
  margin-right: 1em;
`;

export const StyledItemsPerPageText = styled(Text)`
  display: block;

  @media screen and (max-width: ${pointBreaks.sm}) {
    display: none;
 }
`;
