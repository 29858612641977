import AppState from '@state';
import { getWorkspaceAccounts } from '@api/restricted/workspace-account-api';
import useErrorOverlay from '@hooks/useErrorOverlay';

export default async function loadWorkspaceAccounts(workspaceId) {
  try {
    const response = await getWorkspaceAccounts(workspaceId);

    if (response.status !== 200) {
      const json = await response.json();
      if (json.error) {
        useErrorOverlay({ errorMessage: `${json.error} when loading workspace accounts` });
      } else {
        useErrorOverlay({ errorMessage: `${response.statusText} when loading workspace accounts` });
      }
    } else {
      const json = await response.json();

      AppState.workspaceAccounts.value = json.accounts.map(({ alias, pubPreset, ...account }) => {
        const parsedAccount = {
          ...account,
        };

        if (pubPreset?.name) {
          parsedAccount.pubPreset = {
            id: pubPreset.id,
            name: pubPreset.name,
            isDefault: pubPreset.isDefault,
            owners: pubPreset.publishers.filter((pub) => pub.share > 0).map(({ territory, share, ...rest }) => ({ ownedPercentage: share, ...rest })),
            admins: pubPreset.publishers.filter((pub) => pub.territory).map(({ share, ...rest }) => rest),
          };
        } else {
          parsedAccount.pubPreset = {};
        }

        if (alias?.id) {
          parsedAccount.alias = alias;
        } else {
          parsedAccount.alias = null;
        }

        return parsedAccount;
      });
    }
  } catch (err) {
    useErrorOverlay({ errorMessage: `${err.message} when loading workspace accounts` });
  }
}
