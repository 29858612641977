import { workspaceUrl } from '../urls';
import refresh from '../refresh';

export async function getWorkspace(workspaceId) {
  return refresh(async () => {
    const response = await fetch(`${workspaceUrl}?${new URLSearchParams({
      workspace: workspaceId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function postWorkspace(metadata, tagIds, intendedUse, writtenDate, isWriter) {
  const data = {
    metadata,
    tags: tagIds,
    intendedUse,
    written: writtenDate,
    isWriter,
  };
  return refresh(async () => {
    const response = await fetch(workspaceUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function putWorkspace(workspaceId, tagIds, alternateTitles, intendedUse, writtenDate, metadata, hash) {
  const data = {
    workspace: workspaceId,
    tags: tagIds,
    alternateTitles,
    intendedUse,
    written: writtenDate,
    metadata,
    hash,
  };
  return refresh(async () => {
    const response = await fetch(workspaceUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function patchWorkspaceLock(workspaceId) {
  const data = {
    workspace: workspaceId,
  };
  return refresh(async () => {
    const response = await fetch(workspaceUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteWorkspace(workspaceId) {
  const data = {
    workspace: workspaceId,
  };
  return refresh(async () => {
    const response = await fetch(workspaceUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
