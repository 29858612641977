import styled from 'styled-components';
import { useMemo } from 'preact/hooks';
import {
  space, variant, size, typography,
} from 'styled-system';

const StyledBasedButton = styled.button(
  ({
    fluid,
    width,
    maxHeight,
  }) => {
    const btnWidth = useMemo(() => (fluid ? '100%' : width), [fluid]);

    return `
  background: var(--active-nav);
  width: ${btnWidth};
  color: var(--white);
  font-size: 0.938rem;
  font-weight: 600;
  min-height: 2.75em;
  max-height: ${maxHeight || '2.75em'};
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: manipulation;
  ${space}
  ${size}
  ${typography}

  &:disabled {
    cursor: not-allowed;
  }
  &:focus-visible {
    border: 2px solid var(--button-highlight);
  }
  `;
  },
  variant({
    variants: {
      secondary: {
        background: 'transparent',
        border: '1px solid var(--brand-primary)',
        color: 'var(--text-primary)',
      },
      secondaryDanger: {
        background: 'transparent',
        border: '1px solid var(--red-200)',
        color: 'var(--text-primary)',
      },
      emptyState: {
        background: 'var(--empty-state-primary)',
        border: 'none',
        color: 'var(--white)',
        fontSize: '0.875rem',
      },
      danger: {
        background: 'var(--red-200)',
      },
    },
  }),
  space,
  size,
  typography,
);

export default StyledBasedButton;
