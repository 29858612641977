import { accountUserProfileUrl } from '../urls';
import refresh from '../refresh';

export default async function putAccountUserProfile(
  username,
  email,
  legalName,
  country,
  firstName,
  lastName,
) {
  const data = {
    username,
    email,
    legalName,
    country,
    firstName,
    lastName,
  };
  return refresh(async () => {
    const response = await fetch(accountUserProfileUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
