/* eslint-disable object-shorthand */
/* eslint-disable react/style-prop-object */
import anime from 'animejs/lib/anime.es';
import Box from '@ui-kit/box';
import { useEffect, useState } from 'preact/hooks';

function WritersAnimation() {
  const [play, setPlay] = useState(true);
  const [complete, setComplete] = useState(false);
  const [count, setCount] = useState(0);

  const writersAnimation = anime.timeline({
    autoplay: true,
    loop: false,
  });

  const contractAnimation = anime.timeline({
    autoplay: false,
    loop: false,
  });

  const replay = () => {
    if (complete) {
      setComplete(false);
      setPlay(false);

      setTimeout(() => {
        setPlay(true);
        setCount(count + 1);
      }, 300);
    }
  };

  useEffect(() => {
    writersAnimation
      .add({
        targets: '#writer-animation',
        opacity: [0, 1],
        duration: 100,
      })
      .add({
        targets: '#outer-flasher, #inner-flasher',
        scale: anime.stagger([3, 0]),
        duration: 900,
        rotate: [-20, 0],
        easing: 'easeOutSine',
      })
      .add({
        targets: '#core-square',
        opacity: [0, 1],
        scale: [3, 1],
        duration: 1800,
        rotate: [-20, 0],
      }, '-=700')
      .add({
        targets: '#rg',
        translateX: [-138, 0],
        opacity: [0, 1],
        duration: 400,
        easing: 'spring(1, 80, 10, 0)',
      }, 900)
      .add({
        targets: '#lg, #check',
        translateX: [138, 0],
        opacity: [0, 1],
        duration: 400,
        easing: 'spring(1, 80, 10, 0)',
      }, 900)
      .add({
        targets: '#tg, #face',
        translateY: [138, 0],
        opacity: [0, 1],
        duration: 400,
        easing: 'spring(1, 80, 10, 0)',
      }, 900)
      .add({
        targets: '#bg',
        translateY: [-138, 0],
        opacity: [0, 1],
        duration: 400,
        easing: 'spring(1, 80, 10, 0)',
      }, 900)
      .add({
        targets: '#y-axis-line, #x-axis-line',
        scale: [0, 1],
        opacity: [0, 1],
        duration: 400,
        easing: 'spring(1, 80, 10, 0)',
      }, 1200);

    contractAnimation
      .add({
        targets: '#writer-animation',
        opacity: [1, 0],
        duration: 100,
      })
      .add({
        targets: '#contract-animation',
        opacity: [0, 1],
        duration: 100,
      })
      .add({
        targets: '#contract-wrapper',
        scale: [0, 1],
        opacity: [0, 1],
        duration: 900,
      })
      .add({
        targets: '#contract-wrapper .cl, .sig-2',
        translateY: [40, 0],
        opacity: [0, 1],
        duration: 50,
        easing: 'cubicBezier(.5, .05, .1, .3)',
        delay: anime.stagger(100),
      }, '-=600')
      .add({
        targets: '#the-hand',
        translateX: [72, -6],
        translateY: [120, 0],
        easing: 'easeOutSine',
        duration: 700,
      })
      .add({
        targets: '#signature',
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeOutSine',
        duration: 700,
      })
      .add({
        targets: '#the-hand',
        translateX: [0, 56],
        easing: 'easeOutSine',
        duration: 700,
      }, '-=800')
      .add({
        targets: '#the-hand',
        translateY: [0, 120],
        translateX: [56, 156],
        easing: 'easeOutSine',
        duration: 700,
      }, '-=100')
      .add({
        targets: '#focus-corners',
        scale: [0.75, 1],
        easing: 'spring(1, 80, 10, 0)',
        opacity: [0, 1],
        duration: 700,
      }, '-=700')
      .add({
        targets: '#scan-bar',
        opacity: [0, 1],
        duration: 500,
      }, '-=1500')
      .add({
        targets: '#scan-bar',
        translateX: [0, 200],
        duration: 450,
      }, '-=200')
      .add({
        targets: '#scan-bar',
        translateX: [212, 0],
        opacity: [0, 1],
        duration: 450,
      })
      .add({
        targets: '#scan-bar, #focus-corners, #contract-wrapper .cl, #signature, .sig-2',
        opacity: [1, 0],
        duration: 200,
      })
      .add({
        targets: '#contract-wrapper',
        scale: [1, 0.34],
        opacity: [1, 0],
        duration: 900,
      })
      .add({
        targets: '#verified-square',
        scale: [0, 1.5],
        opacity: [0, 1],
        duration: 900,
      }, '-=800')
      .add({
        targets: '#inner-wave, #outer-wave',
        opacity: [0, 1],
        scale: [0, 0.5],
        duration: 100,
      });

    writersAnimation.finished.then(() => {
      writersAnimation.reverse();
      writersAnimation.play();
      writersAnimation.finished.then(() => {
        contractAnimation.play();
        contractAnimation.finished.then(() => {
          anime({
            targets: '#badge',
            scale: [1, 1.25, 1],
            easing: 'spring(1, 80, 10, 0)',
            delay: 4000,
            loop: true,
            duration: 1600,
          });
          anime({
            targets: '#inner-wave',
            scale: [0.5, 1.1],
            loop: true,
            delay: 100,
            duration: 4000,
            opacity: [1, 0.5, 0],
            easing: 'easeOutSine',
          });
          anime({
            targets: '#outer-wave',
            scale: [0.5, 1.8],
            loop: true,
            delay: 100,
            duration: 6000,
            opacity: [1, 0.5, 0],
            easing: 'easeOutSine',
          });
          setComplete(true);
        });
      });
    });
  }, [count]);

  return (
    <Box id="first" onClick={replay}>
      {play
      && (
      <svg xmlns="http://www.w3.org/2000/svg" width="196" height="100%" viewBox="0 0 370 386">
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-38 -8)">
            <g id="animation" transform="translate(38 8)">
              <g id="writer-animation" style="opacity: 0;">
                <rect id="outer-flasher" width="341" height="341" x="14.5" y="17.5" stroke="#5620CA" rx="5" style="transform-box: fill-box; transform-origin: 50% 50%" />
                <rect id="inner-flasher" width="309" height="309" x="30.5" y="33.5" stroke="#5620CA" rx="5" style="transform-box: fill-box; transform-origin: 50% 50%" />
                <path id="x-axis-line" stroke="#9069FF" strokeDasharray="4" d="M76.925 188h219.616" style="transform-box: fill-box; transform-origin: 50% 50%" />
                <path id="y-axis-line" stroke="#9069FF" strokeDasharray="4" d="M186.733 297.808V78.192" style="transform-box: fill-box; transform-origin: 50% 50%" />
                <g transform="translate(278 136)">
                  <g id="rg">
                    <path id="Fill-1" fill="#5620CA" d="m44.021 1.126-42 23.908A4 4 0 0 0 0 28.51v47.718a4 4 0 0 0 2.021 3.477l42 23.907a4 4 0 0 0 3.958 0l42-23.907A4 4 0 0 0 92 76.228V28.51a4 4 0 0 0-2.021-3.476l-42-23.908a4 4 0 0 0-3.958 0Z" />
                    <path id="Path" fill="#C7B6FF" fillRule="nonzero" d="M53.218 38.814a6.792 6.792 0 0 1-4.228 6.328 7.052 7.052 0 0 1-3.952.38 6.895 6.895 0 0 1-3.509-1.877 6.826 6.826 0 0 1 1.057-10.548 6.986 6.986 0 0 1 3.847-1.096 6.813 6.813 0 0 1 4.82 2.004 6.581 6.581 0 0 1 1.52 2.214c.315.829.466 1.71.445 2.595ZM63.046 67.608a3.72 3.72 0 0 1-1.331 1.033 4.005 4.005 0 0 1-1.649.359H32.757a3.808 3.808 0 0 1-2.98-1.392 3.457 3.457 0 0 1-.698-1.393 3.874 3.874 0 0 1 0-1.56c1.69-8.26 8.97-14.192 17.417-14.192 8.447 0 15.727 5.932 17.417 14.191a3.642 3.642 0 0 1 0 1.582c-.175.52-.472.99-.867 1.372Z" />
                    <path id="Combined-Shape-Copy" fill="#355DFF" d="m65.296 57.469 1.176 1.012c.119.104.417.208.573.208h1.265c1.1 0 1.994.893 1.994 1.995v1.265c0 .156.111.454.215.573l1.012 1.176c.625.729.625 1.868 0 2.597L70.52 67.47a1.15 1.15 0 0 0-.215.573v1.265a1.994 1.994 0 0 1-1.994 1.994h-1.265c-.156 0-.454.112-.573.209l-1.176 1.012c-.364.32-.833.476-1.302.476a2 2 0 0 1-1.302-.469l-1.175-1.012c-.12-.104-.417-.208-.573-.208h-1.28a1.994 1.994 0 0 1-1.994-1.995v-1.272c0-.157-.104-.447-.208-.566l-1.004-1.183c-.61-.722-.61-1.86 0-2.582l1.004-1.184c.104-.119.208-.409.208-.565v-1.28c0-1.102.893-1.995 1.994-1.995h1.287c.157 0 .454-.111.573-.208l1.176-1.012a2.032 2.032 0 0 1 2.596 0Z" />
                    <path id="Vector-Copy" fill="#FAFAFA" fillRule="nonzero" d="m62.7 67.195-1.8-1.8a.562.562 0 0 1 0-.79.561.561 0 0 1 .788 0l1.406 1.407 3.2-3.2a.561.561 0 0 1 .788 0 .562.562 0 0 1 0 .789l-3.594 3.594a.557.557 0 0 1-.788 0Z" />
                  </g>
                </g>
                <g transform="translate(0 136)">
                  <g id="lg">
                    <path id="Fill-1" fill="#5620CA" d="m44.021 1.126-42 23.908A4 4 0 0 0 0 28.51v47.718a4 4 0 0 0 2.021 3.477l42 23.907a4 4 0 0 0 3.958 0l42-23.907A4 4 0 0 0 92 76.228V28.51a4 4 0 0 0-2.021-3.476l-42-23.908a4 4 0 0 0-3.958 0Z" />
                    <path id="Path" fill="#C7B6FF" fillRule="nonzero" d="M53.218 38.814a6.792 6.792 0 0 1-4.228 6.328 7.052 7.052 0 0 1-3.952.38 6.895 6.895 0 0 1-3.509-1.877 6.826 6.826 0 0 1 1.057-10.548 6.986 6.986 0 0 1 3.847-1.096 6.813 6.813 0 0 1 4.82 2.004 6.581 6.581 0 0 1 1.52 2.214c.315.829.466 1.71.445 2.595ZM63.046 67.608a3.72 3.72 0 0 1-1.331 1.033 4.005 4.005 0 0 1-1.649.359H32.757a3.808 3.808 0 0 1-2.98-1.392 3.457 3.457 0 0 1-.698-1.393 3.874 3.874 0 0 1 0-1.56c1.69-8.26 8.97-14.192 17.417-14.192 8.447 0 15.727 5.932 17.417 14.191a3.642 3.642 0 0 1 0 1.582c-.175.52-.472.99-.867 1.372Z" />
                    <path id="Combined-Shape-Copy-3" fill="#355DFF" d="m64.296 57.469 1.176 1.012c.119.104.417.208.573.208h1.265c1.1 0 1.994.893 1.994 1.995v1.265c0 .156.111.454.215.573l1.012 1.176c.625.729.625 1.868 0 2.597L69.52 67.47a1.15 1.15 0 0 0-.215.573v1.265a1.994 1.994 0 0 1-1.994 1.994h-1.265c-.156 0-.454.112-.573.209l-1.176 1.012c-.364.32-.833.476-1.302.476a2 2 0 0 1-1.302-.469l-1.175-1.012c-.12-.104-.417-.208-.573-.208h-1.28a1.994 1.994 0 0 1-1.994-1.995v-1.272c0-.157-.104-.447-.208-.566l-1.004-1.183c-.61-.722-.61-1.86 0-2.582l1.004-1.184c.104-.119.208-.409.208-.565v-1.28c0-1.102.893-1.995 1.994-1.995h1.287c.157 0 .454-.111.573-.208l1.176-1.012a2.032 2.032 0 0 1 2.596 0Z" />
                  </g>
                </g>
                <g transform="translate(140)">
                  <g id="tg">
                    <path id="Fill-1" fill="#5620CA" d="m44.021 1.126-42 23.908A4 4 0 0 0 0 28.51v47.718a4 4 0 0 0 2.021 3.477l42 23.907a4 4 0 0 0 3.958 0l42-23.907A4 4 0 0 0 92 76.228V28.51a4 4 0 0 0-2.021-3.476l-42-23.908a4 4 0 0 0-3.958 0Z" />
                    <path id="Path" fill="#C7B6FF" fillRule="nonzero" d="M63.046 67.608a3.72 3.72 0 0 1-1.331 1.033 4.005 4.005 0 0 1-1.649.359H32.757a3.808 3.808 0 0 1-2.98-1.392 3.457 3.457 0 0 1-.698-1.393 3.874 3.874 0 0 1 0-1.56c1.69-8.26 8.97-14.192 17.417-14.192 8.447 0 15.727 5.932 17.417 14.191a3.642 3.642 0 0 1 0 1.582c-.175.52-.472.99-.867 1.372Z" />
                    <path id="Path-4" fill="#4113A4" d="m60.919 57.706 2.384-1.063 2.09 1.584 1.558.19 1.15.13.401.871 1.658 4.153-.144 1.35-1.1 1.438-.414 1.734-.162 1.085-1.39.4-1.916.4-1.07.828-1.774.244-.957-.65-1.496-.822-1.876-.4-.498-1.085-.247-1.734-.989-1.546-.466-1.242.817-.972 1.05-1.587v-1.594l.333-.872.839-.129h1.628l.59-.71Z" />
                    <path id="Combined-Shape" fill="#355DFF" d="m64.296 56.469 1.176 1.012c.119.104.417.208.573.208h1.265c1.1 0 1.994.893 1.994 1.995v1.265c0 .156.111.454.215.573l1.012 1.176c.625.729.625 1.868 0 2.597L69.52 66.47a1.15 1.15 0 0 0-.215.573v1.265a1.994 1.994 0 0 1-1.994 1.994h-1.265c-.156 0-.454.112-.573.209l-1.176 1.012c-.364.32-.833.476-1.302.476a2 2 0 0 1-1.302-.469l-1.175-1.012c-.12-.104-.417-.208-.573-.208h-1.28a1.994 1.994 0 0 1-1.994-1.995v-1.272c0-.157-.104-.447-.208-.566l-1.004-1.183c-.61-.722-.61-1.86 0-2.582l1.004-1.184c.104-.119.208-.409.208-.565v-1.28c0-1.102.893-1.995 1.994-1.995h1.287c.157 0 .454-.111.573-.208l1.176-1.012a2.032 2.032 0 0 1 2.596 0Z" />
                    <path id="Vector" fill="#FAFAFA" fillRule="nonzero" d="m61.7 66.195-1.8-1.8a.562.562 0 0 1 0-.79.561.561 0 0 1 .788 0l1.406 1.407 3.2-3.2a.561.561 0 0 1 .788 0 .562.562 0 0 1 0 .789l-3.594 3.594a.557.557 0 0 1-.788 0Z" />
                    <path id="Path" fill="#C7B6FF" fillRule="nonzero" d="M53.218 38.814a6.792 6.792 0 0 1-4.228 6.328 7.052 7.052 0 0 1-3.952.38 6.895 6.895 0 0 1-3.509-1.877 6.826 6.826 0 0 1 1.057-10.548 6.986 6.986 0 0 1 3.847-1.096 6.813 6.813 0 0 1 4.82 2.004 6.581 6.581 0 0 1 1.52 2.214c.315.829.466 1.71.445 2.595Z" />
                  </g>
                </g>
                <rect id="core-square" width="92" height="92" x="139" y="142" fill="#5620CA" rx="5" style="transform-box: fill-box; transform-origin: 50% 50%" />
                <path id="music-note" fill="#D2C4F1" d="m189.284 171.305 6.463 2.192c2.43.815 4.253 3.4 4.253 6.014v1.742c0 1.77-.718 3.316-1.988 4.243-.801.59-1.768.9-2.79.9a5.88 5.88 0 0 1-1.795-.31l-6.463-2.191-.111-.041v13.08l-.001.028-.003.223c-.13 4.325-3.641 7.815-7.923 7.815-4.363 0-7.926-3.625-7.926-8.065 0-4.44 3.563-8.065 7.926-8.065 1.37 0 2.659.356 3.785.983V176.076l.005-.201c.068-1.66.777-3.099 1.983-3.98 1.27-.927 2.928-1.152 4.585-.59Zm-10.358 21.808c-2.07 0-3.783 1.714-3.783 3.85 0 2.108 1.684 3.85 3.783 3.85 2.072 0 3.784-1.714 3.784-3.85s-1.712-3.85-3.784-3.85Zm8.535-17.902a.681.681 0 0 0-.359.084c-.132.09-.23.305-.263.588.01.076.015.152.015.228v2.02c.137.725.773 1.521 1.408 1.745l6.463 2.192c.359.112.69.112.856 0 .166-.112.276-.422.276-.815v-1.742c0-.787-.718-1.77-1.436-2.024l-6.463-2.192a1.673 1.673 0 0 0-.497-.084Z" />
                <path id="check" fill="#FAFAFA" fillRule="nonzero" d="m61.7 203.195-1.8-1.8a.562.562 0 0 1 0-.79.561.561 0 0 1 .788 0l1.406 1.407 3.2-3.2a.561.561 0 0 1 .788 0 .562.562 0 0 1 0 .789l-3.594 3.594a.557.557 0 0 1-.788 0Z" />
                <g id="BOTTOM-GUY" transform="translate(140 269)">
                  <g id="bg">
                    <path id="Fill-1" fill="#5620CA" d="m44.021 1.126-42 23.908A4 4 0 0 0 0 28.51v47.718a4 4 0 0 0 2.021 3.477l42 23.907a4 4 0 0 0 3.958 0l42-23.907A4 4 0 0 0 92 76.228V28.51a4 4 0 0 0-2.021-3.476l-42-23.908a4 4 0 0 0-3.958 0Z" />
                    <path id="Path" fill="#C7B6FF" fillRule="nonzero" d="M53.218 38.814a6.792 6.792 0 0 1-4.228 6.328 7.052 7.052 0 0 1-3.952.38 6.895 6.895 0 0 1-3.509-1.877 6.826 6.826 0 0 1 1.057-10.548 6.986 6.986 0 0 1 3.847-1.096 6.813 6.813 0 0 1 4.82 2.004 6.581 6.581 0 0 1 1.52 2.214c.315.829.466 1.71.445 2.595ZM63.046 67.608a3.72 3.72 0 0 1-1.331 1.033 4.005 4.005 0 0 1-1.649.359H32.757a3.808 3.808 0 0 1-2.98-1.392 3.457 3.457 0 0 1-.698-1.393 3.874 3.874 0 0 1 0-1.56c1.69-8.26 8.97-14.192 17.417-14.192 8.447 0 15.727 5.932 17.417 14.191a3.642 3.642 0 0 1 0 1.582c-.175.52-.472.99-.867 1.372Z" />
                    <path id="Combined-Shape-Copy-2" fill="#355DFF" d="m67.296 57.469 1.176 1.012c.119.104.417.208.573.208h1.265c1.1 0 1.994.893 1.994 1.995v1.265c0 .156.111.454.215.573l1.012 1.176c.625.729.625 1.868 0 2.597L72.52 67.47a1.15 1.15 0 0 0-.215.573v1.265a1.994 1.994 0 0 1-1.994 1.994h-1.265c-.156 0-.454.112-.573.209l-1.176 1.012c-.364.32-.833.476-1.302.476a2 2 0 0 1-1.302-.469l-1.175-1.012c-.12-.104-.417-.208-.573-.208h-1.28a1.994 1.994 0 0 1-1.994-1.995v-1.272c0-.157-.104-.447-.208-.566l-1.004-1.183c-.61-.722-.61-1.86 0-2.582l1.004-1.184c.104-.119.208-.409.208-.565v-1.28c0-1.102.893-1.995 1.994-1.995h1.287c.157 0 .454-.111.573-.208l1.176-1.012a2.032 2.032 0 0 1 2.596 0Z" />
                    <path id="Vector-Copy-2" fill="#FAFAFA" fillRule="nonzero" d="m64.7 67.195-1.8-1.8a.562.562 0 0 1 0-.79.561.561 0 0 1 .788 0l1.406 1.407 3.2-3.2a.561.561 0 0 1 .788 0 .562.562 0 0 1 0 .789l-3.594 3.594a.557.557 0 0 1-.788 0Z" />
                  </g>
                </g>
              </g>
              <g id="contract-animation" style="opacity: 0;">
                <g transform="translate(45.41 2.155)">
                  <g stroke="#5620CA" transform="translate(0 18.3)">
                    <g id="focus-corners" style="transform-box: fill-box; transform-origin: 50% 50%">
                      <path id="Path-5" d="M227 0h52.322v45.904" />
                      <path id="Path-5-Copy-2" d="M227 289h52.322v45.904" transform="matrix(1 0 0 -1 0 623.904)" />
                      <path id="Path-5-Copy" d="M0 0h52.322v45.904" transform="matrix(-1 0 0 1 52.322 0)" />
                      <path id="Path-5-Copy-3" d="M0 289h52.322v45.904" transform="rotate(180 26.161 311.952)" />
                    </g>
                  </g>
                  <g id="contract" transform="translate(18.59 34.845)">
                    <g id="contract-wrapper" style="transform-box: fill-box; transform-origin: 50% 50%">
                      <rect id="contract-core" width="242" height="302" x="0" y="0" fill="#5620CA" rx="5" style="transform-box: fill-box; transform-origin: 50% 50%" />
                      <g id="Split-Sheet" fill="#DACDFF" fillRule="nonzero" transform="translate(73.576 20.788)">
                        <g id="spit-title" className="cl" style="transform-box: fill-box; transform-origin: 50% 50%">
                          <path id="Path" d="M4.428 13.428c-.732 0-1.494-.138-2.286-.414A6.35 6.35 0 0 1 0 11.754L1.764 9.63c.42.336.873.609 1.359.819.486.21.945.315 1.377.315.468 0 .807-.072 1.017-.216a.699.699 0 0 0 .315-.612c0-.276-.141-.486-.423-.63a13.282 13.282 0 0 0-1.161-.504L2.79 8.19a4.337 4.337 0 0 1-1.647-1.188c-.474-.54-.711-1.23-.711-2.07 0-.672.186-1.281.558-1.827.372-.546.885-.984 1.539-1.314.654-.33 1.407-.495 2.259-.495.672 0 1.353.129 2.043.387.69.258 1.305.651 1.845 1.179L7.128 4.806a5.619 5.619 0 0 0-1.143-.63 3.241 3.241 0 0 0-1.197-.216c-.372 0-.669.066-.891.198a.647.647 0 0 0-.333.594c0 .276.156.489.468.639.312.15.732.327 1.26.531l1.422.558c.732.288 1.296.69 1.692 1.206.396.516.594 1.182.594 1.998 0 .66-.177 1.275-.531 1.845-.354.57-.873 1.029-1.557 1.377-.684.348-1.512.522-2.484.522Z" />
                          <path id="Shape" d="M11.32 16.38V4.212h2.52l.216.828h.072a4.209 4.209 0 0 1 1.143-.756 3.146 3.146 0 0 1 1.305-.288c.708 0 1.323.189 1.845.567.522.378.927.909 1.215 1.593.288.684.432 1.488.432 2.412 0 1.032-.18 1.911-.54 2.637-.36.726-.825 1.278-1.395 1.656-.57.378-1.173.567-1.809.567-.744 0-1.416-.3-2.016-.9l.108 1.368v2.484H11.32Zm4.248-5.472c.36 0 .672-.168.936-.504.264-.336.396-.936.396-1.8 0-.744-.105-1.278-.315-1.602-.21-.324-.525-.486-.945-.486-.228 0-.438.048-.63.144-.192.096-.39.264-.594.504v3.312c.18.156.369.267.567.333.198.066.393.099.585.099Z" />
                          <path id="Path" d="M25.232 13.428c-.696 0-1.245-.141-1.647-.423-.402-.282-.69-.678-.864-1.188-.174-.51-.261-1.101-.261-1.773V.684h3.096v9.468c0 .3.057.507.171.621.114.114.225.171.333.171.06 0 .111-.003.153-.009l.171-.027.36 2.268a2.69 2.69 0 0 1-.612.18 4.878 4.878 0 0 1-.9.072Z" />
                          <path id="Shape" d="M28.524 13.212v-9h3.096v9h-3.096Zm1.548-10.116c-.504 0-.912-.141-1.224-.423-.312-.282-.468-.657-.468-1.125 0-.468.156-.843.468-1.125.312-.282.72-.423 1.224-.423s.912.141 1.224.423c.312.282.468.657.468 1.125 0 .468-.156.843-.468 1.125-.312.282-.72.423-1.224.423Z" />
                          <path id="Path" d="M38.116 13.428c-1.164 0-2.007-.339-2.529-1.017-.522-.678-.783-1.563-.783-2.655V6.624h-1.188V4.32l1.368-.108.36-2.34H37.9v2.34h2.052v2.412H37.9v3.078c0 .492.108.834.324 1.026.216.192.468.288.756.288.144 0 .285-.015.423-.045s.261-.063.369-.099l.468 2.232a9.002 9.002 0 0 1-.864.216 6.318 6.318 0 0 1-1.26.108ZM50.568 13.428c-.732 0-1.494-.138-2.286-.414a6.35 6.35 0 0 1-2.142-1.26l1.764-2.124c.42.336.873.609 1.359.819.486.21.945.315 1.377.315.468 0 .807-.072 1.017-.216a.699.699 0 0 0 .315-.612c0-.276-.141-.486-.423-.63a13.282 13.282 0 0 0-1.161-.504L48.93 8.19a4.337 4.337 0 0 1-1.647-1.188c-.474-.54-.711-1.23-.711-2.07 0-.672.186-1.281.558-1.827.372-.546.885-.984 1.539-1.314.654-.33 1.407-.495 2.259-.495.672 0 1.353.129 2.043.387.69.258 1.305.651 1.845 1.179l-1.548 1.944a5.619 5.619 0 0 0-1.143-.63 3.241 3.241 0 0 0-1.197-.216c-.372 0-.669.066-.891.198a.647.647 0 0 0-.333.594c0 .276.156.489.468.639.312.15.732.327 1.26.531l1.422.558c.732.288 1.296.69 1.692 1.206.396.516.594 1.182.594 1.998 0 .66-.177 1.275-.531 1.845-.354.57-.873 1.029-1.557 1.377-.684.348-1.512.522-2.484.522ZM57.46 13.212V.684h3.096V3.6l-.144 1.548a6.486 6.486 0 0 1 1.125-.783c.438-.246.963-.369 1.575-.369.996 0 1.713.333 2.151.999.438.666.657 1.557.657 2.673v5.544h-3.096V8.064c0-.576-.072-.96-.216-1.152-.144-.192-.372-.288-.684-.288-.288 0-.528.06-.72.18-.192.12-.408.288-.648.504v5.904H57.46Z" />
                          <path id="Shape" d="M72.812 13.428c-.876 0-1.665-.186-2.367-.558a4.134 4.134 0 0 1-1.665-1.611c-.408-.702-.612-1.551-.612-2.547 0-.972.207-1.809.621-2.511A4.464 4.464 0 0 1 70.4 4.572a4.09 4.09 0 0 1 2.088-.576c.888 0 1.623.195 2.205.585.582.39 1.014.915 1.296 1.575.282.66.423 1.392.423 2.196 0 .264-.015.519-.045.765a6.23 6.23 0 0 1-.081.531h-5.13c.144.54.402.915.774 1.125.372.21.81.315 1.314.315.576 0 1.188-.18 1.836-.54l1.008 1.836c-.48.336-1.02.594-1.62.774-.6.18-1.152.27-1.656.27ZM71.12 7.632h2.664c0-.348-.087-.651-.261-.909-.174-.258-.495-.387-.963-.387-.336 0-.636.102-.9.306-.264.204-.444.534-.54.99ZM82.98 13.428c-.876 0-1.665-.186-2.367-.558a4.134 4.134 0 0 1-1.665-1.611c-.408-.702-.612-1.551-.612-2.547 0-.972.207-1.809.621-2.511a4.464 4.464 0 0 1 1.611-1.629 4.09 4.09 0 0 1 2.088-.576c.888 0 1.623.195 2.205.585.582.39 1.014.915 1.296 1.575.282.66.423 1.392.423 2.196 0 .264-.015.519-.045.765a6.23 6.23 0 0 1-.081.531h-5.13c.144.54.402.915.774 1.125.372.21.81.315 1.314.315.576 0 1.188-.18 1.836-.54l1.008 1.836c-.48.336-1.02.594-1.62.774-.6.18-1.152.27-1.656.27Zm-1.692-5.796h2.664c0-.348-.087-.651-.261-.909-.174-.258-.495-.387-.963-.387-.336 0-.636.102-.9.306-.264.204-.444.534-.54.99Z" />
                          <path id="Path" d="M92.5 13.428c-1.164 0-2.007-.339-2.529-1.017-.522-.678-.783-1.563-.783-2.655V6.624H88V4.32l1.368-.108.36-2.34h2.556v2.34h2.052v2.412h-2.052v3.078c0 .492.108.834.324 1.026.216.192.468.288.756.288.144 0 .285-.015.423-.045s.261-.063.369-.099l.468 2.232a9.002 9.002 0 0 1-.864.216 6.318 6.318 0 0 1-1.26.108Z" />
                        </g>
                      </g>
                      <path id="Fill-16" className="cl" fill="#705DFF" d="M73.532 70.853h134.159c3.648 0 3.648-5.673 0-5.673H73.532c-3.648 0-3.648 5.673 0 5.673" />
                      <path id="Fill-17" className="cl" fill="#705DFF" d="M31.136 104.463H207.69c3.648 0 3.648-5.673 0-5.673H31.136c-3.648 0-3.648 5.673 0 5.673" />
                      <path id="Fill-18" className="cl" fill="#705DFF" d="M31.136 138.073H207.69c3.648 0 3.648-5.673 0-5.673H31.136c-3.648 0-3.648 5.673 0 5.673" />
                      <path id="Fill-19" className="cl" fill="#705DFF" d="M31.136 171.682H207.69c3.648 0 3.648-5.672 0-5.672H31.136c-3.648 0-3.648 5.672 0 5.672" />
                      <path id="Fill-20" className="cl" fill="#705DFF" d="M31.136 205.292H207.69c3.648 0 3.648-5.673 0-5.673H31.136c-3.648 0-3.648 5.673 0 5.673" />
                      <path id="Fill-21" className="cl" fill="#705DFF" d="M31.067 284.653h75.464c2.053 0 2.053-3.191 0-3.191H31.067c-2.052 0-2.052 3.19 0 3.19" />
                      <path id="Fill-21-Copy" className="cl" fill="#705DFF" d="M135.067 284.653h75.464c2.053 0 2.053-3.191 0-3.191h-75.464c-2.052 0-2.052 3.19 0 3.19" />
                    </g>
                  </g>
                  <path id="scan-bar" fill="#355DFF" fillRule="nonzero" d="M37.822 0a1 1 0 0 1 .993.883l.007.117v370.583a1 1 0 0 1-1.993.117l-.007-.117V1a1 1 0 0 1 1-1Z" />
                  <path id="signature" stroke="#B297FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M71.278 307.3c-1.798 14.35 0 23.614 3.075 27.99 5.115-9.332 1.595-20.68 0-31.08 2.42-4.17 5.721-8.38 7.073-13.307 1.503-5.482 2.396-12.397 1.168-18.09-2.886 2.771-5.916 10.895-9.283 31.397l2.637 6.706c4.372-2.416 10.244-.804 10.442-14.442-1.811 1.022-2.812 1.022-3.568 5.513-.756 4.491.756 7.682 3.568 6.035 2.812-1.647 5.79-.86 3.75-8.258-1.917 4.078-1.64 7.398 3.768 8.946 2.15-.825 3.196-3.628 3.225-8.251 1.036-.536 2.644.243 3.175 1.528.545 1.317 0 3.174 0 5.313 0 1.252 2.743.137 3.093-.537l.741-1.43 1.907 1.967a7.745 7.745 0 0 0 2.977.067c1.021-.17 2.413-.587 4.175-1.254" />
                </g>
                <path id="REPLACEMEMT-SIGNATURE" className="sig-2" stroke="#B297FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" d="M211 315.253c0-8.245 12.249-15.822 15.146-30.936 2.897-15.114-15.99-.495-12.884 16.454 3.106 16.948 15.77-14.796 18.795 2.265 3.025 17.06-11.088 15.929-12.98 10.043-1.893-5.885 17.063-13.933 18.71-10.043 1.647 3.89.943 11.226-3.228 11.226-4.17 0-2.765-6.18 1.245-10.003 4.01-3.823 8.475 4.813 8.866 8.82.391 4.007.9-4.153 5.01-5.293 4.11-1.14 5.764 1.765 6.32 6.476" />
                <g transform="translate(107.05 271.265)">
                  <g id="the-hand">
                    <path id="Fill-22" fill="#A386E2" d="M68.139 13.365c-8.856-.372-18.123-.328-25.754 3.185-7.63 3.513-12.924 11.55-9.687 18.752 1.233 2.744 20.61 7.752 22.854 10.01 17.186 17.284 17.284 31.881 34.47 49.167 7.41-3.829 11.535-10.952 14.213-18.065 2.313-6.148 3.81-12.528 5.302-18.892 2.319-9.891 4.416-20.933-1.93-29.83-7.834-10.98-24.784-13.708-39.468-14.327" />
                    <path id="Fill-23" fill="#C4A9FF" d="m136.6 75.357-3.988-38.109-46.57-34.603-57.629 18.478s-6.41 7.868 2.637 10.892c9.047 3.023 53.277-3.024 53.277-3.024l15.079 36.286-9.455 47.303h58.59l-6.915-14.04-5.026-23.183Z" />
                    <path id="Fill-24" fill="#E8E0FF" d="m3.515 45.55-3.29-.166c-.252-.012-.306-.229-.092-.363L3.363 43l9.776-3.348 1.165 3.862-10.79 2.037" />
                    <path id="Fill-25" fill="#391C78" d="M9.16 45.35c-3.125.158-4.036-3.136-1.259-4.542l11.381-5.763L145.355 2.914l2.988 12.39-126.726 29.42-12.457.627Z" />
                    <path id="Fill-26" fill="#9069FF" d="m146.736 16.196-3.301-13.543 10.5-2.574c1.666-.408 3.393.807 3.857 2.713l1.619 6.638c.464 1.907-.509 3.783-2.173 4.191l-10.502 2.575" />
                    <path id="Fill-27" fill="#9069FF" d="m154.753 10.13-1.415-5.804 9.894-2.426c1.569-.384 3.002-.033 3.201.784l.694 2.845c.2.817-.911 1.79-2.48 2.175l-9.894 2.426" />
                    <path id="Fill-28" fill="#9069FF" d="m121.265 18.775-.329-1.35c-.143-.589.343-1.214 1.086-1.396l24.322-5.962c.743-.183 1.462.147 1.606.736l.329 1.35c.144.589-.343 1.214-1.086 1.396l-24.321 5.963c-.744.182-1.463-.148-1.607-.737" />
                    <path id="Fill-29" fill="#355DFF" d="m79.95 31.81-2.956-12.124 4.394-1.078 2.956 12.125-4.394 1.077" />
                    <path id="Fill-30" fill="#C4A9FF" d="M89.542 112.58c-7.371-7.273-11.892-13.064-20.07-19.41-6.356-4.933-16.6-14.263-16.69-15.314-.252-2.982-1.462-5.794-1.829-8.765l-3.007-24.315c-.77-6.236-1.98-19.395 5.894-22.016 8.192-2.727 12.462 8.518 12.86 14.682.367 5.707-.353 11.709 1.996 16.921 2.898 6.435 9.653 9.994 15.315 14.192 14.061 10.423 19.903 1.71 23.55 44.025H89.542Z" />
                    <path id="Fill-33" fill="#5620CA" d="M128.541 112.58h47l-16.828-23s-13.601 10.68-30.172 23Z" />
                  </g>
                </g>
                <g transform="translate(46 0)">
                  <circle id="outer-wave" stroke="#5620CA" cx="139.090515" cy="186.344788" r="137" style="transform-box: fill-box; transform-origin: 50% 50%;" />
                  <circle id="inner-wave" stroke="#5620CA" strokeWidth="0.89" cx="139.090515" cy="186.344788" r="122.055" style="transform-box: fill-box; transform-origin: 50% 50%;" />
                </g>
                <g transform="translate(128 133)">
                  <g id="verified-square" style="transform-box: fill-box; transform-origin: 50% 50%;">
                    <rect id="s2" width="92" height="92" x="11" y="9" fill="#5620CA" rx="5" />
                    <path id="music-note" fill="#EDE5FF" d="m61.284 38.305 6.463 2.192c2.43.815 4.253 3.4 4.253 6.014v1.742c0 1.77-.718 3.316-1.988 4.243-.801.59-1.768.9-2.79.9a5.88 5.88 0 0 1-1.795-.31l-6.463-2.191-.111-.041v13.08l-.001.028-.003.223C58.72 68.51 55.208 72 50.926 72 46.563 72 43 68.375 43 63.935c0-4.44 3.563-8.065 7.926-8.065 1.37 0 2.659.356 3.785.983V43.076l.005-.201c.068-1.66.777-3.099 1.983-3.98 1.27-.927 2.928-1.152 4.585-.59ZM50.926 60.113c-2.07 0-3.783 1.714-3.783 3.85 0 2.108 1.684 3.85 3.783 3.85 2.072 0 3.784-1.714 3.784-3.85s-1.712-3.85-3.784-3.85Zm8.535-17.902a.681.681 0 0 0-.359.084c-.132.09-.23.305-.263.588.01.076.015.152.015.228v2.02c.137.725.773 1.521 1.408 1.745l6.463 2.192c.359.112.69.112.856 0 .166-.112.276-.422.276-.815v-1.742c0-.787-.718-1.77-1.436-2.024l-6.463-2.192a1.673 1.673 0 0 0-.497-.084Z" />
                    <g id="GroupX" transform="translate(86)">
                      <g id="badge" style="transform-box: fill-box; transform-origin: 50% 50%;">
                        <path id="Path-766" fill="#355DFF" d="m8.859.654 4.16 1.624h.227L17.417 0l2.814 4.062 4.403.411v5.064L28 12.764l-2.77 3.449.625 4.968-4.207 1.092-2.412 4.466-4.366-1.483-4.18 2.109-2.66-3.899-4.63-.583v-4.908L0 14.589l2.855-3.448-.809-4.685 4.616-1.554z" />
                        <ellipse id="Oval6" cx="14.022" cy="13.768" stroke="#FAF8FF" strokeWidth="1.4" rx="7.606" ry="7.691" />
                        <path id="Path-86" fill="#FAF8FF" d="M9.998 14.597c1.655 2.29 2.65 3.372 2.986 3.244.336-.129 2.168-2.29 5.494-6.487-.04-.632-.159-1.194-1.071-1.051l-4.423 4.457h-.197l-1.731-1.214c-.874-.195-1.274.106-1.058 1.05Z" />
                        <path id="Path-96" fill="#355DFF" d="m6.065 26.912 1.796-1.099 2.706 3.963 4.313-2.411 4.574 1.589 1.803-2.929.601.302L22.065 43l-8.043-6.805L6.065 43z" />
                      </g>
                    </g>
                    <path id="Rectangle5" d="M0 8h11v93H0z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      )}
    </Box>
  );
}

export default WritersAnimation;
