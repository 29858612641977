/* eslint-disable import/prefer-default-export */
import { publisherIdentityUrl } from '../urls';

export async function getPublisherIdentity(uuid) {
  const url = `${publisherIdentityUrl}?${new URLSearchParams({
    uuid,
  })}`;
  const response = await fetch(url, {
    method: 'GET',
  });
  return response;
}
