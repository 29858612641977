/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
// import AppState from '@state';

import HeaderCard from '@distinct-components/cards/headerCard';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import WriterCompositionSummaryCard from '@distinct-components/cards/writerCompositionSummaryCard';

// import {
//   CompositionSectionsGrid,
// } from './ViewCompositionWritersStyles';

function ViewCompositionWriters({ writers, handleSelectedWriter }) {
  return (
    <HeaderCard mb="1.5em" headerText={`${writers.length} Songwriters`}>
      <Box mb="1.5em" mt="0.5em">
        <Text mb="0.75em" color="var(--text-medium-mute)">
          Controlled:
          {' '}
          {(writers.filter((w) => w.isControlled === true)).length}
        </Text>
        {(writers.filter((w) => w.isControlled === true)).map((writer) => (
          <WriterCompositionSummaryCard mb="1.5em" handleSelectedWriter={handleSelectedWriter} writer={writer} />
        ))}
      </Box>
      <Box mb="1.5em">
        <Text mb="0.75em" color="var(--text-medium-mute)">
          Not Controlled:
          {' '}
          {(writers.filter((w) => w.isControlled === false)).length}
        </Text>
        {(writers.filter((w) => w.isControlled === false)).map((writer) => (
          <WriterCompositionSummaryCard mb="1.5em" writer={writer} />
        ))}
      </Box>
    </HeaderCard>
  );
}

ViewCompositionWriters.propTypes = {
  writers: PropTypes.array.isRequired,
  handleSelectedWriter: PropTypes.func.isRequired,
};

export default ViewCompositionWriters;
