import { workspaceContractUrl } from '../urls';
import refresh from '../refresh';

export default async function getWorkspaceContractVersions(workspaceId) {
  return refresh(async () => {
    const response = await fetch(`${workspaceContractUrl}?${new URLSearchParams({
      workspace: workspaceId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}
