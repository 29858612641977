/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useRef } from 'preact/hooks';
import AppState from '@state/AppState';
import SignatureCanvas from 'react-signature-canvas';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import BaseButton from '@ui-kit/buttons/baseButton';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import Icon from '@ui-kit/icon';
import BrushIcon from '@assets/icons/draw-brush.svg';
import KeyboardIcon from '@assets/icons/keyboard.svg';
import TrashIcon from '@assets/icons/trash-outline.svg';

import {
  SignatureWrapper,
  CreateSignatureWrapper,
  SignatureLine,
} from './SingleSignatureBlockStyles';

function ContractSignatureBlock({
  handleSignature,
  signatureError,
  isLocking,
}) {
  const [isSigning, setIsSigning] = useState(false);
  const [isDrawingSignature, setIsDrawingSignature] = useState(true);
  const [textSignature, setTextSignature] = useState(null);
  const sigCanvas = useRef({});
  const [compState, setCompState] = useState({
    loading: false,
    error: null,
  });

  const onToggleSignSignature = () => {
    setIsSigning(!isSigning);
    setIsDrawingSignature(true);
    setTextSignature(null);
    setCompState({ loading: false, error: null });
  };

  const onClickTrashSignatures = () => {
    sigCanvas.current.clear();
  };

  const onSaveSignature = () => {
    setCompState({ loading: true });
    if (isDrawingSignature && sigCanvas.current.isEmpty()) {
      setCompState({ loading: false, error: 'Draw a signature in the box to sign' });
      return;
    }
    if (textSignature !== AppState.userProfile.legalName.value && !isDrawingSignature) {
      setCompState({ loading: false, error: `You need to enter your legal name: ${AppState.userProfile.legalName.value}` });
      return;
    }

    let signaturePng = null;
    if (isDrawingSignature) {
      const trimmed = sigCanvas.current.getTrimmedCanvas();
      signaturePng = trimmed.toDataURL();
    }
    if (isDrawingSignature) {
      handleSignature(signaturePng, null);
    } else {
      handleSignature(null, textSignature);
    }
    setCompState({ loading: false });
    onToggleSignSignature();
  };

  const onToggleDrawSignature = () => {
    setIsDrawingSignature(!isDrawingSignature);
    setTextSignature(null);
    setCompState({ loading: false, error: null });
  };

  return (
    <SignatureWrapper>
      {isDrawingSignature
        ? (
          <CreateSignatureWrapper>
            <SignatureCanvas
              ref={sigCanvas}
              penColor="#9069FF"
              canvasProps={{ width: 320, height: 130, className: 'sigCanvas' }}
            />
            <SignatureLine />
          </CreateSignatureWrapper>
        )
        : (
          <BaseInput
            placeholder={`Enter "${AppState.userProfile.legalName.value}" to save`}
            type="text"
            value={textSignature}
            onChange={(e) => setTextSignature((e.target.value))}
            fluid
            mb="0.75em"
          />
        )}
      <Box mb="1.25em">
        {isDrawingSignature
          ? (
            <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
              <Icon size="1.5em" mr="1em" cursor><KeyboardIcon onClick={onToggleDrawSignature} /></Icon>
              <Icon size="1.25em" mr="0.25em" cursor><TrashIcon onClick={onClickTrashSignatures} /></Icon>
            </Box>
          )
          : (
            <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
              <Icon size="1.25em" mr="1em" cursor><BrushIcon onClick={onToggleDrawSignature} /></Icon>
            </Box>
          )}
      </Box>
      <Box mb="1em" display="flex" justifyContent="center">
        <Text fontSize="0.75rem" color="var(--text-medium-mute)">
          By Signing You Affirm You Agree to Our
          {' '}
          <a href="https://switchchord.com/terms" target="blank">Terms of Use</a>
        </Text>
      </Box>
      {compState.error
            && (
            <AlertMessage
              variant="negative"
              message={compState.error}
              mb="1.5em"
            />
            )}
      {signatureError
            && (
            <AlertMessage
              variant="negative"
              message={signatureError}
              mb="1.5em"
            />
            )}
      <BaseButton
        btnText="Sign & Finalize"
        fluid
        onClick={onSaveSignature}
        isLoading={compState.loading || isLocking}
      />
    </SignatureWrapper>
  );
}

ContractSignatureBlock.propTypes = {
  handleSignature: PropTypes.func.isRequired,
  signatureError: PropTypes.string,
  isLocking: PropTypes.bool.isRequired,
};

ContractSignatureBlock.defaultProps = {
  signatureError: null,
};

export default ContractSignatureBlock;
