/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import { useForm } from 'react-hook-form';
import AppState from '@state';
import dayjs from 'dayjs';

import useIsoDateFormat from '@hooks/useIsoDateFormat';
import { putWorkspace } from '@api/restricted/workspace-api';
import loadComposition from '@actions/loadComposition';

import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import Span from '@ui-kit/typography/span';
import Text from '@ui-kit/typography/text';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import Icon from '@ui-kit/icon';
import EditIcon from '@assets/icons/edit-line.svg';
import LockPrompt from '@distinct-components/feedback/lockPrompt';
import Card from '@distinct-components/cards/baseCard';
import ItemListInput from '@ui-kit/inputs/itemListInput/ItemListInput';
import TagTypeAhead from '@distinct-components/typeAheadInputs/tagTypeAhead';

import WorkspaceEditOverlay from '@layouts/full-screen/workspaceEditOverlay';

import { COMPOSITION_EXPLANATIONS } from '@constants/supportingCopy';

import CompositionOwnershipChart from './CompositionOwnershipChart/CompositionOwnershipChart';
import CompositionSignatureStatus from './CompositionSignatureStatus/CompositionSignatureStatus';

import { StyledWorkspaceTitle, StyledMetadata, InputLockWrapper } from './CompositionDetailsStyles';

function CompositionDetailsSection({ workspaceId, activeUser }) {
  const [isEditing, setEditMode] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alternateTitles, setAlternateTitles] = useState(AppState.composition.alternateTitles.value);
  const [tags, setTags] = useState(AppState.composition.tags.value);

  const TOTAL_WRITERS = AppState.workspaceAccounts.value.filter((account) => account.isWriter).length;

  const d = useIsoDateFormat;
  const TODAY_DATE = dayjs().format().substring(0, 10);

  const {
    register, handleSubmit, reset, formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const datePickerDate = AppState.composition.date.value.substring(0, 10);

  const handleEditSection = () => {
    reset({
      compositionName: AppState.composition.name.value,
      intendedUse: AppState.composition.intendedUse.value,
      compositionDate: datePickerDate,
    });
    setTags(AppState.composition.tags.value);
    setAlternateTitles(AppState.composition.alternateTitles.value);
    setEditMode(!isEditing);
    AppState.workspace.isEditingChild.value = true;
  };

  const handleUpdateAlternateTitles = (titles) => {
    setAlternateTitles(titles);
  };

  const handleCancelEdit = () => {
    reset({
      compositionName: AppState.composition.name.value,
      intendedUse: AppState.composition.intendedUse.value,
      compositionDate: datePickerDate,
    });
    setAlternateTitles(AppState.composition.alternateTitles.value);
    setEditMode(!isEditing);
    AppState.workspace.isEditingChild.value = false;
  };

  const handleSaveDetails = (data) => {
    setError('');
    const selectedDate = data.compositionDate;
    const utcDate = dayjs(selectedDate).format();

    const tagIds = tags.map((tag) => tag.id);

    const cleanedAlternateTitles = alternateTitles.map((title) => title.trim()).filter((title) => title);

    const cleanedData = {
      compositionName: data.compositionName?.trim(),
    };

    if (!cleanedData.compositionName) {
      setError('Invalid composition name');
    } else {
      setIsLoading(true);
      putWorkspace(workspaceId, tagIds, cleanedAlternateTitles, data.intendedUse, utcDate, cleanedData, AppState.composition.hash.value)
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200 || response.status === 202) {
            loadComposition(workspaceId);
            setEditMode(!isEditing);
            setError('');
            AppState.workspace.isEditingChild.value = false;
          } else {
            response.json().then((json) => {
              setError(json.error ? json.error : response.statusText);
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err.message);
        });
    }
  };

  return (
    <Box display="flex">

      <Card mb="1.5em" p="1.5em" minHeight="9em" variant="overflow">
        <Box display="flex" justifyContent="center" style={{ position: 'relative', textAlign: 'center' }}>
          <Box>
            {AppState.composition.isLocked.value
              ? (
                <CompositionSignatureStatus />
              ) : (
                <CompositionOwnershipChart percentage={1} />
              )}
            <StyledWorkspaceTitle>{AppState.composition.name.value}</StyledWorkspaceTitle>
            <StyledMetadata>
              Written:
              {' '}
              {d(AppState.composition.date.value)}
            </StyledMetadata>
          </Box>
          <Box style={{ position: 'absolute', right: 0, top: 0 }}>
            {(!activeUser.isCanEdit)
              ? (
                <LockPrompt
                  lockPrompt={COMPOSITION_EXPLANATIONS.locks.manageSectionBlocked}
                />
              )
              : (
                <Icon size="1em" cursor onClick={handleEditSection}><EditIcon /></Icon>
              )}
          </Box>
        </Box>
      </Card>

      {isEditing
      && (
      <WorkspaceEditOverlay
        closeFunction={handleCancelEdit}
      >
        <Text fontSize="1.5em" fontFamily="var(--font-secondary)" fontWeight="800">Composition Details</Text>
        <Box mb="1.5em">
          <Span>id:</Span>
          <Span fontSize="0.813rem">{AppState.composition.uuid.value}</Span>
        </Box>
        <form id="createCompositionForm" onSubmit={handleSubmit(handleSaveDetails)} autoComplete="on">
          <InputLabel label="Composition Name" />
          <Box style={{ position: 'relative' }}>
            <BaseInput
              {...register('compositionName', { required: true })}
              id="compositionName"
              name="compositionName"
              placeholder="Name of the song written"
              type="text"
              disabled={AppState.composition.isLocked.value}
              fluid
              pr={AppState.composition.isLocked.value ? '2.5em' : '1.125em'}
              mb="1.5em"
            />
            {AppState.composition.isLocked.value
            && (
            <InputLockWrapper>
              <LockPrompt lockPrompt={TOTAL_WRITERS > 1
                ? COMPOSITION_EXPLANATIONS.locks.workspaceLocked
                : COMPOSITION_EXPLANATIONS.locks.workspaceLockedNoContract}
              />
            </InputLockWrapper>
            )}
          </Box>
          <InputLabel label="Alternate Titles" />
          <ItemListInput
            mb="1.5em"
            placeholder="Alternate Title..."
            handleListChange={handleUpdateAlternateTitles}
            listItems={alternateTitles}
            itemLimit={10}
          />
          <TagTypeAhead
            selectedTags={AppState.composition.tags.value}
            handleChange={(data) => setTags(data)}
          />
          <InputLabel label="Intended Use" />
          <BaseSelect
            {...register('intendedUse', { required: true })}
            id="intendedUse"
            name="intendedUse"
            fluid
            mb="1.75rem"
          >
            <option value="General Usage">General Usage</option>
            <option value="Library">Music Library</option>
            <option value="Film">Film / Movie</option>
            <option value="Television">Television</option>
            <option value="Commercial (Jingle, Promo, Trailer)">Commercial (Jingle, Promo, Trailer)</option>
            <option value="Theatre">Theatre</option>
          </BaseSelect>
          <InputLabel label="Date Written" />
          <BaseInput
            {...register('compositionDate', { required: true })}
            id="compositionDate"
            name="compositionDate"
            type="date"
            max={TODAY_DATE}
            fluid
            mb="2.5em"
            required
          />

          {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}

          <BaseButton
            mb="1.5em"
            type="submit"
            btnText="Save Changes"
            disabled={!isValid}
            fluid
            isLoading={isLoading}
          />
        </form>
      </WorkspaceEditOverlay>
      )}

    </Box>
  );
}

CompositionDetailsSection.propTypes = {
  workspaceId: PropTypes.number.isRequired,
  activeUser: PropTypes.object.isRequired,
};

export default CompositionDetailsSection;
