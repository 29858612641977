/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
  useLayoutEffect,
  // useLayoutEffect,
  useRef,
  useState,
} from 'preact/hooks';

import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import Text from '@ui-kit/typography/text';
import SearchInput from '@ui-kit/inputs/searchInput';
import ClearIcon from '@assets/icons/close-solo.svg';
import AscendingIcon from '@assets/icons/sort-oldest-newest.svg';
import DescendingIcon from '@assets/icons/sort-newest-oldest.svg';
import FilterIcon from '@assets/icons/filter-outline.svg';

import {
  TableSearchWrapper,
  ClearSearchChip,
  ControlsWrapper,
  TableFilterButton,
  TableFilterIndicator,
  StyledSortSelect,
  FeaturedToggleWrapper,
  ToggleOption,
  StyledSortButton,
  SortWrapper,
} from './TableControlsAdvancedStyles';

import TableFilterModal from './sections/tableFilterModal';

function TableControlsAdvanced({
  handleSearch,
  filterConfig,
  sortConfig,
  toggleConfig,
  activeConfig,
  viewingLabel,
  initialSearchValue,
}) {
  const [searchActive, setSearchActive] = useState(!!initialSearchValue);
  const [showFilterModal, setShowFilterModal] = useState();

  const activeFilterCount = Object.values(activeConfig.filters).filter((f) => f.length > 0).length;

  const searchRef = useRef();

  useLayoutEffect(() => {
    if (initialSearchValue.length > 0) {
      setSearchActive(true);
      searchRef.current.base.value = initialSearchValue;
    }
  }, [initialSearchValue]);

  const handleSearchInputChange = (query) => {
    if (query?.length > 0) {
      setSearchActive(true);
    } else {
      setSearchActive(false);
    }
    handleSearch(query);
  };

  const handleClearSearch = () => {
    searchRef.current.base.value = '';
    handleSearch();
    setSearchActive(false);
  };

  const onChangeSelectSort = (event) => {
    const selection = event.target.value;
    sortConfig.handleToggle(selection);
    setTimeout(() => {
      event.target.blur();
    }, 100);
  };

  const onToggleSortOrder = () => {
    sortConfig.handleSortOrder();
  };

  const onClickFeaturedToggle = (value) => {
    toggleConfig.handleToggle(value);
  };

  const onToggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  return (
    <Box mt="1.5em" mb="1.25em" display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" style={{ gap: '1em' }}>

      {/* VIEWING LABEL IS NOW CONTROLLED IN THE COMPONENT TO GIVE MORE CONTROL OVER WHAT IS BEING USED */}
      <Box display="flex" flexGap="0.5em">
        {/* SHOULD FOLLOW PATTERN:  Viewing X - Y of totalCount THINGS or Viewing X THINGS */}
        <Text>
          {viewingLabel}
        </Text>
        {activeFilterCount > 0 && (
          <Box display="flex">
            <Text pl="0.75em" style={{ opacity: 0.8 }}>
              (&nbsp;Active Filters:
              {' '}
              {activeFilterCount}
            </Text>
            <LinkButton btnText="Clear" pl="0.5em" pr="0.25em" onClick={filterConfig.handleResetFilters} style={{ opacity: 0.8 }} />
            <Text>)</Text>
          </Box>
        )}
      </Box>

      <ControlsWrapper>

        {toggleConfig
        && (
        <FeaturedToggleWrapper>
          {toggleConfig.options.map((c) => (
            <ToggleOption
              value={c.value}
              isSelected={c.value === activeConfig.toggle}
              onClick={() => onClickFeaturedToggle(c.value)}
            >
              {c.name}
            </ToggleOption>
          ))}
        </FeaturedToggleWrapper>
        )}

        {sortConfig
        && (
        <SortWrapper>
          <StyledSortButton onClick={onToggleSortOrder}>
            {activeConfig.isAscending
              ? <Icon size="0.875em" cursor style={{ transform: 'translate(1px, 1px)' }}><AscendingIcon /></Icon>
              : <Icon size="0.875em" cursor style={{ transform: 'translate(1px, 1px)' }}><DescendingIcon /></Icon>}
          </StyledSortButton>
          <StyledSortSelect
            defaultValue={activeConfig.sort}
            onChange={onChangeSelectSort}
            id="sorter"
            name="sorter"
          >
            {sortConfig.options.map((c) => (
              <option value={c.value}>{c.name}</option>
            ))}
          </StyledSortSelect>
        </SortWrapper>
        )}

        {filterConfig
        && (
        <TableFilterButton onClick={onToggleFilterModal}>
          <Icon size="1em" cursor style={{ transform: 'translateY(3px)' }}><FilterIcon /></Icon>
          {activeFilterCount > 0 && <TableFilterIndicator>{activeFilterCount}</TableFilterIndicator>}
        </TableFilterButton>
        )}

        <TableSearchWrapper>
          {handleSearch
          && (
          <SearchInput
            ref={searchRef}
            id="search"
            name="search"
            placeholder="Search by name..."
            defaultValue={initialSearchValue}
            type="text"
            autoComplete="off"
            fluid
            onChange={(e) => handleSearchInputChange(e.target.value)}
          />
          )}
          {searchActive
          && (
          <ClearSearchChip onClick={handleClearSearch}>
            <Icon size="0.65em" color="var(--white)" cursor><ClearIcon /></Icon>
          </ClearSearchChip>
          )}
        </TableSearchWrapper>

      </ControlsWrapper>

      {showFilterModal && (
      <TableFilterModal
        closeFunction={onToggleFilterModal}
        filterConfig={filterConfig}
        activeFilters={activeConfig.filters}
      />
      )}

    </Box>
  );
}

TableControlsAdvanced.propTypes = {
  handleSearch: PropTypes.func,
  filterConfig: PropTypes.object,
  sortConfig: PropTypes.object,
  toggleConfig: PropTypes.object,
  activeConfig: PropTypes.object,
  viewingLabel: PropTypes.string,
  initialSearchValue: PropTypes.string,
};

TableControlsAdvanced.defaultProps = {
  handleSearch: null,
  filterConfig: null,
  sortConfig: null,
  toggleConfig: null,
  activeConfig: null,
  viewingLabel: null,
  initialSearchValue: null,
};

export default TableControlsAdvanced;
