/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import { postWorkspaceConfirm } from '@api/restricted/workspace-confirm-api';
import { postWorkspaceContractConfirm } from '@api/public/workspace-contract-public-api';

import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import Box from '@ui-kit/box';
import BreakLine from '@ui-kit/dividers/breakLine';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import InputLabel from '@ui-kit/inputs/inputLabel';
import Text from '@ui-kit/typography/text';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

function ContractConfirm({
  contractData,
  suggestionsToConfirm,
  setIsLoadingContract,
  loadContractData,
}) {
  const [isConfirming, setIsConfirming] = useState(false);
  const [workspacePersona, setWorkspacePersona] = useState(null);
  const [acceptSuggestedIPI, setAcceptSuggestedIPI] = useState(true);
  const [acceptSuggestedSociety, setAcceptSuggestedSociety] = useState(true);
  const [error, setError] = useState(false);

  const onClickConfirm = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const handleContractConfirmed = () => {
      if (localStorage.getItem('accessToken')) {
        return postWorkspaceConfirm(
          contractData.id,
          null,
          workspacePersona ? parseInt(workspacePersona, 10) : null,
          (suggestionsToConfirm?.ipi && suggestionsToConfirm.ipi !== AppState.artistProfile.ipi.value && acceptSuggestedIPI)
            ? suggestionsToConfirm.ipi
            : null,
          (suggestionsToConfirm?.society && suggestionsToConfirm.society.name !== AppState.artistProfile.swCollSociety.value[0]?.name && acceptSuggestedSociety)
            ? suggestionsToConfirm.society
            : null,
        );
      }
      return postWorkspaceContractConfirm(
        contractData.id,
        contractData.version,
        queryParams.get('email'),
        queryParams.get('token'),
        null,
        workspacePersona ? parseInt(workspacePersona, 10) : null,
        (suggestionsToConfirm?.ipi && acceptSuggestedIPI) ? suggestionsToConfirm.ipi : null,
        (suggestionsToConfirm?.society && acceptSuggestedSociety) ? suggestionsToConfirm.society : null,
      );
    };

    setError(null);
    setIsConfirming(true);

    handleContractConfirmed()
      .then((response) => {
        if (response.status === 200 || response.status === 202) {
          setIsLoadingContract(true);
          setTimeout(() => {
            loadContractData().then(() => {
              setIsConfirming(false);
              setError(null);
              setIsLoadingContract(false);
            });
          }, 1200);
        } else {
          response.json().then((json) => {
            setError(json.error || response.statusText);
          });
        }
      })
      .catch((err) => {
        setIsConfirming(true);
        setError(err.message);
      });
  };

  return (
    <BaseEditOverlay formCentered>

      <ConversationPrompt
        primaryMessage={`Composition: ${contractData.compositionName}`}
        promptLabel="Time to Sign"
        mb="1em"
      />
      <ConversationExplanation
        mb="2em"
        explanation={AppState.aliases.value.length > 0 || suggestionsToConfirm?.ipi || suggestionsToConfirm?.society
          ? 'Before you sign, there are a few things to confirm.'
          : 'By clicking View Contract, you confirm that you are a participant.'}
      />

      {AppState.aliases.value.length > 0 && (
      <Fragment>
        <InputLabel label="Which Persona did you write with? (Optional)" />
        <BaseSelect
          onChange={(e) => setWorkspacePersona(e.target.value)}
          id="persona"
          name="persona"
          fluid
          mb="1.5rem"
        >
          <option value="" default selected>
            None (
            {AppState.userProfile.firstName}
            {' '}
            {AppState.userProfile.lastName}
            )
          </option>
          {AppState.aliases.value.map((a) => (
            <option value={a.id}>{a.name}</option>
          ))}
        </BaseSelect>
      </Fragment>
      )}

      {(suggestionsToConfirm?.society?.name && suggestionsToConfirm.society.name !== AppState.artistProfile.swCollSociety.value[0]?.name) && (
      <Box>
        <BreakLine mb="1.5em" color="var(--panel-info-border)" />
        <Text fontWeight="800">Suggested Collection Society</Text>
        <Text fontSize="1.67em" mb="0.125em" fontWeight="200" color={acceptSuggestedSociety ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}>
          {suggestionsToConfirm.society.name}
        </Text>
        <Box display="flex" justifyContent="flex-end" mb="1.5em">
          <Text mr="1.25em" fontSize="0.75rem" color="var(--text-primary)">Add to Profile</Text>
          <ToggleSwitch id="society">
            <input
              type="checkbox"
              name="society"
              id="society"
              onChange={() => setAcceptSuggestedSociety(!acceptSuggestedSociety)}
              checked={acceptSuggestedSociety}
            />
          </ToggleSwitch>
        </Box>
      </Box>
      )}

      {(suggestionsToConfirm?.ipi && suggestionsToConfirm.ipi !== AppState.artistProfile.ipi.value) && (
      <Box>
        <BreakLine mb="1.5em" color="var(--panel-info-border)" />
        <Text fontWeight="800">Suggested IPI Number</Text>
        <Text fontSize="1.67em" mb="0.125em" fontWeight="200" color={acceptSuggestedIPI ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}>
          {suggestionsToConfirm.ipi}
        </Text>
        <Box display="flex" justifyContent="flex-end" mb="1.5em">
          <Text mr="1.25em" fontSize="0.75rem" color="var(--text-primary)">Add to Profile</Text>
          <ToggleSwitch id="ipi">
            <input
              type="checkbox"
              name="ipi"
              id="ipi"
              onChange={() => setAcceptSuggestedIPI(!acceptSuggestedIPI)}
              checked={acceptSuggestedIPI}
            />
          </ToggleSwitch>
        </Box>
      </Box>
      )}

      {error && (
      <AlertMessage
        variant="negative"
        message={error}
        mb="1.5em"
      />
      )}

      <BaseButton
        btnText="View Contract"
        fluid
        isLoading={isConfirming}
        onClick={onClickConfirm}
      />

    </BaseEditOverlay>
  );
}

ContractConfirm.propTypes = {
  contractData: PropTypes.object.isRequired,
  suggestionsToConfirm: PropTypes.object,
  setIsLoadingContract: PropTypes.func.isRequired,
  loadContractData: PropTypes.func.isRequired,
};

ContractConfirm.defaultProps = {
  suggestionsToConfirm: {},
};

export default ContractConfirm;
