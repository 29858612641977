import styled from 'styled-components';
import {
  space,
  variant,
} from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const ToastWrapper = styled.div`
  position: fixed;
  top: 16px;
  right: 0;
  border-radius: 16px;
  justify-content: center;
  left: 0;
  display: flex;
  pointer-events: none;
  z-index: 2000;
`;

export const Toast = styled.div(
  () => `
  background: var(--toast-bg);
  box-shadow: var(--toast-shadow);
  position: relative;
  pointer-events: auto;
  min-width: 200px;
  height: 40px;
  border-radius: 0.5em;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0em 1.75em 0em 0.75em;
  border: 1px solid;
  animation: animateInDown 700ms cubic-bezier(0.42, 1, 0.51, 1);
  ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
      margin-top: 0.5em;
    }

    @keyframes animateInDown {
      from {
        transform: translateY(-100px);
      }
      to {
        transform: translateY(0);
      }
    }
`,
  space,
  variant({
    variants: {
      success: {
        borderColor: 'var(--border)',
      },
      error: {
        borderColor: 'var(--border)',
      },
      info: {
        borderColor: 'var(--border)',
      },
    },
  }),
);

export const ToastDismissWrapper = styled.div`
  position: absolute;
 cursor: pointer;
 top: 7px;
 right: 11px;
`;
