import { workspaceContractPublicUrl } from '../urls';

export async function getWorkspaceContractPublic(workspaceId, version, email, token) {
  const url = `${workspaceContractPublicUrl}?${new URLSearchParams({
    workspace: workspaceId,
    version,
    email,
    token,
  })}`;
  return fetch(url, {
    method: 'GET',
  });
}

export async function postWorkspaceContractConfirm(workspaceId, version, email, token, legalName, aliasId, ipi, society) {
  const data = {
    workspace: workspaceId,
    version,
    email,
    token,
    legalName,
    alias: aliasId,
    ipi,
    society,
  };
  return fetch(workspaceContractPublicUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

export async function patchWorkspaceSignaturePublic(workspaceId, email, token, signature, plainSignature) {
  const data = {
    workspace: workspaceId,
    email,
    token,
    signature,
    plainSignature,
  };
  return fetch(workspaceContractPublicUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

export async function patchWorkspaceRejectionPublic(workspaceId, email, token, type, message) {
  const data = {
    workspace: workspaceId,
    email,
    token,
    type,
    message,
  };
  return fetch(workspaceContractPublicUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
