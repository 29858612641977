/* eslint-disable import/prefer-default-export */

export const SOCIETIES = [
  {
    id: '1',
    name: 'ABRAMUS',
    country: 'Brazil',
    societyType: 'cmo',
  },
  {
    id: '2',
    name: 'ADAMI',
    country: 'France',
    societyType: 'cmo',
  },
  {
    id: '3',
    name: 'AIE',
    country: 'Spain',
    societyType: 'cmo',
  },
  {
    id: '4',
    name: 'GRAMEX DK',
    country: 'Denmark',
    societyType: 'cmo',
  },
  {
    id: '5',
    name: 'GVL',
    country: 'Germany',
    societyType: 'cmo',
  },
  {
    id: '6',
    name: 'PLAYRIGHT',
    country: 'Belgium',
    societyType: 'cmo',
  },
  {
    id: '7',
    name: 'PPL India',
    country: 'India',
    societyType: 'cmo',
  },
  {
    id: '8',
    name: 'SIG',
    country: 'Switzerland',
    societyType: 'cmo',
  },
  {
    id: '9',
    name: 'GEMA (Russia)',
    country: 'Russia',
    societyType: 'pro',
  },
  {
    id: '10',
    name: 'AKM',
    country: 'Austria',
    societyType: 'pro',
  },
  {
    id: '11',
    name: 'ARTISJUS',
    country: 'Hungary',
    societyType: 'pro',
  },
  {
    id: '12',
    name: 'BUMA',
    country: 'Netherlands',
    societyType: 'pro',
  },
  {
    id: '13',
    name: 'GEMA',
    country: 'Germany',
    societyType: 'pro',
  },
  {
    id: '14',
    name: 'IMRO',
    country: 'Ireland',
    societyType: 'pro',
  },
  {
    id: '15',
    name: 'KODA',
    country: 'Denmark',
    societyType: 'pro',
  },
  {
    id: '16',
    name: 'MUSICAUTOR',
    country: 'Bulgaria',
    societyType: 'pro',
  },
  {
    id: '17',
    name: 'OSA',
    country: 'Czech Republic',
    societyType: 'pro',
  },
  {
    id: '18',
    name: 'PRS',
    country: 'United Kingdom',
    societyType: 'pro',
  },
  {
    id: '19',
    name: 'PPL',
    country: 'United Kingdom',
    societyType: 'cmo',
  },
  {
    id: '20',
    name: 'SABAM',
    country: 'Belgium',
    societyType: 'pro',
  },
  {
    id: '21',
    name: 'SACEM',
    country: 'France',
    societyType: 'pro',
  },
  {
    id: '22',
    name: 'SAMRO',
    country: 'South Africa',
    societyType: 'pro',
  },
  {
    id: '23',
    name: 'SGAE',
    country: 'Spain',
    societyType: 'pro',
  },
  {
    id: '24',
    name: 'SIAE',
    country: 'Italy',
    societyType: 'pro',
  },
  {
    id: '25',
    name: 'SPA',
    country: 'Portugal',
    societyType: 'pro',
  },
  {
    id: '26',
    name: 'STIM',
    country: 'Sweden',
    societyType: 'pro',
  },
  {
    id: '27',
    name: 'SUISA',
    country: 'Switzerland',
    societyType: 'pro',
  },
  {
    id: '28',
    name: 'TEOSTO',
    country: 'Finland',
    societyType: 'pro',
  },
  {
    id: '29',
    name: 'TONO',
    country: 'Norway',
    societyType: 'pro',
  },
  {
    id: '30',
    name: 'UCMRA-ADA',
    country: 'Romania',
    societyType: 'pro',
  },
  {
    id: '31',
    name: 'ZAiKS',
    country: 'Poland',
    societyType: 'pro',
  },
  {
    id: '32',
    name: 'SESAC',
    country: 'United States',
    societyType: 'pro',
  },
  {
    id: '33',
    name: 'ASCAP',
    country: 'United States',
    societyType: 'pro',
  },
  {
    id: '34',
    name: 'BMI',
    country: 'United States',
    societyType: 'pro',
  },
  {
    id: '35',
    name: 'GMR',
    country: 'United States',
    societyType: 'pro',
  },
  {
    id: '36',
    name: 'SOCAN',
    country: 'Canada',
    societyType: 'pro',
  },
  {
    id: '37',
    name: 'LSG',
    country: 'Austria',
    societyType: 'cmo',
  },
  {
    id: '38',
    name: 'PROPHON',
    country: 'Bulgaria',
    societyType: 'cmo',
  },
  {
    id: '39',
    name: 'RESOUND',
    country: 'Canada',
    societyType: 'cmo',
  },
  {
    id: '40',
    name: 'INTERGRAM',
    country: 'Czech Republic',
    societyType: 'cmo',
  },
  {
    id: '41',
    name: 'GRAMEX FI',
    country: 'Finland',
    societyType: 'cmo',
  },
  {
    id: '42',
    name: 'Itsright',
    country: 'Italy',
    societyType: 'cmo',
  },
  {
    id: '43',
    name: 'JAMMS',
    country: 'Jamaica',
    societyType: 'cmo',
  },
  {
    id: '44',
    name: 'LAIPA',
    country: 'Latvia',
    societyType: 'cmo',
  },
  {
    id: '45',
    name: 'AGATA',
    country: 'Lithuania',
    societyType: 'cmo',
  },
  {
    id: '46',
    name: 'SENA',
    country: 'Netherlands',
    societyType: 'cmo',
  },
  {
    id: '47',
    name: 'GRAMO',
    country: 'Norway',
    societyType: 'cmo',
  },
  {
    id: '48',
    name: 'VOIS',
    country: 'Russia',
    societyType: 'cmo',
  },
  {
    id: '49',
    name: 'SLOVGRAM',
    country: 'Slovakia',
    societyType: 'cmo',
  },
  {
    id: '50',
    name: 'IPF',
    country: 'Slovenia',
    societyType: 'cmo',
  },
  {
    id: '51',
    name: 'SAMPRA',
    country: 'South Africa',
    societyType: 'cmo',
  },
  {
    id: '52',
    name: 'SWISSPERFORM',
    country: 'Switzerland',
    societyType: 'cmo',
  },
  {
    id: '53',
    name: 'UMA',
    country: 'Ukraine',
    societyType: 'cmo',
  },
  {
    id: '54',
    name: 'ULCRR',
    country: 'Ukraine',
    societyType: 'cmo',
  },
  {
    id: '55',
    name: 'AARC',
    country: 'United States',
    societyType: 'cmo',
  },
  {
    id: '56',
    name: 'SoundExchange',
    country: 'United States',
    societyType: 'cmo',
  },
  {
    id: '57',
    name: 'AKDIE',
    country: 'Albania',
    societyType: 'cmo',
  },
  {
    id: '58',
    name: 'AADI',
    country: 'Argentina',
    societyType: 'cmo',
  },
  {
    id: '59',
    name: 'SOCINPRO',
    country: 'Brazil',
    societyType: 'cmo',
  },
  {
    id: '60',
    name: 'UBC',
    country: 'Brazil',
    societyType: 'cmo',
  },
  {
    id: '61',
    name: 'ACTRA',
    country: 'Canada',
    societyType: 'cmo',
  },
  {
    id: '62',
    name: 'ARTISTI',
    country: 'Canada',
    societyType: 'cmo',
  },
  {
    id: '63',
    name: 'MROC',
    country: 'Canada',
    societyType: 'cmo',
  },
  {
    id: '64',
    name: 'ACINPRO',
    country: 'Colombia',
    societyType: 'cmo',
  },
  {
    id: '65',
    name: 'HUZIP',
    country: 'Croatia',
    societyType: 'cmo',
  },
  {
    id: '66',
    name: 'CNR',
    country: 'Cyprus',
    societyType: 'cmo',
  },
  {
    id: '67',
    name: 'EEL',
    country: 'Estonia',
    societyType: 'cmo',
  },
  {
    id: '68',
    name: 'SPEDIDAM',
    country: 'France',
    societyType: 'cmo',
  },
  {
    id: '69',
    name: 'GCA',
    country: 'Georgia',
    societyType: 'cmo',
  },
  {
    id: '70',
    name: 'APOLLON',
    country: 'Greece',
    societyType: 'cmo',
  },
  {
    id: '71',
    name: 'ERATO',
    country: 'Greece',
    societyType: 'cmo',
  },
  {
    id: '72',
    name: 'EJI',
    country: 'Hungary',
    societyType: 'cmo',
  },
  {
    id: '73',
    name: 'SFH',
    country: 'Iceland',
    societyType: 'cmo',
  },
  {
    id: '74',
    name: 'ISRA',
    country: 'India',
    societyType: 'cmo',
  },
  {
    id: '75',
    name: 'RAAP',
    country: 'Ireland',
    societyType: 'cmo',
  },
  {
    id: '76',
    name: 'Nuovo Imaie',
    country: 'Italy',
    societyType: 'cmo',
  },
  {
    id: '77',
    name: 'CPRA',
    country: 'Japan',
    societyType: 'cmo',
  },
  {
    id: '78',
    name: 'AMANAT',
    country: 'Kazakhstan',
    societyType: 'cmo',
  },
  {
    id: '79',
    name: 'FKMP',
    country: 'Republic Of Korea',
    societyType: 'cmo',
  },
  {
    id: '80',
    name: 'RPM',
    country: 'Malaysia',
    societyType: 'cmo',
  },
  {
    id: '81',
    name: 'NORMA',
    country: 'Netherlands',
    societyType: 'cmo',
  },
  {
    id: '82',
    name: 'PRSP',
    country: 'Philippines',
    societyType: 'cmo',
  },
  {
    id: '83',
    name: 'SAWP',
    country: 'Poland',
    societyType: 'cmo',
  },
  {
    id: '84',
    name: 'Norwaco',
    country: 'Norway',
    societyType: 'cmo',
  },
  {
    id: '85',
    name: 'STOART',
    country: 'Poland',
    societyType: 'cmo',
  },
  {
    id: '86',
    name: 'GDA',
    country: 'Portugal',
    societyType: 'cmo',
  },
  {
    id: '87',
    name: 'CREDIDAM',
    country: 'Romania',
    societyType: 'cmo',
  },
  {
    id: '88',
    name: 'PI',
    country: 'Serbia',
    societyType: 'cmo',
  },
  {
    id: '89',
    name: 'SAMI',
    country: 'Sweden',
    societyType: 'cmo',
  },
  {
    id: '90',
    name: 'MUYORBIR',
    country: 'Turkey',
    societyType: 'cmo',
  },
  {
    id: '91',
    name: 'AFM & SAG AFTRA Intellectual Property Rights Fund',
    country: 'United States',
    societyType: 'cmo',
  },
  {
    id: '92',
    name: 'CAPIF',
    country: 'Argentina',
    societyType: 'cmo',
  },
  {
    id: '93',
    name: 'PPCA',
    country: 'Australia',
    societyType: 'cmo',
  },
  {
    id: '94',
    name: 'SIMIM',
    country: 'Belgium',
    societyType: 'cmo',
  },
  {
    id: '95',
    name: 'BRUMUSIC',
    country: 'Brunei',
    societyType: 'cmo',
  },
  {
    id: '96',
    name: 'CONNECT',
    country: 'Canada',
    societyType: 'cmo',
  },
  {
    id: '97',
    name: 'SOPROQ',
    country: 'Canada',
    societyType: 'cmo',
  },
  {
    id: '98',
    name: 'ZAPRAF',
    country: 'Croatia',
    societyType: 'cmo',
  },
  {
    id: '99',
    name: 'GRAMMO',
    country: 'Cyprus',
    societyType: 'cmo',
  },
  {
    id: '100',
    name: 'EFU',
    country: 'Estonia',
    societyType: 'cmo',
  },
  {
    id: '101',
    name: 'SCPP',
    country: 'France',
    societyType: 'cmo',
  },
  {
    id: '102',
    name: 'SPPF',
    country: 'France',
    societyType: 'cmo',
  },
  {
    id: '103',
    name: 'GRAMMO',
    country: 'Greece',
    societyType: 'cmo',
  },
  {
    id: '104',
    name: 'MAHASZ',
    country: 'Hungary',
    societyType: 'cmo',
  },
  {
    id: '105',
    name: 'PPI',
    country: 'Ireland',
    societyType: 'cmo',
  },
  {
    id: '106',
    name: 'SCF',
    country: 'Italy',
    societyType: 'cmo',
  },
  {
    id: '107',
    name: 'RIAJ',
    country: 'Japan',
    societyType: 'cmo',
  },
  {
    id: '108',
    name: 'RIAK',
    country: 'Republic Of Korea',
    societyType: 'cmo',
  },
  {
    id: '109',
    name: 'STAP',
    country: 'Netherlands',
    societyType: 'cmo',
  },
  {
    id: '110',
    name: 'PPNZ',
    country: 'New Zealand',
    societyType: 'cmo',
  },
  {
    id: '111',
    name: 'PARI',
    country: 'Philippines',
    societyType: 'cmo',
  },
  {
    id: '112',
    name: 'ZPAV',
    country: 'Poland',
    societyType: 'cmo',
  },
  {
    id: '113',
    name: 'AUDIOGEST',
    country: 'Portugal',
    societyType: 'cmo',
  },
  {
    id: '114',
    name: 'UPFR',
    country: 'Romania',
    societyType: 'cmo',
  },
  {
    id: '115',
    name: 'OFPS',
    country: 'Serbia',
    societyType: 'cmo',
  },
  {
    id: '116',
    name: 'MMI',
    country: 'Macedonia',
    societyType: 'cmo',
  },
  {
    id: '117',
    name: 'AGEDI',
    country: 'Spain',
    societyType: 'cmo',
  },
  {
    id: '118',
    name: 'IFPI',
    country: 'Sweden',
    societyType: 'cmo',
  },
  {
    id: '119',
    name: 'AACIMH',
    country: 'Honduras',
    societyType: 'pro',
  },
  {
    id: '120',
    name: 'AAS',
    country: 'Azerbaijan',
    societyType: 'pro',
  },
  {
    id: '121',
    name: 'ABRAMUS',
    country: 'Brazil',
    societyType: 'pro',
  },
  {
    id: '122',
    name: 'ABYROY',
    country: 'Kazakhstan',
    societyType: 'pro',
  },
  {
    id: '123',
    name: 'ACAM',
    country: 'Costa Rica',
    societyType: 'pro',
  },
  {
    id: '124',
    name: 'ACCS',
    country: 'Trinidad And Tobago',
    societyType: 'pro',
  },
  {
    id: '125',
    name: 'ACDAM',
    country: 'Cuba',
    societyType: 'pro',
  },
  {
    id: '126',
    name: 'ACS',
    country: 'United Kingdom',
    societyType: 'pro',
  },
  {
    id: '127',
    name: 'ACUM',
    country: 'Israel',
    societyType: 'pro',
  },
  {
    id: '128',
    name: 'ADAGP',
    country: 'France',
    societyType: 'pro',
  },
  {
    id: '129',
    name: 'ADDAF',
    country: 'Brazil',
    societyType: 'pro',
  },
  {
    id: '130',
    name: 'AEI-GUATEMALA',
    country: 'Guatemala',
    societyType: 'pro',
  },
  {
    id: '131',
    name: 'AGADU',
    country: 'Uruguay',
    societyType: 'pro',
  },
  {
    id: '132',
    name: 'AIPA',
    country: 'Slovenia',
    societyType: 'pro',
  },
  {
    id: '133',
    name: 'AKKA-LAA',
    country: 'Latvia',
    societyType: 'pro',
  },
  {
    id: '134',
    name: 'ALBAUTOR',
    country: 'Albania',
    societyType: 'pro',
  },
  {
    id: '135',
    name: 'ALCS',
    country: 'United Kingdom',
    societyType: 'pro',
  },
  {
    id: '136',
    name: 'AMAR SOMBRÁS',
    country: 'Brazil',
    societyType: 'pro',
  },
  {
    id: '137',
    name: 'AMCOS',
    country: 'Australia',
    societyType: 'pro',
  },
  {
    id: '138',
    name: 'AMRA',
    country: 'United States',
    societyType: 'pro',
  },
  {
    id: '139',
    name: 'AMUS',
    country: 'Bosnia And Herzegovina',
    societyType: 'pro',
  },
  {
    id: '140',
    name: 'ANCO',
    country: 'Moldova, Republic Of',
    societyType: 'pro',
  },
  {
    id: '141',
    name: 'APA',
    country: 'Paraguay',
    societyType: 'pro',
  },
  {
    id: '142',
    name: 'APDAYC',
    country: 'Peru',
    societyType: 'pro',
  },
  {
    id: '143',
    name: 'APG-Japan',
    country: 'Japan',
    societyType: 'pro',
  },
  {
    id: '144',
    name: 'APRA',
    country: 'Australasia',
    societyType: 'pro',
  },
  {
    id: '145',
    name: 'APSAV',
    country: 'Peru',
    societyType: 'pro',
  },
  {
    id: '146',
    name: 'ARGENTORES',
    country: 'Argentina',
    societyType: 'pro',
  },
  {
    id: '147',
    name: 'ARMAUTHOR NGO',
    country: 'Armenia',
    societyType: 'pro',
  },
  {
    id: '148',
    name: 'ARS',
    country: 'United States',
    societyType: 'pro',
  },
  {
    id: '149',
    name: 'ARTEGESTION',
    country: 'Ecuador',
    societyType: 'pro',
  },
  {
    id: '150',
    name: 'ASCRL',
    country: 'United States',
    societyType: 'pro',
  },
  {
    id: '151',
    name: 'ASDACS',
    country: 'Australia',
    societyType: 'pro',
  },
  {
    id: '152',
    name: 'ASSIM',
    country: 'Brazil',
    societyType: 'pro',
  },
  {
    id: '153',
    name: 'ATHINA-SADA',
    country: 'Greece',
    societyType: 'pro',
  },
  {
    id: '154',
    name: 'ATN',
    country: 'Chile',
    societyType: 'pro',
  },
  {
    id: '155',
    name: 'AUPO CINEMA',
    country: 'Ukraine',
    societyType: 'pro',
  },
  {
    id: '156',
    name: 'AUTODIA',
    country: 'Greece',
    societyType: 'pro',
  },
  {
    id: '157',
    name: 'AUTVIS',
    country: 'Brazil',
    societyType: 'pro',
  },
  {
    id: '158',
    name: 'AWGACS',
    country: 'Australia',
    societyType: 'pro',
  },
  {
    id: '159',
    name: 'AzDG',
    country: 'Azerbaijan',
    societyType: 'pro',
  },
  {
    id: '160',
    name: 'BBDA',
    country: 'Burkina Faso',
    societyType: 'pro',
  },
  {
    id: '161',
    name: 'BCDA',
    country: 'Congo',
    societyType: 'pro',
  },
  {
    id: '162',
    name: 'BGDA',
    country: 'Guinea',
    societyType: 'pro',
  },
  {
    id: '163',
    name: 'BILDRECHT',
    country: 'Austria',
    societyType: 'pro',
  },
  {
    id: '164',
    name: 'Bildupphovsrätt',
    country: 'Sweden',
    societyType: 'pro',
  },
  {
    id: '165',
    name: 'BMDA',
    country: 'Morocco',
    societyType: 'pro',
  },
  {
    id: '166',
    name: 'BONO',
    country: 'Norway',
    societyType: 'pro',
  },
  {
    id: '167',
    name: 'BSCAP',
    country: 'Belize',
    societyType: 'pro',
  },
  {
    id: '168',
    name: 'BUBEDRA',
    country: 'Benin',
    societyType: 'pro',
  },
  {
    id: '169',
    name: 'BUMDA',
    country: 'Mali',
    societyType: 'pro',
  },
  {
    id: '170',
    name: 'BURIDA',
    country: 'Ivory Coast',
    societyType: 'pro',
  },
  {
    id: '171',
    name: 'BUTODRA',
    country: 'Togo',
    societyType: 'pro',
  },
  {
    id: '172',
    name: 'CAPASSO',
    country: 'South Africa',
    societyType: 'pro',
  },
  {
    id: '173',
    name: 'CARCC',
    country: 'Canada',
    societyType: 'pro',
  },
  {
    id: '174',
    name: 'CASH',
    country: 'Hong Kong',
    societyType: 'pro',
  },
  {
    id: '175',
    name: 'CIAGP',
    country: 'France',
    societyType: 'pro',
  },
  {
    id: '176',
    name: 'CIAM',
    country: 'France',
    societyType: 'pro',
  },
  {
    id: '177',
    name: 'CMC',
    country: 'Cameroon',
    societyType: 'pro',
  },
  {
    id: '178',
    name: 'CMRRA',
    country: 'Canada',
    societyType: 'pro',
  },
  {
    id: '179',
    name: 'COMPASS',
    country: 'Singapore',
    societyType: 'pro',
  },
  {
    id: '180',
    name: 'Copyright Agency',
    country: 'Australia',
    societyType: 'pro',
  },
  {
    id: '181',
    name: 'COSBOTS',
    country: 'Botswana',
    societyType: 'pro',
  },
  {
    id: '182',
    name: 'COSCAP',
    country: 'Barbados',
    societyType: 'pro',
  },
  {
    id: '183',
    name: 'COSOMA',
    country: 'Malawi',
    societyType: 'pro',
  },
  {
    id: '184',
    name: 'COSON',
    country: 'Nigeria',
    societyType: 'pro',
  },
  {
    id: '185',
    name: 'COSOTA',
    country: 'Tanzania, United Republic Of',
    societyType: 'pro',
  },
  {
    id: '186',
    name: 'COSOZA',
    country: 'Tanzania, United Republic Of',
    societyType: 'pro',
  },
  {
    id: '187',
    name: 'COTT',
    country: 'Trinidad And Tobago',
    societyType: 'pro',
  },
  {
    id: '188',
    name: 'CPSN',
    country: 'Nepal',
    societyType: 'pro',
  },
  {
    id: '189',
    name: 'CREAIMAGEN',
    country: 'Chile',
    societyType: 'pro',
  },
  {
    id: '190',
    name: 'CRSEA',
    country: 'Russian Federation',
    societyType: 'pro',
  },
  {
    id: '191',
    name: 'CSCS',
    country: 'Canada',
    societyType: 'pro',
  },
  {
    id: '192',
    name: 'DAC',
    country: 'Argentina',
    societyType: 'pro',
  },
  {
    id: '193',
    name: 'DACIN SARA',
    country: 'Romania',
    societyType: 'pro',
  },
  {
    id: '194',
    name: 'DACS',
    country: 'United Kingdom',
    societyType: 'pro',
  },
  {
    id: '195',
    name: 'DALRO',
    country: 'South Africa',
    societyType: 'pro',
  },
  {
    id: '196',
    name: 'DAMA',
    country: 'Spain',
    societyType: 'pro',
  },
  {
    id: '197',
    name: 'DASC',
    country: 'Colombia',
    societyType: 'pro',
  },
  {
    id: '198',
    name: 'DBCA',
    country: 'Brazil',
    societyType: 'pro',
  },
  {
    id: '199',
    name: 'DEGNZ',
    country: 'New Zealand',
    societyType: 'pro',
  },
  {
    id: '200',
    name: 'DGA',
    country: 'United States',
    societyType: 'pro',
  },
  {
    id: '201',
    name: 'DGK',
    country: 'Korea, Republic Of',
    societyType: 'pro',
  },
  {
    id: '202',
    name: 'DHFR',
    country: 'Croatia',
    societyType: 'pro',
  },
  {
    id: '203',
    name: 'DILIA',
    country: 'Czech Republic',
    societyType: 'pro',
  },
  {
    id: '204',
    name: 'DIRECTORES',
    country: 'Mexico',
    societyType: 'pro',
  },
  {
    id: '205',
    name: 'DIRECTORS UK',
    country: 'United Kingdom',
    societyType: 'pro',
  },
  {
    id: '206',
    name: 'DRCC',
    country: 'Canada',
    societyType: 'pro',
  },
  {
    id: '207',
    name: 'EAU',
    country: 'Estonia',
    societyType: 'pro',
  },
  {
    id: '208',
    name: 'ECCO',
    country: 'Saint Lucia',
    societyType: 'pro',
  },
  {
    id: '209',
    name: 'ESMAA',
    country: 'United Arab Emirates',
    societyType: 'pro',
  },
  {
    id: '210',
    name: 'EVA',
    country: 'Belgium',
    societyType: 'pro',
  },
  {
    id: '211',
    name: 'FILMAUTOR',
    country: 'Bulgaria',
    societyType: 'pro',
  },
  {
    id: '212',
    name: 'FILMJUS',
    country: 'Hungary',
    societyType: 'pro',
  },
  {
    id: '213',
    name: 'FILSCAP',
    country: 'Philippines',
    societyType: 'pro',
  },
  {
    id: '214',
    name: 'GCA',
    country: 'Georgia',
    societyType: 'pro',
  },
  {
    id: '215',
    name: 'GEDAR',
    country: 'Brazil',
    societyType: 'pro',
  },
  {
    id: '216',
    name: 'GESAC',
    country: 'Belgium',
    societyType: 'pro',
  },
  {
    id: '217',
    name: 'GESTOR',
    country: 'Czech Republic',
    societyType: 'pro',
  },
  {
    id: '218',
    name: 'GHAMRO',
    country: 'Ghana',
    societyType: 'pro',
  },
  {
    id: '219',
    name: 'HDS-ZAMP',
    country: 'Croatia',
    societyType: 'pro',
  },
  {
    id: '220',
    name: 'HEXACORP LTD',
    country: 'United States',
    societyType: 'pro',
  },
  {
    id: '221',
    name: 'HUNGART',
    country: 'Hungary',
    societyType: 'pro',
  },
  {
    id: '222',
    name: 'ICSC',
    country: 'China',
    societyType: 'pro',
  },
  {
    id: '223',
    name: 'IMPF',
    country: 'Belgium',
    societyType: 'pro',
  },
  {
    id: '224',
    name: 'IPRS',
    country: 'India',
    societyType: 'pro',
  },
  {
    id: '225',
    name: 'ISOCRATIS',
    country: 'Greece',
    societyType: 'pro',
  },
  {
    id: '226',
    name: 'IVARO',
    country: 'Ireland',
    societyType: 'pro',
  },
  {
    id: '227',
    name: 'JACAP',
    country: 'Jamaica',
    societyType: 'pro',
  },
  {
    id: '228',
    name: 'JASPAR',
    country: 'Japan',
    societyType: 'pro',
  },
  {
    id: '229',
    name: 'JASRAC',
    country: 'Japan',
    societyType: 'pro',
  },
  {
    id: '230',
    name: 'KazAK',
    country: 'Kazakhstan',
    societyType: 'pro',
  },
  {
    id: '231',
    name: 'KOLAA',
    country: 'Korea, Republic Of',
    societyType: 'pro',
  },
  {
    id: '232',
    name: 'KOMCA',
    country: 'Korea, Republic Of',
    societyType: 'pro',
  },
  {
    id: '233',
    name: 'KOPIOSTO',
    country: 'Finland',
    societyType: 'pro',
  },
  {
    id: '234',
    name: 'KOSCAP',
    country: 'Korea, Republic Of',
    societyType: 'pro',
  },
  {
    id: '235',
    name: 'KUVASTO',
    country: 'Finland',
    societyType: 'pro',
  },
  {
    id: '236',
    name: 'LATGA',
    country: 'Lithuania',
    societyType: 'pro',
  },
  {
    id: '237',
    name: 'LIRA',
    country: 'Netherlands',
    societyType: 'pro',
  },
  {
    id: '238',
    name: 'LITA',
    country: 'Slovakia',
    societyType: 'pro',
  },
  {
    id: '239',
    name: 'LITERAR-MECHANA',
    country: 'Austria',
    societyType: 'pro',
  },
  {
    id: '240',
    name: 'MACA',
    country: 'Macau',
    societyType: 'pro',
  },
  {
    id: '241',
    name: 'MACP',
    country: 'Malaysia',
    societyType: 'pro',
  },
  {
    id: '242',
    name: 'MASA',
    country: 'Mauritius',
    societyType: 'pro',
  },
  {
    id: '243',
    name: 'MCSC',
    country: 'China',
    societyType: 'pro',
  },
  {
    id: '244',
    name: 'MCSK',
    country: 'Kenya',
    societyType: 'pro',
  },
  {
    id: '245',
    name: 'MCSN',
    country: 'Nigeria',
    societyType: 'pro',
  },
  {
    id: '246',
    name: 'MCT',
    country: 'Thailand',
    societyType: 'pro',
  },
  {
    id: '247',
    name: 'MOSCAP',
    country: 'Mongolia',
    societyType: 'pro',
  },
  {
    id: '248',
    name: 'MRCSN',
    country: 'Nepal',
    societyType: 'pro',
  },
  {
    id: '249',
    name: 'MÜST',
    country: 'Taiwan, Chinese Taipei',
    societyType: 'pro',
  },
  {
    id: '250',
    name: 'NASCAM',
    country: 'Namibia',
    societyType: 'pro',
  },
  {
    id: '251',
    name: 'NCB',
    country: 'Denmark',
    societyType: 'pro',
  },
  {
    id: '252',
    name: 'NCIP',
    country: 'Belarus',
    societyType: 'pro',
  },
  {
    id: '253',
    name: 'NexTone',
    country: 'Japan',
    societyType: 'pro',
  },
  {
    id: '254',
    name: 'NGO-UACRR',
    country: 'Ukraine',
    societyType: 'pro',
  },
  {
    id: '255',
    name: 'OAZA',
    country: 'Czech Republic',
    societyType: 'pro',
  },
  {
    id: '256',
    name: 'ODDA',
    country: 'Djibouti',
    societyType: 'pro',
  },
  {
    id: '257',
    name: 'OFA',
    country: 'Serbia',
    societyType: 'pro',
  },
  {
    id: '258',
    name: 'OMDA',
    country: 'Madagascar',
    societyType: 'pro',
  },
  {
    id: '259',
    name: 'ONDA',
    country: 'Algeria',
    societyType: 'pro',
  },
  {
    id: '260',
    name: 'OOA-S',
    country: 'Czech Republic',
    societyType: 'pro',
  },
  {
    id: '261',
    name: 'OTDAV',
    country: 'Tunisia',
    societyType: 'pro',
  },
  {
    id: '262',
    name: 'PAM CG',
    country: 'Montenegro',
    societyType: 'pro',
  },
  {
    id: '263',
    name: 'PICTORIGHT',
    country: 'Netherlands',
    societyType: 'pro',
  },
  {
    id: '264',
    name: 'PROLITTERIS',
    country: 'Switzerland',
    societyType: 'pro',
  },
  {
    id: '265',
    name: 'RAO',
    country: 'Russian Federation',
    societyType: 'pro',
  },
  {
    id: '266',
    name: 'REDES',
    country: 'Colombia',
    societyType: 'pro',
  },
  {
    id: '267',
    name: 'RSAU',
    country: 'Rwanda',
    societyType: 'pro',
  },
  {
    id: '268',
    name: 'RUR',
    country: 'Russian Federation',
    societyType: 'pro',
  },
  {
    id: '269',
    name: 'SAA',
    country: 'Belgium',
    societyType: 'pro',
  },
  {
    id: '270',
    name: 'SACD',
    country: 'France',
    societyType: 'pro',
  },
  {
    id: '271',
    name: 'SACEM LUXEMBOURG',
    country: 'Luxembourg',
    societyType: 'pro',
  },
  {
    id: '272',
    name: 'SACENC',
    country: 'France',
    societyType: 'pro',
  },
  {
    id: '273',
    name: 'SACERAU',
    country: 'Egypt',
    societyType: 'pro',
  },
  {
    id: '274',
    name: 'SACIM, EGC',
    country: 'El Salvador',
    societyType: 'pro',
  },
  {
    id: '275',
    name: 'SACK',
    country: 'Korea, Republic Of',
    societyType: 'pro',
  },
  {
    id: '276',
    name: 'SACM',
    country: 'Mexico',
    societyType: 'pro',
  },
  {
    id: '277',
    name: 'SACS',
    country: 'Seychelles',
    societyType: 'pro',
  },
  {
    id: '278',
    name: 'SACVEN',
    country: 'Venezuela',
    societyType: 'pro',
  },
  {
    id: '279',
    name: 'SADAIC',
    country: 'Argentina',
    societyType: 'pro',
  },
  {
    id: '280',
    name: 'SAIF',
    country: 'France',
    societyType: 'pro',
  },
  {
    id: '281',
    name: 'SANASTO',
    country: 'Finland',
    societyType: 'pro',
  },
  {
    id: '282',
    name: 'SARTEC',
    country: 'Canada',
    societyType: 'pro',
  },
  {
    id: '283',
    name: 'SASUR',
    country: 'Suriname',
    societyType: 'pro',
  },
  {
    id: '284',
    name: 'SAVA',
    country: 'Argentina',
    societyType: 'pro',
  },
  {
    id: '285',
    name: 'SAYCE',
    country: 'Ecuador',
    societyType: 'pro',
  },
  {
    id: '286',
    name: 'SAYCO',
    country: 'Colombia',
    societyType: 'pro',
  },
  {
    id: '287',
    name: 'SAZAS',
    country: 'Slovenia',
    societyType: 'pro',
  },
  {
    id: '288',
    name: 'SBACEM',
    country: 'Brazil',
    societyType: 'pro',
  },
  {
    id: '289',
    name: 'Scam',
    country: 'France',
    societyType: 'pro',
  },
  {
    id: '290',
    name: 'SCD',
    country: 'Chile',
    societyType: 'pro',
  },
  {
    id: '291',
    name: 'SCM-COOPERATIVA',
    country: 'Cape Verde',
    societyType: 'pro',
  },
  {
    id: '292',
    name: 'SDADV',
    country: 'Andorra',
    societyType: 'pro',
  },
  {
    id: '293',
    name: 'SGACEDOM',
    country: 'Dominican Republic',
    societyType: 'pro',
  },
  {
    id: '294',
    name: 'SICAM',
    country: 'Brazil',
    societyType: 'pro',
  },
  {
    id: '295',
    name: 'SOBODAYCOM',
    country: 'Bolivia',
    societyType: 'pro',
  },
  {
    id: '296',
    name: 'SOCILADRA',
    country: 'Cameroon',
    societyType: 'pro',
  },
  {
    id: '297',
    name: 'SOCINPRO',
    country: 'Brazil',
    societyType: 'pro',
  },
  {
    id: '298',
    name: 'SODAV',
    country: 'Senegal',
    societyType: 'pro',
  },
  {
    id: '299',
    name: 'SOFAM',
    country: 'Belgium',
    societyType: 'pro',
  },
  {
    id: '300',
    name: 'SOGEM',
    country: 'Mexico',
    societyType: 'pro',
  },
  {
    id: '301',
    name: 'SOKOJ',
    country: 'Serbia',
    societyType: 'pro',
  },
  {
    id: '302',
    name: 'SOMAAP',
    country: 'Mexico',
    societyType: 'pro',
  },
  {
    id: '303',
    name: 'SOMAS',
    country: 'Mozambique',
    societyType: 'pro',
  },
  {
    id: '304',
    name: 'SOUNDREEF',
    country: 'United Kingdom',
    societyType: 'pro',
  },
  {
    id: '305',
    name: 'SOZA',
    country: 'Slovakia',
    societyType: 'pro',
  },
  {
    id: '306',
    name: 'SPAC',
    country: 'Panama',
    societyType: 'pro',
  },
  {
    id: '307',
    name: 'SPACQ',
    country: 'Canada',
    societyType: 'pro',
  },
  {
    id: '308',
    name: 'SSA',
    country: 'Switzerland',
    societyType: 'pro',
  },
  {
    id: '309',
    name: 'STEF',
    country: 'Iceland',
    societyType: 'pro',
  },
  {
    id: '310',
    name: 'SUISSIMAGE',
    country: 'Switzerland',
    societyType: 'pro',
  },
  {
    id: '311',
    name: 'TALI',
    country: 'Israel',
    societyType: 'pro',
  },
  {
    id: '312',
    name: 'UBC',
    country: 'Brazil',
    societyType: 'pro',
  },
  {
    id: '313',
    name: 'UFFICIO GIURIDICO',
    country: 'Holy See (Vatican City State)',
    societyType: 'pro',
  },
  {
    id: '314',
    name: 'UNAC-SA',
    country: 'Angola',
    societyType: 'pro',
  },
  {
    id: '315',
    name: 'UNISON',
    country: 'Spain',
    societyType: 'pro',
  },
  {
    id: '316',
    name: 'UPRAVIS',
    country: 'Russian Federation',
    societyType: 'pro',
  },
  {
    id: '317',
    name: 'UPRS',
    country: 'Uganda',
    societyType: 'pro',
  },
  {
    id: '318',
    name: 'VCPMC',
    country: 'Viet Nam',
    societyType: 'pro',
  },
  {
    id: '319',
    name: 'VdFS',
    country: 'Austria',
    societyType: 'pro',
  },
  {
    id: '320',
    name: 'VEGAP',
    country: 'Spain',
    societyType: 'pro',
  },
  {
    id: '321',
    name: 'VEVAM',
    country: 'Netherlands',
    societyType: 'pro',
  },
  {
    id: '322',
    name: 'VG BILD-KUNST',
    country: 'Germany',
    societyType: 'pro',
  },
  {
    id: '323',
    name: 'VISDA',
    country: 'Denmark',
    societyType: 'pro',
  },
  {
    id: '324',
    name: 'WAMI',
    country: 'Indonesia',
    societyType: 'pro',
  },
  {
    id: '325',
    name: 'WDW',
    country: 'France',
    societyType: 'pro',
  },
  {
    id: '326',
    name: 'WGAW',
    country: 'United States',
    societyType: 'pro',
  },
  {
    id: '327',
    name: 'ZAMCOPS',
    country: 'Zambia',
    societyType: 'pro',
  },
  {
    id: '328',
    name: 'ZAMP Macedonia',
    country: 'Macedonia, The Former Yugoslav Republic Of',
    societyType: 'pro',
  },
  {
    id: '329',
    name: 'ZAMP Slovenia',
    country: 'Slovenia',
    societyType: 'pro',
  },
  {
    id: '330',
    name: 'ZAPA',
    country: 'Poland',
    societyType: 'pro',
  },
  {
    id: '331',
    name: 'ZIMURA',
    country: 'Zimbabwe',
    societyType: 'pro',
  },
  {
    id: '332',
    name: 'STEMRA',
    country: 'Netherlands',
    societyType: 'pro',
  },
  {
    id: '333',
    name: 'MCPS',
    country: 'United Kingdom',
    societyType: 'pro',
  },
  {
    id: '334',
    name: 'NCB (Sweden)',
    country: 'Sweden',
    societyType: 'pro',
  },
  {
    id: '335',
    name: 'ALLTRACK',
    country: 'United States',
    societyType: 'pro',
  },
];
