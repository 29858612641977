// import { useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import BaseButton from '@ui-kit/buttons/baseButton';
import AlertMessage from '@ui-kit/alert/Alert';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';

function LoginForm({
  handleAuthentication,
  error,
  isLoading,
}) {
  const {
    register, handleSubmit, formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const onSubmit = (data) => {
    handleAuthentication(data);
  };

  return (
    <form id="authForm" onSubmit={handleSubmit(onSubmit)} autoComplete="on">
      <BaseInput
        {...register('email', { required: true })}
        id="email"
        name="email"
        placeholder="you@yourdomain.com"
        autoComplete="username"
        type="email"
        fluid
        mb="1.5em"
      />
      <BaseInput
        {...register('password', { required: true })}
        placeholder="Password"
        autoComplete="current-password"
        type="password"
        fluid
        mb="1.5em"
      />
      {error && (
      <AlertMessage
        variant="negative"
        message={error}
        mb="1.5em"
      />
      )}
      <BaseButton
        mb="1.5em"
        type="submit"
        btnText="Submit"
        disabled={!isValid}
        fluid
        isLoading={isLoading}
      />
      <Box display="flex" justifyContent="center">
        <Text><a href="/forgot">Forgot Password</a></Text>
      </Box>
    </form>
  );
}

LoginForm.propTypes = {
  handleAuthentication: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LoginForm;
