import styled from 'styled-components';
import { space } from 'styled-system';

const IconMonster = styled.div(({
  size,
  color,
  cursor,
  pointerEvents,
}) => `
    width:  ${size || '32px'};
    cursor: ${cursor ? 'pointer' : 'default'};
    color: ${color || 'var(--icon)'};
    pointer-events: ${pointerEvents || 'auto'};
    ${space}

    & > svg {
      width: ${size || '32px'};
    }
    `, space);

export default IconMonster;
