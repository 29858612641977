/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import StyledRow from './TableRowStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function TableRow({
  children,
  cursor,
  ...restProps
}) {
  return (
    <StyledRow className="row" cursor={cursor} {...restProps}>
      {children}
    </StyledRow>
  );
}

TableRow.propTypes = {
  children: childrenPropTypes.isRequired,
  cursor: PropTypes.bool,
};

TableRow.defaultProps = {
  cursor: false,
};

export default TableRow;
