import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const StyledPercentageInput = styled.input`
    height: 2.75em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0em;
    border: 1px solid var(--input-border);
    width: 100%;
    padding-left: 0.75em;
    background: var(--input-bg);
    color: var(--text-primary);
`;

export const StyledPercentBox = styled.div`
    color: var(--text-primary);
    height: 2.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75em;
    font-size: 1em;
    border: 1px solid var(--input-border);
    border-left: none;
    background: var(--input-append-bg);
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
`;

export const StyledDateWrapper = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;

        .dateBox {
            width: 100%;

            input {
                margin-bottom: 1.5em;
            }
        }

        hr {
            display: none;
        }
    }
`;
