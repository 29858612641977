const config = {
  google: {
    placesKey: 'AIzaSyAOxRM405Z0xp5-xpN8yXjDXEiD06e9c6A',
  },
  resources: {
    photoPrefixURL: 'https://switchchord-static-hosting.s3.amazonaws.com/images/photos/',
  },
};

module.exports = config;
