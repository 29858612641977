import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const LockWrapper = styled.div`
  display: block;
  position: relative;

  .toolTipText {
    visibility: hidden;
    background-color: var(--active-nav);
    color: var(--text-soft-mute);
    text-align: center;
    position: absolute;
    z-index: 100;
    width: 160px;
    bottom: 100%;
    left: 50%;
    margin-left: -80px;
    font-size: 0.688em;
    line-height: 1.6;
    padding: 0.5em 1em;
    border-radius: 0.5em 1em;
    box-shadow: 2px 4px 11px var(--background);
  }
  
  &:hover .toolTipText {
    visibility: visible;
  }

  @media screen and (max-width: ${pointBreaks.sm}) {
    display: none;
  }
`;

export const MobileLockWrapper = styled.div`
  display: none;

  @media screen and (max-width: ${pointBreaks.sm}) {
    display: block;
  }
`;
