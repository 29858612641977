/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';

export const EmptyOrgWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.5rem;
    width: 4.5rem;
    background: var(--inactive);
    border-radius: 12px;
    ${space};
`;
